<template>
  <div class="login">
    <div class="logo">
      <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="80" height="80" rx="12" fill="#002D6E"/>
        <path d="M49.9065 38.7332C48.9969 37.9501 48.0007 37.3292 46.9557 36.8657C47.3658 36.5241 47.7539 36.1482 48.1153 35.7347C51.7999 31.5178 51.3442 25.1326 47.0972 21.4744C45.1677 19.8128 42.7905 19 40.4241 19.0015L26 19V61.964H53.9997V47.73C54.0232 44.4024 52.6359 41.0858 49.9065 38.7332ZM48.0604 58.5943H42.9695V44.2729H37.0302V58.5943H31.9393V22.3697H37.8786C41.0416 22.3697 43.6986 24.5179 44.4527 27.4243H38.7272V30.794H44.4496C43.6969 33.6989 41.0448 35.8454 37.8849 35.8486H37.8724H35.3332V39.2183H39.5756C41.5475 39.2168 43.5289 39.8955 45.1363 41.2793C47.0688 42.9438 48.0572 45.2871 48.0587 47.6426H48.0619L48.0604 58.5943Z" fill="white"/>
      </svg>
    </div>
    <HseSpace size='small' vertical />
    <form class="form" @submit="forgotReq">
      <Validator :field="login" @change="clearError" />
      <HseSpace size='large' vertical />
      <HseButton
        v-on:click="forgotReq"
        :spinner="buttonSpinner"
        spinnerPosition="left"
      >
        <span v-if="buttonSpinner === false">{{ $t('auth.forgotBtn') }}</span>
        <span v-else-if="buttonSpinner === true">{{ $t('auth.forgotBtnLoading') }}</span>
        <span v-else-if="buttonSpinner === 'done'">{{ $t('auth.btnDone') }}</span>
      </HseButton>
      <div v-if="isCodeSent" class="result">
        <HseSpace size='small_2x' vertical />
        {{ $t('auth.forgotResult') }}
      </div>
      <HseSpace size='small_2x' vertical />
      <div class="links">
        <span class="link" v-on:click="() => setState('register')">{{ $t('auth.register') }}</span>
        <HseSpace size='small_2x' vertical />
        <span class="link" v-on:click="() => setState('loginOptions')">{{ $t('auth.login') }}</span>
      </div>
    </form>
  </div>
</template>

<script>
  import { api } from '../../../api'
  import { HseButton, HseSpace, HseInput } from '@hse-design/vue'
  import Validator from '../form/Validator.vue'

  export default {
    name: 'Login',
    props: ['state'],
    data: () => ({
      loginMaster: {
        value: '',
        isValid: false
      },
      buttonSpinner: false,
      isCodeSent: false,
    }),
    components: {
      Validator,
      HseButton,
      HseSpace,
    },
    methods: {
      clearError () {
        this.loginVal = this.login.value
        if (this.$store.state.error && this.$store.state.error.url === 'auth user.prepare.password.recovery') {
          this.$store.commit('setError', null)
        }
      },
      forgotReq (e) {
        e ? e.preventDefault() : null
        if (!this.isCodeSent) {
          this.$emit('validateFields')
          if (!this.login.isValid) return

          this.buttonSpinner = true

          const params = { login: this.login.value, lang: this.$store.state.lang }
          api.auth('user.prepare.password.recovery', params).then(data => {
            if (data) {
              this.buttonSpinner = 'done'
              this.isCodeSent = true
            } else {
              this.$emit('validateFields')
              this.buttonSpinner = false
              this.isCodeSent = false
            }
          })
        }
      },
      setState (state) {
        this.buttonSpinner = false
        this.isCodeSent = false

        this.$emit('changeState', { state })
      },
    },
    computed: {
      login () {
        return {
          value: this.loginMaster.value,
          isValid: this.loginMaster.isValid,
          label: this.$i18n.t('fields.name.email'),
          placeholder: this.$i18n.t('fields.name.email'),
          isRequired: true,
          size: 'large',
          component: HseInput,
          rules: [{
            name: 'email',
          }, {
            name: 'custom',
            message: 'fields.rule.noSuchRecord',
            validateVal: () => {
              const { error } = this.$store.state
              if (error && error.code === 7 && error.url === 'auth user.prepare.password.recovery') {
                return false
              }
              return true
            }
          }],
          setValues: (isValid, value = '') => {
            this.loginMaster.value = value
            this.loginMaster.isValid = isValid
          }
        }
      },
    },
  }
</script>

<style scoped>
  .login {
    width: 340px;
    background: #FFFFFF;
    border-radius: 12px;
    margin: 53px auto;
    padding: 24px 30px;
  }
  .logo {
    text-align: center;
  }
  .links {
    text-align: center;
  }
  .link {
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
    text-decoration-line: underline;
    color: #0060CF;
    clear: both;
  }
  .result {
    text-align: center;
    font-size: 12px;
  }
  .hse-Button {
    width: 100% !important;
  }

  @media (max-width: 767px) {
    .login {
      margin: 16px auto;
    }
  }
  @media (max-width: 374px) {
    .login {
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
    }
  }
</style>
