import { DateTime } from 'luxon' 
class DateFormatter {
  constructor () {}

  format (date, type) {
    if (!date) return ''
    const dateObj = new DateTime.fromISO(date, { zone: 'utc' })

    let format = ''
    switch (type) {
      case 'year':
        format = 'yyyy'
        break
      case 'date':
        format = 'dd.MM.yyyy'
        break
      case 'reverse-date':
        format = 'yyyy-MM-dd'
        break
      case 'time':
        format = 'HH:mm'
        break
      default:
        format = 'dd.MM.yyyy, HH:mm'
        break
    }

    return dateObj.toFormat(format)
  }
}

export const dateFormatter = new DateFormatter()
