import { HseInput, HseSelect, HseTextArea, } from '@hse-design/vue'
import { resourceLoader } from '../common/resourceLoader'

const fields = {
  event: {
    value: '',
    isValid: false,
    label: "fields.name.event",
    isRequired: true,
    size: "large",
    component: HseSelect,
    searchable: 'dropdown',
    getAllOptions (search) {
      return resourceLoader.load('eventListForUser', { archive: false, offset: 0, limit: 100 }, [], search)
    },
  },
  subject: {
    value: '',
    isValid: false,
    label: 'fields.message.subject',
    isRequired: true,
    size: "large",
    component: HseInput,
  },
  body: {
    value: '',
    isValid: false,
    label: 'fields.message.body',
    isRequired: true,
    size: "large",
    component: HseTextArea,
  },
  attachment: {
    label: 'fields.message.attachment',
    value: '',
    isValid: false,
    maxSizeMb: 30,
    formats: ['jpg', 'jpeg','pdf','doc','docx','xls','xlsx'],
  },

}

Object.keys(fields).forEach((key) => {
  fields[key].setValues = function (isValid, value = '') {
    this.value = value
    this.isValid = isValid
  }
})

export default fields