<template>
  <div class="login">
    <Logos />
    <HseSpace size='small' vertical />
    <div v-if="lang === 'ru'">
      <span class="hse-RichInput__label">For English version please change the language</span>
    </div>
    <HseSpace size='small_2x' vertical />
    <span class="hse-RichInput__label" >{{$t('auth.warning')}}</span>
    <HseSpace size='small' vertical />
    <HseButton v-on:click="() => setState('login')">{{ $t('auth.login') }}</HseButton>
    <HseSpace size='small_2x' vertical />
    <HseButton v-on:click="adfs">{{ $t('auth.adfs') }}</HseButton>
    <HseSpace size='small_2x' vertical />
    <HseButton v-on:click="() => setState('code')">{{ $t('auth.code') }}</HseButton>
    <HseSpace size='small_2x' vertical />
    <div class="links">
      <span class="link" v-on:click="() => setState('register')">{{ $t('auth.register') }}</span>
      <HseSpace size='small_2x' vertical />
<!--      <span class="link" v-on:click="() => setState('forgot')">{{ $t('auth.forgot') }}</span>-->
    </div>
  </div>
</template>

<script>
import { HseButton, HseSpace } from '@hse-design/vue'
import { mapState } from 'vuex'
import { api } from '../../../api'
import Logos from './Logos.vue'

export default {
  name: 'LoginOptions',
  props: ['state'],
  components: {
    HseButton,
    HseSpace,
    Logos
  },
  beforeCreate(){
    api.loginByADFS()
  },
  methods: {
    adfs() {
      window.location.replace(`${window.location.origin}/auth/adfs/login`)
    },
    setState(state) {
      this.$emit('changeState', { state })
    },
  },
  computed: mapState(['lang']),
}
</script>

<style scoped>
.login {
  width: 340px;
  background: #FFFFFF;
  border-radius: 12px;
  margin: 53px auto;
  padding: 24px 30px;
}

.links {
  text-align: center;
}

.link {
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  text-decoration-line: underline;
  color: #0060CF;
  clear: both;
}

.hse-Button {
  width: 100% !important;
}

@media (max-width: 767px) {
  .login {
    margin: 16px auto;
  }
}

@media (max-width: 374px) {
  .login {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
  }
}
</style>
