<template>
    <div>
        <HseModalInfo :modal-class="'submit-modal'" v-model="isOpen" :modal-style="{ maxWidth: '350px' }" ref="modal">
            <div>{{ modalText }}</div>
        </HseModalInfo>
    </div>
</template>

<script>
    import { HseModalInfo } from '@hse-design/vue'

    export default {
        components: {
            HseModalInfo,
        },
        data: () => ({
            isOpen: false,
            isLoading: false,
            modalText: '',
        }),
        created () {
            this.$root.$refs.submitModal = this
        },
        methods: {
            open(text) {
                this.modalText = text
                this.isOpen = true
            }
        },
    }
</script>

<style>
    .submit-modal .hse-BaseModal__wrapper {
        min-height: 120px;
        text-align: center;
        padding: 47px;
    }
</style>