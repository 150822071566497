import {HseInput, HseSelect} from "@hse-design/vue";

const fields = {
    date: {
        value: '',
        isValid: false,
        label: 'fields.specialEvents.date',
        size: 'medium',
        component: HseInput,
    },
    time: {
        value: '',
        isValid: false,
        label: "fields.specialEvents.time",
        size: "medium",
        component: HseSelect,
        searchable: 'dropdown',
        timeArray: [],
        setTimeArray (array) {
            this.timeArray = array
                .map(item => {
                return {
                    value: item.Duration,
                    label: item.Duration,
                }
            })
            .sort((a, b) => {
                const hoursA = a.label.substr(0, 2)
                const minutesA = a.label.substr(3, 2)
                const hoursB = b.label.substr(0, 2)
                const minutesB = b.label.substr(3, 2)

                if (hoursA > hoursB) {
                    return 1
                }
                if (hoursA < hoursB) {
                    return -1
                }
                if (hoursA === hoursB) {
                    if (minutesA > minutesB) {
                        return 1
                    } else if (minutesA < minutesB) {
                        return -1
                    }
                    return 1
                }
            })
        },
        getAllOptions () {
            return new Promise((resolve) => {
                setTimeout(() => {
                    const times = this.timeArray
                    resolve(times)
                }, 100)
            })
        },
    },
}

Object.keys(fields).forEach((key) => {
    fields[key].setValues = function (isValid, value = '') {
        this.value = value
        this.isValid = isValid
    }
})

export default fields