<template>
  <div class="event-card">
    <Loading v-if="!isDataLoaded" />
    <div v-else class="card">
      <HseHeading size="h3">{{ $t("header") }}</HseHeading>
      <HseSpace size="small" vertical />
      <div class="two-cols">
        <ReadonlyInput :label="$t('fields.name.event')" :value="fullEventData.event.Title" />

        <ReadonlyInput :label="$t('requestNumber')" :value="fullEventData.numberRequest" />
      </div>

      <div class="two-cols">
        <ReadonlyInput :label="$t('created')" :value="dateFormat(fullEventData.CreatedOn)" />

        <ReadonlyInput v-if="status === null" :label="$t('status')" :value="$t('rateStatus')" />
        <ReadonlyInput v-else :label="$t('status')" :value="status" />
      </div>

      <div class="two-cols">
        <ReadonlyInput :label="$t('fioSpeaker')" :value="fioSpeakerText" />
      </div>

      <div class="two-cols">
        <!-- <ReadonlyInput v-if="lang === 'ru'" :label="$t('applicant')" :value="fullEventData.ApplicantLastName + ' ' + fullEventData.ApplicantFirstName" />
        <ReadonlyInput v-if="lang === 'en'" :label="$t('applicant')" :value="fullEventData.ApplicantLastNameEN + ' ' + fullEventData.ApplicantFirstNameEN" /> -->

        <ReadonlyInput v-if="lang === 'ru' && fullEventData.report.ReportTitle" :label="$t('reportTitle')"
          :value="fullEventData.report.ReportTitle" />
        <ReadonlyInput v-if="lang === 'en' || !fullEventData.report.ReportTitle" :label="$t('reportTitle')"
          :value="fullEventData.report.ReportTitleEN" />
      </div>

      <div class="two-cols">
        <ReadonlyInput v-if="lang === 'ru'" :label="$t('companyShortName')"
          :value="fullEventData.ApplicantCompanyShortName" />
        <ReadonlyInput v-if="lang === 'en'" :label="$t('companyShortName')"
          :value="fullEventData.ApplicantCompanyShortNameEn" />

        <div>
          <div class="hse-RichInput__label">{{ $t("files") }}</div>
          <a class="link file-link" v-if="fullEventData.report.ThesesFileID && lang === 'ru'"
            :href="`/files/download_file?id=${fullEventData.report.ThesesFileID}`">{{ $t("annotation") }}</a>
          <a class="link file-link" v-if="fullEventData.report.ThesesFileEnID"
            :href="`/files/download_file?id=${fullEventData.report.ThesesFileEnID}`">{{ $t("annotationEng") }}</a>
        </div>
      </div>

      <HseSpace size="small_2x" vertical />
      <HseSpace size="small_2x" vertical />

      <div v-if="fullEventData.report.coAuthors.length" :style="{ background: '#6B7A99', padding: '6px' }">
        <HseButton variant="tertiaryInvert" size="medium" v-on:click="isCoauthorsOpen = !isCoauthorsOpen">
          {{ $t("coauthors") }}
          <span class="ident" v-show="isCoauthorsOpen">&#9650;</span><span class="ident"
            v-show="!isCoauthorsOpen">&#9660;</span>
        </HseButton>
      </div>
      <div v-show="isCoauthorsOpen && fullEventData.report.coAuthors.length"
        :style="{ background: '#F0F2F5', padding: '6px' }">
        <div v-for="value in fullEventData.report.coAuthors" v-bind:key="value.id">
          <div class="two-cols-small" v-if="lang == 'ru'">
            <div>
              {{ value.data.public.LName }} {{ value.data.public.FName }}
              {{ value.data.public.SName }}
            </div>
            <div>{{ value.data.public.CompanyNameShort }}</div>
          </div>
          <div class="two-cols-small" v-else>
            <div>
              {{ value.data.public.FNameEN }} {{ value.data.public.LNameEN }}
            </div>
            <div>{{ value.data.public.CompanyNameShortEn }}</div>
          </div>
        </div>
      </div>

      <div v-if="fullEventData.report.coAuthors.length">
        <HseSpace size="small_2x" vertical />
        <HseSpace size="small_2x" vertical />
      </div>

      <Validator v-bind:field="fields.ExpertRate1" />
      <HseSpace size="small_2x" vertical />

      <Validator v-bind:field="fields.ExpertRate2" />
      <HseSpace size="small_2x" vertical />

      <Validator v-bind:field="fields.ExpertRate3" />
      <HseSpace size="small_2x" vertical />

      <Validator v-bind:field="fields.ExpertTotalResult" />
      <HseSpace size="small_2x" vertical />

      <Validator v-bind:field="fields.ExpertComment" />
      <HseSpace size="small_2x" vertical />

      <HseSpace size="small" vertical />

      <div class="cols-4">
        <div>
          <a class="link" :href="`/#/expert_requests`">{{ $t("back") }}</a>
        </div>
        <div v-if="rateResult === '35DAD1B8-9E57-455D-A9DF-61F7EED76429' ||
          rateResult === null
          ">
          <HseButton size="medium" v-on:click="save()">{{
            $t("rate")
          }}</HseButton>
        </div>
        <div v-if="rateResult === '35DAD1B8-9E57-455D-A9DF-61F7EED76429' ||
            rateResult === null
            ">
          <HseButton size="medium" v-on:click="draft()">{{
            $t("draft")
          }}</HseButton>
        </div>
        <div v-if="rateResult === '35DAD1B8-9E57-455D-A9DF-61F7EED76429' ||
            rateResult === null
            ">
          <HseButton size="medium" v-on:click="decline()">{{
            $t("decline")
          }}</HseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "../../api";
import Loading from "../common/Loading.vue";
import { HseHeading, HseSpace, HseButton } from "@hse-design/vue";
import Validator from "../common/form/Validator.vue";
import ReadonlyInput from "../common/ReadonlyInput.vue";
import fields from "./expertFields";
import { mapState } from "vuex";
import { localizeFields } from "../../i18n";
import { dateFormatter } from "../common/dateFormatter";

export default {
  components: {
    HseButton,
    HseHeading,
    HseSpace,
    Validator,
    ReadonlyInput,
    Loading,
  },
  data: () => ({
    fioSpeakerText: "",
    rateResult: "",
    eventId: "",
    isDataLoaded: false,
    fullEventData: {},
    status: "",
    statusId: "",
    fields,
    isCoauthorsOpen: false,
  }),
  created() {
    this.requestId = this.$route.params.id;
    this.requestFullEventData();
    this.requestEventData();
    this.translateFields();
  },
  methods: {
    fioSpeakerLang(lang) {
      if (lang == "ru") {
        this.fioSpeakerText =
          this.fullEventData.ApplicantLastName +
          " " +
          this.fullEventData.ApplicantFirstName +
          " " +
          this.fullEventData.ApplicantMiddleName;
      } else {
        this.fioSpeakerText =
          this.fullEventData.ApplicantLastNameEN +
          " " +
          this.fullEventData.ApplicantFirstNameEN;
      }
    },
    dateFormat(date) {
      return dateFormatter.format(date);
    },
    requestFullEventData() {
      api.request("requestGetFull", { id: this.requestId }).then((response) => {
        this.fullEventData = response;
        this.isDataLoaded = true;
        this.fioSpeakerLang(this.$store.state.lang);
      });
    },
    requestEventData() {
      api
        .request("expertReviewGet", {
          expertId: this.$store.state.user.id,
          requestId: this.requestId,
          lang: this.$store.state.lang,
        })
        .then((data) => {
          this.rateResult = data[0].TotalResultOptionId;
          this.status = data[0].TotalResultTitle;
          this.statusId = data[0].TotalResultOptionId;
          this.fields.ExpertRate1.value = String(data[0].Novelty ?? "");
          this.fields.ExpertRate2.value = String(data[0].Actuality ?? "");
          this.fields.ExpertRate3.value = String(data[0].Depth ?? "");
          if (
            data[0].TotalResultOptionId !=
            "5EAD88B8-7B2B-41C8-AC16-9081F4927B59" &&
            data[0].TotalResultOptionId !=
            "35DAD1B8-9E57-455D-A9DF-61F7EED76429"
          )
            this.fields.ExpertTotalResult.value = String(
              data[0].TotalResultOptionId ?? ""
            );
          this.fields.ExpertComment.value = data[0].Comment;
          this.isDataLoaded = true;
        });
    },
    save() {
      this.$emit("validateFields");
      let isFormInvalid = false;

      Object.keys(this.fields).some((key) => {
        isFormInvalid = this.fields[key].isValid === false;
        return isFormInvalid;
      });

      if (isFormInvalid) return;

      let comment =
        this.fields.ExpertComment.value == ""
          ? null
          : this.fields.ExpertComment.value;
      api
        .request("expertReviewUpdate", {
          id: this.requestId,
          novelty: this.fields.ExpertRate1.value,
          actuality: this.fields.ExpertRate2.value,
          depth: this.fields.ExpertRate3.value,
          comment,
          totalResultOptionId: this.fields.ExpertTotalResult.value,
        })
        .then(() => {
          this.$router.push("/expert_requests");
        });
    },
    decline() {
      api
        .request("expertReviewUpdate", {
          id: this.requestId,
          novelty: null,
          actuality: null,
          depth: null,
          comment: null,
          totalResultOptionId: "5EAD88B8-7B2B-41C8-AC16-9081F4927B59",
        })
        .then(() => {
          this.$router.push("/expert_requests");
        });
    },
    draft() {
      let comment = this.fields.ExpertComment.value
        ? this.fields.ExpertComment.value
        : "";
      api
        .request("expertReviewUpdateEx", {
          id: this.requestId,
          novelty: this.fields.ExpertRate1.value,
          actuality: this.fields.ExpertRate2.value,
          depth: this.fields.ExpertRate3.value,
          comment,
          totalResultOptionId: "35DAD1B8-9E57-455D-A9DF-61F7EED76429",
        })
        .then(() => {
          this.$router.push("/expert_requests");
        });
    },
    translateFields() {
      this.fields = localizeFields(fields, this.fields);
      this.requestFullEventData();
      this.requestEventData();
    },
  },
  computed: {
    ...mapState(["lang"]),
  },
  watch: {
    lang() {
      this.translateFields();
      this.fioSpeakerLang(this.$store.state.lang);
    },
  },
  i18n: {
    messages: {
      en: {
        header: "Rate request",
        back: "Back to list",
        rate: "Review",
        draft: "Save Draft",
        decline: "Skip Review",
        companyShortName: "Company short name",
        requestNumber: "Request number",
        created: "Created",
        status: "Conclusion",
        applicant: "Speaker",
        reportTitle: "Paper title",
        files: "Files",
        annotation: "Annotation",
        annotationEng: "Annotation (en)",
        rateStatus: "Under review",
        coauthors: "co-Authors",
        fioSpeaker: "Speaker`s full name",
      },
      ru: {
        header: "Оценить заявку",
        back: "К заявкам на оценку",
        rate: "Оценить",
        draft: "Сохранить черновик",
        decline: "Отказаться от оценки",
        companyShortName: "Краткое название организации",
        requestNumber: "Номер заявки",
        created: "Создана",
        status: "Заключение",
        applicant: "Докладчик",
        reportTitle: "Название доклада",
        files: "Файлы",
        annotation: "Аннотация",
        annotationEng: "Аннотация (англ)",
        rateStatus: "На рецензировании",
        coauthors: "Соавторы",
        fioSpeaker: "ФИО докладчика",
      },
    },
  },
};
</script>

<style scoped>
.two-cols {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.two-cols-small {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.file-link {
  display: block;
}

.event-card {
  background: #ffffff;
  border-radius: 12px;
  padding: 32px;
  max-width: 800px;
  /* width: calc(1220px - 320px); */
  width: calc(100vw - 320px);
  margin: 52px auto;
}

.big-item {
  max-width: 780px;
}

.link {
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.ident {
  margin-left: 10px;
}

.cols-4 {
  display: flex;
  justify-content: space-between;
  margin: 0 -5px;
  flex-wrap: wrap;
}

.cols-4>div {
  padding: 5px 5px;
}

@media (max-width: 768px) {

  .event-card {
    /* max-width: unset; */
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
  }

  .cols-4 {
    display: block;
  }

  .two-cols {
    grid-template-columns: 1fr;
    gap: 0;
  }
}
</style>
