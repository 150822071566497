var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('HseHeading',{attrs:{"size":"h3"}},[_vm._v(_vm._s(_vm.data.ReportTitle))]),_c('HseSpace',{attrs:{"size":"small","vertical":""}}),_c('ReadonlyInput',{attrs:{"label":_vm.$t('number'),"value":_vm.data.numberRequest}}),_c('ReadonlyInput',{attrs:{"label":_vm.$t('event'),"value":_vm.data.Title}}),_c('ReadonlyInput',{attrs:{"label":_vm.$t('format'),"value":_vm.data.ReportExist}}),_c('div',{staticClass:"two-cols"},[_c('ReadonlyInput',{attrs:{"isActive":(_vm.data.improvementStatus || _vm.data.FinalScore !== null) &&
        (_vm.data.Status === 'Оценена' || _vm.data.Status === 'Rated') &&
        _vm.data.participation === null,"label":_vm.$t('requestStatus'),"value":_vm.data.Status}}),(_vm.data.payment_status_int !== -2)?_c('ReadonlyInput',{attrs:{"label":_vm.$t('paymentStatus'),"value":_vm.paymentStatus}}):_vm._e()],1),_c('div',{staticClass:"two-cols"},[(_vm.data.FinalScore)?_c('ReadonlyInput',{attrs:{"isActive":_vm.data.FinalScore !== null &&
        (_vm.data.Status === 'Оценена' || _vm.data.Status === 'Rated') &&
        _vm.data.participation === null,"label":_vm.$t('reviewStatus'),"value":_vm.data.FinalScore}}):_vm._e(),(_vm.data.payment_status_int === -2)?_c('ReadonlyInputWithTooltip',{attrs:{"tooltipText":_vm.$t('infoPaymentDisabled'),"label":_vm.$t('paymentStatus'),"value":_vm.paymentStatus}}):_vm._e()],1),_c('div',{staticClass:"actions"},[(_vm.isCollectiveReq)?_c('div',[_c('HseSpace',{attrs:{"size":"small","vertical":""}}),_c('HseButton',{staticClass:"collectiveBtn",attrs:{"size":"medium"},on:{"click":_vm.confirmParticipation}},[_vm._v(_vm._s(_vm.$t("collectiveReq")))]),_c('HseSpace',{attrs:{"size":"small","vertical":""}})],1):_vm._e(),_c('div',[(_vm.isShowCertificate)?_c('div',[_c('a',{staticClass:"link",attrs:{"href":("/files/download_certificate?request=" + (_vm.data.Id) + "&lang=ru")}},[_vm._v(_vm._s(_vm.$t("certificateRu")))]),_c('br'),_c('a',{staticClass:"link",attrs:{"href":("/files/download_certificate?request=" + (_vm.data.Id) + "&lang=en")}},[_vm._v(_vm._s(_vm.$t("certificateEn")))])]):_vm._e(),_c('a',{staticClass:"link",attrs:{"href":("/#/requests/" + (_vm.data.Id))}},[_vm._v(_vm._s(_vm.$t("open")))]),_c('br'),(_vm.isEditReq)?_c('a',{staticClass:"link",attrs:{"href":("/#/requests/update/" + (_vm.data.Id))}},[_vm._v(_vm._s(_vm.$t("update")))]):_vm._e()]),_c('div',[(
          _vm.isPaymentEnabled &&
          !(this.data.Status === 'Отменена заявителем' && _vm.data.ReportExist ==='без доклада'))?_c('a',{staticClass:"link boldLink",attrs:{"href":("/#/requests/" + (_vm.data.Id) + "/payment")}},[_vm._v(_vm._s(_vm.$t("payment")))]):_vm._e(),(
          _vm.data.ReportExist !== 'без доклада' &&
          _vm.data.ReportExist !== 'Without a report' &&
          _vm.data.collectiveRequestId &&
          _vm.isPaymentEnabled
        )?_c('br'):_vm._e(),(
          _vm.data.ReportExist !== 'без доклада' &&
          _vm.data.ReportExist !== 'Without a report' &&
          _vm.data.collectiveRequestId
        )?_c('a',{staticClass:"link",attrs:{"href":("/#/collective-requests/" + (_vm.data.collectiveRequestId))}},[_vm._v(_vm._s(_vm.$t("collectiveReqOpen")))]):_vm._e(),_c('br'),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.data.currentCoAuthor === true &&
          _vm.data.coAuthorPassportId === null &&
          _vm.IsRequiredPassport
        ),expression:"\n          data.currentCoAuthor === true &&\n          data.coAuthorPassportId === null &&\n          IsRequiredPassport\n        "}],staticClass:"link",on:{"mouseup":function () { return _vm.ClickAddPassport(_vm.data.Id, _vm.data.EventId); }}},[_vm._v(_vm._s(_vm.$t("pd")))])])]),_c('HseModal',{attrs:{"shouldCloseOnOverlayClick":false},model:{value:(_vm.modalPassport),callback:function ($$v) {_vm.modalPassport=$$v},expression:"modalPassport"}},[_c('ModalPassport',{attrs:{"fields":_vm.fields},on:{"closeModal":_vm.closeModalPassport}})],1),_c('HseModal',{attrs:{"shouldCloseOnOverlayClick":false},model:{value:(_vm.modalConfirmParticipationIsOpen),callback:function ($$v) {_vm.modalConfirmParticipationIsOpen=$$v},expression:"modalConfirmParticipationIsOpen"}},[_c('ModalConfirmParticipation',{attrs:{"confirmationStatus":_vm.confirmationStatus,"confirmationMessage":_vm.confirmationMessage,"spinner":_vm.buttonSpinner,"field":Object.assign({}, _vm.field, {setValues: this.setField}),"collectiveRequestDirection":_vm.collectiveRequestDirection,"requestId":_vm.requestId},on:{"closeModal":_vm.closeModalParticipation,"getBackIfErr":_vm.getBackIfErr,"approveMember":_vm.approveMember}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }