<template>
  <div class="form">
    <div class="subheading">{{ $t('personal') }}</div>
    <HseSpace size="small" vertical />
    <div class="cols two-cols">
      <ReadonlyInput  v-if="lang=='ru'" :label="`${$t('fields.name.lName')} *`" :value="user.LName" />
      <ReadonlyInput v-if="lang=='ru'" :label="`${$t('fields.name.fName')} *`" :value="user.FName" />
      <ReadonlyInput v-if="lang=='ru'" :label="`${$t('fields.name.mName')}`" :value="user.SName" />
      <ReadonlyInput v-if="lang=='en'" :label="`${$t('fields.name.fNameEn')}`" :value="user.FNameEN" />
      <ReadonlyInput v-if="lang=='en'" :label="`${$t('fields.name.lNameEn')}`" :value="user.LNameEN" />
      <ReadonlyInput :label="`${$t('fields.name.email')} *`" :value="$store.state.user.login" />
    </div>
    <HseSpace size="small" vertical />
    <HseSpace size="small" vertical />
    <div class="cols two-cols">
      <div class="field">
        <Validator :field="fields.direction" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field" v-show="isLangRu">
        <Validator :field="fields.sessionName" />
      </div>
      <div class="field">
        <Validator :field="fields.sessionNameEn" />  
      </div>
    </div>
    <Validator :field="fields.isModerator" />
 </div>
</template>

<script>
  import { store } from '../../store'
  import { HseSpace } from '@hse-design/vue'
  import Validator from '../common/form/Validator.vue'
  import ReadonlyInput from '../common/ReadonlyInput.vue'
  import { mapState } from 'vuex'
  export default {
    name: 'FormPage1',
    props: ['fields', 'eventId'],
    store,
    components: {
      Validator,
      HseSpace,
      ReadonlyInput,
    },
    data: () => ({
      user: {},
      pageFieldKeys: ['direction', 'sessionName', 'sessionNameEn'],
      isCountryRu: false,
    }),
    computed: {
      isLangRu () {
        return this.$store.state.lang === 'ru'
      },
      fieldsToValidate () {
        return this.pageFieldKeys.filter((key) => {
          if (!this.isLangRu && key === 'sessionName') {
            return false
          }
          return true
        })
      },
      ...mapState(['lang']),
    },
    created () {
      this.user = this.$store.state.user.data.public
      this.$emit('setUserData', {
        lastName: this.user.LName,
        firstName: this.user.FName,
        secondName: this.user.SName,
        lastNameEn: this.user.LNameEN,
        firstNameEn: this.user.FNameEN,
        email: this.$store.state.user.login,
      })
      this.$parent.$on('validatePage', ({ current }) => {
        if (current === 1) {
          this.$emit('validateFields')
          this.checkPageValidity()
        }
      })
      this.$parent.$on('fieldsValChange', () => {
        this.$emit('validateFields')
      })
    },
    methods: {
      checkPageValidity () {
        const isPageValid = !this.fieldsToValidate.some(key => {
          return !this.fields[key].isValid
        })
        this.$emit('validatePage', { page: 1, isValid: isPageValid })
      },
    },
    i18n: {
      messages: {
        en: {
          personal: 'Personal data',
        },
        ru: {
          personal: 'Персональные данные',
        },
      }
    },
  }
</script>

<style scoped>
  .form {
    max-width: 670px;
  }
  .cols {
    display: flex;
    margin: 0 -15px;
    flex-wrap: wrap;
  }
  .cols>.field {
    padding: 0 15px;
    flex: 1;
  }
  .two-cols .field {
    max-width: 50%;
    width: 50%;
    flex-basis: 50%;
  }
  .three-cols .field {
    max-width: 33.3%;
  }
  @media (max-width: 767px) {
    .form {
      max-width: unset;
    }
    .two-cols .field, .three-cols .field {
      max-width: 100%;
      width: 100%;
      flex-basis: 100%;
    }
  }
  .flex-tooltip {
    display: flex;
  }
  .flex-tooltip>div:first-child {
    width: calc(100% - 28px)
  }
  .flex-tooltip>div:last-child {
    padding: 36px 0 0 12px;
  }
</style>
