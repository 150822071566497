<template>
  <div class="auth">
    <LoginOptions
      v-if="state === 'loginOptions'"
      :state="state"
      v-on:changeState="changeState"
    />
    <Login
      v-else-if="state === 'login'"
      :state="state"
      v-on:changeState="changeState"
    />
    <Code
      v-else-if="state === 'code'"
      :state="state"
      v-on:changeState="changeState"
    />
    <Forgot
      v-else-if="state === 'forgot'"
      :state="state"
      v-on:changeState="changeState"
    />
    <RegisterUser
      v-else-if="state === 'register'"
      :state="state"
      v-on:changeState="changeState"
    />
  </div>
</template>

<script>
import RegisterUser from './RegisterUser'
import LoginOptions from './LoginOptions'
import Login from './Login.vue'
import Code from './Code.vue'
import Forgot from './Forgot'

export default {
  name: 'Auth',
  data: () => ({
    state: 'loginOptions',
    code: '',
  }),
  components: {
    LoginOptions,
    Login,
    Code,
    Forgot,
    RegisterUser,
  },
  mounted () {
    if (this.$route.path !== '/') {
      this.$router.push('/')
    }
  },
  methods: {
    changeState ({state, code}) {
      this.state = state
      if (code) {
        this.code = code
      }
    }
  }
}
</script>

<style>
</style>
