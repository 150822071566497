<template>
  <div class="login">
    <Logos />
    <HseSpace size='small' vertical />
    <form class="form" @submit="loginReq">
      <Validator v-bind:field="fields.login" v-on:change="onLoginChange" />
      <HseSpace size='small' vertical />
      <Validator v-bind:field="fields.password" v-on:change="onPasswordChange" ref="password" />
      <HseSpace size='large' vertical />
      <HseButton v-on:click="loginReq">{{ $t('auth.login') }}</HseButton>
      <HseSpace size='small_2x' vertical />
      <div class="links">
          <span class="link" v-on:click="() => setState('forgot')">{{ $t('auth.forgot') }}</span>
      </div>
        <div class="links">
          <span class="link" v-on:click="() => setState('loginOptions')">{{ $t('back') }}</span>
      </div>
    </form>
  </div>
</template>

<script>
import { api } from '../../../api'
import { HseButton, HseSpace } from '@hse-design/vue'
import Validator from '../form/Validator'
import fields from './loginFields'
import { mapState } from 'vuex'
import { localizeFields } from '../../../i18n'
import Logos from './Logos.vue'

export default {
  name: 'Login',
  props: ['state'],
  data: () => ({
    fields,
  }),
  components: {
    HseButton,
    HseSpace,
    Validator,
    Logos,
  },
  methods: {
    loginReq(e) {
      e ? e.preventDefault() : null

      api.login(this.fields.login.value, this.fields.password.value).then(() => {
        this.$emit('validateFields')
      })
    },
    setState(state) {
      this.$emit('changeState', { state })
    },
    onLoginChange() {
      if (this.$store.state.error && this.$store.state.error.code === 7) {
        this.$store.state.error.code = null
      }
    },
    onPasswordChange() {
      if (this.$store.state.error && this.$store.state.error.code === 3) {
        this.$store.state.error.code = null
      }
    },
    translateFields() {
      this.fields = localizeFields(fields, this.fields)
    }
  },
  computed: {
    getRoles() {
      return this.$store.state
    },
    ...mapState(['lang'])
  },
  watch: {
    lang() {
      this.translateFields()
    }
  },
  mounted() {
    // инпуту для пароля задаём соответствующий тип
    const passInput = this.$refs.password.$el.querySelector('input')
    passInput.setAttribute('type', 'password')
    this.translateFields()
  },
}
</script>

<style scoped>
.login {
  width: 340px;
  background: #FFFFFF;
  border-radius: 12px;
  margin: 53px auto;
  padding: 24px 30px;
}

.links {
  text-align: center;
}

.link {
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  text-decoration-line: underline;
  color: #0060CF;
  clear: both;
}

.hse-Button {
  width: 100% !important;
}

@media (max-width: 767px) {
  .login {
    margin: 16px auto;
  }
}

@media (max-width: 374px) {
  .login {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
  }
}
</style>
