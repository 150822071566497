<template>
  <div>
    <HseModalForm v-model="isOpen" :modal-style="{ maxHeight: '200px' }">
      <template #actions>
        <div class="cols-2">
        <HseButton :spinner="isLoading" @click="send_ok">
          {{ $t('yes') }}
        </HseButton>
        <HseButton :spinner="isLoading" @click="send_no" color="red">
          {{ $t('no') }}
        </HseButton>
        </div>
      </template>
      
      <div class="content">
        <p>{{text}}</p>
      </div>
    </HseModalForm>
  </div>
</template>

<script>

  import { HseModalForm, HseButton } from '@hse-design/vue' 

  export default {
    props: ['text', 'openMessage', 'responseMessage'],
    components: {
      HseModalForm,
      HseButton,
    },
    data: () => ({
      isOpen: false,
      isLoading: false,
    }),
    created () {
      this.$parent.$on(this.openMessage, () => {this.isOpen = true})
    },
    methods: {
      send_ok() {
            this.isLoading = true;
            this.$emit(this.responseMessage, true)
            setTimeout(() => {
              this.isOpen = false;
              this.isLoading = false;
            }, 100);
          },
      send_no() {
            this.isLoading = true;
            this.$emit(this.responseMessage, false)
            setTimeout(() => {
              this.isOpen = false;
              this.isLoading = false;
            }, 100);
          },
    },
    i18n: {
      messages: {
        en: {
          yes: 'Yes',
          no: 'No',
        },
        ru: {
          yes: 'Да',
          no: 'Нет',
        },
      }
    }
  }
</script>

<style scoped>
  .content {
    min-height: 80px;
    margin: 52px auto;
  }
  .big-item {
    max-width: 780px;
  }
  .link {
    white-space: nowrap;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  .cols-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .pull-right {
    float:right;
  }
</style>