import { HseSelect } from '@hse-design/vue'

const fields = {
  PartisipationConfirmationStatus: {
    value: '',
    isValid: false,
    isRequired: true,
    label: 'fields.request.confirm',
    size: "large",
    component: HseSelect,
    selectData: [
      { value: 'null', label: 'Решение не принято' },
      { value: 'true', label: 'Подтверждаю свое участие в мероприятии' },
      { value: 'false', label: 'Не подтверждаю свое участие в мероприятии' },
    ],
    getAllOptions () {
      return new Promise((resolve) => {
          resolve(this.selectData)
      })
    },
  },
  PresentationFileId: {
    label: 'fields.request.presentationFile',
    isRequired: false,
    value: '',
    isValid: false,
    maxSizeMb: 3,
    formats: ['ppt', 'pptx', 'pdf']
  },
}

Object.keys(fields).forEach((key) => {
  fields[key].setValues = function (isValid, value = '') {
    this.value = value
    this.isValid = isValid
  }
})

export default fields