<template>
  <div class="visa-card">
    <HseHeading size="h3">{{ $t('caption') }}</HseHeading>
    <HseSpace size='small' vertical />
    <div class="field">
      <Validator @change="onRequestChange" v-bind:field="fields.request" />
      <HseSpace size='small_2x' vertical />
    </div>
    <div class="form">
      <div class="field">
        <Validator v-bind:field="fields.LName" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.FName" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div v-show="lang == 'ru'" class="field">
        <Validator v-bind:field="fields.SName" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.gender" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.phoneNumber" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.address" />
        <HseSpace size='small_2x' vertical />
      </div>
    </div>
    <div class="form">
      <div class="field">
        <Datepicker v-bind:field="fields.birthDate" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.birthCountryID" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.birthCity" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.citizenshipID" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.placeOfWork" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.position" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.workAddress" />
        <HseSpace size='small_2x' vertical />
      </div>
    </div>
    <div class="form">
      <div class="field">
        <Validator v-bind:field="fields.passportNumber" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.issuingAuthority" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <FileUploader :field="fields.passport_file_id" />
        <HseSpace size='small_2x' vertical />
      </div>
    </div>
    <div class="form">
      <div class="field">
        <Datepicker v-bind:field="fields.passportDateIssue" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <Datepicker v-bind:field="fields.passportDateExpire" />
        <HseSpace size='small_2x' vertical />
      </div>
    </div>
    <div class="form">
      <div class="field">
        <Datepicker :field="fields.durationFrom" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <Datepicker v-bind:field="fields.durationTo" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.citiesOfVisit" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.countryObtainVisaId" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.russianConsulateCity" />
        <HseSpace size='small_2x' vertical />
      </div>
    </div>
    <div class="form">
      <div class="field">
        <Validator v-bind:field="fields.emailAddress" />
        <HseSpace size='small_2x' vertical />
      </div>
    </div>
    <div class="field">
      <Validator v-bind:field="fields.isAgree" />
      <span class="link" v-on:mouseup="() => showPersonal()">{{ $t('pd') }}</span>
      <HseSpace size='small_2x' vertical />
    </div>
    <HseSpace size='medium' vertical />
    <div class="form-footer">
      <div class="actions">
        <HseButton v-on:click="sendReq" :spinner="registerButtonSpinner" spinnerPosition="left">
          <span v-if="registerButtonSpinner === false">{{ $t('send') }}</span>
          <span v-else-if="registerButtonSpinner === true">{{ $t('registering') }}</span>
          <span v-else-if="registerButtonSpinner === 'done'">{{ $t('auth.btnDone') }}</span>
        </HseButton>
        <HseButton v-on:click="cancel" spinnerPosition="left" variant="secondary">{{ $t('cancel') }}</HseButton>
      </div>
      <div class="form-description">{{ $t('fields.requiredFields') }}</div>
    </div>

    <PersonalDataModalVisa :eventId="eventId" />
  </div>
</template>

<script>
import { HseHeading, HseSpace, HseButton } from '@hse-design/vue'
import { api } from '../../api'
import { mapState } from 'vuex'
import Validator from '../common/form/Validator.vue'
import FileUploader from '../common/form/FileUploader.vue'
import Datepicker from '../common/form/Datepicker.vue'
import fields from '../../pages/visaFields'
import PersonalDataModalVisa from '../common/modal/PersonalDataModalVisa.vue'
import { localizeFields } from '../../i18n'

export default {
  components: {
    HseHeading,
    HseSpace,
    HseButton,
    Validator,
    FileUploader,
    Datepicker,
    PersonalDataModalVisa,
  },
  data: () => ({
    eventId: null,
    fields,
    registerButtonSpinner: false,
    fieldsCompare: {
      LName: 'LName',
      FName: 'FName',
      SName: 'SName',
      phoneNumber: 'Phone',
      citizenshipID: 'CountryId',
      birthCountryID: 'CountryId',
      birthCity: 'City',
      placeOfWork: 'CompanyNameFull',
      position: 'Position',
      gender: 'Gender',
    },
  }),
  created() {
    this.setFieldsCompare()
    this.fillFieldsWithUData()
    this.translateFields()
  },
  computed: {
    isLangRu() {
      return this.lang === 'ru'
    },
    ...mapState(['lang'])
  },
  watch: {
    lang() {
      this.translateFields()
    }
  },
  methods: {
    async onRequestChange(id) {
      const [{ EventId }] = await api.request('requestGet', { id })
      const visaAgreement = await api.request('get_visa_agreements', { eventId: EventId })
      this.$store.commit('setVisaAgreement', visaAgreement)
      this.eventId = visaAgreement.eventId
    },
    fillFieldsWithUData() {
      Object.keys(this.fieldsCompare).forEach(key => {
        const userField = this.fieldsCompare[key]
        this.fields[key].setValues(false, this.$store.state.user.data.public[userField])
      })
      this.fields.emailAddress.setValues(false, this.$store.state.user.login)
    },
    setFieldsCompare() {
      const names = ['LName', 'FName']
      if (!this.isLangRu) {
        names.forEach(field => {
          this.fieldsCompare[field] = `${field}EN`
        })
        this.fieldsCompare.SName = ''
      } else {
        names.forEach(field => {
          this.fieldsCompare[field] = field
        })
        this.fieldsCompare.SName = 'SName'
      }
    },
    translateFields() {
      this.setFieldsCompare();

      ['LName', 'FName', 'SName'].forEach(field => {
        const userField = this.fieldsCompare[field]
        this.fields[field].setValues(false, this.$store.state.user.data.public[userField])
      })

      this.fields = localizeFields(fields, this.fields)
    },
    cancel() {
      this.$router.go(-1);
    },
    showPersonal() {
      this.$emit('openPersonslDataModal')
    },
    sendReq() {
      this.$emit('validateFields')
      let isFormInvalid = false
      Object.keys(this.fields).some(key => {
        isFormInvalid = this.fields[key].isValid === false
        return isFormInvalid
      })


      if (isFormInvalid) return

      this.registerButtonSpinner = true

      const data = {}
      Object.keys(this.fields).forEach(key => {
        const field = this.fields[key]
        data[key] = field.value
        if (['passportDateIssue', 'passportDateExpire', 'durationFrom', 'durationTo', 'birthDate'].includes(key)) {
          let dateObj
          if (field.value.includes('-')) {
            dateObj = new Date(field.value).toISOString().split('T')[0]
          } else {
            dateObj = field.value.split('.').reverse().join('-')
          }
          data[key] = dateObj
        }
      })

      api.request('request.visa', data).then(result => {
        if (result) this.registerButtonSpinner = 'done'
        setTimeout(() => {
          this.registerButtonSpinner = false
          this.$router.push('/visas')
          this.$router.go(this.$router.currentRoute)
        }, 2000)
      })
    },
  },
  i18n: {
    messages: {
      en: {
        caption: 'Visa support',
        send: 'Send a request',
        registering: 'Sending',
        pd: 'Personal data processing agreement'
      },
      ru: {
        caption: 'Визовая поддержка',
        send: 'Отправить заявку',
        registering: 'Отправляем',
        pd: 'Согласие на обработку персональных данных',
      },
    }
  }
}
</script>

<style scoped>
.link {
  font-size: 80%;
  cursor: pointer;
  color: blue;
}

.visa-card {
  background: #FFFFFF;
  border-radius: 12px;
  margin: 53px auto;
  width: fit-content;
  padding: 32px 32px;
  max-width: 960px;
  width: calc(100vw - 168px);
}

.visa-card .form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 -16px;
}

.visa-card .form .field {
  padding: 0 16px;
  min-width: 0;
}

.subheading {
  font-family: 'Work Sans', sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #0F0F14;
}

.actions .hse-Button {
  display: inline-block;
}

.hse-Button:first-child {
  margin-right: 12px;
}

.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.form-description {
  padding-top: 12px;
  font-size: 14px;
  line-height: 20px;
  color: #6B7A99;
}

@media (max-width: 1023px) {
  .visa-card {
    margin: 16px auto;
    max-width: 95%;
    width: 100%;
  }

  .visa-card .form {
    grid-template-columns: repeat(2, 1fr);
  }

  .emptyfield {
    display: none;
  }
}

@media (max-width: 767px) {
  .visa-card {
    margin: 0 auto;
    max-width: 100%;
  }

  .visa-card .form {
    grid-template-columns: repeat(1, 1fr);
  }

  .form-footer {
    flex-wrap: wrap;
  }
}</style>
<style>.smallLabel {
  font-size: 14px !important;
}</style>