<template>
  <HseModalForm v-model="isOpen">   
    <div class="content">
      <HseHeading size="h3">{{$t('header')}}</HseHeading>
      <HseSpace size='small_2x' vertical />
          <div :key="option.value" v-for="option in options">
              <HseRadio
              v-model="radioValue"
              :value="option.value"
              :label="option.label"
              />
              <HseSpace size="small_2x" vertical />
          </div>
    </div>

    <template #actions>
      <HseButton @click="submit">{{$t('ok')}}</HseButton>
          <HseSpace size="small_2x" horizontal />
          <HseButton @click="cancel">{{$t('cancel')}}</HseButton>
    </template> 

  </HseModalForm>
</template>

<script>
  import { HseModalForm, HseButton, HseSpace, HseHeading, HseRadio } from '@hse-design/vue'

  export default {
    components: {
      HseModalForm,
      HseButton,
      HseSpace,
      HseHeading,
      HseRadio,
    },
    data: () => ({
      isOpen: false,
      radioValue: 'Online',
    }),
    created () {
        this.$root.$refs.specialEventModal = this
    },
    methods: {
        open() {
            this.isOpen = true
        },
        submit() {
            const res = this.$parent.requestSpecialEventMembership(this.radioValue)
            if (res) {
              this.radioValue = 'Online'
            } else {
              this.isOpen = false
            }
        },
        cancel() {
            this.isOpen = false
        },
    }, 
    computed: {
        options() {
            return [{value: 'Online', label: this.$i18n.tc('online')}, {value: 'Offline', label: this.$i18n.tc('offline')}]
        }
    },
    i18n: {
      messages: {
        en: {
            header: 'Specify your preferred format for participation in the event',
            online: 'I will take part online',
            offline: 'I will attend this event personally',
            ok: 'Ok',
            cancel: 'Cancel',
        },
        ru: {
            header: 'Укажите предпочитаемый формат участия',
            online: 'Приму участие онлайн',
            offline: 'Приму участие очно',
            ok: 'Ок',
            cancel: 'Отмена',
        },
      }
    } 
  }
</script>

<style scoped>
    .content {
        min-height: 200px;
    }
</style>