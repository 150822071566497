import { HseInput, HseCheckbox, HseSelect } from "@hse-design/vue";
import { resourceLoader } from "../common/resourceLoader";
import { store } from "../../store";
const fields = {
  isAgree: {
    value: false,
    isValid: false,
    hideTopLabel: true,
    label: "fields.name.pdAgreement",
    classes: { label: "smallLabel" },
    component: HseCheckbox,
    rules: [
      {
        name: "custom",
        message: "fields.rule.pdAgreement",
        validateVal: (value) => value,
      },
    ],
  },

  lastName: {
    value: "",
    isValid: false,
    label: "fields.name.lName",
    isRequired: true,
    size: "large",
    component: HseInput,
  },
  firstName: {
    value: "",
    isValid: false,
    label: "fields.name.fName",
    isRequired: true,
    size: "large",
    component: HseInput,
  },
  secondName: {
    value: "",
    isValid: true,
    label: "fields.name.mName",
    size: "large",
    component: HseInput,
  },
  lastNameEn: {
    value: "",
    isValid: false,
    label: "fields.name.lNameEn",
    isRequired: true,
    size: "large",
    component: HseInput,
    rules: [
      {
        name: "latin",
      },
    ],
  },
  firstNameEn: {
    value: "",
    isValid: false,
    label: "fields.name.fNameEn",
    isRequired: true,
    size: "large",
    component: HseInput,
    rules: [
      {
        name: "latin",
      },
    ],
  },
  email: {
    value: "",
    isValid: false,
    label: "fields.name.email",
    isRequired: true,
    size: "large",
    component: HseInput,
    rules: [
      {
        name: "email",
      },
      {
        name: "custom",
        message: "fields.collective.alreadyAdded",
        validateVal() {
          return !store.state.error || store.state.error.code !== 876;
        },
      },
    ],
  },
  countryId: {
    value: "",
    isValid: true,
    label: "fields.name.citizenship",
    isRequired: true,
    size: "large",
    searchable: "dropdown",
    component: HseSelect,
    getAllOptions(search) {
      return resourceLoader.load("countriesList", null, [], search);
    },
  },
  organizationName: {
    value: "",
    isValid: true,
    label: "fields.name.companyShort",
    isRequired: true,
    size: "large",
    component: HseInput,
  },
  organizationNameEn: {
    value: "",
    isValid: true,
    label: "fields.name.companyShortEn",
    isRequired: true,
    size: "large",
    component: HseInput,
    rules: [
      {
        name: "latin",
      },
    ],
  },
  HSEMember: {
    value: false,
    isValid: true,
    hideTopLabel: true,
    label: "fields.request.isHSEPresenter3rd",
    size: "large",
    component: HseCheckbox,
    classes: { label: "smallLabel" },
  },
  campusId: {
    value: "",
    isValid: true,
    label: "fields.request.campus",
    isRequired: true,
    size: "large",
    component: HseSelect,
    getAllOptions(search) {
      return resourceLoader.load("campusList", null, [], search);
    },
  },
  participationFormatId: {
    value: "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A",
    isValid: true,
    options: [],
    getAllOptions() {
      return resourceLoader.load("get_org_formats", null, []).then((res) => {
        this.options = res
          .filter((item) => item.Id !== "895BB6AB-A343-48DC-AA19-7EAB348C8C0D")
          .map((opt) => {
            const label = store.state.lang === "ru" ? opt.Name : opt.NameEn;
            return { label, value: opt.Id };
          });
      });
    },
  },
  Passport: {
    label: "fields.honoraryOrInvitedReport.passport",
    value: "",
    isValid: false,
    isRequired: true,
    maxSizeMb: 3,
    formats: ["doc", "docx", "rtf", "pdf", "jpg", "png", "zip", "rar", "7z"],
  },
};

Object.keys(fields).forEach((key) => {
  fields[key].setValues = function (isValid, value = "") {
    this.value = value;
    this.isValid = isValid;
  };
});

export default fields;
