<template>
  <div class="event-card">
    <Loading v-if="!isDataLoaded" />
    <div v-else class="card">
      <ReadonlyInput v-if="req.report.QuasiIdentifier" :label="$t('quasiIdentifier')"
        :value="req.report.QuasiIdentifier" />
      <ReadonlyInput :label="$t('number')" :value="req.numberRequest" />
      <div v-if="lang == 'ru'" class="three-cols">
        <div>
          <ReadonlyInput :label="$t('LName')" :value="req.ApplicantLastName" />
        </div>
        <div>
          <ReadonlyInput :label="$t('FName')" :value="req.ApplicantFirstName" />
        </div>
        <div>
          <ReadonlyInput :label="$t('SName')" :value="req.ApplicantMiddleName" />
        </div>
      </div>
      <div v-else class="two-cols">
        <div>
          <ReadonlyInput :label="$t('FName')" :value="req.ApplicantFirstNameEN" />
        </div>
        <div>
          <ReadonlyInput :label="$t('LName')" :value="req.ApplicantLastNameEN" />
        </div>
      </div>

      <div class="two-cols">
        <div>
          <ReadonlyInput label="EMail" :value="req.ApplicantEmail" />
        </div>
        <div>
          <ReadonlyInput :label="$t('phone')" :value="req.ApplicantPhone" />
        </div>
      </div>
      <div v-if="lang == 'ru'">
        <div>
          <ReadonlyInput :label="$t('companyShortName')" :value="req.ApplicantCompanyShortName" />
        </div>
      </div>
      <div v-if="lang == 'en'">
        <div>
          <ReadonlyInput :label="$t('companyShortName')" :value="req.ApplicantCompanyShortNameEn" />
        </div>
      </div>

      <div v-if="req_ro.report.Direction1">
        <div>
          <ReadonlyInput v-if="lang === 'ru'" :label="$t('fields.request.direction1')"
            :value="req_ro.report.Direction1.Title" />
        </div>
        <div>
          <ReadonlyInput v-if="lang === 'en'" :label="$t('fields.request.direction1')"
            :value="req_ro.report.Direction1.TitleEN" />
        </div>
      </div>
      <div v-if="req_ro.report.Direction2">
        <div>
          <ReadonlyInput v-if="lang === 'ru'" :label="$t('fields.request.direction2')"
            :value="req_ro.report.Direction2.Title" />
        </div>
        <div>
          <ReadonlyInput v-if="lang === 'en'" :label="$t('fields.request.direction2')"
            :value="req_ro.report.Direction2.TitleEN" />
        </div>
      </div>
      <div>
        <div>
          <Validator @change="changeFinalDirection" width="full" size="large" :field="fields.finalDirection"
            :value="req_ro.report.Direction1.Id" />
        </div>
      </div>

      <div v-if="req.ReportExists == true">
        <ReadonlyInput v-if="(req.report.ReportTitle !== '') && (req.report.ReportTitle !== null) && (lang == 'ru')"
          :label="$t('topic')" :value="req.report.ReportTitle" />
        <ReadonlyInput v-else :label="$t('topic')" :value="req.report.ReportTitleEN" />
        <ReadonlyInput :label="$t('status')" :value="req_ro.StatusName" />
        <div>
          <div class="two-cols flex-reverse">
            <div>
              <a v-if="(req.report.ThesesFileID !== null) && (lang == 'ru')" class="link"
                :href="`/files/download_file?id=${req.report.ThesesFileID}`">{{ $t('annotaion') }}</a><br>
              <a v-if="req.report.ThesesFileEnID !== null" class="link"
                :href="`/files/download_file?id=${req.report.ThesesFileEnID}`">{{ $t('annotaionEn') }}</a>
            </div>
            <div class="prepull" v-if="req_ro.StatusId !== '18F93418-06F1-4827-9F94-DB0D9CEC6DC2'">
              <HseButton class="pull-right" size="medium" v-on:click="save()">{{ $t('changeStatus') }}</HseButton>
            </div>
          </div>
        </div>
        <HseSpace size='small_2x' vertical />

        <!-- Таблица соавторов -->
        <div v-if="isCoauthors" :style="{ background: '#6B7A99', padding: '6px' }">
          <HseButton variant='tertiaryInvert' size='medium' v-on:click="isCoauthorsOpen = !isCoauthorsOpen">
            {{ $t('coauthors') }} <span class="ident" v-show="isCoauthorsOpen">&#9650;</span><span class="ident"
              v-show="!isCoauthorsOpen">&#9660;</span>
          </HseButton>
        </div>
        <div v-show="isCoauthorsOpen && isCoauthors" :style="{ background: '#F0F2F5', padding: '6px' }">
          <div v-for="value in req.report.coAuthors" v-bind:key="value.id">
            <div class="two-cols" v-if="lang == 'ru'">
              <div>{{ value.data.public.LName }} {{ value.data.public.FName }} {{ value.data.public.SName }} ( {{
                value.login }} )</div>
              <div>{{ value.data.public.CompanyNameShort }}</div>
            </div>
            <div class="two-cols" v-else>
              <div>{{ value.data.public.FNameEN }} {{ value.data.public.LNameEN }} ( {{ value.login }} )</div>
              <div>{{ value.data.public.CompanyNameShortEn }}</div>
            </div>

          </div>
        </div>

        <HseSpace size='small_2x' vertical />

        <!-- Таблица экспертов -->
        <div
          v-if="req_ro.StatusId === '23FC9A19-462F-406D-8D18-DE13A6A6B0E0' || req_ro.StatusId === '18F93418-06F1-4827-9F94-DB0D9CEC6DC2' || req_ro.StatusId === 'CB86736A-02F2-4B9F-9DE8-946791D6FBDB'">
          <div class="flex" :style="{ background: '#6B7A99', padding: '6px' }">
            <HseButton variant='tertiaryInvert' size='medium' v-on:click="isExpertsOpen = !isExpertsOpen">
              {{ $t('experts') }} <span class="ident" v-show="isExpertsOpen">&#9650;</span><span class="ident"
                v-show="!isExpertsOpen">&#9660;</span>
            </HseButton>
            <HseSpace size="small_2x" horizontal />
            <div :style="{ display: 'flex' }" v-show="isExpertsOpen">
              <HseButton
                v-if="req_ro.StatusId != '26221A69-FE87-4A64-8D98-080B02C04169' && req_ro.StatusId != '96F18960-F327-419C-B1FA-D4CFA3FD5103' && req_ro.StatusId != 'FF0D452C-672E-4F98-9E4D-53E9B313D3EB'"
                size="medium" v-on:click="set_expert_dialog()">{{ $t('set') }}</HseButton>
            </div>
          </div>
          <div v-show="isExpertsOpen" :style="{ background: '#F0F2F5', padding: '6px' }">

            <div>
              <div v-for="value in experts" v-bind:key="value.id">
                <div class="three-cols">
                  <p v-if="lang == 'ru'">{{ value.data.public.LName }} {{ value.data.public.FName }} {{
                    value.data.public.SName }} </p>
                  <p v-else>{{ value.data.public.FNameEN }} {{ value.data.public.LNameEN }}</p>
                  <div>
                    <small>{{ $t('Novelty') }}: {{ value.Novelty }} </small><br>
                    <small>{{ $t('Actuality') }}: {{ value.Actuality }} </small><br>
                    <small>{{ $t('Depth') }}: {{ value.Depth }} </small><br>
                    {{ $t('TotalResult') }}: {{ value.TotalResult }} <br>
                    {{ $t('TotalResultTitle') }}: {{ value.TotalResultTitle }} <br>
                    {{ $t('Comment') }}: {{ value.Comment }}<br>
                  </div>
                  <div>
                    <HseButton class="pull-right" size="small" color="red" v-on:click="remove_expert(value.id)">
                      {{ $t('remove') }}</HseButton>
                  </div>
                </div>
                <HseDivider />
              </div>
            </div>
          </div>
        </div>

        <HseSpace size='small_2x' vertical />

        <div class="flex" v-if="req_ro.StatusId != '26221A69-FE87-4A64-8D98-080B02C04169'"
          :style="{ background: '#6B7A99', padding: '6px' }">
          <HseButton variant='tertiaryInvert' size='medium' v-on:click="isMyScoreOpen = !isMyScoreOpen">
            {{ $t('your_score') }} <span class="ident" v-show="isMyScoreOpen">&#9650;</span><span class="ident"
              v-show="!isMyScoreOpen">&#9660;</span>
          </HseButton>
          <HseSpace size="small_2x" horizontal />
          <div :style="{ display: 'flex' }" v-show="isMyScoreOpen">
            <HseButton size="medium" v-on:click="score()">{{ $t('score') }}</HseButton>
          </div>
        </div>
        <div v-show="isMyScoreOpen" :style="{ background: '#F0F2F5', padding: '6px' }">
          <div class="two-cols" :style="{ background: '#D3D8E6', padding: '6px' }">
            <div>
              {{ $t('final_score') }} {{ req_ro.report.ScoreName }}
            </div>
          </div>

        </div>

      </div>

      <HseSpace size='small_2x' vertical />

      <HseSpace size='small' vertical />
      <div class="two-cols">
        <a class="link" :href="`/#/manager_requests`">{{ $t('back') }}</a>
      </div>
    </div>
    <RequestDirectionModal :text="$t('directionChanged')" />
    <RequestExpertsModal :request_id="requestId" @openExpertsModalResponse='set_expert' />
    <RequestStatusModal @openStatusModalResponse='set_status' :commentMessage='commentMessage' />
    <RequestScoreModal @openScoreModalResponse='set_score' />
    <RequestSessionModal :SectionCode="req.SectionCode" :SessionCode="req.SessionCode" :SessionName="req.SessionName" />
  </div>
</template>

<script>

import { api } from '../../api'
import Loading from '../common/Loading.vue'
import { HseSpace, HseButton, HseDivider } from '@hse-design/vue'
import fields from './managerFields'
import ReadonlyInput from '../common/ReadonlyInput.vue'
import Validator from '../common/form/Validator.vue'
import RequestExpertsModal from './RequestExpertsModal.vue'
import RequestStatusModal from './RequestStatusModal.vue'
import RequestScoreModal from './RequestScoreModal.vue'
import RequestDirectionModal from './RequestDirectionModal.vue'
import RequestSessionModal from './RequestSessionModal.vue'
import { mapState } from 'vuex'
import { localizeFields } from '../../i18n'

export default {
  components: {
    HseButton,
    HseSpace,
    HseDivider,
    Validator,
    Loading,
    ReadonlyInput,
    RequestExpertsModal,
    RequestStatusModal,
    RequestScoreModal,
    RequestDirectionModal,
    RequestSessionModal,
  },
  data: () => ({
    eventId: '',
    isDataLoaded: false,
    fields,
    req: null,
    experts: null,
    req_ro: null,
    isCoauthorsOpen: false,
    isExpertsOpen: false,
    isMyScoreOpen: false,
    commentMessage: null,
  }),
  created() {
    this.requestId = this.$route.params.id
    fields.ExpertSelect.requestId = this.$route.params.id;
    this.requestEventData()
    this.translateFields()
  },
  watch: {
    lang() {
      this.translateFields()
      this.fields.finalDirection.setEventId(this.req.EventId);
      this.fields.finalDirection.value = this.req.report.FinalDirection;
    }
  },
  methods: {
    translateFields() {
      this.fields = localizeFields(fields, this.fields)
    },
    changeFinalDirection() {
      api.request('requestReportFinalDirectionUpdate', { request_id: this.$route.params.id, direction_id: this.fields.finalDirection.value })
      this.$emit('openDirectionModal')
    },
    requestEventData() {
      let loading = Promise.all([
        api.request('requestGet', { id: this.$route.params.id }),
        api.request('requestExpertsList', { requestId: this.$route.params.id, lang: this.$store.state.lang, }),
        api.request('requestGetFull', { id: this.$route.params.id }),
        api.request('languagesList', { params: { "lang": "ru" } })
      ]);
      loading.then((res) => {
        this.req = res[0][0];
        this.experts = res[1];
        this.req_ro = res[2];
        this.isDataLoaded = true;
        this.fields.finalDirection.setEventId(res[0][0].EventId);
        this.fields.finalDirection.value = res[0][0].report.FinalDirection;

        const { EventId, ApplicantLanguageId } = res[0][0]
        const shortName = res[3].filter(item => item.id === ApplicantLanguageId)[0].shortName

        return api.request('get_event_desc_reject', { id: EventId, lang: shortName })
      }).then((data) => {
        this.commentMessage = data
      });
    },
    save() {
      this.$emit('openStatusModal')
    },
    set_status(status, comment) {
      api.request('requestStatusUpdate', {
        statusId: status,
        comment: comment,
        id: this.$route.params.id
      }).then(() => {
        this.requestEventData()
      })
    },
    score() {
      if (this.req_ro.report.ScoreName !== null && this.req.SessionId !== null) {
        this.$emit('openSessionModal')
      } else {
        this.$emit('openScoreModal')
      }
    },
    set_score(data) {
      api.request('requestFinalScoreUpdate', {
        scoreId: data,
        id: this.$route.params.id
      }).then(() => {
        this.requestEventData()
      })
    },
    remove_expert(id) {
      api.request('expertToRequestDelete', {
        expertId: id,
        requestId: this.$route.params.id
      }).then(() => {
        this.requestEventData();
        api.request('requestExpertsList', { requestId: this.$route.params.id, }).then((res) => {
          this.experts = res;
        })
      })
    },
    set_expert_dialog() {
      this.$emit('openExpertsModal')
    },
    set_expert(data) {
      api.request('expertToRequestAdd', {
        expertId: data,
        requestId: this.$route.params.id
      }).then(() => {
        this.requestEventData();
        api.request('requestExpertsList', { requestId: this.$route.params.id, }).then((res) => {
          this.experts = res;
        })
      })

    },
  },
  computed: {
    isCoauthors() {
      return this.req && this.req.report && this.req.report.coAuthors && this.req.report.coAuthors.length
    },
    ...mapState(['lang']),
  },
  i18n: {
    messages: {
      en: {
        quasiIdentifier: 'Collective request',
        number: 'Number',
        annotaion: 'Annotaion',
        annotaionEn: 'Annotaion',
        LName: 'Last name',
        FName: 'First name',
        SName: 'Middle name',
        phone: 'Phone number',
        topic: 'Topic',
        coauthors: 'co-Authors',
        experts: 'Experts',
        set: 'Set',
        Novelty: 'Novelty',
        Actuality: 'Actuality',
        Depth: 'Depth',
        TotalResult: 'Total result',
        TotalResultTitle: 'Conclusion',
        Comment: 'Comment',
        remove: 'Remove',
        back: 'Back to request list',
        changeStatus: 'Change status',
        status: 'Status',
        final_score: 'Final score:',
        score: 'Score',
        your_score: 'Your score',
        companyShortName: 'Company name',
        directionChanged: 'Direction successfully changed',
      },
      ru: {
        quasiIdentifier: 'Коллективная заявка',
        number: 'Номер',
        annotaion: 'Аннотация',
        annotaionEn: 'Аннотация на английском языке',
        LName: 'Фамилия',
        FName: 'Имя',
        SName: 'Отчество',
        phone: 'Телефон',
        topic: 'Тема доклада',
        coauthors: 'Соавторы',
        experts: 'Эксперты',
        set: 'Назначить',
        Novelty: 'Новизна',
        Actuality: 'Актуальность',
        Depth: 'Глубина',
        TotalResult: 'Оценка',
        TotalResultTitle: 'Заключение',
        Comment: 'Комментарий',
        remove: 'Удалить',
        back: 'К заявкам',
        changeStatus: 'Изменить статус',
        status: 'Статус',
        final_score: 'Финальная оценка:',
        score: 'Оценить',
        your_score: 'Ваша оценка',
        companyShortName: 'Организация',
        directionChanged: 'Направление успешно изменено',
      },
    }
  }
}
</script>

<style scoped>
.event-card {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 32px;
  max-width: 800px;
  margin: 52px auto;
  width: calc(100vw - 320px);

}

@media (max-width: 767px) {
  .event-card {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }

  /* .cols-2 {
    display: block;
  } */
}

.big-item {
  max-width: 780px;
}

.link {
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.two-cols {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.three-cols {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
}

.prepull {
  overflow: auto;
  margin-top: 5px;
}

.pull-right {
  float: right;
}

.ident {
  margin-left: 10px;
}

.flex {
  display: flex;
}

@media screen and (max-width: 500px) {
  .flex-reverse {
    display: flex;
    flex-direction: column-reverse;
  }
}</style>