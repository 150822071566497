<template>
  <div class="form">
    <div class="subheading">{{ $t('coauthors') }}</div>
    <HseSpace size="small" vertical />
    <div class="coauthors">
      <div class="authors" v-if="fields.coAuthorsGuids.value.length !== 0">
        <div class="author" v-for="(coauthor, index) in coauthors" :key="index">
          <div>{{ getCoauthorName(coauthor) }}</div>
          <div class="icons">
            <svg @click="() => editCoathor(coauthor, index)" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.60161 5.35704L11.4995 7.26425L6.69535 12.092L4.7985 10.1848L9.60161 5.35704ZM12.9397 4.89707L12.0933 4.04652C11.7662 3.71781 11.235 3.71781 10.9068 4.04652L10.0961 4.86126L11.994 6.76849L12.9397 5.81814C13.1934 5.56317 13.1934 5.15202 12.9397 4.89707ZM3.80527 12.8655C3.77073 13.0217 3.91108 13.1617 4.06654 13.1237L6.18147 12.6084L4.28461 10.7012L3.80527 12.8655Z" fill="#6B7A99"/>
            </svg>
            <svg @click="() => removeCoauthor(coauthor)" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6663 5.164L11.7263 4.224L7.99967 7.95067L4.27301 4.224L3.33301 5.164L7.05967 8.89067L3.33301 12.6173L4.27301 13.5573L7.99967 9.83067L11.7263 13.5573L12.6663 12.6173L8.93967 8.89067L12.6663 5.164Z" fill="#6B7A99"/>
            </svg>
          </div>
        </div>
      </div>
      <div class="add-author" @click="() => isModalOpen = true">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21 11.5H13V3.5H11V11.5H3V13.5H11V21.5H13V13.5H21V11.5Z" fill="#0050CF"/>
        </svg>
        <span>{{ $t('addCoauthor') }}</span>
      </div>
    </div>
    <HseModal v-model="isModalOpen" :shouldCloseOnOverlayClick="false">
      <CoauthorForm
        v-if="isModalOpen"
        :reportTitle="this.fields.reportTitle.value"
        :editableCoauthor="editableCoauthor"
        :eventId="eventId"
        :added="coauthors"
        @saved="onCoauthorSave"
      />
    </HseModal>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { api } from '../../api'
  import { store } from '../../store'
  import { HseSpace, HseModal } from '@hse-design/vue'
  import CoauthorForm from './CoauthorForm.vue'
  export default {
    name: 'FormPage5',
    store,
    props: ['fields', 'eventId'],
    data: () => ({
      isModalOpen: false,
      coauthors: [],
      editableCoauthor: null,
    }),
    updated () {
      if (this.coauthors.length === this.fields.coAuthorsGuids.value.length) return 
      this.coauthors = Array.isArray(this.fields.coAuthorsGuids.value) ? this.fields.coAuthorsGuids.value.filter(item => item !== undefined) : []
    },
    created () {
      this.fillCoauthorsData()
      this.coauthors = this.fields.coAuthorsGuids.value
    },
    components: {
      HseSpace,
      HseModal,
      CoauthorForm,
    },
    methods: {
      editCoathor (coauthor, index) {
        this.editableCoauthor = { ...coauthor, willCome: this.fields.willCome.value.includes(coauthor.id), index }
        this.isModalOpen = true
      },
      fillCoauthorsData () {
        this.fields.coAuthorsGuids.value.forEach(guid => {
          const isLoaded = this.coauthors.find(coauthor => {
            coauthor.id === guid
          }) !== undefined
          if (!isLoaded)
            api.request('getCoAuthorData', { id: guid }).then(data => {
              this.coauthors.push(data)
            })
        })
      },
      createCoauthorCompanyData(coauthors) {
        const result = {
          coAuthorsCompanyNames: {}
        };
        coauthors.forEach(el => {
          result.coAuthorsCompanyNames[el.id] = {
            CompanyNameShort: el.CompanyNameShort === undefined ? el.data.public.CompanyNameShort : el.CompanyNameShort,
            CompanyNameShortEn:  el.CompanyNameShortEn === undefined ? el.data.public.CompanyNameShortEn : el.CompanyNameShortEn,
          }
        })
        this.$emit('savedCoauthorCompanies', { ...result })
      },
      createCoauthorIdData(coauthors) {
        const result = [];
        coauthors.forEach(el => {
          result.push(el.id)
        })
        this.$emit('savedCoauthorsGuids', result )
      },
      onCoauthorSave (result) {
        if (!this.editableCoauthor) {
          const newVal = [...this.fields.coAuthorsGuids.value, { data: {public: {...result}}, login: result.login, id: result.id}]
          this.fields.coAuthorsGuids.setValues(true, newVal)
          this.coauthors.push(result)
        } else {
          this.coauthors[this.editableCoauthor.index].data.public = {
            ...this.coauthors[this.editableCoauthor.index].data.public,
            ...result.data.public,
            FName: result.FName,
            FNameEN: result.FNameEN,
            LName: result.LName,
            LNameEN: result.LNameEN,
            SName: result.SName,
            login: result.login,
            CompanyNameShort: result.CompanyNameShort,
            CompanyNameShortEn: result.CompanyNameShortEn,
            Affiliated: result.Affiliated,
            Campus: result.Campus,
            offline: result.offline,
          }
          this.coauthors[this.editableCoauthor.index].login = result.login
          this.fields.coAuthorsGuids.setValues(true, this.coauthors)
        }
        this.createCoauthorIdData(this.coauthors);


        this.editableCoauthor = null
        this.isModalOpen = false
        const isWillComeSet = this.fields.willCome.value.includes(result.id)

        let coAuthorsPreferredParticipationFormat = {...this.fields.coAuthorsPreferredParticipationFormat.value}
        coAuthorsPreferredParticipationFormat[result.id] = result.offline ? 'офлайн' : 'онлайн'
        this.fields.coAuthorsPreferredParticipationFormat.setValues(true, coAuthorsPreferredParticipationFormat)

        if (result.willCome && !isWillComeSet) {
          const willCome = [...this.fields.willCome.value, result.id]
          this.fields.willCome.setValues(true, willCome)
        } else if (!result.willCome && isWillComeSet) {
          const willCome = [...this.fields.willCome.value].filter(guid => guid !== result.id)
          this.fields.willCome.setValues(true, willCome)
        }
        this.createCoauthorCompanyData(this.coauthors);
      },
      getCoauthorName (coauthor) {
        const hasData = coauthor && coauthor.data && coauthor.data.public
        if (!hasData) return ''

        return this.lang === 'ru' 
          ? `${coauthor.data.public.LName} ${coauthor.data.public.FName} ${coauthor.data.public.SName}`
          : `${coauthor.data.public.LNameEN} ${coauthor.data.public.FNameEN}`
      },
      removeCoauthor ({ id }) {
        this.coauthors.forEach(el => {
          if (el.id === id) {
            
            this.coauthors.splice(this.coauthors.indexOf(el), 1)
            this.fields.coAuthorsGuids.setValues(true, this.coauthors)
          }
        });
        this.createCoauthorCompanyData(this.coauthors)
        this.createCoauthorIdData(this.coauthors)

        const newVal = [...this.fields.coAuthorsGuids.value].filter(guid => guid !== id)
        this.fields.coAuthorsGuids.setValues(true, newVal)
        this.coauthors = this.coauthors.filter(coauthor => coauthor.id !== id)

        if (this.fields.willCome.value.includes(id)) {
          const willCome = [...this.fields.willCome.value].filter(guid => guid !== id)
          this.fields.willCome.setValues(true, willCome)

          const coAuthorsPreferredParticipationFormat = {...this.fields.coAuthorsPreferredParticipationFormat.value}
          delete coAuthorsPreferredParticipationFormat[id]
          this.fields.coAuthorsPreferredParticipationFormat.setValues(true, coAuthorsPreferredParticipationFormat)
        }
      }
    },
    computed: {
      ...mapState(['lang']),
    },
    watch: {
      isModalOpen (isOpen) {
        if (!isOpen) {
          this.editableCoauthor = null
        }
      },
    },
    i18n: {
      messages: {
        en: {
          addCoauthor: 'Add coauthor',
          coauthors: 'Coauthors'
        },
        ru: {
          addCoauthor: 'Добавить соавтора',
          coauthors: 'Соавторы'
        },
      }
    },
  }
</script>

<style scoped>
  .form {
    width: 670px;
  }
  .add-author {
    background: #F0F5FF;
    border-radius: 4px;
    border: 1px solid #8EB2EC;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #0050CF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 24px 8px 16px;
    width: fit-content;
    cursor: pointer;
  }
  .add-author svg {
    margin-right: 11px;
  }
  .authors {
    width: fit-content
  }
  .author {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    color: #6B7A99;
    padding: 10px 0;
    margin-bottom: 12px;
    justify-content: space-between;
  }
  .author:last-child {
    margin-bottom: 17px;
  }
  .author .icons {
    margin-left: 20px;
  }
  .author .icons>svg {
    cursor: pointer;
    margin: 0 3px;
  }
</style>
<style>
  .hse-BaseModal {
    width: 690px !important;
    max-height: 100%;
  }
</style>