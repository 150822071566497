<template>
  <div class="edit-request">
    <div v-show="!isSaved" class="form-data">
      <!-- <HseHeading size="h3" v-if="page === 1" v-html="$t('formName', { br: brTag })" ></HseHeading> -->
      <HseHeading size="h3">{{ $t("formName", { br: "" }) }}</HseHeading>
      <HseSpace size="small" vertical />
      <div class="tabs-container">
        <HseTabs v-model="currentTab" :tabs="has5thPage ? tabs : tabs.slice(0, tabs.length - 1)" class="tabs"
          @change="selectTab" v-on:validatePage="changePagesValidity" />
      </div>
      <HseSpace size="small" vertical />
      <!-- <FormPage1 :fields="fields" v-if="page === 1" /> -->
      <FormPage2 :fields="fields" v-show="page === 2" v-on:validatePage="changePagesValidity"
        v-on:fillWithUserData="fillWithUserData" />
      <FormPage3 :fields="fields" :isRequiredPassport="IsRequiredPassport"
        :isRequiredPassportRussia="IsRequiredPassportRussia" :isRequiredPassportOther="IsRequiredPassportOther"
        v-show="page === 3" v-on:validatePage="changePagesValidity" />
      <FormPage4 :fields="fields" v-show="page === 4" v-on:validatePage="changePagesValidity" :eventId="eventId" />
      <FormPage5 @savedCoauthorCompanies="coauthorCompanies" @savedCoauthorsGuids="coauthorsGuids" :fields="fields"
        v-show="page === 5" v-on:validatePage="changePagesValidity" :eventId="eventId" />
      <HseSpace size="large" vertical />
      <div class="actions">
        <div>
          <div class="buttons">
            <div v-if="page > 1">
              <HseButton variant="secondary" @click="() => changePage(page - 1)">
                <span>{{ $t("prev") }}</span>
              </HseButton>
            </div>
            <div>
              <HseButton :spinner="buttonSpinner" spinnerPosition="left" @click="() => changePage(page + 1)">
                <span v-if="has5thPage ? page !== 5 : page !== 4">{{
                  $t("next")
                }}</span>
                <span v-else-if="buttonSpinner === false">{{
                  $t("applyRequest")
                }}</span>
                <span v-else-if="buttonSpinner === true">{{ $t("applying") }}...</span>
                <span v-else-if="buttonSpinner === 'done'">{{
                  $t("auth.btnDone")
                }}</span>
              </HseButton>
            </div>
          </div>
        </div>
        <Pagination :current="page - 1" :pagesCount="has5thPage ? 4 : 3" v-on:changePage="(n) => changePage(n + 1)" />
      </div>
    </div>
    <div v-if="isSaved" class="result">
      <div class="status ok" v-if="!isSaveErr">
        <svg width="110" height="100" viewBox="0 0 110 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M26.8055 8.42652C35.0279 2.93245 44.6949 0 54.584 0C67.8448 0 80.5625 5.26784 89.9393 14.6447C99.3161 24.0215 104.584 36.7392 104.584 50C104.584 59.8891 101.652 69.556 96.1575 77.7785C90.6634 86.0009 82.8545 92.4096 73.7182 96.194C64.5819 99.9783 54.5285 100.969 44.8295 99.0392C35.1304 97.11 26.2213 92.3479 19.2287 85.3553C12.236 78.3627 7.474 69.4535 5.54474 59.7545C3.61548 50.0555 4.60565 40.0021 8.39003 30.8658C12.1744 21.7295 18.583 13.9206 26.8055 8.42652ZM85.4219 35.1375L79.2732 28.9887L46.4721 61.7897L29.8964 45.214L23.7476 51.3627L46.4721 74.0872L85.4219 35.1375Z"
            fill="#0050CF" />
        </svg>
        <HseSpace size="large" vertical />
        <HseHeading v-if="fields.reportExists.value" size="h3" v-html="$t('okStatusSpeaker')"></HseHeading>
        <HseHeading v-else size="h3" v-html="$t('okStatus')"></HseHeading>
        <HseSpace size="medium" vertical />
        <HseButton @click="() => this.$router.push('/requests')">Ok</HseButton>
      </div>
      <div class="status err" v-else>
        <svg width="110" height="100" viewBox="0 0 110 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M26.8055 8.42652C35.0279 2.93245 44.6949 0 54.584 0C67.8448 0 80.5625 5.26784 89.9393 14.6447C99.3161 24.0215 104.584 36.7392 104.584 50C104.584 59.8891 101.652 69.556 96.1575 77.7785C90.6634 86.0009 82.8545 92.4096 73.7182 96.194C64.5819 99.9783 54.5285 100.969 44.8295 99.0392C35.1304 97.11 26.2213 92.3479 19.2287 85.3553C12.236 78.3627 7.474 69.4536 5.54474 59.7545C3.61548 50.0555 4.60565 40.0021 8.39003 30.8658C12.1744 21.7295 18.583 13.9206 26.8055 8.42652Z"
            fill="#FF564E" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M73.4702 35.2504L69.7498 31.53L55.0002 46.2796L40.2507 31.53L36.5303 35.2504L51.2799 50L36.5303 64.7496L40.2507 68.47L55.0002 53.7204L69.7498 68.47L73.4702 64.7496L58.7206 50L73.4702 35.2504Z"
            fill="white" />
        </svg>
        <HseSpace size="large" vertical />
        <HseHeading size="h3" v-html="reportErr ? $t('errStatusReport') : $t('errStatus')"></HseHeading>
        <HseSpace size="medium" vertical />
        <HseButton @click="getBackIfErr">{{ $t("back") }}</HseButton>
      </div>
    </div>
  </div>
</template>

<script>
import fields from "./requestFields";
import { store } from "../../store";
import { api } from "../../api";
import { HseTabs } from "@hse-design/vue";
import { HseHeading, HseButton, HseSpace } from "@hse-design/vue";
// import FormPage1 from './FormPage1.vue'
import FormPage2 from "./FormPage2.vue";
import FormPage3 from "./FormPage3.vue";
import FormPage4 from "./FormPage4.vue";
import FormPage5 from "./FormPage5.vue";
import Pagination from "./Pagination.vue";
import { mapState } from "vuex";
import { localizeFields } from "../../i18n";
import { resourceLoader } from "../common/resourceLoader";
export default {
  name: "RequestForm",
  store,
  components: {
    HseButton,
    HseHeading,
    HseSpace,
    HseTabs,
    // FormPage1,
    FormPage2,
    FormPage3,
    FormPage4,
    FormPage5,
    Pagination,
  },
  data: () => ({
    passportCheck: true,
    citizenId: null,
    IsRequiredPassport: false,
    IsRequiredPassportRussia: false,
    IsRequiredPassportOther: false,
    currentTab: "2",
    pagesValidity: [null, null, false, false, false, true],
    tabs: [
      {
        value: "2",
        //label: 'Персональные данные',
        label: "Персональные данные",
      },
      {
        value: "3",
        label: "Организация",
        disabled: true,
      },
      {
        value: "4",
        label: "Формат участия",
        disabled: true,
      },
      {
        value: "5",
        label: "Соавторы",
        disabled: true,
      },
    ],
    fields,
    companyNames: {},
    coAuthorsGuids: [],
    eventId: "",
    eventData: null,
    page: 2,
    buttonSpinner: false,
    isSaved: false,
    isSaveErr: false,
    reportErr: false,
    fieldsCompare: {
      applicantLastName: "LName",
      applicantFirstName: "FName",
      applicantMiddleName: "SName",
      applicantFirstNameEN: "FNameEN",
      applicantLastNameEN: "LNameEN",
      gender: "Gender",
      applicantPhone: "Phone",
      сitizenshipCountryId: "Citizen",
      // applicantCountryId: 'CountryId',
      // regionID: 'Region',
      // applicantCity: 'City',
      applicantLanguageId: "LanguageId",
      dateOfBirth: "BirthDate",
    },
    reportFields: [
      "title",
      "titleEN",
      "languageId",
      "direction1Id",
      "direction2Id",
      "thesesFileID",
      "thesesFileEnID",
      "coAuthorsGuids",
      "willCome",
      "coAuthorsPreferredParticipationFormat",
    ],
    selectsWithNumberId: [
      "studyYear",
      "studyMode",
      "organizationType",
      "occupationType",
    ],
    selectsWithGuid: ["campus", "direction1Id", "direction2Id", "thesesFileID"],
    brTag: "<br>",
  }),
  destroyed() {
    this.$store.commit("setIsLangDropdownDisabled", false);
  },
  updated() {
    this.translateTabs();
  },
  created() {
    // если добавляем новую заявку, выясняем id мероприятия из роута
    if (this.$route.query && this.$route.query.event) {
      this.eventId = this.$route.query.event;
    }

    api.request("eventGet", { id: this.eventId }).then((data) => {
      this.eventData = data[0];
    });

    api.request("eventGet", { id: this.eventId }).then((data) => {
      this.eventData = data[0];
    });

    api
      .request("get_is_required_passport", { EventId: this.eventId })
      .then((data) => {

        this.IsRequiredPassport = data.IsRequiredPassport
        this.IsRequiredPassportRussia = data.IsRequiredPassportRussia
        this.IsRequiredPassportOther = data.IsRequiredPassportOther

        // const citizenIdRussia = 'BC763F4B-FBD9-461B-B395-166020E8C026' // Российская Федерация
        // console.log('this.fields.сitizenshipCountryId.value',this.fields.сitizenshipCountryId)
        // if (
        //     ( data.IsRequiredPassport && data.IsRequiredPassportRussia && this.citizen === citizenIdRussia ) ||
        //     ( data.IsRequiredPassport && data.IsRequiredPassportOther && this.citizen !== citizenIdRussia )
        // ) { this.IsRequiredPassport = true }
      });

    this.translateFields();
    this.translateTabs();
  },
  methods: {
    translateTabs() {
      const tabList = [
        {
          label: this?.$t("personalData"),
        },
        {
          label: this?.$t("organization"),
        },
        {
          label: this?.$t("participationFormat"),
        },
        {
          label: this?.$t("coauthors"),
        },
      ];
      this.tabs.map((item, index) => {
        item.label = tabList[index].label;
      });
    },
    translateFields() {
      ["direction1Id", "direction2Id"].forEach((key) => {
        fields[key].eventId = this.eventId;
      });
      this.fields = localizeFields(fields, this.fields);
    },
    changePage(page) {
      // если возвращаемся назад - всегда переходим
      if (this.page > page) {
        if (page && page >= 2) this.page = page;
        else this.$router.go(-1);
        if (this.tabs[this.page - 1]?.disabled === false) {
          this.tabs[this.page - 1].disabled = true;
        }
        this.currentTab = String(this.page);
        return;
      } else {
        this.$emit("validatePage", { current: this.page });
      }
      // если вперед - проверям текущую
      if (!this.pagesValidity[this.page]) {
        if (this.tabs[this.page - 1]?.disabled === false) {
          this.tabs[this.page - 1].disabled = true;
        }
        return;
      }
      // активируем табы
      if (this.pagesValidity[this.page]) {
        if (this.tabs[this.page - 1]?.disabled) {
          this.tabs[this.page - 1].disabled = false;
        }
        this.currentTab = String(this.page + 1);
      }
      // и проверяем все страницы от текущей до той на которую переходим
      let invalidPage = 0;
      this.pagesValidity.some((isValid, i) => {
        if (i && i <= page && i > this.page) {
          if (!isValid) {
            invalidPage = i;
          }
          return !isValid;
        }
        return false;
      });

      // если между текущей и переключаемой нашли невалидную страницу формы - переключаемся на неё
      if (invalidPage) {
        this.page = invalidPage;
        return;
      }
      // если есть пятая страница, а пытаемся идти на 6 и наоборот
      // нет 5ой, а идём на неё, значит мы нажали подать заявку - запускаем сохранение
      if ((this.has5thPage && page === 6) || (!this.has5thPage && page === 5)) {
        this.buttonSpinner = true;
        this.saveForm();
        return;
      }
      this.page = page;
    },
    changePagesValidity({ page, isValid }) {
      this.pagesValidity[page] = isValid;
    },
    selectTab() {
      if (this.currentTab === "2") {
        this.page = 2;
        //this.tabs[1].disabled = false
      }
      if (this.currentTab === "3") {
        this.page = 3;

        //this.tabs[1].disabled = false
      }
      if (this.currentTab === "4") {
        this.page = 4;
      }

      if (this.currentTab === "5") {
        this.page = 5;
      }
    },
    fillWithUserData(isFill) {
      if (isFill) {
        Object.keys(this.fieldsCompare).forEach((key) => {
          const userField = this.fieldsCompare[key];
          this.fields[key].setValues(
            false,
            this.$store.state.user.data.public[userField]
          );
        });
        this.fields.applicantEmail.setValues(
          false,
          this.$store.state.user.login
        );
        this.fields.applicantLanguageId.setValues(false, this.currLangId);
      } else {
        [...Object.keys(this.fieldsCompare), "applicantEmail"].forEach(
          (key) => {
            this.fields[key].setValues(fields[key].isValid, fields[key].value);
          }
        );
        if (this.lang === "en") {
          this.fields.applicantLanguageId.setValues(
            true,
            "D909F362-2610-49C9-BFA2-57692617EF76"
          );
        }
      }
      this.$emit("fieldsValChange");
    },
    convertToNumber(value) {
      return !value !== null && value.length ? parseInt(value, 10) : null;
    },
    convertGuid(value) {
      return !value ? null : value;
    },
    coauthorCompanies(value) {
      this.companyNames = value;
    },
    coauthorsGuids(value) {
      this.coAuthorsGuids = value;
    },
    saveForm() {
      const params = {};
      if (this.lang === "en") {
        this.fields.applicantFirstName.value =
          this.fields.applicantFirstNameEN.value;
        this.fields.applicantLastName.value =
          this.fields.applicantLastNameEN.value;
        this.fields.applicantMiddleName = "";
        this.fields.title.value = this.fields.titleEN.value;
        this.fields.applicantCompanyFullName.value =
          this.fields.applicantCompanyFullNameEn.value;
        this.fields.applicantCompanyShortName.value =
          this.fields.applicantCompanyShortNameEn.value;
      }
      this.fields.PreferredParticipationFormat.value = this.fields.offline.value
        ? "офлайн"
        : "онлайн";
      Object.keys(this.fields).forEach((key) => {
        if (
          this.reportFields.includes(key) ||
          key === "isExclusive" ||
          key === "offline"
        )
          return;

        const { value } = this.fields[key];

        if (this.selectsWithGuid.includes(key)) {
          params[key] = this.convertGuid(value);
        } else if (this.selectsWithNumberId.includes(key)) {
          params[key] = this.convertToNumber(value);
        } else {
          params[key] = value;
        }
      });

      if (this.fields.reportExists.value) {
        params.report = {};
        this.reportFields.forEach((key) => {
          if (this.selectsWithGuid.includes(key)) {
            params.report[key] = this.convertGuid(this.fields[key].value);
          } else {
            params.report[key] = this.fields[key].value;
          }
        });
        params.report.coAuthorsCompanyNames =
          this.companyNames.coAuthorsCompanyNames;
        params.report.coAuthorsGuids = this.coAuthorsGuids;
      } else {
        params.report = null;
      }
      params.eventId = this.eventId;

      console.log(
        "🚀 ~ file: RequestForm.vue:476 ~ saveForm ~ passportCheck:",
        this.passportCheck
      );

      const citizenIdRussia = "BC763F4B-FBD9-461B-B395-166020E8C026"
      const isRequiredPassportForSpeaker = (
        (this.isRequiredPassport && this.isRequiredPassportRussia && this.fields.сitizenshipCountryId.value === citizenIdRussia) ||
        (this.isRequiredPassport && this.isRequiredPassportOther && this.fields.сitizenshipCountryId.value !== citizenIdRussia)
      )

      if (!this.passportCheck && isRequiredPassportForSpeaker) {
        //add passport in table Passport
        console.log("add passport in table Passport");
        const paramsPassport = {
          EventId: this.eventId,
          Email: this.fields.applicantEmail.value,
          PassportId: this.fields.Passport.value,
        };
        api.request("add_passport", paramsPassport).then((data) => {
          console.log("data requestAdd", data);
        });
      } else {
        delete params.Passport;
      }
      console.log(
        "🚀 ~ file: RequestForm.vue:467 ~ saveForm ~ params:",
        params
      );
      console.log(
        "🚀 ~ file: RequestForm.vue:467 ~ saveForm ~  this.fields:",
        this.fields
      );

      api.request("requestAdd", params).then((data) => {
        this.buttonSpinner = "done";
        setTimeout(() => {
          this.buttonSpinner = false;
          if (data && data.length) {
            this.isSaved = true;
          } else if (data.code === 550 && data.message === "Duplicate exists") {
            this.isSaved = true;
            this.isSaveErr = true;
            this.reportErr = true;
          } else {
            this.isSaved = true;
            this.isSaveErr = true;
            this.reportErr = false;
          }
        }, 2000);
      });
    },
    getBackIfErr() {
      this.changePage(4);
      this.isSaved = false;
      this.isSaveErr = false;
    },
  },
  computed: {
    currLangId() {
      return resourceLoader.loaded.languagesList.find(
        (lang) => lang.shortName === this.lang
      ).id;
    },
    has5thPage() {
      return this.fields.reportExists.value;
    },
    eventName() {
      return this.eventData ? `<br>&laquo;${this.eventData.Title}&raquo;` : "";
    },
    ...mapState(["lang"]),
  },
  watch: {
    lang() {
      this.translateFields();
      this.$emit("validatePage", { current: this.page });
    },
    page() {
      console.log("page change", this.page);
      this.$store.commit("setIsLangDropdownDisabled", this.page !== 2);
      if (this.fields.applicantEmail.value != "" && this.page == 3) {
        console.log(
          this.fields.applicantEmail.value,
          "this.fields.applicantEmail"
        );
        const params = {
          EventId: this.eventId,
          Email: this.fields.applicantEmail.value,
        };
        console.log(params, "params");
        api.request("get_passport", params).then((data) => {
          console.log(
            "🚀 ~ file: RequestForm.vue:513 ~ api.request ~ data:",
            data
          );
          if (Object.keys(data).length === 0) {
            // Объект пуст
            console.log("Объект пуст");
            this.passportCheck = false;
          } else {
            // Объект не пуст
            console.log("Объект не пуст");
            this.passportCheck = true;
          }

          // if (!this.IsRequiredPassport) {
          //   this.passportCheck = false
          // }
        });
      }
    },
  },
  i18n: {
    messages: {
      en: {
        personalData: "Personal data",
        organization: "Company",
        participationFormat: "Form of participation",
        coauthors: "Coauthors",
        formName: "Request for participation {br}in the event",
        applyRequest: "Apply request",
        applying: "Applying",
        okStatusSpeaker:
          "Request for participation <br>as a speaker in the event<br>was accepted for a review",
        okStatus:
          "Request for participation <br>as a participant <br>in the event accepted",
        errStatusReport:
          "A paper with this title <br>is already in the system. <br>To clarify the situation, <br>contact the Organizing Committee",
        errStatus:
          "Error occured while <br>sending the request. <br>Try again later or contact <br>the event administrator about the error.",
      },
      ru: {
        personalData: "Персональные данные",
        organization: "Организация",
        participationFormat: "Формат участия",
        coauthors: "Соавторы",
        formName: "Заявка на участие {br}в мероприятии",
        applyRequest: "Подать заявку",
        applying: "Отправялем",
        okStatusSpeaker:
          "Заявка на участие <br>с докладом в мероприятии<br>была направлена <br>на рассмотрение",
        okStatus:
          "Заявка на участие <br>в мероприятии <br>в качестве слушателя<br>принята",
        errStatusReport:
          "Доклад с таким названием <br> уже существует в системе. <br>Для прояснения ситуации, <br>пожалуйста, свяжитесь с Оргкомитетом.",
        errStatus:
          "При отправке заявки <br>возникла ошибка. <br>Попробуйте повторить отправку <br>позже, или сообщите об ошибке <br>администратору мероприятия.",
      },
    },
  },
};
</script>

<style scoped>
.tabs {
  margin-bottom: 10px;
}

.tabs>* {
  width: 180px;
}

.edit-request {
  width: fit-content;
  margin: 52px auto;
  background: #ffffff;
  border-radius: 12px;
  padding: 32px;
  max-width: calc(100vw - 192px);
}

.actions,
.actions .buttons {
  flex-wrap: wrap;
  display: flex;
  margin: 0 -12px;
  align-items: center;
  justify-content: space-between;
}

.actions {
  margin: -6px -12px;
}

.actions>div {
  padding: 6px 12px;
}

.actions .buttons>div {
  padding: 0 12px;
}

.result .status {
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}

@media (max-width: 1023px) {
  .edit-request {
    max-width: 95%;
    margin: 16px auto;
  }
}

@media (max-width: 767px) {
  .edit-request {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }
}
</style>

<style>
.edit-request .subheading {
  font-family: "Work Sans", sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #0f0f14;
}
</style>
