<template>
  <div v-if="roles && roles.length" class="rolesDropdown" :ref="refName">
    <div class="select-container" v-on:click="toggleRoles">
      <div class="select">
        <div class="top-menu-text">{{ $t(currentItem.name) }}</div>
      </div>
      <HseIcon
        :icon="arrow"
        :style="{ color: color() }"
        :class="{ 'icon-opened': isMenuOpen }"
        class="arrow-icon"
        size="large"
      />
    </div>
    <HseDropdownMenu
      :class="{ show: isMenuOpen }"
      class="roles-menu"
    >
      <HseDropdownMenuItem
        v-for="item in roles"
        :key="item.id"
        class="menu-item"
        :class="{ active: item.id === role }"
        :classes="{}"
        @click.native="() => selectItem(item.id)"
      >
        {{ $t(item.name) }}
      </HseDropdownMenuItem>
    </HseDropdownMenu>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import {
    HseDropdownMenu,
    HseDropdownMenuItem,
    HseIcon,
    IconActionArrowDropdownBottom
  } from '@hse-design/vue'

  export default {
    name: 'RolesDropdown',
    props: ['id'],
    components: {
      HseDropdownMenu,
      HseDropdownMenuItem,
      HseIcon,
    },
    data: () => ({
      isLoaded: false,
      selected: '',
      isMenuOpen: false,
    }),
    watch: {
      user () {
        this.isLoaded = false
        this.$store.commit('setRoles', [])
      },
      roles () {
        if (this.roles.length) {
          this.selected = this.role
        }
      },
      role () {
        if (this.roles.length) {
          this.selected = this.role
        }
      },
    },
    computed: {
      refName () { return `dropdownRolesRef${this.id}` },
      show () {
        return this.roles.length > 1
      },
      currentItem () {
        return this.roles.find(item => item.id === this.selected) ? this.roles.find(item => item.id === this.selected) : this.roles[0]
      },
      arrow () {
        return IconActionArrowDropdownBottom
      },
      ...mapState(['user', 'roles', 'role'])
    },
    methods: {
      color () {
        return '#6b7a99'
      },
      toggleRoles () {
        this.isMenuOpen = !this.isMenuOpen
        if (this.isMenuOpen) {
          addEventListener('click', this.listener, false)
        }
      },
      listener (e) {
        if (!this.$refs[this.refName]) return

        const isDropdownClick = this.$refs[this.refName].contains(e.target)
        if (!isDropdownClick) {
          this.isMenuOpen = false
          removeEventListener('click', this.listener, false)
        }
      },
      selectItem (id) {
        if (this.selected !== id) {
          this.selected = id
          this.$store.commit('setRole', id)
        }
        this.isMenuOpen = !this.isMenuOpen
      },
    },
    i18n: {
      messages: {
        en: {
          participant: 'Participant',
          expert: 'Expert',
          manager: 'Manager',
        },
        ru: {
          participant: 'Участник',
          expert: 'Эксперт',
          manager: 'Руководитель',
        },
      }
    }
  }
</script>

<style scoped>
  .rolesDropdown {
    position: relative;
  }
  .roles-menu {
    right: 0;
    position: absolute;
    margin-right: 0px;
    align-items: center;
    display: none;
  }

  .top-menu-text {
    margin-left: 3px;
  }

  .show {
    display: flex;
  }

  .select-container {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    min-width: 100%;
    padding-right: 8px;
    padding-left: 12px;
    cursor: pointer;
    color: #6b7a99;
  }

  .select {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    flex-grow: 1;
    margin-right: 1px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .menu-item {
    color: #6b7a99;
    white-space: nowrap;
  }
  .menu-item.active {
    color: #0050cf;
    cursor: default;
  }

  .arrow-icon {
    display: block;
    float: none;
    cursor: pointer;
    position: relative;
    width: 24px;
    min-width: 24px;
    height: 24px;
    color: #6b7a99;
    transition: 0.2s transform ease-in-out;
  }

  .icon-opened {
    transform: rotate(180deg);
  }
</style>
