<template>
  <div>
    <!-- <RadioGroup
        :label="$t('eventFormat')"
        :options="fields.specialEventOrganizationFormatId.options"
        @change="setFormat"
        :disabled="fields.specialEventOrganizationFormatId.disabled"
        :defaultValue="fields.specialEventOrganizationFormatId.value"
      />
      <HseSpace size="small_2x" vertical /> -->

    <div class="cols two-cols">
      <div class="field">
        <Validator :field="fields.RTname" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field">
        <Validator :field="fields.RTnameEn" />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>
    <div class="cols two-cols">
      <div class="field">
        <Validator :field="fields.discussionQuestions" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field">
        <Validator :field="fields.discussionQuestionsEn" />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>
    <div class="subheading">{{ $t("responsibleEvent") }}</div>
    <HseSpace size="small" vertical />
    <!-- ФИО -->
    <div class="field">
      <Validator :field="fields.ResponsibleFIO" />
      <HseSpace size="small_2x" vertical />
    </div>
    <div class="cols two-cols">
      <!-- Должность -->
      <div class="field">
        <Validator :field="fields.ResponsiblePost" />
        <HseSpace size="small_2x" vertical />
      </div>
      <!-- Подразделение -->
      <div class="field longDMItem">
        <Validator :field="fields.ResponsibleDepartmenID" />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>
    <div class="cols two-cols">
      <!-- Емаил -->
      <div class="field">
        <Validator :field="fields.ResponsibleEmail" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field">
        <Validator :field="fields.ResponsiblePhone" />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>
    <RadioGroup
      :label="$t('eventFormat')"
      :options="fields.specialEventOrganizationFormatId.options"
      @change="setFormat"
      :defaultValue="fields.specialEventOrganizationFormatId.value"
      :disabled="fieldsDisabled"
    />
    <HseSpace size="small_2x" vertical />
    <div class="cols">
      <div class="field">
        <Validator
          :field="fields.NumberParticipants"
          v-show="
            fields.specialEventOrganizationFormatId.value ===
              '592D7A01-E55F-4860-B403-1BB88FDE85A9' ||
            fields.specialEventOrganizationFormatId.value ===
              '895BB6AB-A343-48DC-AA19-7EAB348C8C0D'
          "
        />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>
    <div class="cols two-cols">
      <div class="field">
        <Datepicker
          :errorText="
            +new Date(
              this.fields.endRegistrationDate.value
                .split('.')
                .reverse()
                .join('-')
            ) +
              new Date().getTimezoneOffset() * 60 * 1000 >
            +new Date(this.fields.date.value.split('.').reverse().join('-'))
              ? 'fields.rule.regError'
              : ''
          "
          v-bind:field="fields.date"
          @change="updateTimestamps"
        />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field">
        <Validator :field="fields.programSessionTimeId" />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>

    <span class="hse-RichInput__label">{{ $t("chooseLang") }}</span>
    <HseSpace size="small_2x" vertical />

    <div class="cols three-cols">
      <div class="field">
        <Validator :field="fields.langRu" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field">
        <Validator :field="fields.langEn" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field">
        <Validator :field="fields.langOther" />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>
    <div
      class="hse-RichInput__message"
      v-bind:style="{ color: '#ff564e' }"
      v-show="
        fields.langRu.value === false &&
        fields.langEn.value === false &&
        fields.langOther.value === false
      "
    >
      Пожалуйста, заполните поле
    </div>
    <HseSpace size="small_2x" vertical />
    <div class="cols" v-show="fields.langOther.value">
      <div class="field">
        <Validator :field="fields.other" />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>

    <div class="cols">
      <div class="field">
        <RadioGroup
          :options="yesnoOptions"
          :label="fields.syncTranslation.label"
          :defaultValue="fields.syncTranslation.value"
          @change="setSyncTranslation"
          :disabled="fields.syncTranslation.disabled"
        />
      </div>
      <HseSpace size="small" horizontal />
    </div>

    <div class="field">
      <RadioGroup
        :options="fields.publicityId.options"
        :label="fields.publicityId.label"
        :defaultValue="fields.publicityId.value"
        @change="setPublicityId"
        :disabled="fields.publicityId.disabled"
      />
    </div>
    <HseSpace size="small" horizontal />
    <HseSpace size="small" vertical />
    <!-- <div class="subheading">{{ $t("secretary") }}</div> -->
    <div class="headingWithTooltip">
      <div class="subheading">{{ $t("secretary") }}</div>
      <HseTooltip :content="$t('secretaryTooltip')">
        <IconStatusInfo />
      </HseTooltip>
    </div>
    <HseSpace size="small" vertical />

    <div class="colssecretary two-cols">
      <!-- Секретарь -->
      <div class="field longDMItem">
        <Validator
          :field="fields.secretaryOnline"
          v-show="
            fields.specialEventOrganizationFormatId.value ===
              '1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A' ||
            fields.specialEventOrganizationFormatId.value ===
              '895BB6AB-A343-48DC-AA19-7EAB348C8C0D'
          "
        />
        <Validator
          :field="fields.secretaryOffline"
          v-show="
            fields.specialEventOrganizationFormatId.value ===
              '592D7A01-E55F-4860-B403-1BB88FDE85A9' ||
            fields.specialEventOrganizationFormatId.value ===
              '895BB6AB-A343-48DC-AA19-7EAB348C8C0D'
          "
        />
      </div>
      <div class="field addButtonSecretary">
        <div>
          <HseButton
            size="medium"
            :rightIcon="plusIcon"
            @click="openSecretaryModal"
            :disabled="fieldsDisabled"
          >
            {{ $t("addParticipantSecretary") }}
          </HseButton>
          <HseSpace size="small_2x" vertical />
        </div>
      </div>
    </div>
    <HseSpace size="small_2x" vertical />

    <div>
      <HseSpace size="small" vertical />
      <div
        :style="{ background: '#6B7A99', padding: '6px' }"
        class="accordion__head"
      >
        <HseButton
          variant="tertiaryInvert"
          size="medium"
          v-on:click="isModeratorBlockOpen = !isModeratorBlockOpen"
        >
          {{ $t("moderatorBlock") }}
          <span class="ident" v-show="isModeratorBlockOpen">&#9650;</span>
          <span class="ident" v-show="!isModeratorBlockOpen">&#9660;</span>
        </HseButton>
        <HseButton
          v-if="!(fieldsDisabled === true) && fieldsDisabled !== 'membersOnly'"
          size="medium"
          :rightIcon="plusIcon"
          @click="openModeratorModal"
        >
          {{ $t("addParticipant") }}
        </HseButton>
        <div
          class="accordion__info"
          v-show="!(isModeratorBlockOpen && moderators.length)"
        >
          {{ $t("moderatorInfo") }}
        </div>
      </div>
      <div
        v-show="isModeratorBlockOpen && moderators.length"
        :style="{ background: '#F0F2F5', padding: '6px' }"
      >
        <div class="accordion">
          <div
            class="participant"
            v-for="(moderator, index) in moderators"
            :key="moderator.email"
          >
            <div class="participant__heading">
              <HseHeading size="h4">{{
                `${$t("fields.name.moderators")} №${index + 1}`
              }}</HseHeading>
              <div
                class="icons"
                v-if="
                  !(fieldsDisabled === true) && fieldsDisabled !== 'membersOnly'
                "
              >
                <svg
                  @click="() => editModerator(moderator, index)"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.60161 5.35704L11.4995 7.26425L6.69535 12.092L4.7985 10.1848L9.60161 5.35704ZM12.9397 4.89707L12.0933 4.04652C11.7662 3.71781 11.235 3.71781 10.9068 4.04652L10.0961 4.86126L11.994 6.76849L12.9397 5.81814C13.1934 5.56317 13.1934 5.15202 12.9397 4.89707ZM3.80527 12.8655C3.77073 13.0217 3.91108 13.1617 4.06654 13.1237L6.18147 12.6084L4.28461 10.7012L3.80527 12.8655Z"
                    fill="#6B7A99"
                  />
                </svg>
                <svg
                  @click="() => removeModerator(moderator)"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.6663 5.164L11.7263 4.224L7.99967 7.95067L4.27301 4.224L3.33301 5.164L7.05967 8.89067L3.33301 12.6173L4.27301 13.5573L7.99967 9.83067L11.7263 13.5573L12.6663 12.6173L8.93967 8.89067L12.6663 5.164Z"
                    fill="#6B7A99"
                  />
                </svg>
              </div>
            </div>
            <HseSpace size="small" vertical />
            <div class="cols two-cols">
              <ReadonlyInput
                :label="$t('fields.name.lName')"
                :value="moderator.lastName"
              />
              <ReadonlyInput
                :label="$t('fields.name.fName')"
                :value="moderator.firstName"
              />
              <ReadonlyInput
                v-if="moderator.secondName"
                :label="$t('fields.name.mName')"
                :value="moderator.secondName"
              />
              <ReadonlyInput
                :label="$t('fields.name.email')"
                :value="moderator.email"
              />
              <ReadonlyInput
                :label="$t('fields.name.companyShort')"
                :value="moderator.organizationName"
              />
              <!-- <ReadonlyInput
                :label="$t('pass')"
                :value="moderator.pass === true ? $t('yes') : $t('no')"
              />
              <ReadonlyInput
                :label="$t('letter')"
                :value="moderator.letter === true ? $t('yes') : $t('no')"
              /> -->
              <div
                :style="{ width: '100%' }"
                v-if="
                  isSendedQRForModerators2 &&
                  (statusPublicated || statusPublication) &&
                  moderator.secondName
                "
              />
              <HseButton
                :style="{ marginLeft: '15px' }"
                v-if="
                  !(
                    moderator.participationFormatId ===
                      '1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A' &&
                    fields.specialEventOrganizationFormatId.value ===
                      '895BB6AB-A343-48DC-AA19-7EAB348C8C0D'
                  ) &&
                  isSendedQRForModerators2 &&
                  !isAllModeratorOnline2 &&
                  (statusPublicated || statusPublication)
                "
                variant="primary"
                @click="() => openQRCodeModal('moderator', moderator.id)"
                size="medium"
              >
                {{ $t("QRCodeForModerator") }}
              </HseButton>
            </div>
            <div
              class="hse-RichInput__message"
              v-bind:style="{ color: '#ff564e' }"
              v-show="
                !moderator.PassportId &&
                !moderator.HSEMember &&
                moderator.IsRequiredPassport
              "
            >
              Пожалуйста, прикрепите копию паспорта
            </div>
          </div>
          <!-- <div
            class="actions"
            v-if="
              !isSendedQRForModerators2 &&
              !isAllModeratorOnline2 &&
              (statusPublicated || statusPublication)
            "
          >
            <HseButton
              variant="primary"
              @click="() => openQRCodeModal('moderator')"
              size="medium"
            >
              {{ $t("QRCodeForModerators") }}
            </HseButton>
          </div> -->
        </div>
      </div>
    </div>

    <div>
      <HseSpace size="small" vertical />
      <div
        :style="{ background: '#6B7A99', padding: '6px' }"
        class="accordion__head"
      >
        <HseButton
          variant="tertiaryInvert"
          size="medium"
          v-on:click="isSpeakerBlockOpen = !isSpeakerBlockOpen"
        >
          {{ $t("speakerBlock") }}
          <span class="ident" v-show="isSpeakerBlockOpen">&#9650;</span>
          <span class="ident" v-show="!isSpeakerBlockOpen">&#9660;</span>
        </HseButton>
        <HseButton
          v-if="!(fieldsDisabled === true) && fieldsDisabled !== 'membersOnly'"
          size="medium"
          :rightIcon="plusIcon"
          @click="openSpeakerModal"
        >
          {{ $t("addParticipant") }}
        </HseButton>
        <div
          class="accordion__info"
          v-show="!(isSpeakerBlockOpen && speakers.length)"
        >
          {{ $t("speakerInfo") }}
        </div>
      </div>
      <div
        v-show="isSpeakerBlockOpen && speakers.length"
        :style="{ background: '#F0F2F5', padding: '6px' }"
      >
        <div class="accordion">
          <div
            class="participant"
            v-for="(speaker, index) in speakers"
            :key="speaker.email"
          >
            <div class="participant__heading">
              <HseHeading size="h4">{{
                `${$t("fields.name.speakers")} №${index + 1}`
              }}</HseHeading>
              <div
                class="icons"
                v-if="
                  !(fieldsDisabled === true) && fieldsDisabled !== 'membersOnly'
                "
              >
                <svg
                  @click="() => editSpeaker(speaker, index)"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.60161 5.35704L11.4995 7.26425L6.69535 12.092L4.7985 10.1848L9.60161 5.35704ZM12.9397 4.89707L12.0933 4.04652C11.7662 3.71781 11.235 3.71781 10.9068 4.04652L10.0961 4.86126L11.994 6.76849L12.9397 5.81814C13.1934 5.56317 13.1934 5.15202 12.9397 4.89707ZM3.80527 12.8655C3.77073 13.0217 3.91108 13.1617 4.06654 13.1237L6.18147 12.6084L4.28461 10.7012L3.80527 12.8655Z"
                    fill="#6B7A99"
                  />
                </svg>
                <svg
                  @click="() => removeSpeaker(speaker)"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.6663 5.164L11.7263 4.224L7.99967 7.95067L4.27301 4.224L3.33301 5.164L7.05967 8.89067L3.33301 12.6173L4.27301 13.5573L7.99967 9.83067L11.7263 13.5573L12.6663 12.6173L8.93967 8.89067L12.6663 5.164Z"
                    fill="#6B7A99"
                  />
                </svg>
              </div>
            </div>
            <HseSpace size="small" vertical />
            <div class="cols two-cols">
              <ReadonlyInput
                :label="$t('fields.name.lName')"
                :value="speaker.lastName"
              />
              <ReadonlyInput
                :label="$t('fields.name.fName')"
                :value="speaker.firstName"
              />
              <ReadonlyInput
                v-if="speaker.secondName"
                :label="$t('fields.name.mName')"
                :value="speaker.secondName"
              />
              <ReadonlyInput
                :label="$t('fields.name.email')"
                :value="speaker.email"
              />
              <ReadonlyInput
                :label="$t('fields.name.companyShort')"
                :value="speaker.organizationName"
              />
              <!-- <ReadonlyInput
                :label="$t('pass')"
                :value="speaker.pass === true ? $t('yes') : $t('no')"
              />
              <ReadonlyInput
                :label="$t('letter')"
                :value="speaker.letter === true ? $t('yes') : $t('no')"
              /> -->
              <div
                :style="{ width: '100%' }"
                v-if="
                  isSendedQRForSpeakers2 &&
                  (statusPublicated || statusPublication) &&
                  speaker.secondName
                "
              />
              <HseButton
                :style="{ marginLeft: '15px' }"
                v-if="
                  !(
                    speaker.participationFormatId ===
                      '1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A' &&
                    fields.specialEventOrganizationFormatId.value ===
                      '895BB6AB-A343-48DC-AA19-7EAB348C8C0D'
                  ) &&
                  isSendedQRForSpeakers2 &&
                  !isAllSpeakersOnline2 &&
                  (statusPublicated || statusPublication)
                "
                variant="primary"
                @click="() => openQRCodeModal('speaker', speaker.id)"
                size="medium"
              >
                {{ $t("QRCodeForSpeaker") }}
              </HseButton>
            </div>
            <div
              class="hse-RichInput__message"
              v-bind:style="{ color: '#ff564e' }"
              v-show="
                !speaker.PassportId &&
                !speaker.HSEMember &&
                speaker.IsRequiredPassport
              "
            >
              Пожалуйста, прикрепите копию паспорта
            </div>
          </div>
          <!-- <div
            class="actions"
            v-if="
              !isSendedQRForSpeakers2 &&
              !isAllSpeakersOnline2 &&
              (statusPublicated || statusPublication)
            "
          >
            <HseButton
              variant="primary"
              @click="() => openQRCodeModal('speaker')"
              size="medium"
            >
              {{ $t("QRCodeForSpeakers") }}
            </HseButton>
          </div> -->
        </div>
      </div>
    </div>

    <div>
      <HseSpace size="small" vertical />
      <div
        :style="{ background: '#6B7A99', padding: '6px' }"
        class="accordion__head"
      >
        <HseButton
          variant="tertiaryInvert"
          size="medium"
          v-on:click="isMemberBlockOpen = !isMemberBlockOpen"
        >
          {{ $t("memberBlock") }}
          <span class="ident" v-show="isMemberBlockOpen">&#9650;</span>
          <span class="ident" v-show="!isMemberBlockOpen">&#9660;</span>
        </HseButton>
        <HseButton
          v-if="!(fieldsDisabled === true) || fieldsDisabled === 'membersOnly'"
          size="medium"
          :rightIcon="plusIcon"
          @click="openMemberModal"
        >
          {{ $t("addParticipant") }}
        </HseButton>
        <div
          class="accordion__info"
          v-show="!(isMemberBlockOpen && members.length)"
        >
          {{ $t("memberInfo") }}
        </div>
      </div>
      <div
        v-show="isMemberBlockOpen && members.length"
        :style="{ background: '#F0F2F5', padding: '6px' }"
      >
        <div class="accordion">
          <div
            class="participant"
            v-for="(member, index) in members"
            :key="member.email"
          >
            <div class="participant__heading">
              <HseHeading size="h4">{{
                `${$t("fields.name.members")} №${index + 1}`
              }}</HseHeading>
              <div
                class="icons"
                v-if="
                  !(fieldsDisabled === true) || fieldsDisabled === 'membersOnly'
                "
              >
                <svg
                  @click="() => editMember(member, index)"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.60161 5.35704L11.4995 7.26425L6.69535 12.092L4.7985 10.1848L9.60161 5.35704ZM12.9397 4.89707L12.0933 4.04652C11.7662 3.71781 11.235 3.71781 10.9068 4.04652L10.0961 4.86126L11.994 6.76849L12.9397 5.81814C13.1934 5.56317 13.1934 5.15202 12.9397 4.89707ZM3.80527 12.8655C3.77073 13.0217 3.91108 13.1617 4.06654 13.1237L6.18147 12.6084L4.28461 10.7012L3.80527 12.8655Z"
                    fill="#6B7A99"
                  />
                </svg>
                <svg
                  @click="() => removeMember(member)"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.6663 5.164L11.7263 4.224L7.99967 7.95067L4.27301 4.224L3.33301 5.164L7.05967 8.89067L3.33301 12.6173L4.27301 13.5573L7.99967 9.83067L11.7263 13.5573L12.6663 12.6173L8.93967 8.89067L12.6663 5.164Z"
                    fill="#6B7A99"
                  />
                </svg>
              </div>
            </div>
            <HseSpace size="small" vertical />
            <div class="cols two-cols">
              <ReadonlyInput
                :label="$t('fields.name.lName')"
                :value="member.lastName"
              />
              <ReadonlyInput
                :label="$t('fields.name.fName')"
                :value="member.firstName"
              />
              <ReadonlyInput
                v-if="member.secondName"
                :label="$t('fields.name.mName')"
                :value="member.secondName"
              />
              <ReadonlyInput
                :label="$t('fields.name.email')"
                :value="member.email"
              />
              <ReadonlyInput
                :label="$t('fields.name.companyShort')"
                :value="member.organizationName"
              />
              <ReadonlyInput
                :label="$t('fields.roundTable.participationStatusId')"
                :value="
                  $t(formatParticipationStatusId(member.participationStatusId))
                "
              />
              <!-- <ReadonlyInput
                :label="$t('pass')"
                :value="member.pass === true ? $t('yes') : $t('no')"
              />
              <ReadonlyInput
                :label="$t('letter')"
                :value="member.letter === true ? $t('yes') : $t('no')"
              /> -->
              <div
                :style="{ width: '100%' }"
                v-if="
                  isSendedQRForMembers2 &&
                  !isAllMemberOnline2 &&
                  (statusPublicated || statusPublication) &&
                  !member.secondName
                "
              />
              <HseButton
                :style="{ marginLeft: '15px' }"
                v-if="
                  !(
                    member.participationFormatId ===
                      '1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A' &&
                    fields.specialEventOrganizationFormatId.value ===
                      '895BB6AB-A343-48DC-AA19-7EAB348C8C0D'
                  ) &&
                  member.participationStatusId ===
                    '99815A4C-EACB-43CD-AD99-5275549F1631' &&
                  isSendedQRForMembers2 &&
                  !isAllMemberOnline2 &&
                  (statusPublicated || statusPublication)
                "
                variant="primary"
                @click="() => openQRCodeModal('member', member.id)"
                size="medium"
              >
                {{ $t("QRCodeForMember") }}
              </HseButton>
            </div>
            <div
              class="hse-RichInput__message"
              v-bind:style="{ color: '#ff564e' }"
              v-show="
                !member.PassportId &&
                !member.HSEMember &&
                member.IsRequiredPassport
              "
            >
              Пожалуйста, прикрепите копию паспорта
            </div>
          </div>
          <div class="actions" v-if="statusPublicated || statusPublication">
            <!-- <HseButton
              v-if="!isSendedQRForMembers2 && !isAllMemberOnline2"
              @click="() => openQRCodeModal('member')"
              variant="primary"
              size="medium"
            >
              {{ $t("QRCodeForMembers") }}
            </HseButton> -->
            <HseButton
              variant="primary"
              size="medium"
              @click="openWebinarModal"
              v-if="
                statusPublicated && this.data.webinarLink && showWebinarLink
              "
            >
              {{ $t("Webinar") }}
            </HseButton>
            <HseButton variant="primary" @click="approveAll" size="medium">
              {{ $t("Approve") }}
            </HseButton>
          </div>
        </div>
      </div>
      <HseSpace size="small" vertical />
    </div>

    <div class="actions">
      <HseLink variant="secondary" @click="back">
        {{ $t("back") }}
      </HseLink>
      <div class="actions">
        <HseButton
          variant="primary"
          :spinner="draftSpinner"
          spinnerPosition="left"
          size="medium"
          @click="draft"
        >
          {{ $t("draft") }}
        </HseButton>
        <HseButton
          variant="primary"
          :spinner="submitSpinner"
          spinnerPosition="left"
          size="medium"
          @click="submit"
        >
          {{ $t("submitSecond") }}
        </HseButton>
      </div>
    </div>

    <HseModal v-model="isSecretaryModalOpen" :shouldCloseOnOverlayClick="false">
      <SecretaryForm
        v-if="isSecretaryModalOpen"
        :editableSecretary="editableSecretary"
        :added="secretary"
        :rtId="eventId"
        @saved="onSecretarySave"
        :showFormat="fieldsDisabled"
        :orgFormat="fields.specialEventOrganizationFormatId.value"
        @updateSecretary="updateSecretary"
      />
    </HseModal>
    <HseModal v-model="isModeratorModalOpen" :shouldCloseOnOverlayClick="false">
      <ModeratorForm
        v-if="isModeratorModalOpen"
        :editableModerator="editableModerator"
        :added="moderators"
        @saved="onModeratorSave"
        :showFormat="fieldsDisabled"
        :orgFormat="fields.specialEventOrganizationFormatId.value"
        :IsRequiredPassport="IsRequiredPassport"
        :IsRequiredPassportRussia="IsRequiredPassportRussia"
        :IsRequiredPassportOther="IsRequiredPassportOther"
      />
    </HseModal>

    <HseModal v-model="isSpeakerModalOpen" :shouldCloseOnOverlayClick="false">
      <SpeakerForm
        v-if="isSpeakerModalOpen"
        :editableSpeaker="editableSpeaker"
        :added="speakers"
        @saved="onSpeakerSave"
        :showFormat="fieldsDisabled"
        :orgFormat="fields.specialEventOrganizationFormatId.value"
        :IsRequiredPassport="IsRequiredPassport"
        :IsRequiredPassportRussia="IsRequiredPassportRussia"
        :IsRequiredPassportOther="IsRequiredPassportOther"
      />
    </HseModal>

    <HseModal v-model="isMemberModalOpen" :shouldCloseOnOverlayClick="false">
      <MemberForm
        v-if="isMemberModalOpen"
        :editableMember="editableMember"
        :added="members"
        @saved="onMemberSave"
        :showFormat="fieldsDisabled"
        :orgFormat="fields.specialEventOrganizationFormatId.value"
        :IsRequiredPassport="IsRequiredPassport"
        :IsRequiredPassportRussia="IsRequiredPassportRussia"
        :IsRequiredPassportOther="IsRequiredPassportOther"
      />
    </HseModal>

    <HseModal v-model="isWarningModalOpen" :shouldCloseOnOverlayClick="false">
      <WarningModalVue
        :isFormatEdit="isEditFormat"
        @closeWarningModal="() => closeWarningModal()"
      />
    </HseModal>
  </div>
</template>

<script>
import {
  HseSpace,
  HseButton,
  HseLink,
  HseModal,
  IconActionPlus,
  HseHeading,
  HseTooltip,
  IconStatusInfo,
} from "@hse-design/vue";
import Validator from "../common/form/Validator";
import RadioGroup from "../common/form/RadioGroup";
import Datepicker from "../common/form/Datepicker";
import { mapState } from "vuex";
import { api } from "../../api";
import ModeratorForm from "./ModeratorForm.vue";
import SpeakerForm from "./SpeakerForm.vue";
import MemberForm from "./MemberForm.vue";
import ReadonlyInput from "../common/ReadonlyInput.vue";
// import CheckboxGroup from '../common/form/CheckboxGroup.vue';
import SecretaryForm from "./SecretaryForm.vue";
import WarningModalVue from "./WarningModal.vue";

export default {
  name: "FormPage2",
  components: {
    RadioGroup,
    HseSpace,
    Datepicker,
    Validator,
    HseButton,
    HseLink,
    ModeratorForm,
    SpeakerForm,
    MemberForm,
    HseHeading,
    // CheckboxGroup,
    IconStatusInfo,
    HseTooltip,
    SecretaryForm,
    HseModal,
    ReadonlyInput,
    WarningModalVue,
  },
  data: () => ({
    isEditFormat: false,
    citizen: null,
    IsRequiredPassport: false,
    IsRequiredPassportRussia: false,
    IsRequiredPassportOther: false,
    plusIcon: IconActionPlus,
    draftSpinner: false,
    submitSpinner: false,
    secretary: [],
    fieldsToValidate: [
      // "specialEventOrganizationFormatId",
      // "massMediaId",
      // "maxParticipants",
      // "endRegistrationDate",
      // "volunteerSupportBlocksId",
      "RTname",
      "RTnameEn",
      "discussionQuestions",
      "discussionQuestionsEn",
      "ResponsibleFIO",
      "ResponsiblePost",
      "ResponsibleDepartmenID",
      "ResponsibleEmail",
      "ResponsiblePhone",
      // "specialEventOrganizationFormatId",
      "NumberParticipants",
      "date",
      "programSessionTimeId",
      "publicityId",
      "langRu",
      "langEn",
      "langOther",
      "other",
      "syncTranslation",
      "secretaryOffline",
      "secretaryOnline",
    ],
    radiosToValidate: [
      "syncTranslation",
      "needOnlineSupport",
      "needVolunteerSupport",
      // "specialEventOrganizationFormatId",
      "needOfficialPhoto",
      "needOfficialVideo",
      "needOnlineTranslation",
    ],
    isSecretaryModalOpen: false,
    editableSecretary: null,
    isModeratorBlockOpen: true,
    isModeratorModalOpen: false,
    moderators: [],
    editableModerator: null,
    isSpeakerBlockOpen: true,
    isSpeakerModalOpen: false,
    speakers: [],
    editableSpeaker: null,
    isMemberBlockOpen: true,
    isMemberModalOpen: false,
    isWarningModalOpen: false,
    members: [],
    membersChanged: false,
    editableMember: null,

    isSendedQRForSpeakers2: null,
    isAllSpeakersOnline2: null,
    isSendedQRForModerators2: null,
    isAllModeratorOnline2: null,
    isSendedQRForMembers2: null,
    isAllMemberOnline2: null,
  }),
  props: [
    "fields",
    "eventId",
    "user",
    "isNew",
    "data",
    "datanew",
    "rtId",
    "fieldsDisabled",
    "publicityForTranslation",
  ],
  created() {
    this.fields.specialEventOrganizationFormatId.getAllOptions();
    this.fields.volunteerSupportBlocksId.getAllOptions();
    //this.fields.publicityId.getAllOptions()

    api
      .request("get_is_required_passport", { EventId: this.eventId })
      .then((data) => {
        this.IsRequiredPassport = data.IsRequiredPassport;
        this.IsRequiredPassportRussia = data.IsRequiredPassportRussia;
        this.IsRequiredPassportOther = data.IsRequiredPassportOther;
      });
  },
  methods: {
    dateFormat(date) {
      return date.split(".").reverse().join("-");
    },
    convertGuid(value) {
      return !value ? null : value;
    },
    submit() {
      this.saveForm(false);
    },
    draft() {
      this.saveForm(true);
    },
    back() {
      this.$router.push("/events");
    },
    openSecretaryModal() {
      this.isSecretaryModalOpen = true;
      this.editableSecretary = null;
    },
    formatParticipationStatusId(id) {
      const obj = {
        "99815A4C-EACB-43CD-AD99-5275549F1631": "approved",
        "600BCF81-1B99-422A-B1E3-53A994058F87": "rejected",
        "489B1F10-AA4F-4E5E-A01A-70B03F5BC72C": "sent",
      };
      return obj[id];
    },
    updateSecretary(val) {
      this.fields.secretaryOffline.setOptions(val);
      this.fields.secretaryOnline.setOptions(val);
      this.$emit("loadAllOptionsSecretary");
    },
    onSecretarySave(result) {
      // this.fields.secretaryOnline.setValues(false, String(result.ID))
      console.log(result);
      this.membersChanged = true;
      this.editableSecretary = null;
      this.isSecretaryModalOpen = false;
    },
    closeWarningModal() {
      this.isWarningModalOpen = false;
      this.isEditFormat = false;
      this.draftSpinner = false;
    },
    openModeratorModal() {
      this.isModeratorModalOpen = true;
      this.editableModerator = null;
    },
    editModerator(moderator, index) {
      this.editableModerator = { ...moderator, index };
      this.isModeratorModalOpen = true;
    },
    onModeratorSave(result) {
      if (!this.editableModerator) {
        this.moderators.push(result);
      } else {
        this.moderators[this.editableModerator.index] = result;
      }
      this.fields.moderators.setValues(true, this.moderators);
      this.editableModerator = null;
      this.isModeratorModalOpen = false;
    },
    removeModerator({ id }) {
      if (!this.isNew) {
        this.fields.moderatorsDeleteIds.setValues(true, [
          ...this.fields.moderatorsDeleteIds.value,
          this.moderators.find((moderator) => moderator.id === id).id,
        ]);
      }
      this.moderators = this.moderators.filter(
        (moderator) => moderator.id !== id
      );
      this.fields.moderators.setValues(true, this.moderators);
      this.editableModerator = null;
    },
    openSpeakerModal() {
      this.isSpeakerModalOpen = true;
      this.editableSpeaker = null;
    },
    editSpeaker(speaker, index) {
      this.editableSpeaker = { ...speaker, index };
      this.isSpeakerModalOpen = true;
    },
    onSpeakerSave(result) {
      if (!this.editableSpeaker) {
        this.speakers.push(result);
      } else {
        this.speakers[this.editableSpeaker.index] = result;
      }
      this.fields.speakers.setValues(true, this.speakers);
      this.editableSpeaker = null;
      this.isSpeakerModalOpen = false;
    },
    removeSpeaker({ id }) {
      if (!this.isNew) {
        this.fields.speakersDeleteIds.setValues(true, [
          ...this.fields.speakersDeleteIds.value,
          this.speakers.find((speaker) => speaker.id === id).id,
        ]);
      }
      this.speakers = this.speakers.filter((speaker) => speaker.id !== id);
      this.fields.speakers.setValues(true, this.speakers);
      this.editableSpeaker = null;
    },
    openMemberModal() {
      this.isMemberModalOpen = true;
      this.editableMember = null;
    },
    editMember(member, index) {
      this.editableMember = { ...member, index };
      this.isMemberModalOpen = true;
    },
    onMemberSave(result) {
      this.membersChanged = true;
      if (!this.editableMember) {
        this.members.push(result);
      } else {
        this.members[this.editableMember.index] = result;
      }
      this.fields.members.setValues(true, this.members);
      this.editableMember = null;
      this.isMemberModalOpen = false;
    },
    removeMember({ id }) {
      this.membersChanged = true;
      if (!this.isNew) {
        this.fields.membersDeleteIds.setValues(true, [
          ...this.fields.membersDeleteIds.value,
          this.members.find((member) => member.id === id).id,
        ]);
      }
      this.members = this.members.filter((member) => member.id !== id);
      this.fields.members.setValues(true, this.members);
      this.editableMember = null;
    },
    statusPublication() {
      return (
        this.data.roundTableStatusId === "28AE374D-D74C-4F4B-857C-E4E5BF0FF90A"
      );
    },
    updateTimestamps(val) {
      this.fields.programSessionTimeId.setDate(this.dateFormat(val));
      this.$emit("loadAllOptionsTime");
    },
    isSendedQRForModerators() {
      return this.moderators.some((item) => item.pass === true);
    },
    isAllModeratorOnline() {
      return this.moderators.every(
        (item) =>
          item.participationFormatId === "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A"
      );
    },
    statusPublicated() {
      return (
        this.data.roundTableStatusId === "C08814A5-7780-4595-9A31-AF51383E3933"
      );
    },
    isSendedQRForSpeakers() {
      return this.speakers.some((item) => item.pass === true);
    },
    isAllSpeakersOnline() {
      return this.speakers.every(
        (item) =>
          item.participationFormatId === "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A"
      );
    },
    isSendedQRForMembers() {
      return this.members.some((item) => item.pass === true);
    },
    isAllMemberOnline() {
      return this.members.every(
        (item) =>
          item.participationFormatId === "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A"
      );
    },
    approveAll() {
      if (
        this.members.some(
          (member) =>
            member.participationStatusId !==
            "99815A4C-EACB-43CD-AD99-5275549F1631"
        )
      ) {
        this.membersChanged = true;
        this.members = this.members.map((member) => ({
          ...member,
          participationStatusId: "99815A4C-EACB-43CD-AD99-5275549F1631",
        }));
        this.fields.members.setValues(true, this.members);
      }
    },
    setFormat(format) {
      this.fields.specialEventOrganizationFormatId.setValues(true, format);
      if (format === "895BB6AB-A343-48DC-AA19-7EAB348C8C0D") {
        // this.isWarningModalOpen = true;
        this.isEditFormat = true;
        if (this.fields.members)
          this.fields.members.setValues(
            true,
            this.fields.members.value.map((item) => {
              return {
                ...item,
                participationFormatId: (item.participationFormatId =
                  "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A"),
              };
            })
          );
        if (this.members)
          this.members = this.members.map((item) => {
            return {
              ...item,
              participationFormatId: (item.participationFormatId =
                "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A"),
            };
          });

        if (this.fields.moderators)
          this.fields.moderators.setValues(
            true,
            this.fields.moderators.value.map((item) => {
              return {
                ...item,
                participationFormatId: (item.participationFormatId =
                  "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A"),
              };
            })
          );
        if (this.moderators)
          this.moderators = this.moderators.map((item) => {
            return {
              ...item,
              participationFormatId: (item.participationFormatId =
                "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A"),
            };
          });

        if (this.fields.speakers)
          this.fields.speakers.setValues(
            true,
            this.fields.speakers.value.map((item) => {
              return {
                ...item,
                participationFormatId: (item.participationFormatId =
                  "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A"),
              };
            })
          );
        if (this.speakers)
          this.speakers = this.speakers.map((item) => {
            return {
              ...item,
              participationFormatId: (item.participationFormatId =
                "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A"),
            };
          });
      } else {
        if (this.fields.members)
          this.fields.members.setValues(
            true,
            this.fields.members.value.map((item) => {
              return {
                ...item,
                participationFormatId: (item.participationFormatId = format),
              };
            })
          );
        if (this.members)
          this.members = this.members.map((item) => {
            return {
              ...item,
              participationFormatId: (item.participationFormatId = format),
            };
          });

        if (this.fields.moderators)
          this.fields.moderators.setValues(
            true,
            this.fields.moderators.value.map((item) => {
              return {
                ...item,
                participationFormatId: (item.participationFormatId = format),
              };
            })
          );
        if (this.moderators)
          this.moderators = this.moderators.map((item) => {
            return {
              ...item,
              participationFormatId: (item.participationFormatId = format),
            };
          });

        if (this.fields.speakers)
          this.fields.speakers.setValues(
            true,
            this.fields.speakers.value.map((item) => {
              return {
                ...item,
                participationFormatId: (item.participationFormatId = format),
              };
            })
          );
        if (this.speakers)
          this.speakers = this.speakers.map((item) => {
            return {
              ...item,
              participationFormatId: (item.participationFormatId = format),
            };
          });
      }
    },
    saveForm(draft) {
      if (
        this.fields.specialEventOrganizationFormatId.value ===
        "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A"
      ) {
        this.fields.maxParticipants.setRequired(false);
        this.fields.maxParticipants.setValues(true, "");
      }
      if (
        this.fields.needVolunteerSupport.value === 1 &&
        this.fields.volunteerSupportBlocksId.value.length === 0
      )
        this.fields.volunteerSupportBlocksId.setValues(false, []);
      if (
        +new Date(
          this.fields.endRegistrationDate.value.split(".").reverse().join("-")
        ) +
          new Date().getTimezoneOffset() * 60 * 1000 >
        +new Date(this.fields.date.value.split(".").reverse().join("-"))
      )
        return;
      this.$emit("validateFields");
      let isPageValid = !this.fieldsToValidate.some((key) => {
        console.log(key, "key");
        console.log(
          this.fields.specialEventOrganizationFormatId.value,
          " this.fields.specialEventOrganizationFormatId.value"
        );
        if (
          key === "NumberParticipants" &&
          this.fields.specialEventOrganizationFormatId.value ===
            "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A"
        ) {
          return false;
        } else {
          if (
            key === "secretaryOnline" &&
            this.fields.specialEventOrganizationFormatId.value ===
              "592D7A01-E55F-4860-B403-1BB88FDE85A9"
          ) {
            return false;
          } else {
            if (
              key === "secretaryOffline" &&
              this.fields.specialEventOrganizationFormatId.value ===
                "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A"
            ) {
              return false;
            } else {
              if (key === "other" && this.fields.langOther.value != true) {
                // console.log("if", this.fields.langOther.value);
                return false;
              } else {
                // console.log("else", this.fields.langOther.value);
                return !this.fields[key].isValid;
              }
            }
          }
        }
      });
      console.log(
        "🚀 ~ file: FormPage2.vue:1270 ~ saveForm ~  this.fields.langRu:",
        this.fields
      );
      if (
        this.fields.langRu.value === false &&
        this.fields.langEn.value === false &&
        this.fields.langOther.value === false
      ) {
        isPageValid = false;
        console.log(
          "🚀 ~ file: FormPage2.vue:1269 ~ isPageValid ~ isPageValid:",
          isPageValid
        );
      }

      if (
        this.convertGuid(this.fields.moderators.value).some(
          (item) =>
            item.PassportId === null &&
            item.HSEMember != true &&
            item.IsRequiredPassport === true
        ) ||
        this.convertGuid(this.fields.speakers.value).some(
          (item) =>
            item.PassportId === null &&
            item.HSEMember != true &&
            item.IsRequiredPassport === true
        ) ||
        this.convertGuid(this.fields.members.value).some(
          (item) =>
            item.PassportId === null &&
            item.HSEMember != true &&
            item.IsRequiredPassport === true
        )
      ) {
        isPageValid = false;
      }

      console.log(
        "🚀 ~ file: FormPage2.vue:1274 ~ isPageValid ~ isPageValid:",
        isPageValid
      );
      if (isPageValid) {
        if (draft) {
          this.draftSpinner = true;
        } else {
          this.submitSpinner = true;
        }
        const params = {};
        params.rtId = this.isNew ? null : this.rtId;
        params.lang = this.lang;
        params.draft = draft;
        params.eventId = this.eventId;
        params.applicantLastName =
          this.lang === "ru"
            ? this.user.data.public.LName
            : this.user.data.public.LNameEN;
        params.applicantFirstName =
          this.lang === "ru"
            ? this.user.data.public.FName
            : this.user.data.public.FNameEN;
        params.applicantSecondName =
          this.lang === "ru" ? this.user.data.public.SName : "";
        params.applicantEmail = this.user.login;
        params.RTname = this.fields.RTname.value;
        params.RTnameEn = this.convertGuid(this.fields.RTnameEn.value);
        params.responsibleFio = this.fields.responsibleFio.value;
        params.discussionQuestions = this.fields.discussionQuestions.value;
        params.discussionQuestionsEn = this.convertGuid(
          this.fields.discussionQuestionsEn.value
        );
        params.programSessionTimeId = this.fields.programSessionTimeId.value;
        params.langRu = this.convertGuid(this.fields.langRu.value);
        params.langEn = this.convertGuid(this.fields.langEn.value);
        params.langOther = this.convertGuid(this.fields.langOther.value);
        params.other = this.convertGuid(this.fields.other.value);
        params.specialEventOrganizationFormatId =
          this.fields.specialEventOrganizationFormatId.value;
        params.contactPerson = this.fields.contactPerson.value;
        params.contactPersonEmail = this.fields.contactPersonEmail.value;
        params.comment = this.convertGuid(this.fields.comment.value);
        params.involvedBusinessOrg = this.convertGuid(
          this.fields.involvedBusinessOrg.value
        );
        params.involvedInfoOrg = this.convertGuid(
          this.fields.involvedInfoOrg.value
        );
        params.moderators = this.convertGuid(this.fields.moderators.value);
        params.speakers = this.convertGuid(this.fields.speakers.value);
        params.members = this.convertGuid(this.fields.members.value);
        params.moderatorsDeleteIds = this.convertGuid(
          this.fields.moderatorsDeleteIds.value
        );
        params.speakersDeleteIds = this.convertGuid(
          this.fields.speakersDeleteIds.value
        );
        params.membersDeleteIds = this.convertGuid(
          this.fields.membersDeleteIds.value
        );
        params.SecretaryOnline =
          this.fields.specialEventOrganizationFormatId.value ===
            "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A" ||
          this.fields.specialEventOrganizationFormatId.value ===
            "895BB6AB-A343-48DC-AA19-7EAB348C8C0D"
            ? this.convertGuid(this.fields.secretaryOnline.value)
            : null;
        params.SecretaryOffline =
          this.fields.specialEventOrganizationFormatId.value ===
            "592D7A01-E55F-4860-B403-1BB88FDE85A9" ||
          this.fields.specialEventOrganizationFormatId.value ===
            "895BB6AB-A343-48DC-AA19-7EAB348C8C0D"
            ? this.convertGuid(this.fields.secretaryOffline.value)
            : null;

        if (params.members.length) {
          params.members = params.members.map((member) => {
            const obj = { ...member };
            if (this.isMixed) {
              if (!member.participationFormatId) {
                obj.participationFormatId =
                  "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A";
              }
            } else {
              obj.participationFormatId =
                this.fields.specialEventOrganizationFormatId.value;
            }
            return obj;
          });
        }

        Object.keys(this.fields).forEach((key) => {
          const { value } = this.fields[key];

          if (key === "publicityId") {
            params[key] = this.fields[key].value;
          }

          if (key === "date" || key === "endRegistrationDate") {
            params[key] = this.dateFormat(value);
          } else if (key === "maxParticipants") {
            params[key] = +value;
          } else if (this.fieldsToValidate.includes(key)) {
            params[key] = this.convertGuid(value);
          } else if (this.radiosToValidate.includes(key)) {
            if (typeof value === "number") {
              params[key] = !!value;
            } else {
              params[key] = value;
            }
          }
        });
        if (
          this.fields.moderators.value.length === 0 ||
          this.fields.speakers.value.length === 0
        ) {
          this.isWarningModalOpen = true;
          this.submitSpinner = false;
          return;
        }

        params.LName = this.fields.LName.value;
        params.FName = this.fields.FName.value;
        params.SName = this.fields.SName.value;
        params.Position = this.fields.Position.value;
        params.DepartmentIdRu = this.fields.DepartmentIdRu.value;
        params.Phone = this.fields.Phone.value;
        params.Email = this.fields.Email.value;

        params.StatusRT = this.fields.StatusRT.value;

        params.plannedRTname = this.fields.plannedRTname.value;
        params.plannedRTnameEn = this.fields.plannedRTnameEn.value;

        params.plannedSpecialEventOrganizationFormatId =
          this.fields.plannedSpecialEventOrganizationFormatId.value;

        params.plannedDiscussionQuestions =
          this.fields.plannedDiscussionQuestions.value;
        params.plannedDdiscussionQuestionsEn =
          this.fields.plannedDdiscussionQuestionsEn.value;

        params.comment = this.fields.comment.value;
        params.involvedBusinessOrg = this.fields.involvedBusinessOrg.value;
        params.involvedInfoOrg = this.fields.involvedInfoOrg.value;

        params.ChiefFIO = this.fields.ChiefFIO.value;
        params.ChiefPost = this.fields.ChiefPost.value;
        params.ChiefDepartmenID = parseInt(this.fields.ChiefDepartmenID.value);
        params.ChiefEmail = this.fields.ChiefEmail.value;

        console.log(
          "🚀 ~ file: FormPage2.vue:1204 ~ api.request ~ params:",
          params
        );
        params.secondPhase = true;
        api.request("add_rt_data", params).then((data) => {
          if (draft) {
            this.draftSpinner = "done";
          } else {
            this.submitSpinner = "done";
          }
          setTimeout(() => {
            if (draft) {
              this.draftSpinner = false;
            } else {
              this.submitSpinner = false;
            }
            if (data && data.length) {
              this.$emit("saved", draft);
            } else {
              this.$emit("savedErr");
            }
          }, 2000);
        });
      }
    },
    setNeedOnlineSupport(val) {
      this.fields.needOnlineSupport.setValues(true, val);
    },
    setNeedVolunteerSupport(val) {
      this.fields.needVolunteerSupport.setValues(true, val);
    },
    setSyncTranslation(val) {
      this.fields.syncTranslation.setValues(true, val);
    },
    setNeedOfficialPhoto(val) {
      this.fields.needOfficialPhoto.setValues(true, val);
    },
    setNeedOfficialVideo(val) {
      this.fields.needOfficialVideo.setValues(true, val);
    },
    setNeedOnlineTranslation(val) {
      this.fields.needOnlineTranslation.setValues(true, val);
    },
    setPublicityId(val) {
      this.fields.publicityId.setValues(true, val);
    },
    setVolunteerSupportBlocks(val) {
      this.fields.volunteerSupportBlocksId.setValues(true, val);
    },
  },
  computed: {
    isOffline() {
      return (
        this.fields.specialEventOrganizationFormatId.value ===
        "592D7A01-E55F-4860-B403-1BB88FDE85A9"
      );
    },
    isOnline() {
      return (
        this.fields.specialEventOrganizationFormatId.value ===
        "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A"
      );
    },
    yesnoOptions() {
      const yes = this.$i18n.tc("yes");
      const no = this.$i18n.tc("no");
      return [
        { label: yes, value: 1 },
        { label: no, value: 0 },
      ];
    },
    isMixed() {
      return (
        this.fields.specialEventOrganizationFormatId.value ===
        "895BB6AB-A343-48DC-AA19-7EAB348C8C0D"
      );
    },
    ...mapState(["lang"]),
  },
  watch: {
    data() {
      if (this.fieldsDisabled) {
        [...this.fieldsToValidate, ...this.radiosToValidate].forEach((key) => {
          this.fields[key].setDisabled(true);
        });
      }
      if (this.data) {
        this.fields.RTname.setValues(
          false,
          this.data.PlannedName != this.data.RTname
            ? this.data.RTname
            : this.data.PlannedName
        );

        this.fields.RTnameEn.setValues(
          false,
          this.data.PlannedNameEn != this.data.RTnameEn
            ? this.data.RTnameEn
            : this.data.PlannedNameEn
        );
        this.fields.discussionQuestions.setValues(
          false,
          this.data.discussionQuestions != ""
            ? this.data.discussionQuestions
            : this.data.PlannedDiscussionQuestions
        );
        this.fields.discussionQuestionsEn.setValues(
          false,
          this.data.discussionQuestionsEn != ""
            ? this.data.discussionQuestionsEn
            : this.data.PlannedDiscussionQuestionsEn
        );

        console.log(
          "🚀 ~ file: FormPage2.vue:1349 ~ data ~ Boolean(this.data.discussionQuestionsEn) :",
          Boolean(this.data.discussionQuestionsEn)
        );
        console.log(
          "🚀 ~ file: FormPage2.vue:1349 ~ data ~  this.data.discussionQuestionsEn != ",
          this.data.discussionQuestionsEn != ""
        );

        // this.fields.specialEventOrganizationFormatId.setValues(
        //   false,
        //   this.data.specialEventOrganizationFormatId != null
        //     ? this.data.specialEventOrganizationFormatId
        //     : this.data.PlannedSpecialEventOrganizationFormatId
        // );

        this.moderators = this.data.moderators || [];
        this.members = this.data.members || [];
        this.speakers = this.data.speakers || [];
        if (this.data.SecretaryOnline) {
          this.fields.secretaryOnline.setValues(
            false,
            String(this.data.SecretaryOnline)
          );
        }
        if (this.data.SecretaryOffline) {
          this.fields.secretaryOffline.setValues(
            false,
            String(this.data.SecretaryOffline)
          );
        }
      }
      if (this.data.speakers) {
        this.isSendedQRForSpeakers2 = this.isSendedQRForSpeakers();
        this.isAllSpeakersOnline2 = this.isAllSpeakersOnline();
      }
      if (this.data.moderators) {
        this.isSendedQRForModerators2 = this.isSendedQRForModerators();
        this.isAllModeratorOnline2 = this.isAllModeratorOnline();
      }
      if (this.data.members) {
        this.isSendedQRForMembers2 = this.isSendedQRForMembers();
        this.isAllMembersOnline2 = this.isAllMembersOnline();
      }
    },
  },
  i18n: {
    messages: {
      en: {
        pass: "Pass",
        letter: "Letter",
        speakerBlock: "Experts",
        moderatorBlock: "Moderators",
        expertBlock: "Expert-speakers",
        memberBlock: "Participants",
        addParticipant: "Add",
        QRCode: "Send QR code",
        Approve: "Approve all",
        eventFormat: "Event format *",
        submit: "Send for review",
        submitSecond: "Send",
        draft: "Save a draft",
        offline: "Offline",
        online: "Online",
        mixed: "Mixed",
        yes: "Yes",
        no: "No",
        massMediaOpen: "Open to media",
        massMediaClosed: "Closed to media",
        massMediaAfterRelease: "Press release on the results of the roundtable",
        open: "Opened",
        close: "Closed",
        onlilneSupportTooltip:
          "Provides technical and organizational support for the event on an online platform",
        errorText: "at least one block must be selected",
        chooseLang: "Choose a language",
        secretary: "Secretary",
        addParticipantSecretary: "Add a secretary",
        approved: "Approved",
        rejected: "Rejected",
        sent: "Sent",
        QRCodeForMembers: "Send QR code to participants",
        QRCodeForMember: "Send QR code to participant",
        QRCodeForSpeakers: "Send qr code to experts",
        QRCodeForSpeaker: "Send qr code to expert",
        QRCodeForModerators: "Send qr code to moderators",
        QRCodeForModerator: "Send qr code to moderator",
        moderatorInfo:
          "The moderator details are captured in the system using their email address, whether it's their own or that of their accompanying person. This is essential for creating a personalized record for the moderator/speaker and for facilitating subsequent organizational tasks, such as registration and pass issuance. We will not use these emails for sending automated notifications. If you have any questions, please feel free to contact the Organizing Committee at YasinConference@hse.ru.",
        speakerInfo:
          "The speaker's details are captured in the system using their email address, whether it's their own or that of their accompanying person. This is essential for creating a personalized record for the moderator/speaker and for facilitating subsequent organizational tasks, such as registration and pass issuance. We will not use these emails for sending automated notifications. If you have any questions, please feel free to contact the Organizing Committee at YasinConference@hse.ru.",
        memberInfo:
          "Participant details are entered in line with the pre-approved application during the organizational preparation of the event.",
      },
      ru: {
        pass: "Пропуск",
        letter: "Письмо",
        speakerBlock: "Эксперты-спикеры",
        moderatorBlock: "Модераторы",
        expertBlock: "Эксперты-спикеры",
        memberBlock: "Участники",
        addParticipant: "Добавить",
        QRCode: "Отправить QR код",
        Approve: "Одобрить всех",
        eventFormat: "Формат мероприятия *",
        submit: "Отправить на проверку",
        submitSecond: "Отправить",
        draft: "Сохранить черновик",
        offline: "Офлайн",
        online: "Онлайн",
        mixed: "Смешанный",
        yes: "Да",
        no: "Нет",
        massMediaOpen: "Открыто для СМИ",
        massMediaClosed: "Закрыто для СМИ",
        massMediaAfterRelease:
          "Пресс-релиз по итогам проведения круглого стола",
        open: "Открытое",
        close: "Закрытое",
        responsibleEvent: "Ответственный за мероприятие",
        onlilneSupportTooltip:
          "Осуществляет техническую и организационную поддержку мероприятия на онлайн-платформе",
        errorText: "необходимо выбрать хотя бы один блок",
        chooseLang: "Выберите язык*",
        secretary: "Секретарь",
        addParticipantSecretary: "Добавить секретаря",
        approved: "Одобрен",
        rejected: "Отклонен",
        sent: "Отправлен",
        QRCodeForMembers: "Отправить qr-код участникам",
        QRCodeForMember: "Отправить qr-код участнику",
        QRCodeForSpeakers: "Отправить qr-код экспертам",
        QRCodeForSpeaker: "Отправить qr-код эксперту",
        QRCodeForModerators: "Отправить qr-код модераторам",
        QRCodeForModerator: "Отправить qr-код модератору",
        moderatorInfo:
          "Данные модератора фиксируются в системе через электронный адрес (свой или сопровождающего). Это необходимо для формирования индивидуальной записи модератора/спикера и для дальнейшей организационной работы, в т.ч. для регистрации и оформления пропуска. Автоматическая рассылка писем не предполагается. В случае вопросов просим обращаться в Организационный комитет конференции: YasinConference@hse.ru",
        speakerInfo:
          "Данные спикера фиксируются в системе через электронный адрес (свой или сопровождающего). Это необходимо для формирования индивидуальной записи модератора/спикера и для дальнейшей организационной работы, в т.ч. для регистрации и оформления пропуска. Автоматическая рассылка писем не предполагается. В случае вопросов просим обращаться в Организационный комитет конференции: YasinConference@hse.ru",
        memberInfo:
          "Информация об участниках заполняется по уже утверждённой заявке, на момент организационной подготовки мероприятия",
        secretaryTooltip:
          "В данном поле необходимо указать секретаря, который будет сопровождать мероприятие или в очном формате, или в формате онлайн. Для назначения секретаря необходимо выбрать его из списка или добавить своего при отсутствии данных в списке. Секретарь - сотрудник / аспирант / студент НИУ ВШЭ, который занимается организационным и техническим сопровождением мероприятия до, во время и по завершении мероприятия.",
      },
    },
  },
};
</script>

<style scoped>
.addButtonSecretary {
  display: flex;
  align-items: flex-end;
}
.errorText {
  color: #ff564e;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.cols {
  display: flex;
  margin: 0 -15px;
  flex-wrap: wrap;
  align-items: center;
}
.cols > .field {
  padding: 0 15px;
  flex: 1;
}

.colssecretary {
  display: flex;
  margin: 0 -15px;
  flex-wrap: wrap;
}
.colssecretary > .field {
  padding: 0 15px;
  flex: 1;
}
.two-cols .field {
  max-width: 50%;
  width: 50%;
  flex-basis: 50%;
}
.three-cols .field {
  max-width: 33.3%;
}
.ident {
  margin-left: 10px;
}
@media (max-width: 767px) {
  .two-cols .field,
  .three-cols .field {
    max-width: 100%;
    width: 100%;
    flex-basis: 100%;
  }
}
.actions {
  flex-wrap: wrap;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}
.accordion {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
}
.accordion__head {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.participant__heading {
  display: flex;
  align-items: center;
  gap: 5px;
}
.participant__heading .icons {
  display: flex;
  gap: 3px;
}

.participant__heading .icons > svg {
  cursor: pointer;
}

.subheading {
  font-family: "Work Sans", sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #0f0f14;
}
.accordion__info {
  font-size: 14px;
  line-height: 1.6em;
  color: #fff;
  padding: 8px 24px;
}

.headingWithTooltip {
  display: flex;
}

.headingWithTooltip > div:first-child {
  padding: 2px 0 0 0;
}

.headingWithTooltip > div:last-child {
  padding: 0px 0 0 12px;
}
</style>
