<template>
  <div class="event-card">
    <Loading v-if="!isDataLoaded" />
    <div v-else class="card">
      <div v-if="!isError">
        <HseHeading size="h3" v-if="status==0">{{ $t('thx') }}</HseHeading>
        <HseHeading size="h3" v-else>{{ $t('dear') }}</HseHeading>
        <HseSpace size='small' vertical />
        <p v-if="status==0">{{ $t('snitch_on_you') }}</p>
        <div v-else>
          <p>{{ $t('go_to_work_1') }}</p>
          <p>{{ $t('go_to_work_2') }}<br>{{dateFormat(confirmResponse.ExpertizeDate)}}</p>
          <p>{{ $t('go_to_work_3') }}</p>
        </div>
      </div>
      <div v-else>
        <HseHeading size="h3" v-if="status==0">{{ $t('error') }}</HseHeading>
        <p>{{ $t('error_1') }}</p>
        <p>{{ $t('error_2') }}</p>
        <p>{{ $t('error_3') }}</p>
      </div>



      <HseSpace size='small' vertical />
      <div class="two-cols">
        <div class="prepull">
        <HseButton v-if="status!=0" class="pull-right" size="medium" v-on:click="home()">Ok</HseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import { api } from '../../api'
  import Loading from '../common/Loading.vue'
  import { HseHeading, HseSpace, HseButton } from '@hse-design/vue' 
  import { mapState } from 'vuex'
  import { store } from '../../store'
  import { dateFormatter } from "../common/dateFormatter";

  export default {
    store,
    components: {
      HseButton,
      HseHeading,
      HseSpace,
      Loading,
    },
    data: () => ({
      showToGuests: true,
      expertId: null,
      eventId: null,
      status: null,
      isDataLoaded: false,
      isError: false,
      confirmResponse: '',
    }),
    created () {
      this.expertId = this.$route.params.expert_id;
      this.event_id = this.$route.params.event_id;
      this.status = this.$route.params.status;
      this.lng = this.$route.params.lng;

      if (this.lng=='ru' || this.lng=='en') {
        this.$store.state.lang = this.lng
        this.$i18n.locale = this.lng
      }


      this.save()
    },
    methods: {
      dateFormat(date) { return dateFormatter.format(date) },
      home() {
        // this.$router.push('/');
        window.location.href = '/'
      },
      save() {
        api.request('expert_confirm_invitation', { 
          expert_id: this.expertId,
          event_id: this.event_id,
          status: this.status
          }).then(response => {
            this.isDataLoaded = true;
            this.confirmResponse = response;
        })

      },
    },
    computed: {
      ...mapState(['error']),
    },
    watch: {
      error(store_error) {
        if (store_error.text.code === 501) {
            this.isError = true;
        }
      }
    },    
    i18n: {
      messages: {
        en: {
          thx: 'Dear colleague,',
          dear: 'Dear expert!',
          snitch_on_you: 'A decision to not review the applications will be sent to the heads of the respective section.',
          go_to_work_1:'Applications for reviewing will be sent to your personal account by the decision of the head of the department.',
          go_to_work_2:'The Program Committee asks you to carry out an examination of the abstracts to the reports before ',
          go_to_work_3:'Best regards, Conference Organizing Committee',
          error:'Error',
          error_1:'An error occurred while validating the verification code.',
          error_2:'This can happen if the action has already been verified or if a new verification code has been sent.',
          error_3:'You can contact Technical Support if you need help solving this problem.',
        },
        ru: {
          thx: 'Благодарим Вас за ответ.',
          dear: 'Уважаемый эксперт!',
          snitch_on_you: 'Решение об отказе рецензирования заявок будет направлено руководителям направления.',
          go_to_work_1:'Заявки для рецензирования будут направлены в Ваш личный кабинет по решению руководителя направления.',
          go_to_work_2:'Программный комитет просит Вас провести экспертизу аннотаций к докладам до ',
          go_to_work_3:'С уважением, Оргкомитет конференции',
          error:'Ошибка',
          error_1:'Произошла ошибка при проверке кода подтверждения.',
          error_2:'Это может произойти, если действие уже было подтверждено или если был отправлен новый код подтверждения.',
          error_3:'Вы можете обратиться в Техническую Поддержку, если Вам необходима помощь в решении этой проблемы.',
        },
      }
    }
  }
</script>

<style scoped>
  .event-card {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 32px;
    max-width: 800px;
    margin: 52px auto;
  }
  .big-item {
    max-width: 780px;
  }
  .link {
    white-space: nowrap;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  .cols-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }
  .prepull{
    overflow:auto;
  }
  .pull-right {
    float:right;
  }
</style>