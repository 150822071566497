<template>
  <article class="session" @click="clickCard">
    <div class="session__control">
      <div>
        <button
          v-if="session.Items.length && isFavorite"
          @click="removeFavorites()"
          class="favorites-button favorites-button--remove"
        >
          <IconStatusStarFilled size="medium" />
        </button>
        <button
          v-else-if="session.Items.length"
          @click="addFavorites()"
          class="favorites-button favorites-button--add"
        >
          <IconStatusStarLinear size="medium" />
        </button>
      </div>
      <section class="session__time">
        <p>{{ session.StartTime }} - {{ session.EndTime }} (UTC +3)</p>
        <p>{{ session.Format }}</p>
        <div class="translation__languages">
          <p v-if="session.LangRu">RUS</p>
          <p v-if="session.LangEn">ENG</p>
          <IconHeadphones v-if="session.IsTranslation" />
        </div>
        <div v-if="session.logoOrganizerId != null">
          <SectionsSessionLogoVue
            :id="
              this.lang === 'ru'
                ? session.logoOrganizerId
                : session.logoOrganizerEnId
            "
          />
        </div>
      </section>
    </div>

    <section class="session__items">
      <h4 class="session__title">
        {{ session.Type }} {{ sectionCode }}-{{ session.Code }}
      </h4>
      <section class="items__item">
        <p v-if="session.TypeId === sessionTypeId">{{ session.Title }}</p>
        <div v-else>
          <p v-for="item in session.Items" :key="item.Id" class="text-3-lines">
            {{ item.Title }}
          </p>
        </div>
        <p
          v-if="
            (session.TypeId === sessionTypeId ||
              session.TypeId === roundtableTypeId) &&
            session.Manager
          "
          class="text-small text-3-lines"
        >
          {{ session.Manager }}
        </p>
        <p
          v-else-if="session.Items[0] && session.Items[0].Manager"
          class="text-small text-3-lines"
        >
          {{ session.Items[0].Manager }}
        </p>
      </section>
      <!-- <section class="items__translation" v-if="session.LangRu || session.LangEn">
        <IconLanguageRu v-if="session.LangRu"/>
        <IconLanguageEn v-if="session.LangEn"/>
        <IconHeadphones v-if="session.IsTranslation"/>
      </section> -->
    </section>
    <HseModal v-model="open">
      <div>
        <ModalFavoritesInfo
          v-if="open"
          :sessionTypeId="sessionTypeId"
          :roundtableTypeId="roundtableTypeId"
          :session="session"
          :sectionCode="sectionCode"
          :date="date"
          :datesMap="datesMap"
        />
      </div>
    </HseModal>
  </article>
</template>

<script>
import {
  IconStatusStarFilled,
  IconStatusStarLinear,
  HseModal,
} from "@hse-design/vue";
import IconHeadphones from "../IconHeadphones";
import { api } from "../../../api";
import ModalFavoritesInfo from "./ModalFavoritesInfo.vue";
import SectionsSessionLogoVue from "./SectionsSessionLogo.vue";
import { mapState } from "vuex";

export default {
  data: () => ({
    isPending: false,
    isFavorite: false,
    open: false,
  }),
  props: {
    sessionTypeId: String,
    roundtableTypeId: String,
    session: Object,
    sectionCode: String,
    updateEventFavorites: Function,
    date: String,
    datesMap: Map,
  },
  computed: {
    ...mapState(["lang"]),
    ufsiiArray() {
      const array = [];
      for (const item of this.session.Items) {
        array.push({
          itemIdSql: item.IdSql,
          ufsii: item.UserFavoriteSessionItemId,
        });
      }

      return array;
    },
  },
  mounted() {
    this.updateIsFavorite(this.session);
  },
  watch: {
    session(newSession) {
      this.updateIsFavorite(newSession);
    },
  },
  methods: {
    clickCard() {
      if (!this.isPending) {
        this.open = true;
      }
    },
    updateIsFavorite(newSession) {
      this.isFavorite = !!newSession.Items[0]?.UserFavoriteSessionItemId;
    },

    async addFavorites() {
      if (this.isPending) {
        return;
      }

      this.isPending = true;
      this.isFavorite = true;

      try {
        const requestArray = [];
        for (const item of this.ufsiiArray) {
          if (item.ufsii !== null) {
            continue;
          }
          requestArray.push(
            api.request(
              "add_user_favorite_session_item",
              { sessionId: this.session.IdSql, itemId: item.itemIdSql },
              []
            )
          );
        }
        const result = await Promise.allSettled(requestArray);
        let idx = 0;
        let rejected = false;
        for (const { status, value } of result) {
          if (
            (Array.isArray(value) && value.length === 0) ||
            status === "rejected"
          ) {
            rejected = true;
            continue;
          }

          this.ufsiiArray[idx].ufsii = value.UserFavoriteSessionItemId;
          idx++;
        }

        this.updateEventFavorites(this.session.Id, this.ufsiiArray);
        if (rejected) {
          throw new Error("Couldn't add to favorites");
        }
      } catch (error) {
        this.isFavorite = false;
      } finally {
        this.isPending = false;
      }
    },

    async removeFavorites() {
      if (this.isPending) {
        return;
      }

      this.isPending = true;
      this.isFavorite = false;

      try {
        const requestArray = [];
        for (const item of this.ufsiiArray) {
          if (item.ufsii === null) {
            continue;
          }

          requestArray.push(
            api.request(
              "delete_user_favorite_session_item",
              { id: item.ufsii },
              []
            )
          );
        }

        const result = await Promise.allSettled(requestArray);
        let rejected = false;
        let idx = 0;
        for (const { value, status } of result) {
          if (
            (Array.isArray(value) && value.length === 0) ||
            status === "rejected"
          ) {
            rejected = true;
            continue;
          }

          this.ufsiiArray[idx].ufsii = null;
          idx++;
        }

        if (rejected) {
          throw new Error("Couldn't remove from favorites");
        }
        this.updateEventFavorites(this.session.Id, this.ufsiiArray);
      } catch (error) {
        this.isFavorite = true;
      } finally {
        this.isPending = false;
      }
    },
  },
  i18n: {
    messages: {
      en: {
        translation: "Synchronous translation",
      },
      ru: {
        translation: "Синхронный перевод",
      },
    },
  },
  components: {
    IconStatusStarLinear,
    IconStatusStarFilled,
    IconHeadphones,
    HseModal,
    ModalFavoritesInfo,
    SectionsSessionLogoVue,
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.sections {
  display: grid;
  flex-direction: column;
  gap: 2em;
}

.favorites-button {
  border: none;
  line-height: 0;
  cursor: pointer;
  font-size: 24px;
}

.favorites-button--remove {
  color: #fea000;
}

.favorites-button--add {
  color: inherit;
}

.text-small {
  font-size: 0.85em;
  color: var(--color-text-grey);
}

.text-3-lines {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.session {
  display: grid;
  gap: 0.5em;
  padding: 1rem 0.5rem 1rem 0.5rem;
  /* padding: 24px; */
  transition: box-shadow 0.2s ease-out;
  border: 1px solid #d3d8e6;
  border-radius: 12px;
  background: #fff;
}

.session:hover {
  box-shadow: 0 4px 24px rgba(106, 122, 152, 0.15);
}

/* .session:not(:last-child) {
  border-bottom: solid 1px #e0e0e0;
} */

@media (min-width: 744px) {
  .session {
    display: flex;
    gap: 0;
  }
}

.session__control {
  display: flex;
  gap: 0.5em;
  align-items: flex-start;
  flex: 0 0 150px;
}

.session__time {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5em;
  /* border-right: solid 2px var(--color-text-grey); */
  font-size: 0.8125em;
  color: #999;
}

.session__time svg {
  color: #555656;
  font-size: 24px;
  width: 24px;
  height: auto;
}

.session__time > p {
  /* width: 5ch; */
}

.session__items {
  position: relative;
  padding: 0 0.5em;

  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.session__title {
  color: #1658da;
  font-size: 0.85em;
  font-weight: 500;
  text-transform: uppercase;
}

.items__item {
  display: flex;
  flex-direction: column;
  gap: 0.25em;
}

.items__translation {
  align-self: flex-start;

  padding-left: 0.1em;

  color: #7d7568;
  display: flex;
  font-size: 24px;
  gap: 0.5em;
}

.translation__languages {
  display: flex;
  gap: 0.5em;
}

.translation__languages p {
  color: black;
  background-color: #e7eefb;
  font-size: 10px;
  line-height: 10px;
  padding: 10px 5px;
  border-radius: 4px;
  font-weight: 600;
}

.translation__languages svg {
  width: 24px;
  height: auto;
}

.hse-Card:hover {
  box-shadow: 0 4px 24px rgba(106, 122, 152, 0.15);
}
.hse-Card {
  padding: 24px;
  transition: box-shadow 0.2s ease-out;
  border: 1px solid #d3d8e6;
  border-radius: 12px;
  background: #fff;
}
</style>
