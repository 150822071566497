<template>
  <div class="login" v-show="passCode">
    <div class="logo">
      <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="80" height="80" rx="12" fill="#002D6E"/>
        <path d="M49.9065 38.7332C48.9969 37.9501 48.0007 37.3292 46.9557 36.8657C47.3658 36.5241 47.7539 36.1482 48.1153 35.7347C51.7999 31.5178 51.3442 25.1326 47.0972 21.4744C45.1677 19.8128 42.7905 19 40.4241 19.0015L26 19V61.964H53.9997V47.73C54.0232 44.4024 52.6359 41.0858 49.9065 38.7332ZM48.0604 58.5943H42.9695V44.2729H37.0302V58.5943H31.9393V22.3697H37.8786C41.0416 22.3697 43.6986 24.5179 44.4527 27.4243H38.7272V30.794H44.4496C43.6969 33.6989 41.0448 35.8454 37.8849 35.8486H37.8724H35.3332V39.2183H39.5756C41.5475 39.2168 43.5289 39.8955 45.1363 41.2793C47.0688 42.9438 48.0572 45.2871 48.0587 47.6426H48.0619L48.0604 58.5943Z" fill="white"/>
      </svg>
    </div>
    <HseSpace size='small' vertical />
    <form class="form" @submit="changePasswordReq">
      <Validator ref="password" :field="password" @change="clearError" />
      <HseSpace size='large' vertical />
      <HseButton
        v-on:click="changePasswordReq"
        :spinner="buttonSpinner"
        spinnerPosition="left"
      >
        <span v-if="buttonSpinner === false">{{ $t('save') }}</span>
        <span v-else-if="buttonSpinner === true">{{ $t('saving') }}...</span>
        <span v-else-if="buttonSpinner === 'done'">{{ $t('auth.btnDone') }}</span>
      </HseButton>
      <HseSpace size='small_2x' vertical />
    </form>
  </div>
</template>

<script>
  import { api } from '../../../api'
  import { HseInput, HseButton, HseSpace } from '@hse-design/vue'
  import Validator from '../form/Validator.vue'

  export default {
    name: 'SetPassword',
    props: ['state', 'code'],
    data: () => ({
      showToGuests: true,
      passCode: '',
      buttonSpinner: false,
      passwordMaster: {
        isValid: false,
        value: ''
      },
    }),
    components: {
      HseButton,
      HseSpace,
      Validator,
    },
    created () {
      if (this.$route.query && this.$route.query.code) {
        this.passCode = this.$route.query.code
      } else {
        this.passCode = this.code
      }
      if (this.$route.query && this.$route.query.lng) {
        this.$store.state.lang = this.$route.query.lng
      }
    },
    i18n: {
      messages: {
        en: {
          save: 'Save password',
          saving: 'Saving',
          label: 'Setup a new password',
          placeholder: 'Your new password'
        },
        ru: {
          save: 'Сохранить пароль',
          saving: 'Сохраняем',
          label: 'Задайте пароль для своего аккаунта',
          placeholder: 'Ваш новый пароль'
        },
      }
    },
    computed: {
      password () {
        return {
          value: this.passwordMaster.value,
          isValid: this.passwordMaster.isValid,
          label: this.$i18n.t('label'),
          isRequired: true,
          placeholder: this.$i18n.t('placeholder'),
          size: 'large',
          component: HseInput,
          rules: [{
            name: 'password',
          }, {
            name: 'custom',
            validateVal: () => {
              return this.$store.state.error && this.$store.state.error.text
              && this.$store.state.error.url === 'auth user.recover.password' ? false : true
            },
            message: this.$store.state.error && this.$store.state.error.text 
              ? this.$store.state.error.text : ''
          }],
          setValues: (isValid, value = '') => {
            this.passwordMaster.value = value
            this.passwordMaster.isValid = isValid
          }
        }
      }
    },
    methods: {
      changePasswordReq (e) {
        e ? e.preventDefault() : null

        this.$emit('validateFields')
        if (!this.password.isValid) return

        this.buttonSpinner = true
        api.auth('user.recover.password', { code: this.passCode, password: this.password.value }).then(result => {
          if (result) {
            this.buttonSpinner = 'done'
            setTimeout(() => {
              this.$router.push('/')
              this.buttonSpinner = false
            }, 2000)
          } else {
            this.$emit('validateFields')
            this.buttonSpinner = false
          }
        })
      },
      setState (state) {
        this.$emit('changeState', { state })
      },
      clearError () {
        if (this.$store.state.error && this.$store.state.error.url == 'auth user.recover.password') {
          this.$store.state.error = null
        }
      }
    },
    mounted () {
      // инпуту для пароля задаём соответствующий тип
      const passInput = this.$refs.password.$el.querySelector('input')
      passInput.setAttribute('type', 'password')
    }
  }
</script>

<style scoped>
  .login {
    width: 340px;
    background: #FFFFFF;
    border-radius: 12px;
    margin: 53px auto;
    padding: 24px 30px;
  }
  .logo {
    text-align: center;
  }
  .links {
    text-align: center;
  }
  .link {
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
    text-decoration-line: underline;
    color: #0060CF;
    clear: both;
  }
  .hse-Button {
    width: 100% !important;
  }

  @media (max-width: 767px) {
    .login {
      margin: 16px auto;
    }
  }
  @media (max-width: 374px) {
    .login {
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
    }
  }
</style>
