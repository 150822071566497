import { HseInput, HseSelect, HseCheckbox } from "@hse-design/vue";
import { resourceLoader } from "../common/resourceLoader";
const fields = {
  isParticipant: {
    value: false,
    isValid: true,
    hideTopLabel: true,
    label: "fields.request.isParticipant",
    classes: { label: "smallLabel" },
    component: HseCheckbox,
  },
  // page 2
  applicantLastName: {
    value: "",
    isValid: false,
    label: "fields.name.lName",
    isRequired: true,
    size: "large",
    component: HseInput,
  },
  applicantFirstName: {
    value: "",
    isValid: false,
    label: "fields.name.fName",
    isRequired: true,
    size: "large",
    component: HseInput,
  },
  applicantMiddleName: {
    value: "",
    isValid: true,
    label: "fields.name.mName",
    size: "large",
    component: HseInput,
  },
  applicantFirstNameEN: {
    value: "",
    isValid: false,
    label: "fields.name.fNameEn",
    isRequired: true,
    size: "large",
    component: HseInput,
    rules: [
      {
        name: "latin",
      },
    ],
  },
  applicantLastNameEN: {
    value: "",
    isValid: false,
    label: "fields.name.lNameEn",
    isRequired: true,
    size: "large",
    component: HseInput,
    rules: [
      {
        name: "latin",
      },
    ],
  },
  gender: {
    value: "",
    isValid: false,
    label: "fields.name.gender",
    isRequired: true,
    size: "large",
    component: HseSelect,
    getAllOptions(search) {
      return resourceLoader.load("genderList", null, [], search);
    },
  },
  dateOfBirth: {
    value: "",
    isValid: false,
    label: "fields.name.birthYear",
    isRequired: true,
    size: "large",
    component: HseSelect,
    options: [...Array(new Date().getYear() - 14)]
      .map((_, i) => {
        const year = String(i + 1900);
        return {
          label: year,
          value: new Date(year).toISOString().split("T")[0],
        };
      })
      .reverse(),
  },
  applicantPhone: {
    value: "",
    isValid: false,
    label: "fields.name.phone",
    isRequired: true,
    size: "large",
    component: HseInput,
    rules: [
      {
        name: "phone",
      },
    ],
  },
  applicantEmail: {
    value: "",
    isValid: false,
    label: "fields.name.email",
    isRequired: true,
    size: "large",
    component: HseInput,
    rules: [
      {
        name: "email",
      },
    ],
  },
  // degreeId: {
  //   value: '',
  //   isValid: false,
  //   label: 'fields.request.degree',
  //   isRequired: false,
  //   size: 'large',
  //   component: HseSelect,
  //   getAllOptions (search) {
  //     return resourceLoader.load('degreeList', null, [], search)
  //   },
  // },
  сitizenshipCountryId: {
    value: "",
    isValid: false,
    label: "fields.name.citizen",
    isRequired: true,
    size: "large",
    searchable: "dropdown",
    component: HseSelect,
    getAllOptions(search) {
      return resourceLoader.load("countriesList", null, [], search);
    },
  },
  // applicantCountryId: {
  //   value: '',
  //   isValid: true,
  //   label: 'fields.name.country',
  //   isRequired: false,
  //   size: 'large',
  //   searchable: 'dropdown',
  //   component: HseSelect,
  //   getAllOptions (search) {
  //     return resourceLoader.load('countriesList', null, [], search)
  //   },
  // },
  // regionID: {
  //   value: '',
  //   isValid: true,
  //   label: 'fields.name.region',
  //   isRequired: false,
  //   size: 'large',
  //   searchable: 'dropdown',
  //   component: HseSelect,
  //   getAllOptions (search) {
  //     return resourceLoader.load('regionsList', null, [], search)
  //   },
  // },
  // applicantCity: {
  //   value: '',
  //   isValid: true,
  //   label: 'fields.name.city',
  //   isRequired: false,
  //   size: 'large',
  //   component: HseInput,
  // },
  applicantLanguageId: {
    value: "",
    isValid: false,
    label: "fields.name.language",
    isRequired: true,
    size: "large",
    component: HseSelect,
    getAllOptions(search) {
      return resourceLoader.load("languagesList", null, [], search);
    },
  },
  // page 3
  occupationType: {
    value: "",
    isValid: false,
    label: "fields.request.occupationType",
    isRequired: true,
    size: "large",
    component: HseSelect,
    getAllOptions(search) {
      return resourceLoader.load("occupationTypeList", null, [], search);
    },
  },
  // applicantBelongsToHSEId: {
  //   value: '',
  //   isValid: false,
  //   label: 'fields.request.belongsToHSE',
  //   isRequired: true,
  //   size: 'large',
  //   component: HseSelect,
  //   getAllOptions (search) {
  //     return resourceLoader.load('belongsToHSETypesList', null, [], search)
  //   },
  // },
  // organizationType: {
  //   value: '',
  //   isValid: true,
  //   label: 'fields.request.organizationType',
  //   size: 'large',
  //   component: HseSelect,
  //   getAllOptions (search) {
  //     return resourceLoader.load('organisationTypeList', null, [], search)
  //   },
  // },
  // faculty: {
  //   value: '',
  //   isValid: true,
  //   isRequired: true,
  //   label: 'fields.request.faculty',
  //   size: 'large',
  //   component: HseSelect,
  //   campus: '',
  //   setCampus (campus) { this.campus = campus },
  //   getAllOptions (search) {
  //     return this.campus ? resourceLoader.load('facultyList', { campus: this.campus }, [], search) : Promise.resolve([])
  //   },
  // },
  campus: {
    value: "",
    isValid: false,
    label: "fields.request.campus",
    isRequired: true,
    size: "large",
    component: HseSelect,
    getAllOptions(search) {
      return resourceLoader.load("campusList", null, [], search);
    },
  },
  applicantCompanyFullName: {
    value: "",
    isValid: false,
    label: "fields.name.companyFull",
    isRequired: true,
    size: "large",
    component: HseInput,
  },
  applicantCompanyShortName: {
    value: "",
    isValid: false,
    label: "fields.name.companyShort",
    isRequired: true,
    size: "large",
    component: HseInput,
  },
  applicantCompanyFullNameEn: {
    value: "",
    isValid: false,
    label: "fields.name.companyFullEn",
    isRequired: true,
    size: "large",
    component: HseInput,
    rules: [
      {
        name: "latin",
      },
    ],
  },
  applicantCompanyShortNameEn: {
    value: "",
    isValid: false,
    label: "fields.name.companyShortEn",
    isRequired: true,
    size: "large",
    component: HseInput,
    rules: [
      {
        name: "latinTitle",
      },
    ],
  },
  // applicantPosition: {
  //   value: '',
  //   isValid: true,
  //   label: 'fields.name.position',
  //   size: 'large',
  //   component: HseInput,
  // },
  // ApplicantIsProfessor: {
  //   value: false,
  //   isValid: true,
  //   hideTopLabel: true,
  //   label: 'fields.request.isProfessor',
  //   classes: { label: 'smallLabel' },
  //   component: HseCheckbox,
  // },
  IsHSEPresenter: {
    value: false,
    isValid: false,
    hideTopLabel: true,
    label: "fields.request.IsHSEPresenter",
    classes: { label: "smallLabel" },
    component: HseCheckbox,
  },
  // ApplicantOccupationForm: {
  //   value: '',
  //   isValid: false,
  //   isRequired: false,
  //   label: 'fields.request.occupationForm',
  //   size: 'large',
  //   component: HseSelect,
  //   getAllOptions (search) {
  //     return resourceLoader.load('occupationFormsList', null, [], search)
  //   },
  // },
  ApplicantDepartmentIdRu: {
    value: "",
    isValid: false,
    isRequired: true,
    size: "large",
    label: "fields.request.departmentRu",
    component: HseSelect,
    searchable: "dropdown",
    getAllOptions(search) {
      return resourceLoader.load("departmentsList", null, [], search);
    },
  },
  ApplicantDepartmentIdEn: {
    value: "",
    isValid: false,
    isRequired: true,
    size: "large",
    label: "fields.request.departmentEn",
    component: HseSelect,
    searchable: "dropdown",
    rules: [
      {
        name: "latin",
      },
    ],
    getAllOptions(search) {
      return resourceLoader.load("departmentsList", null, [], search);
    },
  },
  // ApplicantJobDirection: {
  //   value: '',
  //   isValid: false,
  //   isRequired: false,
  //   label: 'fields.placeholder.jobDirection',
  //   size: 'large',
  //   component: HseSelect,
  //   getAllOptions (search) {
  //     return resourceLoader.load('jobDirectionsList', null, [], search)
  //   },
  // },
  ApplicantOrganizationCountry: {
    value: "",
    isValid: false,
    isRequired: true,
    label: "fields.request.companyCountry",
    size: "large",
    searchable: "dropdown",
    component: HseSelect,
    getAllOptions(search) {
      return resourceLoader.load("countriesList", null, [], search);
    },
  },
  ApplicantOrganizationRegion: {
    value: "",
    isValid: true,
    isRequired: true,
    label: "fields.request.companyRegion",
    size: "large",
    searchable: "dropdown",
    component: HseSelect,
    getAllOptions(search) {
      return resourceLoader.load("regionsList", null, [], search);
    },
  },
  // positionType: {
  //   value: '',
  //   isValid: false,
  //   label: 'fields.request.positionType',
  //   isRequired: true,
  //   size: 'large',
  //   component: HseSelect,
  //   getAllOptions (search) {
  //     return resourceLoader.load('positionTypeList', null, [], search)
  //   },
  // },
  // degreeLevel: {
  //   value: '',
  //   isValid: true,
  //   isRequired: false,
  //   label: 'fields.request.degreeLevel',
  //   size: 'large',
  //   component: HseSelect,
  //   getAllOptions (search) {
  //     return resourceLoader.load('degreeLevelList', null, [], search)
  //   },
  // },
  // studyYear: {
  //   value: '',
  //   isValid: true,
  //   label: 'fields.request.studyYear',
  //   size: 'large',
  //   component: HseSelect,
  //   getAllOptions (search) {
  //     return resourceLoader.load('studyYearList', null, [], search)
  //   },
  // },
  // studyMode: {
  //   value: '',
  //   isValid: true,
  //   label: 'fields.request.studyMode',
  //   size: 'large',
  //   component: HseSelect,
  //   getAllOptions (search) {
  //     return resourceLoader.load('studyModeList', null, [], search)
  //   },
  // },
  Passport: {
    label: "fields.honoraryOrInvitedReport.passport",
    value: "",
    isValid: false,
    isRequired: true,
    maxSizeMb: 3,
    formats: ["doc", "docx", "rtf", "pdf", "jpg", "png", "zip", "rar", "7z"],
  },
  isAgree: {
    value: false,
    isValid: false,
    hideTopLabel: true,
    label: "fields.name.pdAgreement",
    classes: { label: "smallLabel" },
    component: HseCheckbox,
    rules: [
      {
        name: "custom",
        message: "fields.rule.pdAgreement",
        validateVal: (value) => value,
      },
    ],
  },
  // page 4
  thesesFileID: {
    label: "fields.request.thesesFileID",
    value: "",
    isRequired: true,
    isValid: false,
    maxSizeMb: 10,
    formats: ["doc", "docx", "rtf", "pdf"],
  },
  thesesFileEnID: {
    label: "fields.request.thesesFileEnID",
    value: "",
    isValid: false,
    isRequired: true,
    maxSizeMb: 10,
    formats: ["doc", "docx", "rtf", "pdf"],
  },
  StudentDocumentFileID: {
    label: "fields.request.studentFileID",
    value: "",
    isValid: false,
    isRequired: true,
    maxSizeMb: 3,
    formats: ["doc", "docx", "rtf", "pdf", "jpg"],
  },
  reportExists: {
    label: "fields.request.reportExists",
    value: false,
    isValid: true,
  },
  offline: {
    label: "fields.request.offline",
    value: "",
    isValid: true,
  },
  PreferredParticipationFormat: {
    label: "fields.request.preferredParticipationFormat",
    value: "офлайн",
    isValid: true,
  },
  title: {
    value: "",
    isValid: false,
    label: "fields.request.title",
    isRequired: true,
    size: "large",
    component: HseInput,
  },
  titleEN: {
    value: "",
    isValid: false,
    label: "fields.request.titleEn",
    isRequired: true,
    size: "large",
    component: HseInput,
    rules: [
      {
        name: "latinTitle",
      },
    ],
  },
  languageId: {
    value: "",
    isValid: false,
    label: "fields.request.reportLang",
    isRequired: true,
    size: "large",
    component: HseSelect,
    getAllOptions(search) {
      return resourceLoader.load("languagesList", null, [], search);
    },
  },
  direction1Id: {
    value: "",
    isValid: false,
    label: "fields.request.direction1",
    isRequired: true,
    size: "large",
    component: HseSelect,
    eventId: "",
    getAllOptions(search) {
      return resourceLoader.load(
        "eventTopicsList",
        { event_id: this.eventId },
        [],
        search
      );
    },
  },
  direction2Id: {
    value: "",
    isValid: false,
    label: "fields.request.direction2",
    size: "large",
    component: HseSelect,
    eventId: "",
    getAllOptions(search) {
      return resourceLoader.load(
        "eventTopicsList",
        { event_id: this.eventId },
        [],
        search
      );
    },
  },
  isExclusive: {
    value: false,
    isValid: false,
    hideTopLabel: true,
    label: "fields.request.isExclusive",
    isRequired: true,
    classes: { label: "smallLabel" },
    component: HseCheckbox,
    rules: [
      {
        name: "custom",
        message: "fields.rule.needYourApprove",
        validateVal: (value) => value,
      },
    ],
  },
  // page 5
  coAuthorsGuids: {
    value: [],
    isValid: true,
  },
  willCome: {
    value: [],
    isValid: true,
  },
  PaymentExemptionCode: {
    value: "",
    isValid: true,
    label: "fields.request.paymentTitle",
    //isRequired: false,
    size: "medium",
    component: HseInput,
  },
  coAuthorsPreferredParticipationFormat: {
    value: {},
    isValid: true,
  },
};

Object.keys(fields).forEach((key) => {
  fields[key].setValues = function (isValid, value = "") {
    this.value = value;
    this.isValid = isValid;
  };
});

export default fields;
