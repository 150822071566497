<template>
  <div class="event-card">
    <Loading v-if="!isDataLoaded" />
    <div v-else class="card">
      <HseHeading size="h3">
        {{ $t('reqForEvent', { number: req.numberRequest }) }} <a :href="`#/events/${req.EventId}`">{{ req.event.Title }}</a>
      </HseHeading>
      <HseSpace size="small" vertical />
      <div class="two-cols">
        <ReadonlyInput :isActive="((req.FinalScore === 'Принять' || req.FinalScore === 'Accept') && req.Participation === null)" :label="$t('requestStatus')" :value="req.StatusName" />
        <ReadonlyInput :label="$t('paymentStatus')" :value="paymentStatus" />
      </div>
      <div class="two-cols" v-if="req.StatusId === '324B1281-7735-A6DA-46FD-7241A537A3C9'">
        <ReadonlyInput :isActive="((req.FinalScore === 'Принять' || req.FinalScore === 'Accept') && req.Participation === null)" :label="$t('reviewStatus')" :value="req.FinalScore" />
      </div>
      <div class="status-comment" v-if="req.StatusChangeComment">
        <ReadonlyInput :label="$t('statusComment')" :value="req.StatusChangeComment" />
      </div>
      <div v-if="isShowCertificate">
        <a class="link" :href="`/files/download_certificate?request=${req.Id}&lang=ru`">{{ $t('certificateRu') }}</a>
        <br />
        <a class="link" :href="`/files/download_certificate?request=${req.Id}&lang=en`">{{ $t('certificateEn') }}</a>
      </div>
      <HseSpace size="medium" vertical />

      <div :style="{background: '#6B7A99', padding: '6px'}">
        <HseButton variant='tertiaryInvert' size='medium' v-on:click="isInfoOpen = !isInfoOpen">
          {{$t('info')}} <span class="ident" v-show="isInfoOpen">&#9650;</span><span class="ident" v-show="!isInfoOpen">&#9660;</span>
        </HseButton>
      </div>
      <div v-show="isInfoOpen" :style="{background: '#F0F2F5', padding: '6px'}">
        <div class="cols two-cols">
          <ReadonlyInput v-if="isLangRu" :label="$t('fields.name.lName')" :value="req.ApplicantLastName" />
          <ReadonlyInput :label="$t('fields.name.lNameEn')" :value="req.ApplicantLastNameEN" />
          <ReadonlyInput v-if="isLangRu" :label="$t('fields.name.fName')" :value="req.ApplicantFirstName" />
          <ReadonlyInput :label="$t('fields.name.fNameEn')" :value="req.ApplicantFirstNameEN" />
          <ReadonlyInput v-if="isLangRu" :label="$t('fields.name.mName')" :value="req.ApplicantMiddleName" />
          <ReadonlyInput :label="$t('fields.name.email')" :value="req.ApplicantEmail" />
          <ReadonlyInput :label="$t('fields.name.gender')" :value="req.GenderData.gender" />
          <ReadonlyInput :label="$t('fields.name.phone')" :value="req.ApplicantPhone" />
<!--          <ReadonlyInput :label="$t('fields.request.degree')" :value="req.DegreeName" />-->
          <ReadonlyInput :label="$t('fields.name.citizen')" :value="req.CitizenshipCountryName" />
<!--          <ReadonlyInput :label="$t('fields.name.country')" :value="req.ApplicantCountryName" />-->
<!--          <ReadonlyInput v-if="isCountryRu" :label="$t('fields.name.region')" :value="req.RegionName" />-->
<!--          <ReadonlyInput :label="$t('fields.name.city')" hideifempty :value="req.ApplicantCity" />-->
          <ReadonlyInput :label="$t('fields.name.language')" :value="req.ApplicantLanguage.Name" />
          <ReadonlyInput :label="$t('fields.name.birthYear')" :value="birthYear" />
        </div>
      </div>

      <HseSpace size="medium" vertical />
      <div :style="{background: '#6B7A99', padding: '6px'}">
        <HseButton variant='tertiaryInvert' size='medium' v-on:click="isOrgOpen = !isOrgOpen">
          {{$t('company')}} <span class="ident" v-show="isOrgOpen">&#9650;</span><span class="ident" v-show="!isOrgOpen">&#9660;</span>
        </HseButton>
      </div>
      <div v-show="isOrgOpen" :style="{background: '#F0F2F5', padding: '6px'}">
        <div class="cols two-cols">
          <ReadonlyInput
                  :label="$t('fields.request.occupationType')"
                  :value="req.OccupationTypeName"
          />
<!--          <ReadonlyInput-->
<!--                  v-if="isWorking"-->
<!--                  hideifempty-->
<!--                  :label="$t('fields.request.isProfessor')"-->
<!--                  :value="getBoolText('ApplicantIsProfessor')"-->
<!--          />-->
          <ReadonlyInput
                  v-if="isNotSimple"
                  hideifempty
                  :label="$t('fields.request.IsHSEPresenter')"
                  :value="getBoolText('IsHSEPresenter')"
          />
<!--          <ReadonlyInput-->
<!--                  v-if="isWorking && isHse"-->
<!--                  hideifempty-->
<!--                  :label="$t('fields.request.occupationForm')"-->
<!--                  :value="req.ApplicantOccupationFormName"-->
<!--          />-->
          <ReadonlyInput
                  v-if="isWorking && isHse && isLangRu"
                  hideifempty
                  :label="$t('fields.request.departmentRu')"
                  :value="req.ApplicantDepartmentNameRu"
          />
          <ReadonlyInput
                  v-if="isWorking && isHse && !isLangRu"
                  hideifempty
                  :label="$t('fields.request.departmentEn')"
                  :value="req.ApplicantDepartmentNameEn"
          />
<!--          <ReadonlyInput-->
<!--                  v-if="isWorking"-->
<!--                  :label="$t('fields.placeholder.jobDirection')"-->
<!--                  :value="req.ApplicantJobDirectionName"-->
<!--          />-->
          <ReadonlyInput
                  v-if="isHse"
                  hideifempty
                  :label="$t('fields.request.campus')"
                  :value="req.CampusName"
          />
<!--          <ReadonlyInput-->
<!--                  v-if="isHse && isStudying"-->
<!--                  hideifempty-->
<!--                  :label="$t('fields.request.faculty')"-->
<!--                  :value="req.FacultyName"-->
<!--          />-->
          <ReadonlyInput
                  hideifempty
                  :label="$t('fields.request.companyCountry')"
                  :value="req.ApplicantOrganizationCountryName"
          />
          <ReadonlyInput
                  v-if="isCompanyCountryRu"
                  hideifempty
                  :label="$t('fields.request.companyRegion')"
                  :value="req.ApplicantOrganizationRegionName"
          />
          <ReadonlyInput
                  hideifempty
                  v-if="isLangRu"
                  :label="$t('fields.name.companyFull')"
                  :value="req.ApplicantCompanyFullName"
          />
          <ReadonlyInput
                  hideifempty
                  v-if="isLangRu"
                  :label="$t('fields.name.companyShort')"
                  :value="req.ApplicantCompanyShortName"
          />
          <ReadonlyInput v-if="!isLangRu"
                         :label="$t('fields.name.companyFullEn')"
                         :value="req.ApplicantCompanyFullNameEn"
          />
          <ReadonlyInput
                  :label="$t('fields.name.companyShortEn')"
                  :value="req.ApplicantCompanyShortNameEn"
          />
<!--          <ReadonlyInput-->
<!--                  v-if="isStudying"-->
<!--                  :label="$t('fields.request.degreeLevel')"-->
<!--                  :value="req.DegreeLevelName"-->
<!--          />-->
        </div>
        <div class="annotation">
          <a
            v-if="isStudying"
            class="link"
            :href="`/files/download_file?id=${req.StudentDocumentFileID}`"
            >
              {{$t('fields.request.confirmationDocument')}}
          </a>
        </div>
      </div>
      <div v-if="req.ReportExists" class="report" >
        <HseSpace size="medium" vertical />
        <div :style="{background: '#6B7A99', padding: '6px'}">
          <HseButton variant='tertiaryInvert' size='medium' v-on:click="isReportOpen = !isReportOpen">
            {{$t('paper')}} <span class="ident" v-show="isReportOpen">&#9650;</span><span class="ident" v-show="!isReportOpen">&#9660;</span>
          </HseButton>
        </div>

        <div v-show="isReportOpen" :style="{background: '#F0F2F5', padding: '6px'}">
          <div class="cols two-cols">
            <ReadonlyInput v-if="isLangRu && isReportLangRu && req.report.ReportTitle" :label="$t('fields.request.title')" :value="req.report.ReportTitle" />
            <ReadonlyInput :label="$t('fields.request.titleEn')" :value="req.report.ReportTitleEN" />
            <ReadonlyInput v-if="isLangRu" :label="$t('fields.request.direction1')" :value="req.report.Direction1.Title" />
            <ReadonlyInput v-else :label="$t('fields.request.direction1')" :value="req.report.Direction1.TitleEN" />
            <ReadonlyInput v-if="req.report.Direction2 && isLangRu" :label="$t('fields.request.direction2')" :value="req.report.Direction2.Title" />
            <ReadonlyInput v-else-if="req.report.Direction2" :label="$t('fields.request.direction2')" :value="req.report.Direction2.TitleEN" />
            <ReadonlyInput :label="$t('fields.request.reportLang')" :value="req.report.LanguageName" />
          </div>
          <div class="annotation">
            <a
                    v-if="req.report.ThesesFileID && isReportLangRu"
                    class="link"
                    :href="`/files/download_file?id=${req.report.ThesesFileID}`"
            >
              {{$t('fields.request.thesesFileID')}}
            </a>
            <div class="field" v-if="req.report.ThesesFileID && req.StatusId === '96F18960-F327-419C-B1FA-D4CFA3FD5103' && isReportLangRu && !isSaved">
              <FileUploader :field="fields.thesesFileID" />
            </div>
            <br v-if="req.report.ThesesFileID && req.report.ThesesFileEnID">
            <a
                    v-if="req.report.ThesesFileEnID"
                    class="link"
                    :href="`/files/download_file?id=${req.report.ThesesFileEnID}`"
            >
              {{$t('fields.request.thesesFileEnID')}}
            </a>
            <div class="field" v-if="req.report.ThesesFileEnID && req.StatusId === '96F18960-F327-419C-B1FA-D4CFA3FD5103' && !isSaved">
              <FileUploader :field="fields.thesesFileEnID" />
            </div>
          </div>
        </div>


        <div v-if="req.report.coAuthors && req.report.coAuthors.length">
          <HseSpace size="medium" vertical />
          <div :style="{background: '#6B7A99', padding: '6px'}">
            <HseButton variant='tertiaryInvert' size='medium' v-on:click="isCoauthorsOpen = !isCoauthorsOpen">
              {{$t('coauthors')}} <span class="ident" v-show="isCoauthorsOpen">&#9650;</span><span class="ident" v-show="!isCoauthorsOpen">&#9660;</span>
            </HseButton>
          </div>
          <div v-show="isCoauthorsOpen" :style="{background: '#F0F2F5', padding: '6px'}">
            <div v-for="{ data: { public: coauthor }, login, id } in req.report.coAuthors" v-bind:key="id">
              <div v-if="lang === 'ru'">
                {{ coauthor.LName }} {{ coauthor.FName }} {{ coauthor.SName }} ( {{ login }} )
              </div>
              <div v-else>
                {{ coauthor.FNameEN }} {{ coauthor.LNameEN }} ( {{ login }} )
              </div>
            </div>

          </div>
        </div>
        <div v-if="req.ReportExists && req.report.FinalScore ==='2C5ED658-3C00-4D6D-9935-8215528A595B' && req.StatusId === '324B1281-7735-A6DA-46FD-7241A537A3C9'">
          <HseSpace size="medium" vertical />
          <div :style="{outline: ((req.FinalScore === 'Принять' || req.FinalScore === 'Accept') && req.Participation === null) ? '2px solid #0050CF' : 'none', background: '#6B7A99', padding: '6px'}">
            <HseButton variant='tertiaryInvert' size='medium' v-on:click="isParticipationOpen = !isParticipationOpen">
              {{$t('commit')}} <span class="ident" v-show="isParticipationOpen">&#9650;</span><span class="ident" v-show="!isParticipationOpen">&#9660;</span>
            </HseButton>
          </div>
          <div v-show="isParticipationOpen" :style="{background: '#F0F2F5', padding: '6px'}">
            <p>{{$t('ParticipationStatus')}}: {{get_participation_status()}}</p>
            <p>
              <a
                      v-if="req.report.Presentation"
                      class="link"
                      :href="`/files/download_file?id=${req.report.Presentation}`"
              >
                {{$t('presentation')}}
              </a>
            </p>

            <HseButton class="pull-right"  size="medium" v-on:click="commitPartisipation()">{{$t('change')}}</HseButton>
          </div>
        </div>
      </div>
      <HseSpace size='small' vertical />
      <div :class="req.StatusId === '96F18960-F327-419C-B1FA-D4CFA3FD5103' ? 'cols-3' : 'cols-2'">
        <a class="link" :href="`/#/requests`">{{$t('back')}}</a>
        <HseButton
          v-if="isValidReportDate && req.StatusId !== 'FF0D452C-672E-4F98-9E4D-53E9B313D3EB'"
          @click="cancelRequest"
          :spinner="cancelSpinner"
          spinnerPosition="left"
          class="pull-right"
        >
          <span v-if="cancelSpinner === false">{{ $t('cancel') }}</span>
          <span v-else-if="cancelSpinner === true">{{ $t('canceling') }}...</span>
          <span v-else-if="cancelSpinner === 'done'">{{ $t('btnDone') }}</span>
        </HseButton>
        <HseButton
          v-if="req.StatusId === '96F18960-F327-419C-B1FA-D4CFA3FD5103'"
          @click="saveNewAnnotation"
          :spinner="buttonSpinner"
          spinnerPosition="left"
          class="pull-right"
        >
          <span v-if="buttonSpinner === false">{{ $t('save') }}</span>
          <span v-else-if="buttonSpinner === true">{{ $t('adding') }}...</span>
          <span v-else-if="buttonSpinner === 'done'">{{ $t('btnDone') }}</span>
        </HseButton>
      </div>

    </div>


    <RequestCommitModal :request_id="this.requestId" @openCommitModalResponse='set_participation'/>
  </div>
</template>

<script>

  import { api } from '../../api'
  import Loading from '../common/Loading.vue'
  import { HseSpace, HseHeading, HseButton } from '@hse-design/vue'
  import fields from './managerFields'
  import ReadonlyInput from '../common/ReadonlyInput.vue'
  import { mapState } from 'vuex'
  import { dateFormatter } from '../common/dateFormatter'
  import RequestCommitModal from './RequestCommitModal.vue'
  import FileUploader from '../common/form/FileUploader.vue'

  export default {
    components: {
      HseSpace,
      HseHeading,
      Loading,
      ReadonlyInput,
      HseButton,
      RequestCommitModal,
      FileUploader,
    },
    data: () => ({
      eventId: '',
      isDataLoaded: false,
      fields,
      req: null,
      experts: null,
      isCoauthorsOpen: false,
      isReportOpen: false,
      isOrgOpen: false,
      isInfoOpen: false,
      isParticipationOpen: false,
      buttonSpinner: false,
      cancelSpinner: false,
      isSaved: false,
    }),
    created () {
      this.requestId = this.$route.params.id
      fields.ExpertSelect.requestId = this.$route.params.id
      this.requestEventData()
    },
    methods: {
      checkEndRequestDate(endDate) {
        return new Date() > new Date(endDate)
      },
      saveNewAnnotation() {
        this.$emit('validateFields')
        if (!this.isReportLangRu) {
          if (this.req.report.ThesesFileEnID && this.fields.thesesFileEnID.isValid) {
            this.requestNewAnnotationData({
              id: this.$route.params.id,
              ThesesFileEnID: this.fields.thesesFileEnID.value,
            })
          }
        } else {
          if (this.req.report.ThesesFileEnID && this.fields.thesesFileEnID.isValid && this.req.report.ThesesFileID && this.fields.thesesFileID.isValid) {
            this.requestNewAnnotationData({
              id: this.$route.params.id,
              ThesesFileID: this.fields.thesesFileID.value,
              ThesesFileEnID: this.fields.thesesFileEnID.value,
            })
          } else {
            if (this.req.report.ThesesFileEnID && this.fields.thesesFileEnID.isValid) {
              this.requestNewAnnotationData({
                id: this.$route.params.id,
                ThesesFileEnID: this.fields.thesesFileEnID.value,
              })
            }
            if (this.req.report.ThesesFileID && this.fields.thesesFileID.isValid) {
              this.requestNewAnnotationData({
                id: this.$route.params.id,
                ThesesFileID: this.fields.thesesFileID.value,
              })
            }
          }
        }

      },
      requestNewAnnotationData (index) {
        this.buttonSpinner = true
        api.request('requestReportThesesFileUpdate', index).then(data => {
          if (data.ret.length) {
            setTimeout(() => {this.buttonSpinner = 'done', this.isSaved = true}, 1000)
          }
        })
      },
      cancelRequest() {
        this.cancelSpinner = true
        api.request('request_status_canceled', { id: this.$route.params.id, lang: this.lang }).then(data => {
          console.log(data)
          if (data) {
            setTimeout(() => {this.cancelSpinner = 'done'; this.requestEventData()}, 1000)
          }
        })
      },
      openReportField() {
        if (this.req.StatusId === '96F18960-F327-419C-B1FA-D4CFA3FD5103') {
          this.isReportOpen = true
        }
      },
      requestEventData () {
        this.isDataLoaded = false
        api.request('requestGetFull', { id: this.$route.params.id  }).then((res) => {
          this.req = res
          this.isDataLoaded = true
          this.openReportField()
        })
      },
      getBoolText (field) {
        return this.req[field] ? this.$i18n.tc('yes') : this.$i18n.tc('no')
      },
      commitPartisipation() {
        this.$emit('openCommitModal')
      },
      set_participation(data) {
        api.request('requestParticipationUpdate', {
          participation: data.participation,
          presentation_file: data.presentation,
          id: this.$route.params.id,
          eventId: this.req.EventId
        }).then(() => {
          this.requestEventData()
        })
      },
      get_participation_status() {
        switch (this.req.Participation) {
          case null:
            return this.$i18n.t('ParticipationNull')
          case true:
            return this.$i18n.t('ParticipationTrue')
          case false:
            return this.$i18n.t('ParticipationFalse')
        }
      }, 
      isPresented() {
        return this.req.ReportExists ? (this.req.ReportPresented ? true : false) : true
      },
    },
    computed: {
      isShowCertificate() {
        let isValid = this.isValidRequestDate && (this.isNotPayed || this.isPayed)
        if (this.req.ReportExists) {
          isValid = isValid && this.req.ReportPresented
        }
        return isValid
      },
      isNotPayed () {
        return this.req.payment_status === -1
      },
      isPayed () {
        return  this.req.payment_status === 1
      },
      isValidReportDate() {
        if (this.req.ReportExists) {
          return new Date() < new Date(this.req.event.EndReportDate)
        } else {
          return true
        }
      },
      isValidRequestDate() {
        return this.checkEndRequestDate(this.req.event.EndDate)
      },
      birthYear () {
        return dateFormatter.format(this.req.DateOfBirth, 'year')
      },
      // isHSEEmployee () {
      //   return this.req.ApplicantOccupationForm === 1
      // },
      isCompanyCountryRu () {
        return this.req.ApplicantOrganizationCountry === 'BC763F4B-FBD9-461B-B395-166020E8C026'
      },
      isHse () {
        return this.req.IsHSEPresenter
      },
      isCountryRu () {
        return this.req.ApplicantCountryId === 'BC763F4B-FBD9-461B-B395-166020E8C026'
      },
      isWorking () {
        return this.req.OccupationType === 1
      },
      isStudying () {
        return this.req.OccupationType === 2
      },
      isOther () {
        return this.req.OccupationType === 3
      },
      isSelfEmpl () {
        return this.req.OccupationType === 4
      },
      isNotSimple () {
        return !this.isOther && !this.isSelfEmpl
      },
      isLangRu () {
        return this.lang === 'ru'
      },
      isReportLangRu () {

        return this.req.report.LanguageId !== 'D909F362-2610-49C9-BFA2-57692617EF76'

      },
      paymentStatus () {
        switch (this.req.payment_status) {
          case -2:
            return this.$i18n.t('PaymentDisabled')
          case -1:
            return this.$i18n.t('PaymentNotNeeded')
          case 1:
            return this.$i18n.t('payed')
          case 0:
            return this.$i18n.t('notPayed')
          default:
            return ''
        }
      },

      ...mapState(['lang']),
    },
    watch: {
      lang () {
        this.requestEventData()
      }
    },
    i18n: {
      messages: {
        en: {
          reqForEvent: 'Request #{number} for event',
          info: 'Information about participant/speaker',
          company: 'Company',
          number: 'Request number',
          coauthors: 'co-Authors',
          experts: 'Experts',
          set: 'Set',
          Novelty: 'Novelty',
          Actuality: 'Actuality',
          Depth: 'Depth',
          TotalResult: 'Total result',
          TotalResultTitle: 'Conclusion',
          remove: 'Remove',
          back: 'Back to request list',
          changeStatus: 'Change status',
          paper: 'Report',
          payment: 'Payment',
          requestStatus: 'Request status',
          paymentStatus: 'Payment status',
          certificateRu: 'Download certificate(ru)',
          certificateEn: 'Download certificate(en)',
          notPayed: 'Not payed',
          payed: 'Payed',
          paymentProcess: 'In payment process',
          PaymentNotNeeded: 'Payment not needed',
          PaymentDisabled: 'Payment disabled',
          commit: 'Confirm participation and upload presentation',
          change: 'Change',
          ParticipationNull: 'No data',
          ParticipationTrue: 'Confirmed',
          ParticipationFalse: 'Declined',
          ParticipationStatus: 'Participation status',
          presentation: 'Presentation',
          save: 'Save',
          adding: 'Adding',
          btnDone: 'Done',
          cancel: 'Cancel request',
          canceling: 'Canceling request',
          reviewStatus: 'Review status',
          statusComment: 'Comment',
        },
        ru: {
          reqForEvent: 'Заявка №{number} на мероприятие',
          info: 'Информация об участнике/докладчике',
          company: 'Организация',
          number: 'Номер заявки',
          coauthors: 'Соавторы',
          experts: 'Эксперты',
          set: 'Назначить',
          Novelty: 'Новизна',
          Actuality: 'Актуальность',
          Depth: 'Глубина',
          TotalResult: 'Оценка',
          TotalResultTitle: 'Заключение',
          remove: 'Удалить',
          back: 'К заявкам',
          changeStatus: 'Изменить статус',
          paper: 'Доклад',
          payment: 'Оплата',
          requestStatus: 'Статус заявки',
          paymentStatus: 'Статус оплаты',
          certificateRu: 'Скачать сертификат(рус.)',
          certificateEn: 'Скачать сертификат(англ.)',
          notPayed: 'Не оплачена',
          payed: 'Оплачена',
          paymentProcess: 'В процессе оплаты',
          PaymentNotNeeded: 'Оплата не требуется',
          PaymentDisabled: 'Оплата недоступна',
          commit: 'Подтверждение участия и загрузка презентации',
          change: 'Изменить',
          ParticipationNull: 'Решение не принято',
          ParticipationTrue: 'Подтверждено',
          ParticipationFalse: 'Не подтверждено',
          ParticipationStatus: 'Подтверждение участия',
          presentation: 'Презентация',
          save: 'Сохранить',
          adding: 'Добавляем',
          btnDone: 'Готово',
          cancel: 'Отменить заявку',
          canceling: 'Отмена заявки',
          reviewStatus: 'Статус рассмотрения',
          statusComment: 'Комментарий',
        },
      }
    }
  }
</script>
<style>
  .annotation .field .fileUploader .description {
    color: #6b7a99;
  }
</style>
<style scoped>
  .status-comment {
    margin: 0 -16px;
  }
  .annotation .field {
    padding: 0;
  }
  .event-card {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 32px;
    max-width: 800px;
    width: calc(100vw - 128px);
    margin: 52px auto;
  }
  .subheading {
    font-family: 'Work Sans', sans-serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #0F0F14;
  }
  .field {
    padding: 0 16px;
    min-width: 0;
  }
  .link {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  .two-cols {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px;
  }
  .two-cols>div {
    width: 50%;
  }

  .cols-3 {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    gap: 5px;
    align-items: center;
  }

  .cols-2 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 5px;
    align-items: center;
  }

  .pull-right {
    margin-left: auto;
    margin-right: 0;
  }

  .ident {
    margin-left: 10px;
  }
  @media (max-width: 1023px) {
    .event-card {
      margin: 16px auto;
      width: 95%;
    }
  }
  @media (max-width: 767px) {
    .event-card {
      width: 100%;
      margin: 0;
    }
    .two-cols>div {
      width: 100%;
    }
  }
</style>
<style>
  .hse-Table__td {
    word-break: keep-all;
  }
  .hse-Button.hse-Button_variant_tertiaryInvert.hse-Button_size_medium {
    width: auto;
    min-height: 40px;
    height: auto;
  }
</style>
