<template>
  <div class="login">
    <CodeModal :login="fields.login.value" v-if="isOpen" />
    <Logos />
    <RoleModal />
    <HseSpace size='small' vertical />
    <form class="form" @submit="submit">
      <Validator v-bind:field="fields.login" v-on:change="onLoginChange" />
      <HseSpace size='small' vertical />
      <HseButton v-on:click="submit">{{ $t('auth.code') }}</HseButton>
      <HseSpace size='small_2x' vertical />
      <div class="links">
        <span class="link" v-on:click="() => setState('loginOptions')">{{ $t('back') }}</span>
      </div>
    </form>
  </div>
</template>

<script>
import { HseButton, HseSpace } from '@hse-design/vue'
import Validator from '../form/Validator'
import fields from './codeFields'
import { mapState } from 'vuex'
import { localizeFields } from '../../../i18n'
import RoleModal from '../modal/RoleModal.vue'
import CodeModal from '../modal/CodeModal.vue'
import Logos from './Logos.vue'
import {api} from "@/api";

export default {
  name: 'Code',
  props: ['state'],
  data: () => ({
      fields,
      isOpen: false,
      result: "",
  }),
  components: {
    HseButton,
    HseSpace,
    Validator,
    RoleModal,
    Logos,
    CodeModal
  },
  methods: {
      // open() {
      //     this.isOpen = true;
      //     // if (this.$store.state.error && this.$store.state.error.code === 7) {
      //     //     // if (!this.isFormValid()) return
      //     //     this.isOpen = false;
      //     //     // if (!this.code.isValid) return
      //     //     return
      //     // } else {
      //     //     this.isOpen = true
      //         //this.login = login
      //     }
      // },
    isFormValid() {
      this.$emit('validateFields')
      return Object.keys(this.fields).every(key =>
        this.fields[key].isValid
      )
    },
    async submit(e) {
      e?.preventDefault()

      if (!this.isFormValid()) return

      await api.sendCode(this.fields.login.value);
      if (this.$store.state.error && this.$store.state.error.code === 7) {
        if (!this.isFormValid()) return
        return
     } else {
          this.isOpen = true;
      }

    },
    setState(state) {
      this.$emit('changeState', { state })
    },
    onLoginChange() {
      if (this.$store.state.error && this.$store.state.error.code === 7) {
        this.$store.state.error.code = null
      }
    },
    translateFields() {
      this.fields = localizeFields(fields, this.fields)
    }
  },
  mounted() {
    this.translateFields()
  },
  computed: mapState(['lang']),
  watch: {
    lang() {
      this.translateFields()
    }
  },
}
</script>

<style scoped>
.login {
  width: 340px;
  background: #FFFFFF;
  border-radius: 12px;
  margin: 53px auto;
  padding: 24px 30px;
}

.links {
  text-align: center;
}

.link {
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  text-decoration-line: underline;
  color: #0060CF;
  clear: both;
}

.hse-Button {
  width: 100% !important;
}

@media (max-width: 767px) {
  .login {
    margin: 16px auto;
  }
}

@media (max-width: 374px) {
  .login {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
  }
}
</style>
