<template>
  <div class="fileUploader" @click="selectFile" :class="{ 'hse-RichInput_hasError': errorMessage }">
    <div class="label">{{ field.label }}</div>
    <div class="field">
      <div class="dropbox">
        <Loading v-if="isUploading" />
        <div v-else-if="!field.value" class="empty">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 7H9V2H7V7H2V9H7V14H9V9H14V7Z" fill="#0050CF"/>
          </svg>
          <span>{{ $t('attachFile') }}</span>
        </div>
        <div v-else class="file">{{ $t('fileUploaded') }}</div>
      </div>
      <div class="description">
        {{ $t('desc.size', { size: field.maxSizeMb }) }}<br>
        {{ $t('desc.format', { format: format }) }}<br>
        {{ $t('desc.name') }}
      </div>
    </div>
    <input type="file" ref="fileInput" :accept="acceptFormat" v-on:change="onFileSelect" />
    <div v-if="errorMessage" class="hse-RichInput__message">
      {{ $t(errorMessage, { size: field.maxSizeMb }) }}
    </div>
  </div>
</template>

<script>
  import { api } from '../../../api'
  import Loading from '../Loading.vue'
  export default {
    name: 'fileUploader',
    props: ['field', 'customUploadUrl'],
    components: {
      Loading,
    },
    data: () => ({
      errorMessage: '',
      isUploading: false,
      saveFileTimeout: null,
    }),
    created () {
      this.$parent.$on('validateFields', () => this.validate())
    },
    methods: {
      selectFile () {
        this.$refs.fileInput.click()
      },
      setError (error) {
        this.errorMessage = error
        this.isUploading = false
        this.field.setValues(false, this.field.value)
      },
      validate () {
        if (!this.$refs.fileInput) return false

        const currFile = this.$refs.fileInput.files[0]
        if (!currFile && !this.field.value && this.field.isRequired) {
          this.setError('rule.chooseFile')
          return false
        } else if (!this.field.value && !this.field.isRequired && !this.isUploading || !currFile && this.field.value) {
          this.field.setValues(true, this.field.value)
          return true
        }

        if (currFile.size > this.field.maxSizeMb * 1024 * 1024) {
          this.setError('rule.size')
          return false
        }

        let nameSplitted = currFile.name.split('.')
        const fileExt = nameSplitted.pop()
        const fileName = nameSplitted.join('.')
        if (this.field.formats && !this.field.formats.includes(fileExt.toLowerCase())) {
          this.setError('rule.format')
          return false
        }

        const isNameOk = /^[\u0308A-Za-zЁёА-я0-9.,()\-\u0438−\u0435–—_\s\u0306]+$/gu.test(fileName)
        if (!isNameOk) {
          this.setError('rule.name')
          return false
        }
        if (this.isUploading) {
          this.errorMessage = 'rule.uploadingFile'
          this.field.setValues(false, this.field.value)
          return true
        }
        return true
      },
      onFileSelect () {
        this.isUploading = true
        this.errorMessage = ''
        if (!this.$refs.fileInput) return

        const currFile = this.$refs.fileInput.files[0]

        const isValidFile = this.validate()
        if (!isValidFile) return

        if (this.saveFileTimeout) {
          clearTimeout(this.saveFileTimeout)
        }

        this.saveFileTimeout = setTimeout(() => {
          this.saveFile(currFile)
        }, 1000)
      },
      saveFile (file) {
        api.post_file(this.uploadUrl, { file }).then(data => {
          if (data.result) {
            this.field.setValues(true, data.data)
            this.$refs.fileInput.value = ''
            this.isUploading = false
            if (this.errorMessage === 'rule.uploadingFile') {
              this.errorMessage = ''
            }
          } else {
            this.setError(data.message)
          }
        }).catch(() => {
          const err = this.$store.state.error ? this.$store.state.error.text : ''
          this.setError(err)
        })
      }
    },
    computed: {
      acceptFormat () {
        return this.field.accept ? this.field.accept : this.field.formats.map(fmt => `.${fmt}`).join(',')
      },
      uploadUrl () {
        return this.customUploadUrl ? this.customUploadUrl : '/files/upload_file'
      },
      format () {
        return this.field.formats.join(', ').toUpperCase()
      },
    },
    i18n: {
      messages: {
        en: {
          attachFile: 'Attach a file',
          fileUploaded: 'The file is uploaded',
          desc: {
            size: 'file size should be not more than {size} Mb',
            format: 'allowed file formats {format}',
            name: 'file name should not contain special symbols',
          },
          rule: {
            size: 'File size is more than {size} Mb',
            format: 'Incorrect file format',
            name: 'File name contains special symbols',
            chooseFile: 'Please, choose a file',
            uploadingFile: 'Please wait for file upload',
          },
        },
        ru: {
          attachFile: 'Прикрепить файл',
          fileUploaded: 'Файл загружен',
          desc: {
            size: 'размер не более {size} Мб',
            format: 'допустимый формат {format}',
            name: 'название файла не должно содержать спецсимволы',
          },
          rule: {
            size: 'Размер файла больше чем {size} Мб',
            format: 'Некоррекный формат файла',
            name: 'Имя файла содержит специальные символы',
            chooseFile: 'Необходимо выбрать файл',
            uploadingFile: 'Дождитесь загрузки файла',
          },
        },
      }
    }
  }
</script>

<style scoped>
  .fileUploader {
    cursor: pointer;
  }
  .fileUploader input {
    display: none;
  }
  .fileUploader .label {
    font-size: 14px;
    line-height: 20px;
    color: #6B7A99;
    margin-bottom: 8px;
  }
  .fileUploader .field {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .fileUploader .dropbox {
    border: 1px dashed #0050CF;
    box-sizing: border-box;
    border-radius: 4px;
    min-width: 190px;
    width: fit-content;
    margin-right: 12px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    margin-bottom: 8px;
  }
  .fileUploader .dropbox>* {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #0050CF;
  }
  .fileUploader .dropbox svg {
    margin-right: 8px;
  }
  .description {
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #C0C6D1;
    margin-bottom: 8px;
  }
</style>
