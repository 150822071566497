<template>
  <div class="card">
    <HseHeading size="h3">{{ name }}</HseHeading>
    <HseSpace size='small' vertical />
    <ReadonlyInput label="ID" :value="data.AutoNum" />
    <ReadonlyInput :label="$t('fields.name.citizen')" :value="data.CitizenshipCountryName" />
    <ReadonlyInput :label="$t('fields.name.event')" :value="data.EventTitle" />
    <ReadonlyInput :label="$t('created')" :value="formatDate(data.CreatedOn)" />
    <a class="link" :href="`#/visas/${data.Id}`">{{ $t('open') }}</a>
  </div>
</template>

<script>
  import ReadonlyInput from '../common/ReadonlyInput.vue'
  import { HseHeading, HseSpace } from '@hse-design/vue' 
  import { mapState } from 'vuex'
  import { dateFormatter } from '../common/dateFormatter'
  export default {
    name: 'eventCard',
    components: {
      ReadonlyInput,
      HseHeading,
      HseSpace,
    },
    props: ['data'],
    methods: {
      formatDate (date) {
        return dateFormatter.format(date)
      }
    },
    computed: {
      name () {
        return `${this.data.LastName} ${this.data.MiddleName} ${this.data.FirstName}`
      },
      ...mapState(['lang']),
    },
    i18n: {
      messages: {
        en: {
          created: 'Created',
          open: 'View request',
        },
        ru: {
          created: 'Создана',
          open: 'Просмотр',
        },
      }
    }
  }
</script>

<style scoped>
  .two-cols {
    display: flex;
    margin: 0 -15px;
  }
  .two-cols>.field {
    padding: 0 15px;
    flex: 1;
  }
  .link {
    white-space: nowrap;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  .actions>* {
    margin: 0 15px;
  }
  .actions .link {
    padding: 12px 0;
  }
</style>
