<template>
  <div class="event-card">
    <AfterSendMessageModal
      :modalText="'Данный пользователь ранее был добавлен в систему. Если его нет в Вашем списке экспертов, сообщите об этом по адресу: yasinconference@hse.ru'" />
    <Loading v-if="!isDataLoaded" />
    <div v-else class="card">
      <HseHeading size="h3">{{ $t('invite') }}</HseHeading>
      <HseSpace size='small' vertical />

      <Validator @change="checkEmail" v-bind:field="fields.email" />
      <HseSpace size='small_2x' vertical />

      <Validator :disabled="!expectIsNew" v-bind:field="fields.LName" />
      <div class="cols-2">
        <Validator :disabled="!expectIsNew" v-bind:field="fields.FName" />
        <Validator :disabled="!expectIsNew" v-bind:field="fields.SName" />
      </div>

      <Validator :disabled="singleEvent" v-bind:field="fields.event" @change="setEvent" />
      <HseSpace size='small_2x' vertical />

      <Validator :disabled="!expectIsNew" v-bind:field="fields.languageId" />
      <HseSpace size='small_2x' vertical />

      <Validator v-bind:field="fields.CompanyNameShort" />
      <HseSpace size='small_2x' vertical />

      <Validator v-show="fields.event.value" v-bind:field="fields.Topic" :key="fields.Topic.eventId" />
      <HseSpace size='small_2x' vertical />

      <!--Validator v-show="fields.event.value" v-bind:field="fields.Topic2" :key="fields.Topic2.eventId + 1"/>
      <HseSpace size='small_2x' vertical />

      <Validator v-show="fields.event.value" v-bind:field="fields.Topic3" :key="fields.Topic3.eventId + 2"/>
      <HseSpace size='small_2x' vertical /-->

      <Validator v-bind:field="fields.Agreed" />
      <HseSpace size='small_2x' vertical />

      <HseSpace size='small' vertical />
      <div class="two-cols">
        <a class="link" :href="`/#/experts_list`"> {{ $t('back') }} </a>
        <div class="prepull">
          <HseButton class="pull-right" size="medium" v-on:click="save()"> {{ $t('send') }}</HseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { api } from '../../api'
import Loading from '../common/Loading.vue'
import { HseHeading, HseSpace, HseButton } from '@hse-design/vue'
import Validator from '../common/form/Validator.vue'
import fields from './expertFields'
import { mapState } from 'vuex'
import { localizeFields } from '../../i18n'
import AfterSendMessageModal from '../common/modal/AfterSendMessageModal.vue'

export default {
  components: {
    HseButton,
    HseHeading,
    HseSpace,
    Validator,
    Loading,
    AfterSendMessageModal,
  },
  data: () => ({
    expectIsNew: true,
    expertId: null,
    eventId: null,
    isDataLoaded: true,
    fields,
    data: null,
    disableField: false,
    singleEvent: false,
  }),
  created() {
    this.translateFields()
    this.checkSingleEvent()
  },
  methods: {
    checkSingleEvent() {
      fields.event.getAllOptions().then(data => {
        if (data.length > 1) {
          this.singleEvent = false
        } else {
          this.singleEvent = true
          this.fields.event.setValues(true, data[0].value)
          this.setEvent(data[0].value)
        }
      })
    },
    setEvent(event) {
      this.fields.Topic.setEvent(event);
      //this.fields.Topic2.setEvent(event);
      //this.fields.Topic3.setEvent(event);
    },
    checkEmail() {
      this.$emit('validateFields')
      if (this.fields.email.isValid) {
        api.auth('user.manage.check.login', this.fields.email.value).then(result => {
          if (result) {
            this.expectIsNew = false
            api.auth('user.manage.get', result).then(data => {
              this.fields.FName.value = data.data.public.FName
              this.fields.LName.value = data.data.public.LName
              this.fields.SName.value = data.data.public.SName
              this.fields.CompanyNameShort.value = data.data.public.CompanyNameShort
              this.fields.languageId.value = data.data.public.lang
            })
            setTimeout(() => {
              this.$emit('validateFields')
            }, 500)
          } else {
            this.expectIsNew = true
            this.fields.FName.value = ''
            this.fields.LName.value = ''
            this.fields.SName.value = ''
            this.fields.CompanyNameShort.value = ''
            this.fields.languageId.value = ''
          }
        })
      }
    },
    save() {
      this.$emit('validateFields')
      let isFormInvalid = false
      Object.keys(this.fields).some(key => {
        if (this.fields[key] != this.fields.Topic2 && this.fields[key] != this.fields.Topic3) {
          isFormInvalid = this.fields[key].isValid === false
          return isFormInvalid
        }
      })
      if (isFormInvalid) return

      let params = {
        login: this.fields.email.value,
        event_id: this.fields.event.value,
        topic_id: this.fields.Topic.value,
        topic_id_2: null, //this.fields.Topic2.value,  
        topic_id_3: null, //this.fields.Topic3.value,  
        LName: this.fields.LName.value,
        FName: this.fields.FName.value,
        SName: this.fields.SName.value,
        CompanyNameShort: this.fields.CompanyNameShort.value,
        CompanyNameFull: null, //this.fields.CompanyNameFull.value,
        lang: this.fields.languageId.value
      };

      if (this.fields.Agreed.value) params.agreed = true;
      api.request('expert.invite', params).then((res) => {
        if (res?.user_already_is_experts) {
          this.$root.$refs.afterSendMessageModal.openModal();
          setTimeout(() => {
            this.$router.push('/experts_list');
          }, 10000)
        } else {
          this.$router.push('/experts_list');
        }
      })
    },
    translateFields() {
      this.fields.event.value = '';
      this.fields.Topic.value = '';
      //this.fields.Topic2.value='';
      //this.fields.Topic3.value='';
      this.fields = localizeFields(fields, this.fields)
    }
  },
  computed: mapState(['lang']),
  watch: {
    lang() {
      this.translateFields()
    }
  },
  i18n: {
    messages: {
      en: {
        invite: 'Invite expert',
        back: 'To experts list',
        send: 'Send an invitation',
      },
      ru: {
        invite: 'Пригласить эксперта',
        back: 'К экспертам',
        send: 'Отправить приглашение',
      },
    }
  }
}
</script>

<style scoped>
.event-card {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 32px;
  max-width: 800px;
  margin: 52px auto;
  width: calc(100vw - 320px);
}

@media (max-width: 767px) {
  .event-card {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }

  /* .cols-2 {
    display: block;
  } */
}

.big-item {
  max-width: 780px;
}

.link {
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.cols-2 {
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px; */
}

.prepull {
  overflow: auto;
}

.pull-right {
  float: right;
}
</style>