<template>
  <div class="wrapper">
    <TopNewsBanner />
    <div class="visa-card">
      <HseHeading size="h3">{{ $t('header') }}</HseHeading>
      <HseSpace v-show="experts.length" size='small_2x' vertical />
      <div v-show="experts.ßlength" class="cols-3">
        <div class="field">
          <Validator v-bind:field="fields.filter" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <Validator v-bind:field="fields.event" @change="updateEvent" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div v-show="fields.event.value" class="field">
          <Validator v-bind:field="fields.Topic" :key="fields.Topic.eventId" />
          <HseSpace size='small_2x' vertical />
        </div>
      </div>
      <HseSpace size='small' vertical />

      <div v-if="filtered.length">
        <div v-for="value in filtered" v-bind:key="value.id + Math.random()">
          <div class="row">
            <div class="name">{{ getName(value.data.public) }}</div>
            <div>{{ value.EventTitle }}</div>
            <div class="data">
              <div>{{ value.ExpertTopicTitle }}</div>
              <div>{{ value.ExpertTopicTitle2 }}</div>
              <div>{{ value.ExpertTopicTitle3 }}</div>
            </div>
            <div>{{ $t(`status.${getStatus(value.Status)}`) }}</div>
            <div class="cols-4">
              <HseTooltip :content="$t('reminder')" v-if="value.Status !== -1">
                <HseIconButton class="pull-right" size="small" :icon="IconGlobalEmail"
                  v-on:click="send_reminder_modal(value.id, value.EventId)" />
              </HseTooltip>
              <HseTooltip :content="$t('send_again')" v-if="value.Status < 1 && value.Status !== -1">
                <HseIconButton class="pull-right" size="small" :icon="IconActionProcess"
                  v-on:click="resend_invitation(value.id, value.EventId, value.ExpertTopicId, value.ExpertTopicId2, value.ExpertTopicId3)" />
              </HseTooltip>
              <HseTooltip :content="$t('edit')">
                <HseIconButton class="pull-right" size="small" :icon="IconLmsBook"
                  v-on:click="edit_expert(value.id, value.EventId)" />
              </HseTooltip>
              <HseTooltip v-if="!value.AddedToRequests" :content="$t('delete')">
                <HseIconButton class="pull-right" size="small" color="red" :icon="IconActionDelete"
                  v-on:click="confirm_remove(value.id, value.EventId)" />
              </HseTooltip>
              <HseTooltip v-if="value.AddedToRequests" :content="$t('deleteImpossible')">
                <HseIconButton class="pull-right disabled" size="small" color="red" :icon="IconActionDelete" />
              </HseTooltip>
            </div>
          </div>
          <HseDivider />
        </div>
      </div>
      <div v-else class="noData">
        {{ $t('noData') }}
      </div>

      <HseSpace size='medium' vertical />
      <div class="form-footer">
        <div class="actions">
          <HseButton v-on:click="invite_expert">
            {{ $t('send') }}
          </HseButton>
        </div>
      </div>

      <YesNoModal openMessage="openYesNoModal" responseMessage='openYesNoModalResponse' :text='msgtext'
        @openYesNoModalResponse='remove_expert' />
      <YesNoModal openMessage="expertReminder" responseMessage='expertReminderResponse' :text='msgtext1'
        @expertReminderResponse='send_reminder' />
    </div>
  </div>
</template>

<script>
import { HseHeading, HseSpace, HseButton, HseDivider, HseIconButton, IconLmsBook, IconActionDelete, IconGlobalEmail, IconActionProcess, HseTooltip } from '@hse-design/vue'
import { api } from '../api'
import YesNoModal from '../components/common/modal/YesNoModal.vue'
import { mapState } from 'vuex'
import fields from '../components/expertsPage/expertListFields'
import { localizeFields } from '../i18n'
import Validator from '../components/common/form/Validator.vue'
import TopNewsBanner from '../components/TopNewsBanner/TopNewsBanner.vue'

export default {
  components: {
    HseHeading,
    HseSpace,
    HseButton,
    HseDivider,
    HseTooltip,
    HseIconButton,
    YesNoModal,
    Validator,
    TopNewsBanner,
  },
  data: () => ({
    experts: [],
    isDataLoaded: false,
    IconLmsBook,
    IconActionDelete,
    IconGlobalEmail,
    IconActionProcess,
    msgtext: "Удалить из экспертов?",
    msgtext1: "Вы уверены, что хотите отправить повторное уведомление о ссылке в ЛК эксперта?",
    selected_expert: null,
    selected_event: null,
    modal_params: null,
    fields,
  }),
  created() {
    this.loadExperts();
    this.translateMessage();
  },
  methods: {
    loadExperts() {
      api.request('manager.expertList', {}).then((res) => {
        this.experts = res;
        this.isDataLoaded = true;
        this.updateFilter();
      })
    },
    confirm_remove(expert_id, event_id) {
      this.selected_expert = expert_id;
      this.selected_event = event_id;

      this.$emit('openYesNoModal')
    },
    remove_expert(data) {
      if (!data) return;
      api.request('expertDelete', { expert_id: this.selected_expert, event_id: this.selected_event }).then(() => {
        this.selected_expert = null;
        this.selected_event = null;

        this.isDataLoaded = false;
        this.loadExperts();
      })
    },
    edit_expert(expert_id, event_id) {
      this.$router.push('/expert/' + expert_id + '/' + event_id);
    },
    invite_expert() {
      this.$router.push('/new_expert');
    },
    translateMessage() {
      this.fields = localizeFields(fields, this.fields);
      this.fields.Topic.setEvent(this.fields.event.value)
      switch (this.lang) {
        case 'ru':
          this.msgtext = "Удалить из экспертов?";
          this.msgtext1 = "Вы уверены, что хотите отправить повторное уведомление о ссылке в ЛК эксперта?";
          break;
        default:
          this.msgtext = "Remove from experts?";
          this.msgtext1 = "Are you sure you want to send System for Registration and Support of HSE Events URL to expert again?";
          break;
      }
    },
    resend_invitation(expert_id, event_id, topic_id, topic_id_2, topic_id_3) {
      api.request('expert_re_invite', { expert_id, event_id, topic_id, topic_id_2, topic_id_3 }).then(() => {
        this.selected_expert = null
        this.selected_event = null

        this.isDataLoaded = false
        this.loadExperts();
      })
    },
    send_reminder_modal(expert_id, event_id) {
      this.modal_params = { expert_id, event_id }
      this.$emit('expertReminder')
    },
    send_reminder(data) {
      if (!data) return

      const { expert_id, event_id } = this.modal_params

      api.request('expert_send_link', { expert_id, event_id }).then(() => {
        this.selected_expert = null
        this.selected_event = null
      })
    },
    updateEvent() {
      this.fields.Topic.setEvent(this.fields.event.value);
    },
    setFilterByName(item) {
      let isMatchFilter = true
      if (this.filter) {
        const filter = this.filter.toLowerCase()
        const fullName = `${item.data.public['LName']} ${item.data.public['FName']}${item.data.public['SName'] ? ' ' + item.data.public['SName'] : ''}`.toLowerCase()
        isMatchFilter = fullName.includes(filter)
      }
      return isMatchFilter
    },
    setFilterByEvent(item) {
      if (this.fields.event.value != '' && this.fields.event.value != null) {
        if (item.EventId != this.fields.event.value) return false;

        if (this.fields.Topic.value != '' && this.fields.Topic.value != null) {
          if (item.ExpertTopicId == this.fields.Topic.value
            || item.ExpertTopicId2 == this.fields.Topic.value
            || item.ExpertTopicId3 == this.fields.Topic.value) return true
          else return false;
        } else return true;

      } else return true;
    },
    getName(uData) {
      return this.lang === 'ru'
        ? `${uData.LName} ${uData.FName} ${uData.SName}`
        : `${uData.FNameEN} ${uData.LNameEN}`
    },
    getStatus(status) {
      if (status > 0) return 'confirmed'
      else if (status < 0) return 'declined'
      return 'notConfirmed'
    },
  },
  computed: {
    ...mapState(['lang']),
    filter() {
      return this.fields.filter.value
    },
    filtered() {
      return this.experts
        .filter(expert => this.setFilterByName(expert))
        .filter(expert => this.setFilterByEvent(expert))
    },
  },
  watch: {
    lang() {
      this.translateMessage()
    }
  },
  i18n: {
    messages: {
      en: {
        status: {
          confirmed: 'Confirmed',
          declined: 'Declined',
          notConfirmed: 'Not confirmed',
        },
        header: 'Experts',
        back: 'To experts list',
        send: 'Send an invitation',
        send_again: 'Send an invitation',
        reminder: 'Send URL',
        edit: 'Edit',
        delete: 'Delete',
        noData: 'No data',
        deleteImpossible: 'Expert has been assigned to the application. Removal is not possible',
      },
      ru: {
        status: {
          confirmed: 'Приглашение принято',
          declined: 'Отказ эксперта',
          notConfirmed: 'Приглашение отправлено',
        },
        header: 'Эксперты',
        send: 'Добавить эксперта',
        send_again: 'Отправить приглашение',
        reminder: 'Отправить ссылку на ЛК',
        edit: 'Редактировать',
        delete: 'Удалить',
        noData: 'Нет данных',
        deleteImpossible: 'Эксперт назначен на заявку. Удаление невозможно',
      },
    }
  }
}
</script>

<style scoped>
.wrapper {
  max-width: 1500px;
  width: calc(100vw - 192px);
  margin: 24px auto;
}

.disabled {
  opacity: 0.5;
}

.visa-card {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 32px 32px;
}

.visa-card .form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 -16px;
}

.visa-card .form .field {
  padding: 0 16px;
  min-width: 0;
}

.subheading {
  font-family: 'Work Sans', sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #0F0F14;
}

.actions .hse-Button {
  display: inline-block;
}

.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.form-description {
  padding-top: 12px;
  font-size: 14px;
  line-height: 20px;
  color: #6B7A99;
}

.name {
  min-width: 150px;
}

.row {
  display: grid;
  grid-template-columns: 200px 1fr 2fr 200px 155px;
  gap: 10px;
  align-items: center;
  padding: 4px 0;
}

.row>div:nth-child(2) {
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
}

.data {
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
  gap: 10px;
  align-items: center;
  height: 100%;
}

.data>div {
  white-space: nowrap;
}

.cols-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;
}

.cols-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0 -8px;
}

.field {
  padding: 0 8px;
}

.cols-4 {
  display: flex;
  /*grid-template-columns: 1fr 1fr 1fr 1fr;*/
  gap: 4px;
  justify-content: flex-end;
  align-items: center;
  padding-left: 12px;
}

.cols-7 {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
  gap: 16px;
}

.noData {
  text-align: center;
}

@media (max-width: 1024px) {
  .visa-card {
    margin: 16px auto;
    max-width: 95%;
  }

  .visa-card .form {
    grid-template-columns: repeat(2, 1fr);
  }

  .emptyfield {
    display: none;
  }

  .cols-3 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1013px) {
  .visa-card {
    margin: 0 auto;
    max-width: 100%;
  }

  .visa-card .form {
    grid-template-columns: repeat(1, 1fr);
  }

  .form-footer {
    flex-direction: column;
    align-items: flex-start;
  }

  .row {
    grid-template-columns: 1fr;
  }

  .cols-3 {
    grid-template-columns: 1fr;
  }

  .cols-4 {
    display: flex;
    justify-content: flex-start;
    padding-left: 0;
  }
}

@media (max-width: 375px) {
  .cols-4 {
    justify-content: space-between;
  }
}
</style>
