<template>
  <div class="card">
    <HseHeading size="h3">{{
      data.Name === null
        ? lang === "ru"
          ? data.PlannedName
          : data.PlannedNameEn
        : data.Name
    }}</HseHeading>
    <HseSpace size="small" vertical />
    <ReadonlyInput :label="$t('number')" :value="'К' + data.requestNumber" />
    <ReadonlyInput :label="$t('event')" :value="eventName" />
    <div class="two-cols">
      <ReadonlyInput
        :label="$t('requestStatus')"
        :value="data.roundTableStatus"
      />
    </div>
    <div class="actions">
      <div v-if="canShow">
        <a class="link" :href="`/#/my-events/${data.id}`">{{ $t("open") }}</a>
      </div>
      <div v-if="canEdit">
        <a
          class="link"
          :href="`/#/round_table/create?event=${data.EventId}&rtId=${data.id}&step=1`"
          >{{ $t("edit") }}</a
        >
      </div>
      <div v-if="canContinue">
        <a
          class="link"
          :href="`/#/round_table/create?event=${data.EventId}&rtId=${data.id}&step=2`"
          >{{ $t("continue") }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import ReadonlyInput from "../common/ReadonlyInput.vue";
import { HseHeading, HseSpace } from "@hse-design/vue";
import { mapState } from "vuex";
import { api } from "../../api";
export default {
  name: "eventCard",
  components: {
    ReadonlyInput,
    HseHeading,
    HseSpace,
  },
  data: () => ({
    eventName: "",
  }),
  props: ["data"],
  created() {
    this.getEventName();
  },
  methods: {
    getEventName() {
      api
        .request("eventGet", { id: this.data.EventId, lang: this.lang })
        .then((data) => {
          this.eventName = data[0].Title;
          // console.log('data[0]',data[0])
        });
    },
  },
  watch: {
    lang() {
      this.getEventName();
    },
  },
  computed: {
    canEdit() {
      return (
        this.data.roundTableStatusId ===
          "37241B9D-2AAA-4EB4-9B01-F310902E0E84" || // Черновик
        this.data.roundTableStatusId === "31812363-221B-4B92-9B53-69003FF920C4" // Требуется доработка 1 этапа
      );
    },
    canContinue() {
      return (
        this.data.roundTableStatusId ===
          "3BB3404D-032C-48C1-9397-6FCF1E4A6080" || // Поддержана
        this.data.roundTableStatusId === "598FA3AE-2FBB-4703-8B75-02735E758EEA" // Требуется доработка 2 этапа
      );
    },
    canShow() {
      return (
        this.data.roundTableStatusId ===
          "EF7995A1-DA68-4A79-AE54-0BBFCA044F37" || // Отправлена на проверку
        this.data.roundTableStatusId ===
          "81A603E7-391C-460E-B413-FC153EA20C1A" || // Отправлена на рассмотрение
        this.data.roundTableStatusId === "28AE374D-D74C-4F4B-857C-E4E5BF0FF90A" // Отправлена на публикацию
      );
    },
    ...mapState(["lang"]),
  },
  i18n: {
    messages: {
      en: {
        payment: "Payment",
        annotation: "Annotation",
        annotationEn: "Annotation (en)",
        event: "Event",
        number: "Request number",
        format: "Form of participation",
        requestStatus: "Request status",
        paymentStatus: "Payment status",
        open: "View request",
        edit: "Edit",
        continue: "Continue filling in application",
      },
      ru: {
        payment: "Оплата",
        annotation: "Аннотация",
        annotationEn: "Аннотация (англ.)",
        event: "Мероприятие",
        number: "Номер заявки",
        format: "Формат участия",
        requestStatus: "Статус заявки",
        paymentStatus: "Статус оплаты",
        open: "Просмотр",
        edit: "Редактировать",
        continue: "Продолжить заполнение заявки",
      },
    },
  },
};
</script>

<style scoped>
.two-cols {
  display: flex;
  margin: 0 -15px;
}

.two-cols > div {
  padding: 0 15px;
  flex: 1;
}

.link {
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.actions > * {
  margin: 0 15px;
}

.actions .link {
  padding: 12px 0;
}

@media (max-width: 767px) {
  .two-cols,
  .actions {
    flex-direction: column;
  }

  .two-cols > div {
    width: 100%;
  }

  .actions {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
</style>
<style>
.hse-TooltipTarget {
  width: 100%;
}
</style>
