<template>
  <div>
    <HseModalInfo v-model="isOpen" :modal-style="{ maxHeight: '800px' }" ref="modal">
      <div v-if="$store.state.lang=='ru'" v-html="$store.state.static.agreements.ru" />
      <div v-else v-html="$store.state.static.agreements.en" />
    </HseModalInfo>
  </div>
</template>

<script>

  import { HseModalInfo,  } from '@hse-design/vue' 
  import { store } from '../../../store'

  export default {
    store,
    components: {
      HseModalInfo,
    },
    data: () => ({
      isOpen: false,
      isLoading: false,
    }),
    created () {
      this.$parent.$on('openPersonslDataModal', () => { this.isOpen = true })
    },
    watch: {
      isOpen (isOpen) {
        if (isOpen) {
          addEventListener('click', this.checkClickOutside)
          addEventListener('keyup', this.checkClickOutside)
        } else {
          removeEventListener('click', this.checkClickOutside)
          removeEventListener('keyup', this.checkClickOutside)
        }
      }
    },
    methods: {
      checkClickOutside (e) {
        if (!this.$refs.modal || e.key && e.key !== 'Escape') return

        let isClickOutside = false
        if (e.key === 'Escape') {
          isClickOutside = true
        } else {
          if (e.target.classList.contains('link')) return
          const portal = document.querySelector('.vue-portal-target')
          const modal = portal.querySelector('.hse-Modal')
          isClickOutside = !modal.contains(e.target)
        }
        if (isClickOutside) {
          this.isOpen = false
        }
      }
    }
  }
</script>

<style scoped>
  .content {
    min-height: 80px;
    margin: 52px auto;
  }
  .big-item {
    max-width: 780px;
  }
  .link {
    white-space: nowrap;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  .cols-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .pull-right {
    float:right;
  }
</style>