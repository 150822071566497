import { render, staticRenderFns } from "./FavoritesSession.vue?vue&type=template&id=fe7b9ab4&scoped=true&"
import script from "./FavoritesSession.vue?vue&type=script&lang=js&"
export * from "./FavoritesSession.vue?vue&type=script&lang=js&"
import style0 from "./FavoritesSession.vue?vue&type=style&index=0&id=fe7b9ab4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe7b9ab4",
  null
  
)

export default component.exports