<template>
  <HseModalForm v-model="isOpen">   
    <div class="content">
      <HseHeading size="h4">{{`${$t('header')} "${eventName}"?`}}</HseHeading>
      <HseSpace size='small_2x' vertical />
    </div>

    <template #actions>
      <HseButton @click="submit">{{$t('yes')}}</HseButton>
          <HseSpace size="small_2x" horizontal />
          <HseButton @click="cancel">{{$t('cancel')}}</HseButton>
    </template> 

  </HseModalForm>
</template>

<script>
  import { HseModalForm, HseButton, HseSpace, HseHeading } from '@hse-design/vue'

  export default {
    components: {
      HseModalForm,
      HseButton,
      HseSpace,
      HseHeading,
    },
    props: ['eventName'],
    data: () => ({
      isOpen: false,
    }),
    created () {
        this.$root.$refs.cancelModal = this
    },
    methods: {
        open() {
            this.isOpen = true
        },
        submit() {
            this.isOpen = false
            this.$parent.requestDeleteSpecialEventMembers()
        },
        cancel() {
            this.isOpen = false
        },
    }, 
    i18n: {
      messages: {
        en: {
            header: 'Please confirm that you want to cancel your registration for this special event',
            yes: 'Yes',
            cancel: 'Cancel',
        },
        ru: {
            header: 'Вы действительно хотите отменить регистрацию на мероприятие',
            yes: 'Да',
            cancel: 'Отмена',
        },
      }
    } 
  }
</script>

<style scoped>
    .content {
        min-height: 150px;
    }
</style>