<template>
  <div class="card">
    <HseHeading size="h3">{{ data.sessionName }}</HseHeading>
    <HseSpace size='small' vertical />
    <ReadonlyInput :label="$t('number')" :value="data.requestNumber" />
    <ReadonlyInput :label="$t('event')" :value="data.eventTitle" />
    <div class="two-cols">
      <ReadonlyInput :label="$t('requestStatus')" :value="data.status" />
    </div>
    <div class="actions">
      <div>
        <a class="link" :href="`/#/collective-requests/${data.id}`">{{ $t('open') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
  import ReadonlyInput from '../common/ReadonlyInput.vue'
  //import ReadonlyInputWithTooltip from '../common/ReadonlyInputWithTooltip.vue'
  import { HseHeading, HseSpace} from '@hse-design/vue'
  import { mapState } from 'vuex'
  export default {
    name: 'eventCard',
    components: {
      ReadonlyInput,
      //ReadonlyInputWithTooltip,
      HseHeading,
      HseSpace,
    },
    props: ['data'],
    computed: {
      ...mapState(['lang']),
    },
    i18n: {
      messages: {
        en: {
          payment: 'Payment',
          annotation: 'Annotation',
          annotationEn: 'Annotation (en)',
          event: 'Event',
          number: 'Request number',
          format: 'Form of participation',
          requestStatus: 'Request status',
          paymentStatus: 'Payment status',
          open: 'View request',
          certificateRu: 'Download certificate(ru)',
          certificateEn: 'Download certificate(en)',
          notPayed: 'Not payed',
          payed: 'Payed',
          paymentProcess: 'In payment process',
          PaymentNotNeeded: 'Payment not needed',
          PaymentDisabled: 'Payment disabled',
          PaymentHint: 'Payment is possible after your paper is validated and approved',
          infoPaymentDisabled: 'Payment for participation is possible after reviewing the report and including it in the conference program',
        },
        ru: {
          payment: 'Оплата',
          annotation: 'Аннотация',
          annotationEn: 'Аннотация (англ.)',
          event: 'Мероприятие',
          number: 'Номер заявки',
          format: 'Формат участия',
          requestStatus: 'Статус заявки',
          paymentStatus: 'Статус оплаты',
          open: 'Просмотр',
          certificateRu: 'Скачать сертификат(рус.)',
          certificateEn: 'Скачать сертификат(англ.)',
          notPayed: 'Не оплачена',
          payed: 'Оплачена',
          paymentProcess: 'В процессе оплаты',
          PaymentNotNeeded: 'Оплата не требуется',
          PaymentDisabled: 'Оплата недоступна',
          PaymentHint: 'Оплата участия возможна после рассмотрения доклада и включения его в программу конференции',
          infoPaymentDisabled: 'Оплата участия возможна после рассмотрения доклада и включения его в программу конференции',
        },
      }
    }
  }
</script>

<style scoped>
  .two-cols {
    display: flex;
    margin: 0 -15px;
  }
  .two-cols>div {
    padding: 0 15px;
    flex: 1;
  }
  .link {
    white-space: nowrap;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  .actions>* {
    margin: 0 15px;
  }
  .actions .link {
    padding: 12px 0;
  }
  @media (max-width: 767px) {
    .two-cols, .actions {
      flex-direction: column;
    }
    .two-cols>div {
      width: 100%;
    }
    .actions {
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
</style>
<style>
  .hse-TooltipTarget {
    width: 100%;
  }
</style>
