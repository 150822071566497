import { IconGlobalGrid, IconLmsForm, IconGlobalLanguage, IconStatusExpert, IconGlobalPrint } from '@hse-design/vue'
import { store } from '../../../store/index'

const russianMenu = [
  {
    id: 1,
    icon: IconGlobalGrid,
    name: 'menu.events',
    href: '/events'
  }, {
    id: 2,
    icon: IconLmsForm,
    name: 'menu.myEvents',
    href: '/my-events'
  }, {
    id: 3,
    icon: IconLmsForm,
    name: 'menu.myRequests',
    href: '/requests'
  }, {
    id: 8,
    icon: IconLmsForm,
    name: 'menu.collectiveRequests',
    href: '/collective-requests'
  }
]

const defaultMenu = [
  ...russianMenu, {
    id: 5,
    icon: IconGlobalLanguage,
    name: 'menu.visa',
    href: '/visas'
  }
]


const expertMenu = [
   {
    id: 4,
    icon: IconLmsForm,
    name: 'menu.moderateRequests',
    href: '/expert_requests'
  }
]

const managerMenu = [
  {
    id: 5,
    icon: IconStatusExpert,
    name: 'menu.experts',
    href: '/experts_list'
  },
  {
    id: 6,
    icon: IconLmsForm,
    name: 'menu.requests',
    href: '/manager_requests'
  },
  {
    id: 7,
    icon: IconGlobalPrint,
    name: 'menu.reports',
    href: '/reports'
  }
]

export const menu = {
  default: defaultMenu,
  1: defaultMenu,
  2: managerMenu,
  3: expertMenu,
  getItems(role) {
    switch (role) {
      case 1:
      default:
        if (store.state.user.data.public.Citizen == 'BC763F4B-FBD9-461B-B395-166020E8C026') return russianMenu;
        return defaultMenu;
      case 2:
        return managerMenu;
      case 3:
        return expertMenu;
    }
  },
}