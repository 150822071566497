import { HseInput } from '@hse-design/vue'
import { store } from '../../../store/index'

const fields = {
  login: {
    value: '',
    isValid: false,
    label: 'fields.name.email',
    isRequired: true,
    size: 'large',
    component: HseInput,
    rules: [
      {
      name: 'email',
      },
      {
      name: 'custom',
      message: 'fields.rule.userNotFound',
      validateVal () {
        const error = store.state.error && store.state.error.code === 7
        return !error
      },
    }],
  },
}

Object.keys(fields).forEach((key) => {
  fields[key].setValues = function (isValid, value = '') {
    this.value = value
    this.isValid = isValid
  }
})

export default fields
