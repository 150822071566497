<template>
  <div class="form">
    <HseHeading size="h2">{{ $t("title") }}</HseHeading>
    <HseSpace size="small" vertical />

    <div class="tabs-container">
      <HseTabs v-model="currentTab" :tabs="tabs" class="tabs" />
    </div>

    <HseSpace size="small" vertical />

    <!-- Этап 1 -->
    <div v-show="currentTab === '1'">
      <div class="headingWithTooltip">
        <div class="subheading">{{ $t("personal") }}</div>
        <HseTooltip :content="$t('initiatorApplicationTooltip')">
          <IconStatusInfo />
        </HseTooltip>
      </div>

      <HseSpace size="small" vertical />

      <div class="cols two-cols">
        <div class="field">
          <ReadonlyInput
            :value="data.InitiatorLastName"
            :label="$t('fields.name.lName')"
          />
        </div>
        <div class="field">
          <ReadonlyInput
            :value="data.InitiatorFirstName"
            :label="$t('fields.name.fName')"
          />
        </div>
      </div>

      <div class="cols two-cols">
        <div class="field">
          <ReadonlyInput
            :value="data.InitiatorMiddleName"
            :label="$t('fields.name.mName')"
          />
        </div>
      </div>

      <div class="cols two-cols">
        <div class="field">
          <ReadonlyInput
            :value="data.InitiatorPost"
            :label="$t('fields.name.position')"
          />
        </div>
        <div class="field">
          <ReadonlyInput
            :value="data.InitiatorDepartmenIDValue"
            :label="$t('fields.request.departmentRu')"
          />
        </div>
      </div>

      <div class="cols two-cols">
        <div class="field">
          <ReadonlyInput
            :value="data.InitiatorPhone"
            :label="$t('fields.name.phone')"
          />
        </div>
        <div class="field">
          <ReadonlyInput
            :value="data.InitiatorEmail"
            :label="$t('fields.name.email')"
          />
        </div>
      </div>

      <HseSpace size="small" vertical />
      <div class="subheading">{{ $t("speaker") }}</div>
      <HseSpace size="small" vertical />

      <div class="cols two-cols">
        <div class="field">
          <ReadonlyInput
            :value="data.ProgramHonoraryPaperTypeIdValue"
            :label="$t('fields.honoraryOrInvitedReport.speakerStatus')"
          />
        </div>
      </div>

      <div class="cols two-cols">
        <div class="field">
          <ReadonlyInput
            :value="data.LastName"
            :label="$t('fields.honoraryOrInvitedReport.speakerLName')"
          />
        </div>
        <div class="field">
          <ReadonlyInput
            :value="data.LastNameEN"
            :label="$t('fields.honoraryOrInvitedReport.speakerLNameEn')"
          />
        </div>
      </div>

      <div class="cols two-cols">
        <div class="field">
          <ReadonlyInput
            :value="data.FirstName"
            :label="$t('fields.honoraryOrInvitedReport.speakerFName')"
          />
        </div>
        <div class="field">
          <ReadonlyInput
            :value="data.FirstNameEN"
            :label="$t('fields.honoraryOrInvitedReport.speakerFNameEn')"
          />
        </div>
      </div>

      <div class="cols two-cols">
        <div class="field">
          <ReadonlyInput
            :value="data.MiddleName"
            :label="$t('fields.honoraryOrInvitedReport.speakerSName')"
          />
        </div>
      </div>

      <div class="cols two-cols">
        <div class="field">
          <ReadonlyInput
            :value="data.PostRU"
            :label="$t('fields.honoraryOrInvitedReport.speakerPosition')"
          />
        </div>
        <div class="field">
          <ReadonlyInput
            :value="data.PostEN"
            :label="$t('fields.honoraryOrInvitedReport.speakerPositionEn')"
          />
        </div>
      </div>

      <div class="cols two-cols">
        <div class="field">
          <ReadonlyInput
            :value="data.CompanyRU"
            :label="$t('fields.honoraryOrInvitedReport.speakerAffiliation')"
          />
        </div>
        <div class="field">
          <ReadonlyInput
            :value="data.CompanyEN"
            :label="$t('fields.honoraryOrInvitedReport.speakerAffiliationEn')"
          />
        </div>
      </div>

      <div class="cols two-cols">
        <div class="field">
          <ReadonlyInput
            :value="data.PlannedReportTopicRU"
            :label="
              $t('fields.honoraryOrInvitedReport.plannedTopicOfTheReport')
            "
          />
        </div>
        <div class="field">
          <ReadonlyInput
            :value="data.PlannedReportTopicEN"
            :label="
              $t('fields.honoraryOrInvitedReport.plannedTopicOfTheReportEn')
            "
          />
        </div>
      </div>

      <div class="cols two-cols">
        <div class="field">
          <ReadonlyInput
            :value="data.CountryIdValue"
            :label="$t('fields.honoraryOrInvitedReport.speakerCountry')"
          />
        </div>
        <div class="field">
          <ReadonlyInput
            :value="data.CitizenshipCountryIdValue"
            :label="$t('fields.honoraryOrInvitedReport.speakerNationality')"
          />
        </div>
      </div>

      <!-- <div class="cols two-cols">
        Копия паспорта докладчика
        <div class="field">
          <ReadonlyInput :value="data.PassportId" :label="$t('fields.honoraryOrInvitedReport.speakerPassport')" />
        </div>
      </div> -->

      <div class="cols two-cols">
        <div class="field">
          <ReadonlyInput
            :value="data.AnnotationRU"
            :label="$t('fields.honoraryOrInvitedReport.abstractTextRu')"
          />
        </div>
        <div class="field">
          <ReadonlyInput
            :value="data.AnnotationEN"
            :label="$t('fields.honoraryOrInvitedReport.abstractTextEng')"
          />
        </div>
      </div>

      <!-- <div class="cols two-cols">
        Резюме (CV) докладчика (на русском или на английском языке)
        <div class="field">
          <ReadonlyInput :value="data.ResumeId" :label="$t('fields.honoraryOrInvitedReport.speakerCv')" />
        </div>
      </div> -->

      <div class="cols two-cols">
        <div class="field">
          <ReadonlyInput
            :value="data.InvitationJustification"
            :label="$t('fields.honoraryOrInvitedReport.rationaleInvitation')"
          />
        </div>
      </div>

      <div class="cols two-cols">
        <div class="field">
          <ReadonlyInput
            :value="data.ParticipationFormatIdValue"
            :label="$t('eventFormat')"
          />
        </div>
      </div>

      <!-- Coordinating leader  -->
      <HseSpace size="small" vertical />
      <div class="subheading">
        {{ $t("fields.honoraryOrInvitedReport.coordinatingLeader") }}
      </div>
      <HseSpace size="small" vertical />

      <div class="cols two-cols">
        <div class="field">
          <ReadonlyInput
            :value="data.ChiefFIO"
            :label="$t('fields.honoraryOrInvitedReport.coordinatingLeaderFio')"
          />
        </div>
      </div>

      <div class="cols two-cols">
        <div class="field">
          <ReadonlyInput
            :value="data.ChiefPost"
            :label="$t('fields.name.position')"
          />
        </div>
        <div class="field">
          <ReadonlyInput
            :value="data.ChiefDepartmenIDValue"
            :label="$t('fields.request.departmentRu')"
          />
        </div>
      </div>

      <div class="cols two-cols">
        <div class="field">
          <!-- этого поля нет в БД -->
          <ReadonlyInput
            :value="data.ChiefEmail"
            :label="$t('fields.honoraryOrInvitedReport.email')"
          />
        </div>
      </div>

      <div class="cols two-cols">
        <div class="field">
          <ReadonlyInput
            :value="data.RequestComment"
            :label="$t('fields.honoraryOrInvitedReport.comment')"
          />
        </div>
      </div>
    </div>

    <div v-show="currentTab === '2'">
      <!-- Этап 2 -->
      <div class="cols two-cols">
        <!-- Название доклада (рус) -->
        <div class="field">
          <ReadonlyInput
            :value="data.TitleRU"
            :label="$t('fields.honoraryOrInvitedReport.titleReportRu')"
          />
        </div>
        <!-- Название доклада (eng) -->
        <div class="field">
          <ReadonlyInput
            :value="data.TitleEN"
            :label="$t('fields.honoraryOrInvitedReport.titleReportEng')"
          />
        </div>
      </div>

      <div class="cols two-cols">
        <!-- Текст аннотации (рус) -->
        <div class="field">
          <ReadonlyInput
            :value="data.AnnotationTextRU"
            :label="$t('fields.honoraryOrInvitedReport.abstractTextRu')"
          />
        </div>
      </div>

      <div class="cols two-cols">
        <!-- Текст аннотации (eng) * -->
        <div class="field">
          <ReadonlyInput
            :value="data.AnnotationTextEN"
            :label="$t('fields.honoraryOrInvitedReport.abstractTextEng')"
          />
        </div>
      </div>

      <!-- <div class="cols two-cols">
      Фото размером 200*200
      <div class="field">
        <ReadonlyInput :value="data.imageFileID" :label="$t('fields.honoraryOrInvitedReport.imageFileId')" />
      </div>
    </div> -->

      <!-- responsibleEvent -->
      <HseSpace size="small" vertical />
      <div class="subheading">{{ $t("responsibleEvent") }}</div>
      <HseSpace size="small" vertical />

      <div class="cols two-cols">
        <!-- ФИО Ответственного за мероприятие * -->
        <div class="field">
          <ReadonlyInput
            :value="data.ResponsibleFIO"
            :label="$t('fields.roundTable.responsibleFio')"
          />
        </div>
      </div>

      <div class="cols two-cols">
        <!-- Должность * -->
        <div class="field">
          <ReadonlyInput
            :value="data.ResponsiblePost"
            :label="$t('fields.honoraryOrInvitedReport.post')"
          />
        </div>
        <!-- Подразделение * -->
        <div class="field">
          <ReadonlyInput
            :value="data.ResponsibleDepartmenIDValue"
            :label="$t('fields.honoraryOrInvitedReport.division')"
          />
        </div>
      </div>

      <div class="cols two-cols">
        <!-- Предпочитаемая дата презентации доклада * -->
        <div class="field">
          <ReadonlyInput
            :value="data.StartTimeValue"
            :label="$t('fields.honoraryOrInvitedReport.date')"
          />
        </div>
        <!-- Предпочитаемое время презентации доклада * -->
        <div class="field">
          <ReadonlyInput
            :value="data.ProgramSessionTimeIdValue"
            :label="$t('fields.honoraryOrInvitedReport.programSessionTimeId')"
          />
        </div>
      </div>

      <div class="cols two-cols">
        <!-- Формат мероприятия * -->
        <div class="field">
          <ReadonlyInput
            :value="data.ParticipationFormatIdValue"
            :label="$t('eventFormat')"
          />
        </div>
        <!-- Публичность * -->
        <div class="field">
          <ReadonlyInput
            :value="data.PublicityValue"
            :label="$t('fields.roundTable.publicityId')"
          />
        </div>
      </div>

      <div class="cols two-cols">
        <!-- Выберите язык * -->
        <div class="field">
          <ReadonlyInput
            :value="data.lang"
            :label="$t('fields.name.language')"
          />
        </div>
      </div>

      <!-- Секретарь  -->
      <HseSpace size="small" vertical />
      <div class="subheading">
        {{ $t("fields.honoraryOrInvitedReport.secretary") }}
      </div>
      <HseSpace size="small" vertical />

      <div class="cols two-cols">
        <div
          class="field"
          v-show="
            data.OrganizationFormatId ===
              '1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A' ||
            data.OrganizationFormatId === '895BB6AB-A343-48DC-AA19-7EAB348C8C0D'
          "
        >
          <ReadonlyInput
            :value="data.SecretaryOnlineValue"
            :label="$t('fields.honoraryOrInvitedReport.secretaryOnline')"
          />
        </div>
        <div
          class="field"
          v-show="
            data.OrganizationFormatId ===
              '592D7A01-E55F-4860-B403-1BB88FDE85A9' ||
            data.OrganizationFormatId === '895BB6AB-A343-48DC-AA19-7EAB348C8C0D'
          "
        >
          <ReadonlyInput
            :value="data.SecretaryOfflineValue"
            :label="$t('fields.honoraryOrInvitedReport.secretaryOffline')"
          />
        </div>
      </div>

      <div>
        <HseSpace size="small" vertical />
        <div
          :style="{ background: '#6B7A99', padding: '6px' }"
          class="accordion__head"
        >
          <HseButton
            variant="tertiaryInvert"
            size="medium"
            v-on:click="isModeratorBlockOpen = !isModeratorBlockOpen"
          >
            {{ $t("moderatorBlock") }}
            <span class="ident" v-show="isModeratorBlockOpen">&#9650;</span>
            <span class="ident" v-show="!isModeratorBlockOpen">&#9660;</span>
          </HseButton>
          <div
            class="accordion__info"
            v-show="!(isModeratorBlockOpen && data.moderatorsNewLength)"
          >
            {{ $t("moderatorInfo") }}
          </div>
        </div>
        <div
          :style="{ background: '#F0F2F5', padding: '6px' }"
          v-show="isModeratorBlockOpen"
        >
          <div class="accordion">
            <div
              class="participant"
              v-for="(moderator, index) in data.moderatorsNew"
              :key="moderator.login"
            >
              <div class="participant__heading">
                <HseHeading size="h4">{{
                  `${$t("fields.name.moderators")} №${index + 1}`
                }}</HseHeading>
              </div>
              <HseSpace size="small" vertical />
              <div>
                <ReadonlyInput
                  :label="$t('fields.name.lName')"
                  :value="moderator.lastName"
                />
                <ReadonlyInput
                  :label="$t('fields.name.fName')"
                  :value="moderator.firstName"
                />
                <ReadonlyInput
                  v-if="moderator.secondName"
                  :label="$t('fields.name.mName')"
                  :value="moderator.secondName"
                />
                <ReadonlyInput
                  :label="$t('fields.name.email')"
                  :value="moderator.email"
                />
                <ReadonlyInput
                  :label="$t('fields.name.companyFull')"
                  :value="moderator.company"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div v-if="data.speakers && data.speakers.length">
      <HseSpace size='small' vertical/>
      <div :style="{background: '#6B7A99', padding: '6px'}" class="accordion__head">
        <HseButton variant='tertiaryInvert' size='medium' v-on:click="isSpeakerBlockOpen = !isSpeakerBlockOpen">
          {{ $t('speakerBlock') }} <span class="ident" v-show="isSpeakerBlockOpen">&#9650;</span>
          <span class="ident" v-show="!isSpeakerBlockOpen">&#9660;</span>
        </HseButton>
      </div>
      <div v-show="isSpeakerBlockOpen" :style="{background: '#F0F2F5', padding: '6px'}">
        <div class="accordion">
          <div class="participant" v-for="(speaker, index) in data.speakers" :key="speaker.login">
            <div class="participant__heading">
              <HseHeading size="h4">{{ `${$t('fields.name.speakers')} №${index + 1}` }}</HseHeading>
            </div>
            <HseSpace size="small" vertical />
            <div>
              <ReadonlyInput :label="$t('fields.name.lName')" :value="speaker.lastName" />
              <ReadonlyInput :label="$t('fields.name.fName')" :value="speaker.firstName" />
              <ReadonlyInput v-if="speaker.secondName" :label="$t('fields.name.mName')" :value="speaker.secondName" />
              <ReadonlyInput :label="$t('fields.name.email')" :value="speaker.email" />
              <ReadonlyInput :label="$t('fields.name.companyFull')" :value="speaker.organizationName" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="data.members && data.members.length">
      <HseSpace size='small' vertical/>
      <div :style="{background: '#6B7A99', padding: '6px'}" class="accordion__head">
        <HseButton variant='tertiaryInvert' size='medium' v-on:click="isMemberBlockOpen = !isMemberBlockOpen">
          {{ $t('memberBlock') }} <span class="ident" v-show="isMemberBlockOpen">&#9650;</span>
          <span class="ident" v-show="!isMemberBlockOpen">&#9660;</span>
        </HseButton>
      </div>
      <div v-show="isMemberBlockOpen" :style="{background: '#F0F2F5', padding: '6px'}">
        <div class="accordion">
          <div class="participant" v-for="(member, index) in data.members" :key="member.login">
            <div class="participant__heading">
              <HseHeading size="h4">{{ `${$t('fields.name.members')} №${index + 1}` }}</HseHeading>
            </div>
            <HseSpace size="small" vertical />
            <div>
              <ReadonlyInput :label="$t('fields.name.lName')" :value="member.lastName" />
              <ReadonlyInput :label="$t('fields.name.fName')" :value="member.firstName" />
              <ReadonlyInput v-if="member.secondName" :label="$t('fields.name.mName')" :value="member.secondName" />
              <ReadonlyInput :label="$t('fields.name.email')" :value="member.email" />
              <ReadonlyInput :label="$t('fields.name.companyFull')" :value="member.organizationName" />
            </div>
          </div>
          <div class="actions">
            <HseButton variant='primary' size="medium">
              {{ $t('QRCode') }}
            </HseButton>
            <HseButton variant='primary' size="medium">
              {{ $t('Approve') }}
            </HseButton>
          </div>
        </div>
      </div>
      <HseSpace size='small' vertical />
    </div> -->

    <HseSpace size="medium" vertical />
    <div class="actions">
      <HseLink variant="secondary" @click="back">
        {{ $t("back") }}
      </HseLink>
    </div>
  </div>
</template>

<script>
import {
  HseHeading,
  HseSpace,
  HseButton,
  HseLink,
  HseTabs,
  IconActionPlus,
  HseTooltip,
  IconStatusInfo,
} from "@hse-design/vue";
import ReadonlyInput from "../common/ReadonlyInput.vue";
import { mapState } from "vuex";
import { api } from "../../api";
import { dateFormatter } from "../common/dateFormatter";
import fields from "../honoraryOrInvitedReportPage/roundTableFields";

export default {
  name: "MyEventReadOnly",
  components: {
    HseHeading,
    HseSpace,
    HseButton,
    HseLink,
    HseTabs,
    ReadonlyInput,
    HseTooltip,
    IconStatusInfo,
  },
  data: () => ({
    fields,
    plusIcon: IconActionPlus,
    isModeratorBlockOpen: true,
    isSpeakerBlockOpen: true,
    isMemberBlockOpen: true,
    isMemberModalOpen: false,
    members: [],
    editableMember: null,
    eventId: "",
    data: {},
    currentTab: "1",
    tabs: [
      {
        value: "1",
        label: "Содержательная информация",
      },
      {
        value: "2",
        label: "Организационная информация",
        disabled: true,
      },
    ],
  }),
  created() {
    if (this.$route.query && this.$route.params.id) {
      this.eventId = this.$route.params.id;
    }
    this.getData();
    if (
      this.data.roundTableStatusId === "598FA3AE-2FBB-4703-8B75-02735E758EEA" ||
      this.data.roundTableStatusId === "EF7995A1-DA68-4A79-AE54-0BBFCA044F37" ||
      this.data.roundTableStatusId === "28AE374D-D74C-4F4B-857C-E4E5BF0FF90A" ||
      this.data.roundTableStatusId === "C08814A5-7780-4595-9A31-AF51383E3933"
    ) {
      this.tabs[1].disabled = false;
    }
  },
  methods: {
    back() {
      this.$router.push("/my-events");
    },
    getData() {
      api
        .request("get_hor_events_data", {
          horEventId: this.eventId,
          lang: this.lang,
        })
        .then((data) => {
          this.data = data[0];

          console.log("data2312", data);

          let val = [];

          if (this.data.LangRU) {
            val.push(this.$i18n.tc("fields.roundTable.langRu"));
          }
          if (this.data.LangEN) {
            val.push(this.$i18n.tc("fields.roundTable.langEn"));
          }
          if (this.data.LangOther) {
            val.push(this.data.Other);
          }

          this.data.lang = val.join(", ");

          let moderatorsNew = [];
          for (const item of this.data.moderators) {
            moderatorsNew.push({
              id: item.Id,
              lastName: item.LastName,
              firstName: item.FirstName,
              middleName: item.MiddleName,
              firstNameEN: item.FirstNameEN,
              lastNameEN: item.LastNameEN,
              company: item.Company,
              companyEN: item.CompanyEN,
              email: item.Email,
              HSEMember: item.HSEMember,
              campusId: item.campusId,
            });
          }
          this.data.moderatorsNew = moderatorsNew;
          this.data.  moderatorsNewLength =   moderatorsNew?.length;

          if (
            this.data.StatusId === "598FA3AE-2FBB-4703-8B75-02735E758EEA" ||
            this.data.StatusId === "EF7995A1-DA68-4A79-AE54-0BBFCA044F37" ||
            this.data.StatusId === "28AE374D-D74C-4F4B-857C-E4E5BF0FF90A" ||
            this.data.StatusId === "C08814A5-7780-4595-9A31-AF51383E3933"
          ) {
            this.tabs[1].disabled = false;
          }
        });
    },
    formatDate(date) {
      return dateFormatter.format(date, "date");
    },
  },
  computed: {
    langValue() {
      let val = [];

      if (this.data.langRu) {
        val.push(this.$i18n.tc("fields.roundTable.langRu"));
      }
      if (this.data.langEn) {
        val.push(this.$i18n.tc("fields.roundTable.langEn"));
      }
      if (this.data.LangOther) {
        val.push(this.data.Other);
      }

      return val.join(", ");
    },
    ...mapState(["lang"]),
  },
  watch: {
    lang() {
      this.getData();
    },
  },
  i18n: {
    messages: {
      en: {
        title: "Заявка на почетный/приглашенный доклад",
        QRCode: "Send QR code",
        moderatorBlock: "Moderators",
        speakerBlock: "Experts",
        memberBlock: "Participants",
        pass: "Pass",
        letter: "Letter",
        no: "No",
        yes: "Yes",
        addParticipant: "Add",
        Approve: "Approve all",
        Webinar: "Send the link for a webinar",
        back: "Back",
        submit: "Send a request",
        draft: "Save a draft",
        okStatus: "Request sent",
        errStatus: "Error occured",
        chooseLang: "Choose a language",
        eventFormat: "Planned format of the speaker's participation",
        approved: "Approved",
        rejected: "Rejected",
        sent: "Sent",
        save: "Save changes",
        personal: "Request Initiator",
        speaker: "Speaker",
        AnnotationTextHseTooltip: "The volume is not less than 1000 characters",
        honoraryReportHint:
          "Почетный доклад. Подсказка: – доклад российского или иностранного гостя конференции с высоким показателем научного и/или экспертного рейтинга. Тематика почетного доклада, как правило, включает общие вопросы развития экономики и общества. Почетный доклад – всегда самостоятельное мероприятие.",
        invitedReportHint:
          "Приглашённый доклад. Подсказка: – доклад, представляемый российским или иностранным гостем конференции с высоким показателем научного и/или экспертного рейтинга. Тема приглашенного доклада может быть одной из тем сессии и/или круглого стола.",
        moderatorInfo:
          "The moderator details are captured in the system using their email address, whether it's their own or that of their accompanying person. This is essential for creating a personalized record for the moderator/speaker and for facilitating subsequent organizational tasks, such as registration and pass issuance. We will not use these emails for sending automated notifications. If you have any questions, please feel free to contact the Organizing Committee at YasinConference@hse.ru.",
      },
      ru: {
        QRCode: "Отправить QR код",
        moderatorBlock: "Модераторы",
        speakerBlock: "Эксперты-спикеры",
        memberBlock: "Участники",
        pass: "Пропуск",
        letter: "Письмо",
        no: "Нет",
        yes: "Да",
        addParticipant: "Добавить",
        Approve: "Одобрить всех",
        Webinar: "Отправить ссылку на вебинар",
        back: "Назад",
        submit: "Подать заявку",
        draft: "Сохранить черновик",
        okStatus: "Заявка отправлена",
        errStatus: "Произошла ошибка",
        chooseLang: "Выберите язык",
        eventFormat: "Планируемый формат участия докладчика ",
        approved: "Одобрен",
        rejected: "Отклонен",
        sent: "Отправлен",
        save: "Сохранить изменения",
        personal: "Инициатор заявки",
        speaker: "Докладчик",
        initiatorApplicationTooltip:
          "сотрудник НИУ ВШЭ, который заполняет заявку на проведение специального мероприятия ЯМНК и направляет ее на рассмотрение в Программный комитет",
        coordinatingHeadTooltip:
          "руководитель НИУ ВШЭ (уровня первого проректора/проректора/декана/члена ПК), который берет на себя ответственность за инициируемое мероприятие в рамках ЯМНК",
        AnnotationTextHseTooltip: "Объем не менее 1000 знаков",
        honoraryReportHint:
          "Почетный доклад. Подсказка: – доклад российского или иностранного гостя конференции с высоким показателем научного и/или экспертного рейтинга. Тематика почетного доклада, как правило, включает общие вопросы развития экономики и общества. Почетный доклад – всегда самостоятельное мероприятие.",
        invitedReportHint:
          "Приглашённый доклад. Подсказка: – доклад, представляемый российским или иностранным гостем конференции с высоким показателем научного и/или экспертного рейтинга. Тема приглашенного доклада может быть одной из тем сессии и/или круглого стола.",
        responsibleEvent: "Ответственный за мероприятие",
        responsibleEventTooltip:
          "сотрудник НИУ ВШЭ, который координирует процесс подачи заявки на мероприятие и, если она поддержана, его проведение",
        moderatorInfo:
          "Данные модератора фиксируются в системе через электронный адрес (свой или сопровождающего). Это необходимо для формирования индивидуальной записи модератора/спикера и для дальнейшей организационной работы, в т.ч. для регистрации и оформления пропуска. Автоматическая рассылка писем не предполагается. В случае вопросов просим обращаться в Организационный комитет конференции: YasinConference@hse.ru",
      },
    },
  },
};
</script>

<style scoped>
.form {
  width: 800px;
  margin: 52px auto;
  background: #ffffff;
  border-radius: 12px;
  padding: 32px;
  max-width: calc(100vw - 320px);
}

.two-cols,
.three-cols {
  display: flex;
  margin: 0 -15px;
}

.two-cols > .field,
.three-cols > .field {
  padding: 0 15px;
  flex: 1;
}

.three-cols .field {
  width: 33.3%;
}

.ident {
  margin-left: 10px;
}

@media (max-width: 767px) {
  .form {
    /* max-width: unset; */
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
  }

  .two-cols,
  .three-cols {
    flex-direction: column;
  }

  .two-cols > .field,
  .three-cols > .field {
    width: 100%;
  }
}

.actions {
  flex-wrap: wrap;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.accordion {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
}

.accordion__head {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.accordion__info {
  font-size: 14px;
  line-height: 1.6em;
  color: #fff;
  padding: 8px 24px;
}

.participant__heading {
  display: flex;
  align-items: center;
  gap: 5px;
}

.participant__heading .icons {
  display: flex;
  gap: 3px;
}

.participant__heading .icons > svg {
  cursor: pointer;
}

.headingWithTooltip {
  display: flex;
}

.headingWithTooltip > div:first-child {
  padding: 2px 0 0 0;
}

.headingWithTooltip > div:last-child {
  padding: 0px 0 0 12px;
}

.subheading {
  font-family: "Work Sans", sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #0f0f14;
}
</style>
