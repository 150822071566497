<template>
  <div class="form">
    <div class="subheading">{{ $t('moderators') }}</div>
    <HseSpace size="small" vertical />
    <div class="moderators">
      <div class="members">
        <div class="member" v-show="fields.isModerator.value">
          <div>{{ getModeratorName(user) }}</div>
        </div>
        <div class="member" v-for="(moderator, index) in moderators" :key="moderator.login">
          <div>{{ getModeratorName(moderator) }}</div>
          <div class="icons">
            <svg @click="() => editModerator(moderator, index)" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.60161 5.35704L11.4995 7.26425L6.69535 12.092L4.7985 10.1848L9.60161 5.35704ZM12.9397 4.89707L12.0933 4.04652C11.7662 3.71781 11.235 3.71781 10.9068 4.04652L10.0961 4.86126L11.994 6.76849L12.9397 5.81814C13.1934 5.56317 13.1934 5.15202 12.9397 4.89707ZM3.80527 12.8655C3.77073 13.0217 3.91108 13.1617 4.06654 13.1237L6.18147 12.6084L4.28461 10.7012L3.80527 12.8655Z" fill="#6B7A99"/>
            </svg>
            <svg @click="() => removeModerator(moderator)" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6663 5.164L11.7263 4.224L7.99967 7.95067L4.27301 4.224L3.33301 5.164L7.05967 8.89067L3.33301 12.6173L4.27301 13.5573L7.99967 9.83067L11.7263 13.5573L12.6663 12.6173L8.93967 8.89067L12.6663 5.164Z" fill="#6B7A99"/>
            </svg>
          </div>
        </div>
      </div>
      <div class="add-member" @click="() => isModalOpen = true">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21 11.5H13V3.5H11V11.5H3V13.5H11V21.5H13V13.5H21V11.5Z" fill="#0050CF"/>
        </svg>
        <span>{{ $t('addModerator') }}</span>
      </div>
    </div>
    <HseModal v-model="isModalOpen" :shouldCloseOnOverlayClick="false">
      <ModeratorForm
        v-if="isModalOpen"
        :editableModerator="editableModerator"
        :added="moderators"
        @saved="onModeratorSave"
      />
    </HseModal>
    <SubmitModal />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { store } from '../../store'
  import { HseSpace, HseModal } from '@hse-design/vue'
  import ModeratorForm from './ModeratorForm.vue'
  import SubmitModal from '../common/modal/SubmitModal.vue'

  export default {
    name: 'FormPage2',
    store,
    props: ['fields'],
    data: () => ({
      isModalOpen: false,
      moderators: [],
      editableModerator: null,
      user: null,
    }),
    created () {
      this.user = this.$store.state.user
      this.$parent.$on('validatePage', ({ current }) => {
        if (current === 2) {
          this.checkPageValidity()
        }
      })
    },
    components: {
      HseSpace,
      HseModal,
      ModeratorForm,
      SubmitModal,
    },
    methods: {
      checkPageValidity () {
        if (this.moderators.length > 0 ? true : this.fields.isModerator.value) {
          this.$emit('validatePage', { page: 2, isValid: true })
        } else {
          this.$root.$refs.submitModal.open(this.$i18n.tc('noModerators'))
        }
      },
      editModerator (moderator, index) {
        this.editableModerator = { ...moderator, index }
        this.isModalOpen = true
      },
      onModeratorSave (result) {
        if (!this.editableModerator) {
          this.moderators.push(result)
          this.$emit('updateModerators', this.moderators)
        } else {
          this.moderators[this.editableModerator.index] = result
        }


        this.editableModerator = null
        this.isModalOpen = false
      },
      getModeratorName (moderator) {
        const hasData = moderator && moderator.data && moderator.data.public
        if (!hasData) return ''

        return this.lang === 'ru' 
          ? `${moderator.data.public.LName} ${moderator.data.public.FName} ${moderator.data.public.SName}`
          : `${moderator.data.public.LNameEN} ${moderator.data.public.FNameEN}`
      },
      removeModerator ({ id }) {
        this.moderators = this.moderators.filter(moderator => moderator.id !== id)
        this.$emit('updateModerators', this.moderators)
      }
    },
    computed: {
      ...mapState(['lang']),
    },
    watch: {
      isModalOpen (isOpen) {
        if (!isOpen) {
          this.editableModerator = null
        }
      },
    },
    i18n: {
      messages: {
        en: {
          addModerator: 'Add a moderator',
          moderators: 'Add moderators',
          noModerators: 'You haven`t added any moderators',
        },
        ru: {
          addModerator: 'Добавить модератора',
          moderators: 'Добавить модераторов',
          noModerators: 'Вы не добавили ни одного модератора',
        },
      }
    },
  }
</script>

<style scoped>
  .form {
    width: 670px;
  }
  .add-member {
    background: #F0F5FF;
    border-radius: 4px;
    border: 1px solid #8EB2EC;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #0050CF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 24px 8px 16px;
    width: fit-content;
    cursor: pointer;
  }
  .add-member svg {
    margin-right: 11px;
  }
  .members {
    width: fit-content
  }
  .member {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    color: #6B7A99;
    padding: 10px 0;
    margin-bottom: 12px;
    justify-content: space-between;
  }
  .member:last-child {
    margin-bottom: 17px;
  }
  .member .icons {
    margin-left: 20px;
  }
  .member .icons>svg {
    cursor: pointer;
    margin: 0 3px;
  }
</style>
<style>
  .hse-BaseModal {
    width: 690px !important;
    max-height: 100%;
  }
</style>