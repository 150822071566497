<template>
  <div>
    <div v-if="isLangRu">
      <div>
        <p>
          <b>Я подтверждаю</b>, что лично ознакомился с
          <a
            target="_blank"
            href="https://www.hse.ru/data_protection_regulation"
          >
            Положением об обработке персональных данных НИУ ВШЭ </a
          >(101000 Москва, 20 Мясницкая ул. (ИНН/КПП 7714030726/770101001)),
          вправе предоставлять свои персональные данные и давать согласие на их
          обработку.
        </p>
        <p>
          Я даю свое согласие на обработку, в том числе с использованием средств
          автоматизации, своих персональных данных, указанных в форме автора
          заявки с докладом, включая копии документов, подтверждающих у меня
          наличие права не платить регистрационный взнос (студенческий билет,
          пропуск сотрудника, иные документы), с целью обеспечения моего участия
          в мероприятии (регистрации на мероприятие, формирования пакета
          участника, обработки данных заявки, получения информационных рассылок
          от НИУ ВШЭ по вопросам участия в мероприятии, оплаты регистрационного
          взноса, визовой поддержки), с момента заполнения регистрационной формы
          и на весь период функционирования Заявки, но не более 5 (пяти) лет с
          момента предоставления согласия, путем сбора, записи, систематизации,
          накопления, хранения, уточнения (обновления, изменения), извлечения,
          использования, обезличивания, блокирования, удаления, уничтожения.
        </p>
        <p>
          Я даю свое согласие на обработку моих персональных данных, включая
          фото- и видеоизображение, в целях размещения в сети Интернет (в том
          числе на корпоративном сайте (портале) НИУ ВШЭ) сведений о мероприятии
          в течение всего срока размещения таких материалов, а также на передачу
          моих персональных данных третьим лицам в целях содействия в
          опубликовании моего доклада.
        </p>
        <p>Субъект персональных данных вправе:</p>
        <ol>
          <li>
            Получать сведения о своих персональных данных, обрабатываемых НИУ
            ВШЭ (например, чтобы убедиться в том, что персональные данные
            обрабатываются в НИУ ВШЭ или чтобы получить информацию о
            персональных данных, которые обрабатываются в НИУ ВШЭ);
          </li>
          <li>Требовать уточнения неточных/неполных персональных данных;</li>
          <li>
            Требовать уничтожения персональных данных (когда цель обработки
            персональных данных была достигнута; в случае отзыва согласия; в
            случае незаконной обработки или в соответствии с требованиями
            применимого законодательства);
          </li>
          <li>
            Требовать ограничения обработки НИУ ВШЭ персональных данных (когда
            был направлен запрос на уточнение данных или было направлено
            требование о прекращении обработки персональных данных);
          </li>
          <li>
            Переносить персональные данные (другому оператору персональных
            данных);
          </li>
          <li>
            Отозвать согласие в любое время (отзыв согласия не повлияет на
            законность обработки данных до момента отзыва согласия);
          </li>
          <li>
            Направить жалобу в уполномоченный орган по защите прав субъектов
            персональных данных (Федеральная служба по надзору в сфере связи,
            информационных технологий и массовых коммуникаций).
          </li>
        </ol>
        <p>
          Настоящее согласие может быть отозвано путем направления письменного
          заявления в адрес НИУ ВШЭ.
        </p>
      </div>
    </div>
    <div v-if="!isLangRu">
      <div>
        <p>
          <b>I hereby confirm </b>that I have read and understood the<a
            target="_blank"
            href="https://www.hse.ru/en/data_protection_regulation"
          >
            Regulations on Personal Data Processing at National Research
            University Higher School of Economics</a
          >
          (20 Myasnitskaya Ulitsa, Moscow, Russian Federation, 101000, INN (TIN)
          / KPP 7714030726/770101001). I am entitled to disclose my personal
          data and provide my consent to its subsequent processing
        </p>
        <p>
          I hereby grant my consent to the processing (including with the use of
          automated means) of my personal data, as specified in the application
          of the author of the work, including the copies of the documents
          verifying the right to be free from registration fees (student or
          employee ID, other documents), for the purpose of ensuring my
          involvement in the event (registration, putting together participant’s
          documentation package, processing data in relevant applications,
          obtaining information mail-outs from HSE University concerning the
          event, paying registration fees, receiving visa support, etc.), from
          the moment when the registration forms are filled in and for the
          entire period of the application’s validity, but no more than 5 (five)
          years from the date when one’s consent is granted, through the
          collection, recording, systemization, accumulation, storage, amendment
          (updating and changing), retrieval, use, depersonalization, blocking,
          deletion and destruction thereof.
        </p>
        <p>
          I hereby provide my consent to the processing of my personal data,
          including photo and video images, for the purpose of publication
          online (on the University’s website (portal)) of information about the
          event during the entire period of such materials’ publication,
          including transfer of my personal data to third parties for the
          purpose of supporting the publication of my work.
        </p>
        <p>The personal data subject has the right to:</p>
        <ol>
          <li>
            obtain information about personal data being processed by HSE
            University (e.g., to make sure that personal data is processed by
            HSE University, or to receive information about the personal data,
            which is processed at the University);
          </li>
          <li>request amendments to incorrect/incomplete personal data;</li>
          <li>
            request the destruction of personal data (when the purpose of data
            processing has been achieved; if consent is revoked; in cases of
            illegal processing of data in line with the provisions of applicable
            legislation);
          </li>
          <li>
            require restrictions on HSE University’s processing of personal data
            (when requests are made to amend data or halt processing of personal
            data);
          </li>
          <li>transfer personal data (to other personal data operators);</li>
          <li>
            revoke their consent at any time (revoking one’s consent shall not
            impact on the legality of data processing prior to the removal of
            consent);
          </li>
          <li>
            file complaints with the state body authorized to protect the rights
            of personal data subjects (i.e., Federal Service for Supervision of
            Communications, Information Technology and Mass Media).
          </li>
        </ol>
        <p>
          This consent may be withdrawn by sending a written request to HSE
          University.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {},
  data: () => ({}),
  created() {},
  methods: {},
  computed: {
    isLangRu() {
      return this.lang === "ru";
    },
    ...mapState(["lang"]),
  },
  watch: {
    lang() {},
  },
  i18n: {
    messages: {
      en: {},
      ru: {},
    },
  },
};
</script>

<style scoped></style>
