import { HseInput, HseSelect, HseCheckbox } from '@hse-design/vue'
import { resourceLoader } from '../common/resourceLoader'
const fields = {

  // page 1
  isModerator: {
    value: false,
    isValid: true,
    hideTopLabel: true,
    label: 'fields.collective.isModerator',
    classes: { label: 'smallLabel' },
    component: HseCheckbox,
  },
  direction: {
    value: '',
    isValid: false,
    label: 'fields.collective.direction',
    isRequired: true,
    size: 'large',
    component: HseSelect,
    eventId: '',
    getAllOptions (search) {
      return resourceLoader.load('eventTopicsList', {event_id: this.eventId}, [], search)
    },
  },
  sessionName: {
    value: '',
    isValid: false,
    label: 'fields.collective.sessionName',
    isRequired: true,
    size: 'large',
    component: HseInput,
  },
  sessionNameEn: {
    value: '',
    isValid: false,
    label: 'fields.collective.sessionNameEn',
    isRequired: true,
    size: 'large',
    component: HseInput,
    rules: [{
      name: 'latin',
    }],
  },
  lastName: {
    value: '',
    isValid: true,
  },
  firstName: {
    value: '',
    isValid: true,
  },
  secondName: {
    value: '',
    isValid: true,
  },
  lastNameEn: {
    value: '',
    isValid: true,
  },
  firstNameEn: {
    value: '',
    isValid: true,
  },
  email: {
    value: '',
    isValid: true,
  },
  // page 2
  moderators: {
    value: [],
    isValid: true,
  },
  //page 3
  disscussants: {
    value: [],
    isValid: true,
  },
  //page 4
  members: {
    value: [],
    isValid: true,
  },
}

Object.keys(fields).forEach((key) => {
  fields[key].setValues = function (isValid, value = '') {
    this.value = value
    this.isValid = isValid
  }
})

export default fields