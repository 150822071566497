import { HseInput, HseSelect, HseCheckbox, HseTextArea } from "@hse-design/vue";
import { resourceLoader } from "../common/resourceLoader";
import { store } from "../../store/index";

const fields = {
  LName: {
    value: "",
    isValid: false,
    label: "fields.name.lName",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  FName: {
    value: "",
    isValid: false,
    label: "fields.name.fName",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  SName: {
    value: "",
    isValid: false,
    label: "fields.name.mName",
    isRequired: false,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  Email: {
    value: "",
    isValid: false,
    label: "fields.name.email",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  Position: {
    value: "",
    isValid: false,
    label: "fields.name.position",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  Phone: {
    value: "",
    isValid: false,
    label: "fields.name.phone",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  DepartmentIdRu: {
    value: "",
    isValid: false,
    isRequired: true,
    size: "large",
    label: "fields.request.departmentRu",
    component: HseSelect,
    searchable: "dropdown",
    getAllOptions(search) {
      return resourceLoader.load("departmentsList", null, [], search);
    },
    setDisabled(val) {
      this.disabled = val;
    },
  },
  StatusRT: {
    value: "47C97F65-67BC-4BF8-9AC1-1F2A042315E6",
    isValid: true,
    isRequired: true,
    options: [],
    default: "47C97F65-67BC-4BF8-9AC1-1F2A042315E6",
    setDisabled(val) {
      this.disabled = val;
    },
    getAllOptions() {
      return resourceLoader
        .load("get_rt_events_status", { lang: store.state.lang }, [])
        .then((res) => {
          this.options = res.map((opt) => {
            const label = opt.Name;
            if (label === "Научный") {
              return {
                label,
                value: opt.id,
                tooltip:
                  "Научный круглый стол– организованная дискуссия научного сообщества, в рамках которой осуществляется обсуждение вопросов по определенной научной тематике.",
              };
            } else {
              return {
                label,
                value: opt.id,
                tooltip:
                  "Экспертный круглый стол – организованная дискуссия представителей экспертного и научно-экспертного сообщества, нацеленная на обсуждение результатов проекта или проведение оценки той или иной проблемы развития экономики и общества.",
              };
            }
          });
        });
    },
  },
  ChiefFIO: {
    value: "",
    isValid: false,
    label: "fields.honoraryOrInvitedReport.coordinatingLeaderFio",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  ChiefPost: {
    value: "",
    isValid: false,
    label: "fields.name.position",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  ChiefDepartmenID: {
    value: "",
    isValid: false,
    isRequired: true,
    size: "large",
    label: "fields.request.departmentRu",
    component: HseSelect,
    searchable: "dropdown",
    getAllOptions(search) {
      return resourceLoader.load("departmentsList", null, [], search);
    },
    setDisabled(val) {
      this.disabled = val;
    },
  },
  ChiefEmail: {
    value: "",
    isValid: false,
    isRequired: true,
    size: "large",
    label: "fields.honoraryOrInvitedReport.email",
    rules: [
      {
        name: "email",
      },
    ],
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  plannedRTname: {
    value: "",
    isValid: false,
    label: "fields.roundTable.PlannedName",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  plannedRTnameEn: {
    value: "",
    isValid: false,
    label: "fields.roundTable.PlannedNameEn",
    isRequired: true,
    size: "large",
    component: HseInput,
    rules: [
      {
        name: "latin",
      },
    ],
    setDisabled(val) {
      this.disabled = val;
    },
  },
  RTname: {
    value: "",
    isValid: false,
    label: "fields.roundTable.RTname",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  RTnameEn: {
    value: "",
    isValid: false,
    label: "fields.roundTable.RTnameEn",
    isRequired: true,
    size: "large",
    component: HseInput,
    rules: [
      {
        name: "latin",
      },
    ],
    setDisabled(val) {
      this.disabled = val;
    },
  },
  responsibleFio: {
    value: "",
    isValid: false,
    label: "fields.roundTable.responsibleFio",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  plannedDiscussionQuestions: {
    value: "",
    isValid: false,
    label: "fields.roundTable.discussionQuestions",
    isRequired: true,
    size: "large",
    component: HseTextArea,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  plannedDdiscussionQuestionsEn: {
    value: "",
    isValid: false,
    label: "fields.roundTable.discussionQuestionsEn",
    isRequired: true,
    size: "large",
    component: HseTextArea,
    rules: [
      {
        name: "latin",
      },
    ],
    setDisabled(val) {
      this.disabled = val;
    },
  },
  discussionQuestions: {
    value: "",
    isValid: false,
    label: "fields.roundTable.discussionQuestions",
    isRequired: true,
    size: "large",
    component: HseTextArea,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  discussionQuestionsEn: {
    value: "",
    isValid: false,
    label: "fields.roundTable.discussionQuestionsEn",
    isRequired: true,
    size: "large",
    component: HseTextArea,
    rules: [
      {
        name: "latin",
      },
    ],
    setDisabled(val) {
      this.disabled = val;
    },
  },
  date: {
    value: "",
    isValid: false,
    label: "fields.roundTable.date",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  programSessionTimeId: {
    value: "",
    isValid: false,
    name: "programSessionTimeId",
    eventId: "",
    label: "fields.roundTable.programSessionTimeId",
    isRequired: true,
    size: "large",
    date: null,
    component: HseSelect,
    setDate(date) {
      this.date = date;
    },
    getAllOptions() {
      return resourceLoader
        .load("get_event_timeslots", { eventId: this.eventId }, [])
        .then((res) => {
          const date = this.date ? new Date(this.date).toISOString() : null;
          return res
            .filter((opt) => (this.date ? opt.Day === date : true))
            .map((opt) => {
              const label =
                store.state.lang === "ru"
                  ? `${opt.DayRU} ${opt.DurationRU}`
                  : `${opt.DayEN} ${opt.DurationEN}`;
              return { label, value: opt.Id };
            });
        });
    },
    setDisabled(val) {
      this.disabled = val;
    },
  },
  langRu: {
    value: false,
    isValid: false,
    hideTopLabel: true,
    label: "fields.roundTable.langRu",
    classes: { label: "smallLabel" },
    component: HseCheckbox,
    setDisabled(val) {
      this.inactive = val;
    },
  },
  langEn: {
    value: false,
    isValid: false,
    hideTopLabel: true,
    label: "fields.roundTable.langEn",
    classes: { label: "smallLabel" },
    component: HseCheckbox,
    setDisabled(val) {
      this.inactive = val;
    },
  },
  langOther: {
    value: false,
    isValid: true,
    hideTopLabel: true,
    label: "fields.roundTable.langOther",
    classes: { label: "smallLabel" },
    component: HseCheckbox,
    setDisabled(val) {
      this.inactive = val;
    },
  },
  other: {
    value: "",
    isValid: false,
    label: "fields.roundTable.langOther",
    size: "large",
    isRequired: true,
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  contactPerson: {
    value: "",
    isValid: false,
    label: "fields.roundTable.contactPerson",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  contactPersonEmail: {
    value: "",
    isValid: false,
    label: "fields.roundTable.contactPersonEmail",
    isRequired: true,
    size: "large",
    component: HseInput,
    rules: [
      {
        name: "email",
      },
    ],
    setDisabled(val) {
      this.disabled = val;
    },
  },
  comment: {
    value: "",
    isValid: false,
    label: "fields.roundTable.comment",
    isRequired: false,
    size: "large",
    component: HseTextArea,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  involvedBusinessOrg: {
    value: "",
    isValid: false,
    label: "fields.roundTable.involvedBusinessOrg",
    isRequired: true,
    size: "large",
    component: HseTextArea,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  involvedInfoOrg: {
    value: "",
    isValid: false,
    label: "fields.roundTable.involvedInfoOrg",
    isRequired: true,
    size: "large",
    component: HseTextArea,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  moderators: {
    value: [],
    isValid: true,
  },
  moderatorsDeleteIds: {
    value: [],
    isValid: true,
  },
  speakers: {
    value: [],
    isValid: true,
  },
  speakersDeleteIds: {
    value: [],
    isValid: true,
  },
  members: {
    value: [],
    isValid: true,
  },
  membersDeleteIds: {
    value: [],
    isValid: true,
  },

  //page 2

  syncTranslation: {
    value: 0,
    isValid: true,
    isRequired: true,
    label: "fields.roundTable.syncTranslationNew",
    default: 0,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  needOnlineSupport: {
    value: 0,
    isValid: true,
    isRequired: true,
    label: "fields.roundTable.needOnlineSupport",
    default: 0,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  needVolunteerSupport: {
    value: 0,
    isValid: true,
    isRequired: true,
    label: "fields.roundTable.needVolunteerSupport",
    default: 0,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  volunteerSupportBlocksId: {
    value: [],
    isValid: true,
    label: "fields.roundTable.volunteerSupportBlocksId",
    options: [],
    getAllOptions() {
      return resourceLoader
        .load("get_volunteers_blocks", null, [])
        .then((res) => {
          this.options = res.map((opt) => {
            const label = store.state.lang === "ru" ? opt.blocks : opt.blockEn;
            return { label, value: opt.id };
          });
        });
    },
    setDisabled(val) {
      this.disabled = val;
    },
  },
  needOfficialPhoto: {
    value: 0,
    isValid: true,
    isRequired: true,
    label: "fields.roundTable.needOfficialPhoto",
    default: 0,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  needOfficialVideo: {
    value: 0,
    isRequired: true,
    isValid: true,
    label: "fields.roundTable.needOfficialVideo",
    default: 0,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  publicityId: {
    value: "B9B5A48A-BB02-4065-BED7-8C8F6C7BD0F1",
    isRequired: true,
    isValid: true,
    options: [],
    label: "fields.roundTable.publicityId",
    default: "B9B5A48A-BB02-4065-BED7-8C8F6C7BD0F1",
    setDisabled(val) {
      this.disabled = val;
    },
    getAllOptions() {
      return resourceLoader.load("get_publicity", null, []).then((res) => {
        this.options = res.map((opt) => {
          const label =
            store.state.lang === "ru" ? opt.publicity : opt.publicittyEn;
          return { label, value: opt.id };
        });
      });
    },
  },
  massMediaId: {
    value: "",
    isValid: false,
    label: "fields.roundTable.massMediaId",
    isRequired: true,
    size: "large",
    component: HseSelect,
    setDisabled(val) {
      this.disabled = val;
    },
    getAllOptions() {
      return resourceLoader.load("get_mass_media", null, []).then((res) => {
        return res.map((opt) => {
          const label = store.state.lang === "ru" ? opt.value : opt.valueEn;
          return { label, value: opt.id };
        });
      });
    },
  },
  needOnlineTranslation: {
    value: 0,
    isValid: true,
    isRequired: true,
    label: "fields.roundTable.needOnlineTranslation",
    default: 0,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  plannedSpecialEventOrganizationFormatId: {
    value: "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A",
    isValid: true,
    isRequired: true,
    options: [],
    default: "592D7A01-E55F-4860-B403-1BB88FDE85A9",
    setDisabled(val) {
      this.disabled = val;
    },
    getAllOptions() {
      return resourceLoader.load("get_org_formats", null, []).then((res) => {
        this.options = res.map((opt) => {
          const label = store.state.lang === "ru" ? opt.Name : opt.NameEn;
          return { label, value: opt.Id };
        });
      });
    },
  },
  specialEventOrganizationFormatId: {
    value: "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A",
    isValid: true,
    options: [],
    default: "592D7A01-E55F-4860-B403-1BB88FDE85A9",
    setDisabled(val) {
      this.disabled = val;
    },
    getAllOptions() {
      return resourceLoader.load("get_org_formats", null, []).then((res) => {
        this.options = res.map((opt) => {
          const label = store.state.lang === "ru" ? opt.Name : opt.NameEn;
          return { label, value: opt.Id };
        });
      });
    },
  },
  maxParticipants: {
    value: "",
    isValid: false,
    label: "fields.roundTable.maxParticipants",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
    setRequired(val) {
      this.isRequired = val;
    },
    rules: [
      {
        name: "number",
      },
      {
        name: "maxParticipants",
      },
    ],
  },
  endRegistrationDate: {
    value: new Date().toLocaleDateString("ru-RU"),
    isValid: false,
    label: "fields.roundTable.endRegistrationDate",
    isRequired: true,
    size: "large",
    setDisabled(val) {
      this.disabled = val;
    },
    component: HseInput,
  },
  ResponsibleFIO: {
    value: "",
    isValid: false,
    label: "fields.roundTable.responsibleFio",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  ResponsiblePost: {
    value: "",
    isValid: false,
    label: "fields.honoraryOrInvitedReport.post",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  ResponsibleDepartmenID: {
    value: "",
    isValid: false,
    isRequired: true,
    size: "large",
    label: "fields.honoraryOrInvitedReport.division",
    component: HseSelect,
    searchable: "dropdown",
    getAllOptions(search) {
      return resourceLoader.load("departmentsList", null, [], search);
    },
    setDisabled(val) {
      this.disabled = val;
    },
  },
  ResponsibleEmail: {
    value: "",
    isValid: false,
    label: "fields.honoraryOrInvitedReport.email",
    isRequired: true,
    size: "large",
    component: HseInput,
    rules: [
      {
        name: "email",
      },
    ],
    setDisabled(val) {
      this.disabled = val;
    },
  },
  ResponsiblePhone: {
    value: "",
    isValid: false,
    label: "fields.name.phone",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  NumberParticipants: {
    value: "",
    isValid: false,
    label: "fields.name.numberParticipants",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  secretaryOnline: {
    value: "",
    isValid: false,
    isRequired: true,
    name: "secretary",
    size: "large",
    label: "fields.honoraryOrInvitedReport.secretaryOnline",
    component: HseSelect,
    searchable: "dropdown",
    options: [],
    event: "",
    setOptions(data) {
      this.options = data;
    },
    setEvent(eventId) {
      console.log(eventId, "eventId");
      this.event = eventId;
    },
    getAllOptions() {
      console.log(this.event, "test");
      const listSecretary = resourceLoader
        .load("get_secretary_list", Math.random())
        .then((res) => {
          console.log(res, "res");
          let newRes = res.filter((item) => this.event === item.EventID);
          console.log(newRes, "newRes");
          return newRes
            .map((item) => {
              return {
                label:
                  item.LastName + " " + item.FirstName + " " + item.MiddleName,
                value: item.ID,
              };
            })
            .sort((a, b) =>
              a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
            );
        });
      return listSecretary;
    },
  },
  secretaryOffline: {
    value: "",
    isValid: false,
    isRequired: true,
    size: "large",
    label: "fields.honoraryOrInvitedReport.secretaryOffline",
    name: "secretary",
    component: HseSelect,
    searchable: "dropdown",
    options: [],
    event: "",
    setEvent(eventId) {
      console.log(eventId, "eventId");
      this.event = eventId;
    },
    setOptions(data) {
      this.options = data;
    },
    getAllOptions() {
      console.log(this.event, "test");
      const listSecretary = resourceLoader
        .load("get_secretary_list", Math.random())
        .then((res) => {
          console.log(res, "res");
          let newRes = res.filter((item) => this.event === item.EventID);
          console.log(newRes, "newRes");
          return newRes
            .map((item) => {
              return {
                label:
                  item.LastName + " " + item.FirstName + " " + item.MiddleName,
                value: item.ID,
              };
            })
            .sort((a, b) =>
              a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
            );
        });
      return listSecretary;
    },
  },
};

Object.keys(fields).forEach((key) => {
  fields[key].setValues = function (isValid, value = "") {
    this.value = value;
    this.isValid = isValid;
  };
});

export default fields;
