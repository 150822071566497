<template>
  <div class="profileMenu" ref="dropdownProfileRef">
    <div class="select-container" v-on:click="toggle">
      <div class="select" :class="{ active: isActive }">
        <HseIconButton
          size="medium"
          variant="ghost"
          :icon="currentItem.icon"
        />
      </div>
    </div>
    <HseDropdownMenu
      :class="{ show: isMenuOpen }"
      class="profile-menu"
    >
      <div v-if="userName" class="username">{{ userName }}</div>
      <router-link to="/profile" custom v-slot="{ isActive: active }">
        <HseDropdownMenuItem
          :leftIcon="items[0].icon"
          class="menu-item"
          :class="{ active }"
          @click.native="() => moveTo(items[0].id)"
        >
          {{ items[0].name }}
        </HseDropdownMenuItem>
      </router-link>
      <HseDropdownMenuItem
        :leftIcon="items[1].icon"
        class="menu-item"
        @click.native="() => moveTo(items[1].id)"
      >
        {{ items[1].name }}
      </HseDropdownMenuItem>
    </HseDropdownMenu>
  </div>
</template>

<script>
  import { api } from '../../../api'
  import {
    HseIconButton,
    HseDropdownMenu,
    HseDropdownMenuItem,
    IconNavigationEntrance,
    IconGlobalUserFilled,
  } from '@hse-design/vue'

  export default {
    name: 'UserDropdown',
    data: () => ({
      isMenuOpen: false,
    }),
    props: ['isActive'],
    computed: {
        items () {
          return [
            {
              id: 1,
              icon: IconGlobalUserFilled,
              name: this.$i18n.t('profile')
            },
            {
              id: 2,
              icon: IconNavigationEntrance,
              name: this.$i18n.t('logout')
            },
          ];
        },
        currentItem () {
          return this.items[0]
        },
        userName () {
          const { user } = this.$store.state
          if (!user) return ''

          const userData = user.data.public
          if (this.$store.state.lang === 'en') return `${userData.LNameEN} ${userData.FNameEN}`
          return `${userData.LName} ${userData.FName}`
        },
      },
    components: {
      HseDropdownMenu,
      HseDropdownMenuItem,
      HseIconButton,
    },
    methods: {
      toggle () {
        this.isMenuOpen = !this.isMenuOpen
        if (this.isMenuOpen) {
          addEventListener('click', this.listener, false)
        }
      },
      listener (e) {
        if (!this.$refs.dropdownProfileRef) return

        const contains = this.$refs.dropdownProfileRef.contains(e.target)
        if (!contains) {
          this.isMenuOpen = false
          removeEventListener('click', this.listener, false)
        }
      },
      moveTo (id) {
        this.isMenuOpen = !this.isMenuOpen
        switch (id) {
          case 1:
            this.$router.push('/profile')
            break;
          case 2:
            api.logout()
            break;
        }
      },
    },
    i18n: {
      messages: {
        en: {
          profile: 'Profile',
          logout: 'Logout',
        },
        ru: {
          profile: 'Профиль',
          logout: 'Выйти',
        },
      }
    }
  }
</script>

<style scoped>
  .profileMenu {
    position: relative;
  }
  .profile-menu {
    position: absolute;
    margin-right: 0px;
    align-items: center;
    display: none;
    right: 0;
    top: calc(100% + 4px);
  }

  .show {
    display: flex;
  }

  .select-container {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    min-width: 100%;
    cursor: pointer;
    color: #6b7a99;
  }

  .select {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    flex-grow: 1;
    margin-right: 1px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .username {
    white-space: nowrap;
    text-align: center;
    padding: 0 0 8px;
  }
</style>
<style>
  .profileMenu .hse-DropdownMenuBaseItem__content {
    white-space: nowrap;
  }
  .profileMenu .select svg {
    width: 18px;
    height: 18px;
  }
  .profileMenu .select svg path,
  .profileMenu .menu-item svg path {
    fill: #6b7a99;
  }
  .profileMenu .menu-item {
    color: #6b7a99;
  }
  .profileMenu .menu-item.active {
    color: #0050cf;
  }
  .profileMenu .select.active svg path,
  .profileMenu .menu-item.active svg path {
    fill: #0050cf;
  }
</style>