<template>
  <div>
    <div class="tab-top" />
    <section class="days">
      <section
        class="event-day"
        v-for="(date, idx) in plenaryData"
        :key="date.Date"
      >
        <section class="date">
          <h2 class="day">
            {{ $t("day") }} <span class="day__number">{{ idx + 1 }}</span>
          </h2>
          <p v-if="datesMap.has(date.Date)">
            {{
              $i18n.locale === "ru"
                ? datesMap.get(date.Date).DayRU
                : datesMap.get(date.Date).DayEN
            }}
          </p>
          <p v-else>{{ parseDate(date.Date, $i18n.locale) }}</p>
        </section>
        <section class="sessions">
          <div class="sessions__space" />
          <div class="sessions__items">
            <article
              class="session"
              v-for="session in date.Sessions"
              :key="session.Id"
            >
              <section class="session__info">
                <p>{{ session.StartTime }} - {{ session.EndTime }} (UTC +3)</p>
                <p>{{ session.Format }}</p>
                <section class="info__translation">
                  <div class="translation__languages">
                    <p v-if="session.LangRu">RUS</p>
                    <p v-if="session.LangEn">ENG</p>
                    <IconHeadphones v-if="session.IsTranslation" />
                  </div>
                  <!-- <p v-if="session.IsTranslation">{{ $t("translation") }}</p> -->
                  <a
                    class="link"
                    v-if="session.Registration"
                    :href="session.Registration"
                    >{{ $t("registation") }}</a
                  >
                  <a
                    class="link"
                    v-if="session.Accreditation"
                    :href="session.Accreditation"
                    >{{ $t("accreditation") }}</a
                  >
                </section>
              </section>
              <PlenarySession :session="session" />
            </article>
          </div>
        </section>
      </section>
    </section>
  </div>
</template>

<script>
import PlenarySession from "./sessions/PlenarySession";
import IconHeadphones from "./IconHeadphones.vue";

export default {
  props: {
    plenaryData: Array,
    datesMap: Map,
  },
  components: {
    PlenarySession,
    IconHeadphones,
  },
  methods: {
    parseDate(dateRaw, lang) {
      const date = new Date(dateRaw);

      return lang === "ru"
        ? `${date.getDate()} ${this.$t(`months.${date.getMonth()}`)}, ${this.$t(
            `daysOfWeek.${date.getDay()}`
          )}`
        : `${this.$t(`daysOfWeek.${date.getDay()}`)}, ${this.$t(
            `months.${date.getMonth()}`
          )} ${date.getDate()}`;
    },
  },
  i18n: {
    messages: {
      en: {
        day: "Day",
        format: "Format",
        registation: "Registration",
        accreditation: "Media accreditation",
        translation: "Simultaneous translation",
        daysOfWeek: {
          1: "Monday",
          2: "Tuesday",
          3: "Wednesday",
          4: "Thursday",
          5: "Friday",
          6: "Saturday",
          0: "Sunday",
        },
        months: {
          0: "January",
          1: "February",
          2: "March",
          3: "April",
          4: "May",
          5: "June",
          6: "July",
          7: "August",
          8: "September",
          9: "October",
          10: "November",
          11: "December",
        },
      },
      ru: {
        day: "День",
        format: "Формат",
        registation: "Регистрация",
        accreditation: "Аккредитация для СМИ",
        translation: "Синхронный перевод",
        daysOfWeek: {
          1: "Понедельник",
          2: "Вторник",
          3: "Среда",
          4: "Четверг",
          5: "Пятница",
          6: "Суббота",
          0: "Воскресение",
        },
        months: {
          0: "Января",
          1: "Февраля",
          2: "Марта",
          3: "Апреля",
          4: "Мая",
          5: "Июня",
          6: "Июля",
          7: "Августа",
          8: "Сентября",
          9: "Октября",
          10: "Ноября",
          11: "Декабря",
        },
      },
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  --day-color: #f37421;
  --background-color: #fff5c0;
}

.tab-top {
  height: 2em;
  width: 100%;
  border-radius: 0 0 10px 10px;
  background-color: white;
}

.days {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em 0;
}

.event-day {
  background-color: white;
  padding: 1em;
  border-radius: 10px;
}

.date {
  margin-top: 5px;

  display: flex;
  gap: 0.5em;
}

.date > p {
  padding: 0.95em 0.56em;

  background-color: var(--background-color);
  width: 100%;

  text-transform: uppercase;
  font-size: 1em;
}

.day {
  position: relative;
  top: -5px;
  left: -5px;

  padding: 10px 5px 0;
  width: 55px;
  min-height: 76px;

  font-size: 0.8125em;
  font-weight: 400;
  line-height: initial;

  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  color: #fff;
  background-color: var(--day-color);
}

.day > span {
  font-size: 2.8em;
  line-height: 1;
}

.day::before {
  content: "";
  position: absolute;
  top: 5px;
  right: -5px;
  width: 5px;
  height: 100%;
  background-color: var(--background-color);
}

.day::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 5px;
  width: 55px;
  height: 5px;
  background-color: var(--background-color);
}

.sessions {
  padding-top: 1em;
  display: flex;
}

.sessions__items {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1em;
}

.session {
  display: grid;
  grid-template-columns: 1fr;
}

.session__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5em;

  color: #999;
  font-size: 0.8125em;
}

.info__time {
  display: flex;
  gap: 0.5em;
}

.time__text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5em;
}

.info__translation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5em;
}

.translation__languages {
  display: flex;
  gap: 0.5em;
}

.translation__languages p {
  color: black;
  background-color: var(--background-color);
  font-size: 10px;
  line-height: 10px;
  padding: 10px 5px;
  border-radius: 4px;
  font-weight: 600;
}

@media (min-width: 1024px) {
  .sessions {
    gap: calc(55px + 0.5em);
  }

  .session {
    grid-template-columns: 0.25fr 1fr;
    gap: 2em;
  }
}
</style>
