<template>
    <div class="modal">
        <svg v-if="!isFormatEdit" width="110" height="100" viewBox="0 0 110 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M26.8055 8.42652C35.0279 2.93245 44.6949 0 54.584 0C67.8448 0 80.5625 5.26784 89.9393 14.6447C99.3161 24.0215 104.584 36.7392 104.584 50C104.584 59.8891 101.652 69.556 96.1575 77.7785C90.6634 86.0009 82.8545 92.4096 73.7182 96.194C64.5819 99.9783 54.5285 100.969 44.8295 99.0392C35.1304 97.11 26.2213 92.3479 19.2287 85.3553C12.236 78.3627 7.474 69.4536 5.54474 59.7545C3.61548 50.0555 4.60565 40.0021 8.39003 30.8658C12.1744 21.7295 18.583 13.9206 26.8055 8.42652Z" fill="#FF564E"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M73.4702 35.2504L69.7498 31.53L55.0002 46.2796L40.2507 31.53L36.5303 35.2504L51.2799 50L36.5303 64.7496L40.2507 68.47L55.0002 53.7204L69.7498 68.47L73.4702 64.7496L58.7206 50L73.4702 35.2504Z" fill="white"/>
        </svg>
        <HseSpace size='small' vertical />
        <HseHeading :style="{textAlign: 'center'}" size="h3">{{ $t(isFormatEdit ? 'headings.titleFormat' : 'headings.title') }}</HseHeading>
        <HseSpace size='small' vertical />
        <div class="buttonBlock">
            <HseButton
                v-on:click="$emit('closeWarningModal')"
            >
                <span>{{ $t('ok') }}</span>
            </HseButton>
        </div>
    </div>
</template>
<script>
    import { HseHeading, HseSpace, HseButton } from '@hse-design/vue'
    export default {
        components: {
            HseHeading,
            HseSpace,
            HseButton,
        },
        props: ['isFormatEdit'],
        i18n: {
            messages: {
                en: {
                    headings: {
                        title: 'You need to add moderators and expert speakers',
                        titleFormat: 'The format of the event has been changed, check the formats of the participants',
                    },
                    ok: 'Ok',
                },
                ru: {
                    headings: {
                        title: 'Необходимо добавить модераторов и экспертов-спикеров',
                        titleFormat: 'Формат мероприятия изменён, проверьте форматы участников',
                    },
                    ok: 'Хорошо',
                },
            }
        }
    }
</script>
<style>
    .modal {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .buttonBlock {
        display: flex;
        justify-content: space-between;
        width: fit-content;
    }
    .buttonBlock > button:first-child {
        margin-right: 20px;
    }
</style>