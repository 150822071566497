<template>
  <HseModalForm v-model="isOpen">
    <HseHeading size="h3">{{ $t('header') }}</HseHeading>
    <HseParagraph>{{ $t('desc', { login }) }}</HseParagraph>

    <form @submit="submit">
      <Validator v-bind:field="codeField" v-on:change="onCodeChange" />
      <HseSpace size="small" vertical />
    </form>

    <template #actions>
      <HseButton :disabled="!code.isValid" @click="submit">{{
          $t('continue')
      }}</HseButton>
    </template>

    <HseLink :inactive="resendTimer > 0" @click="onResend" variant="secondary">{{ $t('resendCode', { resendIn }) }}
    </HseLink>

  </HseModalForm>
</template>

<script>
import { HseModalForm, HseButton, HseHeading, HseParagraph, HseInput, HseSpace, HseLink } from '@hse-design/vue'
import { mapState } from 'vuex'
import Validator from '../form/Validator'
import { api } from '../../../api'
import fields from "@/components/common/auth/codeFields";

export default {
  components: {
    HseModalForm,
    HseButton,
    HseHeading,
    HseParagraph,
    HseSpace,
    HseLink,
    Validator,
  },
  data: () => ({
    isOpen: true,
    // login: '',
    resendTimer: 0,
    code: {
        fields,
      value: '',
      isValid: false,
    },
  }),
    props: {
      login: String,
    },
  created() {
    this.$root.$refs.codeModal = this
  },
  methods: {
    //    // open() {
    //    //    this.isOpen = false;
    //    //  if (this.$store.state.error && this.$store.state.error.code === 7) {
    //    //     // if (!this.isFormValid()) return
    //    //      this.isOpen = false;
    //    //      // if (!this.code.isValid) return
    //    //      return
    //    //  } else {
    //    //      this.isOpen = true
    //    //      //this.login = login
    //    //  }
    // },
    onCodeChange(nextValue) {
      if (this.$store.state.error && ['The code has expired', 'Incorrect code'].includes(this.$store.state.error.text)) {
        this.$store.state.error.text = null
        this.$root.$refs.validator.validationInModal()
        this.code.value = nextValue
      }
    },
    async submit(e) {
      e?.preventDefault()

      api.loginByCode(this.login, this.code.value).then(() => {
        this.$root.$refs.validator.validationInModal()
        if (!this.code.isValid) return
        this.isOpen = false
        this.$root.$refs.roleModal.open();
      })
    },
    initResendTimer() {
      if (!this.resendTimer) {
        this.resendTimer = 5 * 60
        api.sendCode(this.login)
      }
    },
    onResend() {
      if (!this.resendTimer) {
        this.initResendTimer()
      }
    },
  },
  computed: {
    ...mapState(['lang']),
    codeField() {
      return {
        value: this.code.value,
        isValid: this.code.isValid,
        label: this.$i18n.t('code'),
        isRequired: true,
        size: 'large',
        component: HseInput,
        placeholder: this.$i18n.t('enterCode'),
        rules: [{
          name: 'custom',
          message: this.$i18n.t('expiredCode'),
          validateVal: () => {
            const error = this.$store.state.error && this.$store.state.error.text === 'The code has expired'
            return !error
          },
        },
        {
          name: 'custom',
          message: this.$i18n.t('notValidCode'),
          validateVal: () => {
            const error = this.$store.state.error && this.$store.state.error.text === 'Incorrect code'
            return !error
          },
        }],
        setValues: (isValid, value = '') => {
          this.code.value = value
          this.code.isValid = isValid
        }
      }
    },
    resendIn() {
      if (this.resendTimer) {
        let minutes = Math.floor(this.resendTimer / 60)
        minutes = minutes >= 10 ? minutes : '0' + minutes

        let seconds = this.resendTimer - minutes * 60
        seconds = seconds >= 10 ? seconds : '0' + seconds

        return (this.lang === 'ru' ? 'через' : 'in') + ` ${minutes}:${seconds}`
      } else {
        return null
      }
    }
  },
  watch: {
    isOpen() {
      if (this.isOpen) {
        this.initResendTimer()
      }
    },
    resendTimer() {
      if (this.resendTimer) {
        setTimeout(() => {
          this.resendTimer--
        }, 1000)
      }
    }
  },
  i18n: {
    messages: {
      en: {
        header: 'The code was sent',
        desc: 'Please use the code, which we sent to {login}, within 10 minutes.',
        code: 'Code',
        enterCode: 'Input code',
        continue: 'Continue',
        resendCode: 'Still haven\'t received the code? Try again {resendIn}',
        notValidCode: 'Invalid code',
        expiredCode: 'The code has expired',
      },
      ru: {
        header: 'Код отправлен',
        desc: 'Введите код, который мы отправили на почту {login}, в течение 10 минут.',
        code: 'Код',
        enterCode: 'Введите код',
        continue: 'Продолжить',
        resendCode: 'Не получили код? Отправить повторно {resendIn}',
        notValidCode: 'Неверный код',
        expiredCode: 'Срок действия кода истек',
      },
    }
  }
}
</script>