import { HseInput, HseSelect, HseCheckbox, } from '@hse-design/vue'
import { api } from '../../api'
import { resourceLoader } from '../../components/common/resourceLoader'
import { store } from '../../store/index'

const fields = {
  email: {
    value: '',
    isValid: false,
    isRequired: true,
    label: 'fields.name.email',
    size: "large",
    component: HseInput,
    rules: [{
      name: 'email',
    }],
  },
  LName: {
    value: '',
    isValid: false,
    label: 'fields.name.lName',
    isRequired: true,
    size: 'large',
    component: HseInput,
  },
  FName: {
    value: '',
    isValid: false,
    label: 'fields.name.fName',
    isRequired: true,
    size: 'large',
    component: HseInput,
  },
  SName: {
    value: '',
    isValid: true,
    label: 'fields.name.mName',
    size: 'large',
    component: HseInput,
  },
  CompanyNameShort: {
    value: '',
    isValid: false,
    isRequired: true,
    label: 'fields.name.companyShort',
    size: 'large',
    component: HseInput,
  },
  languageId: {
    value: '',
    isValid: false,
    label: 'fields.name.interfaceLanguage',
    size: "large",
    component: HseSelect,
    isRequired: true,
    myLoad(search) {
      let ret = api.request('languagesList', {lang: store.state.lang}).then(data => {
        const normalized = Promise.resolve(resourceLoader.normalizeData('languagesListLang', data))
        ret = normalized
        return normalized
      })
      return ret.then(data => resourceLoader.search(data, search))
    },
    getAllOptions (search) {
      return this.myLoad(search);
    },
  },
  event: {
    value: '',
    isValid: false,
    isRequired: true,
    label: "fields.name.event",
    size: "large",
    component: HseSelect,
    searchable: 'dropdown',
    getAllOptions (search) {
      return resourceLoader.load('manager.eventList', {  }, [], search)
    },
  },
  Topic: {
    value: '',
    isValid: false,
    isRequired: true,
    label: "fields.name.expert_topic1",
    size: "large",
    component: HseSelect,
    eventId: '',
    setEvent (event) { this.value=''; this.eventId = event; },
    getAllOptions (search) {
      const result = this.eventId ? resourceLoader.load('manager.eventTopics', {event_id: this.eventId}, [], search) : Promise.resolve([])
      result.then(response => {
        if (response.length === 1) {
          this.value = response[0].value
          this.disabled = true
        } else {
          this.disabled = false
        }
      })
      return result
    },
  },
  Topic2: {
    value: '',
    isValid: false,
    label: "fields.name.expert_topic2",
    size: "large",
    component: HseSelect,
    eventId: '',
    setEvent (event) { this.value=''; this.eventId = event; },
    myLoad(search) {
      let ret = api.request('eventTopicsList', {event_id: this.eventId}).then(data => {
        const normalized = Promise.resolve(resourceLoader.normalizeData('eventTopicsList', data))
        ret = normalized
        return normalized
      })
      return ret.then(data => resourceLoader.search(data, search))
    },
    getAllOptions (search) {
      return this.myLoad(search);
    },
  },
  Topic3: {
    value: '',
    isValid: false,
    label: "fields.name.expert_topic3",
    size: "large",
    component: HseSelect,
    eventId: '',
    setEvent (event) { this.value=''; this.eventId = event; },
    myLoad(search) {
      let ret = api.request('eventTopicsList', {event_id: this.eventId}).then(data => {
        const normalized = Promise.resolve(resourceLoader.normalizeData('eventTopicsList', data))
        ret = normalized
        return normalized
      })
      return ret.then(data => resourceLoader.search(data, search))
    },
    getAllOptions (search) {
      return this.myLoad(search);
    },
  },
  Agreed: {
    value: false,
    isValid: true,
    hideTopLabel: true,
    label: 'fields.manager.expert_afreed',
    classes: { label: 'smallLabel' },
    component: HseCheckbox,
  },

}

Object.keys(fields).forEach((key) => {
  fields[key].setValues = function (isValid, value = '') {
    this.value = value
    this.isValid = isValid
  }
})

export default fields