<template>
  <div class="event-card">
    <Loading v-if="!isDataLoaded" />
    <div v-else class="card">
      <HseHeading size="h3">{{ $t('header') }}</HseHeading>
      <HseSpace size='small' vertical />

      <div v-if="lang == 'ru'">
        <ReadonlyInput :label="$t('fields.LName')" :value="data.user_data.public.LName" />
        <div class="cols-2">
          <ReadonlyInput v-if="lang == 'ru'" :label="$t('fields.FName')" :value="data.user_data.public.FName" />
          <ReadonlyInput v-if="lang == 'ru'" :label="$t('fields.SName')" :value="data.user_data.public.SName" />
        </div>
      </div>
      <div v-else>
        <ReadonlyInput :label="$t('fields.FName')" :value="data.user_data.public.FNameEN" />
        <ReadonlyInput :label="$t('fields.LName')" :value="data.user_data.public.LNameEN" />
      </div>





      <ReadonlyInput label="E-mail" :value="data.login" />
      <ReadonlyInput :label="$t('fields.name.companyShort')" :value="data.CompanyNameShort" />
      <ReadonlyInput v-show="visible" :label="$t('fields.name.companyFull')" :value="data.CompanyNameFull" />
      <ReadonlyInput :label="$t('fields.Event')" :value="data.Title" />

      <Validator v-bind:field="fields.Topic" />
      <HseSpace size='small_2x' vertical />

      <div v-if="data.Topic2Title">
        <ReadonlyInput :label="$t('fields.Topic2Title')" :value="data.Topic2Title" />
        <HseSpace size='small_2x' vertical />
      </div>

      <div v-if="data.Topic3Title">
        <ReadonlyInput :label="$t('fields.Topic3Title')" :value="data.Topic3Title" />
        <HseSpace size='small_2x' vertical />
      </div>

      <HseSelect v-if="lang === 'ru'" width="full" v-model="currentStatus" :options="statusOptions" size="large" />
      <HseSelect v-if="lang === 'en'" width="full" v-model="currentStatus" :options="statusOptionsEn" size="large" />

      <HseSpace size='small' vertical />
      <div class="two-cols">
        <a class="link" :href="`/#/experts_list`">{{ $t('back') }}</a>
        <div class="prepull">
          <HseButton class="pull-right" size="medium" v-on:click="save()">{{ $t('save') }}</HseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { api } from '../../api'
import Loading from '../common/Loading.vue'
import { HseHeading, HseSpace, HseButton, HseSelect } from '@hse-design/vue'
import Validator from '../common/form/Validator.vue'
import fields from './expertFields'
import ReadonlyInput from '../common/ReadonlyInput.vue'
import { mapState } from 'vuex'
import { localizeFields } from '../../i18n'

export default {
  components: {
    HseButton,
    HseHeading,
    HseSpace,
    HseSelect,
    Validator,
    Loading,
    ReadonlyInput,
  },
  data: () => ({
    visible: false,
    expertId: null,
    eventId: null,
    isDataLoaded: false,
    fields,
    data: null,
    currentStatus: '',
    statusOptions: Object.freeze([
      { label: 'Отказ эксперта', value: '-1', },
      { label: 'Приглашение отправлено', value: '0', },
      { label: 'Приглашение принято', value: '1', },
    ]),
    statusOptionsEn: Object.freeze([
      { label: 'Declined', value: '-1', },
      { label: 'Not confirmed', value: '0', },
      { label: 'Confirmed', value: '1', },
    ]),
  }),
  created() {
    this.translateFields()
    this.expertId = this.$route.params.id
    this.eventId = this.$route.params.event
    this.fields.Topic.eventId = this.eventId;
    this.fields.Topic2.eventId = null;//this.eventId;
    this.fields.Topic3.eventId = null;//this.eventId;
    this.requestEventData()
  },
  methods: {
    requestEventData() {
      api.request('expert.get', { expert_id: this.expertId, event_id: this.eventId }).then(data => {
        this.data = data;
        this.fields.Topic.value = data.TopicId;
        this.fields.Topic2.value = data.TopicId2;
        this.fields.Topic3.value = data.TopicId3;
        this.currentStatus = data.Status.toString();
        this.isDataLoaded = true
      })
    },
    save() {
      api.request('expert.update', {
        expert_id: this.expertId,
        event_id: this.eventId,
        topic: this.fields.Topic.value,
        topic2: this.fields.Topic2.value,
        topic3: this.fields.Topic3.value,
        status: this.currentStatus,
      }).then(() => {
        this.$router.push('/experts_list');
      })
    },
    translateFields() {
      this.fields = localizeFields(fields, this.fields)
    }
  },
  computed: {
    job() {
      return this.data.user_data && this.data.user_data.public && this.data.user_data.public.CompanyNameShort
        ? this.data.user_data.public.CompanyNameShort
        : ''
    },
    ...mapState(['lang']),
  },
  watch: {
    lang() {
      this.translateFields()
    }
  },
  i18n: {
    messages: {
      en: {
        fields: {
          LName: 'Last name',
          FName: 'First name',
          SName: 'Middle name',
          Event: 'Event',
          Topic2Title: "Additional topic 1",
          Topic3Title: "Additional topic 2",
        },
        header: 'Expert',
        back: 'To experts list',
        save: 'Save',
      },
      ru: {
        fields: {
          LName: 'Фамилия',
          FName: 'Имя',
          SName: 'Отчество',
          Event: 'Мероприятие',
          Topic2Title: "Доп. направление 1",
          Topic3Title: "Доп. направление 2",
        },
        header: 'Эксперт',
        back: 'К экспертам',
        save: 'Сохранить',
      },
    }
  }
}
</script>

<style scoped>
.event-card {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 32px;
  max-width: 800px;
  margin: 52px auto;
  width: calc(100vw - 320px);
}

@media (max-width: 767px) {
  .event-card {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }

  /* .cols-2 {
    display: block;
  } */
}


.big-item {
  max-width: 780px;
}

.link {
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.cols-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
}

.prepull {
  overflow: auto;
}

.pull-right {
  float: right;
}
</style>