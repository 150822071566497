<template>
  <div>
    <div class="cols two-cols">
      <!-- Название доклада (рус) -->
      <div class="field">
        <div class="flex-tooltip">
          <Validator
            :field="fields.TitleRU"
            :defaultValue="fields.SpeakerPosition.value"
          />
          <HseTooltip :content="$t('titleRUHseTooltip')">
            <IconStatusInfo />
          </HseTooltip>
        </div>
        <HseSpace size="small_2x" vertical />
      </div>
      <!-- Название доклада (eng) -->
      <div class="field">
        <div class="flex-tooltip">
          <Validator
            :field="fields.TitleEN"
            :defaultValue="fields.SpeakerPositionEn.value"
          />
          <HseTooltip :content="$t('titleRUHseTooltip')">
            <IconStatusInfo />
          </HseTooltip>
        </div>
        <HseSpace size="small_2x" vertical />
      </div>
    </div>
    <!-- Текст аннотации (рус) -->
    <div class="field">
      <div class="flex-tooltip-area">
        <Validator
          :field="fields.AnnotationTextRU"
          :defaultValue="fields.SpeakerAffiliation.value"
        />
        <HseSpace size="small_2x" vertical />
        <HseTooltip :content="$t('AnnotationTextHseTooltip')">
          <IconStatusInfo />
        </HseTooltip>
      </div>
    </div>
    <!-- Текст аннотации (eng) -->
    <div class="field">
      <div class="flex-tooltip-area">
        <Validator
          :field="fields.AnnotationTextEN"
          :defaultValue="fields.SpeakerAffiliationEn.value"
        />
        <HseTooltip :content="$t('AnnotationTextHseTooltip')">
          <IconStatusInfo />
        </HseTooltip>
      </div>
    </div>
    <!-- Фото -->
    <div class="field fileUploader_item">
      <HseSpace size="small" vertical />
      <FileUploader :field="fields.imageFileID" />
    </div>

    <!-- Копия паспорта докладчика * -->
    <div class="cols" v-show="IsRequiredPassportAll">
      <div class="field fileUploader_item">
        <HseSpace size="small" vertical />
        <FileUploader :field="fields.SpeakerPassport" />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>

    <div class="field" v-show="IsRequiredPassportAll">
      <Validator v-bind:field="fields.isAgree" />
      <span class="link" v-on:mouseup="() => (open = true)">{{
        $t("pd")
      }}</span>
      <HseSpace size="small_2x" vertical />
      <HseSpace size="small_2x" vertical />
    </div>

    <div class="headingWithTooltip">
      <div class="subheading">{{ $t("responsibleEvent") }}</div>
      <HseTooltip :content="$t('responsibleEventTooltip')">
        <IconStatusInfo />
      </HseTooltip>
    </div>
    <HseSpace size="small" vertical />
    <!-- ФИО -->
    <div class="field">
      <Validator :field="fields.ResponsibleFIO" />
      <HseSpace size="small_2x" vertical />
    </div>
    <div class="cols two-cols">
      <!-- Должность -->
      <div class="field">
        <Validator :field="fields.ResponsiblePost" />
        <HseSpace size="small_2x" vertical />
      </div>
      <!-- Подразделение -->
      <div class="field longDMItem">
        <Validator :field="fields.ResponsibleDepartmenID" />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>
    <div class="cols two-cols">
      <!-- Емаил -->
      <div class="field">
        <Validator :field="fields.ResponsibleEmail" />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>
    <div class="cols two-cols">
      <!-- Предпочитаемая дата презентации доклада -->
      <div class="field">
        <!-- вернуть change   @change="updateTimestamps"-->
        <Datepicker
          :errorText="
            +new Date(
              this.fields.endRegistrationDate.value
                .split('.')
                .reverse()
                .join('-')
            ) +
              new Date().getTimezoneOffset() * 60 * 1000 >
            +new Date(this.fields.date.value.split('.').reverse().join('-'))
              ? 'fields.rule.regError'
              : ''
          "
          v-bind:field="fields.date"
          @change="updateTimestamps"
        />
        <HseSpace size="small_2x" vertical />
      </div>
      <!-- Предпочитаемое время презентации доклада -->
      <div class="field">
        <Validator :field="fields.programSessionTimeId" />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>
    <!-- Формат мероприятия -->
    <RadioGroup
      :label="$t('eventFormat')"
      :options="fields.specialEventOrganizationFormatIdForm2.options"
      @change="setFormat"
      :disabled="fields.specialEventOrganizationFormatIdForm2.disabled"
      :defaultValue="fields.specialEventOrganizationFormatIdForm2.value"
    />
    <HseSpace size="small_2x" horizontal />
    <div class="cols">
      <!-- Публичность -->
      <div class="field">
        <RadioGroup
          :options="fields.publicityId.options"
          :label="fields.publicityId.label"
          @change="setPublicityId"
          :defaultValue="fields.publicityId.value"
          :disabled="fields.publicityId.disabled"
        />
      </div>
    </div>
    <span class="hse-RichInput__label">{{ $t("chooseLang") }}</span>
    <HseSpace size="small_2x" vertical />
    <div class="cols three-cols">
      <!-- Выберите язык -->
      <div class="field">
        <Validator :field="fields.langRu" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field">
        <Validator :field="fields.langEn" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field">
        <Validator :field="fields.langOther" />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>

    <div class="cols" v-show="fields.langOther.value">
      <div class="field">
        <Validator :field="fields.other" />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>

    <div class="headingWithTooltip">
      <div class="subheading">{{ $t("memberBlock") }}</div>
      <HseTooltip :content="$t('secretaryTooltip')">
        <IconStatusInfo />
      </HseTooltip>
    </div>
    <HseSpace size="small" vertical />

    <div class="cols two-cols">
      <!-- Секретарь -->
      <div class="field longDMItem">
        <Validator
          :field="fields.secretaryOnline"
          v-show="
            fields.specialEventOrganizationFormatIdForm2.value ===
              '1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A' ||
            fields.specialEventOrganizationFormatIdForm2.value ===
              '895BB6AB-A343-48DC-AA19-7EAB348C8C0D'
          "
        />
        <Validator
          :field="fields.secretaryOffline"
          v-show="
            fields.specialEventOrganizationFormatIdForm2.value ===
              '592D7A01-E55F-4860-B403-1BB88FDE85A9' ||
            fields.specialEventOrganizationFormatIdForm2.value ===
              '895BB6AB-A343-48DC-AA19-7EAB348C8C0D'
          "
        />
      </div>
      <div class="field addButtonSecretary">
        <div>
          <HseButton
            size="medium"
            :rightIcon="plusIcon"
            @click="openSecretaryModal"
          >
            {{ $t("addParticipantSecretary") }}
          </HseButton>
          <HseSpace size="small_2x" vertical />
        </div>
      </div>
    </div>
    <HseSpace size="small_2x" vertical />
    <!-- Модераторы -->
    <div>
      <div
        :style="{ background: '#6B7A99', padding: '6px' }"
        class="accordion__head"
      >
        <HseButton
          variant="tertiaryInvert"
          size="medium"
          v-on:click="isModeratorBlockOpen = !isModeratorBlockOpen"
        >
          {{ $t("moderatorBlock") }}
          <span class="ident" v-show="isModeratorBlockOpen">&#9650;</span>
          <span class="ident" v-show="!isModeratorBlockOpen">&#9660;</span>
        </HseButton>
        <HseButton
          v-if="!(fieldsDisabled === true) && fieldsDisabled !== 'membersOnly'"
          size="medium"
          :rightIcon="plusIcon"
          @click="openModeratorModal"
        >
          {{ $t("addParticipant") }}
        </HseButton>
        <div
          class="accordion__info"
          v-show="!(isModeratorBlockOpen && moderators.length)"
        >
          {{ $t("moderatorInfo") }}
        </div>
      </div>
      <div
        v-show="isModeratorBlockOpen && moderators.length"
        :style="{ background: '#F0F2F5', padding: '6px' }"
      >
        <div class="accordion">
          <div
            class="participant"
            v-for="(moderator, index) in moderators"
            :key="moderator.email"
          >
            <div class="participant__heading">
              <HseHeading size="h4">{{
                `${$t("fields.name.moderators")} №${index + 1}`
              }}</HseHeading>
              <div
                class="icons"
                v-if="
                  !(fieldsDisabled === true) && fieldsDisabled !== 'membersOnly'
                "
              >
                <svg
                  @click="() => editModerator(moderator, index)"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.60161 5.35704L11.4995 7.26425L6.69535 12.092L4.7985 10.1848L9.60161 5.35704ZM12.9397 4.89707L12.0933 4.04652C11.7662 3.71781 11.235 3.71781 10.9068 4.04652L10.0961 4.86126L11.994 6.76849L12.9397 5.81814C13.1934 5.56317 13.1934 5.15202 12.9397 4.89707ZM3.80527 12.8655C3.77073 13.0217 3.91108 13.1617 4.06654 13.1237L6.18147 12.6084L4.28461 10.7012L3.80527 12.8655Z"
                    fill="#6B7A99"
                  />
                </svg>
                <svg
                  @click="() => removeModerator(moderator)"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.6663 5.164L11.7263 4.224L7.99967 7.95067L4.27301 4.224L3.33301 5.164L7.05967 8.89067L3.33301 12.6173L4.27301 13.5573L7.99967 9.83067L11.7263 13.5573L12.6663 12.6173L8.93967 8.89067L12.6663 5.164Z"
                    fill="#6B7A99"
                  />
                </svg>
              </div>
            </div>
            <HseSpace size="small" vertical />
            <div class="cols two-cols">
              <ReadonlyInput
                :label="$t('fields.name.lName')"
                :value="moderator.lastName"
              />
              <ReadonlyInput
                :label="$t('fields.name.fName')"
                :value="moderator.firstName"
              />
              <ReadonlyInput
                v-if="moderator.secondName"
                :label="$t('fields.name.mName')"
                :value="moderator.secondName"
              />
              <ReadonlyInput
                :label="$t('fields.name.email')"
                :value="moderator.email"
              />
              <ReadonlyInput
                :label="$t('fields.name.companyShort')"
                :value="moderator.organizationName"
              />
              <!-- <ReadonlyInput :label="$t('pass')" :value="moderator.pass === true ? $t('yes') : $t('no')" />
              <ReadonlyInput :label="$t('letter')" :value="moderator.letter === true ? $t('yes') : $t('no')" /> -->
              <div
                :style="{ width: '100%' }"
                v-if="
                  isSendedQRForModerators &&
                  (statusPublicated || statusPublication) &&
                  moderator.secondName
                "
              />
              <HseButton
                :style="{ marginLeft: '15px' }"
                v-if="
                  !(
                    moderator.participationFormatId ===
                      '1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A' &&
                    fields.specialEventOrganizationFormatIdForm2.value ===
                      '895BB6AB-A343-48DC-AA19-7EAB348C8C0D'
                  ) &&
                  isSendedQRForModerators &&
                  !isAllModeratorOnline &&
                  (statusPublicated || statusPublication)
                "
                variant="primary"
                @click="() => openQRCodeModal('moderator', moderator.id)"
                size="medium"
              >
                {{ $t("QRCodeForModerator") }}
              </HseButton>
            </div>
          </div>
          <div
            class="actions"
            v-if="
              !isSendedQRForModerators &&
              !isAllModeratorOnline &&
              (statusPublicated || statusPublication)
            "
          >
            <HseButton
              variant="primary"
              @click="() => openQRCodeModal('moderator')"
              size="medium"
            >
              {{ $t("QRCodeForModerators") }}
            </HseButton>
          </div>
        </div>
      </div>
      <div v-show="isErrorModerator" class="hse-RichInput__message hasError">
        {{ $t("errorModerator") }}
      </div>
    </div>

    <HseSpace size="small" vertical />
    <div class="actions">
      <HseLink variant="secondary" @click="back">
        {{ $t("back") }}
      </HseLink>
      <div class="actions" v-if="fieldsDisabled === false">
        <HseButton
          variant="primary"
          :spinner="draftSpinner"
          spinnerPosition="left"
          size="medium"
          @click="draft"
        >
          {{ $t("draft") }}
        </HseButton>
        <HseButton
          variant="primary"
          :spinner="submitSpinner"
          spinnerPosition="left"
          size="medium"
          @click="submit"
        >
          {{ $t("submit") }}
        </HseButton>
      </div>
      <HseButton
        variant="primary"
        v-if="
          fieldsDisabled === 'partially' || fieldsDisabled === 'membersOnly'
        "
        :spinner="submitSpinner"
        spinnerPosition="left"
        size="medium"
        @click="submit"
      >
        {{ $t("save") }}
      </HseButton>
    </div>

    <HseModal v-model="isModeratorModalOpen" :shouldCloseOnOverlayClick="false">
      <ModeratorForm
        v-if="isModeratorModalOpen"
        :editableModerator="editableModerator"
        :added="moderators"
        @saved="onModeratorSave"
        :showFormat="fieldsDisabled"
        :orgFormat="fields.specialEventOrganizationFormatIdForm2.value"
        :IsRequiredPassport="IsRequiredPassport"
        :IsRequiredPassportRussia="IsRequiredPassportRussia"
        :IsRequiredPassportOther="IsRequiredPassportOther"
      />
    </HseModal>

    <HseModal v-model="isSecretaryModalOpen" :shouldCloseOnOverlayClick="false">
      <SecretaryForm
        v-if="isSecretaryModalOpen"
        :editableSecretary="editableSecretary"
        :added="members"
        :rtId="eventId"
        @saved="onSecretarySave"
        :showFormat="fieldsDisabled"
        :orgFormat="fields.specialEventOrganizationFormatIdForm2.value"
        @updateSecretary="updateSecretary"
      />
    </HseModal>

    <HseModal v-model="isQRCodeModalOpen" :shouldCloseOnOverlayClick="false">
      <QRCodeForm
        v-if="isQRCodeModalOpen"
        :rtId="rtId"
        :type="qrCodeType"
        :fields="fields"
        :participantId="participantId"
        @saved="onQRCodeSave"
      />
    </HseModal>
    <HseModal v-model="isWarningModalOpen" :shouldCloseOnOverlayClick="false">
      <WarningModalVue
        :isFormatEdit="isEditFormat"
        @closeWarningModal="() => closeWarningModal()"
      />
    </HseModal>
    <HseModal v-model="open">
      <div>
        <Approval></Approval>
        <HseButton :style="{ marginLeft: 'auto' }" @click="open = false"
          >OK</HseButton
        >
      </div>
    </HseModal>
  </div>
</template>

<script>
import {
  HseHeading,
  HseSpace,
  HseButton,
  HseLink,
  HseModal,
  IconActionPlus,
  HseTooltip,
  IconStatusInfo,
} from "@hse-design/vue";
import Validator from "../common/form/Validator";
import Datepicker from "../common/form/Datepicker";
import RadioGroup from "../common/form/RadioGroup";
import ModeratorForm from "./ModeratorForm.vue";
import SecretaryForm from "./SecretaryForm.vue";
import QRCodeForm from "./QRCodeForm.vue";
import ReadonlyInput from "../common/ReadonlyInput.vue";
import { mapState } from "vuex";
import { api } from "../../api";
import WarningModalVue from "./WarningModal.vue";
import FileUploader from "../common/form/FileUploader.vue";
import Approval from "../approval/Approval.vue";

export default {
  name: "FormPage2",
  components: {
    Validator,
    Datepicker,
    RadioGroup,
    HseHeading,
    HseSpace,
    HseButton,
    HseLink,
    ReadonlyInput,
    HseModal,
    ModeratorForm,
    SecretaryForm,
    QRCodeForm,
    WarningModalVue,
    FileUploader,
    HseTooltip,
    IconStatusInfo,
    Approval,
  },
  data: () => ({
    IsRequiredPassportAll: false,
    IsRequiredPassport: false,
    IsRequiredPassportRussia: false,
    IsRequiredPassportOther: false,
    open: false,
    plusIcon: IconActionPlus,
    isModeratorBlockOpen: true,
    isModeratorModalOpen: false,
    moderators: [],
    editableModerator: null,
    speakers: [],
    isSecretaryModalOpen: false,
    isWarningModalOpen: false,
    members: [],
    membersChanged: false,
    editableSecretary: null,
    isQRCodeModalOpen: false,
    participantId: null,
    qrCodeType: "",
    draftSpinner: false,
    submitSpinner: false,
    isEditFormat: false,
    isErrorModerator: false,
    radiosToValidate: [
      "syncTranslation",
      "needOnlineSupport",
      "needVolunteerSupport",
      "needOfficialPhoto",
      "needOfficialVideo",
      "publicityId",
      "needOnlineTranslation",
      "isAgree",
    ],
    fieldsToValidate: [
      "TitleRU",
      "TitleEN",
      "AnnotationTextRU",
      "AnnotationTextEN",
      "ResponsibleFIO",
      "ResponsiblePost",
      "ResponsibleDepartmenID",
      "ResponsibleEmail",
      "date",
      "programSessionTimeId",
      "SpeakerPassport",
      "secretaryOffline",
      "secretaryOnline",
    ],
  }),
  props: [
    "fields",
    "fieldsDisabled",
    "eventId",
    "user",
    "isNew",
    "data",
    "rtId",
  ],
  created() {
    this.fields.publicityId.getAllOptions();
    this.fields.specialEventOrganizationFormatIdForm2.getAllOptions();
    this.fields.TitleRU.setValues(false, this.data.TitleRU);
    this.fields.TitleEN.setValues(false, this.data.TitleEN);
    this.fields.AnnotationTextRU.setValues(false, this.data.AnnotationTextRU);
    this.fields.AnnotationTextEN.setValues(false, this.data.AnnotationTextEN);

    api
      .request("get_is_required_passport", { EventId: this.eventId })
      .then((data) => {
        this.IsRequiredPassport = data.IsRequiredPassport;
        this.IsRequiredPassportRussia = data.IsRequiredPassportRussia;
        this.IsRequiredPassportOther = data.IsRequiredPassportOther;
        this.IsRequiredPassportAll = this.checkNeedPassport(
          this.fields.SpeakerNationality.value
        );
      });
  },
  methods: {
    changeCountryId(value) {
      this.IsRequiredPassportAll = this.checkNeedPassport(value);
    },
    checkNeedPassport(countryId) {
      const citizenIdRussia = "BC763F4B-FBD9-461B-B395-166020E8C026"; // Российская Федерация

      return (
        (this.IsRequiredPassport &&
          this.IsRequiredPassportRussia &&
          countryId === citizenIdRussia) ||
        (this.IsRequiredPassport &&
          this.IsRequiredPassportOther &&
          countryId !== citizenIdRussia)
      );
    },
    dateFormat(date) {
      return date.split(".").reverse().join("-");
    },
    submit() {
      this.saveForm(false);
    },
    draft() {
      this.saveForm(true);
    },
    back() {
      this.$router.push("/events");
    },
    updateTimestamps(val) {
      this.fields.programSessionTimeId.setDate(this.dateFormat(val));
      this.$emit("loadAllOptionsTime");
    },
    updateSecretary(val) {
      this.fields.secretaryOffline.setOptions(val);
      this.fields.secretaryOnline.setOptions(val);
      this.$emit("loadAllOptionsSecretary");
    },
    closeWarningModal() {
      this.isWarningModalOpen = false;
      this.isEditFormat = false;
      this.draftSpinner = false;
    },
    showPersonal() {
      this.$emit("openPersonslDataModal");
    },
    setFormat(format) {
      this.fields.specialEventOrganizationFormatIdForm2.setValues(true, format);
      if (format === "895BB6AB-A343-48DC-AA19-7EAB348C8C0D") {
        // this.isWarningModalOpen = true;
        this.isEditFormat = true;

        if (this.fields.moderators)
          this.fields.moderators.setValues(
            true,
            this.fields.moderators.value.map((item) => {
              return {
                ...item,
                participationFormatId: (item.participationFormatId =
                  "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A"),
              };
            })
          );
        if (this.moderators)
          this.moderators = this.moderators.map((item) => {
            return {
              ...item,
              participationFormatId: (item.participationFormatId =
                "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A"),
            };
          });
      } else {
        if (this.fields.moderators)
          this.fields.moderators.setValues(
            true,
            this.fields.moderators.value.map((item) => {
              return {
                ...item,
                participationFormatId: (item.participationFormatId = format),
              };
            })
          );
        if (this.moderators)
          this.moderators = this.moderators.map((item) => {
            return {
              ...item,
              participationFormatId: (item.participationFormatId = format),
            };
          });
      }
    },
    saveForm(draft) {
      console.log(this.moderators, "this.moderators");
      this.isErrorModerator = this.moderators.length === 0 ? true : false;

      if (
        this.fields.specialEventOrganizationFormatIdForm2.value ===
        "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A"
      ) {
        this.fields.maxParticipants.setRequired(false);
        this.fields.maxParticipants.setValues(true, "");
      }
      if (
        this.fields.needVolunteerSupport.value === 1 &&
        this.fields.volunteerSupportBlocksId.value.length === 0
      )
        this.fields.volunteerSupportBlocksId.setValues(false, []);
      if (
        +new Date(
          this.fields.endRegistrationDate.value.split(".").reverse().join("-")
        ) +
          new Date().getTimezoneOffset() * 60 * 1000 >
        +new Date(this.fields.date.value.split(".").reverse().join("-"))
      )
        return;
      this.$emit("validateFields");
      let isPageValid = !this.fieldsToValidate.some((key) => {
        console.log(key, "key");
        if (!this.IsRequiredPassportAll && key === "SpeakerPassport") {
          console.log(this.fields[key].isValid, "SpeakerPassport");
          return false;
        } else {
          if (
            key === "secretaryOnline" &&
            this.fields.specialEventOrganizationFormatIdForm2.value ===
              "592D7A01-E55F-4860-B403-1BB88FDE85A9"
          ) {
            return false;
          } else {
            if (
              key === "secretaryOffline" &&
              this.fields.specialEventOrganizationFormatIdForm2.value ===
                "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A"
            ) {
              return false;
            } else {
              console.log(
                !this.fields[key].isValid,
                "!this.fields[key].isValid;"
              );
              return !this.fields[key].isValid;
            }
          }
        }
      });

      if (this.IsRequiredPassportAll) {
        if (!this.fields.isAgree.isValid) {
          console.log(isPageValid, "isPageValid");
          isPageValid = false;
        }
      }

      if (isPageValid && this.moderators.length != 0) {
        if (draft) {
          this.draftSpinner = true;
        } else {
          this.submitSpinner = true;
        }

        let moderatorsNew = [];
        console.log(this.moderators, "this.moderators");
        for (const item of this.moderators) {
          moderatorsNew.push({
            id: item.id,
            lastName: item.lastName,
            firstName: item.firstName,
            middleName: item.secondName,
            firstNameEN: item.firstNameEn,
            lastNameEN: item.lastNameEn,
            company: item.organizationName,
            companyEN: item.organizationNameEn,
            email: item.email,
            HSEMember: item.HSEMember,
            campusId: item.campusId,
            PassportId: item.PassportId,
            countryId: item.countryId,
          });
        }
        const params = {};

        params.LName = this.fields.LName.value;
        params.FName = this.fields.FName.value;
        params.SName = this.fields.SName.value;
        params.Email = this.fields.Email.value;
        params.Position = this.fields.Position.value;
        params.Phone = this.fields.Phone.value;
        params.SpeakerLName = this.fields.SpeakerLName.value;
        params.SpeakerLNameEn = this.fields.SpeakerLNameEn.value;
        params.SpeakerFName = this.fields.SpeakerFName.value;
        params.SpeakerFNameEn = this.fields.SpeakerFNameEn.value;
        params.SpeakerSName = this.fields.SpeakerSName.value;
        params.SpeakerPosition = this.fields.SpeakerPosition.value;
        params.SpeakerPositionEn = this.fields.SpeakerPositionEn.value;
        params.SpeakerAffiliation = this.fields.SpeakerAffiliation.value;
        params.SpeakerAffiliationEn = this.fields.SpeakerAffiliationEn.value;
        params.PlannedTopicOfTheReport =
          this.fields.PlannedTopicOfTheReport.value;
        params.PlannedTopicOfTheReportEn =
          this.fields.PlannedTopicOfTheReportEn.value;
        params.SpeakerCountry = this.fields.SpeakerCountry.value;
        params.SpeakerNationality = this.fields.SpeakerNationality.value;
        params.SpeakerPassport = this.fields.SpeakerPassport.value;
        params.AbstractTextRu = this.fields.AbstractTextRu.value;
        params.AbstractTextEn = this.fields.AbstractTextEn.value;
        params.SpeakerCv = this.fields.SpeakerCv.value;
        params.SpeakerStatus = this.fields.speakerStatusNew.value;
        params.RationaleInvitation = this.fields.RationaleInvitation.value;
        params.CoordinatingLeaderFio = this.fields.CoordinatingLeaderFio.value;
        params.CoordinatingLeaderPosition =
          this.fields.CoordinatingLeaderPosition.value;
        params.CoordinatingLeaderDepartment =
          this.fields.CoordinatingLeaderDepartment.value;
        params.CoordinatingLeaderEmail =
          this.fields.CoordinatingLeaderEmail.value;
        params.Comment = this.fields.Comment.value;
        params.DepartmentIdRu = this.fields.DepartmentIdRu.value;
        params.ParticipationFormatId =
          this.fields.specialEventOrganizationFormatId.value;

        params.rtId = this.isNew ? null : this.rtId;
        params.lang = this.lang;
        params.draft = draft;
        params.eventId = this.eventId;

        // 2 этап
        params.TitleRU = this.convertGuid(this.fields.TitleRU.value);
        params.TitleEN = this.convertGuid(this.fields.TitleEN.value);

        params.AnnotationTextRU = this.convertGuid(
          this.fields.AnnotationTextRU.value
        );
        params.AnnotationTextEN = this.convertGuid(
          this.fields.AnnotationTextEN.value
        );

        params.imageFileID = this.convertGuid(this.fields.imageFileID.value);

        params.ResponsibleFIO = this.convertGuid(
          this.fields.ResponsibleFIO.value
        );
        params.ResponsiblePost = this.convertGuid(
          this.fields.ResponsiblePost.value
        );
        params.ResponsibleDepartmenID = this.convertGuid(
          this.fields.ResponsibleDepartmenID.value
        );
        params.ResponsibleEmail = this.convertGuid(
          this.fields.ResponsibleEmail.value
        );

        params.StartTime = this.dateFormat(this.fields.date.value);
        params.ProgramSessionTimeId = this.fields.programSessionTimeId.value;

        params.OrganizationFormatId =
          this.fields.specialEventOrganizationFormatIdForm2.value;

        params.Publicity =
          this.fields.publicityId.value ===
          "B9B5A48A-BB02-4065-BED7-8C8F6C7BD0F1";

        params.LangRU = this.convertGuid(this.fields.langRu.value);
        params.LangEN = this.convertGuid(this.fields.langEn.value);
        params.LangOther = this.convertGuid(this.fields.langOther.value);

        params.Other = this.convertGuid(this.fields.other.value);

        params.SecretaryOnline =
          this.fields.specialEventOrganizationFormatIdForm2.value ===
            "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A" ||
          this.fields.specialEventOrganizationFormatIdForm2.value ===
            "895BB6AB-A343-48DC-AA19-7EAB348C8C0D"
            ? this.convertGuid(this.fields.secretaryOnline.value)
            : null;
        params.SecretaryOffline =
          this.fields.specialEventOrganizationFormatIdForm2.value ===
            "592D7A01-E55F-4860-B403-1BB88FDE85A9" ||
          this.fields.specialEventOrganizationFormatIdForm2.value ===
            "895BB6AB-A343-48DC-AA19-7EAB348C8C0D"
            ? this.convertGuid(this.fields.secretaryOffline.value)
            : null;

        params.moderators = moderatorsNew;

        params.ManagerRU = this.moderators
          .map((item) => {
            return item.lastName + " " + item.firstName + " " + item.middleName;
          })
          .join(", ");

        params.ManagerEN = this.moderators
          .map((item) => {
            return item.lastNameEN + " " + item.firstNameEN;
          })
          .join(", ");

        params.moderatorsDeleteIds = this.fields.moderatorsDeleteIds.value;

        // params.langRu = this.convertGuid(this.fields.langRu.value)
        // params.langEn = this.convertGuid(this.fields.langEn.value)
        // params.langOther = this.convertGuid(this.fields.langOther.value)
        // params.TitleRU = this.convertGuid(this.fields.TitleRU.value)
        // params.TitleEN = this.convertGuid(this.fields.TitleEN.value)
        // params.AnnotationTextRU = this.convertGuid(this.fields.AnnotationTextRU.value)
        // params.AnnotationTextEN = this.convertGuid(this.fields.AnnotationTextEN.value)
        // params.imageFileID = this.convertGuid(this.fields.imageFileID.value)
        // params.ResponsibleFIO = this.convertGuid(this.fields.ResponsibleFIO.value)
        // params.ResponsiblePost = this.convertGuid(this.fields.ResponsiblePost.value)
        // params.ResponsibleDepartmenID = this.convertGuid(this.fields.ResponsibleDepartmenID.value)
        // params.ResponsibleEmail = this.convertGuid(this.fields.ResponsibleEmail.value)
        // params.moderators = this.convertGuid(this.fields.moderators.value)
        // params.OrganizationFormatId = this.fields.specialEventOrganizationFormatIdForm2.value
        // params.ProgramSessionTimeId = this.fields.programSessionTimeId.value
        // params.Publicity = this.fields.publicityId.value
        // params.SecretaryOnline = this.fields.specialEventOrganizationFormatIdForm2.value === "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A" ? this.convertGuid(this.fields.secretary.value) : null
        // params.SecretaryOffline = this.fields.specialEventOrganizationFormatIdForm2.value === "592D7A01-E55F-4860-B403-1BB88FDE85A9" ? this.convertGuid(this.fields.secretary.value) : null

        // Object.keys(this.fields).forEach(key => {
        //   const { value } = this.fields[key]

        //   if (key === 'publicityId') {
        //     params[key] = this.fields[key].value
        //   }

        //   if (key === 'date' || key === 'endRegistrationDate') {
        //     params[key] = this.dateFormat(value)
        //   } else if (key === 'maxParticipants') {
        //     params[key] = +value
        //   } else if (this.fieldsToValidate.includes(key)) {
        //     params[key] = this.convertGuid(value)
        //   } else if (this.radiosToValidate.includes(key)) {
        //     if (typeof value === 'number') {
        //       params[key] = !!value
        //     } else {
        //       params[key] = value
        //     }
        //   }
        // })

        params.secondPhase = true;

        api.request("add_hor_data", params).then((data) => {
          if (draft) {
            this.draftSpinner = "done";
          } else {
            this.submitSpinner = "done";
          }
          setTimeout(() => {
            if (draft) {
              this.draftSpinner = false;
            } else {
              this.submitSpinner = false;
            }
            if (data && data.length) {
              this.$emit("saved", draft);
            } else {
              this.$emit("savedErr");
            }
          }, 2000);
        });
      }
    },
    convertGuid(value) {
      return !value ? null : value;
    },
    openQRCodeModal(type, participantId) {
      this.qrCodeType = type;
      this.participantId = participantId;
      this.isQRCodeModalOpen = true;
    },
    onQRCodeSave() {
      this.isQRCodeModalOpen = false;
      this.participantId = null;
      this.qrCodeType = "";
      this.$emit("getRTData");
    },
    openModeratorModal() {
      this.isModeratorModalOpen = true;
      this.editableModerator = null;
    },
    editModerator(moderator, index) {
      this.editableModerator = { ...moderator, index };
      this.isModeratorModalOpen = true;
    },
    onModeratorSave(result) {
      if (!this.editableModerator) {
        this.moderators.push(result);
        this.isModeratorModalOpen = false;
      } else {
        this.moderators[this.editableModerator.index] = result;
        this.isModeratorModalOpen = false;
      }
      this.fields.moderators.setValues(true, this.moderators);
      this.isModeratorModalOpen = false;
      this.editableModerator = null;
    },
    removeModerator({ id }) {
      if (!this.isNew) {
        this.fields.moderatorsDeleteIds.setValues(true, [
          ...this.fields.moderatorsDeleteIds.value,
          this.moderators.find((moderator) => moderator.id === id).id,
        ]);
      }
      this.moderators = this.moderators.filter(
        (moderator) => moderator.id !== id
      );
      this.fields.moderators.setValues(true, this.moderators);
      this.editableModerator = null;
    },
    openSecretaryModal() {
      this.isSecretaryModalOpen = true;
      this.editableSecretary = null;
    },
    onSecretarySave(result) {
      // this.fields.secretaryOnline.setValues(false, String(result.ID))
      console.log(result);
      this.membersChanged = true;
      this.editableSecretary = null;
      this.isSecretaryModalOpen = false;
    },
    setPublicityId(val) {
      this.fields.publicityId.setValues(true, val);
    },
  },
  computed: {
    showWebinarLink() {
      return this.data.showWebinarLink && !this.membersChanged;
    },
    statusPublication() {
      return this.data.StatusId === "28AE374D-D74C-4F4B-857C-E4E5BF0FF90A";
    },
    statusPublicated() {
      return this.data.StatusId === "C08814A5-7780-4595-9A31-AF51383E3933";
    },
    isSendedQRForModerators() {
      return this.moderators.some((item) => item.pass === true);
    },
    isAllModeratorOnline() {
      return this.moderators.every(
        (item) =>
          item.participationFormatId === "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A"
      );
    },
    ...mapState(["lang"]),
  },
  watch: {
    data() {
      if (this.fieldsDisabled) {
        this.fieldsToValidate.forEach((key) => {
          if (
            this.fieldsDisabled === "partially" &&
            (key === "PlannedTopicOfTheReport" || key === "SpeakerCountry")
          ) {
            if (this.fields[key].setDisabled) {
              this.fields[key].setDisabled(true);
            }
          } else if (
            this.fieldsDisabled === true ||
            this.fieldsDisabled === "membersOnly"
          ) {
            if (this.fields[key].setDisabled) {
              this.fields[key].setDisabled(true);
            }
          }
        });
      }
      if (this.data.moderators) {
        // this.moderators = this.data.moderators
        let moderatorsNew = [];
        for (const item of this.data.moderators) {
          moderatorsNew.push({
            id: item.Id,
            lastName: item.LastName,
            firstName: item.FirstName,
            secondName: item.MiddleName,
            firstNameEn: item.FirstNameEN,
            lastNameEn: item.LastNameEN,
            organizationName: item.Company,
            organizationNameEn: item.CompanyEN,
            email: item.Email,
            HSEMember: item.HSEMember,
            campusId: item.campusId,
          });
        }
        this.moderators = moderatorsNew;
      }
      if (this.data.speakers) {
        this.speakers = this.data.speakers;
      }
      if (this.data.members) {
        this.members = this.data.members;
      }
      if (this.data.SecretaryOnline) {
        this.fields.secretaryOnline.setValues(
          false,
          String(this.data.SecretaryOnline)
        );
      }
      if (this.data.SecretaryOffline) {
        this.fields.secretaryOffline.setValues(
          false,
          String(this.data.SecretaryOffline)
        );
      }
      if (this.fields.SpeakerNationality.value) {
        console.log("work!");
      }
    },
    fields: {
      handler() {
        if (this.fields.SpeakerNationality.value) {
          this.IsRequiredPassportAll = this.checkNeedPassport(
            this.fields.SpeakerNationality.value
          );
        }
      },
      deep: true,
    },
    moderators(val) {
      this.isErrorModerator = val.length === 0 ? true : false;
    },
  },
  i18n: {
    messages: {
      en: {
        moderatorBlock: "Moderators",
        speakerBlock: "Experts",
        memberBlock: "Secretary",
        pass: "Pass",
        letter: "Letter",
        no: "No",
        yes: "Yes",
        addParticipant: "Add",
        addParticipantSecretary: "Add a secretary",
        QRCodeForSecretarys: "Send QR code to participants",
        QRCodeForSecretary: "Send QR code to participant",
        QRCodeForSpeakers: "Send qr code to experts",
        QRCodeForSpeaker: "Send qr code to expert",
        QRCodeForModerators: "Send qr code to moderators",
        QRCodeForModerator: "Send qr code to moderator",
        Approve: "Approve all",
        Webinar: "Send the link for a webinar",
        back: "Back",
        submit: "Send a request",
        draft: "Save a draft",
        okStatus: "Request sent",
        errStatus: "Error occured",
        chooseLang: "Choose a language",
        eventFormat: "Event format",
        approved: "Approved",
        rejected: "Rejected",
        sent: "Sent",
        save: "Save changes",
        titleReportRu: "Title of the report (rus)",
        titleReportEng: "Title of the report (eng)",
        abstractTextRu: "Abstract text (rus)",
        abstractTextEng: "Abstract text (eng)",
        titleRUHseTooltip:
          "If the title of the report has changed, please indicate the current version",
        AnnotationTextHseTooltip: "The volume is not less than 1000 characters",
        errorModerator: "There must be at least one moderator",
        pd: "Personal data processing agreement",
        moderatorInfo:
          "The moderator details are captured in the system using their email address, whether it's their own or that of their accompanying person. This is essential for creating a personalized record for the moderator/speaker and for facilitating subsequent organizational tasks, such as registration and pass issuance. We will not use these emails for sending automated notifications. If you have any questions, please feel free to contact the Organizing Committee at YasinConference@hse.ru.",
      },
      ru: {
        moderatorBlock: "Модераторы",
        speakerBlock: "Эксперты-спикеры",
        memberBlock: "Секретарь",
        pass: "Пропуск",
        letter: "Письмо",
        no: "Нет",
        yes: "Да",
        addParticipant: "Добавить",
        addParticipantSecretary: "Добавить секретаря",
        QRCodeForSecretarys: "Отправить qr-код участникам",
        QRCodeForSecretary: "Отправить qr-код участнику",
        QRCodeForSpeakers: "Отправить qr-код экспертам",
        QRCodeForSpeaker: "Отправить qr-код эксперту",
        QRCodeForModerators: "Отправить qr-код модераторам",
        QRCodeForModerator: "Отправить qr-код модератору",
        Approve: "Одобрить всех",
        Webinar: "Отправить ссылку на вебинар",
        back: "Назад",
        submit: "Подать заявку",
        draft: "Сохранить черновик",
        okStatus: "Заявка отправлена",
        errStatus: "Произошла ошибка",
        chooseLang: "Выберите язык",
        eventFormat: "Формат мероприятия",
        approved: "Одобрен",
        rejected: "Отклонен",
        sent: "Отправлен",
        save: "Сохранить изменения",
        titleRUHseTooltip:
          "Если название доклада изменилось, укажите, пожалуйста, актуальную версию",
        AnnotationTextHseTooltip: "Объем не менее 1000 знаков",
        errorModerator: "Должен быть хотя бы один модератор",
        responsibleEvent: "Ответственный за мероприятие",
        responsibleEventTooltip:
          "сотрудник НИУ ВШЭ, который координирует процесс подачи заявки на мероприятие и, если она поддержана, его проведение",
        secretaryTooltip:
          "В данном поле необходимо указать секретаря, который будет сопровождать мероприятие или в очном формате, или в формате онлайн. Для назначения секретаря необходимо выбрать его из списка или добавить своего при отсутствии данных в списке. Секретарь - сотрудник / аспирант / студент НИУ ВШЭ, который занимается организационным и техническим сопровождением мероприятия до, во время и по завершении мероприятия.",
        pd: "Согласие на обработку персональных данных",
        moderatorInfo:
          "Данные модератора фиксируются в системе через электронный адрес (свой или сопровождающего). Это необходимо для формирования индивидуальной записи модератора/спикера и для дальнейшей организационной работы, в т.ч. для регистрации и оформления пропуска. Автоматическая рассылка писем не предполагается. В случае вопросов просим обращаться в Организационный комитет конференции: YasinConference@hse.ru",
      },
    },
  },
};
</script>

<style scoped>
.addButtonSecretary {
  display: flex;
  align-items: flex-end;
}

.cols {
  display: flex;
  margin: 0 -15px;
  flex-wrap: wrap;
}

.cols > .field {
  padding: 0 15px;
  flex: 1;
}

.two-cols .field {
  max-width: 50%;
  width: 50%;
  flex-basis: 50%;
}

.three-cols .field {
  max-width: 33.3%;
}

.ident {
  margin-left: 10px;
}

@media (max-width: 767px) {
  .two-cols .field,
  .three-cols .field {
    max-width: 100%;
    width: 100%;
    flex-basis: 100%;
  }
}

.actions {
  flex-wrap: wrap;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.accordion {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
}

.accordion__head {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.accordion__info {
  font-size: 14px;
  line-height: 1.6em;
  color: #fff;
  padding: 8px 24px;
}

.participant__heading {
  display: flex;
  align-items: center;
  gap: 5px;
}

.participant__heading .icons {
  display: flex;
  gap: 3px;
}

.participant__heading .icons > svg {
  cursor: pointer;
}

.hasError {
  color: #ff564e;
}

.flex-tooltip {
  display: flex;
}

.flex-tooltip > div:first-child {
  width: calc(100% - 28px);
}

.flex-tooltip > div:last-child {
  padding: 36px 0 0 12px;
}

.flex-tooltip-area {
  display: flex;
}

.flex-tooltip-area > div:first-child {
  width: calc(100% - 28px);
}

.flex-tooltip-area > div:last-child {
  padding: 48px 0 0 12px;
}

.headingWithTooltip {
  display: flex;
}

.headingWithTooltip > div:first-child {
  padding: 2px 0 0 0;
}

.headingWithTooltip > div:last-child {
  padding: 0px 0 0 12px;
}

.subheading {
  font-family: "Work Sans", sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #0f0f14;
}
</style>
