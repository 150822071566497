import { HseInput, HseSelect, HseCheckbox } from '@hse-design/vue'
import { resourceLoader } from '../components/common/resourceLoader'
import { dateFormatter } from '../components/common/dateFormatter'

const currDate = dateFormatter.format(new Date().toISOString(), 'date')

const fields = {
  LName: {
    value: '',
    isValid: false,
    label: 'fields.visa.lName',
    isRequired: true,
    size: 'large',
    component: HseInput,
  },
  FName: {
    value: '',
    isValid: false,
    label: 'fields.visa.fName',
    isRequired: true,
    size: 'large',
    component: HseInput,
  },
  SName: {
    value: '',
    isValid: true,
    label: 'fields.visa.mName',
    size: 'large',
    component: HseInput,
  },
  address: {
    value: '',
    isValid: false,
    label: 'fields.visa.homeAddress',
    isRequired: true,
    size: "large",
    component: HseInput,
  },
  workAddress: {
    value: '',
    isValid: false,
    label: 'fields.visa.workAddress',
    size: "large",
    isRequired: true,
    component: HseInput,
  },
  phoneNumber: {
    value: '',
    isValid: false,
    label: 'fields.name.phone',
    isRequired: true,
    size: 'large',
    component: HseInput,
    rules: [{
      name: 'phone',
    }],
  },
  passportNumber: {
    value: '',
    isValid: false,
    label: 'fields.visa.passportId',
    isRequired: true,
    size: "large",
    component: HseInput,
  },
  citizenshipID: {
    value: '',
    isValid: false,
    label: 'fields.name.citizen',
    isRequired: true,
    size: 'large',
    component: HseSelect,
    searchable: 'dropdown',
    getAllOptions (search) {
      return resourceLoader.load('countriesList', null, [], search, ['BC763F4B-FBD9-461B-B395-166020E8C026'])
    },
  },
  emailAddress: {
    value: '',
    isValid: false,
    label: 'fields.name.email',
    isRequired: true,
    size: 'large',
    component: HseInput,
    rules: [{
      name: 'email',
    }],
  },
  birthCountryID: {
    value: '',
    isValid: false,
    label: 'fields.visa.birthCountry',
    isRequired: true,
    size: "large",
    component: HseSelect,
    searchable: 'dropdown',
    getAllOptions (search) {
      return resourceLoader.load('countriesList', null, [], search)
    },
  },
  birthCity: {
    value: '',
    isValid: false,
    label: 'fields.visa.birthCity',
    isRequired: true,
    size: "large",
    component: HseInput,
  },
  placeOfWork: {
    value: '',
    isValid: false,
    label: 'fields.visa.workPlace',
    isRequired: true,
    size: "large",
    component: HseInput,
  },
  position: {
    value: '',
    isValid: true,
    label: 'fields.name.position',
    isRequired: true,
    size: 'large',
    component: HseInput,
  },
  passportDateIssue: {
    value: currDate,
    isValid: false,
    label: 'fields.visa.passportDateIssue',
    isRequired: true,
    noPlaceholder: true,
    size: "large",
    component: HseInput,
  },
  passportDateExpire: {
    value: currDate,
    isValid: false,
    label: 'fields.visa.passportDateExpire',
    isRequired: true,
    noPlaceholder: true,
    size: "large",
    component: HseInput,
  },
  durationFrom: {
    value: currDate,
    isValid: false,
    label: 'fields.visa.durationFrom',
    isRequired: true,
    noPlaceholder: true,
    size: "large",
    component: HseInput,
  },
  durationTo: {
    value: currDate,
    isValid: false,
    label: 'fields.visa.durationTo',
    isRequired: true,
    noPlaceholder: true,
    size: "large",
    component: HseInput,
  },
  citiesOfVisit: {
    value: '',
    isValid: false,
    label: 'fields.visa.visitCities',
    isRequired: true,
    size: "large",
    component: HseInput,
  },
  russianConsulateCity: {
    value: '',
    isValid: false,
    label: 'fields.visa.consulateCity',
    isRequired: true,
    size: "large",
    component: HseInput,
  },
  countryObtainVisaId: {
    value: '',
    isValid: false,
    label: 'fields.visa.countryObtain',
    isRequired: true,
    size: "large",
    component: HseSelect,
    searchable: 'dropdown',
    getAllOptions (search) {
      return resourceLoader.load('countriesList', null, [], search, ['BC763F4B-FBD9-461B-B395-166020E8C026'])
    },
  },
  request: {
    value: '',
    isValid: false,
    label: 'fields.visa.request',
    isRequired: true,
    size: "large",
    component: HseSelect,
    searchable: 'dropdown',
    getAllOptions (search) {
      return resourceLoader.load('requestsListAvailableForVisaRequest', {}, [], search)
    },
  },
  isAgree: {
    value: false,
    isValid: false,
    hideTopLabel: true,
    label: 'fields.name.pdAgreement',
    classes: { label: 'smallLabel' },
    component: HseCheckbox,
    rules: [{
      name: 'custom',
      message: 'fields.rule.pdAgreement',
      validateVal: value => value
    }],
  },
  passport_file_id: {
    label: 'fields.visa.passportScan',
    isRequired: true,
    value: '',
    isValid: false,
    maxSizeMb: 3,
    formats: ['jpg', 'jpeg']
  },
  gender: {
    value: '',
    isValid: false,
    label: 'fields.name.gender',
    isRequired: true,
    size: 'large',
    component: HseSelect,
    getAllOptions (search) {
      return resourceLoader.load('genderList', null, [], search)
    },
  },
  birthDate: {
    value: '',
    isValid: false,
    label: 'fields.visa.birthDate',
    isRequired: true,
    size: "large",
    component: HseInput,
  },
  issuingAuthority: {
    value: '',
    isValid: false,
    label: 'fields.visa.issuingAuthority',
    isRequired: true,
    size: "large",
    component: HseInput,
  },
}

Object.keys(fields).forEach((key) => {
  fields[key].setValues = function (isValid, value = '') {
    this.value = value
    this.isValid = isValid
  }
})

export default fields
