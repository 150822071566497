<template>
  <div class="add-moderator">
    <HseHeading size="h3">{{ $t('headings.addModerator') }}</HseHeading>
    <HseSpace size='small' vertical />
    <div v-show="isCheckExists" class="check">
      <div class="field">
        <Validator :field="fields.login" @change="toggleError" />
        <HseSpace size='small_2x' vertical />
      </div>

      <div class="form-footer">
        <div class="actions">
          <HseButton
            v-on:click="checkEmail"
            :spinner="buttonSpinner"
            spinnerPosition="left"
          >
            <span v-if="buttonSpinner === false">{{ $t('headings.addModerator') }}</span>
            <span v-else-if="buttonSpinner === true">{{ $t('adding') }}...</span>
            <span v-else-if="buttonSpinner === 'done'">{{ $t('auth.btnDone') }}</span>
          </HseButton>
        </div>
        <div class="form-description">{{ $t('fields.requiredFields') }}</div>
      </div>

    </div>
    <div v-show="!isCheckExists" class="the-form">
      <div class="subheading">{{ $t('headings.personal') }}</div>
      <HseSpace size='small' vertical />
      <div class="form">
        <div v-if="isLangRu" class="field">
          <Validator :field="fields.LName" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <Validator :field="fields.LNameEN" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div v-if="isLangRu" class="field">
          <Validator :field="fields.FName" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <Validator :field="fields.FNameEN" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div v-if="isLangRu" class="field">
          <Validator :field="fields.SName" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <Validator :field="fields.login" @change="toggleError" />
        </div>
      </div>
      <HseSpace size='small' vertical />
      <div class="subheading">{{ $t('headings.company') }}</div>
      <HseSpace size='small' vertical />
      <div class="form">
        <div v-if="isLangRu" class="field">
          <Validator :field="fields.CompanyNameShort" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <Validator :field="fields.CompanyNameShortEn" />
          <HseSpace size='small_2x' vertical />
        </div>
      </div>
      <HseSpace size='medium' vertical />
      <div class="form-footer">
        <div class="actions">
          <HseButton
            v-on:click="registerReq"
            :spinner="buttonSpinner"
            spinnerPosition="left"
          >
            <span v-if="buttonSpinner === false">{{ $t(this.moderator ? 'save' : 'headings.addModerator') }}</span>
            <span v-else-if="buttonSpinner === true">{{ $t(this.moderator ? 'saving' : 'adding') }}...</span>
            <span v-else-if="buttonSpinner === 'done'">{{ $t('auth.btnDone') }}</span>
          </HseButton>
        </div>
        <div class="form-description">{{ $t('fields.requiredFields') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { HseHeading, HseSpace, HseButton } from '@hse-design/vue'
  import { api } from '../../api'
  import Validator from '../common/form/Validator.vue'
  import fields from './moderatorFields'
  import { mapState } from 'vuex'
  import { localizeFields } from '../../i18n'

  export default {
    components: {
      HseHeading,
      HseSpace,
      HseButton,
      Validator,
    },
    props: ['added'],
    data: () => ({
      fields,
      buttonSpinner: false,
      isCheckExists: true,
      moderator: null,
      compareRuEnFields: {
        LName: 'LNameEN',
        FName: 'FNameEN',
        CompanyNameShort: 'CompanyNameShortEn',
      },
    }),
    created () {
      this.translateFields()
    },
    computed: {
      isLangRu () {
        return this.lang === 'ru'
      },
      ...mapState(['lang'])
    },
    watch: {
      lang () {
        this.translateFields()
      },
    },
    methods: {
      toggleError (value) {
        const isErrSet = this.$store.state.error && this.$store.state.error.code === 876
        const isAdded = this.added ? this.added.some(coauth => coauth.email === value) : false
        if (isAdded && !isErrSet) {
          this.$store.commit('setError', { code: 876 })
          this.$emit('validateFields')
        } else if (!isAdded && isErrSet) {
          this.$store.commit('setError', null)
          this.$emit('validateFields')
        }
      },
      translateFields () {
        this.fields = localizeFields(fields, this.fields)
      },
      registerReq () {
        this.$emit('validateFields')
        let isFormInvalid = false
        const skip = this.isLangRu ? [] : Object.keys(this.compareRuEnFields)

        Object.keys(this.fields).some(key => {
          if (skip.includes(key))
            return false

          isFormInvalid = this.fields[key].isValid === false
          return isFormInvalid
        })

        if (isFormInvalid) return

        this.buttonSpinner = true
        if (this.moderator && !this.moderator.isNew) {
          this.buttonSpinner = 'done'
          setTimeout(() => {
            this.buttonSpinner = false
            this.$emit('saved', {
              ...this.moderator,
              CompanyNameShort: this.fields.CompanyNameShort.value,
              CompanyNameShortEn: this.fields.CompanyNameShortEn.value,
            } )
          }, 2000)
          return
        } else {
          this.buttonSpinner = 'done'
          setTimeout(() => {
            this.buttonSpinner = false
            this.$emit('saved', {
              data: {
                public: {
                  LName: this.fields.LName.value,
                  FName: this.fields.FName.value,
                  SName: this.fields.SName.value,
                  LNameEN: this.fields.LNameEN.value,
                  FNameEN: this.fields.FNameEN.value,
                },
              },
              login: this.fields.login.value,
              CompanyNameShort: this.fields.CompanyNameShort.value,
              CompanyNameShortEn: this.fields.CompanyNameShortEn.value,
            } )
          }, 2000)
        }
      },
      checkEmail () {
        this.$emit('validateFields')

        if (this.fields.login.isValid) {
          this.buttonSpinner = true

          api.auth('user.manage.check.login', this.fields.login.value).then(result => {
            if (result) {
              const isAdded = this.added ? this.added.some(item => item.id === result) : false
              if (!isAdded) {
                api.auth('user.manage.get', result).then(data => {
                  this.moderator = data
                  this.fillFields(data)
                  this.buttonSpinner = false
                })
              } else {
                this.$store.commit('setError', { code: 876 })
                this.buttonSpinner = false
                this.$emit('validateFields')
              }
            } else {
              this.buttonSpinner = false
              this.isCheckExists = false
            }
          })
        }
      },
      fillFields (userData) {
        this.isCheckExists = false
        const { public: uData } = userData.data
        const fieldsKeys = Object.keys(this.fields)
        fieldsKeys.forEach(key => {
          if (uData[key])
            this.fields[key].setValues(true, uData[key])
        })
        this.fields.login.setValues(true, userData.login)
        if (!userData.isNew) {
          fieldsKeys.forEach(key => {
            this.fields[key]['disabled'] = true
            this.fields[key][key === 'CompanyNameShort' ? 'disabled' : 'inactive'] = false
            this.fields[key][key === 'CompanyNameShortEn' ? 'disabled' : 'inactive'] = false
          })
        } else this.$emit('validateFields')
      }
    },
    i18n: {
      messages: {
        en: {
          headings: {
            addModerator: 'Add a moderator',
            personal: 'Personal details',
            company: 'Company',
          },
          adding: 'Adding',
        },
        ru: {
          headings: {
            addModerator: 'Добавить модератора',
            personal: 'Личная информация',
            company: 'Организация',
          },
          adding: 'Добавляем',
        },
      }
    }
  }
</script>

<style scoped>
  .add-moderator .form {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px;
  }
  .form.down {
    align-items: flex-end;
  }
  .form .field {
    padding: 0 16px;
    min-width: 0;
    width: 50%;
    flex-basis: 50%;
  }
  .actions .hse-Button {
    display: inline-block;
  }
  .checkboxes {
    display: flex;
    justify-content: space-between;
  }
  .form-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .form-description {
    padding-top: 12px;
    font-size: 14px;
    line-height: 20px;
    color: #6B7A99;
  }
  .flex-tooltip {
    display: flex;
  }
  .flex-tooltip>div:first-child {
    width: calc(100% - 28px)
  }
  .flex-tooltip>div:last-child {
    padding: 36px 0 0 12px;
  }
  @media (max-width: 1024px) {
    .register {
      margin: 16px auto;
      max-width: 95%;
    }
    .emptyfield {
      display: none;
    }
    .checkboxes {
      flex-direction: column;
    }
  }
  @media (max-width: 768px) {
    .form .field {
      width: 100%;
      flex-basis: 100%;
    }
    .form-footer {
      flex-direction: column;
      align-items: flex-start;
    }
  }
</style>
<style>
  .smallLabel {
    font-size: 14px !important;
  }
  @media (max-width: 768px) {
    .hse-Input_disabled input:disabled {
      opacity: 1 !important;
      color: #000 !important;
    }
  }
</style>