<template>
  <div>
    <HseModalForm v-model="isOpen" :title="$t(!this.isTextVerification ? 'select' : 'checkText')">
      <div class='big-item'>
        <Validator v-bind:field="fields.StatusSelect" v-if="!this.isTextVerification" @change="checkStatus" />
        <HseSpace size='small_2x' vertical />
        
        <!-- sentForRework -->
        <div v-if="this.fields.StatusSelect.value === '96F18960-F327-419C-B1FA-D4CFA3FD5103'">
          <Validator v-bind:field="fields.comment" :readonly="this.isTextVerification" />
        </div>
        
        <!-- rejected -->
        <div v-if="this.fields.StatusSelect.value === '26221A69-FE87-4A64-8D98-080B02C04169'">
          <HseTooltip :content="this.isTextVerification ? '' : $t('textTooltip')"
            :variant="this.isTextVerification ? 'invert' : 'brand'">
            <Validator v-if="fields.StatusSelect.value === '26221A69-FE87-4A64-8D98-080B02C04169'"
              v-bind:field="fields.comment" :readonly="this.isTextVerification" />
          </HseTooltip>
        </div>
      </div>
      <div class="actions">
        <HseButton v-if="this.isTextVerification" @click="cancel">
          {{ $t('cancel') }}
        </HseButton>
        <HseButton :spinner="isLoading" @click="send">
          {{ $t('send') }}
        </HseButton>
      </div>
    </HseModalForm>
  </div>
</template>

<script>

import { HseModalForm, HseButton, HseSpace, HseTooltip } from '@hse-design/vue'
import Validator from '../common/form/Validator.vue'
import fields from './managerFields'
import { mapState } from 'vuex'
import { localizeFields } from '../../i18n'

export default {
  props: [
    'commentMessage'
  ],
  components: {
    HseModalForm,
    HseButton,
    HseSpace,
    Validator,
    HseTooltip,
  },
  data: () => ({
    isOpen: false,
    isLoading: false,
    isTextVerification: false,
    fields
  }),
  created() {
    this.translateFields()
    this.$parent.$on('openStatusModal', () => { this.isOpen = true })
  },
  methods: {
    send() {
      if (this.fields.StatusSelect.value === '26221A69-FE87-4A64-8D98-080B02C04169' || this.fields.StatusSelect.value === '96F18960-F327-419C-B1FA-D4CFA3FD5103') {
        if (this.fields.StatusSelect.value === '26221A69-FE87-4A64-8D98-080B02C04169') {
          if (this.isTextVerification === false) {
            this.isTextVerification = true
          }
          else {
            this.$root.$refs.validator.validationInModal()
            if (this.fields.comment.isValid) {
              this.isLoading = true;
              this.$emit('openStatusModalResponse', this.fields.StatusSelect.value, this.fields.comment.value)

              setTimeout(() => {
                this.isOpen = false;
                this.isLoading = false;
              }, 200);
            }
          }
        }
        else {
          this.$root.$refs.validator.validationInModal()
          if (this.fields.comment.isValid) {
            this.isLoading = true;
            this.$emit('openStatusModalResponse', this.fields.StatusSelect.value, this.fields.comment.value)

            setTimeout(() => {
              this.isOpen = false;
              this.isLoading = false;
            }, 200);
          }
        }
      } else {
        this.isLoading = true;
        this.$emit('openStatusModalResponse', this.fields.StatusSelect.value)

        setTimeout(() => {
          this.isOpen = false;
          this.isLoading = false;
        }, 200);
      }
    },
    cancel() {
      this.isTextVerification = false
    },
    translateFields() {
      this.fields = localizeFields(fields, this.fields)
    },
    checkStatus(value) {
      if (value === '26221A69-FE87-4A64-8D98-080B02C04169') {
        this.fields.comment.value = this.commentMessage
      }
      else {
        console.log('checkStatus else')
        this.fields.comment.value = null
      }
    }
  },
  computed: mapState(['lang']),
  watch: {
    lang() {
      this.translateFields()
    },
    isOpen() {
      this.isTextVerification = false
      this.fields.StatusSelect.value = ''
    },
  },
  i18n: {
    messages: {
      en: {
        send: 'Send',
        select: 'Select a status',
        checkText: 'Check the text of the letter',
        cancel: 'Cancel',
      },
      ru: {
        send: 'Отправить',
        select: 'Выберите статус',
        checkText: 'Проверьте текст письма',
        cancel: 'Отмена',
        textTooltip: 'Можно оставить один из предложенных вариантов текста уведомления (развернуть поле Комментарий можно потянув за правый нижний угол) или заменить на свой. Текст письмо носит рекомендательный характер. Рекомендуется письмо-отказ подписать.',
      },
    }
  }
}
</script>

<style scoped>
.event-card {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 32px;
  max-width: 800px;
  margin: 52px auto;
}

.big-item {
  min-height: 400px;
}

.link {
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.two-cols {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.three-cols {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
}

.prepull {
  overflow: auto;
}

.pull-right {
  float: right;
}

.actions {
  flex-wrap: wrap;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
}
</style>