<template>
  <div v-if="!confirmationStatus" class="report">
    <HseHeading size="h3">{{ $t("headings.confirm") }}</HseHeading>
    <HseSpace size="small" vertical />
    <div class="colsNew">
      <div class="field fileUploader_item">
        <HseSpace size="small" vertical />
        <FileUploader :field="fields.Passport" />
        <!-- <div class="hse-RichInput__message">Необходимо выбрать файл</div> -->
      </div>
      <div class="field">
        <Validator v-bind:field="fields.isAgree" />
        <!-- <div class="hse-RichInput__message">Необходимо ваше согласие</div> -->
        <span class="link" v-on:mouseup="() => (open = true)">{{
          $t("pd")
        }}</span>
        <HseSpace size="small_2x" vertical />
      </div>
    </div>
    <HseSpace size="small" vertical />
    <div class="buttonGroup">
      <HseButton
        variant="primary"
        size="medium"
        v-on:click="() => closeModalWindow(true)"
      >
        <span>{{ $t("send") }}</span>
      </HseButton>
      <HseSpace size="small" horizontal />
      <HseButton
        variant="secondary"
        size="medium"
        v-on:click="() => closeModalWindow(false)"
      >
        <span>{{ $t("cancel") }}</span>
      </HseButton>
    </div>
    <HseModal v-model="open">
      <div>
        <Approval></Approval>
        <HseButton :style="{ marginLeft: 'auto' }" @click="open = false"
          >OK</HseButton
        >
      </div>
    </HseModal>
  </div>
</template>
<script>
import { HseHeading, HseSpace, HseButton, HseModal } from "@hse-design/vue";
import Validator from "../common/form/Validator.vue";
import FileUploader from "../common/form/FileUploader.vue";
import Approval from "../approval/Approval.vue";
export default {
  components: {
    HseHeading,
    HseSpace,
    HseButton,
    Validator,
    FileUploader,
    HseModal,
    Approval,
  },
  data: () => ({
    open: false,
    buttonSpinner: false,
  }),
  props: ["fields"],
  methods: {
    closeModalWindow(val) {
      if (val) {
        this.$emit("validateFields");
        let isPageValid = !Object.keys(this.fields).some((key) => {
          return !this.fields[key].isValid;
        });
        if (isPageValid) {
          this.$emit("closeModal", val);
        }
      } else {
        this.$emit("closeModal", val);
      }
    },
  },
  i18n: {
    messages: {
      en: {
        headings: {
          confirm: "Attach a copy of your passport",
        },
        send: "Send",
        cancel: "Cancel",
        pd: "Personal data processing agreement",
      },
      ru: {
        headings: {
          confirm: "Прикрепить копию паспорта ",
        },
        send: "Отправить",
        cancel: "Отмена",
        pd: "Согласие на обработку персональных данных",
      },
    },
  },
};
</script>
<style>
.hse-RichInput__message {
  color: #ff564e !important;
}
.buttonGroup {
  display: flex;
  flex-direction: row-reverse;
}
.status {
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}
.colsNew {
  margin: 0 -15px;
  flex-wrap: wrap;
}
.colsNew > .field {
  padding: 0 15px;
  flex: 1;
}
</style>
