import { HseInput, HseSelect, HseCheckbox } from '@hse-design/vue'
import { resourceLoader } from '../resourceLoader'

const fields = {
  LName: {
    value: '',
    isValid: false,
    label: 'fields.name.lName',
    isRequired: true,
    size: 'large',
    component: HseInput,
    rules: [{
      name: 'cyrillic',
    }],
  },
  FName: {
    value: '',
    isValid: false,
    label: 'fields.name.fName',
    isRequired: true,
    size: 'large',
    component: HseInput,
    rules: [{
      name: 'cyrillic',
    }],
  },
  SName: {
    value: '',
    isValid: true,
    label: 'fields.name.mName',
    size: 'large',
    component: HseInput,
  },
  FNameEN: {
    value: '',
    isValid: false,
    label: 'fields.name.fNameEn',
    isRequired: true,
    size: 'large',
    component: HseInput,
    rules: [{
      name: 'latin',
    }],
  },
  LNameEN: {
    value: '',
    isValid: false,
    label: 'fields.name.lNameEn',
    isRequired: true,
    size: 'large',
    component: HseInput,
    rules: [{
      name: 'latin',
    }],
  },
  Gender: {
    value: '',
    isValid: false,
    label: 'fields.name.gender',
    isRequired: true,
    size: 'large',
    component: HseSelect,
    getAllOptions (search) {
      return resourceLoader.load('genderList', null, [], search)
    },
  },
  BirthDate: {
    value: '',
    isValid: false,
    label: 'fields.name.birthYear',
    isRequired: true,
    size: 'large',
    component: HseSelect,
    options: [ ...Array(new Date().getYear() - 14) ].map((_, i) => {
      const year = String(i + 1900)
      return { label: year, value: new Date(year).toISOString().split('T')[0] }
    }).reverse()
  },
  Phone: {
    value: '',
    isValid: false,
    label: 'fields.name.phone',
    isRequired: true,
    size: 'large',
    component: HseInput,
    rules: [{
      name: 'phone',
    }],
  },
  Citizen: {
    value: '',
    isValid: false,
    label: 'fields.name.citizen',
    isRequired: true,
    size: 'large',
    component: HseSelect,
    searchable: 'dropdown',
    getAllOptions (search) {
      return resourceLoader.load('countriesList', null, [], search)
    },
  },
  email: {
    value: '',
    isValid: false,
    label: 'fields.name.email',
    isRequired: true,
    size: 'large',
    component: HseInput,
    rules: [{
      name: 'email',
    }],
  },
  verification: {
    isValid: false,
    value: '',
    guid: '',
    setGuid (guid) {
      this.guid = guid
    }
  },
  // CountryId: {
  //   value: '',
  //   isValid: true,
  //   label: 'fields.name.country',
  //   isRequired: false,
  //   size: 'large',
  //   component: HseSelect,
  //   searchable: 'dropdown',
  //   getAllOptions (search) {
  //     return resourceLoader.load('countriesList', null, [], search)
  //   },
  // },
  // Region: {
  //   value: '',
  //   isValid: true,
  //   label: 'fields.name.region',
  //   isRequired: false,
  //   size: 'large',
  //   component: HseSelect,
  //   searchable: 'dropdown',
  //   getAllOptions (search) {
  //     return resourceLoader.load('regionsList', null, [], search)
  //   },
  // },
  // City: {
  //   value: '',
  //   isValid: true,
  //   label: 'fields.name.city',
  //   isRequired: false,
  //   size: 'large',
  //   component: HseInput,
  // },
  CompanyNameFull: {
    value: '',
    isValid: true,
    label: 'fields.name.companyFull',
    size: 'large',
    component: HseInput,
  },
  CompanyNameShort: {
    value: '',
    isValid: true,
    label: 'fields.name.companyShort',
    size: 'large',
    component: HseInput,
  },
  Position: {
    value: '',
    isValid: true,
    label: 'fields.name.position',
    size: 'large',
    component: HseInput,
  },
  isAgree: {
    value: false,
    isValid: false,
    hideTopLabel: true,
    label: 'fields.name.pdAgreement',
    isRequired: true,
    classes: { label: 'smallLabel' },
    component: HseCheckbox,
    rules: [{
      name: 'custom',
      message: 'fields.rule.pdAgreement',
      validateVal: value => value
    }],
  },
  Special: {
    value: false,
    isValid: true,
    hideTopLabel: true,
    label: 'fields.name.marketingAgreement',
    classes: { label: 'smallLabel' },
    component: HseCheckbox,
  },


}

Object.keys(fields).forEach((key) => {
  fields[key].setValues = function (isValid, value = '') {
    this.value = value
    this.isValid = isValid
  }
})

export default fields
