<template>
  <div class="wrapper">
    <TopNewsBanner />
    <ItemsGrid :data="items" :isLoading="isLoading" :types="types" :component="component" v-on:typeChange="changeType" />
  </div>
</template>

<script>
import { api } from "../api";
import ItemsGrid from "../components/common/ItemsGrid.vue";
import EventCard from "../components/eventsPage/EventCard.vue";
import { mapState } from "vuex";
import TopNewsBanner from "../components/TopNewsBanner/TopNewsBanner.vue";

export default {
  data: () => ({
    items: [],
    reqParams: { archive: false, offset: 0, limit: 100 },
    types: [
      { title: "types.events.current", id: "current" },
      { title: "types.events.archive", id: "archive" },
    ],
    component: EventCard,
    isLoading: false,
  }),
  components: {
    ItemsGrid,
    TopNewsBanner,
  },
  methods: {
    requestItems() {
      this.isLoading = true;
      api.request("eventListForUser", this.reqParams, []).then((data) => {
        this.items = data.reverse();
        this.isLoading = false;
      });
    },
    changeType(type) {
      this.reqParams.archive = type === "archive";
      this.requestItems();
    },
  },
  computed: mapState(["lang"]),
  watch: {
    lang() {
      this.requestItems();
    },
  },
};
</script>

<style scoped>
.wrapper {
  max-width: 1500px;
  width: calc(100vw - 192px);
  margin: 24px auto;
}

/* @media (max-width: 669px) {
  .wrapper {
    width: calc(100vw - 48px);
  }
}

@media (min-width: 669px) {
  .wrapper {
    width: calc(100vw - 320px);
  }
}
 */


/* @media (min: 667px) {
  .wrapper {
    width: calc(100vw - 320px);
  }
}

@media (min-width: 667px) {
  .wrapper {
    width: calc(100vw - 48px);
  }
} */

/* @media (min-width: 769px) {
  .wrapper {
    padding-left: 272px;
  }
} */
</style>
