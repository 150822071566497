<template>
    <div>
        <HseHeading size="h3">{{ $t('headings.title') }}</HseHeading>
        <HseSpace size='small' vertical />
        <div class="buttonBlock">
            <HseButton
                v-on:click="$emit('delEntry')"
            >
                <span>{{ $t('yes') }}</span>
            </HseButton>
            <HseButton
                variant='secondary'
                v-on:click="$emit('cancel')"
            >
                <span>{{ $t('cancel') }}</span>
            </HseButton>
        </div>
    </div>
</template>
<script>
    import { HseHeading, HseSpace, HseButton } from '@hse-design/vue'
    export default {
        components: {
            HseHeading,
            HseSpace,
            HseButton,
        },
        i18n: {
            messages: {
                en: {
                    headings: {
                        title: 'Are you sure you want to delete the entry?',
                    },
                    yes: 'yes',
                    cancel: 'cancel'
                },
                ru: {
                    headings: {
                        title: 'Вы действительно хотите удалить запись?',
                    },
                    yes: 'Да',
                    cancel: 'Отмена'
                },
            }
        }
    }
</script>
<style>
    .buttonBlock {
        display: flex;
        justify-content: space-between;
        width: fit-content;
    }
    .buttonBlock > button:first-child {
        margin-right: 20px;
    }
</style>