<template>
  <div class="register">
    <HseHeading size="h3">{{ $t('headings.register') }}</HseHeading>
    <HseSpace size='small' vertical />
    <div class="subheading">{{ $t('headings.personal') }}</div>
    <HseSpace size='small' vertical />
    <div class="form">
      <div class="two-cols">
        <div class="field">
          <Validator v-bind:field="fields.email" />
          <HseSpace class="nomobile" size='small_2x' vertical />
        </div>
        <div class="field">
          <VerificationCode :value="fields.email.value" :isValValid="fields.email.isValid" :field="fields.verification" />
          <HseSpace size='small_2x' vertical />
        </div>
      </div>
      <div class="emptyfield"></div>
      <div v-show="isLangRu" class="field">
        <Validator v-bind:field="fields.LName" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div v-show="isLangRu" class="field">
        <Validator v-bind:field="fields.FName" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div v-show="isLangRu" class="field">
        <Validator v-bind:field="fields.SName" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.FNameEN" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.LNameEN" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.Gender" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.Phone" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.BirthDate" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <div class="flex-tooltip">
          <Validator v-bind:field="fields.Citizen" @change="citizenChange" />
          <HseTooltip :content="$t('fields.placeholder.citizen')">
            <IconStatusInfo />
          </HseTooltip>
        </div>
        <HseSpace size='small_2x' vertical />
      </div>
    </div>
<!--    <HseSpace size='small' vertical />-->
<!--    <div class="subheading">{{ $t('headings.location') }}</div>-->
<!--    <HseSpace size='small' vertical />-->
<!--    <div class="form">-->
<!--      <div class="field">-->
<!--        <Validator v-bind:field="fields.CountryId" />-->
<!--      </div>-->
<!--      <div v-if="isCountryRu" class="field">-->
<!--        <Validator v-bind:field="fields.Region" />-->
<!--      </div>-->
<!--      <div class="field">-->
<!--        <Validator v-bind:field="fields.City" />-->
<!--      </div>-->
<!--    </div>-->
    <!-- <HseSpace size='small' vertical />
    <div class="subheading">{{ $t('headings.company') }}</div>
    <HseSpace size='small' vertical />
    <div class="form">
      <div class="field">
        <Validator v-bind:field="fields.CompanyNameFull" />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.CompanyNameShort" />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.Position" />
      </div>
    </div> -->
    <HseSpace size='small' vertical />
    <div class="checkboxes">
      <div class="field">
        <Validator v-bind:field="fields.Special" />
      </div>
      <HseSpace size='small_2x' vertical />
      <div class="field">
        <Validator v-bind:field="fields.isAgree" />
        <span class="link" v-on:click="showPersonal"> {{ $t('pd') }} </span>
      </div>
    </div>
    <HseSpace size='medium' vertical />
    <div class="form-footer">
      <div class="actions">
        <HseButton
          v-on:click="registerReq"
          :spinner="registerButtonSpinner"
          spinnerPosition="left"
        >
          <span v-if="registerButtonSpinner === false">{{ $t('auth.register') }}</span>
          <span v-else-if="registerButtonSpinner === true">{{ $t('registering') }}</span>
          <span v-else-if="registerButtonSpinner === 'done'">{{ $t('auth.btnDone') }}</span>
        </HseButton>
        <HseButton variant="secondary" v-on:click="() => setState('loginOptions')">{{ $t('cancel') }}</HseButton>
      </div>
      <div class="form-description">{{ $t('fields.requiredFields') }}</div>
    </div>
    <PersonalDataModal />
  </div>
</template>

<script>
  import { HseHeading, HseSpace, HseButton, HseTooltip, IconStatusInfo } from '@hse-design/vue'
  import { store } from '../../../store'
  import { api } from '../../../api'
  import { mapState } from 'vuex'
  import Validator from '../form/Validator'
  import VerificationCode from '../form/VerificationCode'
  import registerFields from './registerFields'
  import PersonalDataModal from '../modal/PersonalDataModal.vue'
  import { localizeFields } from '../../../i18n'

  export default {
    store,
    components: {
      HseHeading,
      HseSpace,
      HseButton,
      HseTooltip,
      IconStatusInfo,
      Validator,
      VerificationCode,
      PersonalDataModal,
    },
    data: () => ({
      fields: registerFields,
      registerButtonSpinner: false,
    }),
    created () {
      this.translateFields()
    },
    computed: {
      isLangRu () {
        return this.$store.state.lang === 'ru'
      },
      isCitizenRu () {
        return this.fields.Citizen.value === 'BC763F4B-FBD9-461B-B395-166020E8C026'
      },
      // isCountryRu () {
      //   return this.fields.CountryId.value === 'BC763F4B-FBD9-461B-B395-166020E8C026'
      // },
      fieldsToValidate () {
        return Object.keys(this.fields).filter(key => {
          // if (key === 'Region' && !this.isCountryRu) return false
           if (['LName', 'FName', 'SName'].includes(key) && !this.isLangRu) return false
          return true
        })
      },
      ...mapState(['lang'])
    },
    watch: {
      lang () {
        this.translateFields()
      }
    },
    methods: {
      citizenChange () {
        registerFields.SName.isRequired = this.isCitizenRu
        this.fields.SName.isRequired = this.isCitizenRu
        if (this.isCitizenRu && !this.fields.SName.label.includes('*')) {
          this.fields.SName.label = `${this.fields.SName.label.trim()} *`
        } else if (!this.isCitizenRu && this.fields.SName.label.includes('*')) {
          this.fields.SName.label = this.fields.SName.label.slice(0, this.fields.SName.label.length - 2)
        }
      },
      translateFields () {
        this.fields = localizeFields(registerFields, this.fields)
      },
      showPersonal () {
        this.$emit('openPersonslDataModal')
      },
      registerReq () {
        this.$emit('validateFields')
        let isFormInvalid = false
          //let skip = [ 'CountryId', 'Region', 'City'];

        this.fieldsToValidate.some(key => {
          isFormInvalid = this.fields[key].isValid === false
          return isFormInvalid
        })

        if (isFormInvalid) return

        this.registerButtonSpinner = true

        if (this.lang === 'en') {
          this.fields.LName.value = this.fields.LNameEN.value
          this.fields.FName.value = this.fields.FNameEN.value
        }

        const data = { public: {lang:this.$store.state.lang} }
        Object.keys(this.fields).forEach(key => {
          if (['email', 'verification'].includes(key)) return

          const field = this.fields[key]
          data.public[key] = field.value
        })
        const params = {
          data,
          lang: this.$store.state.lang,
          code: this.fields.verification.guid
        }
        api.auth('user.registration.finish', params).then(result => {
          this.registerButtonSpinner = 'done'
          setTimeout(() => {
            this.$router.push(`/restorePassword?code=${result.code}`)
            this.registerButtonSpinner = false
          }, 2000)
        })
      },
      setState(state) {
        this.$emit('changeState', { state })
      },
    },
    i18n: {
      messages: {
        en: {
          headings: {
            register: 'Registration',
            personal: 'Personal details',
            location: 'Location',
            company: 'Company',
          },
          registering: 'Registering',
          pd: 'Personal data processing agreement'
        },
        ru: {
          headings: {
            register: 'Регистрация',
            personal: 'Личная информация',
            location: 'Местонахождение',
            company: 'Организация',
          },
          registering: 'Регистрируемся',
          pd: 'Согласие на обработку персональных данных',
        },
      }
    }
  }
</script>

<style scoped>
  .register {
    background: #FFFFFF;
    border-radius: 12px;
    margin: 53px auto;
    width: fit-content;
    padding: 32px 32px;
    width: 960px;
  }
  .register .form {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px;
  }
  .register .form>div {
    width: 33.333%;
    padding: 0 16px;
    min-width: 0;
  }
  .two-cols {
    width: calc(66.666% + 32px)!important;
    display: flex;
    margin: 0 -16px;
    flex-wrap: wrap;
  }
  .two-cols>div {
    width: 50%;
    padding: 0 16px;
    min-width: 0;
  }
  .subheading {
    font-family: 'Work Sans', sans-serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #0F0F14;
  }
  /* .actions .hse-Button {
    display: inline-block;
  } */
  .actions {
    display: flex;
  }
  .hse-Button:first-child {
    margin-right: 12px;
  }
  .link {
    color: blue;
    font-size: 80%;
    cursor: pointer;
  }
  .form-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .form-description {
    padding-top: 12px;
    font-size: 14px;
    line-height: 20px;
    color: #6B7A99;
  }
  .flex-tooltip {
    display: flex;
  }
  .flex-tooltip>div:first-child {
    width: calc(100% - 28px)
  }
  .flex-tooltip>div:last-child {
    padding: 36px 0 0 12px;
  }
  .flex-tooltip.tt-checkbox>div:first-child {
    width: auto;
  }
  .flex-tooltip.tt-checkbox>div:last-child {
    padding-top: 0;
    margin-top: -6px;
  }
  @media (max-width: 1023px) {
    .register {
      margin: 16px auto;
      max-width: 95%;
    }
    .register .form>div {
      width: 50%;
    }
    .two-cols {
      width: calc(100% + 32px) !important;
    }
    .emptyfield {
      display: none;
    }
  }
  @media (max-width: 767px) {
    .register {
      margin: 0 auto;
      max-width: 100%;
    }
    .register .form>div {
      width: 100%;
    }
    .two-cols>div {
      width: 100%;
    }
    .form-footer {
      flex-direction: column;
      align-items: flex-start;
    }
    .nomobile {
      display: none;
    }
  }
</style>
<style>
  .smallLabel {
    font-size: 14px !important;
  }
</style>
