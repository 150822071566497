<template>
  <div class="event-card">
    <HseHeading size="h3">{{ $t('header') }}</HseHeading>
    <HseSpace size='small' vertical />

    <Validator v-bind:field="fields.event" @change="setEvent" />
    <HseSpace size='small_2x' vertical />

    <Validator v-bind:field="fields.Topic" :key="fields.Topic.eventId" @change="setTopic" />
    <HseSpace size='small_2x' vertical />

    <HseSpace size='small' vertical />
    <a v-if="this.event_id != null && this.topic_id != null" class="link" target="_blank"
      :href="`/files/collective_request_report_by_topic?event_id=${this.event_id}&topic_id=${this.topic_id}`">Сформировать
      отчет по коллективным заявкам</a>
    <HseSpace size='small_2x' vertical />
    <a v-if="this.event_id != null && this.topic_id != null" class="link" target="_blank"
      :href="`/files/score_by_topic.xlsx?event_id=${this.event_id}&topic_id=${this.topic_id}`">Сформировать полный
      отчет</a>
    <HseSpace size='small_2x' vertical />
    <a v-if="this.event_id != null && this.topic_id != null" class="link" target="_blank"
      :href="`/files/score_by_topic.xlsx?event_id=${this.event_id}&topic_id=${this.topic_id}&short=true`">Сформировать
      сокращенный отчет</a>
  </div>
</template>

<script>

import { HseHeading, HseSpace, } from '@hse-design/vue'
import Validator from '../components/common/form/Validator.vue'
import fields from '../components/reportsPage/report1Fields'
import { mapState } from 'vuex'
import { localizeFields } from '../i18n'

export default {
  components: {
    HseHeading,
    HseSpace,
    Validator,
  },
  data: () => ({
    event_id: null,
    topic_id: null,
    isDataLoaded: true,
    fields,
  }),
  created() {
    this.translateFields()
  },
  methods: {
    setEvent(event) {
      this.fields.Topic.setEvent(event);
      this.event_id = event;
    },
    setTopic(topic) {
      this.topic_id = topic;
    },
    translateFields() {
      this.fields = localizeFields(fields, this.fields)
    }
  },
  computed: mapState(['lang']),
  watch: {
    lang() {
      this.translateFields()
    },
  },
  i18n: {
    messages: {
      en: {
        header: 'Report',
      },
      ru: {
        header: 'Отчет',
      },
    }
  }
}
</script>

<style scoped>
.event-card {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 32px;
  max-width: 800px;
  margin: 52px auto;
  width: calc(100vw - 320px);
}

@media (max-width: 767px) {
  .event-card {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }

  /* .cols-2 {
    display: block;
  } */
}

.big-item {
  max-width: 780px;
}

.link {
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.cols-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
}

.prepull {
  overflow: auto;
}

.pull-right {
  float: right;
}</style>