var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"session"},[_c('section',{staticClass:"session__info"},[(_vm.session.Items.length && _vm.isFavorite)?_c('button',{staticClass:"favorites-button favorites-button--remove",on:{"click":function($event){return _vm.removeFavorites()}}},[_c('IconStatusStarFilled',{attrs:{"size":"medium"}})],1):(_vm.session.Items.length)?_c('button',{staticClass:"favorites-button favorites-button--add",on:{"click":function($event){return _vm.addFavorites()}}},[_c('IconStatusStarLinear',{attrs:{"size":"medium"}})],1):_vm._e(),_c('section',{staticClass:"info__text"},[(_vm.session.BroadCastLink)?_c('a',{staticClass:"broadcast-link",attrs:{"href":_vm.session.BroadCastLink}},[_c('IconCamera')],1):_vm._e(),_c('p',[_vm._v(" "+_vm._s(_vm.$i18n.locale === "ru" ? _vm.datesMap.get(_vm.date.Date).DayRU : _vm.datesMap.get(_vm.date.Date).DayEN)+" ")]),_c('p',[_vm._v(_vm._s(_vm.session.StartTime)+" - "+_vm._s(_vm.session.EndTime)+" (UTC +3)")]),_c('p',[_vm._v(_vm._s(_vm.session.Format))]),(_vm.session.LangRu || _vm.session.LangEn)?_c('section',{staticClass:"text__translation"},[_c('div',{staticClass:"translation__languages"},[(_vm.session.LangRu)?_c('p',[_vm._v("RUS")]):_vm._e(),(_vm.session.LangEn)?_c('p',[_vm._v("ENG")]):_vm._e(),(_vm.session.IsTranslation)?_c('IconHeadphones'):_vm._e()],1)]):_vm._e(),(_vm.hasPaid)?_c('section',{staticStyle:{"display":"flex","gap":"0.5em","flex-direction":"column"}},[(
            (_vm.session.Format === _vm.$t('online') ||
              _vm.session.Format === _vm.$t('hybrid') ||
              _vm.session.Format === _vm.$t('blended')) &&
            _vm.session.BroadCastLink
          )?_c('a',{attrs:{"href":_vm.session.BroadCastLink}},[_vm._v(" "+_vm._s(_vm.$t("connectionLink"))+" ")]):_vm._e(),(
            (_vm.session.Format === _vm.$t('online') ||
              _vm.session.Format === _vm.$t('hybrid') ||
              _vm.session.Format === _vm.$t('blended')) &&
            _vm.session.ZoomMeetingUrl
          )?_c('a',{attrs:{"href":_vm.session.ZoomMeetingUrl}},[_vm._v(" "+_vm._s(_vm.$t("streamLink"))+" ")]):_vm._e(),(_vm.session.ShowRooms)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.highlightSearchResults(_vm.session.RoomTitle, _vm.searchTerm))}}):_vm._e()]):_vm._e(),(_vm.session.logoOrganizerId != null)?_c('div',[_c('SectionsSessionLogoVue',{attrs:{"id":this.lang === 'ru'
              ? _vm.session.logoOrganizerId
              : _vm.session.logoOrganizerEnId}})],1):_vm._e()])]),_c('section',{staticClass:"session__main"},[_c('div',{staticClass:"div_title"},[_c('h4',[_vm._v(_vm._s(_vm.session.Type)+" "+_vm._s(_vm.section.Code)+"-"+_vm._s(_vm.session.Code)+".")]),(
          _vm.session.TypeId !== _vm.sessionTypeId &&
          _vm.session.TypeId !== _vm.roundtableTypeId &&
          _vm.session.Items[0]
        )?_c('h4',{domProps:{"innerHTML":_vm._s(
          _vm.highlightSearchResults(_vm.session.Items[0].Rapporteur, _vm.searchTerm)
        )}}):_vm._e(),_c('h4',{domProps:{"innerHTML":_vm._s(' ' + _vm.highlightSearchResults(_vm.session.Title, _vm.searchTerm))}})]),(
        (_vm.session.TypeId === _vm.sessionTypeId ||
          _vm.session.TypeId === _vm.roundtableTypeId) &&
        _vm.session.Manager
      )?_c('div',{staticClass:"div_manager"},[(
          (_vm.session.TypeId === _vm.sessionTypeId ||
            _vm.session.TypeId === _vm.roundtableTypeId) &&
          _vm.session.Manager
        )?_c('p',{staticClass:"main__manager",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.$tc("manager", _vm.session.Manager.split(", ").length))+":"+_vm._s("\u00A0")+" ")]):_vm._e(),(
          (_vm.session.TypeId === _vm.sessionTypeId ||
            _vm.session.TypeId === _vm.roundtableTypeId) &&
          _vm.session.Manager
        )?_c('p',{staticClass:"main__manager",domProps:{"innerHTML":_vm._s(_vm.highlightSearchResults(_vm.session.Manager, _vm.searchTerm))}}):_vm._e()]):(_vm.session.Items[0] && _vm.session.Items[0].Manager)?_c('div',{staticStyle:{"display":"flex"}},[_c('p',{staticClass:"main__manager"},[_vm._v(" "+_vm._s(_vm.$tc("manager", _vm.session.Items[0].Manager.split(", ").length))+": ")]),_c('p',{staticClass:"main__manager",domProps:{"innerHTML":_vm._s(
          '&ensp;' +
          _vm.highlightSearchResults(_vm.session.Items[0].Manager, _vm.searchTerm)
        )}})]):_vm._e(),_c('ul',{staticClass:"main__items list-style-none"},_vm._l((_vm.session.Items),function(item){return _c('li',{key:item.Id,class:{ 'item--found': item.IsWordFound }},[(_vm.session.TypeId === _vm.sessionTypeId)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.highlightSearchResults(item.Authors, _vm.searchTerm))}}):_vm._e(),(_vm.session.TypeId === _vm.sessionTypeId)?_c('div',{staticStyle:{"display":"flex","gap":"0.5em"}},[(_vm.session.TypeId === _vm.sessionTypeId)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.highlightSearchResults(item.Title, _vm.searchTerm))}}):_vm._e(),(_vm.session.TypeId === _vm.sessionTypeId)?_c('p',[_vm._v(" ("),_c('a',{attrs:{"href":item.Annotation}},[_vm._v(_vm._s(_vm.$t("annotation")))]),(
                _vm.hasPaid &&
                _vm.session.TypeId === _vm.sessionTypeId &&
                item.Presentation
              )?_c('span',[_vm._v(", ")]):_vm._e(),(
                _vm.hasPaid &&
                _vm.session.TypeId === _vm.sessionTypeId &&
                item.Presentation
              )?_c('a',{attrs:{"href":item.Presentation}},[_vm._v(_vm._s(_vm.$t("presentation")))]):_vm._e(),_vm._v(") ")]):_vm._e()]):(_vm.session.TypeId === _vm.roundtableTypeId)?_c('div',{staticClass:"roundtable"},[_c('section',{staticClass:"roundtable__issues"},[_c('h5',[_vm._v(_vm._s(_vm.$t("issuesForDiscussion"))+":")]),_c('div',{staticClass:"text-pre-line",domProps:{"innerHTML":_vm._s(_vm.highlightSearchResults(item.Discussion, _vm.searchTerm))}})]),_c('div',{staticClass:"div_manager"},[_c('p',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.$t("experts"))+":"+_vm._s("\u00A0")+" ")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.highlightSearchResults(item.Participants, _vm.searchTerm))}})])]):_c('p',{domProps:{"innerHTML":_vm._s(item.Annotation)}})])}),0),(
        (_vm.session.TypeId === _vm.sessionTypeId ||
          _vm.session.TypeId === _vm.roundtableTypeId) &&
        _vm.session.Debutant
      )?_c('div',{staticClass:"div_manager"},[(
          (_vm.session.TypeId === _vm.sessionTypeId ||
            _vm.session.TypeId === _vm.roundtableTypeId) &&
          _vm.session.Debutant
        )?_c('p',{staticClass:"main__debutant",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.$tc("debutant", _vm.session.Debutant.split(", ").length))+":"+_vm._s("\u00A0")+" ")]):_vm._e(),(
          (_vm.session.TypeId === _vm.sessionTypeId ||
            _vm.session.TypeId === _vm.roundtableTypeId) &&
          _vm.session.Debutant
        )?_c('p',{staticClass:"main__debutant",domProps:{"innerHTML":_vm._s(_vm.highlightSearchResults(_vm.session.Debutant, _vm.searchTerm))}}):_vm._e()]):_vm._e(),_c('div')])])}
var staticRenderFns = []

export { render, staticRenderFns }