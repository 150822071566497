<template>
  <div class="page">
    <RoleModal />
    <LeftMenu />
    <div class="wrappers">
      <router-view v-if="$store.state.user"></router-view>
    </div>
    <!-- <router-view v-if="$store.state.user"></router-view> -->
  </div>
</template>

<script>
import LeftMenu from './leftMenu/LeftMenu.vue'
import RoleModal from './modal/RoleModal.vue'
export default {
  components: {
    LeftMenu,
    RoleModal,
  },
  mounted() {
    let params = (new URL(window.location)).searchParams
    if (this.$store.state.user && params.has('download_file')) {
      window.open(`/files/download_file?id=${params.get('download_file')}`, '_blank')
      window.history.pushState({}, '', '/')
    }

    if (this.$store.state.role === 0) {
      this.$root.$refs.roleModal.open();
    }
  },
}
</script>

<style scoped>
@media (min-width: 769px) {
  .wrappers {
    padding-left: 272px;
  }
}

@media (max-width: 769px) {
  .wrapper {
    width: calc(100vw - 48px);
  }
}

@media (min-width: 769px) {
  .wrapper {
    width: calc(100vw - 320px);
  }
}
</style>