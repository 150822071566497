<template>
  <div class="wrapper">
    <ItemsGrid :data="items" :types="types" :isLoading="isLoading" :component="component" v-on:typeChange="changeType" />
  </div>
</template>

<script>
import { api } from '../api'
import ItemsGrid from '../components/common/ItemsGrid.vue'
import requestCard from '../components/CollectiveRequestPage/CollectiveRequestCard.vue'
import { mapState } from 'vuex'

export default {
  data: () => ({
    reqParams: { isArchive: false, offset: 0, limit: 100 },
    items: [],
    types: [
      { title: 'types.collectiveRequests.current', id: 'current' },
      { title: 'types.collectiveRequests.archive', id: 'archive' },
    ],
    component: requestCard,
    isLoading: false,
  }),
  components: {
    ItemsGrid,
  },
  methods: {
    requestItems() {
      this.isLoading = true
      api.request('collective_request_list', this.reqParams, []).then(data => {
        this.items = data.reverse()
        this.isLoading = false
      })
    },
    changeType(type) {
      this.reqParams.isArchive = type === 'archive'
      this.requestItems()
    },
  },
  computed: mapState(['lang']),
  watch: {
    lang() {
      this.requestItems()
    }
  },
}
</script>

<style scoped>
.wrapper {
  max-width: 1500px;
  width: calc(100vw - 192px);
  margin: 24px auto;
}

@media (max-width: 1024px) {
  .wrapper {
    width: calc(100vw - 48px)
  }
}
</style>
