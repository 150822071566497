<template>
  <div class="add-moderator">
    <HseHeading size="h3">{{
      $t(
        this.moderator ? "headings.editableModerator" : "headings.addModerator"
      )
    }}</HseHeading>
    <HseSpace size="small" vertical />
    <div v-show="isCheckExists" class="check">
      <div class="field">
        <Validator :field="fields.email" @change="toggleError" />
        <HseSpace size="small_2x" vertical />
      </div>

      <div class="form-footer">
        <div class="actions">
          <HseButton v-on:click="checkEmail" :spinner="buttonSpinner" spinnerPosition="left">
            <span v-if="buttonSpinner === false">{{
              $t("headings.addModerator")
            }}</span>
            <span v-else-if="buttonSpinner === true">{{ $t("adding") }}...</span>
            <span v-else-if="buttonSpinner === 'done'">{{
              $t("auth.btnDone")
            }}</span>
          </HseButton>
        </div>
        <div class="form-description">{{ $t("fields.requiredFields") }}</div>
      </div>
    </div>
    <div v-show="!isCheckExists" class="the-form">
      <div class="subheading">{{ $t("headings.personal") }}</div>
      <HseSpace size="small" vertical />
      <div class="form">
        <div v-if="isLangRu" class="field">
          <Validator :field="fields.lastName" />
          <HseSpace size="small_2x" vertical />
        </div>
        <div class="field">
          <Validator :field="fields.lastNameEn" />
          <HseSpace size="small_2x" vertical />
        </div>
        <div v-if="isLangRu" class="field">
          <Validator :field="fields.firstName" />
          <HseSpace size="small_2x" vertical />
        </div>
        <div class="field">
          <Validator :field="fields.firstNameEn" />
          <HseSpace size="small_2x" vertical />
        </div>
        <div v-if="isLangRu" class="field">
          <Validator :field="fields.secondName" />
          <HseSpace size="small_2x" vertical />
        </div>
        <div class="field">
          <Validator :field="fields.countryId" @change="changeCountryId" />
          <HseSpace size="small_2x" vertical />
        </div>
        <div class="field">
          <Validator :field="fields.email" @change="toggleError" />
        </div>
      </div>
      <div class="colsNew" v-show="!fields.HSEMember.value && IsRequiredPassportAll">
        <div class="field fileUploader_item">
          <HseSpace size="small" vertical />
          <FileUploader :field="fields.Passport" />
        </div>
        <div class="field">
          <Validator v-bind:field="fields.isAgree" />
          <span class="link" v-on:mouseup="() => (open = true)">{{
            $t("pd")
          }}</span>
          <HseSpace size="small_2x" vertical />
        </div>
      </div>
      <HseSpace size="small" vertical />
      <div class="subheading">{{ $t("headings.company") }}</div>
      <HseSpace size="small" vertical />
      <div class="form">
        <div class="field">
          <Validator @change="setAffiliated" :field="fields.HSEMember" />
          <HseSpace size="small_2x" vertical />
        </div>
        <div class="field"></div>
        <div class="field" v-show="fields.HSEMember.value">
          <Validator :field="fields.campusId" @change="setCampus" />
          <HseSpace size="small_2x" vertical />
        </div>
      </div>
      <div class="form">
        <div v-if="isLangRu" class="field">
          <Validator :field="fields.organizationName" />
          <HseSpace size="small_2x" vertical />
        </div>
        <div class="field">
          <Validator :field="fields.organizationNameEn" />
          <HseSpace size="small_2x" vertical />
        </div>
      </div>
      <!-- <div class="form">
        <div class="field">
          <Validator @change="setAffiliated" :field="fields.HSEMember" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field"></div>
        <div class="field" v-show="fields.HSEMember.value">
          <Validator :field="fields.campusId" @change="setCampus" />
          <HseSpace size='small_2x' vertical />
        </div>
      </div> -->
      <!-- <RadioGroup v-if="isMixed" :label="$t('participationFormatId')" :options="fields.participationFormatId.options"
        @change="setFormat" :defaultValue="fields.participationFormatId.value" />
      <HseSpace size='medium' vertical /> -->
      <div class="form-footer">
        <div class="actions">
          <HseButton v-on:click="registerReq" :spinner="buttonSpinner" spinnerPosition="left">
            <span v-if="buttonSpinner === false">{{
              $t(this.moderator ? "save" : "headings.addModerator")
            }}</span>
            <span v-else-if="buttonSpinner === true">{{ $t(this.moderator ? "saving" : "adding") }}...</span>
            <span v-else-if="buttonSpinner === 'done'">{{
              $t("auth.btnDone")
            }}</span>
          </HseButton>
        </div>
        <div class="form-description">{{ $t("fields.requiredFields") }}</div>
      </div>
    </div>
    <HseModal v-model="open">
      <div>
        <Approval></Approval>
        <HseButton :style="{ marginLeft: 'auto' }" @click="open = false">OK</HseButton>
      </div>
    </HseModal>
  </div>
</template>

<script>
import { HseHeading, HseSpace, HseButton, HseModal } from "@hse-design/vue";
import { api } from "../../api";
import Validator from "../common/form/Validator.vue";
// import RadioGroup from '../common/form/RadioGroup.vue'
import fields from "./moderatorFields";
import { resourceLoader } from "../common/resourceLoader";
import { mapState } from "vuex";
import { localizeFields } from "../../i18n";
import FileUploader from "../common/form/FileUploader.vue";
import Approval from "../approval/Approval.vue";

export default {
  components: {
    HseHeading,
    HseSpace,
    HseButton,
    Validator,
    FileUploader,
    HseModal,
    Approval,
    // RadioGroup,
  },
  props: [
    "editableModerator",
    "added",
    "orgFormat",
    "showFormat",
    "IsRequiredPassport",
    "IsRequiredPassportRussia",
    "IsRequiredPassportOther",
  ],
  data: () => ({
    IsRequiredPassportAll: false,
    open: false,
    fields,
    buttonSpinner: false,
    isCheckExists: true,
    campus: { en: [], ru: [] },
    moderator: null,
    userId: "",
    compareRuEnFields: {
      lastName: "lastNameEn",
      firstName: "firstNameEn",
      organizationName: "organizationNameEn",
    },
    enabledFields: [
      "HSEMember",
      "countryId",
      "organizationName",
      "organizationNameEn",
      "campusId",
      "IsRequiredPassport",
      "IsRequiredPassportRussia",
      "IsRequiredPassportOther",
    ],
  }),
  created() {
    ["en", "ru"].forEach((lang) =>
      resourceLoader
        .load("campusList", { lang })
        .then((data) => (this.campus[lang] = data))
    );
    this.translateFields();
    this.fields.participationFormatId.getAllOptions();
    if (this.editableModerator) {
      this.moderator = this.editableModerator;
      this.fillFields(this.moderator);
      this.IsRequiredPassportAll = this.checkNeedPassport(
        this.fields.countryId.value
      );
    }
  },
  computed: {
    isLangRu() {
      return this.lang === "ru";
    },
    isMixed() {
      return this.orgFormat === "895BB6AB-A343-48DC-AA19-7EAB348C8C0D";
    },
    isMoscowCampus() {
      return (
        this.fields.campusId.value === "DFA00199-FB13-47BB-A69B-F01C6F525F71"
      );
    },
    ...mapState(["lang"]),
  },
  watch: {
    lang() {
      this.translateFields();
    },
  },
  methods: {
    changeCountryId(value) {
      this.IsRequiredPassportAll = this.checkNeedPassport(value);
    },
    checkNeedPassport(countryId) {
      const citizenIdRussia = "BC763F4B-FBD9-461B-B395-166020E8C026"; // Российская Федерация

      return (
        (this.IsRequiredPassport &&
          this.IsRequiredPassportRussia &&
          countryId === citizenIdRussia) ||
        (this.IsRequiredPassport &&
          this.IsRequiredPassportOther &&
          countryId !== citizenIdRussia)
      );
    },
    setFormat(val) {
      this.fields.participationFormatId.setValues(true, val);
    },
    setAffiliated() {
      if (!this.fields.HSEMember.value) {
        this.fields.organizationName.value = "";
        this.fields.organizationNameEn.value = "";
      }
    },
    setCampus(campus) {
      const currCampus = this.campus.ru.find((cmps) => cmps.value === campus);
      const currCampusEn = this.campus.en.find((cmps) => cmps.value === campus);

      if (currCampus)
        this.fields.organizationName.setValues(
          true,
          `НИУ ВШЭ${this.isMoscowCampus ? "" : ` - ${currCampus.label}`}`
        );

      if (currCampusEn)
        this.fields.organizationNameEn.setValues(
          true,
          `HSE University${this.isMoscowCampus ? "" : ` - ${currCampusEn.label}`
          }`
        );
    },
    toggleError(value) {
      const isErrSet =
        this.$store.state.error && this.$store.state.error.code === 876;
      const isAdded = this.added.some((coauth) => coauth.email === value);
      if (isAdded && !isErrSet) {
        if (this.editableModerator && this.editableModerator.email === value)
          return;
        this.$store.commit("setError", { code: 876 });
        this.$emit("validateFields");
      } else if (!isAdded && isErrSet) {
        this.$store.commit("setError", null);
        this.$emit("validateFields");
      }
    },
    translateFields() {
      this.fields = localizeFields(fields, this.fields);
    },
    registerReq() {
      this.$emit("validateFields");
      let isFormInvalid = false;
      const skip = this.isLangRu ? [] : Object.keys(this.compareRuEnFields);

      Object.keys(this.fields).some((key) => {
        if (
          (!this.fields.HSEMember.value && key === "campusId") ||
          skip.includes(key)
        )
          return false;

        if (!this.IsRequiredPassportAll && key === "Passport") {
          return false;
        }

        if (this.fields.HSEMember.value && key === "Passport") {
          return false;
        }

        if (this.fields.HSEMember.value && key === "isAgree") {
          return false;
        }

        if (!this.IsRequiredPassportAll && key === "isAgree") {
          return false;
        }


        isFormInvalid = this.fields[key].isValid === false;
        return isFormInvalid;
      });

      if (isFormInvalid) return;

      this.buttonSpinner = true;
      const params = {
        lastName:
          this.lang === "ru"
            ? this.fields.lastName.value
            : this.fields.lastNameEn.value,
        firstName:
          this.lang === "ru"
            ? this.fields.firstName.value
            : this.fields.firstNameEn.value,
        secondName: this.fields.secondName.value,
        lastNameEn: this.fields.lastNameEn.value,
        firstNameEn: this.fields.firstNameEn.value,
        email: this.fields.email.value,
        organizationName:
          this.lang === "ru"
            ? this.fields.organizationName.value
            : this.fields.organizationNameEn.value,
        organizationNameEn: this.fields.organizationNameEn.value,
        HSEMember: this.fields.HSEMember.value,
        campusId:
          this.fields.campusId.value === "" ? null : this.fields.campusId.value,
        participationFormatId: this.fields.participationFormatId.value,
        countryId:
          this.fields.countryId.value === ""
            ? null
            : this.fields.countryId.value,
        PassportId:
          this.fields.HSEMember.value && this.IsRequiredPassportAll
            ? null
            : this.fields.Passport.value,
      };
      if (this.isMixed) {
        params.participationFormatId = this.fields.participationFormatId.value;
      } else {
        params.participationFormatId = this.orgFormat;
      }
      if (this.moderator && this.moderator.id !== this.userId) {
        params.id = this.moderator.id ?? null;
      }
      this.buttonSpinner = "done";
      setTimeout(() => {
        this.buttonSpinner = false;
        this.$emit("saved", params);
      }, 2000);
      return;
    },
    checkEmail() {
      this.$emit("validateFields");

      if (this.fields.email.isValid) {
        this.buttonSpinner = true;

        // const params =

        // api.request('check_program_honorary_paper_manager_email', this.fields.email.value).then(data => {
        //   console.log(data, 'data check hor')
        //   if (data.length != 0) {
        //     this.moderator = data
        //     this.fillFields(data)
        //     this.buttonSpinner = false

        //   } else {
        //     this.$store.commit('setError', { code: 876 })
        //     this.buttonSpinner = false
        //     this.$emit('validateFields')
        //   }
        // })
        api
          .auth("user.manage.check.login", this.fields.email.value)
          .then((result) => {

            const Email = this.fields.email.value;
            const EventId = this.$route.query.event;

            api
              .request("get_passport", { EventId, Email })
              .then((data) => {
                const PassportId = data?.PassportId;

                if (PassportId) {
                  this.fields.Passport.setValues(true, "");
                  this.fields.Passport.setValues(true, PassportId);
                }

                this.$emit("validateFields");
              });

            if (result) {
              this.userId = result;
              const isAdded = this.added.some((item) => item.id === result);

              if (!isAdded) {
                api.auth("user.manage.get", result).then((data) => {
                  this.moderator = data;
                  this.fillFields(data);
                  this.buttonSpinner = false;

                  //
                  // const Email = data.login;
                  // const EventId = this.$route.query.event;

                  // api
                  //   .request("get_passport", { EventId, Email })
                  //   .then((data) => {
                  //     const PassportId = data?.PassportId;

                  //     if (PassportId) {
                  //       this.fields.Passport.setValues(true, PassportId);
                  //     }
                  //   });
                  //
                });
              } else {
                this.$store.commit("setError", { code: 876 });
                this.buttonSpinner = false;
                this.$emit("validateFields");
              }
            } else {
              this.buttonSpinner = false;
              this.isCheckExists = false;
            }
          });
      }
    },
    fillFields(userData) {
      this.isCheckExists = false;
      const uData = this.editableModerator ? userData : userData.data.public;
      const fieldsKeys = Object.keys(this.fields);
      this.fields.lastName.setValues(true, uData.LName);
      this.fields.lastNameEn.setValues(true, uData.LNameEN);
      this.fields.firstName.setValues(true, uData.FName);
      this.fields.firstNameEn.setValues(true, uData.FNameEN);
      this.fields.secondName.setValues(true, uData.SName);
      fieldsKeys.forEach((key) => {
        if (uData[key]) this.fields[key].setValues(true, uData[key]);
      });
      if (this.moderator && !this.moderator.isNew && this.editableModerator) {
        this.fields.organizationName.setValues(
          true,
          this.editableModerator.organizationName
        );
        this.fields.organizationNameEn.setValues(
          true,
          this.editableModerator.organizationNameEn
        );
        this.fields.HSEMember.setValues(true, this.editableModerator.HSEMember);
        this.fields.campusId.setValues(true, this.editableModerator.campusId);
        this.fields.countryId.setValues(true, this.editableModerator.countryId);
        this.fields.Passport.setValues(true, this.editableModerator.PassportId);
        if (this.editableModerator.participationFormatId) {
          this.fields.participationFormatId.setValues(
            true,
            this.editableModerator.participationFormatId
          );
        }
      }
      this.fields.email.setValues(true, userData.login ?? userData.email);
      if (!userData.isNew) {
        fieldsKeys.forEach((key) => {
          if (!this.enabledFields.includes(key)) {
            this.fields[key]["disabled"] = true;
          }
        });
      } else this.$emit("validateFields");
    },
  },
  i18n: {
    messages: {
      en: {
        headings: {
          addModerator: "Add a moderator",
          editableModerator: "Edit a moderator",
          personal: "Personal details",
          company: "Company",
        },
        adding: "Adding",
        offline: "Offline",
        online: "Online",
        mixed: "Mixed",
        participationFormatId: "Participation format",
        pd: "Personal data processing agreement",
      },
      ru: {
        headings: {
          addModerator: "Добавить модератора",
          editableModerator: "Редактировать модератора",
          personal: "Личная информация",
          company: "Организация",
        },
        adding: "Добавляем",
        offline: "Офлайн",
        online: "Онлайн",
        mixed: "Смешанный",
        participationFormatId: "Формат участия",
        pd: "Согласие на обработку персональных данных",
      },
    },
  },
};
</script>

<style scoped>
.add-moderator .form {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
}

.form.down {
  align-items: flex-end;
}

.form .field {
  padding: 0 16px;
  min-width: 0;
  width: 50%;
  flex-basis: 50%;
}

.actions .hse-Button {
  display: inline-block;
}

.checkboxes {
  display: flex;
  justify-content: space-between;
}

.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.form-description {
  padding-top: 12px;
  font-size: 14px;
  line-height: 20px;
  color: #6b7a99;
}

.flex-tooltip {
  display: flex;
}

.flex-tooltip>div:first-child {
  width: calc(100% - 28px);
}

.flex-tooltip>div:last-child {
  padding: 36px 0 0 12px;
}

@media (max-width: 1024px) {
  .register {
    margin: 16px auto;
    max-width: 95%;
  }

  .emptyfield {
    display: none;
  }

  .checkboxes {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .form .field {
    width: 100%;
    flex-basis: 100%;
  }

  .form-footer {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
<style>
.smallLabel {
  font-size: 14px !important;
}

@media (max-width: 768px) {
  .hse-Input_disabled input:disabled {
    opacity: 1 !important;
    color: #000 !important;
  }
}

.colsNew {
  /* padding: 0 16px; */
  margin: 0 -15px;
  flex-wrap: wrap;
}

.colsNew>.field {
  flex: 1;
  margin: 0 -15px;
}
</style>
