<template>
    <div v-if="this.done==0" class="payment-card">
      <HseHeading size="h3">{{ $t('header') }}</HseHeading>
      <HseSpace size='small' vertical />
      <div class="form">
        <div class="field">
          <Validator :field="fields.offerAgreement" :label="$t('offerAgreement')"/>
          <span class="link-offer" @click="openOfferModal"> {{ $t('offer') }} </span>
          <OfferDataModal :offerData="offerData"/>
        </div>
      </div>
      <div class="form">
        <div class="field">
          <HseSpace size='medium' vertical />
          <div class="actions">
            <HseButton v-on:click="cancel" variant="secondary">{{ $t('cancel') }}</HseButton>
            <div @click="checkValidFields">
              <HseButton :class="{disable: !this.fields.offerAgreement.value}" @click="getUrl">{{ $t('startPayment') }}</HseButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="this.done==1" class="payment-card">
      <HseHeading size="h3">{{ $t('processed') }}</HseHeading>
      <HseSpace size='medium' vertical />
      <HseButton v-on:click="cancel" variant="secondary">{{ $t('cancel') }}</HseButton>
    </div>
    <div v-else class="payment-card">
      <HseHeading size="h3">{{ $t('notneeded') }}</HseHeading>
      <HseSpace size='medium' vertical />
      <HseButton v-on:click="cancel" variant="secondary">{{ $t('cancel') }}</HseButton>
    </div>
</template>

<script>
  import { api } from '../api'
  import fields from './paymentFields'
  import { HseHeading, HseSpace, HseButton } from '@hse-design/vue'
  import { mapState } from 'vuex'
  import Validator from '../components/common/form/Validator.vue'
  import OfferDataModal from "../components/common/modal/OfferDataModal";


  export default {
    name: 'payment',
    data: () => ({
      fields,
      done: false,
      receiptPayment: true,
      eventId: '',
      offerData: '',
      field: {
        label: 'Загрузить квитанцию об оплате',
        value: '',
        isValid: false,
        maxSizeMb: 3,
        formats: ['jpg', 'jpeg'],
        setValues : function (isValid, value = '') {
          this.value = value
          this.isValid = isValid
        }
      },
    }),
    created () {
      api.request('requestGet', { id: this.$route.params.id } ).then(data => this.eventId = data[0].EventId )
      api.request('payments.check.status', this.$route.params.id, []).then(data => this.done = data)
      //api.request('payments.check.enabled', this.$route.params.id, []).then(data => this.enabled = data)
      //и показывать ссылки в зависимости от того включена или нет
      setTimeout(() => {
        this.getOfferData()
      }, 1000);
    },
    components: {
      HseHeading,
      HseSpace,
      //HseSwitcher,
      HseButton,
      Validator,
      OfferDataModal
    },
    computed: {
      ...mapState(['lang']),
    },
    watch: {
      lang: function () {
        this.getOfferData()
      }
    },
    methods: {
      cancel(){
        this.$router.go(-1);
      },
      getUrl () {
        const ua = window.navigator.userAgent
        const isIOS = /iP(ad|hone)/i.test(ua) && /WebKit/i.test(ua) && !/CriOS/i.test(ua) && !/OPiOS/i.test(ua)
        api.request('payments.get.link', { request_id: this.$route.params.id, lang: this.currentLang } ).then(data => isIOS ? window.location.href = data : window.open(data))
      },
      checkValidFields() {
        this.$emit('validateFields')
      },
      getOfferData () {
        api.request('getEventPaymentPublicOffer', { id: this.eventId, lang: this.currentLang } ).then(data => this.offerData = data)
      },
      openOfferModal () {
        this.$root.$refs.offerModal.openOfferModal()
      },
    },
    i18n: {
      messages: {
        en: {
          header: 'Payment for participation',
          payByCard: 'Pay online',
          payByReceipt: 'Pay by receipt',
          offerAgreement: 'I am acquainted with the terms of the offer agreement',
          offer: 'Consent to the offer agreement',
          startPayment: 'Pay',
          downloadReceipt: 'Download receipt',
          processed: 'Payment allready processed',
          notneeded: 'Payment not needed',
          cancel: 'Back',
        },
        ru: {
          header: 'Оплата участия',
          payByCard: 'Оплатить онлайн картой',
          payByReceipt: 'Оплатить по квитанции',
          offerAgreement: 'С условиями договора оферты ознакомлен',
          offer: 'Согласие с договором-офертой',
          startPayment: 'Оплатить',
          downloadReceipt: 'Сформировать квитанцию',
          processed: 'Оплата уже произведена',
          notneeded: 'Оплата не требуется',
          cancel: 'Назад',
        },
      }
    }  
    
  }
</script>

<style scoped>
  .link-offer {
    color: blue;
    font-size: 80%;
    cursor: pointer;
  }
  .disable {
    opacity: 0.5;
    pointer-events: none;
  }
  .actions .hse-Button  {
    display: inline-block;
    margin-right: 20px;
  }
  .payment-card {
    background: #FFFFFF;
    border-radius: 12px;
    margin: 53px auto;
    width: fit-content;
    padding: 32px 32px;
    width: 600px;
    max-width: calc(100vw - 192px)
  }
  .payment-card .switcher {
    display: flex;
    align-items: center;
  }
  .payment-card .left-label {
    margin-right: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #0f0f14;
    font-family: "HSE Sans", system-ui, -apple-system, "Segoe UI", "Roboto", "Ubuntu", "Cantarell", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
  .payment-card .form {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin: 0 -16px;
  }
  .field {
    padding: 0 16px;
    min-width: 0;
  }
  .link {
    white-space: nowrap;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  @media (max-width: 1023px) {
    .payment-card {
      max-width: 95%;
      margin: 16px auto;
    }
  }
  @media (max-width: 767px) {
    .payment-card {
      width: 100%;
      max-width: 100%;
      margin: 0 auto;
    }
  }
  .actions {
    display: flex;
    margin: 0 -4px;
  }
  .actions>* {
    display: flex;
    margin: 0 4px;
  }
</style>
