<template>
  <article class="session">
    <section class="session__info">
      <button
        v-if="session.Items.length && isFavorite"
        @click="removeFavorites()"
        class="favorites-button favorites-button--remove"
      >
        <IconStatusStarFilled size="medium" />
      </button>
      <button
        v-else-if="session.Items.length"
        @click="addFavorites()"
        class="favorites-button favorites-button--add"
      >
        <IconStatusStarLinear size="medium" />
      </button>
      <section class="info__text">
        <a
          v-if="session.BroadCastLink"
          class="broadcast-link"
          :href="session.BroadCastLink"
        >
          <IconCamera />
        </a>
        <p>
          {{
            $i18n.locale === "ru"
              ? datesMap.get(date.Date).DayRU
              : datesMap.get(date.Date).DayEN
          }}
        </p>
        <p>{{ session.StartTime }} - {{ session.EndTime }} (UTC +3)</p>
        <p>{{ session.Format }}</p>
        <section
          v-if="session.LangRu || session.LangEn"
          class="text__translation"
        >
          <div class="translation__languages">
            <p v-if="session.LangRu">RUS</p>
            <p v-if="session.LangEn">ENG</p>
            <IconHeadphones v-if="session.IsTranslation" />
          </div>
        </section>
        <section
          v-if="hasPaid"
          style="display: flex; gap: 0.5em; flex-direction: column"
        >
          <a
            v-if="
              (session.Format === $t('online') ||
                session.Format === $t('hybrid') ||
                session.Format === $t('blended')) &&
              session.BroadCastLink
            "
            :href="session.BroadCastLink"
          >
            {{ $t("connectionLink") }}
          </a>
          <a
            v-if="
              (session.Format === $t('online') ||
                session.Format === $t('hybrid') ||
                session.Format === $t('blended')) &&
              session.ZoomMeetingUrl
            "
            :href="session.ZoomMeetingUrl"
          >
            {{ $t("streamLink") }}
          </a>
          <p
            v-if="session.ShowRooms"
            v-html="highlightSearchResults(session.RoomTitle, searchTerm)"
          ></p>
        </section>
        <div v-if="session.logoOrganizerId != null">
          <SectionsSessionLogoVue
            :id="
              this.lang === 'ru'
                ? session.logoOrganizerId
                : session.logoOrganizerEnId
            "
          />
        </div>
      </section>
    </section>
    <section class="session__main">
      <div class="div_title">
        <h4>{{ session.Type }} {{ section.Code }}-{{ session.Code }}.</h4>
        <h4
          v-if="
            session.TypeId !== sessionTypeId &&
            session.TypeId !== roundtableTypeId &&
            session.Items[0]
          "
          v-html="
            highlightSearchResults(session.Items[0].Rapporteur, searchTerm)
          "
        ></h4>
        <h4
          v-html="' ' + highlightSearchResults(session.Title, searchTerm)"
        ></h4>
      </div>

      <div
        class="div_manager"
        v-if="
          (session.TypeId === sessionTypeId ||
            session.TypeId === roundtableTypeId) &&
          session.Manager
        "
      >
        <p
          v-if="
            (session.TypeId === sessionTypeId ||
              session.TypeId === roundtableTypeId) &&
            session.Manager
          "
          class="main__manager"
          style="white-space: nowrap"
        >
          {{ $tc("manager", session.Manager.split(", ").length) }}:{{
            "\u00A0"
          }}
        </p>
        <p
          v-if="
            (session.TypeId === sessionTypeId ||
              session.TypeId === roundtableTypeId) &&
            session.Manager
          "
          class="main__manager"
          v-html="highlightSearchResults(session.Manager, searchTerm)"
        ></p>
      </div>
      <!-- <p
        v-else-if="session.Items[0] && session.Items[0].Manager"
        class="main__manager"
      >
        {{ $tc("manager", session.Items[0].Manager.split(", ").length) }}:
        {{ session.Items[0].Manager }}
      </p> -->
      <div
        v-else-if="session.Items[0] && session.Items[0].Manager"
        style="display: flex"
      >
        <p class="main__manager">
          {{ $tc("manager", session.Items[0].Manager.split(", ").length) }}:
        </p>
        <p
          class="main__manager"
          v-html="
            '&ensp;' +
            highlightSearchResults(session.Items[0].Manager, searchTerm)
          "
        ></p>
      </div>
      <ul class="main__items list-style-none">
        <li
          :class="{ 'item--found': item.IsWordFound }"
          v-for="item in session.Items"
          :key="item.Id"
        >
          <p
            v-if="session.TypeId === sessionTypeId"
            v-html="highlightSearchResults(item.Authors, searchTerm)"
          ></p>
          <div
            style="display: flex; gap: 0.5em"
            v-if="session.TypeId === sessionTypeId"
          >
            <p
              v-if="session.TypeId === sessionTypeId"
              v-html="highlightSearchResults(item.Title, searchTerm)"
            ></p>
            <p v-if="session.TypeId === sessionTypeId">
              (<a :href="item.Annotation">{{ $t("annotation") }}</a>
              <span
                v-if="
                  hasPaid &&
                  session.TypeId === sessionTypeId &&
                  item.Presentation
                "
                >,
              </span>
              <a
                v-if="
                  hasPaid &&
                  session.TypeId === sessionTypeId &&
                  item.Presentation
                "
                :href="item.Presentation"
                >{{ $t("presentation") }}</a
              >)
            </p>
          </div>
          <div
            class="roundtable"
            v-else-if="session.TypeId === roundtableTypeId"
          >
            <section class="roundtable__issues">
              <h5>{{ $t("issuesForDiscussion") }}:</h5>
              <div
                class="text-pre-line"
                v-html="highlightSearchResults(item.Discussion, searchTerm)"
              ></div>
            </section>
            <div class="div_manager">
              <p style="white-space: nowrap">
                {{ $t("experts") }}:{{ "\u00A0" }}
              </p>
              <p
                v-html="highlightSearchResults(item.Participants, searchTerm)"
              ></p>
            </div>
          </div>
          <p v-else v-html="item.Annotation" />
        </li>
      </ul>
      <div
        v-if="
          (session.TypeId === sessionTypeId ||
            session.TypeId === roundtableTypeId) &&
          session.Debutant
        "
        class="div_manager"
      >
        <p
          v-if="
            (session.TypeId === sessionTypeId ||
              session.TypeId === roundtableTypeId) &&
            session.Debutant
          "
          class="main__debutant"
          style="white-space: nowrap"
        >
          {{ $tc("debutant", session.Debutant.split(", ").length) }}:{{
            "\u00A0"
          }}
        </p>
        <p
          v-if="
            (session.TypeId === sessionTypeId ||
              session.TypeId === roundtableTypeId) &&
            session.Debutant
          "
          class="main__debutant"
          v-html="highlightSearchResults(session.Debutant, searchTerm)"
        ></p>
      </div>
      <div></div>
    </section>
  </article>
</template>
<script>
import { IconStatusStarFilled, IconStatusStarLinear } from "@hse-design/vue";
import { api } from "../../../api";
import IconCamera from "../IconCamera.vue";
import IconHeadphones from "../IconHeadphones";
import SectionsSessionLogoVue from "./SectionsSessionLogo.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      isPending: false,
      isFavorite: false,
    };
  },
  props: {
    session: Object,
    section: Object,
    date: Object,
    datesMap: Map,
    roomsMap: Map,
    hasPaid: Boolean,
    updateEventFavorites: Function,
    invitedReportTypeId: String,
    sessionTypeId: String,
    bookLaunchTypeId: String,
    roundtableTypeId: String,
    honoraryPaperTypeId: String,
    searchTerm: String,
  },
  computed: {
    ...mapState(["lang"]),
    ufsiiArray() {
      const array = [];
      for (const item of this.session.Items) {
        array.push({
          itemIdSql: item.IdSql,
          ufsii: item.UserFavoriteSessionItemId,
        });
      }

      return array;
    },
  },
  mounted() {
    this.updateIsFavorite(this.session);
  },
  watch: {
    session(newSession) {
      this.updateIsFavorite(newSession);
    },
  },
  methods: {
    setLogos(id) {
      const filesPath =
        (process.env.NODE_ENV === "development"
          ? "http://aprilconference-test.hse.ru"
          : window.location.origin) + `/files/public/${id}`;
      console.log(filesPath, "filesPath");
      // state.static.logos[key] = filesPath + logo.logo_id
    },

    highlightSearchResults(theText, searchTerms) {
      const re = new RegExp(searchTerms.trim(), "gi");
      return theText.replace(
        re,
        (match) => `<span style="background-color:#ffff00">${match}</span>`
      );
    },

    updateIsFavorite(newSession) {
      this.isFavorite = !!newSession.Items[0]?.UserFavoriteSessionItemId;
    },
    async addFavorites() {
      console.log("test2");
      if (this.isPending) {
        console.log("test3");
        return;
      }

      this.isPending = true;
      this.isFavorite = true;

      try {
        const requestArray = [];
        for (const item of this.ufsiiArray) {
          if (item.ufsii) {
            continue;
          }
          console.log("test33", this.session.IdSql);
          requestArray.push(
            api.request(
              "add_user_favorite_session_item",
              { sessionId: this.session.IdSql, itemId: item.itemIdSql },
              []
            )
          );
        }
        const result = await Promise.allSettled(requestArray);
        let idx = 0;
        let rejected = false;
        for (const { status, value } of result) {
          if (
            (Array.isArray(value) && value.length === 0) ||
            status === "rejected"
          ) {
            rejected = true;
            continue;
          }

          this.ufsiiArray[idx].ufsii = value.UserFavoriteSessionItemId;
          idx++;
        }

        this.updateEventFavorites(this.session.Id, this.ufsiiArray);
        if (rejected) {
          throw new Error("Couldn't add to favorites");
        }
      } catch (error) {
        this.isFavorite = false;
      } finally {
        this.isPending = false;
      }
    },
    async removeFavorites() {
      console.log("test1");
      if (this.isPending) {
        return;
      }
      console.log("test");
      this.isPending = true;
      this.isFavorite = false;

      try {
        const requestArray = [];
        for (const item of this.ufsiiArray) {
          if (item.ufsii === null) {
            continue;
          }

          requestArray.push(
            api.request(
              "delete_user_favorite_session_item",
              { id: item.ufsii },
              []
            )
          );
        }

        const result = await Promise.allSettled(requestArray);
        let rejected = false;
        let idx = 0;
        for (const { value, status } of result) {
          if (
            (Array.isArray(value) && value.length === 0) ||
            status === "rejected"
          ) {
            rejected = true;
            continue;
          }

          this.ufsiiArray[idx].ufsii = null;
          idx++;
        }

        if (rejected) {
          throw new Error("Couldn't remove from favorites");
        }
        this.updateEventFavorites(this.session.Id, this.ufsiiArray);
      } catch (error) {
        this.isFavorite = true;
      } finally {
        this.isPending = false;
      }
    },
  },
  i18n: {
    messages: {
      en: {
        annotation: "abstract",
        location: "Location",
        connectionLink: "Join the event",
        streamLink: "Stream link",
        link: "link",
        manager: "Moderator | Moderators",
        debutant: "Discussant | Discussants",
        online: "Online",
        hybrid: "Hybrid",
        blended: "Blended",
        translation: "Simultaneous translation",
        presentation: "presentation",
        experts: "Experts",
        issuesForDiscussion: "Issues for discussion",
        errorFavorites: {
          title: "Error",
          description: "Favorites error",
        },
      },
      ru: {
        annotation: "аннотация",
        location: "Место проведения",
        connectionLink: "Ссылка для подключения",
        streamLink: "Ссылка на трансляцию",
        link: "ссылка",
        manager: "Председатель | Председатели",
        debutant: "Дискуссант | Дискуссанты",
        online: "Онлайн",
        hybrid: "Смешанный",
        blended: "Смешанный",
        translation: "Синхронный перевод",
        presentation: "презентация",
        experts: "Эксперты",
        issuesForDiscussion: "Вопросы для обсуждения",
        errorFavorites: {
          title: "Ошибка",
          description: "Ошибка добавления в избранное",
        },
      },
    },
  },
  components: {
    IconStatusStarLinear,
    IconStatusStarFilled,
    IconCamera,
    IconHeadphones,
    SectionsSessionLogoVue,
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.div_manager {
  display: flex;
}

.div_title {
  display: flex;
  gap: 0.5em;
}

@media (max-width: 744px) {
  .div_manager {
    display: flex;
    flex-direction: column;
  }
  .div_title {
    display: flex;
    gap: 0.5em;
    flex-direction: column;
  }
}

.session {
  padding-top: 0.9375em;
  display: grid;
  grid-template-columns: 1fr;
}

.session__info {
  font-size: 0.8125em;

  align-self: flex-start;
  display: flex;
  gap: 0.5em;

  color: #999;
}

.info__text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5em;
}

.broadcast-link {
  color: #555656;
  font-size: 24px;
}

.text__translation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5em;
}

.translation__languages {
  display: flex;
  gap: 0.5em;
}

.translation__languages p {
  color: black;
  background-color: #e7eefb;
  font-size: 10px;
  line-height: 10px;
  padding: 10px 5px;
  border-radius: 4px;
  font-weight: 600;
}

.session__main > h4 {
  font-size: 0.9375em;
}

.main__manager {
  font-size: 0.75em;
  font-weight: 600;
}

.main__debutant {
  font-size: 0.75em;
}

.main__items {
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  font-size: 0.75em;
  display: flex;
  flex-direction: column;
  gap: 0.4em;
}

.item--found {
  background-color: #fff6cb;
}

.roundtable {
  display: flex;
  flex-direction: column;
  gap: 0.4em;
}

.roundtable__issues h5 {
  font-size: 1em;
  font-weight: inherit;
}

.favorites-button {
  align-self: center;

  border: none;
  line-height: 0;
  cursor: pointer;
  font-size: 24px;
}

.favorites-button--remove {
  color: #fea000;
}

.favorites-button--add {
  color: inherit;
}

.list-style-none > li {
  list-style: none;
}

.text-pre-line {
  white-space: pre-line;
}

.translation__languages svg {
  width: 24px;
  height: auto;
}

.items__translation {
  align-self: flex-start;

  padding-left: 0.1em;

  color: #7d7568;
  display: flex;
  font-size: 24px;
  gap: 0.5em;
}

.translation__languages {
  display: flex;
  gap: 0.5em;
}

.translation__languages p {
  color: black;
  background-color: var(--background-color);
  font-size: 10px;
  line-height: 10px;
  padding: 10px 5px;
  border-radius: 4px;
  font-weight: 600;
}

@media (min-width: 744px) {
  .session {
    grid-template-columns: 0.25fr 1fr;
    gap: 2em;
  }
}
</style>
