<template>
  <div class="wrapper">
    <ItemsGrid :data="items" :types="types" :isLoading="isLoading" :component="component" :filterShow="filterShow"
      v-on:typeChange="changeType" v-on:typeOfEvent="typeOfEvent" v-if="this.typeOfEventValue == 1" />
    <ItemsGrid :data="itemsHor" :types="types" :isLoading="isLoading" :component="componentHor" :filterShow="filterShow"
      v-on:typeChange="changeType" v-on:typeOfEvent="typeOfEvent" v-if="this.typeOfEventValue != 1" />
  </div>
</template>

<script>
import { api } from '../api'
import ItemsGrid from '../components/common/ItemsGrid.vue'
import eventCard from '../components/MyEventsPage/MyEventsCard.vue'
import eventCardHor from '../components/MyEventsPage/MyEventsHorCard.vue'
import { mapState } from 'vuex'

export default {
  data: () => ({
    reqParams: {
      isArchive: false,
      lang: "",
      email: "",
    },
    items: [],
    itemsHor: [],
    types: [
      { title: 'types.myEvents.current', id: 'current' },
      { title: 'types.myEvents.archive', id: 'archive' },
    ],
    component: eventCard,
    componentHor: eventCardHor,
    isLoading: false,
    filterShow: false,
    typeOfEventValue: '1',
  }),
  created() {
  },
  components: {
    ItemsGrid,
  },
  methods: {
    requestItems() {
      this.isLoading = true

      if (this.typeOfEventValue === '1') {
        api.request('get_rt_events_list', { ...this.reqParams, email: this.$store.state.user.login }, []).then(data => {
          this.items = data
          this.isLoading = false
        })
      }
      else {
        api.request('get_hor_events_list', { ...this.reqParams, email: this.$store.state.user.login }, []).then(data => {
          this.itemsHor = data.sort((item1, item2) => {
            var dateItem1 = new Date(item1.StartTime);
            var dateItem2 = new Date(item2.StartTime);
            return dateItem2 - dateItem1;
          }).reverse()
          this.isLoading = false
        })
      }
    },
    changeType(type) {
      this.filterShow = true
      this.reqParams.isArchive = type === 'archive'
      this.requestItems()
    },
    typeOfEvent(type) {
      if (typeof type === 'string') {
        this.typeOfEventValue = type
        this.requestItems()
      }
    },
  },
  computed: mapState(['lang']),
  watch: {
    lang() {
      this.requestItems()
    }
  },
}
</script>

<style scoped>
.wrapper {
  max-width: 1500px;
  width: calc(100vw - 192px);
  margin: 24px auto;
}

@media (max-width: 1024px) {
  .wrapper {
    width: calc(100vw - 48px)
  }
}
</style>
