<template>
  <div>
    <HseModalForm v-model="isOpen" :modal-style="{maxWidth: '450px'}">
      
	<div class="content">
		<HseHeading size="h3">{{text}}</HseHeading>
		<HseSpace size='large' vertical />
	</div>

	<template #actions>
		<HseButton @click="close">{{$t('ok')}}</HseButton>
	</template> 

    </HseModalForm>
  </div>
</template>

<script>

  import { HseModalForm, HseButton, HseSpace, HseHeading } from '@hse-design/vue' 

  export default {
    components: {
      HseModalForm,
      HseButton,
      HseSpace,
      HseHeading,
    },
    props: ['text'],
    data: () => ({
      isOpen: false,
    }),
    created () {
      this.$parent.$on('openDirectionModal', () => {this.isOpen = true})
    },
    methods: {
      close() {
            this.isOpen = false;
            this.$router.push('/manager_requests')
          },
    }, 
    i18n: {
      messages: {
        en: {
          ok: 'Ok',
        },
        ru: {
          ok: 'Ок',
        },
      }
    } 
  }
</script>

<style scoped>
</style>