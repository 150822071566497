<template>
    <div v-if="!confirmationStatus" class="report">
        <HseHeading size="h3">{{ $t('headings.confirm') }}</HseHeading>
        <HseSpace size='large' vertical />
        <Validator :field="field"/>
        <HseSpace size='small' vertical />
        <div class="buttonGroup">
            <HseButton
                :spinner="spinner"
                spinnerPosition="left"
                variant='primary'
                size='medium'
                v-on:click="() => closeModalWindow(true)"
            >
                <span>{{ $t('send') }}</span>
            </HseButton>
            <HseSpace size='small' horizontal />
            <HseButton
                variant='secondary'
                size='medium'
                v-on:click="() => closeModalWindow(false)"
            >
                <span>{{ $t('cancel') }}</span>
            </HseButton>
        </div>
    </div>
    <div class="status" v-else-if="confirmationStatus === 'error'">
        <svg width="110" height="100" viewBox="0 0 110 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M26.8055 8.42652C35.0279 2.93245 44.6949 0 54.584 0C67.8448 0 80.5625 5.26784 89.9393 14.6447C99.3161 24.0215 104.584 36.7392 104.584 50C104.584 59.8891 101.652 69.556 96.1575 77.7785C90.6634 86.0009 82.8545 92.4096 73.7182 96.194C64.5819 99.9783 54.5285 100.969 44.8295 99.0392C35.1304 97.11 26.2213 92.3479 19.2287 85.3553C12.236 78.3627 7.474 69.4536 5.54474 59.7545C3.61548 50.0555 4.60565 40.0021 8.39003 30.8658C12.1744 21.7295 18.583 13.9206 26.8055 8.42652Z" fill="#FF564E"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M73.4702 35.2504L69.7498 31.53L55.0002 46.2796L40.2507 31.53L36.5303 35.2504L51.2799 50L36.5303 64.7496L40.2507 68.47L55.0002 53.7204L69.7498 68.47L73.4702 64.7496L58.7206 50L73.4702 35.2504Z" fill="white"/>
        </svg>
        <HseSpace size="large" vertical />
        <HseHeading size="h3" v-html="$t(`${confirmationMessage === 'Error: Код подтверждения от другого мероприятия' ? 'errStatusCode' : 'errStatus'}`)"></HseHeading>
        <HseSpace size="medium" vertical />
        <HseButton @click="getBackIfErr">{{ $t('back') }}</HseButton>
    </div>
    <div class="status" v-else-if="confirmationStatus === 'without direction'">
        <HseHeading size="h3" v-html="$t('directionErrStatus')"></HseHeading>
        <HseSpace size="medium" vertical />
        <div class="buttonGroup">
            <HseButton
                :spinner="buttonSpinner"
                spinnerPosition="left"
                variant='primary'
                size='medium'
                v-on:click="() => chengeDirection()"
            >
                <span>{{ $t('approve') }}</span>
            </HseButton>
            <HseSpace size='small' horizontal />
            <HseButton @click="() => closeModalWindow('done')">{{ $t('cancel') }}</HseButton>
        </div>
    </div>
    <div class="status" v-else-if="confirmationStatus">
        <svg width="110" height="100" viewBox="0 0 110 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M26.8055 8.42652C35.0279 2.93245 44.6949 0 54.584 0C67.8448 0 80.5625 5.26784 89.9393 14.6447C99.3161 24.0215 104.584 36.7392 104.584 50C104.584 59.8891 101.652 69.556 96.1575 77.7785C90.6634 86.0009 82.8545 92.4096 73.7182 96.194C64.5819 99.9783 54.5285 100.969 44.8295 99.0392C35.1304 97.11 26.2213 92.3479 19.2287 85.3553C12.236 78.3627 7.474 69.4535 5.54474 59.7545C3.61548 50.0555 4.60565 40.0021 8.39003 30.8658C12.1744 21.7295 18.583 13.9206 26.8055 8.42652ZM85.4219 35.1375L79.2732 28.9887L46.4721 61.7897L29.8964 45.214L23.7476 51.3627L46.4721 74.0872L85.4219 35.1375Z" fill="#0050CF"/>
        </svg>
        <HseSpace size="large" vertical />
        <HseHeading size="h3" v-html="$t('okStatusSpeaker')"></HseHeading>
        <HseSpace size="medium" vertical />
        <HseButton @click="() => closeModalWindow('done')">{{ $t('ok') }}</HseButton>
    </div>
</template>
<script>
    import { HseHeading, HseSpace, HseButton, } from '@hse-design/vue'
    import Validator from '../common/form/Validator.vue'
    import { api } from '../../api'
    export default {
        components: {
            HseHeading,
            HseSpace,
            HseButton,
            Validator,
        },
        data: () => ({
            buttonSpinner: false,
        }),
        props: ['field', 'spinner', 'confirmationStatus', 'confirmationMessage', 'collectiveRequestDirection', 'requestId'],
        methods: {
            chengeDirection () {
                const paramsRequestChangeDirection = {
                collectiveRequestDirection: this.collectiveRequestDirection,
                requestId: this.requestId
              }
              this.buttonSpinner = true
              api.request('collective_request_change_direction', paramsRequestChangeDirection).then(() => {
                setTimeout(() => {
                    this.$emit('approveMember')
                    this.buttonSpinner = 'done'
                }, 1000)
              })
            },
            closeModalWindow (val) {
                this.$emit('closeModal', val)
            },
            getBackIfErr () {
                this.$emit('getBackIfErr')
            }
        },
        i18n: {
            messages: {
                en: {
                    headings: {
                        confirm: 'Enter confirmation code'
                    },
                    send: 'Send verification code',
                    cancel: 'Cancel',
                    okStatusSpeaker: 'Your request has been added to the collective request',
                    errStatus: 'Invalid code entered. Please try again',
                    directionErrStatus: 'The topics of your application and the joint application do not match. If you confirm your participation in the group application, the field will be replaced',
                    errStatusCode: 'The code has been entered from another event, try again',
                    back: 'Back',
                    ok: 'Ok',
                    approve: 'Approve'
                },
                ru: {
                    headings: {
                        confirm: 'Введите код подтверждения'
                    },
                    send: 'Отправить код подтверждения',
                    cancel: 'Отмена',
                    okStatusSpeaker: 'Ваша заявка добавлена в коллективную заявку',
                    errStatus: 'Введен неверный код. Попробуйте еще раз',
                    directionErrStatus: 'Направление заявки не совпадает с направлением Коллективной заявки. Если Вы подтверждаете участие в Коллективной заявке, направление будет изменено.',
                    errStatusCode: 'Введён код от другого мероприятия, попробуйте ещё раз',
                    back: 'Назад',
                    ok: 'Хорошо',
                    approve: 'Подтвердить'
                }
            }
        }
    }
</script>
<style>
    .buttonGroup {
        display: flex;
    }
    .status {
        max-width: 450px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 0 auto;
    }
</style>
