<template>
  <section class="session__main">
    <h4>{{session.MeetingType === plenarySession ? $t('plenarySession') : $t('plenaryMeeting')}} {{$t('quoteOpen') + session.Title + $t('quoteClose')}}</h4>
    <p v-if="session.Moderators.length">
    {{$t('moderator')}}: {{session.Moderators[0].LastName}} {{session.Moderators[0].FirstName}} {{session.Moderators[0].MiddleName}}, {{session.Moderators[0].Org}}
    </p>
    <a class="main__button link" @click="toggleMore()">
      {{$t('more')}}
      <IconActionArrowDropdownBottom v-if="!showingMore"/>
      <IconActionArrowDropdownTop v-if="showingMore"/>
    </a>
    <section v-if="showingMore" class="main__more">
      <section v-if="session.OpeningSpeech" class="more__opening-speech">
        <h4 class="text-pre-wrap">{{$t('openingSpeech')}}: </h4>
        {{session.OpeningSpeech}}
      </section>  
      <section v-if="session.Disscusants.length" class="more__discussants more__item">
        <h4>{{$t('discussants')}}:</h4>
        <ul class="list-style-dash">
          <li v-for="discussant in session.Disscusants" :key="discussant.Id">
            {{discussant.LastName}} {{discussant.FirstName}} {{discussant.MiddleName}}, {{discussant.Org}}
          </li>
        </ul>
      </section>
      <section v-if="session.Speakers.length" class="more__speakers more__item">
        <h4>{{$t('speakers')}}:</h4>
        <ul class="list-style-none">
          <li v-for="speaker in session.Speakers" :key="speaker.Id">
            {{speaker.LastName}} {{speaker.FirstName}} {{speaker.MiddleName}}, {{speaker.Org}}
          </li>
        </ul>
      </section>
      <section v-if="session.Annotation" class="more__description more__item">
        <h4>{{$t('description')}}:</h4>
        <p>{{session.Annotation}}</p>
      </section>
      <section v-if="session.Discussion" class="more__questions more__item">
        <h4>{{$t('questionsForDiscussion')}}:</h4>
        <div class="text-pre-line" v-html="session.Discussion"></div>
      </section>
    </section>
  </section>
</template>

<script>
  import { IconActionArrowDropdownBottom, IconActionArrowDropdownTop } from '@hse-design/vue';

  export default {
    data: () => ({
      showingMore: false,
      plenaryMeeting: 'Пленарное заседание',
      plenarySession: 'Пленарная сессия',
    }),
    props: {
      session: Object,
    },
    methods: {
      toggleMore(){
        this.showingMore = !this.showingMore;
      },
    },
    components: {
      IconActionArrowDropdownTop, IconActionArrowDropdownBottom
    },
    i18n: {
      messages: {
        en: {
          more: 'More',
          quoteOpen: '“',
          quoteClose: '”',
          moderator: 'Moderator',
          openingSpeech: 'Opening remarks',
          discussants: 'Discussants', 
          speakers: 'Key speakers', 
          description: 'Description',
          questionsForDiscussion: 'Issues for discussion',
          plenarySession: 'Plenary session',
          plenaryMeeting: 'Plenary meeting',
        },
        ru: {
          more: 'Подробнее',
          quoteOpen: '«',
          quoteClose: '»',
          moderator: 'Модератор',
          openingSpeech: 'Приветственное слово',
          discussants: 'Дискуссанты',
          speakers: 'Ключевые спикеры',
          description: 'Описание',
          questionsForDiscussion: 'Вопросы для обсуждения',
          plenarySession: 'Пленарная сессия',
          plenaryMeeting: 'Пленарное заседание',
        },
      }
    },
  }
</script>

<style scoped>
  * {
    margin: 0;
    padding: 0;
  }

  h4  {
    font-weight: 600;
  }

  .list-style-dash > li {
    list-style: "\2014\a0";
  }

  .list-style-none > li {
    list-style: none;
  }

  .link {
    color: #0000EE;
    cursor: pointer;
  }

  .session__main {
    display: flex;
    flex-direction: column;
  }

  .main__button {
    margin: .8em 0;
    display: block;
    align-self: flex-start;
  }

  .main__more {
    padding: .8em 0;

    border-top: 1px solid #654;

    display: flex;
    flex-direction: column;
    gap: .8em;
  }

  .more__item {
    display: flex;
    flex-direction: column;
    gap: .8em;
  }

  .more__discussants > ul {
    gap: .75em;
  }

  .more__opening-speech {
    display: flex;
  }

  .more__item > ul {
    display: flex;
    flex-direction: column;
    gap: .75em;
  }

  .text-pre-line {
    white-space: pre-line;
  }

  .text-pre-wrap {
    white-space: pre-wrap;
  }
</style>
