import { HseSelect, } from '@hse-design/vue'
import { resourceLoader } from '../common/resourceLoader'

const fields = {
  Topic: {
    value: '',
    isValid: false,
    isRequired: true,
    label: "fields.name.expert_topic",
    size: "large",
    component: HseSelect,
    eventId: '',
    setEvent (event) { this.value=''; this.eventId = event; },
    getAllOptions (search) {
      return this.eventId ? resourceLoader.load('manager.eventTopics', {event_id: this.eventId}, [], search) : Promise.resolve([])
    },
  },
  event: {
    value: '',
    isValid: false,
    isRequired: true,
    label: "fields.name.event",
    size: "large",
    component: HseSelect,
    searchable: 'dropdown',
    getAllOptions (search) {
      return resourceLoader.load('manager.eventList', { }, [], search)
    },
  },

}

Object.keys(fields).forEach((key) => {
  fields[key].setValues = function (isValid, value = '') {
    this.value = value
    this.isValid = isValid
  }
})

export default fields