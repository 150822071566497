<template>
  <div class="wrapper">
    <TopNewsBanner />
    <div class="table-card">
      <HseHeading size="h3">{{ $t('header') }}</HseHeading>
      <HseSpace size='small_2x' vertical />
      <div class="cols-2">
        <div class="filters">
          <div class="field" v-if="items.length">
            <Validator :field="fields.filter" @change="setFilter" />
            <HseSpace size='small_2x' vertical />
          </div>
          <div class="field">
            <div class="hse-RichInput__label">{{ $t('Rating') }}</div>
            <HseSelect width="full" @change="filterByRate" :options="$t('rateOptions')" :clearable="false"
              v-model="currentRate" />
            <HseSpace size='small_2x' vertical />
          </div>
          <div class="field field_status" v-if="reqParams.rated">
            <Validator :field="fields.statusSelect" @change="filterByStatus" />
            <HseSpace size='small_2x' vertical />
          </div>
        </div>
      </div>


      <div v-if="filtered.length">
        <div v-for="value in filtered" v-bind:key="value.id">

          <div class="row_header" v-on:click="load(value.Id)"><strong>{{ value.Title }}</strong></div>
          <div class="cols-3" v-on:click="e => load(value.Id, e)">
            <div>
              <div><small>{{ $t('number') }}: {{ value.numberRequest }}</small></div>
              <div><small>{{ $t('created') }}: {{ dateFormat(value.CreatedOn) }}</small></div>
              <div><small>{{ $t('status') }}: {{ value.TotalResultTitle }}</small></div>
            </div>
            <div>
              <!-- <div>{{ value.Fio }}</div> -->
              <!-- <div><small>{{ value.ReportExist }}</small> {{ value.ReportTitle }}</div> -->
              <div><small>{{ $t('report') }}</small> {{ value.ReportTitle }}</div>
            </div>
            <div>
              <div class="hse-RichInput__label">{{ $t('files') }}</div>
              <small>
                <a v-if="getIsRuAnnotation(value)" class="link" :href="`/files/download_file?id=${value.ThesesFileID}`">{{
                  $t('annotation') }}</a><br>
                <a v-if="value.ThesesFileEnID !== null" class="link"
                  :href="`/files/download_file?id=${value.ThesesFileEnID}`">{{ $t('annotationEng') }}</a>
              </small>
            </div>
          </div>
          <div class="cols-3" v-on:click="load(value.Id)">
            <div v-if="value.TotalResult"><small>{{ $t('Rating') }}: {{ value.TotalResult }}</small></div>
            <div><small>{{ value.ExpertComment }}</small></div>
            <div></div>
          </div>
          <HseDivider color="dark" />
        </div>
      </div>
      <div v-else class="noData">
        {{ $t('noData') }}
      </div>

    </div>
  </div>
</template>

<script>
import { api } from '../api'
import { dateFormatter } from '../components/common/dateFormatter'
import { mapState } from 'vuex'
import { HseHeading, HseSpace, HseDivider, HseSelect } from '@hse-design/vue'
import Validator from '../components/common/form/Validator.vue'
import fields from '../components/requestsPage/managerFields'
import { localizeFields } from '../i18n'
import TopNewsBanner from '../components/TopNewsBanner/TopNewsBanner.vue'

export default {
  components: {
    HseHeading,
    HseSpace,
    HseDivider,
    HseSelect,
    Validator,
    TopNewsBanner,
  },
  data: () => ({
    filtered: [],
    reqParams: { archive: false, offset: 0, limit: 100, rated: false, totalResultOptionId: null },
    items: [],
    fields,
    currentRate: {
      label: 'На рецензировании',
      value: 'NotRate',
    },
  }),
  created() {
    this.translateFields()
    this.requestItems()
  },
  methods: {
    filterByStatus() {
      this.reqParams.totalResultOptionId = this.fields.statusSelect.value
      this.requestItems()
    },
    filterByRate() {
      if (this.currentRate.valueOf() === 'notRate') {
        this.reqParams.rated = false
        this.reqParams.totalResultOptionId = null
        this.requestItems()
      } else {
        this.reqParams.rated = true
        this.requestItems()
      }
    },
    load(id, e) {
      if (e && e.target.classList.contains('link')) return

      this.$router.push('/expert_requests/' + id)
    },
    setFilter() {
      this.filtered = this.items.filter(
        item => {
          let isMatchFilter = true
          if (this.filter) {
            const filter = this.filter.toLowerCase()
            isMatchFilter = ['Title', 'Fio', 'ReportTitle', 'TotalResultTitle'].some(key => {
              return item[key] ? item[key].toLowerCase().includes(filter) : false
            })
          }
          return isMatchFilter
        }
      );
    },
    requestItems() {
      this.reqParams.lang = this.lang;
      api.request('requestsListForExpert', this.reqParams, []).then(data => {
        this.items = data.reverse().map(item => {
          if (!item.TotalResultTitle) {
            item.TotalResultTitle = this.$i18n.tc('rateStatus')
          }
          return item
        })
        this.fields.filter.setValues(true, '')
        this.setFilter()
      })
    },
    translateFields() {
      this.fields = localizeFields(fields, this.fields)
      if (this.lang === 'ru') {
        this.currentRate.label = 'На рецензировании'
      } else {
        this.currentRate.label = 'Under review'
      }
    },
    getIsRuAnnotation(value) {
      return value.ThesesFileID !== null
        && value.ApplicantLanguageId !== 'D909F362-2610-49C9-BFA2-57692617EF76'
        && this.lang === 'ru'
    },
    dateFormat(date) { return dateFormatter.format(date) },
  },
  computed: {
    filter() {
      return this.fields.filter.value
    },
    ...mapState(['lang'])
  },
  watch: {
    lang() {
      this.requestItems()
      this.translateFields()
    }
  },
  i18n: {
    messages: {
      en: {
        header: 'Review Applications',
        number: 'Number',
        created: 'Created',
        status: 'Conclusion',
        annotation: 'Annotation',
        annotationEng: 'Annotation (en)',
        files: 'Files',
        rateStatus: 'Under review',
        Rating: 'Review',
        noData: 'No data',
        filter: 'Filter',
        report: 'Report',
        rateOptions: Object.freeze([
          { label: 'Under review', value: 'notRate' },
          { label: 'Reviewed Applications', value: 'rate' },
        ]),
      },
      ru: {
        header: 'Заявки на оценку',
        number: 'Номер',
        created: 'Создана',
        status: 'Заключение',
        annotation: 'Аннотация',
        annotationEng: 'Аннотация (англ)',
        files: 'Файлы',
        report: 'Доклад',
        rateStatus: 'На рецензировании',
        Rating: 'Оценка',
        noData: 'Нет данных',
        filter: 'Фильтр',
        rateOptions: Object.freeze([
          { label: 'На рецензировании', value: 'notRate' },
          { label: 'Оцененные заявки', value: 'rate' },
        ]),
      },
    }
  }
}
</script>

<style scoped>
.hse-Select {
  margin-top: 4px;
  margin-bottom: 8px;
}

.wrapper {
  max-width: 1500px;
  width: calc(100vw - 192px);
  margin: 24px auto;
}

.table-card {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 32px 32px;
}

.table-card .form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 -16px;
}

.table-card .form .field {
  padding: 0 16px;
  min-width: 0;
}

.subheading {
  font-family: 'Work Sans', sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #0F0F14;
}

.actions .hse-Button {
  display: inline-block;
}

.hse-Button:first-child {
  margin-right: 12px;
}

.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.form-description {
  padding-top: 12px;
  font-size: 14px;
  line-height: 20px;
  color: #6B7A99;
}

@media (max-width: 1024px) {
  .wrapper {
    width: calc(100vw - 48px)
  }

  .table-card {
    margin: 16px auto;
    max-width: 95%;
  }

  .table-card .form {
    grid-template-columns: repeat(2, 1fr);
  }

  .emptyfield {
    display: none;
  }
}

@media (max-width: 768px) {
  .wrapper {
    width: calc(100vw - 48px)
  }

  .table-card {
    margin: 0 auto;
    max-width: 100%;
  }

  .table-card .form {
    grid-template-columns: repeat(1, 1fr);
  }

  .form-footer {
    flex-direction: column;
    align-items: flex-start;
  }
}

.filters {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.cols-3 {
  display: grid;
  grid-template-columns: 2fr 4fr 120px;
  gap: 16px;
  cursor: pointer;
  padding: 4px 0;
  white-space: nowrap;
  overflow-x: auto;
}

.row_header {
  background-color: rgb(225, 228, 235);
  color: rgb(107, 122, 153);
  font-size: 14px;
  font-family: Proxima Nova, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  padding: 8px;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .filters {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .filters {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .field_status {
    margin-bottom: 25px;
  }
}
</style>
