<template>
  <div class="field" :class="{ displaynone: isHidden }">
    <div class="hse-RichInput__label">{{label}}</div>
    <div class="hse-Input hse-Input_size_large hse-Input_fullWidth" :class="{ active: isActive }">
      <span v-if="isHtml" v-html="val" />
      <span v-else :class="{ boldText: isActive }">{{val}}</span>
    </div>
    <HseSpace size='small_2x' vertical />
  </div>
</template>

<script>
import { HseSpace } from '@hse-design/vue'
export default {
  props: ['label', 'value', 'hideifempty', 'isHTML', 'isActive'],
  components: {
    HseSpace,
  },
  computed: {
    val () {
      return (this.value !== null || this.value !== undefined) ? this.value : ''
    },
    isHidden () {
      return this.hideifempty !== undefined && !this.val
    },
    isHtml() {
      return this.isHTML !== undefined
    }
  },
}
</script>

<style scoped>
  .field {
    min-width: 0;
  }
  .field .hse-Input {
    padding-top: 14px;
    padding-bottom: 14px;
    height: auto;
    min-height: 48px;
  }
  .displaynone {
    display: none;
  }

  .active {
    outline: 1px solid #0050CF;
  }

  .boldText {
    font-weight: 900;
  }
  /* .field .hse-Input span {
    white-space: nowrap;
  } */
</style>
