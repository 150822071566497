<template>
  <div class="wrapper">
    <div class="table-card">
      <HseHeading size="h3">{{ $t('header') }}</HseHeading>
      <HseSpace size='small_2x' vertical />

      <div class="filters">
        <div class="field">
          <Datepicker :field="fields.date" />
          <HseSpace size='small_2x' vertical />
        </div>

        <div v-if="filtered.length !== 0 && dateVal !== ''">
          <Validator @change="pickCurrentTime" :field="fields.time" />
        </div>
      </div>

      <div v-if="filtered.length">
        <div v-for="value in filtered" v-bind:key="value.Id" @click.prevent="specialEventCardLink(value.Id)">

          <div class="row_header cols-5">
            <strong>{{ value.SpecialEventTypeName }}</strong>
            <strong>{{ $t('eventDate') }}</strong>
            <strong>{{ $t('eventTime') }}</strong>
            <strong>{{ $t('eventStatus') }}</strong>
          </div>
          <div class="cols-5">
            <div>
              <div>{{ value.Name }}</div>
            </div>
            <div>
              <div>{{ value.Date }}</div>
            </div>
            <div>
              <div>{{ value.Duration }}</div>
            </div>
            <div>
              <div>{{ value.Status }}</div>
            </div>
          </div>
        </div>
        <HseDivider color="dark" />
      </div>
      <div v-else class="noData">
        {{ $t('noData') }}
      </div>
    </div>
  </div>
</template>

<script>
import { HseHeading, HseSpace, HseDivider } from '@hse-design/vue'
import fields from "./specialEventsFields";
import Validator from "../common/form/Validator";
import { mapState } from "vuex";
import { localizeFields } from "../../i18n";
import { api } from "../../api";
import { dateFormatter } from "../common/dateFormatter";
import Datepicker from "../common/form/Datepicker";
import { COMPLETE_INPUT_LENGTH } from "./constants";


export default {
  name: "SpecialEvent",
  components: {
    HseHeading,
    HseSpace,
    HseDivider,
    Datepicker,
    Validator,
  },
  data: () => ({
    eventId: '',
    data: [],
    currentTime: '',
    COMPLETE_INPUT_LENGTH: COMPLETE_INPUT_LENGTH,
    isTimeSlotBlockOpen: false,
    fields,
  }),
  methods: {
    pickCurrentTime() {
      this.currentTime = this.fields.time.value
      this.isTimeSlotBlockOpen = false
    },
    translateFields() {
      this.fields = localizeFields(fields, this.fields)
    },
    filterByDate(data) {
      return this.dateVal === '' ? true : data.Date === this.dateVal
    },
    filterByTime(data) {
      let isMatchFilter = true
      if (this.currentTime) {
        isMatchFilter = data.Duration === this.currentTime
      }
      return isMatchFilter
    },
    filterByEventFormat(data) {
      return data.SpecialEventFormatName === 'Открытое' || data.SpecialEventFormatName === 'Open'
    },
    requestSpecialEvent() {
      api.request('get_special_events_list', { eventId: this.eventId, lang: this.lang })
        .then(data => {
          this.data = data.map(item => {
            const date = dateFormatter.format(item.Date, 'date')
            return {
              Date: date,
              Duration: item.Duration,
              Id: item.Id,
              Name: item.Name,
              Status: item.RequestStatus,
              SpecialEventTypeName: item.SpecialEventTypeName,
              SpecialEventFormatName: item.SpecialEventFormatName,
            }
          })

          this.fields.time.setTimeArray(this.data)
        })
    },

    specialEventCardLink(cardId) {
      this.$router.push(`/special_events_card/${this.eventId}/${cardId}`)
    },
  },
  created() {
    this.eventId = this.$route.params.eventId
    this.translateFields()
    this.requestSpecialEvent()
  },
  computed: {
    dateVal() {
      return this.fields.date.value
    },
    filtered() {
      const data = this.data
        .filter(event => this.filterByDate(event))
        .filter(event => this.filterByTime(event))
        .filter(event => this.filterByEventFormat(event))
      this.fields.time.setTimeArray(data)
      return data
    },
    ...mapState(['lang']),
  },
  watch: {
    lang() {
      this.requestSpecialEvent()
      this.fields = localizeFields(fields, this.fields)
    }
  },
  i18n: {
    messages: {
      en: {
        header: 'Special events',
        title: 'Events name',
        eventDate: 'Date',
        eventTime: 'Time',
        eventStatus: 'Status',
        noData: 'No data',
      },
      ru: {
        header: 'Специальные мероприятия',
        title: 'Название ивента',
        eventDate: 'Дата',
        eventTime: 'Время',
        eventStatus: 'Статус',
        noData: 'Нет данных',
      },
    }
  }
}
</script>

<style scoped>
.wrapper {
  max-width: 1500px;
  width: calc(100vw - 192px);
  margin: 24px auto;
}

.filters {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.table-card {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 32px 32px;
  max-width: 95%;
}

.cols-5 {
  display: grid;
  /*grid-template-columns: minmax(200px, 1fr) minmax(200px, 2fr) 2fr 120px;*/
  grid-template-columns: minmax(200px, 2fr) minmax(150px, 2fr) minmax(150px, 2fr) minmax(200px, 2fr);
  gap: 16px;
  cursor: pointer;
  padding: 4px 0;
  white-space: normal;
  overflow-x: auto;
}

.row_header {
  background-color: rgb(225, 228, 235);
  color: rgb(107, 122, 153);
  font-size: 14px;
  font-family: Proxima Nova, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  padding: 8px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .wrapper {
    width: calc(100vw - 48px)
  }

  .table-card {
    margin: 0 auto;
    max-width: 100%;
  }

}

@media (max-width: 1024px) {
  .wrapper {
    width: calc(100vw - 48px)
  }

  .table-card {
    margin: 16px auto;
    max-width: 95%;
  }


}
</style>
