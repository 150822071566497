<template>
  <div class="card">
    <HseHeading size="h3">{{ data.ReportTitle }}</HseHeading>
    <HseSpace size="small" vertical />
    <ReadonlyInput :label="$t('number')" :value="data.numberRequest" />
    <ReadonlyInput :label="$t('event')" :value="data.Title" />
    <ReadonlyInput :label="$t('format')" :value="data.ReportExist" />
    <div class="two-cols">
      <ReadonlyInput
        :isActive="
          (data.improvementStatus || data.FinalScore !== null) &&
          (data.Status === 'Оценена' || data.Status === 'Rated') &&
          data.participation === null
        "
        :label="$t('requestStatus')"
        :value="data.Status"
      />
      <ReadonlyInput
        v-if="data.payment_status_int !== -2"
        :label="$t('paymentStatus')"
        :value="paymentStatus"
      />
    </div>
    <div class="two-cols">
      <ReadonlyInput
        v-if="data.FinalScore"
        :isActive="
          data.FinalScore !== null &&
          (data.Status === 'Оценена' || data.Status === 'Rated') &&
          data.participation === null
        "
        :label="$t('reviewStatus')"
        :value="data.FinalScore"
      />
      <ReadonlyInputWithTooltip
        v-if="data.payment_status_int === -2"
        :tooltipText="$t('infoPaymentDisabled')"
        :label="$t('paymentStatus')"
        :value="paymentStatus"
      />
    </div>
    <div class="actions">
      <div v-if="isCollectiveReq">
        <HseSpace size="small" vertical />
        <HseButton
          class="collectiveBtn"
          size="medium"
          v-on:click="confirmParticipation"
          >{{ $t("collectiveReq") }}</HseButton
        >
        <HseSpace size="small" vertical />
      </div>
      <div>
        <div v-if="isShowCertificate">
          <a
            class="link"
            :href="`/files/download_certificate?request=${data.Id}&lang=ru`"
            >{{ $t("certificateRu") }}</a
          >
          <br />
          <a
            class="link"
            :href="`/files/download_certificate?request=${data.Id}&lang=en`"
            >{{ $t("certificateEn") }}</a
          >
        </div>
        <a class="link" :href="`/#/requests/${data.Id}`">{{ $t("open") }}</a>
        <br />
        <a
          v-if="isEditReq"
          class="link"
          :href="`/#/requests/update/${data.Id}`"
          >{{ $t("update") }}</a
        >
      </div>
      <div>
        <a
          v-if="
            isPaymentEnabled &&
            !(this.data.Status === 'Отменена заявителем' && data.ReportExist ==='без доклада')"
          class="link boldLink"
          :href="`/#/requests/${data.Id}/payment`"
          >{{ $t("payment") }}</a
        >
        <br
          v-if="
            data.ReportExist !== 'без доклада' &&
            data.ReportExist !== 'Without a report' &&
            data.collectiveRequestId &&
            isPaymentEnabled
          "
        />
        <a
          v-if="
            data.ReportExist !== 'без доклада' &&
            data.ReportExist !== 'Without a report' &&
            data.collectiveRequestId
          "
          class="link"
          :href="`/#/collective-requests/${data.collectiveRequestId}`"
          >{{ $t("collectiveReqOpen") }}</a
        >
        <br />
        <span
          v-show="
            data.currentCoAuthor === true &&
            data.coAuthorPassportId === null &&
            IsRequiredPassport
          "
          class="link"
          v-on:mouseup="() => ClickAddPassport(data.Id, data.EventId)"
          >{{ $t("pd") }}</span
        >
      </div>
    </div>
    <HseModal v-model="modalPassport" :shouldCloseOnOverlayClick="false">
      <ModalPassport @closeModal="closeModalPassport" :fields="fields" />
    </HseModal>
    <HseModal
      v-model="modalConfirmParticipationIsOpen"
      :shouldCloseOnOverlayClick="false"
    >
      <ModalConfirmParticipation
        :confirmationStatus="confirmationStatus"
        :confirmationMessage="confirmationMessage"
        :spinner="buttonSpinner"
        :field="{ ...field, setValues: this.setField }"
        :collectiveRequestDirection="collectiveRequestDirection"
        :requestId="requestId"
        @closeModal="closeModalParticipation"
        @getBackIfErr="getBackIfErr"
        @approveMember="approveMember"
      />
    </HseModal>
  </div>
</template>

<script>
import ReadonlyInput from "../common/ReadonlyInput.vue";
import ReadonlyInputWithTooltip from "../common/ReadonlyInputWithTooltip.vue";
import {
  HseHeading,
  HseSpace,
  HseButton,
  HseModal,
  HseInput,
} from "@hse-design/vue";
import ModalConfirmParticipation from "./ModalConfirmParticipation.vue";
import ModalPassport from "./ModalPassport.vue";
import { mapState } from "vuex";
import { api } from "../../api";
import fields from "./passportFields";
import { localizeFields } from "../../i18n";
import { store } from "../../store";
export default {
  store,
  name: "eventCard",
  components: {
    ReadonlyInput,
    ReadonlyInputWithTooltip,
    HseHeading,
    HseSpace,
    HseButton,
    HseModal,
    ModalConfirmParticipation,
    ModalPassport,
  },
  data: () => ({
    fields,
    IsRequiredPassport: false,
    requestIdWithAppPassport: "",
    eventIdWithAppPassport: "",
    modalPassport: false,
    modalConfirmParticipationIsOpen: false,
    confirmationStatus: false,
    confirmationMessage: "",
    buttonSpinner: false,
    isMatch: false,
    collectiveRequestDirection: "",
    requestId: "",
    field: {
      isCode: true,
      isValid: false,
      isRequired: true,
      label: "",
      value: null,
      component: HseInput,
    },
  }),
  props: ["data", "changeType", "type", "allData"],
  created() {
    this.field.label = this.$t("code");
    this.translateFields();

    api
      .request("get_citizen", { UserId: this.$store.state.user.id })
      .then((data) => {
        this.citizen = data.Citizen

        setTimeout(() => {
          api
            .request("get_is_required_passport", { EventId: this.eventId })
            .then((data) => {

              const citizenIdRussia = 'BC763F4B-FBD9-461B-B395-166020E8C026' // Российская Федерация

              if (
                  ( data.IsRequiredPassport && data.IsRequiredPassportRussia && this.citizenId === citizenIdRussia ) ||
                  ( data.IsRequiredPassport && data.IsRequiredPassportOther && this.citizenId !== citizenIdRussia )
              ) { this.IsRequiredPassport = true }
            });
        }, 0)
      })
  },
  updated() {
    this.field.label = this.$t("code");
  },
  methods: {
    translateFields() {
      this.fields = localizeFields(fields, this.fields);
    },
    getBackIfErr() {
      this.confirmationStatus = false;
    },
    setField(isValid, value = "") {
      this.field.value = value;
      this.field.isValid = isValid;
    },
    confirmParticipation() {
      this.modalConfirmParticipationIsOpen = true;
    },
    approveMember() {
      const params = {
        code: this.code,
        requestId: this.data.Id,
        requestAutoNum: this.data.numberRequest,
        eventId: this.data.EventId,
      };
      this.buttonSpinner = true;
      api.request("collective_request_approve", params).then((res) => {
        this.buttonSpinner = "done";

        setTimeout(() => {
          this.buttonSpinner = false;
          if (
            res.collectiveRequestId ||
            (Array.isArray(res) &&
              typeof res[0].collectiveRequestId === "string")
          ) {
            this.confirmationStatus = true;
          } else if (
            res.message === "Код подтверждения от другого мероприятия"
          ) {
            this.confirmationStatus = "error";
            this.confirmationMessage = res.message;
          } else {
            this.confirmationStatus = "error";
            this.confirmationMessage = "";
          }
        }, 2000);
      });
    },
    closeModalParticipation(isSend) {
      if (isSend === true) {
        if (this.code === "" || this.code === null) {
          this.field.isValid === false;
          return;
        } else {
          this.field.isValid === true;
        }
        const paramsRequestCheckDirection = {
          code: this.code,
          requestId: this.data.Id,
          eventId: this.data.EventId,
        };
        api
          .request(
            "collective_request_check_direction",
            paramsRequestCheckDirection
          )
          .then((res) => {
            if (res.message === "Error: Wrong confirmation code") {
              this.confirmationStatus = "error";
              this.confirmationMessage = "";
            } else if (
              res.message === "Error: Код подтверждения от другого мероприятия"
            ) {
              this.confirmationStatus = "error";
              this.confirmationMessage = res.message;
            } else if (!res.match) {
              this.confirmationStatus = "without direction";
              this.collectiveRequestDirection = res.collectiveRequestDirection;
              this.requestId = this.data.Id;
            }
            if (res.match) {
              this.approveMember();
            }
          });
      } else if (isSend === "done") {
        this.changeType(this.type.id);
        this.modalConfirmParticipationIsOpen = false;
        this.confirmationStatus = false;
      } else {
        this.modalConfirmParticipationIsOpen = false;
      }
    },
    ClickAddPassport(requestId, eventId) {
      this.requestIdWithAppPassport = requestId;
      this.eventIdWithAppPassport = eventId;
      this.modalPassport = true;
    },
    closeModalPassport(isSend) {
      if (isSend === true) {
        const params = {};
        params.UserId = store.state.user.id;
        // params.RequestIdList = [];
        params.PassportId = this.fields.Passport.value;
        params.RequestIdList = this.allData
          .filter(
            (item) =>
              item.EventId === this.eventIdWithAppPassport &&
              item.currentCoAuthor &&
              item.coAuthorPassportId === null
          )
          .map((item) => item.Id);
        api.request("add_passport_id_for_co_author", params).then(() => {
          this.changeType(this.type.id);
        });
        const paramsPassport = {
          EventId: this.eventIdWithAppPassport,
          Email: store.state.user.login,
          PassportId: this.fields.Passport.value,
        };
        api.request("add_passport", paramsPassport).then((data) => {
          console.log("data requestAdd", data);
          this.modalPassport = false;
        });
      } else {
        this.modalPassport = false;
      }
    },
  },
  computed: {
    isEditReq() {
      const endReportDateTimeStamp = new Date(
        this.data.endReportDate
      ).getTime();
      const endRequesDateTimeStamp = new Date(
        this.data.EndRequestDate
      ).getTime();
      const currentDate = new Date().getTime();
      const data =
        this.data.ReportExist === "с докладом" ||
        this.data.ReportExist === "Speaker"
          ? currentDate < endReportDateTimeStamp
          : currentDate < endRequesDateTimeStamp;
      return (
        data &&
        this.data.Status !== "Отменена заявителем" &&
        this.data.Status !== "Cancelled by user" &&
        this.data.Status !== "Отклонена" &&
        this.data.Status !== "Rejected"
        // this.data.Status !== "Требуется доработка" &&
        // this.data.Status !== "Sent for rework"
      );
    },
    isCollectiveReq() {
      return (
        this.data.Status !== "Отменена заявителем" &&
        this.data.Status !== "Cancelled by user" &&
        this.data.ReportExist !== "без доклада" &&
        this.data.ReportExist !== "Without a report" &&
        this.data.inviteToCollectiveRequests &&
        !this.data.confirmToCollectiveRequests
      );
    },
    code() {
      return this.field.value;
    },
    isShowCertificate() {
      let isValid =
        this.$route.query.type === "archive" &&
        (this.isNotPayed || this.isPayed);
      if (this.data.ReportExistsInt) {
        isValid = isValid && this.data.ReportPresented;
      }
      return isValid;
    },
    isPaymentEnabled() {
      return this.data.payment_status_int == 0;
    },
    isNotPayed() {
      return this.data.payment_status_int === -1;
    },
    isPayed() {
      return this.data.payment_status_int === 1;
    },
    isCertEnabled() {
      return (
        this.data.payment_status_int == -1 || this.data.payment_status_int == 1
      );
    },
    isRuFile() {
      return this.data.ThesesFileID !== null && this.lang === "ru";
    },
    isEnFile() {
      return this.data.ThesesFileEnID !== null;
    },
    paymentStatus() {
      switch (this.data.payment_status_int) {
        case -2:
          return this.$i18n.t("PaymentDisabled");
        case -1:
          return this.$i18n.t("PaymentNotNeeded");
        case 1:
          return this.$i18n.t("payed");
        case 0:
          return this.$i18n.t("notPayed");
        default:
          return "";
      }
    },
    ...mapState(["lang"]),
  },
  i18n: {
    messages: {
      en: {
        payment: "Payment",
        event: "Event",
        number: "Request number",
        format: "Form of participation",
        requestStatus: "Request status",
        reviewStatus: "Review status",
        paymentStatus: "Payment status",
        open: "View request",
        collectiveReqOpen: "Collective request",
        update: "Editing request",
        certificateRu: "Download certificate(ru)",
        certificateEn: "Download certificate(en)",
        notPayed: "Not payed",
        payed: "Payed",
        paymentProcess: "In payment process",
        PaymentNotNeeded: "Payment not needed",
        PaymentDisabled: "Payment disabled",
        PaymentHint:
          "Payment is possible after your paper is validated and approved",
        infoPaymentDisabled:
          "Payment for participation is possible after reviewing the report and including it in the conference program",
        collectiveReq: "Confirm participation in the collective session",
        code: "Code from the letter",
        pd: "Attach a copy of your passport",
      },
      ru: {
        payment: "Оплата",
        event: "Мероприятие",
        number: "Номер заявки",
        format: "Формат участия",
        requestStatus: "Статус заявки",
        reviewStatus: "Статус рассмотрения",
        paymentStatus: "Статус оплаты",
        open: "Просмотр",
        collectiveReqOpen: "Коллективная заявка",
        update: "Редактирование заявки",
        certificateRu: "Скачать сертификат(рус.)",
        certificateEn: "Скачать сертификат(англ.)",
        notPayed: "Не оплачена",
        payed: "Оплачена",
        paymentProcess: "В процессе оплаты",
        PaymentNotNeeded: "Оплата не требуется",
        PaymentDisabled: "Оплата недоступна",
        PaymentHint:
          "Оплата участия возможна после рассмотрения доклада и включения его в программу конференции",
        infoPaymentDisabled:
          "Оплата участия возможна после рассмотрения доклада и включения его в программу конференции",
        collectiveReq: "Подтвердить участие в коллективной сессии",
        code: "Код из письма",
        pd: "Прикрепить копию паспорта ",
      },
    },
  },
};
</script>

<style scoped>
.link {
  font-size: 80%;
  cursor: pointer;
  color: blue;
}
.two-cols {
  display: flex;
  margin: 0 -15px;
}
.two-cols > div {
  padding: 0 15px;
  flex: 1;
}
.link {
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.boldLink {
  font-weight: 900;
}
.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.actions > * {
  margin: 0 15px;
}
.actions .link {
  padding: 12px 0;
}
.collectiveBtn {
  width: fit-content;
}

@media (max-width: 767px) {
  .two-cols,
  .actions {
    flex-direction: column;
  }
  .two-cols > div {
    width: 100%;
  }
  .actions {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
</style>
<style>
.hse-TooltipTarget {
  width: 100%;
}
</style>
