<template>
  <div class="form">
    <div class="subheading">{{ $t("format") }}</div>
    <HseSpace size="small" vertical />
    <div class="field">
      <div v-if="invalidReportDate">
        <div v-for="option in options" :key="option.value">
          <HseRadio
            v-model="radioValue"
            :value="option.value"
            :label="option.label"
            @change="changeRadioButton"
          />
          <HseSpace size="small_2x" vertical />
        </div>
      </div>
    </div>
    <div class="field">
      <!--      <HseSwitcher v-if="invalidReportDate" v-model="isReportExists" :label="$t('reportExists')" size="medium" />-->
      <HseTooltip
        class="tooltip"
        v-if="!invalidReportDate"
        :content="$t('reportExistsInvalidReportDate')"
      >
        <!--        <HseSwitcher v-model="isReportExists" :label="$t('reportExists')" size="medium" inactive />-->
        <div v-for="option in options" :key="option.value">
          <HseRadio
            v-model="radioValue"
            :value="option.value"
            :label="option.label"
            :inactive="true"
            @change="changeRadioButton"
          />
          <HseSpace size="small_2x" vertical />
        </div>
      </HseTooltip>
      <HseSpace size="small_2x" vertical />
    </div>
    <HseSpace size="small_2x" vertical />
    <HseTooltip
      :content="$t('codeTooltip')"
      v-if="showPaymentExemptionCode && !isStudying"
    >
      <div class="payment cols two-cols">
        <div>
          <Validator :field="fields.PaymentExemptionCode" />
        </div>
        <div>
          <HseButton
            :spinner="buttonSpinner"
            spinnerPosition="left"
            @click="checkPaymentCode"
          >
            <span v-if="buttonSpinner === true">{{ $t("checking") }}</span>
            <span v-else-if="buttonSpinner === false">{{
              $t("checkCode")
            }}</span>
            <span v-else-if="buttonSpinner === 'done'">{{
              $t("codeDone")
            }}</span>
          </HseButton>
        </div>
        <HseSpace size="small_2x" vertical />
      </div>
    </HseTooltip>
    <HseSpace size="small_2x" vertical />
    <div v-show="isReportExists">
      <div class="field">
        <Validator v-if="isRussian" :field="fields.languageId" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div v-if="isRussian" class="field">
        <Validator :field="fields.title" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field">
        <Validator :field="fields.titleEN" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="cols two-cols">
        <div class="field">
          <Validator :field="fields.direction1Id" />
          <HseSpace size="small_2x" vertical />
        </div>
        <div class="field">
          <Validator :field="fields.direction2Id" />
          <HseSpace size="small_2x" vertical />
        </div>
      </div>
      <div class="field">
        <FileUploader v-if="isRussian" :field="fields.thesesFileID" />
        <HseSpace size="small" vertical />
      </div>
      <div class="field">
        <FileUploader :field="fields.thesesFileEnID" />
        <HseSpace size="small" vertical />
      </div>
      <div class="field">
        <Validator :field="fields.isExclusive" />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>
    <PaymentFreeCodeModal />
    <!--    <Switcher :field="fields.offline" :label="$t('offline')" size="medium" />-->
    <div v-for="option in preferOnlineOrOffline" :key="option.value">
      <HseRadio
        :field="fields.offline"
        v-model="groupValue"
        :value="option.value"
        :label="option.label"
        @change="changeOnlineStatus"
      />
      <HseSpace size="small_2x" vertical />
    </div>
    <!--    <Radio :field="fields.offline" :options="preferOnlineOrOffline" @change="changeOnlineStatus" v-model="groupValue" />-->
  </div>
</template>

<script>
import { store } from "../../store";
import { HseSpace, HseTooltip, HseButton, HseRadio } from "@hse-design/vue";
import Validator from "../common/form/Validator.vue";
import FileUploader from "../common/form/FileUploader.vue";
// import Switcher from '../common/form/Switcher.vue'
// import Radio from '../common/form/Radio.vue'
import PaymentFreeCodeModal from "../common/modal/PaymentFreeCodeModal";
import { mapState } from "vuex";
import { api } from "../../api";

export default {
  name: "FormPage4",
  store,
  props: ["fields", "eventId"],
  components: {
    HseSpace,
    // HseSwitcher,
    HseTooltip,
    // HseRadioGroup,
    HseRadio,
    // Radio,
    // Switcher,
    Validator,
    FileUploader,
    HseButton,
    PaymentFreeCodeModal,
  },
  data: () => ({
    options: [
      {
        label: "Без доклада",
        value: 1,
      },
      {
        label: "С докладом",
        value: 2,
      },
    ],
    preferOnlineOrOffline: [
      {
        label: "Онлайн",
        value: 1,
      },
      {
        label: "Офлайн",
        value: 2,
      },
    ],
    radioValue: 1,
    groupValue: 1,
    pageFieldKeys: [
      "title",
      "titleEN",
      "thesesFileID",
      "thesesFileEnID",
      "languageId",
      "isExclusive",
      "direction1Id",
    ],
    isReportExists: false,
    invalidReportDate: false,
    buttonSpinner: false,
    isCodeValid: false,
    showPaymentExemptionCode: false,
  }),
  created() {
    api.request("eventGet", { id: this.eventId }).then((data) => {
      this.checkEndReportDate(data[0]);
    });
    this.checkPaymentStatusByEvent();
    this.isReportExists = this.fields.reportExists.value;
    this.setReportLang();
    this.$parent.$on("validatePage", ({ current }) => {
      if (current === 4) {
        this.$emit("validateFields");
        this.checkPageValidity();
      }
    });

    this.translateButtons();
  },
  updated() {
    this.translateButtons();
  },
  computed: {
    isRussian() {
      return this.lang === "ru";
    },
    isStudying() {
      return this.fields.occupationType.value === "2";
    },
    ...mapState(["lang", "error"]),
  },
  methods: {
    translateButtons() {
      const radioList = [
        {
          label: this?.$t("withoutReport"),
        },
        {
          label: this?.$t("reportExists"),
        },
      ];
      this.options.map((item, index) => {
        item.label = radioList[index].label;
      });
      const reportStatus = [
        {
          label: this?.$t("online"),
        },
        {
          label: this?.$t("offline"),
        },
      ];
      this.preferOnlineOrOffline.map((item, index) => {
        item.label = reportStatus[index].label;
      });
    },
    changeRadioButton() {
      this.isReportExists = this.radioValue === 2;
    },
    changeOnlineStatus() {
      if (this.groupValue === 2) {
        this.fields.offline.setValues(true, true);
      } else {
        this.fields.offline.setValues(true, false);
      }
    },
    checkPaymentStatusByEvent() {
      this.buttonSpinner = true;
      const params = {
        EventId: this.eventId,
      };
      api.request("payment_status_by_event", params).then((response) => {
        if (response === 0 || response === false) {
          this.showPaymentExemptionCode = true;
        }
      });
      this.buttonSpinner = false;
    },
    checkPaymentCode() {
      this.buttonSpinner = true;
      const params = {
        EventId: this.eventId,
        Code: this.fields.PaymentExemptionCode.value,
      };
      api.request("payments_check_exemption_code", params).then((response) => {
        if (response === true) {
          this.isCodeValid = true;
          this.fields.PaymentExemptionCode.setValues(
            true,
            this.fields.PaymentExemptionCode.value
          );
        } else {
          this.isCodeValid = false;
          this.fields.PaymentExemptionCode.setValues(
            false,
            this.fields.PaymentExemptionCode.value
          );
        }
        this.buttonSpinner = false;
        if (response) {
          this.$root.$refs.paymentFreeCodeModal.openOfferModal(response);
          this.buttonSpinner = "done";
        } else {
          this.$root.$refs.paymentFreeCodeModal.openOfferModal(
            this.error.text.code
          );
        }
      });
    },
    checkEndReportDate(event) {
      const endDate = new Date(event.EndReportDate).getTime(),
        startDate = new Date(event.StartReportDate).getTime(),
        currentDate = new Date().getTime();
      this.invalidReportDate = currentDate < endDate && startDate < currentDate;
    },
    checkPageValidity() {
      let isPageValid = true;
      if (this.fields.PaymentExemptionCode.value !== "" && !this.isCodeValid) {
        this.fields.PaymentExemptionCode.setValues(
          false,
          this.fields.PaymentExemptionCode.value
        );
        this.$root.$refs.paymentFreeCodeModal.openOfferModal(9);
      } else {
        this.fields.PaymentExemptionCode.setValues(
          true,
          this.fields.PaymentExemptionCode.value
        );
      }
      if (!this.fields.PaymentExemptionCode.isValid) {
        isPageValid = false;
      }
      if (isPageValid && this.isReportExists) {
        let skip = ["title", "thesesFileID"];
        isPageValid = !this.pageFieldKeys.some((key) => {
          return !this.isRussian && skip.includes(key)
            ? false
            : !this.fields[key].isValid;
        });
      }

      this.$emit("validatePage", { page: 4, isValid: isPageValid });
    },
    setReportLang() {
      this.fields.languageId.setValues(
        !this.isRussian,
        this.isRussian ? "" : "D909F362-2610-49C9-BFA2-57692617EF76"
      );
    },
  },
  watch: {
    isReportExists(val) {
      this.fields.reportExists.setValues(true, val);
    },
    lang() {
      this.setReportLang();
    },
  },
  i18n: {
    messages: {
      en: {
        withoutReport: "Without a paper",
        reportExists: "With a paper",
        online: "Prefer online",
        offline: "Prefer face-to-face",
        format: "Form of participation",
        reportExistsInvalidReportDate:
          "Registration to the event for speakers is prohibited",
        checkCode: "Apply",
        checking: "Checking the code",
        codeDone: "Code applied",
        codeTooltip: "Enter the code provided by Organizing Committee",
      },
      ru: {
        withoutReport: "Без доклада",
        reportExists: "С докладом",
        online: "Предпочитаю онлайн",
        offline: "Предпочитаю очно",
        format: "Формат участия",
        reportExistsInvalidReportDate:
          "Прием заявок на участие с докладом запрещен",
        checkCode: "Применить",
        checking: "Проверяем код",
        codeDone: "Код применен",
        codeTooltip: "Введите код, предоставленный оргкомитетом",
      },
    },
  },
};
</script>

<style scoped>
.payment.two-cols {
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;
}
.payment > div {
  padding: 0 15px;
  width: 100%;
  min-width: 320px;
}
.payment .hse-Button {
  margin-bottom: 8px;
}
.form {
  max-width: 670px;
}
.cols {
  display: flex;
  margin: 0 -15px;
  flex-wrap: wrap;
}
.cols > .field {
  padding: 0 15px;
  flex: 1;
}
.two-cols .field {
  max-width: 50%;
  width: 50%;
  flex-basis: 50%;
}
.tooltip {
  width: fit-content;
}
@media (max-width: 767px) {
  .payment.two-cols {
    flex-wrap: wrap;
  }
  .form {
    max-width: unset;
  }
  .two-cols .field,
  .three-cols .field {
    max-width: 100%;
    width: 100%;
    flex-basis: 100%;
  }
}
</style>
