<template>
    <div>
        <HseModalInfo v-model="isOpen" :modal-style="{ maxHeight: '800px' }" ref="modal">
            <div v-if="offerData" v-html="offerData"></div>
            <div v-else>{{ $t('error') }}</div>
        </HseModalInfo>
    </div>
</template>

<script>
import { HseModalInfo,  } from '@hse-design/vue'
import { store } from '../../../store'

export default {
    store,
    components: {
      HseModalInfo,
    },
    props: {
        offerData: {
            type: String
        }
    },
    data: () => ({
      isOpen: false,
      isLoading: false,
    }),
    created () {
      this.$root.$refs.offerModal = this;
    },
    methods: {
      openOfferModal() {
        this.isOpen = true;
      }
    },
    i18n: {
      messages: {
        en: {
          error: 'Public offer not found'
        },
        ru: {
          error: 'Публичная оферта не найдена'
        }
      },
    }
}
</script>

<style scoped>
</style>