<template>
  <div>
    <HseModalForm v-model="isOpen" :title="$t('select')">

      <template #actions>
        <HseButton :spinner="isLoading" @click="send">
          {{$t('send')}}
        </HseButton>
        <HseSpace size='small_2x' horizontal />
        <HseButton variant='secondary' @click="isOpen = false">
          {{$t('close')}}
        </HseButton>
      </template>
      
      <div class='big-item'>
        <Validator v-bind:field="fields.PartisipationConfirmationStatus" />
        <FileUploader :field="fields.PresentationFileId" />
        <span v-if="fields.PresentationFileId.value" class="remove-file" @click="removePresentationFile">{{$t('removeFile')}}</span>
      </div>

    </HseModalForm>
  </div>
</template>

<script>

  import { HseModalForm, HseButton, HseSpace } from '@hse-design/vue' 
  import Validator from '../common/form/Validator.vue'
  import fields from './userFields'
  import { mapState } from 'vuex'
  import { localizeFields } from '../../i18n'
  import { api } from '../../api'
  import FileUploader from '../common/form/FileUploader.vue'
  import { menu } from '../common/leftMenu/menuItems'
  import { store } from '../../store'

  export default {
    props: ['request_id'],
    components: {
      HseModalForm,
      HseButton,
      HseSpace,
      Validator,
      FileUploader,
    },
    data: () => ({
      isOpen: false,
      isLoading: false,
      fields,
      req: null,
      isDataLoaded: false,
      consentPublicationInCollection: 'true',
      options: Object.freeze([
        {
          label: 'Да',
          value: 'true',
        },
        {
          label: 'Нет',
          value: 'false',
        }
      ]),
    }),
    created () {
      this.translateFields()
      this.translateOptions()
      this.$parent.$on('openCommitModal', () => {this.isOpen = true})
      this.requestEventData();
    },
    updated () {
      this.translateOptions()
      this.getNotificationsList();
    },
    methods: {
      getNotificationsList () {
        api.request("get_requests_notification_status").then(res => {
          store._mutations.setMenuNotifications[0](res)
          menu.getItems(this.$store.state.role).map(item => {
            if(item.id === 3) {
              store._mutations.setCountNotifications[0](this.$store.state.menuNotifications.length)
            }
            return item
          })
        })
      },
      translateOptions () {
        const optionsLang = [
          {
            label: this.$t('yes'),
            value: 'true',
          },
          {
            label: this.$t('no'),
            value: 'false',
          }
        ]
        this.options.map((item, index) => item.label = optionsLang[index].label)
      },
      removePresentationFile () {
        this.fields.PresentationFileId.value = ''
      },
      requestEventData () {
        this.isDataLoaded = false
        api.request('requestGetFull', { id: this.request_id }).then((res) => {
          this.req = res
          this.isDataLoaded = true

          this.fields.PartisipationConfirmationStatus.value = String(res.Participation);
        })
      },
      send() {
        if (this.fields.PartisipationConfirmationStatus.value === 'true') {
          const params = {
            requestId: this.req.Id,
            consentPublicationInCollection: this.consentPublicationInCollection === 'true' ? true : false
          }
          api.request('requestConsentPublicationInCollectionUpdate', params)
        }
        this.isLoading = true;
        this.$emit('openCommitModalResponse', {
          participation: this.fields.PartisipationConfirmationStatus.value,
          presentation: this.fields.PresentationFileId.value
        })
        setTimeout(() => {
          this.isOpen = false;
          this.isLoading = false;
        }, 200);
      },
      translateFields () {
        this.fields = localizeFields(fields, this.fields)
        this.fields.PartisipationConfirmationStatus.selectData = this.$i18n.t('confirmSelectData');
      }
    },
    computed: mapState(['lang']),
    watch: {
      lang () {
        this.translateFields()
      }
    },
    i18n: {
      messages: {
        en: {
          send: 'Send',
          select: 'Participation confirm and upload presentation',
          publicationInCollection: 'Are you ready to publish the report in the collection following the results of the conference?',
          yes: 'Yes',
          no: 'No',
          confirmSelectData: [
            // { value: 'null', label: 'No data' },
            { value: 'true', label: 'Confirm participation' },
            { value: 'false', label: 'Decline participation' },
          ],
          close: 'Cancel',
          removeFile: 'Delete presentation file',
        },
        ru: {
          send: 'Отправить',
          select: 'Подтверждение участия и загрузка презентации',
          publicationInCollection: 'Готовы ли Вы опубликовать доклад в сборнике по итогам конференции?',
          yes: 'Да',
          no: 'Нет',
          confirmSelectData: [
            // { value: 'null', label: 'Решение не принято' },
            { value: 'true', label: 'Подтверждаю свое участие в мероприятии' },
            { value: 'false', label: 'Не подтверждаю свое участие в мероприятии' },
          ],
          close: 'Отмена',
          removeFile: 'Удалить файл презентации',
        },
      }
    } 
  }
</script>

<style scoped>
  .remove-file {
    font-size: 14px;
    line-height: 20px;
    color: #0050cf;
    cursor: pointer;
    text-decoration: underline;
  }
  .event-card {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 32px;
    max-width: 800px;
    margin: 52px auto;
  }
  .big-item {
    min-height: 400px;
  }
  .link {
    white-space: nowrap;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  .two-cols {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .three-cols {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
  }
  .prepull{
    overflow:auto;
  }
  .pull-right {
    float:right;
  }
</style>