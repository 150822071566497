<template>
    <div class="report">
        <HseHeading size="h3">{{ $t('headings.report') }}</HseHeading>
        <HseContentParagraph>
            {{ $t('action') }}
        </HseContentParagraph>
        <HseSpace size='small' vertical />
        <div class="buttonGroup">
            <HseButton
                variant='primary'
                size='medium'
                v-on:click="() => closeModalWindow(false)"
            >
                <span>{{ $t('closeReport') }}</span>
            </HseButton>
            <HseSpace size='small' horizontal />
            <HseButton
                variant='secondary'
                size='medium'
                v-on:click="() => closeModalWindow(true)"
            >
                <span>{{ $t('openReport') }}</span>
            </HseButton>
        </div>
    </div>
</template>
<script>
    import { HseHeading, HseSpace, HseButton, HseContentParagraph } from '@hse-design/vue'
    export default {
        components: {
            HseHeading,
            HseSpace,
            HseButton,
            HseContentParagraph,
        },
        props: ['fields'],
        mounted () {
            const closeBtn = document.querySelector('.hse-BaseModal__wrapper > button')
            closeBtn.style.display = 'none'
        },
        methods: {
            closeModalWindow (val) {
                this.fields.reportExists.setValues(true, val)
                this.$emit('closeModal')
            }
        },
        i18n: {
            messages: {
                en: {
                    headings: {
                        report: 'If you change the format, all information about the report will be deleted'
                    },
                    action: 'Do you want to continue?',
                    closeReport: 'yes',
                    openReport: 'no'
                },
                ru: {
                    headings: {
                        report: 'При изменении формата вся информация о докладе будет удалена'
                    },
                    action: 'Хотите продолжить?',
                    closeReport: 'да',
                    openReport: 'нет'
                }
            }
        }
    }
</script>
<style>
    .buttonGroup {
        display: flex;
    }
</style>