<template>
  <div class="visa-card">
    <after-send-message-modal />
    <Loading v-if="!isDataLoaded" />
    <div v-else>
      <HseHeading size="h3">
        {{ $t('caption') }}
      </HseHeading>
      <HseSpace size='small' vertical />

      <div v-if="isNew">
        <Validator v-bind:field="fields.event" />
        <HseSpace size='small_2x' vertical />

        <Validator v-bind:field="fields.subject" />
        <HseSpace size='small_2x' vertical />
      </div>

      <Validator v-bind:field="fields.body" />
      <HseSpace size='small_2x' vertical />

      <FileUploader :field="fields.attachment" />
      <HseSpace size='medium' vertical />

      <div class="form-footer">
        <div class="actions">
          <HseButton v-on:click="saveMessage">
            <span v-if="!isNew">{{ $t('reply') }}</span>
            <span v-else>{{ $t('send') }}</span>
          </HseButton>
          <HseButton variant="secondary" v-on:click="$router.go(-1)">
            {{ $t('back') }}
          </HseButton>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {
  HseHeading,
  HseSpace,
  HseButton,
  IconGlobalEmail,
  IconActionArrowBottom,
  IconActionArrowTop,
  IconActionAttach,
} from '@hse-design/vue'
import AfterSendMessageModal from "../common/modal/AfterSendMessageModal";
import { api } from '../../api'
import { mapState } from 'vuex'
import Validator from '../common/form/Validator.vue'
import fields from './messageFields'
import FileUploader from '../common/form/FileUploader.vue'
import Loading from '../common/Loading.vue'
import { localizeFields } from '../../i18n'

export default {
  components: {
    HseHeading,
    HseSpace,
    HseButton,
    Validator,
    FileUploader,
    Loading,
    AfterSendMessageModal,
  },
  data: () => ({
    reply_to: null,
    message: null,
    isDataLoaded: false,
    IconActionArrowBottom,
    IconActionArrowTop,
    IconGlobalEmail,
    IconActionAttach,
    fields,
  }),
  created() {
    this.reply_to = this.$route.params.reply;
    this.load();
    this.translateFields()
  },
  computed: {
    isNew() {
      return this.reply_to === 'new'
    },
    ...mapState(['lang'])
  },
  watch: {
    lang() {
      this.translateFields()
    }
  },
  methods: {
    translateFields() {
      this.fields = localizeFields(fields, this.fields)
    },
    load() {
      if (!this.isNew) {
        api.request('message.get', this.reply_to).then((res) => {
          this.message = res;
          this.fields.event.value = res.EventId
          this.fields.subject.value = 'Re:' + res.MessageSubject
          this.isDataLoaded = true;
        })
      } else {
        this.isDataLoaded = true;
      }
    },
    saveMessage() {
      this.$emit('validateFields')
      const isInvalid = this.isNew
        ? Object.keys(this.fields).some(key => !this.fields[key].isValid)
        : ['attachment', 'body'].some(key => !this.fields[key].isValid)

      if (isInvalid) return

      const attachment = this.fields.attachment.value ? this.fields.attachment.value : null
      api.request('userMessageAdd', {
        eventId: this.fields.event.value,
        messageSubject: this.fields.subject.value,
        messageBody: this.fields.body.value,
        messageAttachment: attachment
      }).then(() => {
        this.$root.$refs.afterSendMessageModal.openModal();
        setTimeout(() => {
          this.$router.push('/messages')
        }, 2000)
      })
    },
  },
  i18n: {
    messages: {
      en: {
        caption: 'New message',
        send: 'Send',
        reply: 'Reply',
      },
      ru: {
        caption: 'Новое сообщение',
        send: 'Отправить',
        reply: 'Ответить',
      },
    }
  }
}
</script>

<style scoped>
.visa-card {
  background: #FFFFFF;
  border-radius: 12px;
  margin: 53px auto;
  padding: 32px 32px;
  max-width: calc(100vw - 320px)
}

.actions .hse-Button {
  display: inline-block;
}

.hse-Button:first-child {
  margin-right: 12px;
}

.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

@media (max-width: 1023px) {
  .visa-card {
    margin: 16px auto;
    max-width: 95%;
  }
}

@media (max-width: 767px) {
  .visa-card {
    margin: 0 auto;
    max-width: 100%;
  }

  .form-footer {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
