<template>
  <div class="select" ref="select">
    <div class="label" @click="() => toggleDropdown()">
      <slot name="label" />
      <span v-if="!$slots.label">{{ current.label }}</span>
    </div>
    <div class="dropdown" :class="{ visible: isOpen }">
      <div class="items">
        <div class="item" v-for="item in values" :key="item.value" @click="() => chooseItem(item)" :class="{ active: item.value === current.value }">
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['value', 'values'],
    data: () => ({
      isOpen: false,
    }),
    computed: {
      current () {
        return this.values.find(item => item.value === this.value) || { label: this.value, value: this.value }
      },
    },
    methods: {
      toggleDropdown (isOpen) {
        this.isOpen = isOpen === undefined ? !this.isOpen : isOpen
        if (this.isOpen)
          addEventListener('click', this.closeDropdown, false)
      },
      closeDropdown ({ target }) {
        if (!this.$refs.select) return

        if (this.isOpen && !this.$refs.select.contains(target)) {
          this.toggleDropdown(false)
          removeEventListener('click', this.closeDropdown, false)
        }
      },
      chooseItem (item) {
        this.toggleDropdown(false)
        removeEventListener('click', this.closeDropdown, false)
        this.$emit('change', item)
      },
    },
  }
</script>

<style scoped>
  .select {
    position: relative;
  }
  .label {
    cursor: pointer;
  }
  .dropdown {
    position: absolute;
    display: none;
  }
  .dropdown.visible {
    display: block;
  }
  .item {
    width: fit-content;
    cursor: pointer;
  }
  .item.active {
    font-weight: 800;
  }
</style>