import { store } from './store/index'
class Api {
  constructor() {
    this.token = ''
  }

  async refresh() {
    let res = await this.jrpc("/auth/web", "user.token.refresh", null);
    this.token = res.result && typeof res.result === 'string' ? res.result : "";
    if (this.token) {
      store._mutations.setUser[0](await this.auth("user.get", null, null))
      this.getRoles()
      store._mutations.setRole[0](1)
    }
  }

  async getRoles() {
    this.request('user.roles').then(data => {
      if (!data) return

      const roles = []
      if (data.is_member) roles.push({ id: 1, name: 'participant' })
      if (data.is_expert) roles.push({ id: 3, name: 'expert' })
      if (data.is_manager) roles.push({ id: 2, name: 'manager' })

      if (roles.length) {
        store._mutations.setRoles[0](roles)
      }
    })
  }

  async login(login, password) {
    try {
      let res = await this.jrpc("/auth/web", "user.login", { login, password });
      if (res.error) {
        switch (res.error.code) {
          case 7: throw { code: res.error.code, message: "Пользователь не найден" };
          case 3: throw { code: res.error.code, message: "Некорректный пароль" };
          default: throw res.error;
        }
      }
      api.token = res.result;
      store._mutations.setUser[0](await this.auth("user.get", null, null))
      this.getRoles()
    } catch (error) {
      store._mutations.setError[0]({ url: 'login', code: error.code, text: error.message });
    }
  }

  async sendCode(login) {
    try {
      let res = await this.jrpc('/auth/', 'user_send_one_time_code', { login, lang: store.state.lang })
      if (res.error) {
        switch (res.error.code) {
          case 7: throw { code: res.error.code, message: "Пользователь не найден" };
          default: throw res.error;
        }
      }
    } catch (error) {
      store._mutations.setError[0]({ url: 'sendCode', code: error.code, text: error.message })
    }
  }

  async loginByCode(login, code) {
    try {
      let res = await this.jrpc("/auth/web", "user_login_by_code", { login, code });
      if (res.error) {
        switch (res.error.code) {
          case 7: throw { code: res.error.code, message: "Пользователь не найден" };
          case 3: throw { code: res.error.code, message: "Некорректный пароль" };
          default: throw res.error;
        }
      }
      api.token = res.result;
      store._mutations.setUser[0](await this.auth("user.get", null, null))
      this.getRoles()
    } catch (error) {
      store._mutations.setError[0]({ url: 'loginByCode', code: error.code, text: error.message });
    }
  }

  async loginByADFS() {
    const adfs = new URLSearchParams(window.location.search).get('adfs')
    if (adfs) {
      window.history.pushState({}, '', window.location.pathname);
      api.jrpc("auth/web", "callback", { code: adfs }).then(({ result: token }) => {
        api.token = token
        //Вернуть когда бэк пофиксит
        // api.auth("user.get", null, null).then(user => {
        //   this.$store._mutations.setUser[0](user)
        //   api.getRoles()
        // })
        //Убрать когда бэк пофиксит
        this.refresh()
      })
    }
  }

  async logout() {
    try {
      let res = await this.jrpc("/auth/web", "user.logout", null);
      if (res.error && res.error.code == 2) {
        await this.refresh();
        if (this.token)
          res = await this.jrpc("/auth/web", "user.logout", null);
      }

      if (res.error) throw res.error;
      store._mutations.setRole[0](0)
      store._mutations.setUser[0](null);
    } catch (error) {
      store._mutations.setError[0]({ url: 'logout', text: error });
    }
  }

  async billing_web(method, params, errValue) {
    try {
      let res = await this.jrpc("/auth/web", method, params);
      if (res.error && res.error.code == 2) {
        await this.refresh();
        if (this.token)
          res = await this.jrpc("/auth/web", method, params);
      }

      if (res.error) throw res.error;
      return res.result;
    } catch (error) {
      store._mutations.setError[0]({ url: 'billing_web', text: error });
      console.log("Ошибка", error)
      return errValue;
    }
  }

  async auth(method, params, errValue) {
    try {
      let res = await this.jrpc("/auth/", method, params);
      if (res.error && res.error.code == 2) {
        await this.refresh();
        if (this.token)
          res = await this.jrpc("/auth/", method, params);
      }

      if (res.error) throw res.error;

      store._mutations.setUserDataFull[0](await this.request('user_check_full', null));

      return res.result;
    } catch (error) {
      store._mutations.setError[0]({
        url: `auth ${method}`,
        text: error && error.message ? error.message : error,
        code: error && error.code ? error.code : error,
      });
      return errValue;
    }
  }

  async request(method, reqParams, errValue) {
    let params = reqParams ? JSON.parse(JSON.stringify(reqParams)) : reqParams
    if (params === null || params === undefined)
      params = {}
    if ((typeof params === 'object') && (!params.lang))
      params.lang = store.state.lang

    try {
      let res = await this.jrpc("/api/", method, params);
      if (res.error && res.error.code == 2) {
        await this.refresh();
        if (this.token)
          res = await this.jrpc("/api/", method, params);
      }

      if (res.error) throw res.error;
      return res.result;
    } catch (error) {
      store._mutations.setError[0]({ url: `api ${method}`, text: error });
      return errValue ? errValue : error;
    }
  }

  async jrpc(url, method, params) {
    let headers = {
      'Content-Type': 'application/json',
      'JRPC-Method': method
    }
    this.token && (headers.Authorization = `Bearer ${this.token}`);

    let body = {
      jsonrpc: '2.0',
      method,
      id: (~~(Math.random() * 1e8)).toString(16)
    }
    params && (body.params = params);

    const options = {
      method: 'POST',
      credentials: "same-origin",
      headers,
      body: JSON.stringify(body)
    }

    let response = await fetch(url, options)
    if (!response.ok) throw response.statusText;
    return response.json();
  }

  async post_file(url, params) {
    const formData = new FormData();

    Object.keys(params).forEach(key => {
      formData.append(key, params[key]);
    })

    const options = {
      method: 'POST',
      body: formData
    }

    let response = await fetch(url, options);

    if (!response.ok) throw response.statusText;
    return response.json();
  }

  async getStaticData() {
    const { strings, logos, agreements } = await this.request('get_static_data')
    store.commit('setStrings', strings)
    store.commit('setLogos', logos)
    store.commit('setAgreements', agreements)
  }
}

export const api = new Api();