<template>
  <div class="wrapper">
    <div v-if="isLoading" class="no-data">
      <Loading />
    </div>
    <HseTabs v-else class="tabs" v-model="activeTab" :tabs="tabs" />
    <component v-if="activeTab === '0' && !isLoading" :is="tabs[0].component" :hasPaid="hasPaid"
      :filterOptions="filterOptions" :datesMap="datesMap" :roomsMap="roomsMap" :sectionsData="tabs[0].content"
      :updateEventFavorites="updateEventFavorites" :invitedReportTypeId="invitedReportTypeId"
      :sessionTypeId="sessionTypeId" :bookLaunchTypeId="bookLaunchTypeId" :roundtableTypeId="roundtableTypeId"
      :honoraryPaperTypeId="honoraryPaperTypeId" @selectedSection="onSelectedSection" @selectedDate="onSelectedDate"
      @selectedAddress="onSelectedAddress" @selectedSessionType="onSelectedSessionType" />

    <component v-if="activeTab === '1' && !isLoading" :is="tabs[1].component" :plenaryData="tabs[1].content"
      :datesMap="datesMap" />
    <component v-if="activeTab === '2' && !isLoading" :is="favoritesComponent" :favoritesData="favoritesData"
      :datesMap="datesMap" :updateEventFavorites="updateEventFavorites" :sessionTypeId="sessionTypeId"
      :roundtableTypeId="roundtableTypeId" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import SectionsProgram from "../components/EventProgramPage/SectionsProgram";
import PlenaryProgram from "../components/EventProgramPage/PlenaryProgram";
import Favorites from "../components/EventProgramPage/Favorites";
import { api } from "../api";
import { HseButton, HseInput, HseTabs } from "@hse-design/vue";
import Loading from "../components/common/Loading.vue";

function sortByOrder(left, right) {
  return left.OrderBy - right.OrderBy;
}

function filterFavorites(event) {
  event.Sections = event.Sections.filter((section) => {
    section.Sessions = section.Sessions.filter((session) => {
      session.Items = session.Items.filter(
        (item) => item.UserFavoriteSessionItemId
      );
      return session.Items.length;
    });
    return section.Sessions.length;
  });
  return event.Sections.length;
}

export default {
  data: () => ({
    responseAddresses: [],
    responseTimeSlots: [],
    responseSessionTypes: [],

    eventId: "",
    eventListAll: [],
    eventListDate: [],
    invitedReportTypeId: "ce67dd60-ae3e-4215-a6bb-5601a7354f63",
    bookLaunchTypeId: "6c79f16d-40b4-4091-adf2-6635c30deb01",
    honoraryPaperTypeId: "b40acf74-7313-4f6d-846d-e9c9d1cc99bd",
    roundtableTypeId: "40799327-dc3f-4d56-ad36-80c245ee2d94",
    sessionTypeId: "6ed0e211-17ac-44fc-bb42-395830ef3c63",
    activeTab: "0",
    isLoading: 0,
    filterOptions: {
      sections: [],
      addresses: [],
      sessionTypes: [],
      dates: [],
    },
    datesMap: new Map(),
    roomsMap: new Map(),
    favoritesData: null,
    favoritesComponent: null,
    sections: [],
    addresses: [],
    uniqueChars: [],
    hasPaid: false,
    tabs: [
      {
        value: "0",
        component: SectionsProgram,
        content: [],
        label: "",
      },
      {
        value: "1",
        component: PlenaryProgram,
        content: [],
        label: "",
      },
      {
        value: "2",
        component: Favorites,
        content: [],
        label: "",
      },
    ],
    selectedSection: 'all',
    selectedDate: 'all',
    selectedAddress: 'all',
    selectedSessionType: 'all',
  }),
  props: ["data"],
  components: { HseButton, HseTabs, Loading, HseInput },
  created() {
    this.eventId = this.$route.params.id;

    this.getPaymentStatus();
    this.initPage();
  },
  methods: {
    initPage() {
      this.tabs = [
        {
          value: "0",
          component: SectionsProgram,
          content: [],
          label: "",
        },
        {
          value: "1",
          component: PlenaryProgram,
          content: [],
          label: "",
        },
        {
          value: "2",
          component: Favorites,
          content: [],
          label: "",
        },
      ];
      this.requestSectionsProgram();
      this.requestPlenary();
      this.requestSections();
      this.requestAddresses();
      this.requestSessionTypes();
      this.requestDates()

      this.tabs[0].label = this.$t("sectionsProgram");
      this.tabs[1].label = this.$t("plenarySessions");
      this.tabs[2].label = this.$t("favorites");
    },
    onSelectedAddress({ selectedAddress }) {
      this.selectedAddress = selectedAddress
      this.filteredSessionTypes()
    },
    onSelectedDate({ selectedDate }) {
      this.selectedDate = selectedDate
      this.filteredAddresses()
      this.filteredSessionTypes()
    },
    onSelectedSessionType({ selectedSessionType }) {
      this.selectedSessionType = selectedSessionType
    },
    onSelectedSection({ selectedSection }) {
      this.selectedSection = selectedSection
      this.filteredTimeslots()
      this.filteredAddresses()
      this.filteredSessionTypes()
    },
    filterEvents(
      event,
      selectedSection,
      selectedFullDate,
      selectedSessionType,
      selectedAddress
    ) {
      const roomIds = selectedAddress?.split("//");

      const [selectedMonth, selectedDate] = selectedFullDate
        .split("/")
        .map((elem) => parseInt(elem));

      return event.filter((event) => {
        event.Sections = event.Sections.filter((section) => {
          section.Sessions = section.Sessions.filter(
            (session) =>
              (selectedSessionType === "all" ||
                selectedSessionType === session.TypeId) &&
              (selectedAddress === "all" ||
                !selectedAddress ||
                roomIds.includes(session.RoomId))
          );

          return (
            section.Sessions.length &&
            (selectedSection === "all" ||
              !selectedSection ||
              selectedSection === section.IdSql)
          );
        });
        const fullDate = new Date(event.Date);
        const date = fullDate.getDate();
        const month = fullDate.getMonth();
        return (
          event.Sections.length &&
          (selectedFullDate === "all" ||
            (selectedMonth === month && selectedDate === date))
        );
      });
    },
    async getPaymentStatus() {
      const data = await api.request(
        "eventListForUser",
        { archive: false, offset: 0, limit: 0 },
        []
      );
      const event = data.find((event) => event.Id === this.eventId);
      this.hasPaid = !!event.PaymentStatus;
    },
    async requestSectionsProgram() {
      this.isLoading++;

      const data = await api.request(
        "get_event_programs",
        { eventId: this.eventId },
        []
      );
      this.isLoading--;

      let idx = 1;
      for (const date of data) {
        date.DayNumber = idx;

        let idExtend = 0;
        for (const section of date.Sections) {
          section.Sessions.sort(sortByOrder);
          section.IdExtend = idExtend;
          idExtend++;

          for (const session of section.Sessions) {
            session.Items.sort(sortByOrder);

            if (
              session.ZoomMeetingUrl &&
              !session.ZoomMeetingUrl.includes("http")
            ) {
              session.ZoomMeetingUrl = `http://${session.ZoomMeetingUrl}`;
            }
          }
        }
        idx++;
      }
      this.tabs[0].content = data;
      this.eventListAll = data

      let test = [];

      data.map((item) => {
        item.Sections.map((section) => {
          section.Sessions.map((session) => {
            test.push(session.TypeId);
          });
        });
      });
      this.uniqueChars = [...new Set(test)];

      this.updateFavoritesPage();
    },
    async requestPlenary() {
      this.isLoading++;
      const data = await api.request(
        "get_event_planery_sessions",
        { eventId: this.eventId },
        []
      );
      this.tabs[1].content = data;
      if (data.length === 0) {
        this.tabs.splice(1, 1);
      }

      this.isLoading--;
    },
    async requestSections() {
      this.isLoading++;
      const data = await api.request(
        "get_event_program_sections",
        { eventId: this.eventId },
        []
      );
      this.isLoading--;

      this.filterOptions.sections = [
        {
          value: "all",
          label: this.$t("allSections"),
        },
      ];
      for (const section of data) {
        this.filterOptions.sections.push({
          value: section.Id,
          label: `${this.$t("section")} ${section.Code}. ${section.Title}`,
        });
      }
    },
    async requestAddresses() {
      this.isLoading++;
      try {
        const data = await api.request(
          "get_event_addresses",
          { eventId: this.eventId },
          []
        );
        this.responseAddresses = data;

        this.filterOptions.addresses = [
          {
            value: "all",
            label: this.$t("allAddresses"),
          },
        ];

        this.roomsMap = new Map()

        for (const address of data) {
          let isRoom = false
          for (const room of address.RoomIds) {
            this.roomsMap.set(room, address.Title);

            const events = this.filterEvents(
              JSON.parse(JSON.stringify(this.eventListAll)),
              "all",
              "all",
              "all",
              room
            )

            if (events.length) {
              isRoom = true
            }
          }

          if (isRoom) {
            this.filterOptions.addresses.push({
              value: address.RoomIds.join("//"),
              label: address.Title ? address.Title : " ",
            });
          }
        }
      } finally {
        this.isLoading--;
      }
    },
    filteredAddresses() {
      const filteredAddressees = [
        {
          value: "all",
          label: this.$t("allAddresses"),
        },
      ];

      this.roomsMap = new Map()

      for (const address of this.responseAddresses) {
        let isRoom = false
        for (const room of address.RoomIds) {
          this.roomsMap.set(room, address.Title);

          const events = this.filterEvents(
            JSON.parse(JSON.stringify(this.eventListAll)),
            this.selectedSection,
            this.selectedDate,
            "all",
            room
          )

          if (events.length) {
            isRoom = true
          }
        }

        if (isRoom) {
          filteredAddressees.push({
            value: address.RoomIds.join("//"),
            label: address.Title ? address.Title : " ",
          });
        }

        this.filterOptions.addresses = filteredAddressees
      }
    },
    async requestSessionTypes() {
      this.isLoading++;
      try {
        const data = await api.request(
          "get_event_program_session_type",
          { eventId: this.eventId },
          []
        );
        data.sort((left, right) => left.OrderBy - right.OrderBy);
        this.responseSessionTypes = data

        this.filterOptions.sessionTypes = [
          {
            value: "all",
            label: this.$t("allSessionTypes"),
          },
        ];
        for (const type of data) {
          if (this.uniqueChars.includes(type.Id.toLowerCase())) {
            this.filterOptions.sessionTypes.push({
              value: type.Id.toLowerCase(),
              label: type.Title,
            });
          }
        }
      } finally {
        this.isLoading--;
      }
    },
    filteredSessionTypes() {
      const sessionTypes = [
        {
          value: "all",
          label: this.$t("allSessionTypes"),
        },
      ];

      for (const type of this.responseSessionTypes) {
        if (this.uniqueChars.includes(type.Id.toLowerCase())) {
          const events = this.filterEvents(
            JSON.parse(JSON.stringify(this.eventListAll)),
            this.selectedSection,
            this.selectedDate,
            type.Id.toLowerCase(),
            this.selectedAddress
          )

          if (events.length) {
            sessionTypes.push({
              value: type.Id.toLowerCase(),
              label: type.Title,
            });
          }
        }
      }

      this.filterOptions.sessionTypes = sessionTypes
    },
    async requestDates() {
      this.isLoading++;
      try {
        const data = await api.request(
          "get_event_timeslots",
          { eventId: this.eventId },
          []
        );

        this.responseTimeSlots = data;

        const datesSet = new Set();

        this.filterOptions.dates = [
          { label: this.$t("allDates"), value: "all" },
        ];
        for (const { Day, DayRU, DayEN } of data) {
          this.datesMap.set(Day.slice(0, -5), { DayRU, DayEN });
          const date = new Date(Day);
          const month = date.getMonth();
          const day = date.getDate();
          datesSet.add(`${month}/${day}`);
        }

        const datesArray = [];
        for (const fullDate of datesSet) {
          const [month, date] = fullDate
            .split("/")
            .map((elem) => parseInt(elem));

          datesArray.push({ month, date });
        }

        datesArray.sort((left, right) => {
          if (left.month === right.month) {
            return left.date - right.date;
          }

          return left.month - right.month;
        });

        for (const { date, month } of datesArray) {
          const events = this.filterEvents(
            JSON.parse(JSON.stringify(this.eventListAll)),
            "all",
            `${month}/${date}`,
            "all",
            "all"
          )

          if (events.length) {

            this.filterOptions.dates.push({
              value: `${month}/${date}`,
              label:
                this.$i18n.locale === "ru"
                  ? `${date} ${this.$t(`months.${month}`)}`
                  : `${this.$t(`months.${month}`)} ${date}`,
            });
          }
        }
      } finally {
        this.isLoading--;
      }
    },
    filteredTimeslots() {
      const datesSet = new Set();
      const dates = [
        { label: this.$t("allDates"), value: "all" },
      ];
      for (const { Day, DayRU, DayEN } of this.responseTimeSlots) {
        this.datesMap.set(Day.slice(0, -5), { DayRU, DayEN });
        const date = new Date(Day);
        const month = date.getMonth();
        const day = date.getDate();
        datesSet.add(`${month}/${day}`);
      }

      const datesArray = [];
      for (const fullDate of datesSet) {
        const [month, date] = fullDate
          .split("/")
          .map((elem) => parseInt(elem));

        datesArray.push({ month, date });
      }

      datesArray.sort((left, right) => {
        if (left.month === right.month) {
          return left.date - right.date;
        }

        return left.month - right.month;
      });

      for (const { date, month } of datesArray) {
        const events = this.filterEvents(
          JSON.parse(JSON.stringify(this.eventListAll)),
          this.selectedSection,
          `${month}/${date}`,
          "all",
          "all"
        )

        if (events.length) {
          dates.push({
            value: `${month}/${date}`,
            label:
              this.$i18n.locale === "ru"
                ? `${date} ${this.$t(`months.${month}`)}`
                : `${this.$t(`months.${month}`)} ${date}`,
          });
        }
      }

      this.filterOptions.dates = dates
    },
    updateEventFavorites(sessionId, ufsiiArray) {
      for (const date of this.tabs[0].content) {
        for (const section of date.Sections) {
          let i = 0;
          for (const session of section.Sessions) {
            if (sessionId !== session.Id) {
              continue;
            }

            if (session.TypeId === "CE67DD60-AE3E-4215-A6BB-5601A7354F63") {
              section.Sessions.splice(i, 1, {
                ...section.Sessions[i],
                UserFavoriteSessionItemId: ufsiiArray[0].ufsii,
              });
              this.updateFavoritesPage();
              return;
            }
            i++;

            session.Items = session.Items.map((item) => {
              const ufsii = ufsiiArray.find(
                ({ itemIdSql }) => itemIdSql === item.IdSql
              )?.ufsii;
              return {
                ...item,
                UserFavoriteSessionItemId:
                  ufsii === undefined ? item.UserFavoriteSessionItemId : ufsii,
              };
            });

            this.updateFavoritesPage();
            return;
          }
        }
      }
    },
    updateFavoritesPage() {
      const favoritesData = JSON.parse(
        JSON.stringify(this.tabs[0].content)
      ).filter(filterFavorites);
      for (const date of favoritesData) {
        date.Sessions = [];

        for (const section of date.Sections) {
          for (const session of section.Sessions) {
            session.SectionCode = section.Code;
            date.Sessions.push(session);
          }
        }

        date.Sessions.sort((left, right) => {
          if (left.StartTime < right.StartTime) {
            return -1;
          }

          if (left.StartTime > right.StartTime) {
            return 1;
          }

          return 0;
        });
      }
      if (this.tabs[1].value === "2") {
        this.tabs[1].content = favoritesData;
        this.favoritesComponent = this.tabs[1].component;
        this.favoritesData = favoritesData;
      } else {
        this.tabs[2].content = favoritesData;
        this.favoritesComponent = this.tabs[2].component;
        this.favoritesData = favoritesData;
      }
    },
  },
  computed: mapState(["lang"]),
  watch: {
    lang() {
      this.initPage();
    },
    uniqueChars() {
      this.requestSessionTypes();
    },
    eventListAll() {
      this.requestDates()
      this.requestAddresses()
    },
  },
  i18n: {
    messages: {
      en: {
        sectionsProgram: "Programme of Sections",
        plenarySessions: "Programme of Plenary Sessions",
        favorites: "Favorites",
        section: "Section",
        allSections: "All sections",
        allAddresses: "All venues",
        allSessionTypes: "All categories",
        allDates: "All dates",
        months: {
          0: "January",
          1: "February",
          2: "March",
          3: "April",
          4: "May",
          5: "June",
          6: "July",
          7: "August",
          8: "September",
          9: "October",
          10: "November",
          11: "December",
        },
      },
      ru: {
        sectionsProgram: "Программа секций",
        plenarySessions: "Программа пленарных сессий/заседаний",
        favorites: "Избранное",
        section: "Секция",
        allSections: "Все секции",
        allAddresses: "Все адреса",
        allSessionTypes: "Все категории",
        allDates: "Все даты",
        months: {
          0: "января",
          1: "февраля",
          2: "марта",
          3: "апреля",
          4: "мая",
          5: "июня",
          6: "июля",
          7: "августа",
          8: "сентября",
          9: "октября",
          10: "ноября",
          11: "декабря",
        },
      },
    },
  },
};
</script>

<style scoped>
.wrapper {
  max-width: 1500px;
  width: calc(100vw - 192px);
  margin: 24px auto;

  font-size: 17px;
  line-height: 1.55;
}

.no-data {
  margin: 20px;
  background: white;
  border-radius: 12px;
  padding: 32px;
  text-align: center;
}

.tabs {
  background: white;
  border-radius: 10px 10px 0 0;
  justify-content: space-evenly;
}

.tabs>>>button {
  width: 100%;
  justify-content: center;
  border-radius: 10px 10px 0 0;
}

@media (max-width: 785px) {
  .tabs {
    flex-direction: column;
  }
}

@media (max-width: 1024px) {
  .wrapper {
    width: calc(100vw - 48px);
  }
}
</style>
