<template>
  <div class="add-member">
    <HseHeading size="h3">{{ $t(this.member ? 'headings.editableMember' : 'headings.addMember') }}</HseHeading>
    <HseSpace size='small' vertical />
    <div v-show="isCheckExists" class="check">
      <div class="field">
        <Validator :field="fields.email" @change="toggleError" />
        <HseSpace size='small_2x' vertical />
      </div>

      <div class="form-footer">
        <div class="actions">
          <HseButton v-on:click="checkEmail" :spinner="buttonSpinner" spinnerPosition="left">
            <span v-if="buttonSpinner === false">{{ $t('headings.addMember') }}</span>
            <span v-else-if="buttonSpinner === true">{{ $t('adding') }}...</span>
            <span v-else-if="buttonSpinner === 'done'">{{ $t('auth.btnDone') }}</span>
          </HseButton>
        </div>
        <div class="form-description">{{ $t('fields.requiredFields') }}</div>
      </div>

    </div>
    <div v-show="!isCheckExists" class="the-form">
      <div class="subheading">{{ $t('headings.personal') }}</div>
      <HseSpace size='small' vertical />
      <div class="form">
        <div class="field">
          <Validator :field="fields.lastName" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <Validator :field="fields.firstName" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <Validator :field="fields.secondName" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <Validator :field="fields.email" @change="toggleError" />
        </div>
      </div>
      <HseSpace size='small_2x' vertical />
      <div class="subheading">{{ $t('headings.company') }}</div>
      <HseSpace size='small' vertical />
      <div class="form">
        <div class="field">
          <Validator @change="setAffiliated" :field="fields.HSEMember" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field"></div>
        <div class="field" v-show="fields.HSEMember.value">
          <Validator :field="fields.campusId" @change="setCampus" />
          <HseSpace size='small_2x' vertical />
        </div>
      </div>
      <div class="form">
        <div class="field">
          <Validator :field="fields.organizationName" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <Validator :field="fields.organizationNameEn" />
          <HseSpace size='small_2x' vertical />
        </div>
      </div>
      <div class="form">
        <div class="field">
          <Validator :field="fields.phone" />
          <HseSpace size='small_2x' vertical />
        </div>
      </div>
      <div class="form-footer">
        <div class="actions">
          <HseButton v-on:click="registerReq" :spinner="buttonSpinner" spinnerPosition="left">
            <span v-if="buttonSpinner === false">{{ $t(this.member ? 'save' : 'headings.addMember') }}</span>
            <span v-else-if="buttonSpinner === true">{{ $t(this.member ? 'saving' : 'adding') }}...</span>
            <span v-else-if="buttonSpinner === 'done'">{{ $t('auth.btnDone') }}</span>
          </HseButton>
        </div>
        <div class="form-description">{{ $t('fields.requiredFields') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { HseHeading, HseSpace, HseButton } from '@hse-design/vue'
import { api } from '../../api'
import Validator from '../common/form/Validator.vue'
import fields from './memberFields'
import fieldsForm2 from './roundTableFields'
import { resourceLoader } from '../common/resourceLoader'
import { mapState } from 'vuex'
import { localizeFields } from '../../i18n'

export default {
  components: {
    HseHeading,
    HseSpace,
    HseButton,
    Validator,
  },
  props: ['editableMember', 'added', 'orgFormat', 'showFormat', 'rtId'],
  data: () => ({
    fields,
    fieldsForm2,
    buttonSpinner: false,
    isCheckExists: true,
    campus: { en: [], ru: [] },
    member: null,
    compareRuEnFields: {
      lastName: 'lastNameEn',
      firstName: 'firstNameEn',
      organizationName: 'organizationNameEn',
    },
    enabledFields: ['HSEMember', 'countryId', 'organizationName', 'organizationNameEn', 'campusId', 'participationStatusId'],
  }),
  created() {
    ['en', 'ru'].forEach(lang =>
      resourceLoader.load('campusList', { lang })
        .then(data => this.campus[lang] = data)
    )
    this.translateFields()
    this.fields.participationFormatId.getAllOptions()

    if (this.editableMember) {
      this.member = this.editableMember
      this.fillFields(this.member)
    }
  },
  computed: {
    isLangRu() {
      return this.lang === 'ru'
    },
    isMixed() {
      return this.orgFormat === '895BB6AB-A343-48DC-AA19-7EAB348C8C0D'
    },
    isMoscowCampus() {
      return this.fields.campusId.value === 'DFA00199-FB13-47BB-A69B-F01C6F525F71'
    },
    ...mapState(['lang'])
  },
  watch: {
    lang() {
      this.translateFields()
    },
  },
  methods: {
    setFormat(val) {
      this.fields.participationFormatId.setValues(true, val)
    },
    setAffiliated() {
      if (!this.fields.HSEMember.value) {
        this.fields.organizationName.value = ''
        this.fields.organizationNameEn.value = ''
      }
    },
    setCampus(campus) {
      const currCampus = this.campus.ru.find(cmps => cmps.value === campus)
      const currCampusEn = this.campus.en.find(cmps => cmps.value === campus)

      if (currCampus)
        this.fields.organizationName.setValues(
          true, `НИУ ВШЭ${this.isMoscowCampus ? '' : ` - ${currCampus.label}`}`
        )

      if (currCampusEn)
        this.fields.organizationNameEn.setValues(
          true, `HSE University${this.isMoscowCampus ? '' : ` - ${currCampusEn.label}`}`
        )
    },
    toggleError(value) {
      const isErrSet = this.$store.state.error && this.$store.state.error.code === 876
      const isAdded = this.added.some(coauth => coauth.email === value)
      if (isAdded && !isErrSet) {
        if (this.editableMember && this.editableMember.email === value) return
        this.$store.commit('setError', { code: 876 })
        this.$emit('validateFields')
      } else if (!isAdded && isErrSet) {
        this.$store.commit('setError', null)
        this.$emit('validateFields')
      }
    },
    translateFields() {
      this.fields = localizeFields(fields, this.fields)
    },
    registerReq() {
      this.$emit('validateFields')
      let isFormInvalid = false
      const skip = this.isLangRu ? [] : Object.keys(this.compareRuEnFields)
      Object.keys(this.fields).some(key => {
        if (!this.fields.HSEMember.value && key === 'campusId' || skip.includes(key))
          return false

        isFormInvalid = this.fields[key].isValid === false
        return isFormInvalid
      })

      if (isFormInvalid) return

      this.buttonSpinner = true
      const params = {
        rtEventId: this.rtId, secretary: {
          email: this.fields.email.value,
          lastName: this.fields.lastName.value,
          firstName: this.fields.firstName.value,
          middleName: this.fields.secondName.value,
          company: this.fields.organizationName.value,
          phone: this.fields.phone.value,
          HSEMember: this.fields.HSEMember.value,
          campusId: this.fields.campusId.value === '' ? null : this.fields.campusId.value,
        }
      }
      this.buttonSpinner = 'done'
      setTimeout(() => {
        this.buttonSpinner = false
        api.request('add_secretary', params).then(data => {
          const newSecretary = data.find((element) => element.Email === params.secretary.email)
          this.$emit('updateSecretary', data)
          this.$emit('saved', newSecretary)
        })
      }, 200)

      return
    },
    checkEmail() {
      this.$emit('validateFields')
      if (this.fields.email.isValid) {
        this.buttonSpinner = true
        return resourceLoader.load('get_secretary_list', null, []).then(res => {
          const result = res.find(row => row.Email === this.fields.email.value)
          if (result != undefined) {
            this.$store.commit('setError', { code: 876 })
            this.buttonSpinner = false
            this.$emit('validateFields')
          }
          else {
            this.buttonSpinner = false
            this.isCheckExists = false
          }
        })
      }
    },
    fillFields(userData) {
      this.isCheckExists = false
      const uData = this.editableMember ? userData : userData.data.public
      const fieldsKeys = Object.keys(this.fields)
      this.fields.lastName.setValues(true, uData.LName)
      this.fields.lastNameEn.setValues(true, uData.LNameEN)
      this.fields.firstName.setValues(true, uData.FName)
      this.fields.firstNameEn.setValues(true, uData.FNameEN)
      this.fields.secondName.setValues(true, uData.SName)
      fieldsKeys.forEach(key => {
        if (uData[key])
          this.fields[key].setValues(true, uData[key])
      })
      if (this.member && !this.member.isNew && this.editableMember) {
        this.fields.organizationName.setValues(true, this.editableMember.organizationName)
        this.fields.organizationNameEn.setValues(true, this.editableMember.organizationNameEn)
        this.fields.HSEMember.setValues(true, this.editableMember.HSEMember)
        this.fields.campusId.setValues(true, this.editableMember.campusId)
        this.fields.countryId.setValues(true, this.editableMember.countryId)
        this.fields.participationStatusId.setValues(true, this.editableMember.participationStatusId)
        if (this.editableMember.participationFormatId) {
          this.fields.participationFormatId.setValues(true, this.editableMember.participationFormatId)
        }
      }
      this.fields.email.setValues(true, userData.login ?? userData.email)
      if (!userData.isNew) {
        fieldsKeys.forEach(key => {
          if (!this.enabledFields.includes(key)) {
            this.fields[key]['disabled'] = true
          }
        })
      } else this.$emit('validateFields')
    }
  },
  i18n: {
    messages: {
      en: {
        headings: {
          addMember: 'Add a secretary',
          editableMember: 'Edit a secretary',
          personal: 'Personal details',
          company: 'Company',
        },
        adding: 'Adding',
        offline: 'Offline',
        online: 'Online',
        mixed: 'Mixed',
        participationFormatId: 'Participation format',
        approved: 'Approved',
        rejected: 'Rejected',
        sent: 'Sent',
      },
      ru: {
        headings: {
          addMember: 'Добавить секретаря',
          editableMember: 'Редактировать секретаря',
          personal: 'Личная информация',
          company: 'Организация',
        },
        adding: 'Добавляем',
        offline: 'Офлайн',
        online: 'Онлайн',
        mixed: 'Смешанный',
        participationFormatId: 'Формат участия',
        approved: 'Одобрен',
        rejected: 'Отклонен',
        sent: 'Отправлен',
      },
    }
  }
}
</script>

<style scoped>
.add-member .form {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
}

.form.down {
  align-items: flex-end;
}

.form .field {
  padding: 0 16px;
  min-width: 0;
  width: 50%;
  flex-basis: 50%;
}

.actions .hse-Button {
  display: inline-block;
}

.checkboxes {
  display: flex;
  justify-content: space-between;
}

.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.form-description {
  padding-top: 12px;
  font-size: 14px;
  line-height: 20px;
  color: #6B7A99;
}

.flex-tooltip {
  display: flex;
}

.flex-tooltip>div:first-child {
  width: calc(100% - 28px)
}

.flex-tooltip>div:last-child {
  padding: 36px 0 0 12px;
}

@media (max-width: 1024px) {
  .register {
    margin: 16px auto;
    max-width: 95%;
  }

  .emptyfield {
    display: none;
  }

  .checkboxes {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .form .field {
    width: 100%;
    flex-basis: 100%;
  }

  .form-footer {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
<style>
.smallLabel {
  font-size: 14px !important;
}

@media (max-width: 768px) {
  .hse-Input_disabled input:disabled {
    opacity: 1 !important;
    color: #000 !important;
  }
}
</style>