<template>
  <HseModalForm v-model="isOpen" :shouldCloseOnOverlayClick="false" :shouldCloseOnEsc="false">
    <div class="content">
      <HseHeading size="h3">{{$t('header')}}</HseHeading>
      <HseSpace size='small_2x' vertical />
          <div :key="role.id" v-for="role in roles">
              <HseRadio
              v-model="radioValue"
              :value="role.id"
              :label="$t(role.name)"
              :checked="radioValue === role.id"
              />
              <HseSpace size="small_2x" vertical />
          </div>
      <p>{{$t('text')}}</p>
    </div>

    <template #actions>
      <HseButton @click="submit">{{$t('ok')}}</HseButton>
    </template> 

  </HseModalForm>
</template>

<script>
  import { HseModalForm, HseButton, HseSpace, HseHeading, HseRadio } from '@hse-design/vue'
  import { mapState } from 'vuex'

  export default {
    components: {
      HseModalForm,
      HseButton,
      HseSpace,
      HseHeading,
      HseRadio,
    },
    data: () => ({
      isOpen: false,
      radioValue: 1,
    }),
    created () {
        this.$root.$refs.roleModal = this
    },
    methods: {
        open() {
            if ([...this.roles].length > 1) {
              this.isOpen = true
            }
          
        },
        submit() {
            this.$store.commit('setRole', this.radioValue)
            this.isOpen = false
        },
    }, 
    computed: {
        ...mapState(['roles'])
    },
    i18n: {
      messages: {
        en: {
            header: 'Select your role to continue',
            participant: 'Participant',
            expert: 'Expert',
            manager: 'Moderator',
            ok: 'Ok',
            cancel: 'Cancel',
            text: 'If you wish to change your position, use the option in the upper right corner of your personal account.'
        },
        ru: {
            header: 'В какой роли Вы хотите продолжить работу',
            participant: 'Участник',
            expert: 'Эксперт',
            manager: 'Руководитель',
            ok: 'Ок',
            cancel: 'Отмена',
            text: 'В дальнейшем для изменения роли Вы можете воспользоваться опцией переключения в правом верхнем углу личного кабинета.'
        },
      }
    } 
  }
</script>

<style scoped>
    .content {
        min-height: 200px;
    }
</style>