<template>
  <HseRichInput :label="label">
    <div v-if="field.getAllOptions && !isLoaded" :class="`between hse-Input hse-Input_size_${field.size ? field.size : 'medium'
      } hse-Input_fullWidth`">
      {{ $t("loading") }}...
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
        class="hse-Icon hse-Select__valueContainerIcon hse-Icon_size_medium">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 10L12 15L17 10H7Z" fill="currentColor"></path>
      </svg>
    </div>
    <component v-model="vmodel" v-else-if="field.component" :is="field.component ? field.component : ''"
      v-bind="{ ...field, ...additionalProps, ...$attrs }" v-on:input="onValueChange" v-on:change="onValueChange"
      v-on:blur="onBlur" :fullWidth="true" width="full" />
    <template #error-message>
      {{ isError ? $t(errorMessage) : "" }}
    </template>
  </HseRichInput>
</template>

<script>
import { mapState } from "vuex";
import { HseRichInput, HseSelect } from "@hse-design/vue";
export default {
  name: "Validator",
  data: () => ({
    vmodel: null,
    errorMessage: "",
    options: [],
    isLoaded: false,
  }),
  props: {
    field: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    if (this.field.isValueId) {
      this.vmodel = `${this.$t("loading")}...`;
      setTimeout(() => {
        if (this.vmodel === `${this.$t("loading")}...`) this.vmodel = "";
      }, 1500);
    } else {
      this.vmodel = this.field ? this.field.value : null;
    }
    this.$parent.$on("validateFields", () =>
      this.onValueChange(this.value, null, true)
    );
    this.$parent.$on("loadAllOptions", () => this.loadAllOptions(true));
    this.$parent.$on("loadAllOptionsTime", () => this.loadAllOptionsTime(true));
    this.$parent.$on("loadAllOptionsSecretary", () =>
      this.loadAllOptionsSecretary(true)
    );
    this.loadAllOptions();
    this.$root.$refs.validator = this;
  },
  computed: {
    isError() {
      return (
        !this.field.isValid && !this.field.disabled && !this.field.inactive
      );
    },
    additionalProps() {
      if (this.field.component === HseSelect && this.field.getAllOptions) {
        return { options: this.options };
      }
      return {};
    },
    isValid() {
      return this.field ? this.field.isValid : true;
    },
    value() {
      return this.field ? this.field.value : null;
    },
    label() {
      return this.field && this.field.label && !this.field.hideTopLabel
        ? this.field.label
        : "";
    },
    ...mapState(["lang"]),
  },
  components: {
    HseRichInput,
  },
  watch: {
    lang() {
      this.loadAllOptions();
    },
    value(value) {
      if (this.field.isValueId) return;
      this.vmodel = value;
    },
  },
  methods: {
    validationInModal() {
      this.onValueChange(this.value, null, true);
    },
    loadAllOptions(repeat = false) {
      if (this.field.getAllOptions) {
        if (repeat) {
          this.vmodel = `${this.$t("loading")}...`;
        }
        this.field
          .getAllOptions()
          .then((options) => {
            if (this.vmodel === `${this.$t("loading")}...`) this.vmodel = "";
            const optionsNormalized = this.field.excludeOptions?.length
              ? options.filter(({ value }) =>
                this.field.excludeOptions.find((exclude) => exclude !== value)
              )
              : options;
            this.options = Object.freeze(optionsNormalized);
            this.isLoaded = true;
            const isOptsHasCurrVal = this.options.find(
              (opt) => opt.value === this.value
            );
            if (this.field.isValueId) {
              setTimeout(() => {
                this.vmodel = options.find(
                  (item) => parseInt(item.value) === parseInt(this.field.value)
                ).label
                  ? options.find(
                    (item) =>
                      parseInt(item.value) === parseInt(this.field.value)
                  ).label
                  : options.find(
                    (item) =>
                      parseInt(item.value) === parseInt(this.field.value)
                  ).name;
                this.field.setValues(
                  true,
                  options.find(
                    (item) =>
                      parseInt(item.value) === parseInt(this.field.value)
                  ).value
                    ? options.find(
                      (item) =>
                        parseInt(item.value) === parseInt(this.field.value)
                    ).value
                    : options.find(
                      (item) =>
                        parseInt(item.value) === parseInt(this.field.value)
                    ).id
                );
              }, 1000);
            }
            if (
              !this.field.isValueId &&
              !this.field.isEditVal &&
              !isOptsHasCurrVal
            ) {
              this.setValue("");
            }
          })
          .catch(console.error);
      }
    },
    loadAllOptionsTime(repeat = false) {
      if (
        this.field.getAllOptions &&
        this.field.name === "programSessionTimeId"
      ) {
        if (repeat) {
          this.vmodel = `${this.$t("loading")}...`;
        }
        this.field
          .getAllOptions()
          .then((options) => {
            if (this.vmodel === `${this.$t("loading")}...`) this.vmodel = "";
            const optionsNormalized = this.field.excludeOptions?.length
              ? options.filter(({ value }) =>
                this.field.excludeOptions.find((exclude) => exclude !== value)
              )
              : options;
            this.options = Object.freeze(optionsNormalized);
            this.isLoaded = true;
            const isOptsHasCurrVal = this.options.find(
              (opt) => opt.value === this.value
            );
            if (this.field.isValueId) {
              setTimeout(() => {
                this.vmodel = options.find(
                  (item) => parseInt(item.value) === parseInt(this.field.value)
                ).label
                  ? options.find(
                    (item) =>
                      parseInt(item.value) === parseInt(this.field.value)
                  ).label
                  : options.find(
                    (item) =>
                      parseInt(item.value) === parseInt(this.field.value)
                  ).name;
                this.field.setValues(
                  true,
                  options.find(
                    (item) =>
                      parseInt(item.value) === parseInt(this.field.value)
                  ).value
                    ? options.find(
                      (item) =>
                        parseInt(item.value) === parseInt(this.field.value)
                    ).value
                    : options.find(
                      (item) =>
                        parseInt(item.value) === parseInt(this.field.value)
                    ).id
                );
              }, 1000);
            }
            if (
              !this.field.isValueId &&
              !this.field.isEditVal &&
              !isOptsHasCurrVal
            ) {
              this.setValue("");
            }
          })
          .catch(console.error);
      }
    },
    loadAllOptionsSecretary(repeat = false) {
      if (this.field.getAllOptions && this.field.name === "secretary") {
        if (repeat) {
          this.vmodel = `${this.$t("loading")}...`;
        }
        this.field
          .getAllOptions()
          .then((options) => {
            if (this.vmodel === `${this.$t("loading")}...`) this.vmodel = "";
            const optionsNormalized = this.field.excludeOptions?.length
              ? options.filter(({ value }) =>
                this.field.excludeOptions.find((exclude) => exclude !== value)
              )
              : options;
            this.options = Object.freeze(optionsNormalized);
            this.isLoaded = true;
            const isOptsHasCurrVal = this.options.find(
              (opt) => opt.value === this.value
            );
            if (this.field.isValueId) {
              setTimeout(() => {
                this.vmodel = options.find(
                  (item) => parseInt(item.value) === parseInt(this.field.value)
                ).label
                  ? options.find(
                    (item) =>
                      parseInt(item.value) === parseInt(this.field.value)
                  ).label
                  : options.find(
                    (item) =>
                      parseInt(item.value) === parseInt(this.field.value)
                  ).name;
                this.field.setValues(
                  true,
                  options.find(
                    (item) =>
                      parseInt(item.value) === parseInt(this.field.value)
                  ).value
                    ? options.find(
                      (item) =>
                        parseInt(item.value) === parseInt(this.field.value)
                    ).value
                    : options.find(
                      (item) =>
                        parseInt(item.value) === parseInt(this.field.value)
                    ).id
                );
              }, 1000);
            }
            if (
              !this.field.isValueId &&
              !this.field.isEditVal &&
              !isOptsHasCurrVal
            ) {
              this.setValue("");
            }
          })
          .catch(console.error);
      }
    },

    setValid(isValid) {
      if (this.isValid === isValid) return;
      this.field.setValues(isValid, this.field.value);
    },
    setValue(value) {
      if (this.value === value) return;
      this.field.setValues(this.field.isValid, value);
    },
    validateValue(val) {
      let isValid = true;
      if (this.field.isRequired && !val) {
        this.setValid(false);
        this.errorMessage = "fields.rule.empty";
        return;
      }
      if (this.field.rules) {
        this.field.rules.some((rule) => {
          switch (rule.name) {
            case "cyrillic":
              isValid = val ? /^[ЁёА-я\s-–«»"",.!?:;0-9()]+$/.test(val) : true;
              break;
            case "latin":
              isValid = val ? /^[A-Za-z\s-–«»""”№~`’,.!?:;0-9()$@#%^&*_+=|\\/'><{}[\]]+$/.test(val) : true;
              break;
            case "cyrillicTitle":
              isValid = val
                ? /^[А-Яа-я\s-–«»"",.!?:;0-9()$@#%^&*_+=|\\/'><{}[\]]+$/.test(
                  val
                )
                : true;
              break;
            case "latinTitle":
              isValid = val
                ? /^[A-Za-z\s-–«»""’,.!?:;0-9()$@#%^&*_+=|\\/'><{}[\]]+$/.test(
                  val
                )
                : true;
              break;
            case "phone":
              isValid = val ? /^[0-9+()-]+$/.test(val) : true;
              break;
            case "email":
              isValid = val
                ? /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-.\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,3})$/.test(
                  val
                ) && !/\s/g.test(val)
                : true;
              break;
            case "number":
              isValid = val ? /^[0-9]+[.]?[0-9]*?$/.test(val) : true;
              break;
            case "password":
              isValid = val
                ? /^[A-Za-z!@#$%^&*(){}[\]/\\|«»""<>`~,.?:;_0-9-]+$/.test(val)
                : true;
              break;
            case "maxParticipants":
              isValid = val > 0;
              break;
            case "custom":
              isValid = rule.validateVal(val);
              break;
          }
          this.errorMessage = isValid
            ? ""
            : rule.message
              ? rule.message
              : `fields.rule.${rule.name}`;
          return !isValid;
        });
      }
      this.setValid(isValid);
    },
    onValueChange(data, _, skipEmit) {
      let val = data;
      if (data && typeof data === "object") {
        val = data.target.value;
      }
      if (
        (!this.field.rules || this.field.rules.name !== "password") &&
        typeof val === "string"
      ) {
        val = val.replace(/\s\s+/g, " ");
        this.vmodel = val;
      }
      this.setValue(val);
      if (this.field.isRequired || this.field.rules) this.validateValue(val);
      else if (!this.field.isValid) this.setValid(true);
      if (!skipEmit) this.$emit("change", val);
    },
    onBlur(data) {
      let val = data;
      if (data && typeof data === "object") {
        val = data.target.value;
      }
      if (
        (!this.field.rules || this.field.rules.name !== "password") &&
        typeof val === "string"
      ) {
        val = val.trim();
        this.vmodel = val;
        this.setValue(val);
      }
    },
  },
  i18n: {
    messages: {
      ru: {
        loading: "Загрузка",
      },
      en: {
        loading: "Loading",
      },
    },
  },
};
</script>

<style scoped>
.between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
