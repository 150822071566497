<template>
  <div class="add-moderator">
    <div class="check">
      <div class="field">
        <div class="flex-tooltip">
          <ReadonlyInput :label="$t('link')" :value="link" />
          <HseTooltip :content="$t('tooltipText')">
            <IconStatusInfo />
          </HseTooltip>
      </div>
        <HseSpace size='small_2x' vertical />
      </div>

      <div class="form-footer">
        <div class="actions">
          <HseButton
            @click="cancel"
          >
            {{$t('cancel')}}
          </HseButton>
          <HseButton
            @click="send"
            :spinner="buttonSpinner"
            spinnerPosition="left"
          >
            <span v-if="buttonSpinner === false">{{ $t('send') }}</span>
            <span v-else-if="buttonSpinner === true">{{ $t('sending') }}...</span>
            <span v-else-if="buttonSpinner === 'done'">{{ $t('sent') }}</span>
          </HseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { HseSpace, HseButton, HseTooltip, IconStatusInfo } from '@hse-design/vue'
  import { api } from '../../api'
  import ReadonlyInput from '../common/ReadonlyInput.vue'
  import { mapState } from 'vuex'

  export default {
    components: {
      HseSpace,
      HseButton,
      ReadonlyInput,
      HseTooltip,
      IconStatusInfo
    },
    props: ['link', 'rtId'],
    data: () => ({
      buttonSpinner: false,
    }),
    created () {
      this.translateFields()
    },
    computed: {
      ...mapState(['lang'])
    },
    watch: {
      lang () {
        this.translateFields()
      },
    },
    methods: {
      send() {
        this.buttonSpinner = true
        api.request('send_rt_webinar_link', {rtEventId: this.rtId, lang: this.lang}).then(res => {
          if (res) {
            this.buttonSpinner = 'done'
            this.$emit('saved')
          } else {
            this.buttonSpinner = false
            console.log('Error occured')
          }
        })
      },
      cancel() {
        this.$emit('saved')
      },
    },
    i18n: {
      messages: {
        en: {
          send: 'Send',
          sending: 'Sending',
          sent: 'Sent',
          cancel: 'Cancel',
          link: 'Webinar link',
          tooltipText: 'Please check this link. If the link does not work, contact the Conference Organizing Committee at: YasinConference@hse.ru'
        },
        ru: {
          send: 'Отправить',
          sending: 'Отправляем',
          sent: 'Отправлено',
          cancel: 'Отмена',
          link: 'Ссылка на вебинар',
          tooltipText: 'Пожалуйста, проверьте ссылку. Если указана неверная ссылка, необходимо обратиться в Организационный комитет конференции: YasinConference@hse.ru.'
        },
      }
    }
  }
</script>

<style scoped>
  .add-moderator .form {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px;
  }
  .form.down {
    align-items: flex-end;
  }
  .form .field {
    padding: 0 16px;
    min-width: 0;
    width: 50%;
    flex-basis: 50%;
  }
  .actions {
    display: flex;
    gap: 20px;
  }
  .actions .hse-Button {
    display: inline-block;
  }
  .checkboxes {
    display: flex;
    justify-content: space-between;
  }
  .form-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .form-description {
    padding-top: 12px;
    font-size: 14px;
    line-height: 20px;
    color: #6B7A99;
  }
  .flex-tooltip {
    display: flex;
  }
  .flex-tooltip>div:first-child {
    width: calc(100% - 28px)
  }
  .flex-tooltip>div:last-child {
    padding: 36px 0 0 12px;
  }
  @media (max-width: 1024px) {
    .register {
      margin: 16px auto;
      max-width: 95%;
    }
    .emptyfield {
      display: none;
    }
    .checkboxes {
      flex-direction: column;
    }
  }
  @media (max-width: 768px) {
    .form .field {
      width: 100%;
      flex-basis: 100%;
    }
    .form-footer {
      flex-direction: column;
      align-items: flex-start;
    }
  }
</style>
<style>
  .smallLabel {
    font-size: 14px !important;
  }
  @media (max-width: 768px) {
    .hse-Input_disabled input:disabled {
      opacity: 1 !important;
      color: #000 !important;
    }
  }
</style>