<template>
  <div class="verification" :class="{ 'hse-RichInput_hasError': errorMessage }">
    <HseButton
      v-if="!isCodeSent && !isVerificated"
      v-on:click="checkValue"
      size="large"
      :spinner="buttonSpinner"
      spinnerPosition="left"
    >
      <span v-if="buttonSpinner === false">{{ $t('btn') }}</span>
      <span v-if="buttonSpinner === true">{{ $t('sending') }}</span>
    </HseButton>
    <HseInput
      v-else size="large"
      :fullWidth="true"
      v-model="code"
      v-on:input="onCodeInput"
      :placeholder="$t('inputPlaceholder')"
      :rightIcon="inputIcon"
    />
    <div v-if="errorMessage" class="hse-RichInput__message">
      {{ $t(errorMessage) }}
    </div>
  </div>
</template>

<script>
  import { api } from '../../../api'
  import { store } from '../../../store'
  import { HseButton, HseInput, IconActionCheck } from '@hse-design/vue'
  
  export default {
    name: 'VerificationCode',
    store,
    props: [
      'field',
      'value',
      'isValValid',
    ],
    components: {
      HseButton,
      HseInput,
    },
    data: () => ({
      isCodeSent: false,
      isVerificated: false,
      errorMessage: '',
      code: '',
      inputIcon: '',
      checkerTimeout: null,
      buttonSpinner: false,
    }),
    created () {
      this.$parent.$on('validateFields', () => this.validate())
    },
    i18n: {
      messages: {
        en: {
          btn: 'Confirm E-mail',
          sending: 'Sending a code',
          inputPlaceholder: 'Confirmation code',
          invalidCode: 'Incorrect confirmation code',
          approveEmail: 'Please confirm e-mail',
          enterCodeFromEmail: 'Enter confirmation code from e-mail',
        },
        ru: {
          btn: 'Подтвердить E-mail',
          sending: 'Высылаем код',
          inputPlaceholder: 'Код проверки',
          invalidCode: 'Введён некорректный проверочный код',
          approveEmail: 'Необходимо подтвердить e-mail',
          enterCodeFromEmail: 'Введите проверочный код, высланный на почту',
        },
      }
    },
    methods: {
      checkValue () {
        if (!this.value || !this.isValValid) {
          this.errorMessage = 'fields.rule.email'
          this.field.setValues(false)
          return
        }

        this.buttonSpinner = true

        api.auth('user.registration.start', { login: this.value, lang: this.$store.state.lang })
          .then(data => {
            setTimeout(() => {
              const { error } = this.$store.state
              if (data === true) {
                this.isCodeSent = true
                this.validate()
              } else if (error && error.url === 'auth user.registration.start' && error.code === 9) {
                this.errorMessage = 'fields.rule.alreadyRegistered'
                this.field.setValues(false)
              } else {
                this.errorMessage = 'fields.rule.email'
                this.field.setValues(false)
              }
              this.buttonSpinner = false

            }, 1000)
          }).catch(() => {
            this.errorMessage = 'fields.rule.email'
            this.field.setValues(false)
            this.buttonSpinner = false
          })
      },
      checkCode () {
        api.auth('user.pre.check.email', { login: this.value, code: this.code, lang: this.$store.state.lang })
          .then(data => {
            if (data) {
              this.inputIcon = IconActionCheck
              this.isVerificated = true
              this.field.setValues(true)
              this.field.setGuid(data)
            } else {
              this.field.setValues(false)
              this.errorMessage = 'invalidCode'
            }
          }).catch(() => {
            this.field.setValues(false)
            this.errorMessage = 'invalidCode'
          })
      },
      validate () {
        if (!this.isCodeSent) {
          this.errorMessage = 'approveEmail'
          this.field.setValues(false)
        } else if (!this.isVerificated) {
          if (this.code.length === 6 && /^[0-9]{6}$/.test(this.code)) {
            this.errorMessage = ''
            if (this.checkerTimeout) this.checkerTimeout = null
            this.checkerTimeout = setTimeout(() => {
              this.checkCode()
            }, 1000)
          } else if (!this.code) {
            this.errorMessage = 'enterCodeFromEmail'
            this.field.setValues(false)
          } else {
            this.errorMessage = 'invalidCode'
            this.field.setValues(false)
          }
        }
      },
      onCodeInput () {
        if (!this.isVerificated) {
          this.field.setValues(false)
          this.validate()
        }
      }
    },
    watch: {
      value () {
        this.isCodeSent = false
        this.isVerificated = false
        this.code = ''
        this.inputIcon = ''
        this.buttonSpinner = false
        this.field.setValues(false)
        this.validate()
      }
    },
  }
</script>

<style>
  .verification {
    width: 100%;
    margin-top: 28px;
  }
  .verification button {
    width: 100%;
  }
</style>
