<template>
    <HseSwitcher :label="label" v-model="value" :size="size"/>
</template>

<script>
  import { mapState } from 'vuex'
  import { HseSwitcher } from '@hse-design/vue'
  export default {
    name: 'Switcher',
    data: () => ({
      value: false,
    }),
    props: {
      field: {
        type: Object,
        default: () => ({})
      },
      label: {
        type: String,
        default: ''
      },
      size: {
        type: String,
        default: 'medium'
      }
    },
    created () {
      this.value = this.field.value
      this.$parent.$on('validateFields', () => this.onValueChange(this.value))
    },
    computed: {
      ...mapState(['lang'])
    },
    components: {
      HseSwitcher
    },
    methods: {
      setValue (value) {
        this.field.setValues(this.field.isValid, value)
      },
      onValueChange (data) {
        //console.log(this.value);
        this.setValue(data)
      },
    },
  }
</script>