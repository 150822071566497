<template>
  <div class="edit-request">
    <div v-show="!isSaved" class="form-data">
      <HseHeading size="h3">{{ $t('formName', { br: '' }) }}</HseHeading>
      <HseSpace size="small" vertical />
      <FormPage1 @setUserData="setUserData" :fields="fields" v-show="page === 1"
        v-on:validatePage="changePagesValidity" />
      <FormPage2 @updateModerators="updateModerators" :fields="fields" v-show="page === 2"
        v-on:validatePage="changePagesValidity" />
      <FormPage3 @updateDisscussants="updateDisscussants" :fields="fields" v-show="page === 3"
        v-on:validatePage="changePagesValidity" />
      <FormPage4 :maxParticipants="eventData.collectiveMembersMaxCount" @updateParticipants="updateParticipants"
        v-show="page === 4" v-on:validatePage="changePagesValidity" />
      <HseSpace size="large" vertical />
      <div class="actions">
        <div>
          <div class="buttons">
            <div v-if="page >= 1">
              <HseButton variant="secondary" @click="() => changePage(page - 1)">
                <span>{{ $t('prev') }}</span>
              </HseButton>
            </div>
            <div>
              <HseButton :spinner="buttonSpinner" spinnerPosition="left" @click="() => changePage(page + 1)">
                <span v-if="page !== 4">{{ $t('next') }}</span>
                <span v-else-if="buttonSpinner === false">{{ $t('applyRequest') }}</span>
                <span v-else-if="buttonSpinner === true">{{ $t('applying') }}...</span>
                <span v-else-if="buttonSpinner === 'done'">{{ $t('auth.btnDone') }}</span>
              </HseButton>
            </div>
          </div>
        </div>
        <Pagination :current="page" :pagesCount="4" v-on:changePage="n => changePage(n)" />
      </div>
    </div>
    <div v-if="isSaved" class="result">
      <div class="status ok" v-if="!isSaveErr">
        <svg width="110" height="100" viewBox="0 0 110 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M26.8055 8.42652C35.0279 2.93245 44.6949 0 54.584 0C67.8448 0 80.5625 5.26784 89.9393 14.6447C99.3161 24.0215 104.584 36.7392 104.584 50C104.584 59.8891 101.652 69.556 96.1575 77.7785C90.6634 86.0009 82.8545 92.4096 73.7182 96.194C64.5819 99.9783 54.5285 100.969 44.8295 99.0392C35.1304 97.11 26.2213 92.3479 19.2287 85.3553C12.236 78.3627 7.474 69.4535 5.54474 59.7545C3.61548 50.0555 4.60565 40.0021 8.39003 30.8658C12.1744 21.7295 18.583 13.9206 26.8055 8.42652ZM85.4219 35.1375L79.2732 28.9887L46.4721 61.7897L29.8964 45.214L23.7476 51.3627L46.4721 74.0872L85.4219 35.1375Z"
            fill="#0050CF" />
        </svg>
        <HseSpace size="large" vertical />
        <HseHeading size="h3" v-html="$t('okStatus')"></HseHeading>
        <HseSpace size="medium" vertical />
        <HseButton @click="() => this.$router.push('/requests')">Ok</HseButton>
      </div>
      <div class="status err" v-else>
        <svg width="110" height="100" viewBox="0 0 110 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M26.8055 8.42652C35.0279 2.93245 44.6949 0 54.584 0C67.8448 0 80.5625 5.26784 89.9393 14.6447C99.3161 24.0215 104.584 36.7392 104.584 50C104.584 59.8891 101.652 69.556 96.1575 77.7785C90.6634 86.0009 82.8545 92.4096 73.7182 96.194C64.5819 99.9783 54.5285 100.969 44.8295 99.0392C35.1304 97.11 26.2213 92.3479 19.2287 85.3553C12.236 78.3627 7.474 69.4536 5.54474 59.7545C3.61548 50.0555 4.60565 40.0021 8.39003 30.8658C12.1744 21.7295 18.583 13.9206 26.8055 8.42652Z"
            fill="#FF564E" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M73.4702 35.2504L69.7498 31.53L55.0002 46.2796L40.2507 31.53L36.5303 35.2504L51.2799 50L36.5303 64.7496L40.2507 68.47L55.0002 53.7204L69.7498 68.47L73.4702 64.7496L58.7206 50L73.4702 35.2504Z"
            fill="white" />
        </svg>
        <HseSpace size="large" vertical />
        <HseHeading size="h3" v-html="$t('errStatus')"></HseHeading>
        <HseSpace size="medium" vertical />
        <HseButton @click="getBackIfErr">{{ $t('back') }}</HseButton>
      </div>
    </div>
  </div>
</template>

<script>
import fields from './requestFields'
import { store } from '../../store'
import { api } from '../../api'
import { HseHeading, HseButton, HseSpace } from '@hse-design/vue'
import FormPage1 from './FormPage1.vue'
import FormPage2 from './FormPage2.vue'
import FormPage3 from './FormPage3.vue'
import FormPage4 from './FormPage4.vue'
import Pagination from './Pagination.vue'
import { mapState } from 'vuex'
import { localizeFields } from '../../i18n'
export default {
  name: 'CollectiveRequestForm',
  store,
  components: {
    HseButton,
    HseHeading,
    HseSpace,
    FormPage1,
    FormPage2,
    FormPage3,
    FormPage4,
    Pagination,
  },
  data: () => ({
    fields,
    eventId: '',
    eventData: null,
    page: 1,
    pagesValidity: [null, false, false, false, false, true],
    buttonSpinner: false,
    isSaved: false,
    isSaveErr: false,
    selectsWithGuid: ['direction'],
    roleStatus: {
      disscussants: '8EB5C9A2-8EE1-DB30-4956-B9E08C3356D0',
      moderators: '1DEDD098-DAE2-DDE9-4C9F-40606EA5659D',
    },
    brTag: '<br>',
  }),
  destroyed() {
    this.$store.commit('setIsLangDropdownDisabled', false)
  },
  created() {
    // если добавляем новую заявку, выясняем id мероприятия из роута
    if (this.$route.query && this.$route.query.event) {
      this.eventId = this.$route.query.event
    }

    api.request('eventGet', { id: this.eventId }).then(data => {
      this.eventData = data[0]
    })

    this.translateFields()
  },
  methods: {
    translateFields() {
      ['direction'].forEach(key => {
        fields[key].eventId = this.eventId
      })
      this.fields = localizeFields(fields, this.fields)
    },
    changePage(page) {
      // если возвращаемся назад - всегда переходим
      if (this.page > page) {
        if (page && page >= 1) this.page = page
        else this.$router.go(-1)
        return
      } else {
        this.$emit('validatePage', { current: this.page })
      }
      // если вперед - проверям текущую
      if (!this.pagesValidity[this.page]) {
        return
      }

      // и проверяем все страницы от текущей до той на которую переходим
      let invalidPage = 0
      this.pagesValidity.some((isValid, i) => {
        if (i && i <= page && i > this.page) {
          if (!isValid) {
            invalidPage = i
          }
          return !isValid
        }
        return false
      })

      // если между текущей и переключаемой нашли невалидную страницу формы - переключаемся на неё
      if (invalidPage) {
        this.page = invalidPage
        return
      }
      // если есть пятая страница, а пытаемся идти на 6 и наоборот
      // нет 5ой, а идём на неё, значит мы нажали подать заявку - запускаем сохранение
      if (page === 5) {
        this.buttonSpinner = true
        this.saveForm()
        return
      }
      this.page = page
    },
    changePagesValidity({ page, isValid }) {
      this.pagesValidity[page] = isValid
    },
    convertToNumber(value) {
      return !value !== null && value.length ? parseInt(value, 10) : null
    },
    convertGuid(value) {
      return !value ? null : value
    },
    setUserData(data) {
      this.fields.lastName.value = data.lastName
      this.fields.firstName.value = data.firstName
      this.fields.secondName.value = data.secondName
      this.fields.lastNameEn.value = data.lastNameEn
      this.fields.firstNameEn.value = data.firstNameEn
      this.fields.email.value = data.email

      if (this.isLangEn) {
        this.fields.lastName.value = data.lastNameEn
        this.fields.firstName.value = data.firstNameEn
        this.fields.secondName.value = ''
      }
    },
    updateModerators(data) {
      this.fields.moderators.value = [...data]
    },
    updateDisscussants(data) {
      this.fields.disscussants.value = [...data]
    },
    updateParticipants(data) {
      this.fields.members.value = [...data]
    },
    saveForm() {
      const params = {}
      if (this.isLangEn) {
        this.fields.sessionName.value = this.fields.sessionNameEn.value
      }
      Object.keys(this.fields).forEach(key => {
        const { value } = this.fields[key]

        if (this.selectsWithGuid.includes(key)) {
          params[key] = this.convertGuid(value)
        } else if (['moderators', 'disscussants'].includes(key)) {
          if (value.length > 0) {
            params[key] = value.map((item) => ({
              roleId: this.roleStatus[key],
              email: item.login,
              lastName: this.isLangEn ? item.data.public.LNameEN : item.data.public.LName,
              firstName: this.isLangEn ? item.data.public.FNameEN : item.data.public.FName,
              secondName: this.isLangEn ? '' : item.data.public.SName,
              company: this.isLangEn ? item.CompanyNameShortEn : item.CompanyNameShort,
              lastNameEn: item.data.public.LNameEN,
              firstNameEn: item.data.public.FNameEN,
              companyEn: item.CompanyNameShortEn,
            }))
          } else {
            return params[key] = value;
          }
        } else if (key === 'members') {
          params[key] = value.map((item) => ({
            email: item.login
          }))
        } else {
          params[key] = value
        }
      })

      params.eventId = this.eventId
      params.lang = this.lang

      api.request('collective_request_add', params).then(data => {
        this.buttonSpinner = 'done'
        setTimeout(() => {
          this.buttonSpinner = false
          if (data && data.length) {
            this.isSaved = true
          } else {
            this.isSaved = true
            this.isSaveErr = true
          }
        }, 2000)
      })
    },
    getBackIfErr() {
      this.changePage(4)
      this.isSaved = false
      this.isSaveErr = false
    }
  },
  computed: {
    isLangEn() {
      return this.lang === 'en'
    },
    eventName() {
      return this.eventData ? `<br>&laquo;${this.eventData.Title}&raquo;` : ''
    },
    ...mapState(['lang'])
  },
  watch: {
    lang() {
      this.translateFields()
      this.$emit('validatePage', { current: this.page })
    },
    page() {
      console.log('page change', this.page)
      this.$store.commit('setIsLangDropdownDisabled', this.page !== 1)
    }
  },
  i18n: {
    messages: {
      en: {
        formName: 'Collective application for participation {br}in the event',
        applyRequest: 'Invite participants',
        applying: 'Iviting',
        okStatus: 'Invitations sent',
        errStatus: 'Error occured while <br>sending the invitations. <br>Try again later or contact <br>the event administrator about the error.',
      },
      ru: {
        formName: 'Коллективная заявка на участие {br}в мероприятии',
        applyRequest: 'Пригласить участников',
        applying: 'Приглашаем',
        okStatus: 'Приглашения отправлены',
        errStatus: 'При отправке приглашений <br>возникла ошибка. <br>Попробуйте повторить отправку <br>позже, или сообщите об ошибке <br>администратору мероприятия.',
      },
    }
  }
}
</script>

<style scoped>
.edit-request {
  width: fit-content;
  margin: 52px auto;
  background: #FFFFFF;
  border-radius: 12px;
  padding: 32px;
  max-width: calc(100vw - 192px);
}

.actions,
.actions .buttons {
  flex-wrap: wrap;
  display: flex;
  margin: 0 -12px;
  align-items: center;
  justify-content: space-between;
}

.actions {
  margin: -6px -12px;
}

.actions>div {
  padding: 6px 12px;
}

.actions .buttons>div {
  padding: 0 12px;
}

.result .status {
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}

@media (max-width: 1023px) {
  .edit-request {
    max-width: 95%;
    margin: 16px auto;
  }
}

@media (max-width: 767px) {
  .edit-request {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }
}
</style>

<style>
.edit-request .subheading {
  font-family: 'Work Sans', sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #0F0F14;
}
</style>
