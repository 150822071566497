<template>
  <div class="logo" v-if="this.filesPath">
    <img :src="this.filesPath" onerror="this.style.display = 'none'" />
  </div>
</template>
<script>
export default {
  data: () => ({
    filesPath: Object,
  }),
  props: ["id"],
  created() {
    this.filesPath =
      (process.env.NODE_ENV === "development"
        ? "http://aprilconference-test.hse.ru"
        : window.location.origin) + `/files/public/${this.id}`;
  },
  computed: {},
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>

<style scoped>
.logo {
  max-width: 140px;
  max-height: 140px;
  margin-top: 0.6em;
}
img {
  max-width: 140px;
  height: auto;
}
</style>
