<template>
  <div>
    <section class="session__items">
      <section class="items__item">
        <h4 class="session__title">
          {{ session.Type }} {{ sectionCode }}-{{ session.Code }}
        </h4>
        <h3 v-if="session.TypeId === sessionTypeId">{{ session.Title }}</h3>
        <div v-else>
          <h3 v-for="item in session.Items" :key="item.Id">
            {{ item.Title }}
          </h3>
        </div>
      </section>
      <section class="session__time">
        <div class="translation__languages">
          <p v-if="session.LangRu">RUS</p>
          <p v-if="session.LangEn">ENG</p>
          <IconHeadphones v-if="session.IsTranslation" />
        </div>
        <div class="time_and_format">
          <IconGlobalClock />
          <HseSpace vertical horizontal size="small_x" />
          <p>
            {{
              $i18n.locale === "ru"
                ? datesMap.get(date).DayRU.split(", ")[0]
                : datesMap.get(date).DayEN.split(", ")[1]
            }}
          </p>
          <HseSpace vertical horizontal size="small_3x" />
          <p>{{ session.StartTime }} - {{ session.EndTime }} (UTC +3)</p>
          <HseSpace vertical horizontal size="small_3x" />
          <p>&#x2022; {{ session.Format }}</p>
        </div>
      </section>
      <HseDivider />
      <section class="video_call" v-if="session.BroadCastLink">
        <a
          v-if="session.BroadCastLink"
          class="broadcast-link"
          target="_blank"
          :href="session.BroadCastLink"
        >
          <IconCamera />
        </a>
        <a
          class="video_call_text"
          v-if="session.BroadCastLink"
          target="_blank"
          :href="session.BroadCastLink"
        >
          {{ $t("connectToEvent") }}
        </a>
      </section>
      <section class="video_call" v-if="session.ZoomMeetingUrl">
        <a
          class="broadcast-link"
          target="_blank"
          :href="session.ZoomMeetingUrl"
        >
          <IconLink />
        </a>
        <a
          class="video_call_text"
          target="_blank"
          :href="session.ZoomMeetingUrl"
        >
          {{ $t("streamLink") }}
        </a>
      </section>
      <section class="room" v-if="session.RoomTitle != ''">
        <p style="margin-bottom: 0">{{ session.RoomTitle }}</p>
      </section>

      <section v-if="session.Items[0].Discussion">
        <p class="title">{{ $t("issuesForDiscussion") }}</p>
        <div v-if="isShowToggleDiscussion">
          <div class="accordion">
            <ul>
              <li><p v-html="session.Items[0].Discussion" /></li>
            </ul>
          </div>
        </div>
        <div v-if="!isShowToggleDiscussion">
          <div class="accordion accordion--hidden" v-if="!isShow">
            <ul>
              <li><p v-html="session.Items[0].Discussion" /></li>
            </ul>
          </div>
          <div class="accordion" v-if="isShow">
            <ul>
              <li><p v-html="session.Items[0].Discussion" /></li>
            </ul>
          </div>
          <button
            @click="toggleAccordion()"
            v-if="!isShow"
            class="accordion-link"
          >
            {{ $t("showInFull") }}
          </button>
          <button
            @click="toggleAccordion()"
            v-if="isShow"
            class="accordion-link"
          >
            {{ $t("hide") }}
          </button>
        </div>
      </section>

      <section
        v-if="
          session.Items[0].Annotation &&
          session.TypeId === 'b40acf74-7313-4f6d-846d-e9c9d1cc99bd'
        "
      >
        <p class="title">{{ $t("abstractToTheReport") }}</p>
        <div class="accordion" v-if="isShowToggleAnnonation">
          <ul>
            <li><p v-html="session.Items[0].Annotation" /></li>
          </ul>
        </div>
        <div v-if="!isShowToggleAnnonation">
          <div class="accordion accordion--hidden" v-if="!isShow">
            <ul>
              <li><p v-html="session.Items[0].Annotation" /></li>
            </ul>
          </div>
          <div class="accordion" v-if="isShow">
            <ul>
              <li><p v-html="session.Items[0].Annotation" /></li>
            </ul>
          </div>
          <button
            @click="toggleAccordion()"
            v-if="!isShow"
            class="accordion-link"
          >
            {{ $t("showInFull") }}
          </button>
          <button
            @click="toggleAccordion()"
            v-if="isShow"
            class="accordion-link"
          >
            {{ $t("hide") }}
          </button>
        </div>
      </section>
      <div v-if="session.BroadCastLink">
        <HseSpace vertical horizontal size="small_2x" />
        <HseDivider />
      </div>
      <section class="manager">
        <p
          v-if="
            (session.TypeId === sessionTypeId ||
              session.TypeId === roundtableTypeId) &&
            session.Manager
          "
          class="title"
        >
          {{ $tc("manager", session.Manager.split(", ").length) }}
        </p>
        <p
          v-else-if="session.Items[0] && session.Items[0].Manager"
          class="title"
        >
          {{ $tc("manager", session.Items[0].Manager.split(", ").length) }}
        </p>
        <p
          v-if="session.Items[0] && session.Items[0].Manager"
          style="margin-bottom: 0"
        >
          {{ session.Items[0].Manager }}
        </p>
        <p style="margin-bottom: 0">
          {{ session.Manager }}
        </p>
      </section>
      <section class="expert">
        <p
          v-if="
            (session.TypeId === sessionTypeId ||
              session.TypeId === roundtableTypeId) &&
            session.Participants
          "
          class="title"
        >
          {{ $tc("experts", session.Participants.split(", ").length) }}
        </p>
        <p
          v-else-if="session.Items[0] && session.Items[0].Participants"
          class="title"
        >
          {{ $tc("experts", session.Items[0].Participants.split(", ").length) }}
        </p>
        <p
          v-if="session.Items[0] && session.Items[0].Participants"
          style="margin-bottom: 0"
        >
          {{ session.Items[0].Participants }}
        </p>
        <p style="margin-bottom: 0">
          {{ session.Participants }}
        </p>
      </section>
      <section
        class="reports"
        v-if="session.Type === 'Сессия' || session.Type === 'Session'"
      >
        <p class="title">
          {{ $tc("reports", session.Items.length) }}
        </p>
        <ul class="main__items list-style-none">
          <li
            :class="{ 'item--found': item.IsWordFound }"
            v-for="item in session.Items"
            :key="item.Id"
          >
            <p v-if="session.TypeId === sessionTypeId" style="margin: 0">
              {{ item.Authors }}
            </p>
            <a
              :href="item.Annotation"
              v-if="session.TypeId === sessionTypeId"
              class="annotation_text"
            >
              {{ item.Title }}</a
            >
            <HseSpace vertical horizontal size="small_3x" />
          </li>
        </ul>
      </section>
      <section class="debutant">
        <p
          v-if="
            (session.TypeId === sessionTypeId ||
              session.TypeId === roundtableTypeId) &&
            session.Debutant
          "
          class="title"
        >
          {{ $tc("debutant", session.Debutant.split(", ").length) }}
        </p>
        <p
          v-if="
            (session.TypeId === sessionTypeId ||
              session.TypeId === roundtableTypeId) &&
            session.Debutant
          "
        >
          {{ session.Debutant }}
        </p>
      </section>
    </section>
  </div>
</template>

<script>
import IconHeadphones from "../IconHeadphones";
import IconGlobalClock from "../IconGlobalClock";
import IconCamera from "../IconCamera";
import IconLink from "../IconLink";
import { HseSpace, HseDivider } from "@hse-design/vue";
export default {
  components: {
    IconHeadphones,
    HseSpace,
    HseDivider,
    IconCamera,
    IconLink,
    IconGlobalClock,
  },
  props: {
    sessionTypeId: String,
    roundtableTypeId: String,
    session: Object,
    sectionCode: String,
    date: String,
    datesMap: Map,
  },
  data: () => ({
    isShow: false,
    isShowToggleAnnonation: false,
    isShowToggleDiscussion: false,
  }),
  created() {
    this.update();
  },
  computed: {},
  watch: {},
  methods: {
    toggleAccordion() {
      this.isShow = !this.isShow;
    },
    update() {
      console.log("Annotation", this.session?.Items[0]?.Annotation?.length);
      console.log("Discussion", this.session?.Items[0]?.Discussion?.length);

      if (this.session?.Items[0]?.Annotation?.length < 400) {
        this.isShowToggleAnnonation = true;
      } else {
        this.isShowToggleAnnonation = false;
      }

      if (this.session?.Items[0]?.Discussion?.length < 400) {
        this.isShowToggleDiscussion = true;
      } else {
        this.isShowToggleDiscussion = false;
      }
    },
  },
  i18n: {
    messages: {
      en: {
        connectToEvent: "Join the event",
        manager: "Moderator | Moderators",
        debutant: "Discussant | Discussants",
        reports: "Report | Reports",
        issuesForDiscussion: "Issues for discussion",
        abstractToTheReport: "Abstract to the report",
        streamLink: "Watch online",
        showInFull: "show in full",
        hide: "hide",
        audienceNumber: "Audience number",
        experts: "Expert | Experts",
      },
      ru: {
        connectToEvent: "Подключиться к мероприятию",
        manager: "Председатель | Председатели",
        debutant: "Дискуссант | Дискуссанты",
        reports: "Доклад | Доклады",
        issuesForDiscussion: "Вопросы для обсуждения",
        abstractToTheReport: "Аннотация к докладу",
        streamLink: "Ссылка на трансляцию",
        showInFull: "показать полностью",
        hide: "скрыть",
        audienceNumber: "Номер аудитории",
        experts: "Эксперт | Эксперты",
      },
    },
  },
};
</script>
<style>
.main__items {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.list-style-none > li {
  list-style: none;
}
.item--found {
  background-color: #fff6cb;
}
.video_call {
  display: flex;
  gap: 10px;
  align-items: baseline;
}
.video_call .broadcast-link {
  flex-basis: 24px;
}
.video_call_text {
  cursor: pointer;
  color: #0050cf;
  font-weight: 500;
  text-decoration: none;
}

.annotation_text {
  cursor: pointer;
  color: #0050cf;
  font-weight: 500;
  text-decoration: none;
}
.session__time {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5em;
  /* border-right: solid 2px var(--color-text-grey); */
  font-size: 0.8125em;
  color: #999;
}

.session__time svg {
  color: #555656;
  font-size: 24px;
  width: 24px;
  height: auto;
}

.translation__languages {
  display: flex;
  gap: 0.5em;
}

.translation__languages p {
  color: black;
  background-color: #e7eefb;
  font-size: 10px;
  line-height: 10px;
  padding: 10px 5px;
  border-radius: 4px;
  font-weight: 600;
}

.translation__languages svg {
  width: 24px;
  height: auto;
}
.session__items {
  position: relative;
  padding: 0 0.5em;

  display: flex;
  flex-direction: column;
}
.session__title {
  margin: 0;
  color: #1658da;
  font-weight: 500;
  text-transform: uppercase;
}
.items__item {
  display: flex;
  flex-direction: column;
  gap: 0.25em;
}
.text-small {
  font-size: 0.85em;
  color: var(--color-text-grey);
}

.text-3-lines {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.time_and_format {
  display: flex;
}

.time_and_format p {
  color: black;
  font-size: 15px;
}
.broadcast-link {
  color: #0050cf;
  font-size: 24px;
}
.title {
  font-weight: 500;
  color: #999;
  font-size: 18px;
}
.accordion {
}
.accordion--hidden ul {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.accordion ul {
  margin: 0;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.accordion li {
  position: relative;
  padding-left: 24px;
}

.accordion li::before {
  position: absolute;
  top: 0.2em;
  left: 0;
  display: inline-block;
  width: 1em;
  height: 1em;
  content: "";
  background-image: url(/img/listBullet.9722a13f.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 8px;
}

.accordion-link {
  cursor: pointer;
  color: #0050cf;
  font-weight: 500;
  text-decoration: none;
  padding: 0;
  border: none;
  background: transparent;
  margin: 10px 0 0 24px;
  display: inline-block;
}
</style>
