var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.variantMenu === 'static')?_c('div',[_c('HseSidebar',{ref:"sidebar",attrs:{"show-toggle":true,"classes":{},"withHeader":true,"variant":"static"},model:{value:(_vm.$store.state.isLeftMenuOpen),callback:function ($$v) {_vm.$set(_vm.$store.state, "isLeftMenuOpen", $$v)},expression:"$store.state.isLeftMenuOpen"}},[_c('div',{staticClass:"mobile-items top"},[_c('LangDropdown'),_c('RolesDropdown',{attrs:{"id":"left"}})],1),_c('div',{staticClass:"mobile-items"},[_c('HseSpace',{attrs:{"size":"small","vertical":""}})],1),_vm._l((_vm.items),function(item){return _c('router-link',{key:item.id,attrs:{"to":item.href,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
return [(!(item.id === 8 && !_vm.userHaveCollectiveRequest || item.id === 2 && !_vm.userHasEvents))?_c('hse-tooltip',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',[_vm._v(_vm._s(item.name))]),(item.id === 3)?_vm._l((_vm.$store.state.menuNotifications),function(notification){return _c('div',{key:notification},[_vm._v(_vm._s(notification))])}):_vm._e()]},proxy:true}],null,true)},[_c('HseSidebarItem',{attrs:{"icon":item.icon,"label":item.id === 3 ? _vm.$store.state.countNotifications : 0,"selected":isActive,"classes":{}},on:{"click":function () { return _vm.onSidebarItemClick(item); }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1):_vm._e()]}}],null,true)})}),_c('div',{staticClass:"mobile-items"},_vm._l((_vm.mobileItems),function(item){return _c('router-link',{key:item.id,attrs:{"to":item.href,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
return [(!(item.id === 8 && !_vm.userHaveCollectiveRequest))?_c('HseSidebarItem',{attrs:{"icon":item.icon,"label":item.id === 3 ? _vm.$store.state.countNotifications : 0,"selected":isActive,"classes":item.classes ? item.classes : {}},on:{"click":function () { return _vm.onSidebarItemClick(item); }}},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()]}}],null,true)})}),1)],2)],1):_vm._e(),(_vm.variantMenu === 'dynamic')?_c('div',[_c('HseSidebar',{ref:"sidebar",attrs:{"show-toggle":true,"classes":{},"withHeader":true,"variant":"dynamic"},model:{value:(_vm.$store.state.isLeftMenuOpen),callback:function ($$v) {_vm.$set(_vm.$store.state, "isLeftMenuOpen", $$v)},expression:"$store.state.isLeftMenuOpen"}},[_c('div',{staticClass:"mobile-items top"},[_c('LangDropdown'),_c('RolesDropdown',{attrs:{"id":"left"}})],1),_c('div',{staticClass:"mobile-items"},[_c('HseSpace',{attrs:{"size":"small","vertical":""}})],1),_vm._l((_vm.items),function(item){return _c('router-link',{key:item.id,attrs:{"to":item.href,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
return [(!(item.id === 8 && !_vm.userHaveCollectiveRequest || item.id === 2 && !_vm.userHasEvents))?_c('hse-tooltip',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',[_vm._v(_vm._s(item.name))]),(item.id === 3)?_vm._l((_vm.$store.state.menuNotifications),function(notification){return _c('div',{key:notification},[_vm._v(_vm._s(notification))])}):_vm._e()]},proxy:true}],null,true)},[_c('HseSidebarItem',{attrs:{"icon":item.icon,"label":item.id === 3 ? _vm.$store.state.countNotifications : 0,"selected":isActive,"classes":{}},on:{"click":function () { return _vm.onSidebarItemClick(item); }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1):_vm._e()]}}],null,true)})}),_c('div',{staticClass:"mobile-items"},_vm._l((_vm.mobileItems),function(item){return _c('router-link',{key:item.id,attrs:{"to":item.href,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
return [(!(item.id === 8 && !_vm.userHaveCollectiveRequest))?_c('HseSidebarItem',{attrs:{"icon":item.icon,"label":item.id === 3 ? _vm.$store.state.countNotifications : 0,"selected":isActive,"classes":item.classes ? item.classes : {}},on:{"click":function () { return _vm.onSidebarItemClick(item); }}},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()]}}],null,true)})}),1)],2)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }