import { HseCheckbox } from "@hse-design/vue";

const fields = {
  Passport: {
    label: "fields.honoraryOrInvitedReport.passport",
    value: "",
    isValid: false,
    isRequired: true,
    maxSizeMb: 3,
    formats: ["doc", "docx", "rtf", "pdf", "jpg", "png", "zip", "rar", "7z"],
  },
  isAgree: {
    value: false,
    isValid: false,
    hideTopLabel: true,
    label: "fields.name.pdAgreement",
    classes: { label: "smallLabel" },
    component: HseCheckbox,
    rules: [
      {
        name: "custom",
        message: "fields.rule.pdAgreement",
        validateVal: (value) => value,
      },
    ],
  },
};

Object.keys(fields).forEach((key) => {
  fields[key].setValues = function (isValid, value = "") {
    this.value = value;
    this.isValid = isValid;
  };
});

export default fields;
