<template>
  <div class="form">
    <div class="subheading">{{ $t("company") }}</div>
    <HseSpace size="small" vertical />
    <div class="cols two-cols">
      <div class="field">
        <Validator :field="fields.occupationType" @change="occupationChange" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field fileUploader_item" v-show="isStudying">
        <FileUploader :field="fields.StudentDocumentFileID" />
        <HseSpace size="small" vertical />
      </div>
    </div>
    <div class="cols two-cols">
      <div v-show="isWorking || isStudying" class="field">
        <Validator :field="fields.IsHSEPresenter" @change="onHSEChange" />
        <HseSpace size="small_2x" vertical />
      </div>
      <!--      <div v-show="isWorking" class="field">-->
      <!--        <Validator :field="fields.ApplicantIsProfessor" />-->
      <!--        <HseSpace size="small_2x" vertical />-->
      <!--      </div>-->
    </div>
    <div class="cols two-cols">
      <!--      <div v-show="isWorking && isHse" class="field longDMItem">-->
      <!--        <Validator :field="fields.ApplicantOccupationForm" />-->
      <!--        <HseSpace size="small_2x" vertical />-->
      <!--      </div>-->
      <div v-show="isHse && isNotSimple" class="field">
        <Validator :field="fields.campus" @change="setCampus" />
        <HseSpace size="small_2x" vertical />
      </div>
      <!--      <div v-show="isWorking" class="field longDMItem">-->
      <!--        <Validator :field="fields.ApplicantJobDirection" />-->
      <!--        <HseSpace size="small_2x" vertical />-->
      <!--      </div>-->
      <div v-if="isLangRu" v-show="isWorking && isHse" class="field longDMItem">
        <Validator :field="fields.ApplicantDepartmentIdRu" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div
        v-if="!isLangRu"
        v-show="isWorking && isHse"
        class="field longDMItem"
      >
        <Validator :field="fields.ApplicantDepartmentIdEn" />
        <HseSpace size="small_2x" vertical />
      </div>
      <!--      <div v-show="isHse && isCampusSet && isStudying" class="field longDMItem">-->
      <!--        <Validator :field="fields.faculty" :key="fields.faculty.campus" />-->
      <!--        <HseSpace size="small_2x" vertical />-->
      <!--      </div>-->
      <div
        v-show="isWorking || isStudying || isSelfEmpl || isOther"
        class="field"
      >
        <div class="flex-tooltip">
          <Validator :field="fields.ApplicantOrganizationCountry" />
          <HseTooltip :content="$t('fields.placeholder.companyCountry')">
            <IconStatusInfo />
          </HseTooltip>
        </div>
        <HseSpace size="small_2x" vertical />
      </div>
      <div
        v-if="
          (isWorking || isStudying || isSelfEmpl || isOther) &&
          isCompanyCountryRu
        "
        class="field"
      >
        <Validator :field="fields.ApplicantOrganizationRegion" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div v-if="isLangRu" class="field">
        <Validator :field="fields.applicantCompanyFullName" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div v-if="isLangRu" class="field">
        <Validator :field="fields.applicantCompanyShortName" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div v-if="!isLangRu" class="field">
        <Validator :field="fields.applicantCompanyFullNameEn" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field">
        <Validator :field="fields.applicantCompanyShortNameEn" />
        <HseSpace size="small_2x" vertical />
      </div>
      <!--      <div v-show="isStudying" class="field">-->
      <!--        <Validator :field="fields.degreeLevel" />-->
      <!--        <HseSpace size="small_2x" vertical />-->
      <!--      </div>-->
    </div>
    <div
      v-show="
        !passportCheck && isRequiredPassportForSpeaker && !fields.IsHSEPresenter.value
      "
    >
      <div class="subheading">{{ $t("info") }}</div>
      <HseSpace size="small" vertical />
      <div class="colsNew">
        <div class="field fileUploader_item">
          <HseSpace size="small" vertical />
          <FileUploader :field="fields.Passport" />
        </div>
        <div class="field">
          <Validator v-bind:field="fields.isAgree" />
          <span class="link" v-on:mouseup="() => (open = true)">{{
            $t("pd")
          }}</span>
          <HseSpace size="small_2x" vertical />
        </div>
      </div>
    </div>
    <HseModal v-model="open">
      <div>
        <Approval></Approval>
        <HseButton :style="{ marginLeft: 'auto' }" @click="open = false"
          >OK</HseButton
        >
      </div>
    </HseModal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { store } from "../../store";
import {
  HseSpace,
  HseTooltip,
  IconStatusInfo,
  HseModal,
  HseButton,
} from "@hse-design/vue";
import Validator from "../common/form/Validator.vue";
import FileUploader from "../common/form/FileUploader.vue";
import { resourceLoader } from "../common/resourceLoader";
// import FileUploader from "../common/form/FileUploader.vue";
import Approval from "../approval/Approval.vue";

export default {
  name: "FormPage3",
  store,
  props: ["fields", "passportCheck",
    "isRequiredPassport",
    "isRequiredPassportRussia",
    "isRequiredPassportOther",
  ],
  components: {
    Validator,
    FileUploader,
    HseSpace,
    HseTooltip,
    IconStatusInfo,
    HseModal,
    HseButton,
    Approval,
  },
  data: () => ({
    open: false,
    campus: { en: {}, ru: {} },
    campusRegion: {
      "11484285-8B98-453A-8C97-3909989B4979": "61", // perm
      "34CF730D-C4C2-4AE9-A052-D430996F11A8": "2", // spb
      "F32435E2-B45D-4CD9-87D3-EE2640F58DA3": "54", // nizhniy
      "DFA00199-FB13-47BB-A69B-F01C6F525F71": "1", // msk
    },
    ruCountryGuid: "BC763F4B-FBD9-461B-B395-166020E8C026",
  }),
  created() {
    ["en", "ru"].forEach((lang) =>
      resourceLoader
        .load("campusList", { lang })
        .then((data) => (this.campus[lang] = data))
    );

    this.$parent.$on("validatePage", ({ current }) => {
      if (current === 3) {
        this.$emit("validateFields");
        this.checkPageValidity();
      }
    });
  },
  computed: {
    isRequiredPassportForSpeaker() {
      const citizenIdRussia = "BC763F4B-FBD9-461B-B395-166020E8C026"
      
      return (
        ( this.isRequiredPassport && this.isRequiredPassportRussia && this.fields.сitizenshipCountryId.value === citizenIdRussia ) ||
        ( this.isRequiredPassport && this.isRequiredPassportOther && this.fields.сitizenshipCountryId.value !== citizenIdRussia )
      )
    },
    isCitizenRu() {
      return (
        this.fields.сitizenshipCountryId.value ===
        "BC763F4B-FBD9-461B-B395-166020E8C026"
      );
    },
    isLangRu() {
      return this.lang === "ru";
    },
    // isHSEEmployee () {
    //   return this.fields.ApplicantOccupationForm.value === '1'
    // },
    isCompanyCountryRu() {
      return (
        this.fields.ApplicantOrganizationCountry.value === this.ruCountryGuid
      );
    },
    isHse() {
      return this.fields.IsHSEPresenter.value;
    },
    isCampusSet() {
      return this.fields.campus.value;
    },
    isWorking() {
      return this.fields.occupationType.value === "1";
    },
    isStudying() {
      return this.fields.occupationType.value === "2";
    },
    isOther() {
      return this.fields.occupationType.value === "3";
    },
    isSelfEmpl() {
      return this.fields.occupationType.value === "4";
    },
    isNotSimple() {
      return !this.isOther && !this.isSelfEmpl;
    },
    isMoscowCampus() {
      return (
        this.fields.campus.value === "DFA00199-FB13-47BB-A69B-F01C6F525F71"
      );
    },
    pageFieldKeys() {
      return {
        occupationType: true,
        campus: this.isHse && this.isNotSimple,
        // faculty: this.isHse && this.isStudying,
        // degreeLevel: this.isStudying,
        applicantCompanyFullName: this.isLangRu,
        applicantCompanyShortName: this.isLangRu,
        applicantCompanyFullNameEn: !this.isLangRu,
        applicantCompanyShortNameEn: true,
        // ApplicantOccupationForm: this.isHse && this.isWorking,
        ApplicantDepartmentIdRu: this.isWorking && this.isHse && this.isLangRu,
        ApplicantDepartmentIdEn: this.isWorking && this.isHse && !this.isLangRu,
        // ApplicantJobDirection: this.isWorking,
        ApplicantOrganizationCountry: true,
        ApplicantOrganizationRegion: true,
        StudentDocumentFileID: this.isStudying,
      };
    },
    ...mapState(["lang"]),
  },
  methods: {
    setFldVal(fld, val) {
      this.fields[fld].setValues(false, val);
    },
    occupationChange() {
      if (this.isNotSimple) {
        this.fields.applicantCompanyFullName.setValues(false, "");
        this.fields.applicantCompanyShortName.setValues(false, "");
        this.fields.applicantCompanyFullNameEn.setValues(false, "");
        this.fields.applicantCompanyShortNameEn.setValues(false, "");

        if (this.isHse) {
          this.onHSEChange();
          if (this.fields.campus.value) {
            this.setCampus(this.fields.campus.value);
          }
        }
        return;
      }

      this.fields.applicantCompanyFullName.setValues(
        true,
        "Независимый исследователь"
      );
      this.fields.applicantCompanyShortName.setValues(
        true,
        "Независимый исследователь"
      );
      this.fields.applicantCompanyFullNameEn.setValues(
        true,
        "Independent researcher"
      );
      this.fields.applicantCompanyShortNameEn.setValues(
        true,
        "Independent researcher"
      );
    },
    setCampus(campus) {
      this.fields.ApplicantOrganizationRegion.setValues(
        true,
        this.campusRegion[campus]
      );
      // this.fields.faculty.setCampus(campus)

      const currCampus = this.campus.ru.find((cmps) => cmps.value === campus);
      const currCampusEn = this.campus.en.find((cmps) => cmps.value === campus);

      if (currCampus)
        this.fields.applicantCompanyShortName.setValues(
          true,
          `НИУ ВШЭ${this.isMoscowCampus ? "" : ` - ${currCampus.label}`}`
        );

      if (currCampusEn)
        this.fields.applicantCompanyShortNameEn.setValues(
          true,
          `HSE University${
            this.isMoscowCampus ? "" : ` - ${currCampusEn.label}`
          }`
        );
    },
    onHSEChange() {
      if (!this.isHse) {
        this.fields.applicantCompanyFullName.setValues(false, "");
        this.fields.applicantCompanyShortName.setValues(false, "");
        this.fields.applicantCompanyFullNameEn.setValues(false, "");
        this.fields.applicantCompanyShortNameEn.setValues(false, "");
        this.fields.ApplicantOrganizationCountry.setValues(true, "");
        this.fields.ApplicantOrganizationRegion.setValues(true, "");
        return;
      }
      this.fields.ApplicantOrganizationCountry.setValues(
        true,
        this.ruCountryGuid
      );

      this.fields.applicantCompanyFullName.setValues(
        true,
        "Национальный исследовательский университет «Высшая школа экономики»"
      );
      this.fields.applicantCompanyFullNameEn.setValues(
        true,
        "National Research University Higher School of Economics"
      );

      if (this.fields.campus.value) this.setCampus(this.fields.campus.value);
    },
    checkPageValidity() {
      let isPageValid = !Object.keys(this.pageFieldKeys).some((key) => {
        const isFieldRequired = this.pageFieldKeys[key];

        return isFieldRequired ? !this.fields[key].isValid : false;
      });
      console.log(
        "🚀 ~ file: FormPage3.vue:313 ~ isPageValid ~ isPageValid:",
        isPageValid
      );

      if (
        !this.passportCheck &&
        this.isRequiredPassportForSpeaker &&
        !this.fields.IsHSEPresenter.value
      ) {
        console.log("test");
        if (
          this.fields.Passport.value === "" ||
          this.fields.isAgree.value == false
        ) {
          isPageValid = false;
        }
      }

      this.$emit("validatePage", { page: 3, isValid: isPageValid });
    },
  },
  watch: {
    // lang () {
    //   this.fields.faculty.setCampus(this.fields.campus.value)
    // }
  },
  i18n: {
    messages: {
      en: {
        company: "Company",
        info: "Information about participant/speaker",
        pd: "Personal data processing agreement",
      },
      ru: {
        company: "Организация",
        info: "Информация об участнике/докладчике",
        pd: "Согласие на обработку персональных данных",
      },
    },
  },
};
</script>

<style scoped>
.form {
  max-width: 670px;
}
.cols {
  display: flex;
  margin: 0 -15px;
  flex-wrap: wrap;
}
.cols > .field {
  padding: 0 15px;
  flex: 1;
}
.colsNew {
  margin: 0 -15px;
  flex-wrap: wrap;
}
.colsNew > .field {
  padding: 0 15px;
  flex: 1;
}
.two-cols .field {
  max-width: 50%;
  width: 50%;
  flex-basis: 50%;
}
.three-cols .field {
  max-width: 33.3%;
}
.flex-tooltip {
  display: flex;
}
.flex-tooltip > div:first-child {
  width: calc(100% - 28px);
}
.flex-tooltip > div:last-child {
  padding: 36px 0 0 12px;
}
@media (max-width: 767px) {
  .form {
    max-width: unset;
  }
  .two-cols .field,
  .three-cols .field {
    max-width: 100%;
    width: 100%;
    flex-basis: 100%;
  }
}
</style>
<style>
.field.longDMItem .hse-DropdownMenuBaseItem {
  height: auto;
  min-height: 40px;
}
@media screen and (min-width: 767px) {
  .fileUploader_item {
    position: relative;
  }
  .fileUploader_item .fileUploader {
    position: relative;
    top: -20px;
  }
}

.fileUploader_item .fileUploader .label {
  font-size: 12px;
}

.link {
  font-size: 80%;
  cursor: pointer;
  color: blue;
}
</style>
