<template>
  <div>
    <div class="subheading">{{ $t("personal") }}</div>

    <HseSpace size="small" vertical />

    <div class="cols two-cols">
      <div class="field longDMItem">
        <Validator :field="fields.LName" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field longDMItem">
        <Validator :field="fields.FName" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field longDMItem">
        <Validator :field="fields.SName" />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>

    <div class="cols two-cols">
      <div class="field longDMItem">
        <Validator :field="fields.Position" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field longDMItem">
        <Validator :field="fields.DepartmentIdRu" />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>

    <div class="cols two-cols">
      <div class="field longDMItem">
        <Validator :field="fields.Phone" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field longDMItem">
        <Validator :field="fields.Email" />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>
    <RadioGroupWithTooltip
      :label="$t('statusRoundTable')"
      :options="fields.StatusRT.options"
      @change="setFormatStatusRoundTable"
      :disabled="fields.StatusRT.disabled"
      :defaultValue="fields.StatusRT.value"
    />
    <HseSpace size="small_2x" vertical />

    <div class="cols two-cols">
      <div class="field">
        <Validator :field="fields.plannedRTname" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field">
        <Validator :field="fields.plannedRTnameEn" />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>
    <RadioGroup
      :label="$t('eventFormat')"
      :options="fields.plannedSpecialEventOrganizationFormatId.options"
      @change="setFormat"
      :disabled="fields.plannedSpecialEventOrganizationFormatId.disabled"
      :defaultValue="fields.plannedSpecialEventOrganizationFormatId.value"
    />
    <HseSpace size="small_2x" vertical />
    <div class="cols two-cols">
      <div class="field">
        <Validator :field="fields.plannedDiscussionQuestions" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field">
        <Validator :field="fields.plannedDdiscussionQuestionsEn" />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>

    <div class="field">
      <Validator :field="fields.comment" />
      <HseSpace size="small_2x" vertical />
    </div>
    <div class="field">
      <Validator :field="fields.involvedBusinessOrg" />
      <HseSpace size="small_2x" vertical />
    </div>
    <div class="field">
      <Validator :field="fields.involvedInfoOrg" />
      <HseSpace size="small_2x" vertical />
    </div>
    <!-- <div class="cols">
      <div class="field">
        <RadioGroup :options="fields.publicityId.options" :label="fields.publicityId.label"
          :defaultValue="fields.publicityId.value" @change="setPublicityId" :disabled="fields.publicityId.disabled" />
      </div>
      <HseSpace size='small' horizontal />
    </div> -->
    <div class="subheading">
      {{ $t("fields.honoraryOrInvitedReport.coordinatingLeader") }}
    </div>
    <HseSpace size="small" vertical />

    <div class="field">
      <Validator :field="fields.ChiefFIO" />
      <HseSpace size="small_2x" vertical />
    </div>

    <div class="cols two-cols">
      <div class="field">
        <Validator :field="fields.ChiefPost" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field">
        <Validator :field="fields.ChiefDepartmenID" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field">
        <Validator :field="fields.ChiefEmail" />
      </div>
    </div>

    <div>
      <HseSpace size="small" vertical />
      <div
        :style="{ background: '#6B7A99', padding: '6px' }"
        class="accordion__head"
      >
        <HseButton
          variant="tertiaryInvert"
          size="medium"
          v-on:click="isModeratorBlockOpen = !isModeratorBlockOpen"
        >
          {{ $t("moderatorBlock") }}
          <span class="ident" v-show="isModeratorBlockOpen">&#9650;</span>
          <span class="ident" v-show="!isModeratorBlockOpen">&#9660;</span>
        </HseButton>
        <HseButton
          v-if="!(fieldsDisabled === true) && fieldsDisabled !== 'membersOnly'"
          size="medium"
          :rightIcon="plusIcon"
          @click="openModeratorModal"
        >
          {{ $t("addParticipant") }}
        </HseButton>
        <div
          class="accordion__info"
          v-show="!(isModeratorBlockOpen && moderators.length)"
        >
          {{ $t("moderatorInfo") }}
        </div>
      </div>
      <div
        v-show="isModeratorBlockOpen && moderators.length"
        :style="{ background: '#F0F2F5', padding: '6px' }"
      >
        <div class="accordion">
          <div
            class="participant"
            v-for="(moderator, index) in moderators"
            :key="moderator.email"
          >
            <div class="participant__heading">
              <HseHeading size="h4">{{
                `${$t("fields.name.moderators")} №${index + 1}`
              }}</HseHeading>
              <div
                class="icons"
                v-if="
                  !(fieldsDisabled === true) && fieldsDisabled !== 'membersOnly'
                "
              >
                <svg
                  @click="() => editModerator(moderator, index)"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.60161 5.35704L11.4995 7.26425L6.69535 12.092L4.7985 10.1848L9.60161 5.35704ZM12.9397 4.89707L12.0933 4.04652C11.7662 3.71781 11.235 3.71781 10.9068 4.04652L10.0961 4.86126L11.994 6.76849L12.9397 5.81814C13.1934 5.56317 13.1934 5.15202 12.9397 4.89707ZM3.80527 12.8655C3.77073 13.0217 3.91108 13.1617 4.06654 13.1237L6.18147 12.6084L4.28461 10.7012L3.80527 12.8655Z"
                    fill="#6B7A99"
                  />
                </svg>
                <svg
                  @click="() => removeModerator(moderator)"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.6663 5.164L11.7263 4.224L7.99967 7.95067L4.27301 4.224L3.33301 5.164L7.05967 8.89067L3.33301 12.6173L4.27301 13.5573L7.99967 9.83067L11.7263 13.5573L12.6663 12.6173L8.93967 8.89067L12.6663 5.164Z"
                    fill="#6B7A99"
                  />
                </svg>
              </div>
            </div>
            <HseSpace size="small" vertical />
            <div class="cols two-cols">
              <ReadonlyInput
                :label="$t('fields.name.lName')"
                :value="moderator.lastName"
              />
              <ReadonlyInput
                :label="$t('fields.name.fName')"
                :value="moderator.firstName"
              />
              <ReadonlyInput
                v-if="moderator.secondName"
                :label="$t('fields.name.mName')"
                :value="moderator.secondName"
              />
              <ReadonlyInput
                :label="$t('fields.name.email')"
                :value="moderator.email"
              />
              <ReadonlyInput
                :label="$t('fields.name.companyShort')"
                :value="moderator.organizationName"
              />
              <!-- <ReadonlyInput
                :label="$t('pass')"
                :value="moderator.pass === true ? $t('yes') : $t('no')"
              />
              <ReadonlyInput
                :label="$t('letter')"
                :value="moderator.letter === true ? $t('yes') : $t('no')"
              /> -->
              <div
                :style="{ width: '100%' }"
                v-if="
                  isSendedQRForModerators2 &&
                  (statusPublicated || statusPublication) &&
                  moderator.secondName
                "
              />
              <HseButton
                :style="{ marginLeft: '15px' }"
                v-if="
                  !(
                    moderator.participationFormatId ===
                      '1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A' &&
                    fields.plannedSpecialEventOrganizationFormatId.value ===
                      '895BB6AB-A343-48DC-AA19-7EAB348C8C0D'
                  ) &&
                  isSendedQRForModerators2 &&
                  !isAllModeratorOnline2 &&
                  (statusPublicated || statusPublication)
                "
                variant="primary"
                @click="() => openQRCodeModal('moderator', moderator.id)"
                size="medium"
              >
                {{ $t("QRCodeForModerator") }}
              </HseButton>
            </div>
          </div>
          <div
            class="actions"
            v-if="
              !isSendedQRForModerators2 &&
              !isAllModeratorOnline2 &&
              (statusPublicated || statusPublication)
            "
          >
            <HseButton
              variant="primary"
              @click="() => openQRCodeModal('moderator')"
              size="medium"
            >
              {{ $t("QRCodeForModerators") }}
            </HseButton>
          </div>
        </div>
      </div>
    </div>

    <div>
      <HseSpace size="small" vertical />
      <div
        :style="{ background: '#6B7A99', padding: '6px' }"
        class="accordion__head"
      >
        <HseButton
          variant="tertiaryInvert"
          size="medium"
          v-on:click="isSpeakerBlockOpen = !isSpeakerBlockOpen"
        >
          {{ $t("speakerBlock") }}
          <span class="ident" v-show="isSpeakerBlockOpen">&#9650;</span>
          <span class="ident" v-show="!isSpeakerBlockOpen">&#9660;</span>
        </HseButton>
        <HseButton
          v-if="!(fieldsDisabled === true) && fieldsDisabled !== 'membersOnly'"
          size="medium"
          :rightIcon="plusIcon"
          @click="openSpeakerModal"
        >
          {{ $t("addParticipant") }}
        </HseButton>
        <div
          class="accordion__info"
          v-show="!(isSpeakerBlockOpen && speakers.length)"
        >
          {{ $t("speakerInfo") }}
        </div>
      </div>
      <div
        v-show="isSpeakerBlockOpen && speakers.length"
        :style="{ background: '#F0F2F5', padding: '6px' }"
      >
        <div class="accordion">
          <div
            class="participant"
            v-for="(speaker, index) in speakers"
            :key="speaker.email"
          >
            <div class="participant__heading">
              <HseHeading size="h4">{{
                `${$t("fields.name.speakers")} №${index + 1}`
              }}</HseHeading>
              <div
                class="icons"
                v-if="
                  !(fieldsDisabled === true) && fieldsDisabled !== 'membersOnly'
                "
              >
                <svg
                  @click="() => editSpeaker(speaker, index)"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.60161 5.35704L11.4995 7.26425L6.69535 12.092L4.7985 10.1848L9.60161 5.35704ZM12.9397 4.89707L12.0933 4.04652C11.7662 3.71781 11.235 3.71781 10.9068 4.04652L10.0961 4.86126L11.994 6.76849L12.9397 5.81814C13.1934 5.56317 13.1934 5.15202 12.9397 4.89707ZM3.80527 12.8655C3.77073 13.0217 3.91108 13.1617 4.06654 13.1237L6.18147 12.6084L4.28461 10.7012L3.80527 12.8655Z"
                    fill="#6B7A99"
                  />
                </svg>
                <svg
                  @click="() => removeSpeaker(speaker)"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.6663 5.164L11.7263 4.224L7.99967 7.95067L4.27301 4.224L3.33301 5.164L7.05967 8.89067L3.33301 12.6173L4.27301 13.5573L7.99967 9.83067L11.7263 13.5573L12.6663 12.6173L8.93967 8.89067L12.6663 5.164Z"
                    fill="#6B7A99"
                  />
                </svg>
              </div>
            </div>
            <HseSpace size="small" vertical />
            <div class="cols two-cols">
              <ReadonlyInput
                :label="$t('fields.name.lName')"
                :value="speaker.lastName"
              />
              <ReadonlyInput
                :label="$t('fields.name.fName')"
                :value="speaker.firstName"
              />
              <ReadonlyInput
                v-if="speaker.secondName"
                :label="$t('fields.name.mName')"
                :value="speaker.secondName"
              />
              <ReadonlyInput
                :label="$t('fields.name.email')"
                :value="speaker.email"
              />
              <ReadonlyInput
                :label="$t('fields.name.companyShort')"
                :value="speaker.organizationName"
              />
              <!-- <ReadonlyInput
                :label="$t('pass')"
                :value="speaker.pass === true ? $t('yes') : $t('no')"
              />
              <ReadonlyInput
                :label="$t('letter')"
                :value="speaker.letter === true ? $t('yes') : $t('no')"
              /> -->
              <div
                :style="{ width: '100%' }"
                v-if="
                  isSendedQRForSpeakers2 &&
                  (statusPublicated || statusPublication) &&
                  speaker.secondName
                "
              />
              <HseButton
                :style="{ marginLeft: '15px' }"
                v-if="
                  !(
                    speaker.participationFormatId ===
                      '1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A' &&
                    fields.plannedSpecialEventOrganizationFormatId.value ===
                      '895BB6AB-A343-48DC-AA19-7EAB348C8C0D'
                  ) &&
                  isSendedQRForSpeakers2 &&
                  !isAllSpeakersOnline2 &&
                  (statusPublicated || statusPublication)
                "
                variant="primary"
                @click="() => openQRCodeModal('speaker', speaker.id)"
                size="medium"
              >
                {{ $t("QRCodeForSpeaker") }}
              </HseButton>
            </div>
          </div>
          <div
            class="actions"
            v-if="
              !isSendedQRForSpeakers2 &&
              !isAllSpeakersOnline2 &&
              (statusPublicated || statusPublication)
            "
          >
            <HseButton
              variant="primary"
              @click="() => openQRCodeModal('speaker')"
              size="medium"
            >
              {{ $t("QRCodeForSpeakers") }}
            </HseButton>
          </div>
        </div>
      </div>
    </div>

    <div>
      <HseSpace size="small" vertical />
      <div
        :style="{ background: '#6B7A99', padding: '6px' }"
        class="accordion__head"
      >
        <HseButton
          variant="tertiaryInvert"
          size="medium"
          v-on:click="isMemberBlockOpen = !isMemberBlockOpen"
        >
          {{ $t("memberBlock") }}
          <span class="ident" v-show="isMemberBlockOpen">&#9650;</span>
          <span class="ident" v-show="!isMemberBlockOpen">&#9660;</span>
        </HseButton>
        <HseButton
          v-if="!(fieldsDisabled === true) || fieldsDisabled === 'membersOnly'"
          size="medium"
          :rightIcon="plusIcon"
          @click="openMemberModal"
        >
          {{ $t("addParticipant") }}
        </HseButton>
        <div
          class="accordion__info"
          v-show="!(isMemberBlockOpen && members.length)"
        >
          {{ $t("memberInfo") }}
        </div>
      </div>
      <div
        v-show="isMemberBlockOpen && members.length"
        :style="{ background: '#F0F2F5', padding: '6px' }"
      >
        <div class="accordion">
          <div
            class="participant"
            v-for="(member, index) in members"
            :key="member.email"
          >
            <div class="participant__heading">
              <HseHeading size="h4">{{
                `${$t("fields.name.members")} №${index + 1}`
              }}</HseHeading>
              <div
                class="icons"
                v-if="
                  !(fieldsDisabled === true) || fieldsDisabled === 'membersOnly'
                "
              >
                <svg
                  @click="() => editMember(member, index)"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.60161 5.35704L11.4995 7.26425L6.69535 12.092L4.7985 10.1848L9.60161 5.35704ZM12.9397 4.89707L12.0933 4.04652C11.7662 3.71781 11.235 3.71781 10.9068 4.04652L10.0961 4.86126L11.994 6.76849L12.9397 5.81814C13.1934 5.56317 13.1934 5.15202 12.9397 4.89707ZM3.80527 12.8655C3.77073 13.0217 3.91108 13.1617 4.06654 13.1237L6.18147 12.6084L4.28461 10.7012L3.80527 12.8655Z"
                    fill="#6B7A99"
                  />
                </svg>
                <svg
                  @click="() => removeMember(member)"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.6663 5.164L11.7263 4.224L7.99967 7.95067L4.27301 4.224L3.33301 5.164L7.05967 8.89067L3.33301 12.6173L4.27301 13.5573L7.99967 9.83067L11.7263 13.5573L12.6663 12.6173L8.93967 8.89067L12.6663 5.164Z"
                    fill="#6B7A99"
                  />
                </svg>
              </div>
            </div>
            <HseSpace size="small" vertical />
            <div class="cols two-cols">
              <ReadonlyInput
                :label="$t('fields.name.lName')"
                :value="member.lastName"
              />
              <ReadonlyInput
                :label="$t('fields.name.fName')"
                :value="member.firstName"
              />
              <ReadonlyInput
                v-if="member.secondName"
                :label="$t('fields.name.mName')"
                :value="member.secondName"
              />
              <ReadonlyInput
                :label="$t('fields.name.email')"
                :value="member.email"
              />
              <ReadonlyInput
                :label="$t('fields.name.companyShort')"
                :value="member.organizationName"
              />
              <ReadonlyInput
                :label="$t('fields.roundTable.participationStatusId')"
                :value="
                  $t(formatParticipationStatusId(member.participationStatusId))
                "
              />
              <!-- <ReadonlyInput
                :label="$t('pass')"
                :value="member.pass === true ? $t('yes') : $t('no')"
              />
              <ReadonlyInput
                :label="$t('letter')"
                :value="member.letter === true ? $t('yes') : $t('no')"
              /> -->
              <div
                :style="{ width: '100%' }"
                v-if="
                  isSendedQRForMembers2 &&
                  !isAllMemberOnline2 &&
                  (statusPublicated || statusPublication) &&
                  !member.secondName
                "
              />
              <HseButton
                :style="{ marginLeft: '15px' }"
                v-if="
                  !(
                    member.participationFormatId ===
                      '1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A' &&
                    fields.plannedSpecialEventOrganizationFormatId.value ===
                      '895BB6AB-A343-48DC-AA19-7EAB348C8C0D'
                  ) &&
                  member.participationStatusId ===
                    '99815A4C-EACB-43CD-AD99-5275549F1631' &&
                  isSendedQRForMembers2 &&
                  !isAllMemberOnline2 &&
                  (statusPublicated || statusPublication)
                "
                variant="primary"
                @click="() => openQRCodeModal('member', member.id)"
                size="medium"
              >
                {{ $t("QRCodeForMember") }}
              </HseButton>
            </div>
          </div>
          <div class="actions" v-if="statusPublicated || statusPublication">
            <HseButton
              v-if="!isSendedQRForMembers2 && !isAllMemberOnline2"
              @click="() => openQRCodeModal('member')"
              variant="primary"
              size="medium"
            >
              {{ $t("QRCodeForMembers") }}
            </HseButton>
            <HseButton
              variant="primary"
              size="medium"
              @click="openWebinarModal"
              v-if="
                statusPublicated && this.data.webinarLink && showWebinarLink
              "
            >
              {{ $t("Webinar") }}
            </HseButton>
            <HseButton variant="primary" @click="approveAll" size="medium">
              {{ $t("Approve") }}
            </HseButton>
          </div>
        </div>
      </div>
      <HseSpace size="small" vertical />
    </div>

    <div class="actions">
      <HseLink variant="secondary" @click="back">
        {{ $t("back") }}
      </HseLink>
      <div class="actions" v-if="fieldsDisabled === false">
        <HseButton
          variant="primary"
          :spinner="draftSpinner"
          spinnerPosition="left"
          size="medium"
          @click="draft"
        >
          {{ $t("draft") }}
        </HseButton>
        <HseButton
          variant="primary"
          :spinner="submitSpinner"
          spinnerPosition="left"
          size="medium"
          @click="submit"
        >
          {{ $t("submit") }}
        </HseButton>
      </div>
      <HseButton
        variant="primary"
        v-if="
          !statusDraft &&
          (fieldsDisabled === 'partially' || fieldsDisabled === 'membersOnly')
        "
        :spinner="submitSpinner"
        spinnerPosition="left"
        size="medium"
        @click="submit"
      >
        {{ $t("save") }}
      </HseButton>
    </div>

    <HseModal v-model="isModeratorModalOpen" :shouldCloseOnOverlayClick="false">
      <ModeratorForm
        v-if="isModeratorModalOpen"
        :editableModerator="editableModerator"
        :added="moderators"
        @saved="onModeratorSave"
        :showFormat="fieldsDisabled"
        :orgFormat="fields.plannedSpecialEventOrganizationFormatId.value"
      />
    </HseModal>

    <HseModal v-model="isSpeakerModalOpen" :shouldCloseOnOverlayClick="false">
      <SpeakerForm
        v-if="isSpeakerModalOpen"
        :editableSpeaker="editableSpeaker"
        :added="speakers"
        @saved="onSpeakerSave"
        :showFormat="fieldsDisabled"
        :orgFormat="fields.plannedSpecialEventOrganizationFormatId.value"
      />
    </HseModal>

    <HseModal v-model="isMemberModalOpen" :shouldCloseOnOverlayClick="false">
      <MemberForm
        v-if="isMemberModalOpen"
        :editableMember="editableMember"
        :added="members"
        @saved="onMemberSave"
        :showFormat="fieldsDisabled"
        :orgFormat="fields.plannedSpecialEventOrganizationFormatId.value"
      />
    </HseModal>

    <HseModal v-model="isWebinarModalOpen" :shouldCloseOnOverlayClick="false">
      <WebinarForm
        v-if="isWebinarModalOpen"
        :link="data.webinarLink"
        :rtId="rtId"
        @saved="onWebinarSave"
      />
    </HseModal>

    <HseModal v-model="isQRCodeModalOpen" :shouldCloseOnOverlayClick="false">
      <QRCodeForm
        v-if="isQRCodeModalOpen"
        :rtId="rtId"
        :type="qrCodeType"
        :fields="fields"
        :participantId="participantId"
        @saved="onQRCodeSave"
      />
    </HseModal>
    <HseModal v-model="isWarningModalOpen" :shouldCloseOnOverlayClick="false">
      <WarningModalVue
        :isFormatEdit="isEditFormat"
        @closeWarningModal="() => closeWarningModal()"
      />
    </HseModal>
  </div>
</template>

<script>
import {
  HseHeading,
  HseSpace,
  HseButton,
  HseLink,
  HseModal,
  IconActionPlus,
} from "@hse-design/vue";
import Validator from "../common/form/Validator";
// import Datepicker from "../common/form/Datepicker";
import RadioGroupWithTooltip from "../common/form/RadioGroupWithTooltip";
import RadioGroup from "../common/form/RadioGroup";
import ModeratorForm from "./ModeratorForm1.vue";
import SpeakerForm from "./SpeakerForm1.vue";
import MemberForm from "./MemberForm1.vue";
import WebinarForm from "./WebinarForm.vue";
import QRCodeForm from "./QRCodeForm.vue";
import ReadonlyInput from "../common/ReadonlyInput.vue";
import { mapState } from "vuex";
import { api } from "../../api";
import WarningModalVue from "./WarningModal.vue";

export default {
  name: "FormPage1",
  components: {
    Validator,
    // Datepicker,
    RadioGroup,
    HseHeading,
    HseSpace,
    HseButton,
    HseLink,
    ReadonlyInput,
    HseModal,
    ModeratorForm,
    SpeakerForm,
    MemberForm,
    WebinarForm,
    QRCodeForm,
    WarningModalVue,
    RadioGroupWithTooltip,
  },
  data: () => ({
    plusIcon: IconActionPlus,
    isModeratorBlockOpen: true,
    isModeratorModalOpen: false,
    moderators: [],
    editableModerator: null,
    isSpeakerBlockOpen: true,
    isSpeakerModalOpen: false,
    speakers: [],
    editableSpeaker: null,
    isMemberBlockOpen: true,
    isMemberModalOpen: false,
    isWarningModalOpen: false,
    members: [],
    membersChanged: false,
    editableMember: null,
    isWebinarModalOpen: false,
    isQRCodeModalOpen: false,
    participantId: null,
    qrCodeType: "",
    draftSpinner: false,
    submitSpinner: false,
    isEditFormat: false,
    fieldsToValidate2: [
      "plannedSpecialEventOrganizationFormatId",
      "massMediaId",
      "maxParticipants",
      "endRegistrationDate",
      "volunteerSupportBlocksId",
    ],
    radiosToValidate: [
      "syncTranslation",
      "needOnlineSupport",
      "needVolunteerSupport",
      "needOfficialPhoto",
      "needOfficialVideo",
      "publicityId",
      "needOnlineTranslation",
    ],
    fieldsToValidate: [
      // "PlannedSpecialEventOrganizationFormatId",
      // "RTname",
      // "RTnameEn",
      // "responsibleFio",
      // "discussionQuestions",
      // "discussionQuestionsEn",
      // "date",
      // "programSessionTimeId",
      // "langRu",
      // "langEn",
      // "langOther",
      // "other",
      // "contactPerson",
      // "contactPersonEmail",
      // "comment",
      // "involvedBusinessOrg",
      // "involvedInfoOrg",
      // "moderators",
      // "moderatorsDeleteIds",
      // "speakers",
      // "speakersDeleteIds",
      // "members",
      // "membersDeleteIds",
      // "publicityId",
      "LName",
      "FName",
      "SName",
      "Position",
      "DepartmentIdRu",
      "Email",
      "Phone",
      "StatusRT",
      "plannedRTname",
      "plannedRTnameEn",
      "plannedSpecialEventOrganizationFormatId",
      "plannedDiscussionQuestions",
      "plannedDdiscussionQuestionsEn",
      "comment",
      "involvedBusinessOrg",
      "involvedInfoOrg",
      "ChiefFIO",
      "ChiefPost",
      "ChiefDepartmenID",
      "ChiefEmail",
    ],
    isSendedQRForSpeakers2: null,
    isAllSpeakersOnline2: null,
    isSendedQRForModerators2: null,
    isAllModeratorOnline2: null,
    isSendedQRForMembers2: null,
    isAllMemberOnline2: null,
  }),
  props: [
    "fields",
    "fieldsDisabled",
    "eventId",
    "user",
    "isNew",
    "data",
    "rtId",
  ],
  created() {
    this.fields.publicityId.getAllOptions();
    this.fields.plannedSpecialEventOrganizationFormatId.getAllOptions();

    this.fields.LName.setValues(
      false,
      this.$store.state.user.data.public.LName
    );
    this.fields.FName.setValues(
      false,
      this.$store.state.user.data.public.FName
    );
    this.fields.SName.setValues(
      false,
      this.$store.state.user.data.public.SName
    );
    this.fields.Email.setValues(false, this.$store.state.user.login);
    this.fields.Position.setValues(
      false,
      this.$store.state.user.data.public.Position
    );
    this.fields.Phone.setValues(
      false,
      this.$store.state.user.data.public.Phone
    );

    this.fields.StatusRT.getAllOptions();
  },
  methods: {
    dateFormat(date) {
      return date.split(".").reverse().join("-");
    },
    submit() {
      this.saveForm(false);
    },
    draft() {
      this.saveForm(true);
    },
    back() {
      this.$router.push("/events");
    },
    updateTimestamps(val) {
      this.fields.programSessionTimeId.setDate(this.dateFormat(val));
      this.$emit("loadAllOptions");
    },
    approveAll() {
      if (
        this.members.some(
          (member) =>
            member.participationStatusId !==
            "99815A4C-EACB-43CD-AD99-5275549F1631"
        )
      ) {
        this.membersChanged = true;
        this.members = this.members.map((member) => ({
          ...member,
          participationStatusId: "99815A4C-EACB-43CD-AD99-5275549F1631",
        }));
        this.fields.members.setValues(true, this.members);
      }
    },
    formatParticipationStatusId(id) {
      const obj = {
        "99815A4C-EACB-43CD-AD99-5275549F1631": "approved",
        "600BCF81-1B99-422A-B1E3-53A994058F87": "rejected",
        "489B1F10-AA4F-4E5E-A01A-70B03F5BC72C": "sent",
      };
      return obj[id];
    },
    closeWarningModal() {
      this.isWarningModalOpen = false;
      this.isEditFormat = false;
      this.draftSpinner = false;
    },
    setFormatStatusRoundTable(format) {
      this.fields.StatusRT.setValues(true, format);
    },
    setFormat(format) {
      this.fields.plannedSpecialEventOrganizationFormatId.setValues(
        true,
        format
      );
      if (format === "895BB6AB-A343-48DC-AA19-7EAB348C8C0D") {
        // this.isWarningModalOpen = true;
        this.isEditFormat = true;
        if (this.fields.members)
          this.fields.members.setValues(
            true,
            this.fields.members.value.map((item) => {
              return {
                ...item,
                participationFormatId: (item.participationFormatId =
                  "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A"),
              };
            })
          );
        if (this.members)
          this.members = this.members.map((item) => {
            return {
              ...item,
              participationFormatId: (item.participationFormatId =
                "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A"),
            };
          });

        if (this.fields.moderators)
          this.fields.moderators.setValues(
            true,
            this.fields.moderators.value.map((item) => {
              return {
                ...item,
                participationFormatId: (item.participationFormatId =
                  "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A"),
              };
            })
          );
        if (this.moderators)
          this.moderators = this.moderators.map((item) => {
            return {
              ...item,
              participationFormatId: (item.participationFormatId =
                "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A"),
            };
          });

        if (this.fields.speakers)
          this.fields.speakers.setValues(
            true,
            this.fields.speakers.value.map((item) => {
              return {
                ...item,
                participationFormatId: (item.participationFormatId =
                  "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A"),
              };
            })
          );
        if (this.speakers)
          this.speakers = this.speakers.map((item) => {
            return {
              ...item,
              participationFormatId: (item.participationFormatId =
                "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A"),
            };
          });
      } else {
        if (this.fields.members)
          this.fields.members.setValues(
            true,
            this.fields.members.value.map((item) => {
              return {
                ...item,
                participationFormatId: (item.participationFormatId = format),
              };
            })
          );
        if (this.members)
          this.members = this.members.map((item) => {
            return {
              ...item,
              participationFormatId: (item.participationFormatId = format),
            };
          });

        if (this.fields.moderators)
          this.fields.moderators.setValues(
            true,
            this.fields.moderators.value.map((item) => {
              return {
                ...item,
                participationFormatId: (item.participationFormatId = format),
              };
            })
          );
        if (this.moderators)
          this.moderators = this.moderators.map((item) => {
            return {
              ...item,
              participationFormatId: (item.participationFormatId = format),
            };
          });

        if (this.fields.speakers)
          this.fields.speakers.setValues(
            true,
            this.fields.speakers.value.map((item) => {
              return {
                ...item,
                participationFormatId: (item.participationFormatId = format),
              };
            })
          );
        if (this.speakers)
          this.speakers = this.speakers.map((item) => {
            return {
              ...item,
              participationFormatId: (item.participationFormatId = format),
            };
          });
      }
    },
    saveForm(draft) {
      this.$emit("validateFields");
      if (
        +new Date(
          this.fields.endRegistrationDate.value.split(".").reverse().join("-")
        ) +
          new Date().getTimezoneOffset() * 60 * 1000 >
        +new Date(this.fields.date.value.split(".").reverse().join("-"))
      )
        return;
      const isPageValid = !this.fieldsToValidate.some((key) => {
        return !this.fields[key].isValid;
      });
      if (isPageValid) {
        if (draft) {
          this.draftSpinner = true;
        } else {
          this.submitSpinner = true;
        }
        const params = {};

        params.rtId = this.isNew ? null : this.rtId;
        params.lang = this.lang;
        params.draft = draft;
        params.eventId = this.eventId;
        params.applicantLastName =
          this.lang === "ru"
            ? this.user.data.public.LName
            : this.user.data.public.LNameEN;
        params.applicantFirstName =
          this.lang === "ru"
            ? this.user.data.public.FName
            : this.user.data.public.FNameEN;
        params.applicantSecondName =
          this.lang === "ru" ? this.user.data.public.SName : "";
        params.applicantEmail = this.user.login;

        Object.keys(this.fields).forEach((key) => {
          const { value } = this.fields[key];
          if (key === "date") {
            params[key] = this.dateFormat(value);
          } else if (this.fieldsToValidate.includes(key)) {
            params[key] = this.convertGuid(value);
          } else {
            if (
              this.statusPublicated ||
              this.statusPublication ||
              this.statusCheck
            ) {
              if (key === "endRegistrationDate") {
                params[key] = this.dateFormat(value);
              } else if (key === "maxParticipants") {
                params[key] = +value;
              } else if (this.fieldsToValidate2.includes(key)) {
                params[key] = this.convertGuid(value);
              } else if (this.radiosToValidate.includes(key)) {
                if (typeof value === "number") {
                  params[key] = !!value;
                } else {
                  params[key] = value;
                }
              }
            } else {
              params[key] = null;
            }
          }
        });
        if (
          this.fields.moderators.value.length === 0 ||
          this.fields.speakers.value.length === 0
        ) {
          this.isWarningModalOpen = true;
          this.submitSpinner = false;
          return;
        }
        if (this.statusSupported) {
          this.$emit("editCurrentTab");
          this.submitSpinner = false;
          return;
        }

        params.moderators = JSON.parse(
          JSON.stringify(this.fields.moderators.value)
        );

        params.members = JSON.parse(JSON.stringify(this.fields.members.value));

        params.speakers = JSON.parse(
          JSON.stringify(this.fields.speakers.value)
        );

        params.moderatorsDeleteIds = JSON.parse(
          JSON.stringify(this.fields.moderatorsDeleteIds.value)
        );

        params.membersDeleteIds = JSON.parse(
          JSON.stringify(this.fields.membersDeleteIds.value)
        );

        params.speakersDeleteIds = JSON.parse(
          JSON.stringify(this.fields.speakersDeleteIds.value)
        );
        params.specialEventOrganizationFormatId =
          this.fields.plannedSpecialEventOrganizationFormatId.value;
        console.log(
          "🚀 ~ file: FormPage1.vue:1096 ~ saveForm ~ params:",
          params
        );
        console.log(
          "🚀 ~ file: FormPage1.vue:1127 ~ saveForm ~ this.fields:",
          this.fields
        );
        params.secondPhase = false;
        api.request("add_rt_data", params).then((data) => {
          if (draft) {
            this.draftSpinner = "done";
          } else {
            this.submitSpinner = "done";
          }
          setTimeout(() => {
            if (draft) {
              this.draftSpinner = false;
            } else {
              this.submitSpinner = false;
            }
            if (data && data.length) {
              this.$emit("saved", draft);
            } else {
              this.$emit("savedErr");
            }
          }, 2000);
        });
      }
    },
    convertGuid(value) {
      return !value ? null : value;
    },
    getBackIfErr() {
      this.isSaved = false;
      this.isSaveErr = false;
    },
    openWebinarModal() {
      this.isWebinarModalOpen = true;
    },
    onWebinarSave() {
      this.isWebinarModalOpen = false;
    },
    openQRCodeModal(type, participantId) {
      this.qrCodeType = type;
      this.participantId = participantId;
      this.isQRCodeModalOpen = true;
    },
    onQRCodeSave() {
      this.isQRCodeModalOpen = false;
      this.participantId = null;
      this.qrCodeType = "";
      this.$emit("getRTData");
    },
    openModeratorModal() {
      this.isModeratorModalOpen = true;
      this.editableModerator = null;
    },
    editModerator(moderator, index) {
      this.editableModerator = { ...moderator, index };
      this.isModeratorModalOpen = true;
    },
    onModeratorSave(result) {
      if (!this.editableModerator) {
        this.moderators.push(result);
      } else {
        this.moderators[this.editableModerator.index] = result;
      }
      this.fields.moderators.setValues(true, this.moderators);
      this.editableModerator = null;
      this.isModeratorModalOpen = false;
    },
    removeModerator({ id }) {
      if (!this.isNew) {
        this.fields.moderatorsDeleteIds.setValues(true, [
          ...this.fields.moderatorsDeleteIds.value,
          this.moderators.find((moderator) => moderator.id === id).id,
        ]);
      }
      this.moderators = this.moderators.filter(
        (moderator) => moderator.id !== id
      );
      this.fields.moderators.setValues(true, this.moderators);
      this.editableModerator = null;
    },
    openSpeakerModal() {
      this.isSpeakerModalOpen = true;
      this.editableSpeaker = null;
    },
    editSpeaker(speaker, index) {
      this.editableSpeaker = { ...speaker, index };
      this.isSpeakerModalOpen = true;
    },
    onSpeakerSave(result) {
      if (!this.editableSpeaker) {
        this.speakers.push(result);
      } else {
        this.speakers[this.editableSpeaker.index] = result;
      }
      this.fields.speakers.setValues(true, this.speakers);
      this.editableSpeaker = null;
      this.isSpeakerModalOpen = false;
    },
    removeSpeaker({ id }) {
      console.log(id, "id speakers delete");
      if (!this.isNew) {
        this.fields.speakersDeleteIds.setValues(true, [
          ...this.fields.speakersDeleteIds.value,
          this.speakers.find((speaker) => speaker.id === id).id,
        ]);
        console.log(
          this.fields.speakersDeleteIds,
          "this.fields.speakersDeleteIds"
        );
      }
      this.speakers = this.speakers.filter((speaker) => speaker.id !== id);
      this.fields.speakers.setValues(true, this.speakers);
      this.editableSpeaker = null;
    },
    openMemberModal() {
      this.isMemberModalOpen = true;
      this.editableMember = null;
    },
    editMember(member, index) {
      this.editableMember = { ...member, index };
      this.isMemberModalOpen = true;
    },
    onMemberSave(result) {
      this.membersChanged = true;
      if (!this.editableMember) {
        this.members.push(result);
      } else {
        this.members[this.editableMember.index] = result;
      }
      this.fields.members.setValues(true, this.members);
      this.editableMember = null;
      this.isMemberModalOpen = false;
    },
    removeMember({ id }) {
      this.membersChanged = true;
      if (!this.isNew) {
        this.fields.membersDeleteIds.setValues(true, [
          ...this.fields.membersDeleteIds.value,
          this.members.find((member) => member.id === id).id,
        ]);
      }
      this.members = this.members.filter((member) => member.id !== id);
      this.fields.members.setValues(true, this.members);
      this.editableMember = null;
    },
    setPublicityId(val) {
      this.fields.publicityId.setValues(true, val);
    },
  },
  computed: {
    showWebinarLink() {
      return this.data.showWebinarLink && !this.membersChanged;
    },
    statusReview() {
      return (
        this.data.roundTableStatusId === "81A603E7-391C-460E-B413-FC153EA20C1A"
      );
    },
    statusSupported() {
      return (
        this.data.roundTableStatusId === "3BB3404D-032C-48C1-9397-6FCF1E4A6080"
      );
    },
    statusFirstModification() {
      return (
        this.data.roundTableStatusId === "31812363-221B-4B92-9B53-69003FF920C4"
      );
    },
    statusSecondModification() {
      return (
        this.data.roundTableStatusId === "598FA3AE-2FBB-4703-8B75-02735E758EEA"
      );
    },
    statusCheck() {
      return (
        this.data.roundTableStatusId === "EF7995A1-DA68-4A79-AE54-0BBFCA044F37"
      );
    },
    statusPublication() {
      return (
        this.data.roundTableStatusId === "28AE374D-D74C-4F4B-857C-E4E5BF0FF90A"
      );
    },
    statusPublicated() {
      return (
        this.data.roundTableStatusId === "C08814A5-7780-4595-9A31-AF51383E3933"
      );
    },
    isSendedQRForModerators() {
      return this.moderators.some((item) => item.pass === true);
    },
    isSendedQRForSpeakers() {
      return this.speakers.some((item) => item.pass === true);
    },
    isSendedQRForMembers() {
      return this.members.some((item) => item.pass === true);
    },
    isAllMemberOnline() {
      return this.members.every(
        (item) =>
          item.participationFormatId === "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A"
      );
    },
    isAllModeratorOnline() {
      return this.moderators.every(
        (item) =>
          item.participationFormatId === "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A"
      );
    },
    isAllSpeakersOnline() {
      return this.speakers.every(
        (item) =>
          item.participationFormatId === "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A"
      );
    },
    statusCanceled() {
      return (
        this.data.roundTableStatusId === "CC88FBA9-62A7-4A0F-BC3D-A133BB7207EF"
      );
    },
    statusDeclined() {
      return (
        this.data.roundTableStatusId === "472430DA-E08D-491B-862E-19B1EED635FD"
      );
    },
    statusDraft() {
      return (
        this.data.roundTableStatusId === "37241B9D-2AAA-4EB4-9B01-F310902E0E84"
      );
    },
    ...mapState(["lang"]),
  },
  watch: {
    data() {
      if (this.fieldsDisabled) {
        this.fieldsToValidate.forEach((key) => {
          if (
            this.fieldsDisabled === "partially" &&
            (key === "RTname" || key === "discussionQuestions")
          ) {
            if (this.fields[key].setDisabled) {
              this.fields[key].setDisabled(true);
            }
          } else if (
            this.fieldsDisabled === true ||
            this.fieldsDisabled === "membersOnly"
          ) {
            if (this.fields[key].setDisabled) {
              this.fields[key].setDisabled(true);
            }
          }
        });
      }
      if (this.data.moderators) {
        this.moderators = this.data.moderators;
      }
      if (this.data.speakers) {
        this.speakers = this.data.speakers;
      }
      if (this.data.members) {
        this.members = this.data.members;
      }
      if (this.data.speakers) {
        this.isSendedQRForSpeakers2 = this.isSendedQRForSpeakers();
        this.isAllSpeakersOnline2 = this.isAllSpeakersOnline();
      }
      if (this.data.moderators) {
        this.isSendedQRForModerators2 = this.isSendedQRForModerators();
        this.isAllModeratorOnline2 = this.isAllModeratorOnline();
      }
      if (this.data.members) {
        this.isSendedQRForMembers2 = this.isSendedQRForMembers();
        this.isAllMembersOnline2 = this.isAllMembersOnline();
      }
    },
  },
  i18n: {
    messages: {
      en: {
        moderatorBlock: "Moderators",
        speakerBlock: "Experts",
        memberBlock: "Participants",
        pass: "Pass",
        letter: "Letter",
        no: "No",
        yes: "Yes",
        addParticipant: "Add",
        QRCodeForMembers: "Send QR code to participants",
        QRCodeForMember: "Send QR code to participant",
        QRCodeForSpeakers: "Send qr code to experts",
        QRCodeForSpeaker: "Send qr code to expert",
        QRCodeForModerators: "Send qr code to moderators",
        QRCodeForModerator: "Send qr code to moderator",
        Approve: "Approve all",
        Webinar: "Send the link for a webinar",
        back: "Back",
        submit: "Send a request",
        draft: "Save a draft",
        okStatus: "Request sent",
        errStatus: "Error occured",
        chooseLang: "Choose a language",
        eventFormat: "Planned format of the event",
        approved: "Approved",
        rejected: "Rejected",
        sent: "Sent",
        save: "Save changes",
        personal: "Request Initiator",
        statusRoundTable: "Status of the round table",
        moderatorInfo:
          "The moderator details are captured in the system using their email address, whether it's their own or that of their accompanying person. This is essential for creating a personalized record for the moderator/speaker and for facilitating subsequent organizational tasks, such as registration and pass issuance. We will not use these emails for sending automated notifications. If you have any questions, please feel free to contact the Organizing Committee at YasinConference@hse.ru.",
        speakerInfo:
          "The speaker's details are captured in the system using their email address, whether it's their own or that of their accompanying person. This is essential for creating a personalized record for the moderator/speaker and for facilitating subsequent organizational tasks, such as registration and pass issuance. We will not use these emails for sending automated notifications. If you have any questions, please feel free to contact the Organizing Committee at YasinConference@hse.ru.",
        memberInfo:
          "Participant details are entered in line with the pre-approved application during the organizational preparation of the event.",
      },
      ru: {
        moderatorBlock: "Модераторы",
        speakerBlock: "Эксперты-спикеры",
        memberBlock: "Участники",
        pass: "Пропуск",
        letter: "Письмо",
        no: "Нет",
        yes: "Да",
        addParticipant: "Добавить",
        QRCodeForMembers: "Отправить qr-код участникам",
        QRCodeForMember: "Отправить qr-код участнику",
        QRCodeForSpeakers: "Отправить qr-код экспертам",
        QRCodeForSpeaker: "Отправить qr-код эксперту",
        QRCodeForModerators: "Отправить qr-код модераторам",
        QRCodeForModerator: "Отправить qr-код модератору",
        Approve: "Одобрить всех",
        Webinar: "Отправить ссылку на вебинар",
        back: "Назад",
        submit: "Подать заявку",
        draft: "Сохранить черновик",
        okStatus: "Заявка отправлена",
        errStatus: "Произошла ошибка",
        chooseLang: "Выберите язык",
        eventFormat: "Планируемый формат мероприятия",
        approved: "Одобрен",
        rejected: "Отклонен",
        sent: "Отправлен",
        save: "Сохранить изменения",
        personal: "Инициатор заявки",
        statusRoundTable: "Статус круглого стола",
        moderatorInfo:
          "Данные модератора фиксируются в системе через электронный адрес (свой или сопровождающего). Это необходимо для формирования индивидуальной записи модератора/спикера и для дальнейшей организационной работы, в т.ч. для регистрации и оформления пропуска. Автоматическая рассылка писем не предполагается. В случае вопросов просим обращаться в Организационный комитет конференции: YasinConference@hse.ru",
        speakerInfo:
          "Данные спикера фиксируются в системе через электронный адрес (свой или сопровождающего). Это необходимо для формирования индивидуальной записи модератора/спикера и для дальнейшей организационной работы, в т.ч. для регистрации и оформления пропуска. Автоматическая рассылка писем не предполагается. В случае вопросов просим обращаться в Организационный комитет конференции: YasinConference@hse.ru",
        memberInfo:
          "Информация об участниках заполняется по уже утверждённой заявке, на момент организационной подготовки мероприятия",
      },
    },
  },
};
</script>

<style scoped>
.cols {
  display: flex;
  margin: 0 -15px;
  flex-wrap: wrap;
}

.cols > .field {
  padding: 0 15px;
  flex: 1;
}

.two-cols .field {
  max-width: 50%;
  width: 50%;
  flex-basis: 50%;
}

.three-cols .field {
  max-width: 33.3%;
}

.ident {
  margin-left: 10px;
}

@media (max-width: 767px) {
  .two-cols .field,
  .three-cols .field {
    max-width: 100%;
    width: 100%;
    flex-basis: 100%;
  }
}

.actions {
  flex-wrap: wrap;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.accordion {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
}

.accordion__head {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.accordion__info {
  font-size: 14px;
  line-height: 1.6em;
  color: #fff;
  padding: 8px 24px;
}

.participant__heading {
  display: flex;
  align-items: center;
  gap: 5px;
}

.participant__heading .icons {
  display: flex;
  gap: 3px;
}

.participant__heading .icons > svg {
  cursor: pointer;
}
.subheading {
  font-family: "Work Sans", sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #0f0f14;
}
</style>
