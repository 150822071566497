<template>
  <div class="add-moderator">
    <div v-if="!isError">
      <HseHeading size="h3">{{ participantId ? $t('participantHeading') : $t('heading') }}</HseHeading>
      <HseSpace size='small' vertical />
      <div class="field">
        <HseCheckbox v-if="!participantId" v-model="checked" :label="$t('checkbox')" />
        <HseSpace size='small' vertical />
        <HseSpace size='small_2x' vertical />
      </div>

      <div class="form-footer">
        <div class="actions">
          <HseButton
            @click="cancel"
          >
            {{$t('cancel')}}
          </HseButton>
          <HseButton
            @click="send"
            :spinner="buttonSpinner"
            spinnerPosition="left"
          >
            <span>{{ $t('ok') }}</span>
          </HseButton>
        </div>
      </div>
    </div>
    <div class="modal" v-else-if="isError">
      <svg width="110" height="100" viewBox="0 0 110 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M26.8055 8.42652C35.0279 2.93245 44.6949 0 54.584 0C67.8448 0 80.5625 5.26784 89.9393 14.6447C99.3161 24.0215 104.584 36.7392 104.584 50C104.584 59.8891 101.652 69.556 96.1575 77.7785C90.6634 86.0009 82.8545 92.4096 73.7182 96.194C64.5819 99.9783 54.5285 100.969 44.8295 99.0392C35.1304 97.11 26.2213 92.3479 19.2287 85.3553C12.236 78.3627 7.474 69.4536 5.54474 59.7545C3.61548 50.0555 4.60565 40.0021 8.39003 30.8658C12.1744 21.7295 18.583 13.9206 26.8055 8.42652Z" fill="#FF564E"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M73.4702 35.2504L69.7498 31.53L55.0002 46.2796L40.2507 31.53L36.5303 35.2504L51.2799 50L36.5303 64.7496L40.2507 68.47L55.0002 53.7204L69.7498 68.47L73.4702 64.7496L58.7206 50L73.4702 35.2504Z" fill="white"/>
      </svg>
      <HseSpace size='small' vertical />
      <HseHeading size="h3">{{ $t('ErrorHeading') }}</HseHeading>
      <HseSpace size='small' vertical />
      <HseParagraph>{{ $t('ErrorText') }}</HseParagraph>
      <HseSpace size='small' vertical />
      <div class="actions">
          <HseButton
            @click="() => this.$emit('saved')"
          >
            {{$t('no')}}
          </HseButton>
          <HseButton
            @click="() => this.isError = false"
          >
            <span>{{ $t('yes') }}</span>
          </HseButton>
        </div>
    </div>
  </div>
</template>

<script>
  import { HseSpace, HseButton, HseCheckbox, HseHeading, HseParagraph } from '@hse-design/vue'
  import { mapState } from 'vuex'
  import {api} from "../../api";

  export default {
    components: {
    HseSpace,
    HseButton,
    HseCheckbox,
    HseHeading,
    HseParagraph,
},
    props: ['rtId', 'type', 'fields', 'participantId'],
    data: () => ({
      buttonSpinner: false,
      checked: false,
      isError: false,
    }),
    computed: {
      ...mapState(['lang'])
    },
    methods: {
      send() {
        let role = ''
        if (this.type === 'moderator') {
          role = 'Модератор'
        }
        if (this.type === 'speaker') {
          role = 'Спикер'
        }
        if (this.type === 'member') {
          role = 'Участник'
        }

        const params = {
          rtEventId: this.rtId,
          role: role,
          sendEmail: this.checked ? 1 : 0
        }
        if (this.participantId) {
          params.participantId = this.participantId
          params.sendEmail = 1
        }
        this.buttonSpinner = true
        api.request('send_qr_code', params).then(data => {
          this.buttonSpinner = 'done'
          setTimeout(() => {
            console.log(data);
            if (data === true) {
              this.buttonSpinner = false
              this.$emit('saved')
            }
            else {
              this.buttonSpinner = false
              this.isError = true
            }
          }, 2000)
        })

        //const auth = {
        //  user: 'user_AMNK',
        //  pass: 'ps48dRQ!'
        //}

        //const urlArray = arr.map(item => {
        //  const id = item.id ?? ''
        //  return `https://smartevent-test.hse.ru/smartevent_test/hs/amnk/CreatePasses?specialeventid=${this.rtId}&role=${role}&participantid=${id}${this.checked ? '&sendEmail=1' : ''}`
        //})

        //this.buttonSpinner = true
        
        //Promise.all(urlArray.map(url => {
        //  fetch(url, {
        //    headers: {
        //      'Authorization': `Basic ${btoa(`${auth.user}:${auth.pass}`)}`,
        //    }
        //  }).then(res => {
        //    if (res) {
        //      this.buttonSpinner = 'done'
        //      this.$emit('saved')
        //    } else {
        //      this.buttonSpinner = false
        //      console.log('Error occured')
        //    }
        //  })
        //}))
      },
      cancel() {
        this.$emit('saved')
      },
    },
    i18n: {
      messages: {
        en: {
          ok: 'Ok',
          cancel: 'Cancel',
          no: 'No',
          yes: 'Yes',
          heading: 'Create QR-codes',
          participantHeading: 'Send QR-code',
          ErrorHeading: 'There was an error in the generation of the QR code.',
          ErrorText: 'Please try again or contact the Conference Organizing Committee at: YasinConference@hse.ru',
          checkbox: 'Send QR-code',
        },
        ru: {
          ok: 'Ок',
          cancel: 'Отмена',
          no: 'Нет',
          yes: 'Да',
          heading: 'Создать QR-коды',
          participantHeading: 'Отправить QR-код',
          ErrorHeading: 'При создании QR-кода возникла ошибка.',
          ErrorText: 'Повторите попытку снова или сообщите об ошибке в Организационный комитет конференции: YasinConference@hse.ru',
          checkbox: 'Отправить QR-код',
        },
      }
    }
  }
</script>

<style scoped>

  .modal {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .add-moderator .form {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px;
  }
  .form.down {
    align-items: flex-end;
  }
  .form .field {
    padding: 0 16px;
    min-width: 0;
    width: 50%;
    flex-basis: 50%;
  }
  .actions {
    display: flex;
    gap: 20px;
  }
  .actions .hse-Button {
    display: inline-block;
  }
  .checkboxes {
    display: flex;
    justify-content: space-between;
  }
  .form-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .form-description {
    padding-top: 12px;
    font-size: 14px;
    line-height: 20px;
    color: #6B7A99;
  }
  .flex-tooltip {
    display: flex;
  }
  .flex-tooltip>div:first-child {
    width: calc(100% - 28px)
  }
  .flex-tooltip>div:last-child {
    padding: 36px 0 0 12px;
  }
  @media (max-width: 1024px) {
    .register {
      margin: 16px auto;
      max-width: 95%;
    }
    .emptyfield {
      display: none;
    }
    .checkboxes {
      flex-direction: column;
    }
  }
  @media (max-width: 768px) {
    .form .field {
      width: 100%;
      flex-basis: 100%;
    }
    .form-footer {
      flex-direction: column;
      align-items: flex-start;
    }
  }
</style>
<style>
  .smallLabel {
    font-size: 14px !important;
  }
  @media (max-width: 768px) {
    .hse-Input_disabled input:disabled {
      opacity: 1 !important;
      color: #000 !important;
    }
  }
</style>