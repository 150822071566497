<template>
    <div class="field" :class="{ displaynone: isHidden }">
        <div class="hse-RichInput__label">{{label}}</div>
        <HseTooltip :content="tooltipText">
            <div class="hse-Input hse-Input_size_large hse-Input_fullWidth">
                <span>{{val}}</span>
            </div>
        </HseTooltip>
        <HseSpace size='small_2x' vertical />
    </div>
</template>

<script>
    import { HseSpace, HseTooltip } from '@hse-design/vue'
    export default {
        props: ['label', 'value', 'hideifempty', 'tooltipText'],
        components: {
            HseSpace,
            HseTooltip
        },
        computed: {
            val () {
                return (this.value !== null || this.value !== undefined) ? this.value : ''
            },
            isHidden () {
                return this.hideifempty !== undefined && !this.val
            },
        },
    }
</script>

<style scoped>
    .field {
        min-width: 0;
    }
    .field .hse-Input {
        padding-top: 14px;
        padding-bottom: 14px;
        height: auto;
        min-height: 48px;
    }
    .displaynone {
        display: none;
    }
    /* .field .hse-Input span {
      white-space: nowrap;
    } */
</style>
