<template>
  <div class="wrapper">
    <TopNewsBanner />
    <div class="table-card">
      <HseHeading size="h3">{{ $t('header') }}</HseHeading>
      <HseSpace size='small_2x' vertical />
      <div v-if="items.length" class="filters">
        <div class="field">
          <Validator :field="fields.search" @change="setFilter" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <Validator :field="fields.StatusAllSelect" @change="setFilter" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div v-if="isStatusScore" class="field">
          <Validator :field="fields.ScoreSelectMedium" @change="setFilter" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <Validator :field="fields.event" @change="setFilter" />
          <HseSpace size='small_2x' vertical />
        </div>
      </div>


      <div v-if="filtered.length">
        <RequestManagerItem v-for="value in filtered" :key="value.Id" :value="value"
          @changeFinalScore="onChangeFinalScore" />
      </div>
      <div v-else class="noData">
        {{ $t('noData') }}
      </div>

    </div>
  </div>
</template>

<script>
import { api } from '../api'
import { HseHeading, HseSpace } from '@hse-design/vue'
import { mapState } from 'vuex'
import Validator from '../components/common/form/Validator.vue'
import RequestManagerItem from '../components/requestsPage/RequestManagerItem'
import fields from '../components/requestsPage/managerFields'
import { STATUS_ID_RECIEVED, STATUS_ID_SCORE } from '../components/requestsPage/constants'
import { localizeFields } from '../i18n'
import TopNewsBanner from '../components/TopNewsBanner/TopNewsBanner.vue'

export default {
  components: {
    HseHeading,
    HseSpace,
    RequestManagerItem,
    Validator,
    TopNewsBanner,
  },
  data: () => ({
    filtered: [],
    reqParams: { archive: false, offset: 0, limit: 100 },
    items: [],
    fields,
  }),
  created() {
    this.translateFields()
    this.requestItems()
  },
  methods: {
    translateFields() {
      this.fields = localizeFields(fields, this.fields)
    },
    clearFinalScore() {
      if (this.fields.ScoreSelectMedium)
        this.fields.ScoreSelectMedium.value = ''
    },
    setFilter() {
      this.filtered = this.items.filter(
        item => {
          let isMatchFilter = true
          let isMatchEvent = true
          if (isMatchEvent && this.fields.event.value) {
            isMatchEvent = this.fields.event.value === item.EventId
          }
          if (isMatchEvent && this.fields?.StatusAllSelect.value) {
            isMatchEvent = this.fields.StatusAllSelect.value === item.StatusId
          }
          if (isMatchEvent && this.fields?.ScoreSelectMedium.value) {
            if (!this.isStatusScore)
              this.clearFinalScore()
            else
              isMatchEvent = this.fields.ScoreSelectMedium.value === item.FinalScoreId
          }
          if (isMatchEvent && this.fields.search.value) {
            const search = this.fields.search.value.toLowerCase()
            isMatchFilter = ['Fio', 'ReportTitle', 'numberRequest'].some(key => {
              return item[key] ? item[key].toLowerCase().includes(search) : false
            })
          }
          return isMatchFilter && isMatchEvent
        }
      );
    },
    requestItems() {
      this.reqParams.lang = this.lang;
      api.request('requestsListForManager', this.reqParams, []).then((data) => {
        this.items = data.reverse()
        this.items.map(item => {
          if (item.StatusId === STATUS_ID_RECIEVED) {
            item.Status = this.lang === 'ru' ? 'Получена' : 'Recieved'
          }
        })
        this.fields.search.setValues(true, '')
        this.fields.event.value = ''
        this.fields.StatusAllSelect.value = ''
        this.fields.ScoreSelectMedium.value = ''
        this.setFilter()
      })
    },
    changeType(type) {
      this.reqParams.archive = type === 'archive'
      this.requestItems()
    },
    formatPaymentStatus(status) {
      switch (this.lang) {
        case 'ru':
          switch (status) {
            case -1:
              return 'Не оплачена'
            case 1:
              return 'Оплачена'
            case 0:
              return 'В процессе оплаты'
            default:
              return ''
          }
        default:
          switch (status) {
            case -1:
              return 'Not payed'
            case 1:
              return 'Payed'
            case 0:
              return 'In payment process'
            default:
              return ''
          }
      }
    },
    filterName() {
      switch (this.lang) {
        case 'ru':
          return 'Фильтр'
        default:
          return 'Filter'
      }
    },
    onChangeFinalScore({ id, scoreId }) {
      console.log(id, scoreId)
      if (!id || !scoreId) return;

      api.request('requestFinalScoreUpdate', { scoreId, id })
      this.requestItems()
    },
  },
  computed: {
    isStatusScore() {
      return this.fields.StatusAllSelect.value === STATUS_ID_SCORE
    },
    ...mapState(['lang'])
  },
  watch: {
    lang() {
      this.requestItems()
    }
  },
  i18n: {
    messages: {
      en: {
        header: 'Requests',
        number: 'Number',
        noData: 'No data',
        filter: 'Filter',
        search: 'Search',
      },
      ru: {
        header: 'Заявки',
        number: 'Номер',
        noData: 'Нет данных',
        filter: 'Фильтр',
        search: 'Поиск',
      },
    }
  }
}
</script>

<style scoped>
.wrapper {
  max-width: 1500px;
  width: calc(100vw - 192px);
  margin: 24px auto;
}

.table-card {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 32px 32px;
  max-width: 95%;
}

.table-card .form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 -16px;
}

.table-card .form .field {
  padding: 0 16px;
  min-width: 0;
}

@media (max-width: 1024px) {
  .wrapper {
    width: calc(100vw - 48px)
  }

  .table-card {
    margin: 16px auto;
    max-width: 95%;
  }

  .table-card .form {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .wrapper {
    width: calc(100vw - 48px)
  }

  .table-card {
    margin: 0 auto;
    max-width: 100%;
  }

  .table-card .form {
    grid-template-columns: repeat(1, 1fr);
  }
}

.filters {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px 16px;
}

@media (max-width: 1024px) {
  .filters {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .filters {
    grid-template-columns: 1fr;
  }
}
</style>
