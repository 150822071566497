<template>
  <div class="notifications" :class="{ active: isActive }" v-on:click="click">
    <div v-show="hasNewMessages" class="indicator">!</div>
    <HseIconButton
      size="medium"
      variant="ghost"
      :icon="icon"
    />
  </div>
</template>

<script>
  import { api } from '../../../api'
  import {
    HseIconButton,
    IconGlobalMessage,
  } from '@hse-design/vue'

  export default {
    name: 'NotificationButton',
    props: ['isActive'],
    data: () => ({
      hasNewMessages: false,
      timeoutRequest: null,
    }),
    computed: {
      icon() {
        return IconGlobalMessage
      },
    },
    components: {
      HseIconButton,
    },
    created () {
      setTimeout(() => {
        this.checkNewMessages()
      }, 500)
    },
    methods: {
      click() {
        this.$router.push('/messages')
      },
      checkNewMessages () {
        if (this.timeoutRequest)
          clearTimeout(this.timeoutRequest)

        api.request('userMessagesHaveUnread', null, false).then(res => {
          if (res.length)
            this.hasNewMessages = res[0].haveNewMessages
          else
            this.hasNewMessages = false
        })
        this.timeoutRequest = setTimeout(() => {
          this.checkNewMessages()
        }, 3 * 60 * 1000) // 3 minutes
      },
    },
    watch: {
      $route () {
        setTimeout(() => {
          this.checkNewMessages()
        }, 500)
      }
    }
  }
</script>

<style scoped>
  .notifications {
    position: relative;
  }
  .container {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    min-width: 100%;
    padding-right: 8px;
    padding-left: 8px;
    cursor: pointer;
  }
  .indicator {
    position: absolute;
    top: 6px;
    right: 4px;
    color: #FF564E;
    font-size: 22px;
    z-index: 1;
  }
</style>

<style>
  .notifications svg path {
    fill: #6b7a99;
  }
  .notifications.active svg path {
    fill: #0050cf;
  }
</style>
