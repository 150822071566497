import { api } from '../../api'
import { store } from '../../store'

class ResourceLoader {
  constructor () {
    this.clear()
  }
  load (resource, params, errVal, search, excludeValsArr = []) {
    // из этой функции всегда возвращаем промис
    // проверяем добавлен ли ресурс
    const resName = `${resource}${store.state.lang}${params ? JSON.stringify(params) : ''}`
    if (!this.added.includes(resName)) {
      // не добавлен, добавляем, начинаем загрузку
      this.added.push(resName)
      // определяем в хранилище текущий ресурс как промис запроса
      this.resources[resName] = api.request(resource, params, errVal).then(data => {
        // когда загрузится, нормализуем данные
        this.loaded[resource] = data
        const normalized = Promise.resolve(
          this.normalizeData(resource, data)
        )
        // определяем ключ объекта ресурсов как промис, возвращающий данные
        this.resources[resName] = normalized
        return normalized
      })
      // возвращаем промис
      return this.resources[resName].then(data => 
        this.search(data, search).filter(x => !excludeValsArr.includes(x.value))
      )
    }
    return this.resources[resName].then(data =>
      this.search(data, search).filter(x => !excludeValsArr.includes(x.value))
    )
  }
  normalizeData (resource, data) {
    switch (resource) {
      case 'countriesList':
      case 'regionsList':
        return this.normalizeNoSort(data, 'Name', 'id')
      case 'languagesList':
      case 'studyYearList':
        return this.normalizeFields(data, 'Name', 'id', 'Name')
      case 'expertTotalResultOptionsList':
      case 'expertTotalResultOptionsListShort':
        return this.normalizeFields(data, 'TotalResultTitle', 'Id', 'SortOrder')
      case 'degreeList':
        return this.normalizeFields(data, 'Degree', 'id')
      case 'genderList':
        return this.normalizeFields(data, 'gender', 'id')
      case 'requestsList':
        return this.createRequestsValues(data)
      case 'requestsListAvailableForVisaRequest':
        return this.createRequestsValues(data)
      case 'eventListForUser':
        return this.normalizeFields(data, 'Title', 'Id', 'Title')
      case 'manager.eventList':
        return this.normalizeFields(data, 'Title', 'id', 'Title')
      case 'belongsToHSETypesList':
        return this.normalizeFields(data, 'Title', 'id')
      case 'occupationTypeList':
      case 'positionTypeList':
      case 'organisationTypeList':
        return this.normalizeFields(data, 'Type', 'id')
      case 'campusList':
      case 'facultyList':
        return this.normalizeFields(data, 'Campus', 'id', 'Campus')
      case 'degreeLevelList':
        return this.normalizeFields(data, 'DegreeLevel', 'id')
      case 'studyModeList':
        return this.normalizeFields(data, 'StudyMode', 'id')
      case 'manager.expertList':
      case 'manager.expertListAvailable':  
        return this.normalizeUsers(data)
      case 'statusesList':
      case 'statusesListManager':
        return this.normalizeFields(data, 'StatusName', 'Id')
      case 'event.topic.list':
      case 'manager.eventTopics':
        return this.normalizeFields(data, 'Title', 'Id')
      case 'eventTopicsList':
        return this.normalizeFields(data, 'Title', 'Id', 'Title')
      case 'languagesListLang':
        return this.normalizeFields(data, 'Name', 'shortName', 'Name')
      case 'occupationFormsList':
      case 'jobDirectionsList':
        return this.normalizeFields(data, 'label', 'value')
      case 'departmentsList':
        return this.normalizeFields(data, 'Name', 'id')
      case 'expertTotalResultOptionsListFilter':
        return this.normalizeFields(data, 'TotalResultTitle', 'Id')
      case 'get_speaker_statuses_list':
        return this.normalizeFields(data, 'Title', 'Id',)
      case 'get_event_program_session_type':
        return this.normalizeFields(data, 'Title', 'Id', 'OrderBy')
      default:
        return data
    }
  }
  normalizeFields (data, label, value, sortBy) {
    return this.sortByField(data, sortBy).map(el => ({ label: el[label], value: String(el[value]) }) )
  }
  normalizeUsers (data) {
    return this.sortByField(data, 'id').map(el => ({ 
      label: el['data']['public']['LName'] + ' ' + 
        el['data']['public']['FName'] + ' ' + 
        el['data']['public']['SName'], 
      value: String(el['id']) }) )
  }
  search (arr, search) {
    return search ? arr.filter(el => new RegExp(`${search}`, 'i').test(el.label) ) : arr
  }
  sortByField (arr, field) {
    return field ? arr.sort((a, b) => {
      if (typeof(a[field]) === 'string' && typeof(b[field] === 'string')) {
        if (a[field].toLocaleLowerCase() > b[field].toLocaleLowerCase()) return 1
        if (a[field].toLocaleLowerCase() < b[field].toLocaleLowerCase()) return -1
        return 0
      }

      if (a[field] > b[field]) return 1
      if (a[field] < b[field]) return -1
      return 0
    }) : arr
  }
  normalizeNoSort (data, label, value,) {
    return data.map(el => ({ label: el[label], value: String(el[value]) }) )
  }
  createRequestsValues (data) {
    return this.sortByField(
      data.map(item => ({
        label: `#${item.numberRequest} - ${item.Title} / ${item.Fio}`,
        value: item.Id
      })),
      'name'
    )
  }
  clear () {
    this.resources = {}
    this.loaded = {}
    this.added = []
  }
}

export const resourceLoader = new ResourceLoader()
