<template>
    <div>
        <HseModalInfo :modal-class="'submit-modal'" v-model="isOpen" :modal-style="{ maxWidth: '350px' }" ref="modal">
            <div v-html="text"></div>
        </HseModalInfo>
    </div>
</template>

<script>
    import { HseModalInfo } from '@hse-design/vue'

    export default {
        components: {
            HseModalInfo,
        },
        props: ['text'],
        data: () => ({
            isOpen: false,
        }),
        created () {
            this.$root.$refs.submitModalHtml = this
        },
        methods: {
            open() {
                this.isOpen = true
            }
        },
    }
</script>

<style>
    .submit-modal .hse-BaseModal__wrapper {
        min-height: 120px;
        text-align: center;
        padding: 47px;
    }
</style>