<template>
  <div class="calendar" :class="{ range: isRange, picker: isDatepicker }">
    <div class="calendar-head">
      <div class="arrow" @click="() => switchMonth('prev')">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.7049 11.41L18.2949 10L12.2949 16L18.2949 22L19.7049 20.59L15.1249 16L19.7049 11.41Z" fill="#0F0F14"/>
        </svg>
      </div>
      <div class="calendar-month">
        <Select :value="monthDate.month" :values="months" @change="setMonth" />
        <Select :value="monthDate.year" :values="years" @change="setYear" />
      </div>
      <div class="arrow" @click="() => switchMonth('next')">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.7049 10L12.2949 11.41L16.8749 16L12.2949 20.59L13.7049 22L19.7049 16L13.7049 10Z" fill="#0F0F14"/>
        </svg>
      </div>
    </div>
    <div class="dates" :class="{ range: isRange }">
      <div v-for="weekday in weekdays" :key="weekday" class="dates-column weekday">{{ $t(`wd.${weekday}`) }}</div>
      <div
        v-for="day in days" :key="day.ts"
        @click="() => chooseDay(day)"
        :style="`grid-column: ${day.weekday};`"
        :class="`dates-column date ${getRangeClasses(day)}`"
      >
        <div class="date-num">{{day.toFormat('d')}}</div>
      </div>
    </div>
  </div>
</template>
<script>
  import Select from './Select.vue' 
  import { DateTime } from 'luxon'
  export default {
    components: { Select },
    name: 'Calendar',
    data: () => ({
      range: [],
      chosen: null,
      weekdays: ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'],
      today: DateTime.local(),
      calendarMonth: null
    }),
    props: {
      isRange: { type: Boolean, default: false },
      isDatepicker: { type: Boolean, default: false },
      val: { default: '' },
    },
    created () {
      this.calendarMonth = this.importedVal ? this.importedVal : this.today
    },
    methods: {
      checkIsToday (day) {
        return this.today.hasSame(day, 'day')
      },
      checkIsChosen (day) {
        if (!this.isDatepicker || !this.chosen) return
        return this.chosen.hasSame(day, 'day')
      },
      getRangeClasses (day) {
        let classNames = []
        if (this.checkIsToday(day)) classNames.push('today')
        if (this.checkIsChosen(day)) classNames.push('chosen')
        if (!this.isRange) return classNames.join(' ')

        const [from, to] = this.range

        const isStart = from && from.hasSame(day, 'day')
        const isEnd = to && to.hasSame(day, 'day')

        if (isStart) classNames.push('checked', 'start') 
        else if (isEnd) classNames.push('checked', 'end')

        if (day >= from && day <= to) {
          classNames.push('inrange')

          if (day.weekday === 1) classNames.push('monday')
          else if (day.weekday === 7) classNames.push('sunday')
        }

        return classNames.join(' ')
      },
      setMonth ({ value }) {
        this.calendarMonth = this.calendarMonth.plus({ month: value - this.calendarMonth.month })
      },
      setYear ({ value }) {
        this.calendarMonth = this.calendarMonth.plus({ year: value - this.calendarMonth.year })
      },
      switchMonth (type) {
        switch (type) {
          case 'next':
            this.calendarMonth = this.calendarMonth.plus({ month: 1 })
            break
          case 'prev':
            this.calendarMonth = this.calendarMonth.plus({ month: -1 })
            break
        }
      },
      getDate (day) {
        this.chosen = day
        this.$emit('datePicked', day)
      },
      setRange (day) {
        if (this.range.length === 2) this.range = [day]
        else if (this.range.length < 2) {
          this.range.push(day)
        }

        if (this.range.length === 2) {
          this.range = this.range.sort((a, b) => a.ts - b.ts)
          this.$emit('rangeChosen', this.range)
        } else if (this.range.length === 1) {
          this.$emit('rangeChosen', [day, day])
        }

        this.calendarMonth = this.range[this.range.length - 1]
      },
      chooseDay (day) {
        if (this.isDatepicker) this.getDate(day)
        else if (this.isRange) this.setRange(day)
      },
    },
    watch: {
      importedVal () {
        if (this.importedVal) {
          this.chosen = this.importedVal
          this.calendarMonth = this.importedVal
        }
      }
    },
    computed: {
      importedVal () {
        return this.val
      },
      years () {
        return [...Array(this.today.get('year') - 1910)].map((_, i) => {
          return { label: i + 1920 + 1, value: i + 1920 + 1 }
        }).reverse()
      },
      months () {
        return [...Array(12)].map((_, i) => {
          return { label: this.$i18n.tc(`m.m${i + 1}`), value: i + 1 }
        })
      },
      monthDate () { 
        return this.calendarMonth.startOf('month')
      },
      days () {
        return [...Array(this.monthDate.daysInMonth)].map((_, i) => {
          return this.monthDate.plus({ day: i })
        })
      },
    },
    i18n: {
      messages: {
        en: {
          wd: {
            mo: 'Mo',
            tu: 'Tu',
            we: 'We',
            th: 'Th',
            fr: 'Fr',
            sa: 'Sa',
            su: 'Su',
          },
          m: {
            m1: 'January',
            m2: 'February',
            m3: 'March',
            m4: 'April',
            m5: 'May',
            m6: 'June',
            m7: 'July',
            m8: 'August',
            m9: 'September',
            m10: 'October',
            m11: 'November',
            m12: 'December',
          },
        },
        ru: {
          wd: {
            mo: 'Пн',
            tu: 'Вт',
            we: 'Ср',
            th: 'Чт',
            fr: 'Пт',
            sa: 'Сб',
            su: 'Вс',
          },
          m: {
            m1: 'Январь',
            m2: 'Февраль',
            m3: 'Март',
            m4: 'Апрель',
            m5: 'Май',
            m6: 'Июнь',
            m7: 'Июль',
            m8: 'Август',
            m9: 'Сентябрь',
            m10: 'Октябрь',
            m11: 'Ноябрь',
            m12: 'Декабрь',
          },
        },
      }
    }
  }
</script>

<style scoped>
  .calendar {
    margin-bottom: 30px;
    user-select: none;
    -webkit-user-select: none;
  }
  .calendar-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 7px 0 0;
    margin: 0 0 15px;
  }
  .calendar-month {
    font-family: 'Proxima Nova', sans-serif;
    color: #6b7a99;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 0 10px 0 7px;
    display: flex;
    justify-content: space-around;
    flex: 1;
  }
  .controls {
    display: flex;
    width: 41px;
    justify-content: space-between;
    align-items: center;
  }
  .arrow {
    cursor: pointer;
  }
  .dates {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }
  .dates.range {
    grid-gap: 9px 0;
  }
  .dates-column {
    width: 36px;
    height: 36px;   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 17px;
    cursor: default;
  }
  .weekday {
    width: 32px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 8px;
    font-weight: 500;
    color: #6b7a99;
    font-family: 'Proxima Nova', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  .date {
    font-family: HSE Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    border-radius: 4px;
    color: #0F0F14;
  }
  .date.today {
    color: #0060CF;
    border: 1px solid #0060CF;
  }
  .date.chosen {
    color: #0060CF;
  }
  .date.inrange {
    background: #21272E;
    border-radius: 0;
  }
  .date.checked {
    position: relative;
    color: #6b7a99;
  }
  .date.checked.start, .date.inrange.monday {
    border-radius: 10px 0  0 10px;
  }
  .date.checked.end, .date.inrange.sunday {
    border-radius: 0 10px 10px 0;
  }
  .date.checked>div {
    position: relative;
  }
  .date.checked::before {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #4B576A;
    border-radius: 10px;
  }
  .calendar.range .date,
  .calendar.picker .date {
    cursor: pointer;
  }
  .calendar.picker {
    background: #EEEFF4;
    border: 1px solid #EEEFF4;
    box-sizing: border-box;
    box-shadow: 0px 1px 12px rgba(106, 122, 152, 0.12);
    border-radius: 4px;
    padding: 16px;
  } 
</style>

<style>
  .calendar-month {
    margin: 0 -3px;
  }
  .calendar-month .select {
    padding: 0 3px;
  }
  .calendar-month .select .dropdown {
    background: #fff;
    box-shadow: 0px 10px 10px #6b7a9936;
    max-height: 200px;
    overflow-y: auto;
  }
  .calendar-month .select .item {
    white-space: nowrap;
    padding: 5px 10px;
  }
</style>