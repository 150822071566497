<template>
  <div class="card">
    <HseHeading size="h3">{{ eventName }}</HseHeading>
    <HseSpace size='small' vertical />
    <ReadonlyInput :label="$t('number')" :value="'П' + data.requestNumber" />
    <ReadonlyInput :label="$t('event')" :value="horName" />
    <div class="two-cols">
      <ReadonlyInput :label="$t('requestStatus')" :value="data.roundTableStatus" />
    </div>
    <div class="actions">
      <div v-if="catShow">
        <a class="link" :href="`/#/my-events-hor/${data.id}`">{{ $t('open') }}</a>
      </div>
      <div v-if="canEdit">
        <a class="link" :href="`/#/honorary_or_invited_report/create?event=${data.EventId}&rtId=${data.id}&step=1`">{{
          $t('edit') }}</a>
      </div>
      <div v-if="canCotinue">
        <a class="link" :href="`/#/honorary_or_invited_report/create?event=${data.EventId}&rtId=${data.id}&step=2`">{{
          $t('continue') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import ReadonlyInput from '../common/ReadonlyInput.vue'
import { HseHeading, HseSpace } from '@hse-design/vue'
import { mapState } from 'vuex'
export default {
  name: 'eventCardHor',
  components: {
    ReadonlyInput,
    HseHeading,
    HseSpace,
  },
  data: () => ({
    eventName: '',
    horName: '',
  }),
  props: ['data'],
  created() {
    this.getEventName()
  },
  methods: {
    getEventName() {
      this.eventName = this.lang === 'ru' ? this.data.TitleRU : this.data.TitleEN
      this.horName = this.lang === 'ru' ? this.data.EventTitle : this.data.EventTitleEN
    },
  },
  watch: {
    lang() {
      this.getEventName()
    },
  },
  computed: {
    canEdit() {
      return (this.data.StatusId === '37241B9D-2AAA-4EB4-9B01-F310902E0E84') || this.data.StatusId === '31812363-221B-4B92-9B53-69003FF920C4'
    },
    canCotinue() {
      return (this.data.StatusId === '598FA3AE-2FBB-4703-8B75-02735E758EEA' || this.data.StatusId === '3BB3404D-032C-48C1-9397-6FCF1E4A6080')
    },
    catShow() {
      return (this.data.StatusId === 'EF7995A1-DA68-4A79-AE54-0BBFCA044F37' || this.data.StatusId === '81A603E7-391C-460E-B413-FC153EA20C1A')
    },
    ...mapState(['lang']),
  },
  i18n: {
    messages: {
      en: {
        payment: 'Payment',
        annotation: 'Annotation',
        annotationEn: 'Annotation (en)',
        event: 'Event',
        number: 'Request number',
        format: 'Form of participation',
        requestStatus: 'Request status',
        paymentStatus: 'Payment status',
        open: 'View request',
        edit: 'Edit',
        continue: 'Continue filling in application',
      },
      ru: {
        payment: 'Оплата',
        annotation: 'Аннотация',
        annotationEn: 'Аннотация (англ.)',
        event: 'Мероприятие',
        number: 'Номер заявки',
        format: 'Формат участия',
        requestStatus: 'Статус заявки',
        paymentStatus: 'Статус оплаты',
        open: 'Просмотр',
        edit: 'Редактировать',
        continue: 'Продолжить заполнение заявки',
      },
    }
  }
}
</script>

<style scoped>
.two-cols {
  display: flex;
  margin: 0 -15px;
}

.two-cols>div {
  padding: 0 15px;
  flex: 1;
}

.link {
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.actions>* {
  margin: 0 15px;
}

.actions .link {
  padding: 12px 0;
}

@media (max-width: 767px) {

  .two-cols,
  .actions {
    flex-direction: column;
  }

  .two-cols>div {
    width: 100%;
  }

  .actions {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
</style>
<style>
.hse-TooltipTarget {
  width: 100%;
}
</style>
