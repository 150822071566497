<template>
  <div class="visa-card">
    <Loading v-if="!isDataLoaded" />
    <div v-else>
      <div class="header">
        <div class="subject">
          <HseIcon v-if="message.IncomingForUser == 1" :icon="IconActionArrowBottom" />
          <HseIcon v-else :icon="IconActionArrowTop" />
          <span>{{ message.MessageSubject }}</span>
        </div>
        <a class="attacn link" :href="`/files/download_file?id=${message.MessageAttachment}`"
          v-if="message.MessageAttachment">
          <HseIcon size="medium" :icon="IconActionAttach" />
          {{ $t('attachment') }}
        </a>
      </div>
      <HseSpace size='small_2x' vertical />

      <div class="created_date">{{ dateFormat(message.CreatedOn) }}</div>

      <HseDivider />
      <HseSpace size='small_2x' vertical />

      <div class="body">{{ message.MessageBody }}</div>

      <HseSpace size='medium' vertical />

      <div class="form-footer">
        <div class="actions">
          <HseButton v-if="message.IncomingForUser" v-on:click="editMessage(message_id)">
            {{ $t('reply') }}
          </HseButton>
          <HseButton variant="secondary" v-on:click="$router.go(-1)">
            {{ $t('back') }}
          </HseButton>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {
  HseSpace,
  HseButton,
  HseIcon,
  HseDivider,
  IconGlobalEmail,
  IconActionArrowBottom,
  IconActionArrowTop,
  IconActionAttach,
} from '@hse-design/vue'
import { api } from '../../api'
import { dateFormatter } from '../common/dateFormatter'
import { mapState } from 'vuex'
import Loading from '../common/Loading.vue'

export default {
  components: {
    HseSpace,
    HseButton,
    HseIcon,
    HseDivider,
    Loading,
  },
  data: () => ({
    message_id: null,
    message: null,
    isDataLoaded: false,
    IconActionArrowBottom,
    IconActionArrowTop,
    IconGlobalEmail,
    IconActionAttach,
  }),
  created() {
    this.message_id = this.$route.params.id;
    this.load();
  },
  computed: {
    ...mapState(['lang'])
  },
  methods: {
    load() {
      api.request('message.get', this.message_id).then((res) => {
        this.message = res;
        this.isDataLoaded = true;
      })
      api.request('userMessageOpened', { id: this.message_id }).then(() => { })
    },
    editMessage(message_id) {
      this.$router.push(`/messages/${message_id}/new`);
    },
    dateFormat(date) { return dateFormatter.format(date) },
  },
  i18n: {
    messages: {
      en: {
        attachment: 'Attachment',
        reply: 'Reply',
      },
      ru: {
        attachment: 'Вложение',
        reply: 'Ответить',
      },
    }
  }
}
</script>

<style scoped>
.visa-card {
  background: #FFFFFF;
  border-radius: 12px;
  margin: 53px auto;
  padding: 32px 32px;
  max-width: calc(100vw - 320px)
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subject {
  font-size: 20px;
  font-weight: 200;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.subject span {
  margin-left: 10px;
}

.attacn {
  font-weight: 200;
}

.created_date {
  font-size: 12px;
  font-weight: 200;
  color: grey;
}

.body {
  font-size: 16px;
  font-weight: 300;
}

.visa-card .form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 -16px;
}

.visa-card .form .field {
  padding: 0 16px;
  min-width: 0;
}

.subheading {
  font-family: 'Work Sans', sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #0F0F14;
}

.actions .hse-Button {
  display: inline-block;
}

.hse-Button:first-child {
  margin-right: 12px;
}

.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.form-description {
  padding-top: 12px;
  font-size: 14px;
  line-height: 20px;
  color: #6B7A99;
}

@media (max-width: 1023px) {
  .visa-card {
    margin: 16px auto;
    max-width: 95%;
  }
}

@media (max-width: 767px) {
  .visa-card {
    margin: 0 auto;
    max-width: 100%;
  }

  .form-footer {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
