<template>
  <div>
    <HseModalForm v-model="isOpen">
      <template #actions>
        <HseButton @click="ok">
          {{$t('ok')}}
        </HseButton>
      </template>
      
      <div class="big-item">
        <HseHeading size="h4">{{`Данная заявка включена в сессию ${SectionCode} ${SessionCode} ${SessionName}. Обратитесь, пожалуйста, в оргкомитет для удаления заявки из программы конференции.`}}</HseHeading>
      </div>

    </HseModalForm>
  </div>
</template>

<script>

  import { HseModalForm, HseButton, HseHeading } from '@hse-design/vue' 

  export default {
    props: ['SectionCode', 'SessionCode', 'SessionName'],
    components: {
      HseModalForm,
      HseButton,
      HseHeading,
    },
    data: () => ({
      isOpen: false,
    }),
    created () {
      this.$parent.$on('openSessionModal', () => {this.isOpen = true})
    },
    methods: {
      ok() {
        this.isOpen = false;
      },

    },
    i18n: {
      messages: {
        en: {
          ok: 'OK',
        },
        ru: {
          ok: 'OK',
        },
      }
    } 
  }
</script>

<style scoped>
.big-item {
    min-height: 150px;
  }
</style>