import Vue from 'vue'
import Vuex from 'vuex'
import { resourceLoader } from '../components/common/resourceLoader'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    user: null,
    error: null,
    lang: 'ru',
    isLangDropdownDisabled: false,
    isLeftMenuOpen: false,
    userDataFull: false,
    role: 0,
    roles: [],
    menuNotifications: [],
    countNotifications: 0,
    static: {
      title: {
        ru: "",
        en: ""
      },
      logos: {
        logo1: "",
        logo1_en: "",
        logo2: "",
        logo2_en: "",
      },
      agreements: {
        ru: "",
        en: ""
      },
      visaAgreements: {
        default: {
          ru: "",
          en: ""
        }
      }
    }
  },
  getters: {
    title(state) {
      return state.static.title[state.lang]
    }
  },
  mutations: {
    setUser (state, payload) {
      resourceLoader.clear()
      state.user = payload
    },
    setError (state, payload) {
      state.error = payload
    },
    setLang (state, payload) {
      state.lang = payload
    },
    setRoles (state, payload) {
      state.roles = payload
    },
    setRole (state, payload) {
      state.role = payload
      //disable english for manager
      if (payload === 2) {
        state.lang = 'ru'
        state.isLangDropdownDisabled = true;
      } else {
        state.isLangDropdownDisabled = false;
      }
    },
    setMenuState (state, payload) {
      state.isLeftMenuOpen = payload
    },
    setIsLangDropdownDisabled (state, payload) {
      state.isLangDropdownDisabled = payload
    },
    setStrings (state, payload) {
      const title = payload.find(({ type }) => type === 'title')
      title &&
        (state.static.title = { ru: title.text, en: title.textEn })
      
    },
    setLogos (state, payload) {
      const [logo1, logo1_en, logo2, logo2_en] = [
        payload.find(({ type, lang }) => type === 'ymnk' && lang === 'ru'),
        payload.find(({ type, lang }) => type === 'ymnk' && lang === 'en'),
        payload.find(({ type, lang }) => type === 'hse' && lang === 'ru'),
        payload.find(({ type, lang }) => type === 'hse' && lang === 'en')
      ]

      const logos = { logo1, logo1_en, logo2, logo2_en }

      const filesPath = (process.env.NODE_ENV === 'development' ? 'http://aprilconference-test.hse.ru' : window.location.origin) + '/files/public/'

      Object.keys(logos).forEach(key => {
        const logo = logos[key]
        logo && (state.static.logos[key] = filesPath + logo.logo_id)
      })
    },
    setAgreements (state, payload) {
      payload &&
        (state.static.agreements = { ru: payload.text, en: payload.textEn })
    },
    setVisaAgreement (state, payload) {
      state.static.visaAgreements[payload.eventId] = { ru: payload.text, en: payload.textEn }
    },
    setUserDataFull (state, payload) {
      state.userDataFull = payload
    },
    setMenuNotifications (state, payload) {
      state.menuNotifications = payload
    },
    setCountNotifications (state, payload) {
      state.countNotifications = payload
    }
  },
  actions: {},
})
