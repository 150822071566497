<template>
  <div class="form">
    <div v-if="!isSaved">
      <HseHeading size="h3">{{
        currentTab === "1" ? $t("title1") : $t("title2")
      }}</HseHeading>
      <HseSpace size="small" vertical />

      <div class="tabs-container">
        <HseTabs v-model="currentTab" :tabs="tabs" class="tabs" />
      </div>

      <HseSpace size="small" vertical />

      <FormPage1
        v-show="currentTab === '1'"
        :fieldsDisabled="fieldsDisabled"
        :fields="fields"
        :eventId="eventId"
        :user="user"
        :isNew="isNew"
        :data="data"
        :rtId="rtId"
        @saved="onSaved"
        @savedErr="onSavedErr"
        @getRTData="getRTData"
        @editCurrentTab="() => editCurrentTab('2')"
      />
      <FormPage2
        v-show="currentTab === '2'"
        :publicityForTranslation="publicityForTranslation"
        :fieldsDisabled="fieldsDisabled2"
        :fields="fields"
        :eventId="eventId"
        :user="user"
        :isNew="isNew"
        :data="data"
        :datanew="data"
        :rtId="rtId"
        @saved="onSaved"
        @savedErr="onSavedErr"
      />
    </div>

    <div v-if="isSaved" class="result">
      <div class="status ok" v-if="!isSaveErr">
        <svg
          width="110"
          height="100"
          viewBox="0 0 110 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M26.8055 8.42652C35.0279 2.93245 44.6949 0 54.584 0C67.8448 0 80.5625 5.26784 89.9393 14.6447C99.3161 24.0215 104.584 36.7392 104.584 50C104.584 59.8891 101.652 69.556 96.1575 77.7785C90.6634 86.0009 82.8545 92.4096 73.7182 96.194C64.5819 99.9783 54.5285 100.969 44.8295 99.0392C35.1304 97.11 26.2213 92.3479 19.2287 85.3553C12.236 78.3627 7.474 69.4535 5.54474 59.7545C3.61548 50.0555 4.60565 40.0021 8.39003 30.8658C12.1744 21.7295 18.583 13.9206 26.8055 8.42652ZM85.4219 35.1375L79.2732 28.9887L46.4721 61.7897L29.8964 45.214L23.7476 51.3627L46.4721 74.0872L85.4219 35.1375Z"
            fill="#0050CF"
          />
        </svg>
        <HseSpace size="large" vertical />
        <HseHeading
          size="h3"
          v-html="$t(draft ? 'okStatusDraft' : 'okStatus')"
        ></HseHeading>
        <HseSpace size="medium" vertical />
        <HseButton @click="() => this.$router.push('/my-events')">Ok</HseButton>
      </div>
      <div class="status err" v-else>
        <svg
          width="110"
          height="100"
          viewBox="0 0 110 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M26.8055 8.42652C35.0279 2.93245 44.6949 0 54.584 0C67.8448 0 80.5625 5.26784 89.9393 14.6447C99.3161 24.0215 104.584 36.7392 104.584 50C104.584 59.8891 101.652 69.556 96.1575 77.7785C90.6634 86.0009 82.8545 92.4096 73.7182 96.194C64.5819 99.9783 54.5285 100.969 44.8295 99.0392C35.1304 97.11 26.2213 92.3479 19.2287 85.3553C12.236 78.3627 7.474 69.4536 5.54474 59.7545C3.61548 50.0555 4.60565 40.0021 8.39003 30.8658C12.1744 21.7295 18.583 13.9206 26.8055 8.42652Z"
            fill="#FF564E"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M73.4702 35.2504L69.7498 31.53L55.0002 46.2796L40.2507 31.53L36.5303 35.2504L51.2799 50L36.5303 64.7496L40.2507 68.47L55.0002 53.7204L69.7498 68.47L73.4702 64.7496L58.7206 50L73.4702 35.2504Z"
            fill="white"
          />
        </svg>
        <HseSpace size="large" vertical />
        <HseHeading size="h3" v-html="$t('errStatus')"></HseHeading>
        <HseSpace size="medium" vertical />
        <HseButton @click="getBackIfErr">{{ $t("back") }}</HseButton>
      </div>
    </div>
  </div>
</template>

<script>
import { HseHeading, HseSpace, HseTabs, HseButton } from "@hse-design/vue";
import { mapState } from "vuex";
import FormPage1 from "./FormPage1.vue";
import FormPage2 from "./FormPage2.vue";
import { localizeFields } from "../../i18n";
import { api } from "../../api";
import { dateFormatter } from "../common/dateFormatter";
import fields from "./roundTableFields";

export default {
  name: "RoundTableForm",
  components: {
    HseHeading,
    HseSpace,
    HseTabs,
    FormPage1,
    FormPage2,
    HseButton,
  },
  data: () => ({
    IsRequiredPassport: false,
    IsRequiredPassportRussia: false,
    IsRequiredPassportOther: false,
    fields,
    currentTab: "1",
    eventId: "",
    isSaved: false,
    isSaveErr: false,
    isNew: true,
    draft: false,
    fieldsDisabled: false,
    fieldsDisabled2: false,
    rtId: "",
    publicityForTranslation: false,
    data: {},
    user: {},
  }),
  created() {
    if (this.$route.query && this.$route.query.event) {
      this.eventId = this.$route.query.event;
    }
    if (
      this.$route.query &&
      (this.$route.query.step === "1" || this.$route.query.step === "2")
    ) {
      this.currentTab = this.$route.query.step;
    }
    if (this.$route.query && this.$route.query.rtId) {
      this.isNew = false;
      this.rtId = this.$route.query.rtId;
      this.getRTData();
    }
    this.user = this.$store.state.user;
    this.translateFields();

    this.fields.secretaryOnline.setEvent(this.eventId);
    this.fields.secretaryOffline.setEvent(this.eventId);
  },
  methods: {
    editCurrentTab(val) {
      this.currentTab = val;
    },
    translateFields() {
      ["programSessionTimeId"].forEach((key) => {
        fields[key].eventId = this.eventId;
      });
      this.fields = localizeFields(fields, this.fields);
    },
    getRTData() {
      api
        .request("get_is_required_passport", { EventId: this.eventId })
        .then((data) => {
          this.IsRequiredPassport = data.IsRequiredPassport;
          this.IsRequiredPassportRussia = data.IsRequiredPassportRussia;
          this.IsRequiredPassportOther = data.IsRequiredPassportOther;

          api
            .request("get_rt_events_data", {
              rtEventId: this.rtId,
              lang: this.lang,
            })
            .then((data) => {
              const citizenIdRussia = "BC763F4B-FBD9-461B-B395-166020E8C026";

              data.moderators.map((moderator) => {
                if (
                  (this.IsRequiredPassport &&
                    this.IsRequiredPassportRussia &&
                    moderator.countryId === citizenIdRussia) ||
                  (this.IsRequiredPassport &&
                    this.IsRequiredPassportOther &&
                    moderator.countryId !== citizenIdRussia)
                ) {
                  moderator.IsRequiredPassport = true;
                }
              });

              data.speakers.map((speaker) => {
                if (
                  (this.IsRequiredPassport &&
                    this.IsRequiredPassportRussia &&
                    speaker.countryId === citizenIdRussia) ||
                  (this.IsRequiredPassport &&
                    this.IsRequiredPassportOther &&
                    speaker.countryId !== citizenIdRussia)
                ) {
                  speaker.IsRequiredPassport = true;
                }
              });

              data.members.map((member) => {
                if (
                  (this.IsRequiredPassport &&
                    this.IsRequiredPassportRussia &&
                    member.countryId === citizenIdRussia) ||
                  (this.IsRequiredPassport &&
                    this.IsRequiredPassportOther &&
                    member.countryId !== citizenIdRussia)
                ) {
                  member.IsRequiredPassport = true;
                }
              });

              this.data = data;

              console.log(this.data, " this.data");
              this.publicityForTranslation = data.publicityForTranslation;
              if (
                !this.statusFirstModification &&
                !(this.data.ResponsibleEmail === null && this.statusDraft)
              ) {
                this.fieldsDisabled = "partially";
              }
              if (this.statusPublication || this.statusPublicated) {
                this.fieldsDisabled = "membersOnly";
              }
              if (
                this.statusCheck ||
                this.statusSupported ||
                this.statusReview ||
                this.statusSecondModification
              ) {
                this.fieldsDisabled = true;
              }
              if (
                this.statusPublication ||
                this.statusPublicated ||
                this.statusCheck
              ) {
                this.fieldsDisabled2 = true;
              }
              this.fillFields();
            });
        });
    },
    onSaved(draft) {
      this.draft = draft;
      this.isSaved = true;
    },
    onSavedErr() {
      this.isSaved = true;
      this.isSaveErr = true;
    },
    getBackIfErr() {
      this.isSaved = false;
      this.isSaveErr = false;
      this.getRTData();
    },
    nullToString(x) {
      return x === null ? "" : x;
    },
    fillFields() {
      this.fields.LName.value = this.data.InitiatorLastName;
      this.fields.FName.value = this.data.InitiatorFirstName;
      this.fields.SName.value = this.data.InitiatorMiddleName;
      this.fields.Email.value = this.data.InitiatorEmail;
      this.fields.Position.value = this.data.InitiatorPost;
      this.fields.DepartmentIdRu.value = String(this.data.InitiatorDepartmenID);
      this.fields.StatusRT.value = this.data.StatusId;
      this.fields.plannedRTname.value = this.data.PlannedName;
      this.fields.plannedRTnameEn.value = this.data.PlannedNameEn;
      this.fields.plannedSpecialEventOrganizationFormatId.value =
        this.data.PlannedSpecialEventOrganizationFormatId;
      this.fields.plannedDiscussionQuestions.value =
        this.data.PlannedDiscussionQuestions;
      this.fields.plannedDdiscussionQuestionsEn.value =
        this.data.PlannedDiscussionQuestionsEn;
      this.fields.comment.value = this.nullToString(this.data.comment);
      this.fields.involvedBusinessOrg.value = this.nullToString(
        this.data.involvedBusinessOrg
      );
      this.fields.involvedInfoOrg.value = this.nullToString(
        this.data.involvedInfoOrg
      );
      this.fields.ChiefFIO.value = this.data.ChiefFIO;
      this.fields.ChiefPost.value = this.data.ChiefPost;
      this.fields.ChiefDepartmenID.value = String(this.data.ChiefDepartmenID);
      this.fields.ChiefEmail.value = this.data.ChiefEmail;

      this.fields.RTname.value = this.data.RTname;
      this.fields.RTnameEn.value = this.nullToString(this.data.RTnameEn);
      this.fields.responsibleFio.value = this.data.responsibleFio;
      this.fields.discussionQuestions.value = this.data.discussionQuestions;
      this.fields.discussionQuestionsEn.value = this.nullToString(
        this.data.discussionQuestionsEn
      );
      if (this.data.date != "1753-01-01T00:00:00.000Z") {
        this.fields.date.value = dateFormatter.format(this.data.date, "date");
      }
      if (
        this.data.programSessionTimeId != "00000000-0000-0000-0000-000000000000"
      ) {
        this.fields.programSessionTimeId.value = this.data.programSessionTimeId;
      }
      // this.fields.programSessionTimeId.value = this.data.programSessionTimeId;
      this.fields.langRu.value = !!this.data.langRu;
      this.fields.langEn.value = !!this.data.langEn;
      this.fields.langOther.value = !!this.data.LangOther;
      this.fields.other.value = this.nullToString(this.data.Other);
      this.fields.contactPerson.value = this.data.contactPerson;
      this.fields.contactPersonEmail.value = this.data.contactPersonEmail;

      this.fields.ResponsibleFIO.value = this.data.responsibleFio;
      this.fields.ResponsiblePost.value = this.data.ResponsiblePost;
      if (this.data.ResponsibleDepartmenID > 0) {
        this.fields.ResponsibleDepartmenID.value = String(
          this.data.ResponsibleDepartmenID
        );
      }
      this.fields.ResponsibleEmail.value = this.data.ResponsibleEmail;
      this.fields.ResponsiblePhone.value = this.data.ResponsiblePhone;

      this.fields.specialEventOrganizationFormatId.value =
        this.data.specialEventOrganizationFormatId;

      if (this.data.moderators) {
        this.fields.moderators.value = this.data.moderators;
      }
      if (this.data.speakers) {
        this.fields.speakers.value = this.data.speakers;
      }
      if (this.data.members) {
        this.fields.members.value = this.data.members;
      }

      this.fields.syncTranslation.value = this.data.syncTranslation ? 1 : 0;
      this.fields.needOnlineSupport.value = this.data.needOnlineSupport ? 1 : 0;
      this.fields.needVolunteerSupport.value = this.data.needVolunteerSupport
        ? 1
        : 0;
      this.fields.volunteerSupportBlocksId.value = this.data
        .volunteerSupportBlocksId
        ? this.data.volunteerSupportBlocksId
        : [];
      this.fields.needOfficialPhoto.value = this.data.needOfficialPhoto ? 1 : 0;
      this.fields.needOfficialVideo.value = this.data.needOfficialVideo ? 1 : 0;
      this.fields.publicityId.value = this.data.publicityId
        ? this.data.publicityId
        : this.fields.publicityId.default;
      this.fields.massMediaId.value = this.data.massMediaId;
      this.fields.needOnlineTranslation.value = this.data.needOnlineTranslation
        ? 1
        : 0;

      this.fields.NumberParticipants.value =
        this.data.maxParticipants <= 0
          ? this.fields.maxParticipants.value
          : this.data.maxParticipants;
      this.fields.maxParticipants.value =
        this.data.maxParticipants <= 0
          ? this.fields.maxParticipants.value
          : this.data.maxParticipants;
      this.fields.endRegistrationDate.value = dateFormatter.format(
        this.data.endRegistrationDate,
        "date"
      );
    },
  },
  destroyed() {
    this.$store.commit("setIsLangDropdownDisabled", false);
  },
  computed: {
    tabs() {
      return [
        {
          value: "1",
          label: this.$i18n.tc("step1"),
        },
        {
          value: "2",
          label: this.$i18n.tc("step2"),
          disabled: this.fieldsDisabled2
            ? false
            : !this.fieldsDisabled || this.statusReview,
        },
      ];
    },
    statusReview() {
      return (
        this.data.roundTableStatusId === "81A603E7-391C-460E-B413-FC153EA20C1A"
      );
    },
    statusSupported() {
      return (
        this.data.roundTableStatusId === "3BB3404D-032C-48C1-9397-6FCF1E4A6080"
      );
    },
    statusFirstModification() {
      return (
        this.data.roundTableStatusId === "31812363-221B-4B92-9B53-69003FF920C4"
      );
    },
    statusSecondModification() {
      return (
        this.data.roundTableStatusId === "598FA3AE-2FBB-4703-8B75-02735E758EEA"
      );
    },
    statusCheck() {
      return (
        this.data.roundTableStatusId === "EF7995A1-DA68-4A79-AE54-0BBFCA044F37"
      );
    },
    statusPublication() {
      return (
        this.data.roundTableStatusId === "28AE374D-D74C-4F4B-857C-E4E5BF0FF90A"
      );
    },
    statusPublicated() {
      return (
        this.data.roundTableStatusId === "C08814A5-7780-4595-9A31-AF51383E3933"
      );
    },
    statusCanceled() {
      return (
        this.data.roundTableStatusId === "CC88FBA9-62A7-4A0F-BC3D-A133BB7207EF"
      );
    },
    statusDeclined() {
      return (
        this.data.roundTableStatusId === "472430DA-E08D-491B-862E-19B1EED635FD"
      );
    },
    statusDraft() {
      return (
        this.data.roundTableStatusId === "37241B9D-2AAA-4EB4-9B01-F310902E0E84"
      );
    },
    ...mapState(["lang"]),
  },
  watch: {
    lang() {
      this.translateFields();
    },
    currentTab() {
      this.$store.commit("setIsLangDropdownDisabled", this.currentTab !== 1);
    },
  },
  i18n: {
    messages: {
      en: {
        title1: "Content Information",
        title2: "Organizational information",
        back: "Back",
        okStatus: "Request sent",
        okStatusDraft: "Draft saved",
        errStatus: "Error occured",
        step1: "Content Information",
        step2: "Organizational information",
      },
      ru: {
        title1: "Содержательная информация",
        title2: "Организационная информация",
        back: "Назад",
        okStatus: "Заявка отправлена",
        okStatusDraft: "Черновик сохранен",
        errStatus: "Произошла ошибка",
        step1: "Содержательная информация",
        step2: "Организационная информация",
      },
    },
  },
};
</script>

<style scoped>
.form {
  width: 800px;
  margin: 52px auto;
  background: #ffffff;
  border-radius: 12px;
  padding: 32px;
  max-width: calc(100vw - 320px);
}

/* @media (max-width: 769px) {
  .wrapper {
    width: calc(100vw - 48px);
  }
}

@media (min-width: 769px) {
  .wrapper {
    width: calc(100vw - 320px);
  }
} */

.cols {
  display: flex;
  margin: 0 -15px;
  flex-wrap: wrap;
}

.cols > .field {
  padding: 0 15px;
  flex: 1;
}

.two-cols .field {
  max-width: 50%;
  width: 50%;
  flex-basis: 50%;
}

.three-cols .field {
  max-width: 33.3%;
}

.ident {
  margin-left: 10px;
}

@media (max-width: 767px) {
  .form {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }

  .two-cols .field,
  .three-cols .field {
    max-width: 100%;
    width: 100%;
    flex-basis: 100%;
  }
}

.actions {
  flex-wrap: wrap;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.accordion {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
}

.accordion__head {
  display: flex;
  justify-content: space-between;
}

.participant__heading {
  display: flex;
  align-items: center;
  gap: 5px;
}

.participant__heading .icons {
  display: flex;
  gap: 3px;
}

.participant__heading .icons > svg {
  cursor: pointer;
}
</style>
