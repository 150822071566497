<template>
  <div class="form">
    <div class="subheading">{{ $t('disscussants') }}</div>
    <HseSpace size="small" vertical />
    <div class="disscussants">
      <div class="members">
        <div class="member" v-for="(disscussant, index) in disscussants" :key="disscussant.login">
          <div>{{ getDisscussantName(disscussant) }}</div>
          <div class="icons">
            <svg @click="() => editDisscussant(disscussant, index)" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.60161 5.35704L11.4995 7.26425L6.69535 12.092L4.7985 10.1848L9.60161 5.35704ZM12.9397 4.89707L12.0933 4.04652C11.7662 3.71781 11.235 3.71781 10.9068 4.04652L10.0961 4.86126L11.994 6.76849L12.9397 5.81814C13.1934 5.56317 13.1934 5.15202 12.9397 4.89707ZM3.80527 12.8655C3.77073 13.0217 3.91108 13.1617 4.06654 13.1237L6.18147 12.6084L4.28461 10.7012L3.80527 12.8655Z" fill="#6B7A99"/>
            </svg>
            <svg @click="() => removeDisscussant(disscussant)" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6663 5.164L11.7263 4.224L7.99967 7.95067L4.27301 4.224L3.33301 5.164L7.05967 8.89067L3.33301 12.6173L4.27301 13.5573L7.99967 9.83067L11.7263 13.5573L12.6663 12.6173L8.93967 8.89067L12.6663 5.164Z" fill="#6B7A99"/>
            </svg>
          </div>
        </div>
      </div>
      <div class="add-member" @click="() => isModalOpen = true">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21 11.5H13V3.5H11V11.5H3V13.5H11V21.5H13V13.5H21V11.5Z" fill="#0050CF"/>
        </svg>
        <span>{{ $t('addDisscussant') }}</span>
      </div>
    </div>
    <HseModal v-model="isModalOpen" :shouldCloseOnOverlayClick="false">
      <DisscussantForm
        v-if="isModalOpen"
        :editableDisscussant="editableDisscussant"
        :added="disscussants"
        @saved="onDisscussantSave"
      />
    </HseModal>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { store } from '../../store'
  import { HseSpace, HseModal } from '@hse-design/vue'
  import DisscussantForm from './DisscussantForm'
  export default {
    name: 'FormPage3',
    store,
    data: () => ({
      isModalOpen: false,
      disscussants: [],
      editableDisscussant: null,
    }),
    created () {
      this.$parent.$on('validatePage', ({ current }) => {
        if (current === 3) {
          this.checkPageValidity()
        }
      })
    },
    components: {
      HseSpace,
      HseModal,
      DisscussantForm,
    },
    methods: {
      checkPageValidity () {
        this.$emit('validatePage', { page: 3, isValid: true })
      },
      editDisscussant (disscussant, index) {
        this.editableDisscussant = { ...disscussant, index }
        this.isModalOpen = true
      },
      onDisscussantSave (result) {
        if (!this.editableDisscussant) {
          this.disscussants.push(result)
          this.$emit('updateDisscussants', this.disscussants)
        } else {
          this.disscussants[this.editableDisscussant.index] = result
        }

        this.editableDisscussant = null
        this.isModalOpen = false
      },
      getDisscussantName (disscussant) {
        const hasData = disscussant && disscussant.data && disscussant.data.public
        if (!hasData) return ''

        return this.lang === 'ru' 
          ? `${disscussant.data.public.LName} ${disscussant.data.public.FName} ${disscussant.data.public.SName}`
          : `${disscussant.data.public.LNameEN} ${disscussant.data.public.FNameEN}`
      },
      removeDisscussant ({ id }) {
        this.disscussants = this.disscussants.filter(disscussant => disscussant.id !== id)
        this.$emit('updateDisscussants', this.discussants)
      }
    },
    computed: {
      ...mapState(['lang']),
    },
    watch: {
      isModalOpen (isOpen) {
        if (!isOpen) {
          this.editableDisscussant = null
        }
      },
    },
    i18n: {
      messages: {
        en: {
          addDisscussant: 'Add a discussant',
          disscussants: 'Add discussants'
        },
        ru: {
          addDisscussant: 'Добавить дискуссанта',
          disscussants: 'Добавить дискуссантов'
        },
      }
    },
  }
</script>

<style scoped>
  .form {
    width: 670px;
  }
  .add-member {
    background: #F0F5FF;
    border-radius: 4px;
    border: 1px solid #8EB2EC;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #0050CF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 24px 8px 16px;
    width: fit-content;
    cursor: pointer;
  }
  .add-member svg {
    margin-right: 11px;
  }
  .members {
    width: fit-content
  }
  .member {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    color: #6B7A99;
    padding: 10px 0;
    margin-bottom: 12px;
    justify-content: space-between;
  }
  .member:last-child {
    margin-bottom: 17px;
  }
  .member .icons {
    margin-left: 20px;
  }
  .member .icons>svg {
    cursor: pointer;
    margin: 0 3px;
  }
</style>
<style>
  .hse-BaseModal {
    width: 690px !important;
    max-height: 100%;
  }
</style>