import Vue from 'vue'
import VueRouter from 'vue-router'

import RequestForm from '../components/requestPage/RequestForm.vue'
import RequestUpdateForm from '../components/requestUpdatePage/RequestUpdateForm.vue'
import EventSingle from '../components/eventsPage/EventSingle.vue'
import SetPassword from '../components/common/auth/SetPassword.vue'
import Events from '../pages/Events'
import Requests from '../pages/Requests'
import CollectiveRequests from '../pages/CollectiveRequests'
import MyEvents from '../pages/MyEvents'
import UserProfile from '../pages/UserProfile'
import ExpertRequests from '../pages/ExpertRequests'
import RequestCardExpertSingle from '../components/requestsPage/RequestCardExpertSingle.vue'
import Visas from '../pages/Visas'
import Visa from '../components/visasPage/Visa.vue'
import VisaSingle from '../components/visasPage/VisaSingle.vue'
import ManagerRequests from '../pages/ManagerRequests'
import RequestCardManagerSingle from '../components/requestsPage/RequestCardManagerSingle.vue'
import Payment from '../pages/Payment'
import Experts from '../pages/Experts'
import Expert from '../components/expertsPage/Expert.vue'
import ExpertNew from '../components/expertsPage/ExpertNew.vue'
import ExpertConfirm from '../components/expertsPage/ExpertConfirm.vue'
import Messages from '../pages/Messages'
import Message from '../components/messagesPage/Message.vue'
import NewMessage from '../components/messagesPage/NewMessage.vue'
import Reports from '../pages/Reports'
import RequestCardUserReadOnly from '../components/requestsPage/RequestCardUserReadOnly.vue'
import CollectiveRequestCardUserReadOnly from '../components/CollectiveRequestPage/RequestCardUserReadOnly.vue'
import SpecialEvents from "../components/specialEventsPage/SpecialEvents";
import SpecialEventCard from "../components/specialEventsPage/SpecialEventCard";
import CollectiveRequestForm from '../components/collectivePage/CollectiveRequestForm'
import RoundTableForm from '../components/roundTablePage/RoundTableForm'
import honoraryOrInvitedReportForm from '../components/honoraryOrInvitedReportPage/honoraryOrInvitedReportForm'
import MyEventReadOnly from '../components/MyEventsPage/MyEventReadOnly'
import MyEventHorReadOnly from '../components/MyEventsPage/MyEventHorReadOnly'
import EventProgram from '../pages/EventProgram'

Vue.use(VueRouter)

const routes = [
  {
    path: '/events',
    name: 'Events',
    component: Events,
    roles: [1]
  }, {
    path: '/events/:id',
    name: 'Event Single',
    component: EventSingle,
    roles: [1]
  }, {
    path: '/event-program/:id',
    name: 'EventProgram',
    component: EventProgram,
    roles: [1]
  }, {
    path: '/requests',
    name: 'Requests',
    component: Requests,
    roles: [1]
  }, {
    path: '/collective-requests',
    name: 'CollectiveRequests',
    component: CollectiveRequests,
    roles: [1]
  }, {
    path: '/visas/add/',
    name: 'Add Visa Support',
    component: Visa,
    roles: [1]
  }, {
    path: '/visas',
    name: 'Visas',
    component: Visas,
    roles: [1]
  }, {
    path: '/visas/:id',
    name: 'Visa Single',
    component: VisaSingle,
    roles: [1]
  }, {
    path: '/requests/add/',
    name: 'Add request',
    component: RequestForm,
    roles: [1]
  }, {
    path: '/requests/update/:id',
    name: 'Update request',
    component: RequestUpdateForm,
    roles: [1]
  }, {
    path: '/requests/:id',
    name: 'View request',
    component: RequestCardUserReadOnly,
    roles: [1]
  }, {
    path: '/collective-requests/:id',
    name: 'View request',
    component: CollectiveRequestCardUserReadOnly,
    roles: [1]
  }, {

    path: '/collective_requests/add/',
    name: 'Add collective request',
    component: CollectiveRequestForm,
    roles: [1],
  }, {
    path: '/round_table/create/',
    name: 'Create round table',
    component: RoundTableForm,
    roles: [1],
  }, {
    path: '/honorary_or_invited_report/create/',
    name: 'Create round table',
    component: honoraryOrInvitedReportForm,
    roles: [1],
  },
  {
    path: '/my-events',
    name: 'My events',
    component: MyEvents,
    roles: [1],
  }, {
    path: '/my-events/:id',
    name: 'My event detail',
    component: MyEventReadOnly,
    roles: [1],
  }, {
    path: '/my-events-hor/:id',
    name: 'My event detail',
    component: MyEventHorReadOnly,
    roles: [1],
  }, {
    path: '/profile',
    name: 'Profile',
    component: UserProfile,
  }, {
    path: '/restorePassword',
    name: 'SetPassword',
    component: SetPassword,
  }, {
    path: '/expert_requests',
    name: 'ExpertRequests',
    component: ExpertRequests,
    roles: [3]
  }, {
    path: '/expert_requests/:id',
    name: 'RequestCardExpertSingle',
    component: RequestCardExpertSingle,
    roles: [3]
  },
  {
    path: '/manager_requests',
    name: 'ManagerRequests',
    component: ManagerRequests,
    roles: [2]
  }, {
    path: '/manager_requests/:id',
    name: 'RequestCardManagerSingle',
    component: RequestCardManagerSingle,
    roles: [2]
  }, {
    path: '/requests/:id/payment',
    name: 'Payment',
    component: Payment,
    roles: [2, 3]
  }, {
    path: '/experts_list',
    name: 'Experts',
    component: Experts,
    roles: [2]
  },
  {
    path: '/expert/:id/:event',
    name: 'Expert',
    component: Expert,
    roles: [2]
  },
  {
    path: '/new_expert',
    name: 'ExpertNew',
    component: ExpertNew,
    roles: [2]
  },
  {
    path: '/expert_confirm/:expert_id/:event_id/:status/:lng',
    name: 'ExpertConfirm',
    component: ExpertConfirm,
  },
  {
    path: '/messages',
    name: 'Messages',
    component: Messages,
  },
  {
    path: '/messages/:id',
    name: 'Message',
    component: Message,
  },
  {
    path: '/messages/:reply/new',
    name: 'NewMessage',
    component: NewMessage,
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
    roles: [2]
  },
  {
    path: '/special_events/:eventId',
    name: 'SpecialEvents',
    component: SpecialEvents,
    roles: [1]
  },
  {
    path: '/special_events_card/:eventId/:cardId',
    name: 'SpecialEventCard',
    component: SpecialEventCard,
    roles: [1]
  },
]

const router = new VueRouter({
  routes
})

export { router, routes }