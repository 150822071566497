<template>
  <div class="form">
    <HseHeading size="h2">{{ $t("title") }}</HseHeading>
    <HseSpace size="small" vertical />

    <div class="tabs-container">
      <HseTabs v-model="currentTab" :tabs="tabs" class="tabs" />
    </div>

    <HseSpace size="small" vertical />

    <!-- Этап 1 -->
    <div v-show="currentTab === '1'">
      <!-- Инициатор заявки -->
      <div>
        <HseSpace size="small" vertical />
        <div class="headingWithTooltip">
          <div class="subheading">{{ $t("personal") }}</div>
          <HseTooltip :content="$t('initiatorApplicationTooltip')">
            <IconStatusInfo />
          </HseTooltip>
        </div>

        <HseSpace size="small" vertical />

        <div class="cols two-cols">
          <div class="field">
            <ReadonlyInput
              :value="data.InitiatorLastName"
              :label="$t('fields.name.lName')"
            />
          </div>
          <div class="field">
            <ReadonlyInput
              :value="data.InitiatorFirstName"
              :label="$t('fields.name.fName')"
            />
          </div>
        </div>

        <div class="cols two-cols">
          <div class="field">
            <ReadonlyInput
              :value="data.InitiatorMiddleName"
              :label="$t('fields.name.mName')"
            />
          </div>
        </div>

        <div class="cols two-cols">
          <div class="field">
            <ReadonlyInput
              :value="data.InitiatorPost"
              :label="$t('fields.name.position')"
            />
          </div>
          <div class="field">
            <ReadonlyInput
              :value="data.InitiatorDepartmenIDValue"
              :label="$t('fields.request.departmentRu')"
            />
          </div>
        </div>

        <div class="cols two-cols">
          <div class="field">
            <ReadonlyInput
              :value="data.InitiatorPhone"
              :label="$t('fields.name.phone')"
            />
          </div>
          <div class="field">
            <ReadonlyInput
              :value="data.InitiatorEmail"
              :label="$t('fields.name.email')"
            />
          </div>
        </div>
      </div>

      <HseSpace size="small" vertical />
      <div class="cols two-cols">
        <div class="field">
          <!-- Статус круглого стола -->
          <ReadonlyInput
            :value="data.StatusIdValue"
            :label="$t('fields.roundTable.statusRt')"
          />
        </div>
      </div>

      <HseSpace size="small" vertical />
      <div class="cols two-cols">
        <div class="field">
          <!-- Название круглого стола -->
          <ReadonlyInput
            :value="data.PlannedName"
            :label="$t('fields.roundTable.RTname')"
          />
        </div>
        <div class="field">
          <!-- Название мероприятия (en) -->
          <ReadonlyInput
            :value="data.PlannedNameEn"
            :label="$t('fields.roundTable.RTnameEn')"
          />
        </div>
      </div>

      <HseSpace size="small" vertical />
      <div class="cols two-cols">
        <div class="field">
          <!-- Планируемый формат мероприятия -->
          <ReadonlyInput
            :value="data.PlannedSpecialEventOrganizationFormatIdValue"
            :label="$t('plannedEventFormat')"
          />
        </div>
      </div>

      <!-- Вопросы для обсуждения -->
      <HseSpace size="small" vertical />
      <div class="cols two-cols">
        <div class="field">
          <ReadonlyInput
            :value="data.PlannedDiscussionQuestions"
            :label="$t('fields.roundTable.discussionQuestions')"
          />
        </div>
        <div class="field">
          <ReadonlyInput
            :value="data.PlannedDiscussionQuestionsEn"
            :label="$t('fields.roundTable.discussionQuestionsEn')"
          />
        </div>
      </div>

      <!-- Комментарии -->
      <HseSpace size="small" vertical />
      <ReadonlyInput
        :value="data.comment"
        :label="$t('fields.roundTable.comment')"
      />

      <HseSpace size="small" vertical />
      <ReadonlyInput
        :value="data.involvedBusinessOrg"
        :label="$t('fields.roundTable.involvedBusinessOrg')"
      />

      <HseSpace size="small" vertical />
      <ReadonlyInput
        :value="data.involvedInfoOrg"
        :label="$t('fields.roundTable.involvedInfoOrg')"
      />

      <!-- Coordinating leader  -->
      <div>
        <HseSpace size="small" vertical />
        <div class="subheading">
          {{ $t("fields.honoraryOrInvitedReport.coordinatingLeader") }}
        </div>
        <HseSpace size="small" vertical />

        <ReadonlyInput
          :value="data.ChiefFIO"
          :label="$t('fields.honoraryOrInvitedReport.coordinatingLeaderFio')"
        />
        <div class="cols two-cols">
          <div class="field">
            <ReadonlyInput
              :value="data.ChiefPost"
              :label="$t('fields.name.position')"
            />
          </div>
          <div class="field">
            <ReadonlyInput
              :value="data.ChiefDepartmenIDValue"
              :label="$t('fields.request.departmentRu')"
            />
          </div>
        </div>
        <div class="cols two-cols">
          <div class="field">
            <ReadonlyInput
              :value="data.ChiefEmail"
              :label="$t('fields.name.email')"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Этап 2 -->
    <div v-show="currentTab === '2'">
      <HseSpace size="small" vertical />
      <div class="cols two-cols">
        <div class="field">
          <!-- Название круглого стола -->
          <ReadonlyInput
            :value="data.RTname"
            :label="$t('fields.roundTable.RTname')"
          />
        </div>
        <div class="field">
          <!-- Название мероприятия (en) -->
          <ReadonlyInput
            :value="data.RTnameEn"
            :label="$t('fields.roundTable.RTnameEn')"
          />
        </div>
      </div>

      <!-- Вопросы для обсуждения -->
      <HseSpace size="small" vertical />
      <div class="cols two-cols">
        <div class="field">
          <ReadonlyInput
            :value="data.discussionQuestions"
            :label="$t('fields.roundTable.discussionQuestions')"
          />
        </div>
        <div class="field">
          <ReadonlyInput
            :value="data.discussionQuestionsEn"
            :label="$t('fields.roundTable.discussionQuestionsEn')"
          />
        </div>
      </div>

      <!-- Ответственный за мероприятие -->
      <HseSpace size="small" vertical />
      <div class="subheading">
        {{ $t("responsibleEvent") }}
      </div>
      <HseSpace size="small" vertical />

      <!-- ФИО Ответственного за мероприятие -->
      <ReadonlyInput
        :value="data.responsibleFio"
        :label="$t('fields.roundTable.responsibleFio')"
      />
      <div class="cols two-cols">
        <!-- Должность * -->
        <div class="field">
          <ReadonlyInput
            :value="data.ResponsiblePost"
            :label="$t('fields.honoraryOrInvitedReport.post')"
          />
        </div>
        <!-- Подразделение * -->
        <div class="field">
          <ReadonlyInput
            :value="data.ResponsibleDepartmenIDValue"
            :label="$t('fields.honoraryOrInvitedReport.division')"
          />
        </div>
      </div>
      <div class="cols two-cols">
        <!-- Должность * -->
        <div class="field">
          <ReadonlyInput
            :value="data.ResponsibleEmail"
            :label="$t('fields.name.email')"
          />
        </div>
        <!-- Подразделение * -->
        <div class="field">
          <ReadonlyInput
            :value="data.ResponsiblePhone"
            :label="$t('fields.name.phone')"
          />
        </div>
      </div>

      <HseSpace size="small" vertical />
      <div class="cols two-cols">
        <div class="field">
          <!-- Формат мероприятия -->
          <ReadonlyInput
            :value="data.specialEventOrganizationFormat"
            :label="$t('eventFormat')"
          />
        </div>
      </div>

      <div
        class="cols two-cols"
        v-show="
          data.specialEventOrganizationFormatId ===
          '592D7A01-E55F-4860-B403-1BB88FDE85A9'
        "
      >
        <div class="field">
          <!-- Максимальное количество участников в очном формате -->
          <ReadonlyInput
            :value="data.maxParticipants"
            :label="$t('fields.name.numberParticipants')"
          />
        </div>
      </div>

      <HseSpace size="small" vertical />
      <div class="cols two-cols">
        <div class="field">
          <!-- Планируемая дата проведения -->
          <ReadonlyInput
            :value="formatDate(data.date)"
            :label="$t('fields.roundTable.date')"
          />
        </div>
        <div class="field">
          <!-- Планируемый временной слот -->
          <ReadonlyInput
            :value="data.programSessionTime"
            :label="$t('fields.roundTable.programSessionTimeId')"
          />
        </div>
      </div>

      <!-- Рабочий язык круглого стола -->
      <ReadonlyInput
        :value="langValue"
        :label="$t('fields.roundTable.workingLang')"
      />

      <!-- Требуется ли синхронный перевод -->
      <ReadonlyInput
        :value="data.syncTranslationValue"
        :label="$t('fields.roundTable.syncTranslationNew')"
      />

      <!-- Публичность -->
      <ReadonlyInput
        :value="data.publicity"
        :label="$t('fields.roundTable.publicityId')"
      />

      <!-- Секретарь  -->
      <HseSpace size="small" vertical />
      <div class="subheading">
        {{ $t("fields.honoraryOrInvitedReport.secretary") }}
      </div>
      <HseSpace size="small" vertical />

      <div class="cols two-cols">
        <div
          class="field"
          v-show="
            data.specialEventOrganizationFormatId ===
              '1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A' ||
            data.specialEventOrganizationFormatId ===
              '895BB6AB-A343-48DC-AA19-7EAB348C8C0D'
          "
        >
          <ReadonlyInput
            :value="data.SecretaryOnlineValue"
            :label="$t('fields.honoraryOrInvitedReport.secretaryOnline')"
          />
        </div>
        <div
          class="field"
          v-show="
            data.specialEventOrganizationFormatId ===
              '592D7A01-E55F-4860-B403-1BB88FDE85A9' ||
            data.specialEventOrganizationFormatId ===
              '895BB6AB-A343-48DC-AA19-7EAB348C8C0D'
          "
        >
          <ReadonlyInput
            :value="data.SecretaryOfflineValue"
            :label="$t('fields.honoraryOrInvitedReport.secretaryOffline')"
          />
        </div>
      </div>
    </div>

    <div v-if="data.moderators && data.moderators.length">
      <HseSpace size="small" vertical />
      <div
        :style="{ background: '#6B7A99', padding: '6px' }"
        class="accordion__head"
      >
        <HseButton
          variant="tertiaryInvert"
          size="medium"
          v-on:click="isModeratorBlockOpen = !isModeratorBlockOpen"
        >
          {{ $t("moderatorBlock") }}
          <span class="ident" v-show="isModeratorBlockOpen">&#9650;</span>
          <span class="ident" v-show="!isModeratorBlockOpen">&#9660;</span>
        </HseButton>
        <div
          class="accordion__info"
          v-show="!(isModeratorBlockOpen && data.moderators.length)"
        >
          {{ $t("moderatorInfo") }}
        </div>
      </div>
      <div
        v-show="isModeratorBlockOpen"
        :style="{ background: '#F0F2F5', padding: '6px' }"
      >
        <div class="accordion">
          <div
            class="participant"
            v-for="(moderator, index) in data.moderators"
            :key="moderator.login"
          >
            <div class="participant__heading">
              <HseHeading size="h4">{{
                `${$t("fields.name.moderators")} №${index + 1}`
              }}</HseHeading>
            </div>
            <HseSpace size="small" vertical />
            <div>
              <ReadonlyInput
                :label="$t('fields.name.lName')"
                :value="moderator.lastName"
              />
              <ReadonlyInput
                :label="$t('fields.name.fName')"
                :value="moderator.firstName"
              />
              <ReadonlyInput
                v-if="moderator.secondName"
                :label="$t('fields.name.mName')"
                :value="moderator.secondName"
              />
              <ReadonlyInput
                :label="$t('fields.name.email')"
                :value="moderator.email"
              />
              <ReadonlyInput
                :label="$t('fields.name.companyFull')"
                :value="moderator.organizationName"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="data.speakers && data.speakers.length">
      <HseSpace size="small" vertical />
      <div
        :style="{ background: '#6B7A99', padding: '6px' }"
        class="accordion__head"
      >
        <HseButton
          variant="tertiaryInvert"
          size="medium"
          v-on:click="isSpeakerBlockOpen = !isSpeakerBlockOpen"
        >
          {{ $t("speakerBlock") }}
          <span class="ident" v-show="isSpeakerBlockOpen">&#9650;</span>
          <span class="ident" v-show="!isSpeakerBlockOpen">&#9660;</span>
        </HseButton>
        <div
          class="accordion__info"
          v-show="!(isSpeakerBlockOpen && data.speakers.length)"
        >
          {{ $t("speakerInfo") }}
        </div>
      </div>
      <div
        v-show="isSpeakerBlockOpen"
        :style="{ background: '#F0F2F5', padding: '6px' }"
      >
        <div class="accordion">
          <div
            class="participant"
            v-for="(speaker, index) in data.speakers"
            :key="speaker.login"
          >
            <div class="participant__heading">
              <HseHeading size="h4">{{
                `${$t("fields.name.speakers")} №${index + 1}`
              }}</HseHeading>
            </div>
            <HseSpace size="small" vertical />
            <div>
              <ReadonlyInput
                :label="$t('fields.name.lName')"
                :value="speaker.lastName"
              />
              <ReadonlyInput
                :label="$t('fields.name.fName')"
                :value="speaker.firstName"
              />
              <ReadonlyInput
                v-if="speaker.secondName"
                :label="$t('fields.name.mName')"
                :value="speaker.secondName"
              />
              <ReadonlyInput
                :label="$t('fields.name.email')"
                :value="speaker.email"
              />
              <ReadonlyInput
                :label="$t('fields.name.companyFull')"
                :value="speaker.organizationName"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="data.members && data.members.length">
      <HseSpace size="small" vertical />
      <div
        :style="{ background: '#6B7A99', padding: '6px' }"
        class="accordion__head"
      >
        <HseButton
          variant="tertiaryInvert"
          size="medium"
          v-on:click="isMemberBlockOpen = !isMemberBlockOpen"
        >
          {{ $t("memberBlock") }}
          <span class="ident" v-show="isMemberBlockOpen">&#9650;</span>
          <span class="ident" v-show="!isMemberBlockOpen">&#9660;</span>
        </HseButton>
        <div
          class="accordion__info"
          v-show="!(isMemberBlockOpen && data.members.length)"
        >
          {{ $t("memberInfo") }}
        </div>
      </div>
      <div
        v-show="isMemberBlockOpen"
        :style="{ background: '#F0F2F5', padding: '6px' }"
      >
        <div class="accordion">
          <div
            class="participant"
            v-for="(member, index) in data.members"
            :key="member.login"
          >
            <div class="participant__heading">
              <HseHeading size="h4">{{
                `${$t("fields.name.members")} №${index + 1}`
              }}</HseHeading>
            </div>
            <HseSpace size="small" vertical />
            <div>
              <ReadonlyInput
                :label="$t('fields.name.lName')"
                :value="member.lastName"
              />
              <ReadonlyInput
                :label="$t('fields.name.fName')"
                :value="member.firstName"
              />
              <ReadonlyInput
                v-if="member.secondName"
                :label="$t('fields.name.mName')"
                :value="member.secondName"
              />
              <ReadonlyInput
                :label="$t('fields.name.email')"
                :value="member.email"
              />
              <ReadonlyInput
                :label="$t('fields.name.companyFull')"
                :value="member.organizationName"
              />
            </div>
          </div>
        </div>
      </div>
      <HseSpace size="small" vertical />
    </div>

    <HseSpace size="medium" vertical />
    <div class="actions">
      <HseLink variant="secondary" @click="back">
        {{ $t("back") }}
      </HseLink>
    </div>
  </div>
</template>

<script>
import {
  HseHeading,
  HseSpace,
  HseButton,
  HseLink,
  HseTabs,
  IconActionPlus,
} from "@hse-design/vue";
import ReadonlyInput from "../common/ReadonlyInput.vue";
import { mapState } from "vuex";
import { api } from "../../api";
import { dateFormatter } from "../common/dateFormatter";

export default {
  name: "MyEventReadOnly",
  components: {
    HseHeading,
    HseSpace,
    HseButton,
    HseLink,
    HseTabs,
    ReadonlyInput,
  },
  data: () => ({
    plusIcon: IconActionPlus,
    isModeratorBlockOpen: true,
    isSpeakerBlockOpen: true,
    isMemberBlockOpen: true,
    isMemberModalOpen: false,
    members: [],
    editableMember: null,
    eventId: "",
    data: {},
    currentTab: "1",
    tabs: [
      {
        value: "1",
        label: "Содержательная информация",
      },
      {
        value: "2",
        label: "Организационная информация",
        disabled: false,
      },
    ],
  }),
  created() {
    if (this.$route.query && this.$route.params.id) {
      this.eventId = this.$route.params.id;
    }
    this.getData();
    if (
      this.data.roundTableStatusId === "598FA3AE-2FBB-4703-8B75-02735E758EEA" ||
      this.data.roundTableStatusId === "EF7995A1-DA68-4A79-AE54-0BBFCA044F37" ||
      this.data.roundTableStatusId === "28AE374D-D74C-4F4B-857C-E4E5BF0FF90A" ||
      this.data.roundTableStatusId === "C08814A5-7780-4595-9A31-AF51383E3933"
    ) {
      this.tabs[1].disabled = false;
    }
  },
  methods: {
    back() {
      this.$router.push("/my-events");
    },
    getData() {
      api
        .request("get_rt_events_data", {
          rtEventId: this.eventId,
          lang: this.lang,
        })
        .then((data) => {
          this.data = data;
        });
    },
    formatDate(date) {
      return dateFormatter.format(date, "date");
    },
  },
  computed: {
    langValue() {
      let val = [];

      if (this.data.langRu) {
        val.push(this.$i18n.tc("fields.roundTable.langRu"));
      }
      if (this.data.langEn) {
        val.push(this.$i18n.tc("fields.roundTable.langEn"));
      }
      if (this.data.LangOther) {
        val.push(this.data.Other);
      }

      return val.join(", ");
    },
    ...mapState(["lang"]),
  },
  watch: {
    lang() {
      this.getData();
    },
  },
  i18n: {
    messages: {
      en: {
        title: "notranslation",
        moderatorBlock: "Moderators",
        speakerBlock: "Experts",
        memberBlock: "Participants",
        back: "Back",
        submit: "Save a request",
        draft: "Save a draft",
        addParticipant: "Add",
        QRCode: "Send QR code",
        Approve: "Approve all",
        personal: "Request Initiator",
        speaker: "Speaker",
        AnnotationTextHseTooltip: "The volume is not less than 1000 characters",
        honoraryReportHint:
          "Почетный доклад. Подсказка: – доклад российского или иностранного гостя конференции с высоким показателем научного и/или экспертного рейтинга. Тематика почетного доклада, как правило, включает общие вопросы развития экономики и общества. Почетный доклад – всегда самостоятельное мероприятие.",
        invitedReportHint:
          "Приглашённый доклад. Подсказка: – доклад, представляемый российским или иностранным гостем конференции с высоким показателем научного и/или экспертного рейтинга. Тема приглашенного доклада может быть одной из тем сессии и/или круглого стола.",
        responsibleEvent: "Ответственный за мероприятие",
        plannedEventFormat: "Planned format of the event",
        eventFormat: "Format of the event",
        moderatorInfo:
          "The moderator details are captured in the system using their email address, whether it's their own or that of their accompanying person. This is essential for creating a personalized record for the moderator/speaker and for facilitating subsequent organizational tasks, such as registration and pass issuance. We will not use these emails for sending automated notifications. If you have any questions, please feel free to contact the Organizing Committee at YasinConference@hse.ru.",
        speakerInfo:
          "The speaker's details are captured in the system using their email address, whether it's their own or that of their accompanying person. This is essential for creating a personalized record for the moderator/speaker and for facilitating subsequent organizational tasks, such as registration and pass issuance. We will not use these emails for sending automated notifications. If you have any questions, please feel free to contact the Organizing Committee at YasinConference@hse.ru.",
        memberInfo:
          "Participant details are entered in line with the pre-approved application during the organizational preparation of the event.",
      },
      ru: {
        title: "Заявка на проведение экспертного круглого стола",
        moderatorBlock: "Модераторы",
        speakerBlock: "Эксперты-спикеры",
        memberBlock: "Участники",
        back: "Назад",
        submit: "Сохранить заявку",
        draft: "Сохранить черновик",
        addParticipant: "Добавить",
        QRCode: "Отправить QR код",
        Approve: "Одобрить всех",
        personal: "Инициатор заявки",
        speaker: "Докладчик",
        initiatorApplicationTooltip:
          "сотрудник НИУ ВШЭ, который заполняет заявку на проведение специального мероприятия ЯМНК и направляет ее на рассмотрение в Программный комитет",
        coordinatingHeadTooltip:
          "руководитель НИУ ВШЭ (уровня первого проректора/проректора/декана/члена ПК), который берет на себя ответственность за инициируемое мероприятие в рамках ЯМНК",
        AnnotationTextHseTooltip: "Объем не менее 1000 знаков",
        honoraryReportHint:
          "Почетный доклад. Подсказка: – доклад российского или иностранного гостя конференции с высоким показателем научного и/или экспертного рейтинга. Тематика почетного доклада, как правило, включает общие вопросы развития экономики и общества. Почетный доклад – всегда самостоятельное мероприятие.",
        invitedReportHint:
          "Приглашённый доклад. Подсказка: – доклад, представляемый российским или иностранным гостем конференции с высоким показателем научного и/или экспертного рейтинга. Тема приглашенного доклада может быть одной из тем сессии и/или круглого стола.",
        responsibleEvent: "Ответственный за мероприятие",
        plannedEventFormat: "Планируемый формат мероприятия",
        eventFormat: "Формат мероприятия",
        moderatorInfo:
          "Данные модератора фиксируются в системе через электронный адрес (свой или сопровождающего). Это необходимо для формирования индивидуальной записи модератора/спикера и для дальнейшей организационной работы, в т.ч. для регистрации и оформления пропуска. Автоматическая рассылка писем не предполагается. В случае вопросов просим обращаться в Организационный комитет конференции: YasinConference@hse.ru",
        speakerInfo:
          "Данные спикера фиксируются в системе через электронный адрес (свой или сопровождающего). Это необходимо для формирования индивидуальной записи модератора/спикера и для дальнейшей организационной работы, в т.ч. для регистрации и оформления пропуска. Автоматическая рассылка писем не предполагается. В случае вопросов просим обращаться в Организационный комитет конференции: YasinConference@hse.ru",
        memberInfo:
          "Информация об участниках заполняется по уже утверждённой заявке, на момент организационной подготовки мероприятия",
      },
    },
  },
};
</script>

<style scoped>
.form {
  width: 800px;
  margin: 52px auto;
  background: #ffffff;
  border-radius: 12px;
  padding: 32px;
  max-width: calc(100vw - 320px);
}

.two-cols,
.three-cols {
  display: flex;
  margin: 0 -15px;
}

.two-cols > .field,
.three-cols > .field {
  padding: 0 15px;
  flex: 1;
}

.three-cols .field {
  width: 33.3%;
}

.ident {
  margin-left: 10px;
}

@media (max-width: 767px) {
  .form {
    /* max-width: unset; */
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
  }

  .two-cols,
  .three-cols {
    flex-direction: column;
  }

  .two-cols > .field,
  .three-cols > .field {
    width: 100%;
  }
}

.actions {
  flex-wrap: wrap;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.accordion {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
}

.accordion__head {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.accordion__info {
  font-size: 14px;
  line-height: 1.6em;
  color: #fff;
  padding: 8px 24px;
}

.participant__heading {
  display: flex;
  align-items: center;
  gap: 5px;
}

.participant__heading .icons {
  display: flex;
  gap: 3px;
}

.participant__heading .icons > svg {
  cursor: pointer;
}

.headingWithTooltip {
  display: flex;
}

.headingWithTooltip > div:first-child {
  padding: 2px 0 0 0;
}

.headingWithTooltip > div:last-child {
  padding: 0px 0 0 12px;
}

.subheading {
  font-family: "Work Sans", sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #0f0f14;
}
</style>
