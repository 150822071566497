<template>
  <div class="visa-card">
    <HseHeading size="h3">{{ $t('caption') }}</HseHeading>
    <HseSpace size='small' vertical />

    <div class="messages" v-if="isDataLoaded && messages && messages.length">
      <div v-for="value in messages" v-bind:key="value.Id" v-on:click="openMessage(value.Id)" class="message"
        :class="{ new: !value.Opened }">
        <div class="flex">
          <div class="cols-2">
            <HseIcon v-if="value.IncomingForUser == 1" :icon="IconActionArrowBottom" />
            <HseIcon v-else :icon="IconActionArrowTop" />
            <!--HseIcon v-if="value.Opened!=1" :icon="IconGlobalEmail" />
            <div v-else></div!-->
          </div>


          <div>{{ value.EventTitle }}</div>
          <HseSpace size='small' horizontal />
          <div>{{ dateFormat(value.CreatedOn) }}</div>
          <HseSpace size='small' horizontal />
          <HseIcon v-if="value.MessageAttachment != null" :icon="IconActionAttach" />
        </div>
        <div class="flex">
          <div class="cols-2"></div>
          <div v-if="value.Opened != 1" class="subject"><b>{{ value.MessageSubject }}</b></div>
          <div v-else class="subject">{{ value.MessageSubject }}</div>
        </div>
        <div class="flex">
          <div class="cols-2"></div>
          <div class="body">{{ getMessageBody(value) }}</div>
        </div>
      </div>
    </div>
    <div v-else-if="!isDataLoaded">
      <Loading />
    </div>
    <div style="text-align: center;" v-else>
      {{ $t('noData') }}
    </div>


    <HseSpace size='medium' vertical />
    <div class="form-footer">
      <div class="actions">
        <HseButton v-on:click="editMessage('new')">
          {{ $t('newMessage') }}
        </HseButton>
      </div>
    </div>

  </div>
</template>

<script>
import { HseHeading, HseSpace, HseButton, IconActionAttach, IconActionArrowBottom, IconActionArrowTop, HseIcon } from '@hse-design/vue'
import { api } from '../api'
import { mapState } from 'vuex'
import Loading from '../components/common/Loading.vue'
import { dateFormatter } from '../components/common/dateFormatter'

export default {
  components: {
    HseHeading,
    HseSpace,
    HseButton,
    HseIcon,
    Loading,
  },
  data: () => ({
    messages: [],
    isDataLoaded: false,
    IconActionArrowBottom,
    IconActionArrowTop,
    IconActionAttach,
    msgtext: "Удалить из экспертов?",
    selected_expert: null,
    selected_event: null,
  }),
  created() {
    this.load();
  },
  computed: {
    ...mapState(['lang'])
  },
  methods: {
    dateFormat(date) { return dateFormatter.format(date) },
    load() {
      this.isDataLoaded = false
      api.request('messageList', {}).then((res) => {
        this.messages = res;
        this.isDataLoaded = true;
      })
    },
    openMessage(message_id) {
      this.$router.push(`/messages/${message_id}`);
    },
    editMessage(message_id) {
      this.$router.push(`/messages/${message_id}/new/`);
    },
    getMessageBody(msg) {
      return msg.MessageBody.length > 100 ? `${msg.MessageBody.substr(0, 100)}...` : msg.MessageBody
    }
  },
  i18n: {
    messages: {
      en: {
        caption: 'Notifications',
        newMessage: 'Write message',
        noData: 'No messages to show',
      },
      ru: {
        caption: 'Уведомления',
        newMessage: 'Написать сообщение',
        noData: 'Нет сообщений',
      },
    }
  }
}
</script>

<style scoped>
.visa-card {
  background: #FFFFFF;
  border-radius: 12px;
  margin: 53px auto;
  padding: 32px 32px;
  max-width: calc(100vw - 320px)
}

.actions .hse-Button {
  display: inline-block;
}

.hse-Button:first-child {
  margin-right: 12px;
}

.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.form-description {
  padding-top: 12px;
  font-size: 14px;
  line-height: 20px;
  color: #6B7A99;
}

.message {
  padding: 12px;
}

.message.new {
  background: #EEEFF4;
}

.message:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.flex {
  display: flex;
}

.cols-2 {
  display: flex;
  align-items: center;
  justify-items: space-around;
  width: 50px;
}

.subject {
  font-size: 20px;
  font-weight: 200;
  line-height: 24px;
}

.body {
  font-size: 12px;
  font-weight: 200;
  line-height: 24px;
}

@media (max-width: 1023px) {
  .visa-card {
    margin: 16px auto;
    max-width: 95%;
  }
}

@media (max-width: 767px) {
  .visa-card {
    margin: 0 auto;
    max-width: 100%;
  }

  .form-footer {
    flex-direction: column;
    align-items: flex-start;
  }
}</style>
