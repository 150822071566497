<template>
  <div>
    <HseModalForm v-model="isOpen" :title="$t('select')" :modal-style="{ maxHeight: '800px' }">
      <template #actions>
        <HseButton :spinner="isLoading" @click="send">
          {{$t('send')}}
        </HseButton>
      </template>
      
      <div class='big-item'>
        <Validator v-bind:field="fields.ExpertSelect" />
        <HseSpace size='small_2x' vertical />
      </div>

    </HseModalForm>
  </div>
</template>

<script>

  import { HseModalForm, HseButton, HseSpace } from '@hse-design/vue' 
  import Validator from '../common/form/Validator.vue'
  import fields from './managerFields'
  import { mapState } from 'vuex'
  import { localizeFields } from '../../i18n'

  export default {
    props: ['request_id'],
    components: {
      HseModalForm,
      HseButton,
      HseSpace,
      Validator,
    },
    data: () => ({
      isOpen: false,
      isLoading: false,
      fields,
    }),
    created () {
      this.fields.ExpertSelect.setRequestId(this.request_id)
      this.translateFields()
      this.$parent.$on('openExpertsModal', () => {this.isOpen = true})
    },
    methods: {
      send() {
        this.isLoading = true;
        this.$emit('openExpertsModalResponse', this.fields.ExpertSelect.value)

        setTimeout(() => {
          this.isOpen = false;
          this.isLoading = false;
        }, 200);
      },
      translateFields () {
        this.fields = localizeFields(fields, this.fields)
      }
    },
    computed: mapState(['lang']),
    watch: {
      lang () {
        this.translateFields()
      },
    },   
    i18n: {
      messages: {
        en: {
          send: 'Send',
          select: 'Select an expert',
        },
        ru: {
          send: 'Отправить',
          select: 'Выберите эксперта',
        },
      }
    } 
  }
</script>

<style scoped>
  .event-card {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 32px;
    max-width: 800px;
    margin: 52px auto;
  }
  .big-item {
    min-height: 400px;
  }
  .link {
    white-space: nowrap;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  .two-cols {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .three-cols {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
  }
  .prepull{
    overflow:auto;
  }
  .pull-right {
    float:right;
  }
</style>