<template>
  <div class="form">
    <div class="subheading">{{ $t('participants') }}</div>
    <HseSpace size="small" vertical />
    <div class="participants">
      <div class="members">
        <div class="member" v-for="(participant) in participants" :key="participant.login">
          <div>{{ getParticipantName(participant) }}</div>
          <div class="icons">
            <svg @click="() => removeParticipant(participant)" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6663 5.164L11.7263 4.224L7.99967 7.95067L4.27301 4.224L3.33301 5.164L7.05967 8.89067L3.33301 12.6173L4.27301 13.5573L7.99967 9.83067L11.7263 13.5573L12.6663 12.6173L8.93967 8.89067L12.6663 5.164Z" fill="#6B7A99"/>
            </svg>
          </div>
        </div>
      </div>
      <div class="add-member" @click="checkMaxParticipants">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21 11.5H13V3.5H11V11.5H3V13.5H11V21.5H13V13.5H21V11.5Z" fill="#0050CF"/>
        </svg>
        <span>{{ $t('addParticipant') }}</span>
      </div>
    </div>
    <HseModal v-model="isModalOpen" :shouldCloseOnOverlayClick="false">
      <ParticipantForm
        v-if="isModalOpen"
        :added="participants"
        @saved="onParticipantSave"
      />
    </HseModal>
    <SubmitModal />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { store } from '../../store'
  import { HseSpace, HseModal } from '@hse-design/vue'
  import ParticipantForm from './ParticipantForm.vue'
  import SubmitModal from '../common/modal/SubmitModal.vue'

  export default {
    name: 'FormPage4',
    store,
    props: ['maxParticipants'],
    data: () => ({
      isModalOpen: false,
      participants: [],
    }),
    created () {
      this.$parent.$on('validatePage', ({ current }) => {
        if (current === 4) {
          this.checkPageValidity()
        }
      })
    },
    components: {
      HseSpace,
      HseModal,
      ParticipantForm,
      SubmitModal,
    },
    methods: {
      checkPageValidity () {
        if (this.participants.length > 0) {
          this.$emit('validatePage', { page: 4, isValid: true })
        } else {
          this.$root.$refs.submitModal.open(this.$i18n.tc('noParticipants'))
        }
      },
      checkMaxParticipants () {
        if (this.participants.length < this.maxParticipants) {
          this.isModalOpen = true
        } else {
          this.$root.$refs.submitModal.open(this.$i18n.tc('maxParticipants'))
        }
      },
      onParticipantSave (result) {
        this.participants.push(result)
        this.$emit('updateParticipants', this.participants)
        this.isModalOpen = false
      },
      getParticipantName (participant) {
        const hasData = participant && participant.data && participant.data.public
        if (!hasData) return ''

        return this.lang === 'ru' 
          ? `${participant.data.public.LName} ${participant.data.public.FName} ${participant.data.public.SName}`
          : `${participant.data.public.LNameEN} ${participant.data.public.FNameEN}`
      },
      removeParticipant ({ id }) {
        this.participants = this.participants.filter(participant => participant.id !== id)
        this.$emit('updateParticipants', this.participants)
      }
    },
    computed: {
      ...mapState(['lang']),
    },
    i18n: {
      messages: {
        en: {
          addParticipant: 'Add a participant',
          participants: 'Add participants',
          maxParticipants: 'You added maximum participants. Adding new participants is impossible',
          noParticipants: 'You haven`t added any participants',
        },
        ru: {
          addParticipant: 'Добавить участника',
          participants: 'Добавить участников',
          maxParticipants: 'Вы добавили максимальное количество участников. Добавление новых участников невозможно',
          noParticipants: 'Вы не добавили ни одного участника',
        },
      }
    },
  }
</script>

<style scoped>
  .form {
    width: 670px;
  }
  .add-member {
    background: #F0F5FF;
    border-radius: 4px;
    border: 1px solid #8EB2EC;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #0050CF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 24px 8px 16px;
    width: fit-content;
    cursor: pointer;
  }
  .add-member svg {
    margin-right: 11px;
  }
  .members {
    width: fit-content
  }
  .member {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    color: #6B7A99;
    padding: 10px 0;
    margin-bottom: 12px;
    justify-content: space-between;
  }
  .member:last-child {
    margin-bottom: 17px;
  }
  .member .icons {
    margin-left: 20px;
  }
  .member .icons>svg {
    cursor: pointer;
    margin: 0 3px;
  }
</style>
<style>
  .hse-BaseModal {
    width: 690px !important;
    max-height: 100%;
  }
</style>