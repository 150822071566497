import { store } from "../store";
import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: store.state.lang,
  messages: {
    en: {
      save: "Save",
      saving: "Saving",
      cancel: "Сancel",
      yes: "Yes",
      no: "No",
      back: "Back",
      next: "Next",
      prev: "Prev",
      auth: {
        login: "Sign in using password",
        warning: "If you haven't registered in the HSE University Events System yet, please click Registration to create your personal account. If you already have a personal account, you can log in using any of the provided authorization methods.",
        adfs: "Sign in using HSE University personal account",
        code: "Access by requesting a code",
        register: "Registration",
        forgot: "Forgot password?",
        btnDone: "Done!",
        forgotBtn: "Restore",
        forgotBtnLoading: "Restoring...",
        forgotResult: "We sent you an email with restore password inctructions",
      },
      event: {
        format: "Format",
        announcement: "Announcement",
        type: "Event type",
        typeOfEvent: "Event type",
        location: "Location",
        startDate: "Start",
        endDate: "End",
        password: "Password",
        date: "Date of validity",
      },
      fields: {
        requiredFields: "* — required fields",
        name: {
          password: "Password",
          fName: "First name",
          lName: "Last name",
          mName: "Middle name",
          fNameEn: "First name (en)",
          lNameEn: "Last name (en)",
          moderators: "Moderator",
          experts: "Expert",
          members: "Member",
          speakers: "Speaker",
          disscussants: "Disscussant",
          gender: "Gender",
          birthYear: "Birth year",
          phone: "Phone",
          email: "E-mail",
          citizen: "Citizenship",
          language: "Language",
          interfaceLanguage: "Interface language",
          country: "Country of residence",
          citizenship: "Citizenship",
          region: "Region",
          city: "City",
          position: "Position",
          companyFull: "Company full name",
          companyShort: "Company short name",
          companyFullEn: "Company full name (en)",
          companyShortEn: "Company short name (en)",
          Job: "Job",
          affilated: "Affilated with HSE",
          pdAgreement: "I agree to the processing of my personal data",
          marketingAgreement: "I want to receive information from HSE",
          event: "Event",
          expert_topic1: "Main topic",
          expert_topic2: "Additional topic 1",
          expert_topic3: "Additional topic 2",
          expert_topic: "Topic",
          status: "Conclusion",
        },
        rule: {
          empty: "Please fill this field",
          regError: "Registration end date cannot be later than the event date",
          cyrillic: "Please fill this field with cyrillic symbols",
          latin: "PLease fill this field with latin symbols",
          cyrillicTitle: "Please fill this field with cyrillic symbols",
          latinTitle: "PLease fill this field with latin symbols",
          phone: "Only digits, +, -, ( and ) are allowed",
          email: "Please enter correct e-mail",
          custom: "Incorrect value",
          pdAgreement: "Agreement is required",
          offerAgreement: "Agreement is required",
          userNotFound: "User not found",
          incorrectPassword: "Incorrect password",
          needYourApprove: "Approval is required",
          alreadyRegistered: "This email is already registered",
          noSuchRecord: "This email is not registered",
          number: "Only digits",
          date: "Incorrect date",
          password: "Field value includes incorrect password character",
          maxParticipants:
            "The maximum number of participants must be greater than 0",
        },
        placeholder: {
          loading: "Loading list...",
          noData: "No data",
          search: "Search",
          citizen:
            "In case of multiple citizenships, please, specify any non-Russian one",
          companyCountry: "Country of the company, that you represent",
          special: "Email notifications about April conference",
          jobDirection: "Your occupation at this organization",
        },
        visa: {
          lName: "Last name",
          fName: "First name",
          mName: "Middle name",
          request: "Application for participation in the event",
          homeAddress: "Home address",
          workAddress: "Work address",
          birthDate: "Date of birth",
          birthCountry: "Place of birth (country)",
          birthCity: "Place of birth (city)",
          workPlace: "Place of work",
          passportId: "Passport ID",
          issuingAuthority: "Issuing authority",
          passportDateIssue: "Date of issue",
          passportDateExpire: "Date of expire",
          consulateCity:
            "Consulate of the Russian Federation (city where visa documents will be submitted):",
          countryObtain:
            "Country in which documents for a Russian visa will be submitted:",
          durationFrom: "Stay in the Russian Federation from",
          durationTo: "Stay in the Russian Federation to",
          visitCities: "Cities to visit in Russian Federation",
          passportScan: "Passport scan",
        },
        request: {
          isParticipant: "I am a participant/speaker",
          degree: "Degree",
          occupationType: "Occupation type",
          belongsToHSE: "Belongs to HSE",
          faculty: "Faculty",
          campus: "Campus",
          isProfessor: "Are you a professor?",
          IsHSEPresenter: "Do you represent HSE?",
          status: "Status",
          isHSEPresenter3rd: "Does coauthor represent HSE?",
          occupationForm: "Employment form",
          departmentEn: "Department",
          jobDirection: "Your occupation at this organization",
          companyCountry: "Country of the company",
          companyRegion: "Region of the company",
          positionType: "Position type",
          degreeLevel: "Educational level",
          reportExists: "With report",
          thesesFileID: "Extended report's annotation (ru)",
          thesesFileEnID: "Extended report's annotation (en)",
          studentFileID:
            "Attach a scan or photo of a document confirming your student status",
          title: "Paper title (ru)",
          titleEn: "Paper title (en)",
          reportLang: "Language of the report",
          direction1: "Topic 1",
          direction2: "Topic 2",
          isExclusive:
            "This is to confirm that the uploaded report has not been submitted for review to take part in the events associated with XXIII April International Academic Conference on Economic and Social Development",
          confirm: "Participation confirmation",
          presentationFile: "Presentation file",
          paymentTitle: "Promocode",
          confirmationDocument: "Confirmation document",
        },
        coauthor: {
          willCome: "Will come to the conference",
          alreadyAdded: "This coauthor is already added",
        },
        collective: {
          isModerator: "I am a moderator",
          direction: "Topic",
          sessionNameEn: "Session name (en)",
          sessionName: "Session name",
          alreadyAdded: "This participant is already added",
          userNotFound:
            "User with such email is not registered. Only registered useres can be participants of collective application",
        },
        roundTable: {
          RTname: "Name of roundtable",
          RTnameEn: "Name of roundtable (en)",
          PlannedName: "Planned name of the event",
          PlannedNameEn: "Planned name of the event (en)",
          responsibleFio: "Full name of persons responsible for event",
          discussionQuestions: "Issues for discussion",
          discussionQuestionsEn: "Issues for discussion (en)",
          date: "Planned date",
          programSessionTimeId: "Planned time slot",
          eventFormat: "Event format",
          workingLang: "Working language for roundtable",
          langRu: "Russian",
          langEn: "English",
          langOther: "Other",
          contactPerson: "Name of contact person",
          contactPersonEmail: "e-mail of the contact person",
          comment: "Comments",
          involvedBusinessOrg:
            "If you plan to invite representatives from business, government, international organizations, etc., as partners, please list them",
          involvedInfoOrg:
            "If you plan to invite information partners for your event, please list them",
          syncTranslation: "Simultaneous translation",
          needOnlineSupport: "Do you require an online assistant?",
          needOfficialPhoto:
            "Do you require official photography of the event?",
          needOfficialVideo: "Do you require a video recording of the event?",
          needOnlineTranslation: "Will the event be broadcast?",
          maxParticipants:
            "Maximum number of participants present face-to-face",
          endRegistrationDate:
            "Deadline for event registration for academic programme participants",
          needVolunteerSupport:
            "Do you require the assistance of volunteers in organizing the roundtable discussion?",
          volunteerSupportBlocksId:
            "Please specify areas where assistance may be required",
          guestNavigation:
            "Meeting and accompanying Russian and foreign key speakers",
          auditoryHelp: "Navigation (including from the parking lot)",
          organizationHelp:
            "Providing assistance in the conference hall (placing signs, fulfilling requests of the initiators, seating guests, laying out materials, etc.)",
          massMediaId: "Media",
          publicityId: "Publicity",
          participationStatusId: "Status of participation request",
          statusRt: "Status round table",
        },
        message: {
          subject: "Message subject",
          body: "Message body",
          attachment: "Upload attachment",
        },
        manager: {
          expert: "Expert",
          status: "Status",
          expert_afreed: "Expert consent received",
          score: "Final score",
          filter: "Filter",
          finalDirection: "Final direction",
        },
        expert: {
          Novelty: "Novelty",
          Actuality: "Relevance",
          Depth: "Depth",
          TotalResult: "Review",
          TotalResultTitle: "Conclusion",
          Comment: "Comments",
        },
        specialEvents: {
          date: "Date",
          programSessionTimeId: "Time",
        },
        honoraryOrInvitedReport: {
          affiliation: "Affiliation",
          secretary: "Secretary",
          titleReportRu: "Title of the report (rus)",
          titleReportEng: "Title of the report (eng)",
          abstractTextRu: "Abstract text (rus)",
          abstractTextEng: "Abstract text (eng)",
          imageFileId: "Photo size 200*200",
          post: "Post",
          division: "Division",
          email: "E-mail",
          date: "Preferred date of presentation of the report",
          programSessionTimeId: "Preferred time of presentation of the report",
          speakerCv:
            "Резюме (CV) докладчика (на русском или на английском языке)",
          speakerStatus: "Status of the speaker",
          speakerLName: "Last name of the speaker (rus)",
          speakerLNameEn: "Last name of the speaker (eng)",
          speakerFName: "Speaker's name (rus)",
          speakerFNameEn: "Speaker's name (eng)",
          speakerSName: "Patronymic of the speaker",
          coordinatingLeader: "Coordinating Head",
          coordinatingLeaderFio: "ФИО",
          textAnnotation: "Abstract text (rus)",
          textAnnotationEn: "Abstract text (eng)",
          rationaleInvitation: "Justification of the invitation",
          speakerAffiliation: "Speaker's affiliation (rus)",
          speakerAffiliationEn: "Speaker's affiliation (eng)",
          speakerCountry: "Country of organization",
          speakerNationality: "Speaker nationality",
          speakerPassport: "Copy of speaker's passport",
          passport: "Copy of passport",
          speakerPosition: "Professor position (rus)",
          speakerPositionEn: "Professor position (eng)",
          plannedTopicOfTheReport: "Planned topic of the report (rus)",
          plannedTopicOfTheReportEn: "Planned topic of the report (eng)",
          comment: "Comment",
          secretaryOffline: "Secretary (offline)",
          secretaryOnline: "Secretary (online)",
          status: {
            invitedReport: "Invited report",
            honoraryReport: "Honorary report",
          },
        },
      },
    },

    ru: {
      save: "Сохранить",
      saving: "Сохранение",
      cancel: "Отмена",
      yes: "Да",
      no: "Нет",
      next: "Далее",
      prev: "Назад",
      back: "Назад",
      auth: {
        login: "Войти с паролем",
        warning: 'Если Вы ранее не создавали личный кабинет в системе мероприятий НИУ ВШЭ, то просим создать его, нажав кнопку "Зарегистрироваться". Для входа в ранее созданный личный кабинет Вы можете воспользоваться любым из предложенных способов авторизации.',
        adfs: "Войти через профиль НИУ ВШЭ",
        code: "Войти по коду",
        register: "Зарегистрироваться",
        forgot: "Забыли пароль?",
        btnDone: "Готово!",
        forgotBtn: "Восстановить",
        forgotBtnLoading: "Восстанавливаем...",
        forgotResult:
          "Мы выслали инструкции по восстановлению пароля на указанную вами почту",
      },
      event: {
        format: "Формат",
        announcement: "Объявление",
        type: "Тип мероприятия",
        typeOfEvent: "Вид мероприятия",
        location: "Место проведения",
        startDate: "Начало",
        endDate: "Окончание",
        password: "Пароль",
        date: "Дата действия",
      },
      fields: {
        requiredFields: "* — поля, обязательные для заполнения",
        name: {
          password: "Пароль",
          fName: "Имя",
          lName: "Фамилия",
          mName: "Отчество",
          fNameEn: "Имя (en)",
          lNameEn: "Фамилия (en)",
          moderators: "Модератор",
          experts: "Эксперт",
          speakers: "Спикер",
          members: "Участник",
          disscussants: "Дискуссант",
          gender: "Пол",
          birthYear: "Год рождения",
          phone: "Телефон",
          email: "E-mail",
          citizen: "Гражданство",
          language: "Язык общения",
          interfaceLanguage: "Язык интерфейса",
          country: "Страна проживания",
          citizenship: "Гражданство",
          region: "Регион",
          city: "Город",
          position: "Должность",
          companyFull: "Полное название организации",
          companyShort: "Краткое название организации",
          companyFullEn: "Полное название организации на английском",
          companyShortEn: "Краткое название организации на английском",
          Job: "Место работы",
          affilated: "Относится к НИУ ВШЭ",
          pdAgreement: "Я согласен на обработку моих персональных данных",
          marketingAgreement: "Я хочу получать информацию от НИУ ВШЭ",
          event: "Мероприятие",
          expert_topic1: "Основное направление",
          expert_topic2: "Доп. направление 1",
          expert_topic3: "Доп. направление 2",
          expert_topic: "Направление",
          status: "Заключение",
          numberParticipants:
            "Максимальное количество участников в очном формате",
        },
        rule: {
          empty: "Пожалуйста, заполните поле",
          regError:
            "дата окончания регистрации не может быть больше даты проведения мероприятия",
          cyrillic: "Пожалуйста, заполните поле кириллицей",
          latin: "Пожалуйста, заполните поле латиницей",
          cyrillicTitle: "Пожалуйста, заполните поле кириллицей",
          latinTitle: "Пожалуйста, заполните поле латиницей",
          phone: "Допустимы цифры, +, (, ) и -",
          email: "Введите корректный емейл",
          custom: "Некорректно заполнено поле",
          pdAgreement: "Необходимо ваше согласие",
          offerAgreement: "Необходимо ваше согласие",
          userNotFound: "Пользователь не найден",
          incorrectPassword: "Некорректный пароль",
          needYourApprove: "Необходимо ваше подтверждение",
          alreadyRegistered: "Этот емейл уже зарегистрирован",
          noSuchRecord: "Этот емейл не зарегистрирован",
          number: "Введите числовое значение",
          date: "Некорректная дата",
          password: "Введённый пароль содержит недопустимые для пароля символы",
          maxParticipants:
            "Максимальное кол-во участников должно быть больше 0",
        },
        placeholder: {
          loading: "Загружаем список...",
          noData: "Нет данных",
          search: "Поиск",
          citizen: "В случае нескольких гражданств, укажите любое нероссийское",
          companyCountry:
            "Страна, где расположена организация, которую Вы представляете",
          jobDirection: "Направление Вашей деятельности в организации",
          special: "Отправка уведомлений по email по Апрельской конференции",
        },
        visa: {
          lName: "Фамилия",
          fName: "Имя",
          mName: "Отчество",
          request: "Заявка на участие в мероприятии",
          homeAddress: "Домашний адрес",
          workAddress: "Рабочий адрес",
          birthDate: "Дата рождения",
          birthCountry: "Место рождения (страна)",
          birthCity: "Место рождения (город)",
          workPlace: "Место работы",
          passportId: "Номер паспорта",
          issuingAuthority: "Орган, выдавший документ",
          passportDateIssue: "Дата выдачи",
          passportDateExpire: "Действителен до",
          consulateCity:
            "Консульство РФ (город, в котором будут подаваться документы на визу):",
          countryObtain:
            "Страна, в которой будут подаваться документы на визу РФ:",
          durationFrom: "Пребывание в РФ с",
          durationTo: "Пребывание в РФ по",
          visitCities: "Города для посещения в РФ",
          passportScan: "Скан паспорта",
        },
        request: {
          isParticipant: "Я являюсь участником/докладчиком",
          degree: "Ученая степень",
          occupationType: "Основной тип занятости",
          belongsToHSE: "Принадлежность к ВШЭ",
          faculty: "Факультет",
          campus: "Кампус",
          isProfessor: "Являетесь ли Вы профессором?",
          IsHSEPresenter: "Представляете ли Вы НИУ ВШЭ?",
          status: "Статус",
          isHSEPresenter3rd: "Представляет НИУ ВШЭ?",
          occupationForm: "Форма занятости",
          departmentRu: "Подразделение",
          jobDirection: "Направление работы",
          companyCountry: "Страна",
          companyRegion: "Регион",
          positionType: "Основная категория занятости",
          degreeLevel: "Уровень получаемого образования",
          reportExists: "С докладом",
          thesesFileID: "Расширенная аннотация к докладу",
          thesesFileEnID: "Расширенная аннотация к докладу на английском языке",
          studentFileID:
            "Прикрепите скан или фото документа, подтверждающего ваш статус обучающегося",
          title: "Название доклада",
          titleEn: "Название доклада на английском языке",
          reportLang: "Язык выступления",
          direction1: "Направление 1",
          direction2: "Направление 2",
          isExclusive:
            "Настоящим подтверждаю, что представленный доклад не подан на рассмотрение для участия в ассоциированных мероприятиях Я(А)МНК",
          confirm: "Подтверждение участия",
          presentationFile: "Файл презентации",
          paymentTitle: "Промокод",
          confirmationDocument: "Подтверждающий документ",
        },
        collective: {
          isModerator: "Я являюсь модератором",
          direction: "Направление",
          sessionNameEn: "Название сессии (en)",
          sessionName: "Название сессии",
          alreadyAdded: "Этот участник уже добавлен",
          userNotFound:
            "Пользовать с таким email не зарегистрирован. Участником коллективной сессии может быть только зарегистрированный пользователь",
        },
        roundTable: {
          RTname: "Название мероприятия",
          RTnameEn: "Название мероприятия (en)",
          PlannedName: "Планируемое название мероприятия",
          PlannedNameEn: "Планируемое название мероприятия (en)",
          responsibleFio: "ФИО Ответственного за мероприятие",
          discussionQuestions: "Вопросы для обсуждения",
          discussionQuestionsEn: "Вопросы для обсуждения (en)",
          date: "Планируемая дата проведения",
          programSessionTimeId: "Планируемый временной слот",
          eventFormat: "Формат мероприятия",
          workingLang: "Рабочий язык круглого стола",
          langRu: "Русский",
          langEn: "Английский",
          langOther: "Другой",
          contactPerson: "ФИО контактного лица",
          contactPersonEmail: "e-mail контактного лица",
          comment: "Комментарии",
          involvedBusinessOrg:
            "Если планируете привлекать в качестве партнёров организации из сферы бизнеса, государственного сектора, международные организации, российские и/или зарубежные вузы, другое, пожалуйста, перечислите",
          involvedInfoOrg:
            "Если планируете привлекать информационных партнёров, пожалуйста, перечислите",
          syncTranslation: "Синхронный перевод",
          syncTranslationNew: "Требуется ли синхронный перевод?",
          needOnlineSupport: "Понадобится ли Вам помощь онлайн-ассистента?",
          needOfficialPhoto: "Понадобится ли Вам официальная фотосъемка?",
          needOfficialVideo: "Необходима ли запись видеозапись мероприятия?",
          needOnlineTranslation: "Необходима ли трансляция мероприятия?",
          maxParticipants: "Максимальное кол-во участников в офлайн-формате",
          endRegistrationDate:
            "Дата окончания регистрации на мероприятие для участников академической программы",
          needVolunteerSupport:
            "Понадобится ли помощь волонтеров в организации круглого стола?",
          volunteerSupportBlocksId: "На какие блоки?",
          guestNavigation:
            "Встреча и сопровождение российских и иностранных ключевых спикеров",
          auditoryHelp: "Навигация (в т.ч. с паркинга)",
          organizationHelp:
            "Помощь в зале (расстановка табличек, выполнение просьб инициаторов, рассадка гостей, раскладка материалов)",
          massMediaId: "СМИ",
          publicityId: "Публичность",
          participationStatusId: "Статус запроса на участия",
          statusRt: "Статус круглого стола",
        },
        coauthor: {
          willCome: "Собирается приехать на конференцию",
          alreadyAdded: "Этот соавтор уже добавлен",
        },
        message: {
          subject: "Тема сообщения",
          body: "Текст сообщения",
          attachment: "Загрузить вложение",
        },
        manager: {
          expert: "Эксперт",
          status: "Статус",
          expert_afreed: "Согласие эксперта получено",
          score: "Финальная оценка",
          filter: "Фильтр",
          search: "Поиск",
          finalDirection: "Передать в другое направление",
        },
        expert: {
          Novelty: "Новизна",
          Actuality: "Актуальность",
          Depth: "Глубина",
          TotalResult: "Оценка",
          TotalResultTitle: "Заключение",
          Comment: "Комментарии",
        },
        specialEvents: {
          date: "Дата проведения",
          programSessionTimeId: "Время проведения",
        },
        honoraryOrInvitedReport: {
          affiliation: "Аффилиация",
          secretary: "Секретарь",
          titleReportRu: "Название доклада (рус)",
          titleReportEng: "Название доклада (eng)",
          abstractTextRu: "Текст аннотации (рус)",
          abstractTextEng: "Текст аннотации (eng)",
          imageFileId: "Фото размером 200*200",
          post: "Должность",
          division: "Подразделение",
          email: "E-mail",
          date: "Предпочитаемая дата презентации доклада",
          programSessionTimeId: "Предпочитаемое время презентации доклада",
          speakerCv:
            "Резюме (CV) докладчика (на русском или на английском языке)",
          speakerStatus: "Статус докладчика",
          speakerLName: "Фамилия докладчика (рус)",
          speakerLNameEn: "Фамилия докладчика (eng)",
          speakerFName: "Имя докладчика (рус)",
          speakerFNameEn: "Имя докладчика (eng)",
          speakerSName: "Отчество докладчика",
          coordinatingLeader: "Координирующий руководитель",
          coordinatingLeaderFio: "ФИО",
          textAnnotation: "Текст аннотации (рус)",
          textAnnotationEn: "Текст аннотации (eng)",
          rationaleInvitation: "Обоснование приглашения",
          speakerAffiliation: "Аффилиация докладчика (рус)",
          speakerAffiliationEn: "Аффилиация докладчика (eng)",
          speakerCountry: "Страна организации",
          speakerNationality: "Гражданство докладчика",
          speakerPassport: "Копия паспорта докладчика",
          passport: "Копия паспорта",
          speakerPosition: "Должность докладчика (рус)",
          speakerPositionEn: "Должность докладчика (eng)",
          plannedTopicOfTheReport: "Планируемая тема доклада (рус)",
          plannedTopicOfTheReportEn: "Планируемая тема доклада (eng)",
          comment: "Комментарий",
          secretaryOffline: "Секретарь (очный)",
          secretaryOnline: "Секретарь (онлайн)",
          status: {
            invitedReport: "Приглашённый доклад",
            honoraryReport: "Почетный доклад",
          },
        },
      },
    },
  },
});

function localizeFields(rawFields, componentsFields) {
  const fields = {};
  Object.keys(rawFields).forEach((key) => {
    const field = { ...rawFields[key] };
    if (field.label) {
      if (!field.noPlaceholder) {
        field.placeholder = i18n.tc(
          field.placeholder ? field.placeholder : field.label
        );
      }
      field.label = `${i18n.tc(field.label)} ${field.isRequired ? "*" : ""}`;
    }
    if (field.searchable) {
      [
        { name: "loadingPlaceholder", path: "loading" },
        { name: "nothingFoundPlaceholder", path: "noData" },
        { name: "searchPlaceholder", path: "search" },
      ].forEach((placeholder) => {
        field[placeholder.name] = i18n.tc(
          `fields.placeholder.${placeholder.path}`
        );
      });
    }
    field.value = componentsFields[key].value;
    fields[key] = field;
  });
  return fields;
}

export { i18n, localizeFields };
