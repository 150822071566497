<template>
  <div ref="dropdownLangRef">
    <div class="select-container" v-on:click="toggleLanguages">
      <div class="select">
        <HseIcon :icon="currentItem.icon" size="medium"/>
        <div class="top-menu-text">{{currentItem.name}}</div>
      </div>
      <HseIcon
        :icon="arrow"
        :style="{ color: color() }"
        :class="{ 'icon-opened': isMenuOpen }"
        class="arrow-icon"
        size="large"
      />
    </div>
    <HseDropdownMenu
      :class="{ show: isMenuOpen }"
      class="language-menu"
    >
      <HseDropdownMenuItem
        v-for="item in items"
        :leftIcon="item.icon"
        :key="item.id"
        :classes="{}"
        :selected="item.id === selected"
        @click.native="() => selectItem(item.id)"
      >
        <span class="name">{{ item.name }}</span>
      </HseDropdownMenuItem>
    </HseDropdownMenu>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { store } from '../../../store'
  import { api } from '../../../api'
  import {
    HseDropdownMenu,
    HseDropdownMenuItem,
    HseIcon,
    IconLanguageRu,
    IconLanguageEn,
    IconActionArrowDropdownBottom
  } from '@hse-design/vue'

  export default {
    name: 'LangDropdown',
    store,
    data: () => ({
      selected: '',
      isMenuOpen: false,
    }),
    created () {
      this.selectItem(this.$store.state.lang)
    },
    computed: {
      items () {
        return [
          {
            id: 'ru',
            icon: IconLanguageRu,
            name: 'RU'
          },
          {
            id: 'en',
            icon: IconLanguageEn,
            name: 'EN'
          },
        ];
      },
      currentItem () {
        return this.items.find(item => item.id === this.selected)
      },
      arrow () {
        return IconActionArrowDropdownBottom
      },
      ...mapState(['user', 'lang'])
    },
    watch: {
      user (usr) {
        if (usr) {
          const { lang } = usr.data.public
          if (lang) this.selectItem(lang)
        }
      },
      lang (lang) {
        if (this.selected !== lang) this.selectItem(lang)
      },
    },
    components: {
      HseDropdownMenu,
      HseDropdownMenuItem,
      HseIcon,
    },
    methods: {
      color() {
        return "#6b7a99"
      },
      toggleLanguages() {
        if (this.$store.state.isLangDropdownDisabled) return;

        this.isMenuOpen = !this.isMenuOpen
        if (this.isMenuOpen) {
          addEventListener('click', this.listener, false)
        }
      },
      listener(e) {
        const contains = this.$refs.dropdownLangRef.contains(e.target)
        if (!contains) {
          this.isMenuOpen = false
          removeEventListener('click', this.listener, false)
        }
      },
      selectItem (id) {
        this.selected = id
        this.$store.state.lang = id
        this.$i18n.locale = id
        this.isMenuOpen = false
        if (this.$store.state.user)
          api.auth('user.set.public.parameter', { lang: id })
      },
    },
  }
</script>

<style scoped>
  .language-menu {
    position: absolute;
    margin-right: 0px;
    align-items: center;
    display: none;
  }

  .top-menu-text {
    margin-left: 3px;
  }

  .show {
    display: flex;
  }

  .select-container {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    min-width: 100%;
    padding-right: 8px;
    padding-left: 12px;
    cursor: pointer;
    color: #6b7a99;
  }

  .select {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    flex-grow: 1;
    margin-right: 1px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .arrow-icon {
    display: block;
    float: none;
    cursor: pointer;
    position: relative;
    width: 24px;
    min-width: 24px;
    height: 24px;
    color: #6b7a99;
    transition: 0.2s transform ease-in-out;
  }

  .icon-opened {
    transform: rotate(180deg);
  }
  span.name {
    white-space: nowrap;
  }
</style>
