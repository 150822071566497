<template>
  <div class="itemsGrid">
    <div class="heading">
      <HseHeading size="h3" v-html="$t(currentType.title)" />
      <HseSpace size="small_2x" vertical />
      <div>
        <slot name="top" />
        <HseButton v-if="buttonType" variant="secondary" @click="changeType">
          {{ $t(buttonType.title) }}
        </HseButton>
      </div>
    </div>
    <slot name="filter" />
    <!-- Фильтр мероприятий -->
    <div v-show="filterShow" class="field">
      <div class="hse-RichInput__label">{{ $t("TypeOfEvent") }}</div>
      <HseSelect :clearable="false" :options="options" v-model="currentValue" />
      <HseSpace size="small_2x" vertical />
    </div>
    <div v-show="isLoading" class="no-data">
      <Loading />
    </div>
    <div
      v-show="!isLoading && data.length"
      class="items-grid"
      :style="cols ? `grid-template-columns: ${cols}` : ''"
    >
      <div class="col" :key="item.id" v-for="item in itemsToShow">
        <div class="item">
          <component
            :changeType="() => $emit('typeChange', currentType.id)"
            :is="component"
            :data="item"
            :type="currentType"
            :allData="data"
          />
        </div>
      </div>
    </div>
    <div v-show="!data.length && !isLoading" class="no-data">
      {{ $t("noData") }}
      <div v-if="$store.state.error">{{ error }}</div>
    </div>
    <div v-if="showPagination" class="pagination">
      <HseButton variant="secondary" @click="nextPage">
        <span v-if="page === 1">{{ $t("loadMore") }}...</span>
        <span v-else>{{ $t("more") }}</span>
      </HseButton>
    </div>
  </div>
</template>

<script>
import { HseHeading, HseButton, HseSpace, HseSelect } from "@hse-design/vue";
import Loading from "./Loading.vue";
import { mapState } from "vuex";
export default {
  name: "blockGrid",
  props: ["component", "data", "types", "isLoading", "cols", "filterShow"],
  components: {
    HseHeading,
    HseButton,
    HseSpace,
    Loading,
    HseSelect,
  },
  data: () => ({
    page: 1,
    itemsPerPage: 9,
    currentType: {},
    buttonType: null,
    options: Object.freeze([]),
    currentValue: null,
  }),
  created() {
    if (this.$store.state.lang === "ru") {
      this.options = Object.freeze([
        { label: "Круглые столы", value: "1" },
        { label: "Почетные/приглашенные докладчики", value: "2" },
      ]);
      this.currentValue = this.options[0];
    } else {
      this.options = Object.freeze([
        { label: "Round tables", value: "1" },
        { label: "Honorary/invited speakers", value: "2" },
      ]);
      this.currentValue = this.options[0];
    }
    this.$store.state.error = null;
    if (this.$route.query.type) {
      const queryType = this.types.find(
        (type) => type.id === this.$route.query.type
      );
      if (!queryType) {
        this.$router.replace(`${this.$route.path}?type=${this.currentType.id}`);
      }

      this.currentType = queryType || this.types[0];
      this.buttonType = this.types.find(
        (type) => type.id !== this.currentType.id
      );

      this.$emit("typeChange", this.currentType.id);
      console.log(this.currentType.id, "this.currentType.id");
    } else {
      this.changeType();
    }
  },
  methods: {
    changeType() {
      if (this.types.length < 2) {
        this.currentType = this.types[0];
        this.$emit("typeChange", this.currentType.id);
        return;
      } else {
        if (this.currentType.id === this.types[0].id) {
          this.currentType = this.types[1];
          this.buttonType = this.types[0];
        } else {
          this.currentType = this.types[0];
          this.buttonType = this.types[1];
        }
      }

      this.page = 1;
      this.$emit("typeChange", this.currentType.id);

      const currTypeRoute = `${this.$route.path}?type=${this.currentType.id}`;
      if (this.$route.fullPath !== currTypeRoute)
        this.$router.replace(currTypeRoute);
    },
    nextPage() {
      this.page += 1;
    },
  },
  computed: {
    itemsToShow() {
      return this.data.slice(0, this.page * this.itemsPerPage);
    },
    showPagination() {
      return this.data.length > this.page * this.itemsPerPage;
    },
    error() {
      const err = this.$store.state.error;
      if (err) {
        if (typeof err === "string") {
          return err;
        }
        return err.text;
      }
      return "";
    },
    ...mapState(["lang"]),
  },
  watch: {
    lang() {
      if (this.$store.state.lang === "ru") {
        this.options = Object.freeze([
          { label: "Круглые столы", value: "1" },
          { label: "Почетные/приглашенные докладчики", value: "2" },
        ]);
        this.currentValue = {
          value: this.options.find(
            (item) => item.value == this.currentValue.value
          ).value,
          label: this.options.find(
            (item) => item.value == this.currentValue.value
          ).label,
        };
      } else {
        this.options = Object.freeze([
          { label: "Round tables", value: "1" },
          { label: "Honorary/invited speakers", value: "2" },
        ]);
        this.currentValue = {
          value: this.options.find(
            (item) => item.value == this.currentValue.value
          ).value,
          label: this.options.find(
            (item) => item.value == this.currentValue.value
          ).label,
        };
      }
    },
    currentValue() {
      this.$emit("typeOfEvent", this.currentValue);
    },
  },
  i18n: {
    messages: {
      en: {
        noData: "No data",
        loadMore: "Load more",
        more: "More",
        TypeOfEvent: "Type of event",
        types: {
          events: {
            current: "Current events",
            archive: "Archive events",
          },
          requests: {
            current: "Current requests",
            archive: "Archive requests",
          },
          collectiveRequests: {
            current: "Current collective requests",
            archive: "Archive collective requests",
          },
          myEvents: {
            current: "Applications for special events",
            archive: "Archive of special events",
          },
          visa: {
            current: "Current requests <br>for visa help",
            archive: "Archive requests <br>for visa help",
          },
          experts: "Experts",
        },
      },
      ru: {
        noData: "Нет данных",
        loadMore: "Загрузить ещё",
        more: "Больше",
        TypeOfEvent: "Тип мероприятия",
        types: {
          events: {
            current: "Текущие мероприятия",
            archive: "Архив мероприятий",
          },
          requests: {
            current: "Текущие заявки",
            archive: "Архив заявок",
          },
          collectiveRequests: {
            current: "Текущие коллективные заявки",
            archive: "Архив коллективных заявок",
          },
          myEvents: {
            current: "Заявки на специальные мероприятия",
            archive: "Архив специальных мероприятий",
          },
          visa: {
            current: "Текущие заявки <br>на визовую поддержку",
            archive: "Архив заявок <br>на визовую поддержку",
          },
          experts: "Эксперты",
        },
      },
    },
  },
};
</script>

<style scoped>
.itemsGrid {
  margin: 0 -20px;
  position: relative;
  padding: 0 0 100px;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 4px;
}

.field {
  padding: 0 20px 4px;
}

.hse-Button {
  background: #fff;
}

.hse-Heading {
  padding-left: 32px;
}

.items-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.col {
  padding: 20px;
  min-width: 100%;
}

.item {
  background: #ffffff;
  border-radius: 12px;
  padding: 32px;
}

.no-data {
  margin: 20px;
  background: #ffffff;
  border-radius: 12px;
  padding: 32px;
  text-align: center;
}

.pagination {
  position: sticky;
  bottom: 100px;
  padding: 0 20px;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1279px) {
  .items-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .heading {
    flex-direction: column;
  }

  .items-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .item {
    max-width: 460px;
    margin: 0 auto;
  }
}
</style>
