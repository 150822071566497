<template>
  <div class="visa-card">
    <Loading v-if="!isDataLoaded" />
    <div v-else class="card">
      <div class="indent">
        <HseHeading size="h3">#{{ data.AutoNum }}</HseHeading>
        <HseSpace size='small' vertical />
        <div class="field">
          <ReadonlyInput :label="$t('fields.visa.request')" :value="requestName" />
          <HseSpace size='small_2x' vertical />
        </div>
      </div>
      <div class="form">
        <div class="field">
          <ReadonlyInput :label="$t('fields.visa.lName')" :value="data.LastName" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <ReadonlyInput :label="$t('fields.visa.fName')" :value="data.FirstName" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div v-if="lang=='ru'" class="field">
          <ReadonlyInput :label="$t('fields.visa.mName')" :value="data.MiddleName" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <ReadonlyInput :label="$t('fields.name.gender')" :value="data.GenderName" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <ReadonlyInput :label="$t('fields.name.phone')" :value="data.PhoneNumber" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <ReadonlyInput :label="$t('fields.visa.homeAddress')" :value="data.Address" />
          <HseSpace size='small_2x' vertical />
        </div>
      </div>
      <div class="form">
        <div class="field">
          <ReadonlyInput :label="$t('fields.visa.birthDate')" :value="dateFormat(data.BirthDate)" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <ReadonlyInput :label="$t('fields.visa.birthCountry')" :value="data.BirthCountryName" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <ReadonlyInput :label="$t('fields.visa.birthCity')" :value="data.BirthCity" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <ReadonlyInput :label="$t('fields.name.citizen')" :value="data.CitizenshipCountryName" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <ReadonlyInput :label="$t('fields.name.Job')" :value="data.PlaceOfWork" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <ReadonlyInput :label="$t('fields.name.position')" :value="data.Position" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <ReadonlyInput :label="$t('fields.visa.workAddress')" :value="data.WorkAddress" />
          <HseSpace size='small_2x' vertical />
        </div>
      </div>
      <div class="form">
        <div class="field">
          <ReadonlyInput :label="$t('fields.visa.passportId')" :value="data.PassportNumber" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <ReadonlyInput :label="$t('fields.visa.issuingAuthority')" :value="data.IssuingAuthority" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <ReadonlyInput :label="$t('fields.visa.passportDateIssue')" :value="dateFormat(data.PassportDateIssue)" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <ReadonlyInput :label="$t('fields.visa.passportDateExpire')" :value="dateFormat(data.PassportDateExpire)" />
          <HseSpace size='small_2x' vertical />
        </div>
      </div>
      <div class="form">
        <div class="field">
          <ReadonlyInput :label="$t('fields.visa.durationFrom')" :value="dateFormat(data.DurationFrom)" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <ReadonlyInput :label="$t('fields.visa.durationTo')" :value="dateFormat(data.DurationTo)" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <ReadonlyInput :label="$t('fields.visa.visitCities')" :value="data.CitiesOfVisit" />
          <HseSpace size='small_2x' vertical />
        </div>
      </div>
      <div class="form">
        <div class="field">
          <ReadonlyInput :label="$t('fields.visa.countryObtain')" :value="data.CountryObtainVisayName" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <ReadonlyInput :label="$t('fields.visa.consulateCity')" :value="data.RussianConsulateCity" />
          <HseSpace size='small_2x' vertical />
        </div>
      </div>
      <div class="form">
        <div class="field">
          <ReadonlyInput :label="$t('fields.name.email')" :value="data.EmailAddress" />
        </div>
      </div>
      <HseSpace size='medium' vertical />
      <div class="indent">
        <div class="actions">
          <a class="link" :href="`/#/visas`">{{ $t('back') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { api } from '../../api'
  import { mapState } from 'vuex'
  import Loading from '../common/Loading.vue'
  import ReadonlyInput from '../common/ReadonlyInput.vue'
  import { HseHeading, HseSpace } from '@hse-design/vue' 
  export default {
    components: {
      ReadonlyInput,
      HseHeading,
      HseSpace,
      Loading,
    },
    data: () => ({
      visaId: '',
      isDataLoaded: false,
      data: null,
      requestData: null,
      eventData: null,
    }),
    created () {
      this.visaId = this.$route.params.id
      this.getVisaData()
    },
    computed: {
      isLangRu () {
        return this.lang === 'ru'
      },
      requestName () {
        if (!this.requestData || !this.eventData) return ''

        const requester = this.isLangRu
          ? `${this.requestData.ApplicantLastName} ${this.requestData.ApplicantFirstName} ${this.requestData.ApplicantMiddleName}`
          : `${this.requestData.ApplicantLastNameEN} ${this.requestData.ApplicantFirstNameEN}`

        return `#${this.requestData.numberRequest} - ${this.eventData.Title} / ${requester}`
      },
      ...mapState(['lang']),
    },
    methods: {
      getVisaData () {
        api.request('visa_get', { visa_id: this.visaId, lang: this.lang  }).then(data => {
          this.data = data[0]
          this.isDataLoaded = true
        }).then(this.getReqData).then(this.getEventData)
      },
      getReqData () {
        return api.request('requestGet', { id: this.data.RequestId  }).then(
          data => this.requestData = data[0]
        )
      },
      getEventData () {
        return api.request('eventGet', { id: this.requestData.EventId  }).then(
          data => this.eventData = data[0]
        )
      },
      dateFormat (date) {
        const dateObj = new Date(date)
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return new Intl.DateTimeFormat('ru-RU', options).format(dateObj)
      },
    },
    watch: {
      lang () {
        this.getVisaData()
      }
    }, 
    i18n: {
      messages: {
        en: {
          back: 'To the visa support list',
        },
        ru: {
          back: 'К списку визовой поддержки',
        },
      }
    }
  }
</script>

<style scoped>
  .visa-card {
    background: #FFFFFF;
    border-radius: 12px;
    margin: 53px auto;
    width: fit-content;
    padding: 32px 32px;
    width: 960px;
  }
  .visa-card .form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0 -16px;
  }
  .visa-card .form .field {
    padding: 0 16px;
    min-width: 0;
  }
  .visa-card .indent {
    margin: 0 16px;
  }
  .subheading {
    font-family: 'Work Sans', sans-serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #0F0F14;
  }
  .link {
    white-space: nowrap;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  @media (max-width: 1024px) {
    .visa-card {
      margin: 16px auto;
      max-width: 95%;
    }
    .visa-card .form {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (max-width: 768px) {
    .visa-card {
      margin: 0 auto;
      max-width: 100%;
    }
    .visa-card .form {
      grid-template-columns: repeat(1, 1fr);
    }
  }
</style>
