<template>
  <div id="app">
    <Topmenu :isLogined="$store.state.user" />
    <div v-if="isTokenRefreshed" class="content">
      <router-view v-if="isRouteShowsComponentToGuest"></router-view>
      <Auth v-else-if="!$store.state.user || $store.state.roles.length === 0" />
      <UserProfile v-else-if="$store.state.user && !$store.state.userDataFull" />
      <Page v-else />
    </div>
  </div>
</template>

<script>
  import { store } from './store'
  import { api } from './api'
  import Page from './components/common/Page'
  import Topmenu from './components/common/topmenu/TopMenu'
  import Auth from './components/common/auth/Auth'
  import UserProfile from './pages/UserProfile.vue'

  export default {
    name: 'HSE',
    store,
    data: () => ({
      isTokenRefreshed: false
    }),
    components: {
      Topmenu,
      Auth,
      Page,
      UserProfile,
    },
    mounted() {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`)

      window.addEventListener('resize', () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      })

      api.refresh().then(() => this.isTokenRefreshed = true)

      api.getStaticData()
    },
    computed: {
      isRouteShowsComponentToGuest () {
        const { matched } = this.$route
        let showToGuests = false

        if (matched && matched.length) {
          const [component] = matched

          if (component && component.components && component.components.default) {
            const componentData = component.components.default.data()

            if (componentData && componentData.showToGuests)
              showToGuests = true
          }
        }


        if (this.$route.name === 'ExpertConfirm') {
          return this.$route.name && showToGuests
        }

        return !this.$store.state.user && this.$route.name && showToGuests
      }
    },
  }
</script>

<style>
  #app {
    background: #E5E5E5;
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
  }
  .content {
    overflow-y: auto;
  }
  .hse-RichInput__label {
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .hse-Select__valueContainer {
    color: #0f0f14 !important;
  }
  .hse-DropdownMenu:not(.hse-DropdownMenu_searchable_dropdown) > .hse-DropdownMenu__items,
  .hse-DropdownMenu_searchable_dropdown {
    border: 1px solid #d3d8e6 !important;
  }
  
  * {
    box-sizing: border-box;
  }
  *::-webkit-scrollbar {
    height: 4px;               /* width of the x scrollbar */
    width: 4px;               /* width of the y scrollbar */
  }
  *::-webkit-scrollbar-track {
    background: transparent;        /* color of the tracking area */
  }
  *::-webkit-scrollbar-thumb {
    background-color: #ccc;    /* color of the scroll thumb */
    border-radius: 2px;       /* roundness of the scroll thumb */
  }
</style>
