import { HseInput, HseSelect, HseTextArea, HseCheckbox } from "@hse-design/vue";
import { resourceLoader } from "../common/resourceLoader";
import { store } from "../../store/index";

const fields = {
  LName: {
    value: "",
    isValid: false,
    label: "fields.name.lName",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  FName: {
    value: "",
    isValid: false,
    label: "fields.name.fName",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  SName: {
    value: "",
    isValid: false,
    label: "fields.name.mName",
    isRequired: false,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  Email: {
    value: "",
    isValid: false,
    label: "fields.name.email",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  Position: {
    value: "",
    isValid: false,
    label: "fields.name.position",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  Phone: {
    value: "",
    isValid: false,
    label: "fields.name.phone",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  SpeakerLName: {
    value: "",
    isValid: false,
    label: "fields.honoraryOrInvitedReport.speakerLName",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  SpeakerLNameEn: {
    value: "",
    isValid: false,
    label: "fields.honoraryOrInvitedReport.speakerLNameEn",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
    rules: [
      {
        name: "latin",
      },
    ],
  },
  SpeakerFName: {
    value: "",
    isValid: false,
    label: "fields.honoraryOrInvitedReport.speakerFName",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  SpeakerFNameEn: {
    value: "",
    isValid: false,
    label: "fields.honoraryOrInvitedReport.speakerFNameEn",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
    rules: [
      {
        name: "latin",
      },
    ],
  },
  SpeakerSName: {
    value: "",
    isValid: false,
    label: "fields.honoraryOrInvitedReport.speakerSName",
    isRequired: false,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  SpeakerPosition: {
    value: "",
    isValid: false,
    label: "fields.honoraryOrInvitedReport.speakerPosition",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  SpeakerPositionEn: {
    value: "",
    isValid: false,
    label: "fields.honoraryOrInvitedReport.speakerPositionEn",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  SpeakerAffiliation: {
    value: "",
    isValid: false,
    label: "fields.honoraryOrInvitedReport.speakerAffiliation",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  SpeakerAffiliationEn: {
    value: "",
    isValid: false,
    label: "fields.honoraryOrInvitedReport.speakerAffiliationEn",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  PlannedTopicOfTheReport: {
    value: "",
    isValid: false,
    label: "fields.honoraryOrInvitedReport.plannedTopicOfTheReport",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  PlannedTopicOfTheReportEn: {
    value: "",
    isValid: false,
    label: "fields.honoraryOrInvitedReport.plannedTopicOfTheReportEn",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  SpeakerCountry: {
    value: "",
    isValid: true,
    label: "fields.honoraryOrInvitedReport.speakerCountry",
    isRequired: true,
    size: "large",
    searchable: "dropdown",
    component: HseSelect,
    getAllOptions(search) {
      return resourceLoader.load("countriesList", null, [], search);
    },
  },
  SpeakerNationality: {
    value: "",
    isValid: false,
    label: "fields.honoraryOrInvitedReport.speakerNationality",
    isRequired: true,
    size: "large",
    component: HseSelect,
    searchable: "dropdown",
    getAllOptions(search) {
      return resourceLoader.load("countriesList", null, [], search);
    },
  },
  SpeakerPassport: {
    label: "fields.honoraryOrInvitedReport.speakerPassport",
    value: "",
    isValid: false,
    isRequired: true,
    maxSizeMb: 3,
    formats: ["doc", "docx", "rtf", "pdf", "jpg", "png", "zip", "rar", "7z"],
  },
  AbstractTextRu: {
    value: "",
    isValid: false,
    label: "fields.honoraryOrInvitedReport.abstractTextRu",
    isRequired: true,
    size: "large",
    component: HseTextArea,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  AbstractTextEn: {
    value: "",
    isValid: false,
    label: "fields.honoraryOrInvitedReport.abstractTextEng",
    isRequired: true,
    size: "large",
    component: HseTextArea,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  SpeakerCv: {
    label: "fields.honoraryOrInvitedReport.speakerCv",
    value: "",
    isValid: false,
    isRequired: true,
    maxSizeMb: 3,
    formats: ["doc", "docx", "rtf", "pdf", "jpg"],
  },
  RationaleInvitation: {
    value: "",
    isValid: false,
    label: "fields.honoraryOrInvitedReport.rationaleInvitation",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  CoordinatingLeaderFio: {
    value: "",
    isValid: false,
    label: "fields.honoraryOrInvitedReport.coordinatingLeaderFio",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  CoordinatingLeaderPosition: {
    value: "",
    isValid: false,
    label: "fields.name.position",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  CoordinatingLeaderDepartment: {
    value: "",
    isValid: false,
    isRequired: true,
    size: "large",
    label: "fields.request.departmentRu",
    component: HseSelect,
    searchable: "dropdown",
    getAllOptions(search) {
      return resourceLoader.load("departmentsList", null, [], search);
    },
  },
  CoordinatingLeaderEmail: {
    value: "",
    isValid: false,
    isRequired: true,
    size: "large",
    label: "fields.honoraryOrInvitedReport.email",
    rules: [
      {
        name: "email",
      },
    ],
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  Comment: {
    value: "",
    isValid: false,
    label: "fields.honoraryOrInvitedReport.comment",
    isRequired: false,
    size: "large",
    component: HseTextArea,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  isAgree: {
    value: false,
    isValid: false,
    hideTopLabel: true,
    label: "fields.name.pdAgreement",
    classes: { label: "smallLabel" },
    component: HseCheckbox,
    rules: [
      {
        name: "custom",
        message: "fields.rule.pdAgreement",
        validateVal: (value) => value,
      },
    ],
  },

  // RTname: {
  //   value: '',
  //   isValid: false,
  //   label: 'fields.roundTable.RTname',
  //   isRequired: true,
  //   size: 'large',
  //   component: HseInput,
  //   setDisabled(val) {
  //     this.disabled = val
  //   },
  // },
  // RTnameEn: {
  //   value: '',
  //   isValid: false,
  //   label: 'fields.roundTable.RTnameEn',
  //   isRequired: false,
  //   size: 'large',
  //   component: HseInput,
  //   rules: [{
  //     name: 'latin',
  //   }],
  //   setDisabled(val) {
  //     this.disabled = val
  //   },
  // },
  // responsibleFio: {
  //   value: '',
  //   isValid: false,
  //   label: 'fields.roundTable.responsibleFio',
  //   isRequired: true,
  //   size: 'large',
  //   component: HseInput,
  //   setDisabled(val) {
  //     this.disabled = val
  //   },
  // },
  // discussionQuestions: {
  //   value: '',
  //   isValid: false,
  //   label: 'fields.roundTable.discussionQuestions',
  //   isRequired: true,
  //   size: 'large',
  //   component: HseTextArea,
  //   setDisabled(val) {
  //     this.disabled = val
  //   },
  // },
  // discussionQuestionsEn: {
  //   value: '',
  //   isValid: false,
  //   label: 'fields.roundTable.discussionQuestionsEn',
  //   isRequired: false,
  //   size: 'large',
  //   component: HseTextArea,
  //   rules: [{
  //     name: 'latin',
  //   }],
  //   setDisabled(val) {
  //     this.disabled = val
  //   },
  // },
  // date: {
  //   value: '',
  //   isValid: false,
  //   label: 'fields.roundTable.date',
  //   isRequired: true,
  //   size: 'large',
  //   component: HseInput,
  //   setDisabled(val) {
  //     this.disabled = val
  //   },
  // },
  // programSessionTimeId: {
  //   value: '',
  //   isValid: false,
  //   eventId: '',
  //   label: 'fields.roundTable.programSessionTimeId',
  //   isRequired: true,
  //   size: 'large',
  //   date: null,
  //   component: HseSelect,
  //   setDate(date) {
  //     this.date = date
  //   },
  //   getAllOptions() {
  //     return resourceLoader.load('get_event_timeslots', { eventId: this.eventId }, []).then(res => {
  //       const date = this.date ? new Date(this.date).toISOString() : null
  //       return res.filter(opt => this.date ? opt.Day === date : true).map(opt => {
  //         const label = store.state.lang === 'ru' ? `${opt.DayRU} ${opt.DurationRU}` : `${opt.DayEN} ${opt.DurationEN}`
  //         return { label, value: opt.Id }
  //       })
  //     })
  //   },
  //   setDisabled(val) {
  //     this.disabled = val
  //   },
  // },
  // other: {
  //   value: '',
  //   isValid: false,
  //   label: 'fields.roundTable.langOther',
  //   size: 'large',
  //   component: HseInput,
  //   setDisabled(val) {
  //     this.disabled = val
  //   },
  // },
  // contactPerson: {
  //   value: '',
  //   isValid: false,
  //   label: 'fields.roundTable.contactPerson',
  //   isRequired: true,
  //   size: 'large',
  //   component: HseInput,
  //   setDisabled(val) {
  //     this.disabled = val
  //   },
  // },
  // contactPersonEmail: {
  //   value: '',
  //   isValid: false,
  //   label: 'fields.roundTable.contactPersonEmail',
  //   isRequired: true,
  //   size: 'large',
  //   component: HseInput,
  //   rules: [{
  //     name: 'email'
  //   }],
  //   setDisabled(val) {
  //     this.disabled = val
  //   },
  // },

  // involvedBusinessOrg: {
  //   value: '',
  //   isValid: false,
  //   label: 'fields.roundTable.involvedBusinessOrg',
  //   isRequired: true,
  //   size: 'large',
  //   component: HseTextArea,
  //   setDisabled(val) {
  //     this.disabled = val
  //   },
  // },
  // involvedInfoOrg: {
  //   value: '',
  //   isValid: false,
  //   label: 'fields.roundTable.involvedInfoOrg',
  //   isRequired: true,
  //   size: 'large',
  //   component: HseTextArea,
  //   setDisabled(val) {
  //     this.disabled = val
  //   },
  // },
  moderators: {
    value: [],
    isValid: false,
  },
  moderatorsDeleteIds: {
    value: [],
    isValid: true,
  },
  // speakers: {
  //   value: [],
  //   isValid: true,
  // },
  // speakersDeleteIds: {
  //   value: [],
  //   isValid: true,
  // },
  // members: {
  //   value: [],
  //   isValid: true,
  // },
  // membersDeleteIds: {
  //   value: [],
  //   isValid: true,
  // },

  //page 2
  //////////////////////////////////////
  /////////////////////////////////////
  ////////////////////////////////////

  TitleRU: {
    value: "",
    isValid: false,
    label: "fields.honoraryOrInvitedReport.titleReportRu",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  TitleEN: {
    value: "",
    isValid: false,
    label: "fields.honoraryOrInvitedReport.titleReportEng",
    isRequired: true,
    size: "large",
    component: HseInput,
    rules: [
      {
        name: "latin",
      },
    ],
    setDisabled(val) {
      this.disabled = val;
    },
  },
  AnnotationTextRU: {
    value: "",
    isValid: false,
    label: "fields.honoraryOrInvitedReport.abstractTextRu",
    isRequired: true,
    size: "large",
    component: HseTextArea,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  AnnotationTextEN: {
    value: "",
    isValid: false,
    label: "fields.honoraryOrInvitedReport.abstractTextEng",
    isRequired: true,
    size: "large",
    component: HseTextArea,
    rules: [
      {
        name: "latinTitle",
      },
    ],
    setDisabled(val) {
      this.disabled = val;
    },
  },
  imageFileID: {
    label: "fields.honoraryOrInvitedReport.imageFileId",
    value: "",
    isValid: false,
    isRequired: false,
    maxSizeMb: 3,
    formats: ["jpeg", "png"],
  },
  ResponsibleFIO: {
    value: "",
    isValid: false,
    label: "fields.roundTable.responsibleFio",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  ResponsiblePost: {
    value: "",
    isValid: false,
    label: "fields.honoraryOrInvitedReport.post",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  ResponsibleDepartmenID: {
    value: "",
    isValid: false,
    isRequired: true,
    size: "large",
    label: "fields.honoraryOrInvitedReport.division",
    component: HseSelect,
    searchable: "dropdown",
    getAllOptions(search) {
      return resourceLoader.load("departmentsList", null, [], search);
    },
  },
  ResponsibleEmail: {
    value: "",
    isValid: false,
    label: "fields.honoraryOrInvitedReport.email",
    isRequired: true,
    size: "large",
    component: HseInput,
    rules: [
      {
        name: "email",
      },
    ],
    setDisabled(val) {
      this.disabled = val;
    },
  },
  date: {
    value: "",
    isValid: false,
    label: "fields.honoraryOrInvitedReport.date",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  programSessionTimeId: {
    value: "",
    isValid: false,
    eventId: "",
    name: "programSessionTimeId",
    label: "fields.honoraryOrInvitedReport.programSessionTimeId",
    isRequired: true,
    size: "large",
    date: null,
    component: HseSelect,
    setDate(date) {
      this.date = date;
    },
    getAllOptions() {
      return resourceLoader
        .load("get_event_timeslots", { eventId: this.eventId }, [])
        .then((res) => {
          const date = this.date ? new Date(this.date).toISOString() : null;
          return res
            .filter((opt) => (this.date ? opt.Day === date : true))
            .map((opt) => {
              const label =
                store.state.lang === "ru"
                  ? `${opt.DayRU} ${opt.DurationRU}`
                  : `${opt.DayEN} ${opt.DurationEN}`;
              return { label, value: opt.Id };
            });
        });
    },
    setDisabled(val) {
      this.disabled = val;
    },
  },
  secretaryOnline: {
    value: "",
    isValid: false,
    isRequired: true,
    name: "secretary",
    size: "large",
    label: "fields.honoraryOrInvitedReport.secretaryOnline",
    component: HseSelect,
    searchable: "dropdown",
    options: [],
    event: "",
    setOptions(data) {
      this.options = data;
    },
    setEvent(eventId) {
      console.log(eventId, "eventId");
      this.event = eventId;
    },
    getAllOptions() {
      console.log(this.event, "test");
      const listSecretary = resourceLoader
        .load("get_secretary_list", Math.random())
        .then((res) => {
          console.log(res, "res");
          let newRes = res.filter((item) => this.event === item.EventID);
          console.log(newRes, "newRes");
          return newRes
            .map((item) => {
              return {
                label:
                  item.LastName + " " + item.FirstName + " " + item.MiddleName,
                value: item.ID,
              };
            })
            .sort((a, b) =>
              a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
            );
        });
      return listSecretary;
    },
  },
  secretaryOffline: {
    value: "",
    isValid: false,
    isRequired: true,
    size: "large",
    label: "fields.honoraryOrInvitedReport.secretaryOffline",
    name: "secretary",
    component: HseSelect,
    searchable: "dropdown",
    options: [],
    event: "",
    setEvent(eventId) {
      console.log(eventId, "eventId");
      this.event = eventId;
    },
    setOptions(data) {
      this.options = data;
    },
    getAllOptions() {
      console.log(this.event, "test");
      const listSecretary = resourceLoader
        .load("get_secretary_list", Math.random())
        .then((res) => {
          console.log(res, "res");
          let newRes = res.filter((item) => this.event === item.EventID);
          console.log(newRes, "newRes");
          return newRes
            .map((item) => {
              return {
                label:
                  item.LastName + " " + item.FirstName + " " + item.MiddleName,
                value: item.ID,
              };
            })
            .sort((a, b) =>
              a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
            );
        });
      return listSecretary;
    },
  },
  langRu: {
    value: false,
    isValid: false,
    hideTopLabel: true,
    label: "fields.roundTable.langRu",
    classes: { label: "smallLabel" },
    component: HseCheckbox,
    setDisabled(val) {
      this.inactive = val;
    },
  },
  langEn: {
    value: false,
    isValid: false,
    hideTopLabel: true,
    label: "fields.roundTable.langEn",
    classes: { label: "smallLabel" },
    component: HseCheckbox,
    setDisabled(val) {
      this.inactive = val;
    },
  },
  langOther: {
    value: false,
    isValid: true,
    hideTopLabel: true,
    label: "fields.roundTable.langOther",
    classes: { label: "smallLabel" },
    component: HseCheckbox,
    setDisabled(val) {
      this.inactive = val;
    },
  },
  other: {
    value: "",
    isValid: false,
    label: "fields.roundTable.langOther",
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
  },

  ///////////////////////////////////
  //////////////////////////////////
  /////////////////////////////////

  syncTranslation: {
    value: 0,
    isValid: true,
    isRequired: true,
    label: "fields.roundTable.syncTranslation",
    default: 0,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  needOnlineSupport: {
    value: 0,
    isValid: true,
    isRequired: true,
    label: "fields.roundTable.needOnlineSupport",
    default: 0,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  needVolunteerSupport: {
    value: 0,
    isValid: true,
    isRequired: true,
    label: "fields.roundTable.needVolunteerSupport",
    default: 0,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  volunteerSupportBlocksId: {
    value: [],
    isValid: true,
    label: "fields.roundTable.volunteerSupportBlocksId",
    options: [],
    getAllOptions() {
      return resourceLoader
        .load("get_volunteers_blocks", null, [])
        .then((res) => {
          this.options = res.map((opt) => {
            const label = store.state.lang === "ru" ? opt.blocks : opt.blockEn;
            return { label, value: opt.id };
          });
        });
    },
    setDisabled(val) {
      this.disabled = val;
    },
  },
  needOfficialPhoto: {
    value: 0,
    isValid: true,
    isRequired: true,
    label: "fields.roundTable.needOfficialPhoto",
    default: 0,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  needOfficialVideo: {
    value: 0,
    isRequired: true,
    isValid: true,
    label: "fields.roundTable.needOfficialVideo",
    default: 0,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  publicityId: {
    value: "52B63329-51B3-4DA7-9D25-A953A56157BF",
    isRequired: true,
    isValid: true,
    options: [],
    label: "fields.roundTable.publicityId",
    default: "52B63329-51B3-4DA7-9D25-A953A56157BF",
    setDisabled(val) {
      this.disabled = val;
    },
    getAllOptions() {
      return resourceLoader.load("get_publicity", null, []).then((res) => {
        this.options = res.map((opt) => {
          const label =
            store.state.lang === "ru" ? opt.publicity : opt.publicittyEn;
          return { label, value: opt.id };
        });
      });
    },
  },
  massMediaId: {
    value: "",
    isValid: false,
    label: "fields.roundTable.massMediaId",
    isRequired: true,
    size: "large",
    component: HseSelect,
    setDisabled(val) {
      this.disabled = val;
    },
    getAllOptions() {
      return resourceLoader.load("get_mass_media", null, []).then((res) => {
        return res.map((opt) => {
          const label = store.state.lang === "ru" ? opt.value : opt.valueEn;
          return { label, value: opt.id };
        });
      });
    },
  },
  needOnlineTranslation: {
    value: 0,
    isValid: true,
    isRequired: true,
    label: "fields.roundTable.needOnlineTranslation",
    default: 0,
    setDisabled(val) {
      this.disabled = val;
    },
  },
  specialEventOrganizationFormatId: {
    value: "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A",
    isValid: true,
    options: [],
    default: "592D7A01-E55F-4860-B403-1BB88FDE85A9",
    setDisabled(val) {
      this.disabled = val;
    },
    getAllOptions() {
      return resourceLoader.load("get_org_formats", null, []).then((res) => {
        const ids = [
          "592D7A01-E55F-4860-B403-1BB88FDE85A9",
          "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A",
        ];

        this.options = res
          .filter((item) => ids.includes(item.Id))
          .map((opt) => {
            const label = store.state.lang === "ru" ? opt.Name : opt.NameEn;
            return { label, value: opt.Id };
          });
      });
    },
  },
  specialEventOrganizationFormatIdForm2: {
    value: "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A",
    isValid: true,
    options: [],
    default: "592D7A01-E55F-4860-B403-1BB88FDE85A9",
    setDisabled(val) {
      this.disabled = val;
    },
    getAllOptions() {
      return resourceLoader.load("get_org_formats", null, []).then((res) => {
        const ids = [
          "592D7A01-E55F-4860-B403-1BB88FDE85A9",
          "1EEAFEAA-97E8-4436-8D7C-C9D4731FD16A",
          "895BB6AB-A343-48DC-AA19-7EAB348C8C0D",
        ];

        this.options = res
          .filter((item) => ids.includes(item.Id))
          .map((opt) => {
            const label = store.state.lang === "ru" ? opt.Name : opt.NameEn;
            return { label, value: opt.Id };
          });
      });
    },
  },
  speakerStatusNew: {
    value: "4154B895-2961-5867-4AD8-5CFFE09B57C4",
    isValid: true,
    options: [],
    default: "4154B895-2961-5867-4AD8-5CFFE09B57C4",
    setDisabled(val) {
      this.disabled = val;
    },
    getAllOptions() {
      return resourceLoader
        .load("get_speaker_statuses_list", null, [])
        .then((res) => {
          this.options = res
            .filter(
              (opt) =>
                opt.value === "4154B895-2961-5867-4AD8-5CFFE09B57C4" ||
                opt.value === "766F01A4-4B35-80C5-422B-709AD9BF46FB"
            )
            .map((opt) => {
              let label = opt.label;
              let tooltip = "";

              if (opt.value === "4154B895-2961-5867-4AD8-5CFFE09B57C4") {
                label =
                  store.state.lang === "ru"
                    ? "Приглашённый доклад"
                    : "Invited report";
                tooltip =
                  "доклад, представляемый российским или иностранным гостем конференции с высоким показателем научного и/или экспертного рейтинга. Тема приглашенного доклада может быть одной из тем сессии и/или круглого стола.";
              }

              if (opt.value === "766F01A4-4B35-80C5-422B-709AD9BF46FB") {
                label =
                  store.state.lang === "ru"
                    ? "Почетный доклад"
                    : "Honorary report";
                tooltip =
                  "доклад российского или иностранного гостя конференции с высоким показателем научного и/или экспертного рейтинга. Тематика почетного доклада, как правило, включает общие вопросы развития экономики и общества. Почетный доклад – всегда самостоятельное мероприятие.";
              }

              return {
                label,
                value: opt.value,
                tooltip,
              };
            });
        });
    },
  },
  maxParticipants: {
    value: "",
    isValid: false,
    label: "fields.roundTable.maxParticipants",
    isRequired: true,
    size: "large",
    component: HseInput,
    setDisabled(val) {
      this.disabled = val;
    },
    setRequired(val) {
      this.isRequired = val;
    },
    rules: [
      {
        name: "number",
      },
      {
        name: "maxParticipants",
      },
    ],
  },
  endRegistrationDate: {
    value: new Date().toLocaleDateString("ru-RU"),
    isValid: false,
    label: "fields.roundTable.endRegistrationDate",
    isRequired: true,
    size: "large",
    setDisabled(val) {
      this.disabled = val;
    },
    component: HseInput,
  },
  DepartmentIdRu: {
    value: "",
    isValid: false,
    isRequired: true,
    size: "large",
    label: "fields.request.departmentRu",
    component: HseSelect,
    searchable: "dropdown",
    getAllOptions(search) {
      return resourceLoader.load("departmentsList", null, [], search);
    },
  },
  ApplicantDepartmentIdEn: {
    value: "",
    isValid: false,
    isRequired: true,
    size: "large",
    label: "fields.request.departmentEn",
    component: HseSelect,
    searchable: "dropdown",
    rules: [
      {
        name: "latin",
      },
    ],
    getAllOptions(search) {
      return resourceLoader.load("departmentsList", null, [], search);
    },
  },
  SpeakerStatus: {
    value: "",
    isValid: false,
    isRequired: true,
    size: "large",
    label: "fields.honoraryOrInvitedReport.speakerStatus",
    component: HseSelect,
    getAllOptions() {
      return resourceLoader
        .load("get_speaker_statuses_list", null, [])
        .then((res) => {
          return res
            .filter(
              (opt) =>
                opt.value === "4154B895-2961-5867-4AD8-5CFFE09B57C4" ||
                opt.value === "766F01A4-4B35-80C5-422B-709AD9BF46FB"
            )
            .map((opt) => {
              let label = opt.label;

              if (opt.value === "4154B895-2961-5867-4AD8-5CFFE09B57C4") {
                label =
                  store.state.lang === "ru"
                    ? "Приглашённый доклад"
                    : "Invited report";
              }

              if (opt.value === "766F01A4-4B35-80C5-422B-709AD9BF46FB") {
                label =
                  store.state.lang === "ru"
                    ? "Почетный доклад"
                    : "Honorary report";
              }

              return {
                label,
                value: opt.value,
              };
            });
        });
    },
  },
  // SpeakerStatus: {
  //   value: '',
  //   isValid: false,
  //   isRequired: true,
  //   size: 'large',
  //   label: 'fields.honoraryOrInvitedReport.speakerStatus',
  //   component: HseSelect,
  //   getAllOptions() {
  //     return resourceLoader.load('get_event_program_session_type', null, []).then(res => {
  //       const correctStatuses = ['B40ACF74-7313-4F6D-846D-E9C9D1CC99BD', 'CE67DD60-AE3E-4215-A6BB-5601A7354F63']
  //       return res.filter(opt => correctStatuses.includes(opt.value))
  //     })
  //   },
  // },
  StudentDocumentFileID: {
    label: "fields.request.studentFileID",
    value: "",
    isValid: false,
    isRequired: true,
    maxSizeMb: 3,
    formats: ["doc", "docx", "rtf", "pdf", "jpg"],
  },
};

Object.keys(fields).forEach((key) => {
  fields[key].setValues = function (isValid, value = "") {
    this.value = value;
    this.isValid = isValid;
  };
});

export default fields;
