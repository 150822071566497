<template>
  <div class="event-card">
    <Loading v-if="!isDataLoaded" />
    <div v-else class="card">
      <HseHeading size="h3">{{ data.Title }}</HseHeading>
      <HseSpace size='small' vertical />
      <div class="subheading">{{ $t('basic') }}</div>
      <HseSpace size='small' vertical />
      <ReadonlyInput :label="$t('event.location')" :value="data.Location" />
      <ReadonlyInput :label="$t('event.typeOfEvent')" :value="data.EventType" />
      <ReadonlyInput :label="$t('event.format')" :value="data.VidName" />
      <ReadonlyInput :label="$t('language')" :value="data.Language" />
      <ReadonlyInput :label="$t('contactInfo')" :value="data.Contacts" />
      <div class="two-cols">
        <ReadonlyInput :label="$t('event.startDate')" :value="dateFormat(data.StartDate)" />
        <ReadonlyInput :label="$t('event.endDate')" :value="dateFormat(data.EndDate)" />
      </div>
      <div class="two-cols">
        <ReadonlyInput :label="$t('reqStartDate')" :value="dateFormat(data.StartRequestDate)" />
        <ReadonlyInput :label="$t('reqEndDate')" :value="dateFormat(data.EndRequestDate)" />
      </div>
      <div class="two-cols">
        <ReadonlyInput :label="$t('repStartDate')" :value="dateFormat(data.StartReportDate)" />
        <ReadonlyInput :label="$t('repEndDate')" :value="dateFormat(data.EndReportDate)" />
      </div>
      <div class="hse-RichInput__label">{{ $t('annotation') }}</div>
      <div class="big-item" v-html="data.Annotation" />
      <HseSpace size='small_2x' vertical />
      <div class="hse-RichInput__label">{{ $t('description') }}</div>
      <div class="big-item" v-html="data.Description" />
      <HseSpace size='medium' vertical />
      <div class="subheading">{{ $t('topicList') }}</div>
      <HseSpace size='small' vertical />
      <HseTable>
        <HseTableHead>
          <HseTableRow>
            <HseTableHeadCell>{{ $t('topicName') }}</HseTableHeadCell>
            <HseTableHeadCell>{{ $t('topicDesc') }}</HseTableHeadCell>
          </HseTableRow>
        </HseTableHead>
        <HseTableBody>
          <HseTableRow v-for="(row, rowIndex) in topicData" :key="rowIndex">
            <HseTableCell
              v-for="(cell, cellIndex) in row"
              :style="{ minWidth: '200px' }"
              :key="cellIndex"
              v-html="cell"
            >
            </HseTableCell>
          </HseTableRow>
        </HseTableBody>
      </HseTable>
      <HseSpace size='small' vertical />
      <a class="link" v-if="data.PaymentRegulationsLink" :href="`${ data.PaymentRegulationsLink }`">{{ $t('paymentInfo') }}</a>
      <HseSpace size='small' vertical />
      <div class="actions">
        <div v-if="type === 'current' && invalidRequestDate">
          <HseButton size="medium" v-on:click="registerReq">{{ $t('register') }}</HseButton>
        </div>
        <div v-if="!invalidRequestDate">
          <HseTooltip :content="$t('endRegistration')">
            <HseButton class="disable" size="medium">
              {{ $t('register') }}
            </HseButton>
          </HseTooltip>
        </div>
        <a class="link" v-if="(paymentStatus === 1 || paymentStatus === -1) && data.hasOpen" :href="`/#/special_events/${this.eventId}`">{{ $t('specialEvents') }}</a>
        <a class="link" :href="`/#/events${type ? `?type=${type}` : ''}`">{{ $t('reqList') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
  import { api } from '../../api'
  import { mapState } from 'vuex'
  import Loading from '../common/Loading.vue'
  import ReadonlyInput from '../common/ReadonlyInput.vue'
  import { dateFormatter } from '../common/dateFormatter'
  import { HseHeading,
           HseSpace,
           HseTooltip,
           HseButton,
           HseTable,
           HseTableHead,
           HseTableBody,
           HseTableRow,
           HseTableHeadCell,
           HseTableCell } from '@hse-design/vue'

  export default {
    components: {
      ReadonlyInput,
      HseHeading,
      HseSpace,
      HseTooltip,
      HseButton,
      HseTable,
      HseTableHead,
      HseTableBody,
      HseTableRow,
      HseTableHeadCell,
      HseTableCell,
      Loading,
    },
    data: () => ({
      eventId: '',
      isDataLoaded: false,
      data: null,
      topicData: [],
      type: '',
      invalidRequestDate: false,
      paymentStatus: '',
    }),
    created () {
      this.eventId = this.$route.params.id
      this.type = this.$route.query.from || ''
      this.requestEventData()
      this.topicList()
      this.requestPaymentStatusByEvent()
    },
    computed: {
      ...mapState(['lang']),
    },
    methods: {
      requestPaymentStatusByEvent() {
        api.request('payment_status_by_event', { EventId: this.eventId})
            .then(data => {
              this.paymentStatus = data
            })
      },
      checkEndRequestDate (endDate) {
        const endDateTimeStamp = new Date(endDate).getTime()
        const currentDate = new Date().getTime()
        this.invalidRequestDate = currentDate < endDateTimeStamp
      },
      requestEventData () {
        api.request('eventGet', { id: this.eventId, lang:this.lang  }).then(data => {
          this.data = data[0]
          this.isDataLoaded = true
          this.checkEndRequestDate(this.data.EndRequestDate)
        })
      },
      topicList () {
        api.request('eventTopicsList', { event_id: this.eventId }).then(data => {
          const topics = []
          data.forEach(item => {
            const row = {}
            Object.keys(item).forEach(key => {
              if (['Title', 'Description'].includes(key)) {
                row[key] = item[key]
              }
            })
            topics.push(row)
          })
          this.topicData = topics
        })
      },
      dateFormat (date) { return dateFormatter.format(date) },
      registerReq () {
        this.$router.push(`/requests/add/?event=${this.eventId}`)
      },
    },
    watch: {
      lang () {
        this.requestEventData()
        this.topicList()
      }
    },
    i18n: {
      messages: {
        en: {
          basic: 'Basic information',
          topicList: 'List of topics',
          language: 'Language',
          reqStartDate: 'Start date of accepting applications without report',
          reqEndDate: 'End date of accepting applications without report',
          repStartDate: 'Start date of accepting applications with report',
          repEndDate: 'End date of accepting applications  with report',
          annotation: 'Announcement',
          description: 'Description',
          topicName: 'Topic name',
          topicDesc: 'Topic description',
          reqList: 'To the events list',
          contactInfo: 'Contacts',
          endRegistration: 'Registration to the Conference is finished',
          paymentInfo: 'Regulations on participation payment',
          register: 'Apply for partipication',
          specialEvents: 'Special events',
        },
        ru: {
          basic: 'Основная информация',
          topicList: 'Список направлений',
          language: 'Язык',
          reqStartDate: 'Дата начала приема заявок без доклада',
          reqEndDate: 'Дата окончания приема заявок без доклада',
          repStartDate: 'Дата начала приема заявок с докладом',
          repEndDate: 'Дата окончания приема заявок с докладом',
          annotation: 'Анонс',
          description: 'Описание',
          topicName: 'Наименование направления',
          topicDesc: 'Описание направления',
          reqList: 'К списку мероприятий',
          contactInfo: 'Контактная информация',
          endRegistration: 'Регистрация на мероприятие завершена',
          paymentInfo: 'Положение об оплате участия',
          register: 'Подать заявку на участие',
          specialEvents: 'Специальные мероприятия',
        },
      }
    }
  }
</script>

<style scoped>
  .disable {
    opacity: 0.5;
    background-color: rgba(37, 37, 65, 0.6);;
  }
  .event-card {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 32px;
    max-width: 800px;
    width: calc(100vw - 128px);
    margin: 52px auto;
  }
  .subheading {
    font-family: 'Work Sans', sans-serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #0F0F14;
  }
  .big-item {
    max-width: 780px;
  }
  .link {
    white-space: nowrap;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  .actions>* {
    margin: 0 15px;
  }
  .actions .link {
    padding: 12px 0;
  }
  .two-cols {
    display: flex;
    margin: 0 -15px;
  }
  .two-cols>.field {
    padding: 0 15px;
    flex: 1;
  }
  @media (max-width: 1023px) {
    .event-card {
      margin: 16px auto;
      width: 95%;
    }
  }
  @media (max-width: 767px) {
    .event-card {
      width: 100%;
      margin: 0;
    }
    .two-cols {
      flex-direction: column;
    }
    .two-cols>.field {
      width: 100%;
    }
  }
</style>
