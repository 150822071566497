<template>
  <div>
    <span class="hse-RichInput__label">{{ label }}</span>
    <HseSpace size="small_2x" vertical />
    <HseRadioGroup class="group">
      <HseRadio
        class="item"
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        :modelValue="value"
        @change="setValue"
        :inactive="disabled"
      >
        {{ option.label }}
      </HseRadio>
    </HseRadioGroup>
  </div>
</template>
<script>
  import { HseRadioGroup, HseRadio, HseSpace } from '@hse-design/vue'
  export default {
    components: {
      HseRadioGroup,
      HseRadio,
      HseSpace,
    },
    data: () => ({
      value: '',
    }),
    created () {
      this.value = this.defaultValue
    },
    watch: {
      defaultValue() {
        this.value = this.defaultValue
      },
    },
    props: ['options', 'label', 'defaultValue', 'disabled'],
    methods: {
      setValue (val) {
        this.value = val
        this.$emit('change', val)
      },
    },
  }
</script>

<style scoped>
.group {
  display: flex;
  margin: 0 -15px;
}
.item {
  flex: 1;
  padding: 0 15px;
}
</style>