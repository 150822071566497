import { HseSelect, HseInput, HseTextArea } from '@hse-design/vue'
import { resourceLoader } from '../common/resourceLoader'
import { STATUS_ID_CONFIRMED } from '../requestsPage/constants'

const fields = {
  ExpertSelect: {
    request_id: '',
    value: '',
    isValid: false,
    isRequired: true,
    label: 'fields.manager.expert',
    size: "large",
    component: HseSelect,
    searchable: 'dropdown',
    requestId: null,
    setRequestId(request_id){this.request_id = request_id},
    getAllOptions (search) {
      return resourceLoader.load('manager.expertListAvailable', {request_id: this.request_id}, [], search)
    },
  },
  StatusSelect: {
    value: '',
    isValid: false,
    label: 'fields.manager.status',
    size: "large",
    component: HseSelect,
    searchable: 'inline',
    requestId: null,
    getAllOptions (search) {
      return resourceLoader.load('statusesListManager', {}, [], search)
    },
  },
  StatusAllSelect: {
    value: '',
    isValid: false,
    label: 'fields.manager.status',
    size: "medium",
    component: HseSelect,
    // searchable: 'inline',
    requestId: null,
    excludeOptions: [STATUS_ID_CONFIRMED],
    getAllOptions (search) {
      return resourceLoader.load('statusesList', {}, [], search)
    },
  },
  ScoreSelect: {
    value: '',
    isValid: false,
    label: 'fields.manager.score',
    size: "large",
    component: HseSelect,
    searchable: 'inline',
    requestId: null,
    getAllOptions (search) {
      return resourceLoader.load('expertTotalResultOptionsListShort', {}, [], search)
    },
  },
  ScoreSelectMedium: {
    value: '',
    isValid: false,
    label: 'fields.manager.score',
    size: "medium",
    component: HseSelect,
    requestId: null,
    getAllOptions (search) {
      return resourceLoader.load('expertTotalResultOptionsListShort', {}, [], search)
    },
  },
  filter: {
    value: '',
    isValid: false,
    label: 'fields.manager.filter',
    size: 'medium',
    component: HseInput,
  },
  search: {
    value: '',
    isValid: false,
    label: 'fields.manager.search',
    size: 'medium',
    component: HseInput,
  },
  event: {
    value: '',
    isValid: false,
    label: 'fields.name.event',
    size: 'medium',
    component: HseSelect,
    getAllOptions (search) {
      return resourceLoader.load('manager.eventList', {  }, [], search)
    },
  },
  thesesFileID: {
    label: '',
    value: '',
    isRequired: true,
    isValid: false,
    maxSizeMb: 10,
    formats: ['doc', 'docx', 'rtf', 'pdf']
  },
  thesesFileEnID: {
    label: '',
    value: '',
    isRequired: true,
    isValid: false,
    maxSizeMb: 10,
    formats: ['doc', 'docx', 'rtf', 'pdf']
  },
  finalDirection: {
    value: '',
    isValid: true,
    label: 'fields.manager.finalDirection',
    size: 'medium',
    eventId: '',
    component: HseSelect,
    setEventId(event_id){this.eventId = event_id},
    getAllOptions (search) {
      return this.eventId ? resourceLoader.load('eventTopicsList', {event_id: this.eventId}, [], search) : Promise.resolve([])
    },
  },
  statusSelect: {
    value: '',
    width: 'full',
    isValid: false,
    label: 'fields.name.status',
    size: 'medium',
    component: HseSelect,
    getAllOptions (search) {
      return resourceLoader.load('expertTotalResultOptionsListFilter', { rated: true }, [], search)
    },
  },
  comment: {
    value: '',
    isValid: false,
    isRequired: true,
    label: 'fields.expert.Comment',
    size: "large",
    component: HseTextArea,
  }
}

Object.keys(fields).forEach((key) => {
  fields[key].setValues = function (isValid, value = '') {
    this.value = value
    this.isValid = isValid
  }
})

export default fields