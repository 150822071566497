import { HseSelect, HseInput } from '@hse-design/vue'
import { api } from '../../api'
import { resourceLoader } from '../common/resourceLoader'

const fields = {
  event: {
    value: '',
    isValid: false,
    isRequired: false,
    label: "fields.name.event",
    size: "medium",
    component: HseSelect,
    searchable: 'dropdown',
    getAllOptions (search) {
      return resourceLoader.load('manager.eventList', {  }, [], search)
    },
  },
  Topic: {
    value: '',
    isValid: false,
    isRequired: false,
    label: "fields.name.expert_topic",
    size: "medium",
    component: HseSelect,
    eventId: '',
    setEvent (event) { this.value='';  this.eventId = event; },
    myLoad(search) {
      let ret = api.request('manager.eventTopics', {event_id: this.eventId}).then(data => {
        if (!data) return Promise.resolve([])
        const items = data.map(item => {
          if (item.TitleEN) item.Title = item.TitleEN;
          return item
        })
        const normalized = Promise.resolve(resourceLoader.normalizeData('manager.eventTopics', items))
        ret = normalized
        return normalized
      })
      return ret.then(data => resourceLoader.search(data, search))
    },
    getAllOptions (search) {
      return this.myLoad(search);
    },
  },
  filter: {
    value: '',
    isValid: false,
    label: 'fields.manager.filter',
    size: 'medium',
    component: HseInput,
  },
}

Object.keys(fields).forEach((key) => {
  fields[key].setValues = function (isValid, value = '') {
    this.value = value
    this.isValid = isValid
  }
})

export default fields