import { HseInput, HseSelect, HseCheckbox } from '@hse-design/vue'
import { resourceLoader } from '../common/resourceLoader'
import { store } from '../../store'
const fields = {
  LName: {
    value: '',
    isValid: false,
    label: 'fields.name.lName',
    isRequired: true,
    size: 'large',
    component: HseInput,
  },
  FName: {
    value: '',
    isValid: false,
    label: 'fields.name.fName',
    isRequired: true,
    size: 'large',
    component: HseInput,
  },
  SName: {
    value: '',
    isValid: true,
    label: 'fields.name.mName',
    size: 'large',
    component: HseInput,
  },
  LNameEN: {
    value: '',
    isValid: false,
    label: 'fields.name.lNameEn',
    isRequired: true,
    size: 'large',
    component: HseInput,
    rules: [{
      name: 'latin',
    }],
  },
  FNameEN: {
    value: '',
    isValid: false,
    label: 'fields.name.fNameEn',
    isRequired: true,
    size: 'large',
    component: HseInput,
    rules: [{
      name: 'latin',
    }],
  },
  login: {
    value: '',
    isValid: false,
    label: 'fields.name.email',
    isRequired: true,
    size: 'large',
    component: HseInput,
    rules: [{
      name: 'email',
    }, {
      name: 'custom', 
      message: 'fields.coauthor.alreadyAdded',
      validateVal () {
        return !store.state.error || store.state.error.code !== 876
      }
    }],
  },
  CompanyNameShort: {
    value: '',
    isValid: true,
    label: 'fields.name.companyShort',
    isRequired: true,
    size: 'large',
    component: HseInput,
  },
  CompanyNameShortEn: {
    value: '',
    isValid: true,
    label: 'fields.name.companyShortEn',
    isRequired: true,
    size: 'large',
    component: HseInput,
    rules: [{
      name: 'latin',
    }]
  },
  Affiliated: {
    value: false,
    isValid: true,
    hideTopLabel: true,
    label: 'fields.request.isHSEPresenter3rd',
    size: 'large',
    component: HseCheckbox,
    classes: { label: 'smallLabel' }
  },
  Campus: {
    value: '',
    isValid: true,
    label: 'fields.request.campus',
    isRequired: true,
    size: 'large',
    component: HseSelect,
    getAllOptions (search) {
      return resourceLoader.load('campusList', null, [], search)
    },
  },
  willCome: {
    value: false,
    isValid: true,
    hideTopLabel: true,
    label: 'fields.coauthor.willCome',
    classes: { label: 'smallLabel' },
    component: HseCheckbox,
  },
  offline: {
    value: false,
    isValid: true,
    label: 'fields.coauthor.offline',
  }
}

Object.keys(fields).forEach((key) => {
  fields[key].setValues = function (isValid, value = '') {
    this.value = value
    this.isValid = isValid
  }
})

export default fields