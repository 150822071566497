<template>
  <section class="dates">
    <section v-for="date in favoritesData" :key="date.Date">
      <section class="date">
        <p>
          {{
            $i18n.locale === "ru"
              ? datesMap.get(date.Date).DayRU.split(", ")[0]
              : datesMap.get(date.Date).DayEN.split(", ")[1]
          }}
        </p>
      </section>
      <section class="sessions">
        <FavoritesSession
          v-for="session in date.Sessions"
          :date="date.Date"
          :datesMap="datesMap"
          :key="session.Id"
          :session="session"
          :sectionCode="session.SectionCode"
          :updateEventFavorites="updateEventFavorites"
          :sessionTypeId="sessionTypeId"
          :roundtableTypeId="roundtableTypeId"
        />
      </section>
    </section>
  </section>
</template>

<script>
import FavoritesSession from "./sessions//FavoritesSession";

export default {
  props: {
    favoritesData: Array,
    datesMap: Map,
    updateEventFavorites: Function,
    sessionTypeId: String,
    roundtableTypeId: String,
  },
  components: { FavoritesSession },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;

  --color-text-grey: #a5a3a1;
}

.dates {
  background: white;
  padding: 0 1em 1em;
  border-radius: 0 0 10px 10px;

  display: flex;
  flex-direction: column;
  gap: 1em;
}

.date > p {
  padding: 0.95em 0;

  width: 100%;
  color: var(--color-text-grey);

  font-size: 0.85em;
  text-transform: uppercase;
}

.sessions {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
</style>
