<template>
  <div :class="{ 'recieved': isStatusRecieved }" class="rmi_wrapper">
    <div class="row_header" v-on:click="load(value.Id)"><b>{{ value.numberRequest }}</b> {{ value.Status }}</div>
    <ul class="cols-3" v-on:click="e => load(value.Id, e)">
      <li>
        <div><b>{{ value.Fio }}</b></div>
        <div>{{ value.ReportTitle }}</div>
        <div>{{ value.ApplicantCompanyShortName }}</div>
        <div v-if="value.QuasiIdentifier"><small>{{ $t('quasiIdentifier') }}: {{ value.QuasiIdentifier }}</small></div>
      </li>
      <li>
        <div v-if="value.ExpertReviews !== null">
          <div v-for="expert in value.ExpertReviews" :key="expert.ExpertFio">
            <div>{{ $t('expert') }}: {{ expert.ExpertFio }}</div>
            <div>
              <small v-for="(expertScore) in expertScoresFormat(expert)" :key="expertScore[0]">
                {{ expertScore[0] }}: {{ expertScore[1] }}
              </small>
            </div>
            <div v-if="expert.TotalResultTitle"><small>{{ $t('totalResultTitle') }}: {{ expert.TotalResultTitle }}</small>
            </div>
            <div v-if="expert.Comment"><small>{{ $t('comment') }}: {{ expert.Comment }}</small></div>
          </div>
        </div>
      </li>
      <li @click.stop class="final_score">
        <div class="hse-RichInput__label">{{ $t('files') }}</div>
        <small>
          <a v-if="getIsRuAnnotation(value)" class="link" :href="`/files/download_file?id=${value.ThesesFileID}`">{{
            $t('annotaion') }}</a><br>
          <a v-if="value.ThesesFileEnID !== null" class="link"
            :href="`/files/download_file?id=${value.ThesesFileEnID}`">{{ $t('annotaionEng') }}</a>
        </small>
        <div v-if="!isStatusCanceled" class="flex_sb" :style="{ alignItems: 'center', minHeight: '32px' }">
          <small>
            {{ $t('finalScoreTitle') }}:
            <span :class="finalScoreClass">
              {{ formattedFinalScore }}
            </span>
          </small>
          <HseIconButton v-if="hasChangeFinalScore" :style="{ transform: `rotate(${isEditFinalScore ? 180 : 0}deg)` }"
            :icon="IconActionArrowDropdownBottom" variant="ghost" size="small" @click="editFinalScore" />
        </div>
        <div v-if="isEditFinalScore" class="flex_sb" :style="{ marginTop: '4px' }">
          <Validator :style="{ flex: 1 }" :field="finalScoreFields" />
          <HseIconButton :style="{ marginLeft: '6px' }" :icon="IconActionCheck"
            :inactive="!finalScoreFields.value || !isChangedFinalScore" size="small" @click="handleChangeFinalScore" />
        </div>
      </li>
    </ul>
    <HseDivider color="dark" />
  </div>
</template>

<script>
import { dateFormatter } from '../common/dateFormatter'
import {
  HseDivider,
  HseIconButton,
  HseSelect,
  IconActionArrowDropdownBottom,
  IconActionCheck
} from '@hse-design/vue'
import { mapState } from 'vuex'
import Validator from '../common/form/Validator.vue'
import fields from '../requestsPage/managerFields'
import { STATUS_ID_CANCELED, STATUS_ID_RECIEVED, STATUSES_TO_CHANGE_FINAL_SCORE } from '../requestsPage/constants'
import { resourceLoader } from '../common/resourceLoader'
import { localizeFields } from '../../i18n'

export default {
  components: {
    HseDivider,
    Validator,
    HseIconButton,
  },
  props: ['value'],
  data: () => ({
    IconActionArrowDropdownBottom,
    IconActionCheck,
    statusesToChangeFinalScore: STATUSES_TO_CHANGE_FINAL_SCORE,
    fields,
    finalScoreFields: {
      value: '',
      size: 'small',
      component: HseSelect,
      isValid: false,
      options: [],
      setValues: () => { },
    },
    isEditFinalScore: false
  }),
  created() {
    this.translateFields()
    this.loadOptions()
    this.finalScoreFields.setValues = (isValid, val) => this.onSetValue(isValid, val)
  },
  methods: {
    getFinalScoreOptions() {
      return resourceLoader.load('expertTotalResultOptionsListShort', {}, [], '')
    },
    loadOptions() {
      this.getFinalScoreOptions()
        .then((options) => {
          this.finalScoreFields.options = Object.freeze(options)
          this.finalScoreFields.value = this.value.FinalScoreId
        })
        .catch(console.error);
    },
    editFinalScore() {
      this.isEditFinalScore = !this.isEditFinalScore
    },
    translateFields() {
      this.fields = localizeFields(fields, this.fields)
    },
    getIsRuAnnotation(value) {
      return value.ThesesFileID !== null
        && value.ApplicantLanguageId !== 'D909F362-2610-49C9-BFA2-57692617EF76'
        && this.lang === 'ru'
    },
    onSetValue(isValid, value = '') {
      this.finalScoreFields.value = value
      this.isValid = isValid
    },
    load(request_id, e) {
      if (e && e.target.classList.contains('link')) return

      this.$router.push('/manager_requests/' + request_id)
    },
    handleChangeFinalScore() {
      this.$emit('changeFinalScore', {
        id: this.value.Id,
        scoreId: this.finalScoreFields.value
      })
    },
    expertScoresFormat(expert) {
      return [
        [this.$t('novelty'), expert.Novelty],
        [this.$t('actuality'), expert.Actuality],
        [this.$t('depth'), expert.Depth],
      ].filter((v) => v?.[1]).map((v, idx, arr) => [v[0], `${v[1]}${idx < arr.length - 1 ? '; ' : ''}`])
    },
    dateFormat(date) { return dateFormatter.format(date) },
  },
  computed: {
    finalScoreClass() {
      const items = [
        ['bg-green', 'Принять'],
        ['bg-red', 'Отклонить'],
        ['bg-gray', 'В резерв'],
      ]
      return items.find((v) => v[1] === this.value.FinalScoreTitle)?.[0] ?? ''
    },
    formattedFinalScore() {
      return this.value.FinalScoreTitle ?? 'нет'
    },
    isChangedFinalScore() {
      return this.value.FinalScoreId !== this.finalScoreFields.value
    },
    isStatusRecieved() {
      return this.value.StatusId === STATUS_ID_RECIEVED
    },
    isStatusCanceled() {
      return this.value.StatusId === STATUS_ID_CANCELED
    },
    hasChangeFinalScore() {
      return this.statusesToChangeFinalScore.find((v) => v === this.value.StatusId)
    },
    ...mapState(['lang'])
  },
  watch: {
    lang() {
      this.requestItems()
    }
  },
  i18n: {
    messages: {
      en: {
        status: 'Status',
        annotaion: 'Annotaion',
        annotaionEng: 'Annotaion (en)',
        files: 'Files',
        finalScoreTitle: 'Total result',
        novelty: 'Novelty',
        actuality: 'Actuality',
        depth: 'Depth',
        totalResultTitle: 'Summary',
        expert: 'Expert',
        quasiIdentifier: 'Collective request',
        comment: 'Comment',
        noScore: 'No score',
      },
      ru: {
        status: 'Статус',
        annotaion: 'Аннотация',
        annotaionEng: 'Аннотация (англ)',
        files: 'Файлы',
        finalScoreTitle: 'Финальная оценка',
        novelty: 'Новизна',
        actuality: 'Актуальность',
        depth: 'Глубина',
        totalResultTitle: 'Заключение',
        expert: 'Эксперт',
        quasiIdentifier: 'Коллективная заявка',
        comment: 'Комментарий',
        noScore: 'Нет оценки',
      },
    }
  }
}
</script>

<style scoped>
.rmi_wrapper ul {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0px;
}

.rmi_wrapper li {
  list-style: none;
}

.flex_sb {
  display: flex;
  justify-content: space-between;
}

.final_score {
  cursor: default;
}

.form-description {
  padding-top: 12px;
  font-size: 14px;
  line-height: 20px;
  color: #6B7A99;
}

@media (max-width: 1024px) {
  .emptyfield {
    display: none;
  }
}

@media (max-width: 1000px) {
  .cols-3 {
    display: block !important;
  }


}

.cols-4 {
  display: grid;
  grid-template-columns: minmax(200px, 2fr) 2fr 120px 2fr;
  gap: 16px;
  cursor: pointer;
  padding: 4px 0;
  white-space: normal;
  /* overflow-x: auto; */
}

.cols-3 {
  display: grid;
  grid-template-columns: minmax(200px, 2fr) 2fr 232px;
  gap: 16px;
  cursor: pointer;
  padding: 4px 0;
  white-space: normal;
  /* overflow-x: auto;  */
}

.row_header {
  background-color: rgb(225, 228, 235);
  color: rgb(107, 122, 153);
  font-size: 16px;
  font-family: Proxima Nova, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  padding: 8px;
  cursor: pointer;
}

.recieved {
  background-color: rgba(240, 245, 255, 50%);
}

.bg-red {
  background-color: #FFBEAD;
}

.bg-green {
  background-color: #AFE1CD;
}

.bg-gray {
  background-color: #E1E4EB;
}
</style>
