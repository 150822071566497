<template>
  <div class="pagination">
    <div class="page" v-for="(page, i) in new Array(pagesCount)" :key="i">
      <div class="btn" :class="{ active: current === i + 1 }" @click="() => changePage(i)">
        {{ i + 1 }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['current', 'pagesCount'],
    methods: {
      changePage (i) {
        this.$emit('changePage', i + 1)
      },
    },
  }
</script>

<style scoped>
  .pagination {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    margin: 0 -2px;
  }
  .page {
    padding: 0 2px;
  }
  .btn {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
  }
  .btn.active {
    background: #0050CF;
    color: #fff;
    cursor: default;
  }
</style>
