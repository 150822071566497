<template>
  <div class="form">
    <div class="subheading">{{ $t('personal') }}</div>
    <HseSpace size="small" vertical />
    <div class="cols two-cols">
      <ReadonlyInput v-if="lang == 'ru'" :label="`${$t('fields.name.lName')} *`" :value="user.LName" />
      <ReadonlyInput v-if="lang == 'ru'" :label="`${$t('fields.name.fName')} *`" :value="user.FName" />
      <ReadonlyInput v-if="lang == 'ru'" :label="`${$t('fields.name.mName')}`" :value="user.SName" />
      <ReadonlyInput v-if="lang == 'en'" :label="`${$t('fields.name.fNameEn')}`" :value="user.FNameEN" />
      <ReadonlyInput v-if="lang == 'en'" :label="`${$t('fields.name.lNameEn')}`" :value="user.LNameEN" />
      <ReadonlyInput :label="`${$t('fields.name.email')} *`" :value="$store.state.user.login" />
    </div>
    <Validator :field="fields.isParticipant" @change="fillUserData" />
    <HseSpace size="small" vertical />
    <div class="subheading">{{ $t('info') }}</div>
    <HseSpace size="small" vertical />
    <div class="cols two-cols">
      <div v-show="isLangRu" class="field">
        <Validator :field="fields.applicantLastName" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field">
        <Validator :field="fields.applicantLastNameEN" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div v-show="isLangRu" class="field">
        <Validator :field="fields.applicantFirstName" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field">
        <Validator :field="fields.applicantFirstNameEN" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div v-show="isLangRu" class="field">
        <Validator :field="fields.applicantMiddleName" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field">
        <Validator :field="fields.gender" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field">
        <Validator :field="fields.applicantEmail" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field">
        <Validator :field="fields.applicantPhone" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field">
        <Validator :field="fields.dateOfBirth" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field">
        <Validator v-if="lang === 'ru'" :field="fields.applicantLanguageId" />
        <Validator v-if="lang === 'en'" :disabled="true" :field="fields.applicantLanguageId" />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>
    <!--    <div class="field">-->
    <!--      <Validator :field="fields.degreeId" />-->
    <!--      <HseSpace size="small_2x" vertical />-->
    <!--    </div>-->
    <div class="cols two-cols">
      <div class="field">
        <div class="flex-tooltip">
          <Validator :field="fields.сitizenshipCountryId" />
          <HseTooltip :content="$t('fields.placeholder.citizen')">
            <IconStatusInfo />
          </HseTooltip>
        </div>
        <HseSpace size="small_2x" vertical />
      </div>
      <!--      <div class="field">-->
      <!--        <Validator :field="fields.applicantCountryId" v-on:change="toggleRegion" />-->
      <!--        <HseSpace size="small_2x" vertical />-->
      <!--      </div>-->
    </div>
    <!--    <div class="cols" :class="{ 'two-cols': !isCountryRu, 'three-cols': isCountryRu }">-->
    <!--      <div class="field" v-if="isCountryRu">-->
    <!--        <Validator :field="fields.regionID" />-->
    <!--        <HseSpace size="small_2x" vertical />-->
    <!--      </div>-->
    <!--      <div class="field">-->
    <!--        <Validator :field="fields.applicantCity" />-->
    <!--        <HseSpace size="small_2x" vertical />-->
    <!--      </div>-->
    <!--      <div class="field">-->
    <!--        <Validator v-if="lang === 'ru'" :field="fields.applicantLanguageId" />-->
    <!--        <Validator v-if="lang === 'en'" :disabled="true" :field="fields.applicantLanguageId" />-->
    <!--        <HseSpace size="small_2x" vertical />-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import { store } from '../../store'
import { HseSpace, HseTooltip, IconStatusInfo } from '@hse-design/vue'
import Validator from '../common/form/Validator.vue'
import ReadonlyInput from '../common/ReadonlyInput.vue'
import { mapState } from 'vuex'
export default {
  name: 'FormPage2',
  props: ['fields'],
  store,
  components: {
    Validator,
    HseSpace,
    HseTooltip,
    IconStatusInfo,
    ReadonlyInput,
  },
  data: () => ({
    user: {},
    ruFields: ['applicantLastName', 'applicantFirstName', 'applicantMiddleName'],
    pageFieldKeys: [
      'applicantLastName', 'applicantFirstName', 'applicantFirstNameEN', 'applicantLastNameEN',
      'gender', 'applicantPhone', 'applicantEmail', 'сitizenshipCountryId', 'dateOfBirth', 'applicantLanguageId'
    ],
    isCountryRu: false,
  }),
  watch: {
    lang: function () {
      this.fillUserLang()
    }
  },
  computed: {
    isCitizenRu() {
      return this.fields.сitizenshipCountryId.value === 'BC763F4B-FBD9-461B-B395-166020E8C026'
    },
    isLangRu() {
      return this.$store.state.lang === 'ru'
    },
    fieldsToValidate() {
      return this.pageFieldKeys.filter(key => {
        if (key === 'regionID' && !this.isCountryRu) return false
        else if (this.ruFields.includes(key) && !this.isLangRu) return false
        return true
      })
    },
    ...mapState(['lang']),
  },
  updated() {
    this.fillUserLang()
  },
  created() {
    this.fillUserLang()
    this.user = this.$store.state.user.data.public
    this.$parent.$on('validatePage', ({ current }) => {
      if (current === 2) {
        this.$emit('validateFields')
        this.checkPageValidity()
      }
    })
    this.$parent.$on('fieldsValChange', () => {
      this.$emit('validateFields')
      this.toggleRegion()
    })
    this.toggleRegion()
  },
  methods: {
    fillUserLang() {
      if (this.lang === 'en') {
        this.fields.applicantLanguageId.setValues(true, 'D909F362-2610-49C9-BFA2-57692617EF76')
      }
    },
    fillUserData(isFill) {
      this.$emit('fillWithUserData', isFill)
    },
    checkPageValidity() {
      const isPageValid = !this.fieldsToValidate.some(key => {
        return !this.fields[key].isValid
      })
      this.$emit('validatePage', { page: 2, isValid: isPageValid })
    },
    toggleRegion() {
      this.isCountryRu = this.fields.applicantCountryId.value === 'BC763F4B-FBD9-461B-B395-166020E8C026'
      this.fields.regionID.setValues(!this.isCountryRu, this.fields.regionID.value)
    }
  },
  i18n: {
    messages: {
      en: {
        personal: 'Information about applicant',
        info: 'Information about participant/speaker',
      },
      ru: {
        personal: 'Информация о заявителе',
        info: 'Информация об участнике/докладчике',
      },
    }
  },
}
</script>

<style scoped>
.form {
  max-width: 670px;
}

.cols {
  display: flex;
  margin: 0 -15px;
  flex-wrap: wrap;
}

.cols>.field {
  padding: 0 15px;
  flex: 1;
}

.two-cols .field {
  max-width: 50%;
  width: 50%;
  flex-basis: 50%;
}

.three-cols .field {
  max-width: 33.3%;
}

@media (max-width: 767px) {
  .form {
    max-width: unset;
  }

  .two-cols .field,
  .three-cols .field {
    max-width: 100%;
    width: 100%;
    flex-basis: 100%;
  }
}

.flex-tooltip {
  display: flex;
}

.flex-tooltip>div:first-child {
  width: calc(100% - 28px)
}

.flex-tooltip>div:last-child {
  padding: 36px 0 0 12px;
}
</style>
