<template>
    <div>
        <HseModalInfo :modal-class="'after-send-message-modal'" v-model="isOpen" :modal-style="{ maxWidth: '350px' }" ref="modal">
            <div v-if="!modalText">{{ $t('text') }}</div>
            <div v-else >{{ modalText }}</div>
        </HseModalInfo>
    </div>
</template>

<script>
    import { HseModalInfo,  } from '@hse-design/vue'
    import { store } from '../../../store'

    export default {
        store,
        components: {
            HseModalInfo,
        },
        props: {
            modalText:String,
        },
        data: () => ({
            isOpen: false,
            isLoading: false,
        }),
        created () {
            this.$root.$refs.afterSendMessageModal = this;
        },
        methods: {
            openModal() {
                this.isOpen = true;
            }
        },
        i18n: {
            messages: {
                en: {
                    text: 'Message sent'
                },
                ru: {
                    text: 'Сообщение отправлено'
                }
            },
        }
    }
</script>

<style>
    .after-send-message-modal .hse-BaseModal__wrapper {
        min-height: 120px;
        text-align: center;
        padding: 47px;
    }
</style>