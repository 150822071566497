<template>
  <section class="program">
    <section class="program__filters">
      <HseSelect
        class="filters__select"
        v-model="selectedSection"
        :options="filterOptions.sections"
        placeholder=""
      />
      <HseSegmentedControl
        class="filters__dates filters__segmented-control"
        v-model="selectedDate"
        :items="filterOptions.dates"
      />
      <HseInput
        class="filters__search"
        :leftIcon="searchIcon"
        :placeholder="$t('search')"
        v-model="searchTerm"
      />
      <HseSelect
        class="filters__select"
        v-model="selectedAddress"
        :options="filterOptions.addresses"
        placeholder=""
      />
      <HseSegmentedControl
        class="filters__type filters__segmented-control"
        v-model="selectedSessionType"
        :items="filterOptions.sessionTypes"
      />
    </section>
    <section class="program__days">
      <section
        class="event-day"
        v-for="date in filteredByWordsData"
        :key="date.Date"
      >
        <section class="date">
          <h2 class="day">
            {{ $t("day") }}
            <span class="day__number">{{ date.DayNumber }}</span>
          </h2>
          <p>
            {{
              $i18n.locale === "ru"
                ? datesMap.get(date.Date).DayRU
                : datesMap.get(date.Date).DayEN
            }}
          </p>
        </section>
        <section class="sections">
          <div class="sections__space" />
          <div class="sections__items">
            <section
              class="section"
              v-for="section in date.Sections"
              :key="section.Id + date.Date + section.IdExtend"
            >
              <!-- <h3 class="section__title text-normal">
                {{ `${$t("section")} ${section.Code}. ${section.Title}` }}
              </h3> -->
              <h3
                class="section__title text-normal"
                v-html="
                  $t('section') +
                  highlightSearchResults(
                    ` ${section.Code}. ${section.Title}`,
                    searchTerm
                  )
                "
              ></h3>

              <div class="div_manager">
                <p
                  v-if="
                    section.Manager &&
                    section.Sessions.length &&
                    section.Sessions[0].TypeId !== invitedReportTypeId
                  "
                  class="section__manager text-boldest"
                >
                  {{ $tc("manager", section.Manager.split(", ").length) }}:{{
                    "\u00A0"
                  }}
                </p>
                <p
                  v-if="
                    section.Manager &&
                    section.Sessions.length &&
                    section.Sessions[0].TypeId !== invitedReportTypeId
                  "
                  class="section__manager text-boldest"
                  v-html="highlightSearchResults(section.Manager, searchTerm)"
                ></p>
              </div>
              <SectionsSession
                v-for="session in section.Sessions"
                :key="session.Id"
                :date="date"
                :hasPaid="hasPaid"
                :section="section"
                :session="session"
                :datesMap="datesMap"
                :roomsMap="roomsMap"
                :updateEventFavorites="updateEventFavorites"
                :invitedReportTypeId="invitedReportTypeId"
                :sessionTypeId="sessionTypeId"
                :bookLaunchTypeId="bookLaunchTypeId"
                :roundtableTypeId="roundtableTypeId"
                :honoraryPaperTypeId="honoraryPaperTypeId"
                :searchTerm="searchTerm"
              />
            </section>
          </div>
        </section>
      </section>
    </section>
  </section>
</template>
<script>
import {
  HseInput,
  HseSegmentedControl,
  HseSelect,
  IconActionSearch,
} from "@hse-design/vue";
import SectionsSession from "./sessions/SectionsSession";

export default {
  data() {
    return {
      searchTerm: "",
      selectedSection: "all",
      selectedDate: "all",
      selectedAddress: "all",
      selectedSessionType: "all",
      searchIcon: IconActionSearch,
    };
  },
  props: {
    hasPaid: Boolean,
    sectionsData: Array,
    filterOptions: Object,
    datesMap: Map,
    roomsMap: Map,
    updateEventFavorites: Function,
    invitedReportTypeId: String,
    sessionTypeId: String,
    bookLaunchTypeId: String,
    roundtableTypeId: String,
    honoraryPaperTypeId: String,
  },
  methods: {
    highlightSearchResults(theText, searchTerms) {
      const re = new RegExp(searchTerms.trim(), "gi");
      return theText.replace(
        re,
        (match) => `<span style="background-color:#ffff00">${match}</span>`
      );
    },
    filterByWords(event, words) {
      const searchWords = words.toLowerCase().trim().split(" ");

      return event.filter((event) => {
        event.Sections = event.Sections.filter((section) => {
          let searchSections = true;
          let searchItems = false;
          let searchSessions = false;
          const toSearch = `${section.Title} ${section.Manager}`.toLowerCase();
          for (const word of searchWords) {
            searchSections = searchSections && toSearch.includes(word);
          }

          const filteredSessions = section.Sessions.filter((session) => {
            let wordFilter = true;
            const toSearch =
              `${session.Title} ${session.Manager} ${session.RoomTitle} ${session.Debutant}`.toLowerCase();
            for (const word of searchWords) {
              wordFilter = wordFilter && toSearch.includes(word);
            }

            for (const {
              Authors,
              Title,
              Discussion,
              Manager,
              Rapporteur,
              Participants,
              Annotation,
            } of session.Items) {
              const toSearch =
                `${Authors} ${Title} ${Discussion} ${Manager} ${Rapporteur} ${Participants} ${Annotation}`.toLowerCase();
              let wordFilter = true;
              for (const word of searchWords) {
                wordFilter = wordFilter && toSearch.includes(word);
              }

              searchItems = searchItems || wordFilter;
            }

            searchSessions = searchSessions || wordFilter;
            return wordFilter || searchItems;
          });

          if (!searchSections && filteredSessions.length) {
            section.Sessions = filteredSessions;
          }

          return searchSections || searchSessions || searchItems;
        });
        return event.Sections.length;
      });
    },
    filterEvents(
      event,
      selectedSection,
      selectedFullDate,
      selectedSessionType,
      selectedAddress
    ) {
      const roomIds = selectedAddress?.split("//");

      const [selectedMonth, selectedDate] = selectedFullDate
        .split("/")
        .map((elem) => parseInt(elem));

      return event.filter((event) => {
        event.Sections = event.Sections.filter((section) => {
          section.Sessions = section.Sessions.filter(
            (session) =>
              (selectedSessionType === "all" ||
                selectedSessionType === session.TypeId) &&
              (selectedAddress === "all" ||
                !selectedAddress ||
                roomIds.includes(session.RoomId))
          );

          return (
            section.Sessions.length &&
            (selectedSection === "all" ||
              !selectedSection ||
              selectedSection === section.IdSql)
          );
        });
        const fullDate = new Date(event.Date);
        const date = fullDate.getDate();
        const month = fullDate.getMonth();
        return (
          event.Sections.length &&
          (selectedFullDate === "all" ||
            (selectedMonth === month && selectedDate === date))
        );
      });
    },
  },
  computed: {
    filteredByWordsData() {
      return this.filterByWords(
        JSON.parse(JSON.stringify(this.filteredData)),
        this.searchTerm
      );
    },
    filteredData() {
      return this.filterEvents(
        JSON.parse(JSON.stringify(this.sectionsData)),
        this.selectedSection,
        this.selectedDate,
        this.selectedSessionType,
        this.selectedAddress
      );
    },
  },
  watch: {
    selectedAddress() {
      this.selectedSessionType = "all";
      this.$emit("selectedAddress", { selectedAddress: this.selectedAddress });
    },
    selectedDate() {
      this.selectedAddress = "all";
      this.selectedSessionType = "all";
      this.$emit("selectedDate", { selectedDate: this.selectedDate });
    },
    selectedSessionType() {
      this.$emit("selectedSessionType", {
        selectedSessionType: this.selectedSessionType,
      });
    },
    selectedSection() {
      this.selectedDate = "all";
      this.selectedAddress = "all";
      this.selectedSessionType = "all";
      this.$emit("selectedSection", { selectedSection: this.selectedSection });
    },
  },
  i18n: {
    messages: {
      en: {
        search: "Search",
        section: "Section",
        day: "Day",
        manager: "Moderator | Moderators",
      },
      ru: {
        search: "Поиск по программе",
        section: "Секция",
        day: "День",
        manager: "Руководитель | Руководители",
      },
    },
  },
  components: { HseSelect, HseSegmentedControl, HseInput, SectionsSession },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  --day-color: #1658da;
  --background-color: #e7eefb;
}

.program__filters {
  background-color: white;
  border-radius: 0 0 10px 10px;

  padding: 1em;

  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.filters__select {
  width: 100% !important;
}

.filters__search {
  width: 100%;
}

.filters__dates {
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr;
  color: #000;
}

.filters__segmented-control {
  gap: 0.2em;
}

.filters__segmented-control >>> .hse-SegmentedControl__item {
  padding: 5px;

  border: 1px solid transparent;
  border-radius: 100px;

  color: #000;
  font-size: 17px;
  line-height: 16px;
  font-weight: 400;
}

.filters__segmented-control >>> .hse-SegmentedControl__item:hover {
  background: #d4eeff;
}

.filters__segmented-control >>> .hse-SegmentedControl__item::after {
  content: none;
}

.filters__segmented-control >>> .hse-SegmentedControl__item_active {
  background: transparent;
  border: 1px solid #000;
  border-radius: 100px;
  font-weight: 600;
}

.filters__type {
  display: flex;
  flex-wrap: wrap;
}

.text-normal {
  font-weight: 400;
}

.text-boldest {
  font-weight: 900;
}

.program__days {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em 0;
}

.event-day {
  background-color: white;
  padding: 1em;
  border-radius: 10px;
}

.date {
  margin-top: 5px;

  display: flex;
  gap: 0.5em;
}

.date > p {
  margin: 0;
  padding: 0.95em 0.56em;

  background-color: var(--background-color);
  width: 100%;

  text-transform: uppercase;
  font-size: 1em;
}

.day {
  position: relative;
  top: -5px;
  left: -5px;

  padding: 10px 5px 0;
  width: 55px;
  min-height: 76px;

  font-size: 0.8125em;
  font-weight: 400;
  line-height: initial;

  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  color: #fff;
  background-color: var(--day-color);
}

.day > span {
  font-size: 2.8em;
  line-height: 1;
}

.day::before {
  content: "";
  position: absolute;
  top: 5px;
  right: -5px;
  width: 5px;
  height: 100%;
  background-color: var(--background-color);
}

.day::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 5px;
  width: 55px;
  height: 5px;
  background-color: var(--background-color);
}

.sections {
  display: flex;
}

.sections__items {
  padding-top: 2em;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 2em;
}

.section {
  border-bottom: solid 1px #e0e0e0;
  padding-bottom: 2em;
}

.section:last-child {
  border-bottom: none;
}

.section__title {
  font-size: 1.125em;
}

.section__manager {
  font-size: 0.8125em;
}

.div_manager {
  display: flex;
}

@media (max-width: 744px) {
  .div_manager {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 528px) {
  .filters__dates {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1024px) {
  .sections {
    gap: calc(55px + 0.5em);
  }

  .filters__dates {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (min-width: 1405px) {
  .filters__dates {
    grid-template-columns: repeat(9, 1fr);
  }
}
.hl-search {
  background-color: yellow;
}
</style>
