<template>
  <div class="loading">
    <IconGlobalButtonLoader size="large" />
  </div>
</template>

<script>
  import { IconGlobalButtonLoader } from '@hse-design/vue'
  export default {
    name: 'Loading',
    components: { IconGlobalButtonLoader }
  }
</script>

<style scoped>
  .loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .loading svg {
    animation: rotation 1s linear infinite;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
