<template>
  <div>
    <div v-if="variantMenu === 'static'">
      <HseSidebar v-model="$store.state.isLeftMenuOpen" :show-toggle="true" :classes="{}" :withHeader="true" ref="sidebar"
        variant="static">
        <div class="mobile-items top">
          <LangDropdown />
          <RolesDropdown id="left" />
        </div>
        <div class="mobile-items">
          <HseSpace size='small' vertical />
        </div>
        <router-link v-for="item in items" :key="item.id" :to="item.href" custom v-slot="{ isActive }">
          <hse-tooltip v-if="!(item.id === 8 && !userHaveCollectiveRequest || item.id === 2 && !userHasEvents)">
            <template #content>
              <div>{{ item.name }}</div>
              <template v-if="item.id === 3">
                <div v-for="notification of $store.state.menuNotifications" :key="notification">{{ notification }}</div>
              </template>
            </template>
            <HseSidebarItem :icon="item.icon" :label="item.id === 3 ? $store.state.countNotifications : 0"
              :selected="isActive" :classes="{}" @click="() => onSidebarItemClick(item)">
              {{ item.name }}
            </HseSidebarItem>
          </hse-tooltip>
        </router-link>
        <div class="mobile-items">
          <router-link v-for="item in mobileItems" :key="item.id" :to="item.href" custom v-slot="{ isActive }">
            <HseSidebarItem v-if="!(item.id === 8 && !userHaveCollectiveRequest)" :icon="item.icon"
              :label="item.id === 3 ? $store.state.countNotifications : 0" :selected="isActive"
              :classes="item.classes ? item.classes : {}" @click="() => onSidebarItemClick(item)">
              {{ item.name }}
            </HseSidebarItem>
          </router-link>
        </div>
      </HseSidebar>
    </div>
    <div v-if="variantMenu === 'dynamic'">
      <HseSidebar v-model="$store.state.isLeftMenuOpen" :show-toggle="true" :classes="{}" :withHeader="true" ref="sidebar"
        variant="dynamic">
        <div class="mobile-items top">
          <LangDropdown />
          <RolesDropdown id="left" />
        </div>
        <div class="mobile-items">
          <HseSpace size='small' vertical />
        </div>
        <router-link v-for="item in items" :key="item.id" :to="item.href" custom v-slot="{ isActive }">
          <hse-tooltip v-if="!(item.id === 8 && !userHaveCollectiveRequest || item.id === 2 && !userHasEvents)">
            <template #content>
              <div>{{ item.name }}</div>
              <template v-if="item.id === 3">
                <div v-for="notification of $store.state.menuNotifications" :key="notification">{{ notification }}</div>
              </template>
            </template>
            <HseSidebarItem :icon="item.icon" :label="item.id === 3 ? $store.state.countNotifications : 0"
              :selected="isActive" :classes="{}" @click="() => onSidebarItemClick(item)">
              {{ item.name }}
            </HseSidebarItem>
          </hse-tooltip>
        </router-link>
        <div class="mobile-items">
          <router-link v-for="item in mobileItems" :key="item.id" :to="item.href" custom v-slot="{ isActive }">
            <HseSidebarItem v-if="!(item.id === 8 && !userHaveCollectiveRequest)" :icon="item.icon"
              :label="item.id === 3 ? $store.state.countNotifications : 0" :selected="isActive"
              :classes="item.classes ? item.classes : {}" @click="() => onSidebarItemClick(item)">
              {{ item.name }}
            </HseSidebarItem>
          </router-link>
        </div>
      </HseSidebar>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { menu } from './menuItems'
import { api } from '../../../api'
import { store } from '../../../store'
import { routes } from '../../../router'
import {
  HseSidebar,
  HseSidebarItem,
  HseSpace,
  IconGlobalUserFilled,
  IconNavigationEntrance,
  IconGlobalAlarm,
  HseTooltip,
} from '@hse-design/vue'
import LangDropdown from '../topmenu/LangDropdown.vue'
import RolesDropdown from '../topmenu/RolesDropdown.vue'

export default {
  store,
  components: {
    HseSidebar,
    HseSidebarItem,
    HseSpace,
    LangDropdown,
    RolesDropdown,
    HseTooltip,
  },
  data: () => ({
    userHaveCollectiveRequest: false,
    userHasEvents: false,
    isWidthOK: false,
  }),
  updated() {
    this.getRequestNotification()
  },
  mounted() {
    this.getRequestNotification()
    this.checkPaths()
    this.isHaveCollectiveRequest()
    this.checkMyEvents()
  },
  created() {
    const mql = window.matchMedia('(max-width: 769px)');
    const onChange = () => this.isWidthOK = mql.matches;
    onChange();
    mql.addEventListener('change', onChange);
    this.$once('hook:beforeDestroy', () => mql.removeEventListener('change', onChange));
  },
  computed: {
    variantMenu() {
      return this.isWidthOK ? 'dynamic' : 'static';
    },
    mobileItems() {
      return [{
        id: 100,
        name: 'menu.messages',
        href: '/messages',
        icon: IconGlobalAlarm,
      }, {
        id: 101,
        name: 'menu.profile',
        href: '/profile',
        classes: { icon: 'smaller' },
        icon: IconGlobalUserFilled,
      }, {
        id: 102,
        name: 'menu.logout',
        href: '/logout',
        action: () => api.logout(),
        icon: IconNavigationEntrance,
      }].map(item => {
        item.name = this.$i18n.t(item.name)
        return item
      })
    },
    items() {
      return menu.getItems(this.$store.state.role).filter(item => {
        if (!this.userHasEvents && item.href === '/my-events') {
          return false
        } else {
          return true
        }
      }).map(item => {
        const translatedItem = { ...item }
        translatedItem.name = this.$i18n.t(item.name)
        return translatedItem
      })
    },
    ...mapState(['role', 'isLeftMenuOpen']),
  },
  watch: {
    role() {
      this.checkPaths()
    },
    isLeftMenuOpen(isOpen) {
      if (isOpen) {
        addEventListener('click', this.checkOutsideClick)
      } else {
        removeEventListener('click', this.checkOutsideClick)
      }
    }
  },
  methods: {
    getRequestNotification() {
      api.request("get_requests_notification_status").then(res => {
        store._mutations.setMenuNotifications[0](res)
        menu.getItems(this.$store.state.role).map(item => {
          if (item.id === 3) {
            store._mutations.setCountNotifications[0](this.$store.state.menuNotifications.length)
          }
          return item
        })
      })
    },
    isHaveCollectiveRequest() {
      api.request("user_have_collective_request").then(res => {
        this.items.forEach(item => {
          if (item.id === 8) {
            if (!res.userHaveCR) {
              this.userHaveCollectiveRequest = false
            } else {
              this.userHaveCollectiveRequest = true
            }
          }
        })
      })
    },
    checkMyEvents() {
      api.request("user_have_rt", null).then(res => this.userHasEvents = this.userHasEvents || res)
      api.request("user_have_hor", null).then(res => this.userHasEvents = this.userHasEvents || res)
    },
    onSidebarItemClick(item) {
      this.$store.state.isLeftMenuOpen = false
      if (this.$route.path === item.href && !item.action) return

      item.action ? item.action() : this.$router.push(item.href)
    },
    checkOutsideClick(e) {
      const { sidebar } = this.$refs
      if (!sidebar) return

      const hseHamburger = this.$root.$el.querySelector('.hseHeaderHamburger')

      const isClickInside = sidebar.$el.contains(e.target) || (hseHamburger ? hseHamburger.contains(e.target) : false)
      if (!isClickInside) this.$store.state.isLeftMenuOpen = false
    },
    checkPaths() {
      const [firstMenuItem] = this.items
      const { matched } = this.$route
      if (!matched || !matched.length) {
        this.$router.push(firstMenuItem.href)
      } else {
        const { role: currRole } = this.$store.state
        const { name: routeName } = matched[0]
        const currRoute = routes.find(route => route.name == routeName)
        if (!currRoute) {
          this.$router.push(firstMenuItem.href)
        } else {
          const { roles } = currRoute
          if (roles && roles.length) {
            if (!roles.includes(currRole)) {
              this.$router.push(firstMenuItem.href)
            }
          }
        }
      }
    },
  },
  i18n: {
    messages: {
      en: {
        menu: {
          events: 'Events',
          myRequests: 'My requests',
          collectiveRequests: 'My collective applications',
          myEvents: 'Applications for special events',
          visa: 'Visa help',
          moderateRequests: 'Review Applications',
          experts: 'Experts',
          requests: 'Requests',
          reports: 'Reports',
          messages: 'Notifications',
          profile: 'Profile',
          logout: 'Logout',
        },
      },
      ru: {
        menu: {
          events: 'Мероприятия',
          myRequests: 'Мои заявки',
          collectiveRequests: 'Мои коллективные заявки',
          myEvents: 'Заявки на специальные мероприятия',
          visa: 'Визовая поддержка',
          moderateRequests: 'Заявки на оценку',
          experts: 'Эксперты',
          requests: 'Заявки',
          reports: 'Отчеты',
          messages: 'Уведомления',
          profile: 'Профиль',
          logout: 'Выйти',
        },
      },
    }
  }
}
</script>
<style scoped>
.mobile-items {
  display: none;
}

@media (max-width: 767px) {
  .mobile-items {
    display: block;
  }

  .mobile-items.top {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #6B7A99;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
</style>
<style>
.hse-Sidebar__content .hse-Tooltip.hse-Tooltip_visible {
  position: fixed !important;
}

.hse-SidebarItem__icon {
  background: unset !important;
}

.mobile-items .smaller {
  padding: 27px
}

.mobile-items .smaller svg {
  width: 18px;
  height: 18px;
}
</style>
