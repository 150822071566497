<template>
    <div class="two-cols">
        <div class="logo" v-if="logo1 && lang === 'ru'">
            <img :src="logo1" />   
        </div>
        <div class="logo" v-if="logo1_en && lang === 'en'">
            <img :src="logo1_en" />
        </div>
        <div class="logo" v-if="logo2 && lang === 'ru'">
            <img :src="logo2" />   
        </div>
        <div class="logo" v-if="logo2_en && lang === 'en'">
            <img :src="logo2_en" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'Logos',
    computed: {
        ...mapState(['lang', 'static']),
        logo1(){
            return this.static.logos.logo1
        },
        logo1_en(){
            return this.static.logos.logo1_en
        },
        logo2(){
            return this.static.logos.logo2
        },
        logo2_en(){
            return this.static.logos.logo2_en
        },
    },
}
</script>

<style scoped>
.logo {
    text-align: center;
}

.two-cols {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}
</style>
