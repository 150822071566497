import { HseCheckbox } from '@hse-design/vue'

const fields = {
    offerAgreement: {
        value: false,
        isValid: false,
        hideTopLabel: true,
        classes: { label: 'smallLabel' },
        component: HseCheckbox,
        rules: [{
            name: 'custom',
            message: 'fields.rule.offerAgreement',
            validateVal: value => value
        }],
    },
}


Object.keys(fields).forEach((key) => {
    fields[key].setValues = function (isValid, value = '') {
        this.value = value
        this.isValid = isValid
    }
})

export default fields