<template>
  <div class="datepickerField" ref="datepickerField" :class="{ 'hse-RichInput_hasError': !field.isValid }">
    <div class="hse-RichInput__label">{{ field.label }}</div>
    <HseInput
      v-model="vmodel"
      v-bind="{ ...field, ...$attrs }"
      @input="onChange"
      :fullWidth="true"
      mask="99.99.9999"
      @focus="() => togglePicker(true)"
    />
    <div class="hse-RichInput__message">{{ !field.isValid ? $t(errorMessage) : '' }}</div>
    <div class="datepicker" :class="{ visible: isVisible, right: field.isRight }" ref="calendarWrap">
      <Calendar :isDatepicker="true" @datePicked="setDate" :val="chosen" />
    </div>
  </div>
</template>

<script>
  import { HseInput } from '@hse-design/vue'
  import Calendar from '../Calendar.vue'
  import { DateTime } from 'luxon'
  import {COMPLETE_INPUT_LENGTH} from "@/components/specialEventsPage/constants";

  export default {
    name: 'datepicker',
    props: ['field', 'errorText'],
    components: { HseInput, Calendar },
    data: () => ({
      errorMessage: '',
      isVisible: false,
      vmodel: null,
    }),
    created () {
      this.vmodel = this.value ? this.value : ''
      this.$parent.$on('validateFields', () => this.onChange(this.value))
      this.errorMessage = this.errorText ? this.errorText : ''
    },
    updated () {
      if (this.errorText) {
        this.field.setValues(false, this.field.value)
        this.errorMessage = this.errorText
      }
    },
    methods: {
      togglePicker (isOpen) {
        if (this.isVisible === isOpen) return

        this.isVisible = isOpen
        if (isOpen) {
          addEventListener('click', this.closePicker, false)
        }
      },
      closePicker ({ target }) {
        if (!this.$refs.datepickerField) return

        if (this.isVisible && !this.$refs.datepickerField.contains(target)) {
          this.togglePicker(false)
          removeEventListener('click', this.closePicker, false)
        }
      },
      setDate (day) {
        this.onChange( day.toFormat('dd.MM.yyyy') )
        this.togglePicker(false)
      },
      onChange (val) {
        this.field.setValues(this.field.isValid, val)
        this.$emit('change', val)
        this.validateVal(val)
        if(val.replace(/[.|_]/g,'').length === COMPLETE_INPUT_LENGTH){
          this.togglePicker(false)
        }
      },
      validateVal (val) {
        let isValid = true

        if (this.field.isRequired && !val) {
          isValid = false
          this.errorMessage = 'fields.rule.empty'
        } else if (val && !/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/g.test(val)) {
          isValid = false
          this.errorMessage = 'fields.rule.date'
        } else if (val && DateTime.fromFormat(val, 'dd.MM.yyyy').invalid) {
          isValid = false
          this.errorMessage = 'fields.rule.date'
        }

        this.field.setValues(isValid, this.field.value)
      }
    },
    computed: {
      chosen () {
        if (this.field.isValid) {
          const chosen = DateTime.fromFormat(this.value, 'dd.MM.yyyy')
          return chosen.invalid ? null : chosen
        }
        return null
      },
      label () {
        return this.field.label
      },
      value () {
        return this.field.value
      },
    },
    watch: {
      value (val) {
        this.vmodel = val
      }
    },

  }
</script>

<style scoped>
  .datepickerField {
    position: relative;
  }
  .datepicker {
    z-index: 1;
    position: absolute;
    display: none;
    left: 0;
  }
  .datepicker.right {
    left: unset;
    right: 0;
  }
  .datepicker.visible {
    display: block;
  }
</style>
