<template>
  <div class="card">
    <HseHeading size="h3">{{ data.Title }}</HseHeading>
    <HseSpace size="small" vertical />
    <!-- <ReadonlyInput :label="$t('event.format')" :value="data.VidName" /> -->
    <ReadonlyInput :label="$t('event.announcement')" v-if="data.Announcement" :value="data.Announcement" isHTML />
    <!-- <ReadonlyInput :label="$t('event.typeOfEvent')" :value="data.EventTitle" /> -->
    <!-- <ReadonlyInput :label="$t('event.location')" :value="data.Location" /> -->
    <div class="two-cols">
      <ReadonlyInput :label="$t('event.startDate')" :value="dateFormat(data.StartDate)" />
      <ReadonlyInput :label="$t('event.endDate')" :value="dateFormat(data.EndDate)" />
    </div>
    <div class="two-cols">
      <ReadonlyInput v-if="checkAccessCode()" :label="$t('eventPassword')" :value="data.passwordProgram" />
      <ReadonlyInput v-if="checkAccessCode()" :label="$t('eventDate')" :value="dateFormat(data.dateOfValidity)" />
    </div>
    <HseSpace size="medium" vertical />
    <div class="actions">
      <div v-if="type.id === 'current'">
        <div class="buttons">
          <div class="menu" ref="dropdownParticipationRef">
            <div class="select-container">
              <HseButton :disabled="!invalidRequestDate" size="medium" v-on:click="toggle">{{ $t("register") }}
              </HseButton>
            </div>
            <HseDropdownMenu :class="{ show: isMenuOpenParticipation }" class="menuDrop">
              <HseButton :disabled="!invalidRequestDate" size="medium" v-on:click="registerReq">{{
                $t("individualApplication") }}</HseButton>
              <HseSpace size="small_3x" vertical />
              <HseTooltip :content="$t('collectiveTooltip')">
                <HseButton :disabled="!invalidReportDate" size="medium" v-on:click="collectiveReq">{{ $t("collective") }}
                </HseButton>
              </HseTooltip>
            </HseDropdownMenu>
          </div>
          <HseSpace size="small_3x" vertical />
          <div class="menu" ref="dropdownSpecialEventRef">
            <div class="select-container">
              <HseTooltip :content="$t('initiatorsSpecial')">
                <HseButton :disabled="!canApplyRT" size="medium" v-on:click="toggleSpecialEvent">{{ $t("specialEvent") }}
                </HseButton>
              </HseTooltip>
            </div>
            <HseDropdownMenu :class="{ show: isMenuOpenSpecialEvent }" class="menuDrop">
              <HseButton :disabled="!canApplyRT" size="medium" v-on:click="clickRoundTable">{{ $t("roundTable") }}
              </HseButton>
              <HseSpace size="small_3x" vertical />
              <HseButton :disabled="!canApplyRT" size="medium" v-on:click="clickHonoraryReport">{{
                $t("honoraryReport") }}
              </HseButton>
            </HseDropdownMenu>
          </div>
        </div>
        <HseSpace size="small_3x" vertical />
      </div>
      <div class="textTechnicalProblems">
        {{ $t('textTechnicalProblems') }}
      </div>
      <a class="link" :href="`/#/events/${data.Id}?from=${type.id}`">{{
        $t("info")
      }}</a>
      <a class="link" v-if="data.PublishProgram" :href="`/#/event-program/${data.Id}`">{{ $t("eventProgram") }}</a>
      <a class="link" v-if="(data.PaymentStatus === 1 || data.PaymentStatus === -1) &&
        data.hasOpen
        " :href="`/#/special_events/${data.Id}`">{{ $t("specialEvents") }}</a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ReadonlyInput from "../common/ReadonlyInput.vue";
import {
  HseHeading,
  HseSpace,
  HseButton,
  HseTooltip,
  HseDropdownMenu,
} from "@hse-design/vue";
import { dateFormatter } from "../common/dateFormatter";
import { api } from "../../api";

export default {
  name: "eventCard",
  data: () => ({
    isMenuOpenParticipation: false,
    isMenuOpenSpecialEvent: false,
    isDebug: true,
    invalidRequestDate: false,
    invalidReportDate: false,
    invalidRTDate: false,
    canApplyRT: false,
    disabledPPD: false,

  }),
  components: {
    ReadonlyInput,
    HseHeading,
    HseSpace,
    HseButton,
    HseTooltip,
    HseDropdownMenu,
  },
  props: ["data", "type"],
  created() {
    this.checkEndRequestDate(
      this.data.EndRequestDate,
      this.data.StartRequestDate
    );
    this.checkEndReportDate(this.data.StartReportDate, this.data.EndReportDate);
    this.checkEndRTDate(this.data.startRtRequest, this.data.endRtRequest);
    this.checkCanApplyRT();
    this.checkDisabledPPD();
  },
  computed: {
    ...mapState(["lang"]),
  },
  methods: {
    toggle() {
      this.isMenuOpenParticipation = !this.isMenuOpenParticipation;
      if (this.isMenuOpenParticipation) {
        addEventListener("click", this.listener, false);
      }
    },
    listener(e) {
      if (!this.$refs.dropdownParticipationRef) return;

      const contains = this.$refs.dropdownParticipationRef.contains(e.target);
      if (!contains) {
        this.isMenuOpenParticipation = false;
        removeEventListener("click", this.listener, false);
      }
    },
    toggleSpecialEvent() {
      this.isMenuOpenSpecialEvent = !this.isMenuOpenSpecialEvent;
      if (this.isMenuOpenSpecialEvent) {
        addEventListener("click", this.listener1, false);
      }
    },
    listener1(e) {
      if (!this.$refs.dropdownSpecialEventRef) return;

      const contains = this.$refs.dropdownSpecialEventRef.contains(e.target);
      if (!contains) {
        this.isMenuOpenSpecialEvent = false;
        removeEventListener("click", this.listener1, false);
      }
    },
    clickRoundTable() {
      this.isMenuOpenSpecialEvent = !this.isMenuOpenSpecialEvent;
      this.$router.push(`/round_table/create?event=${this.data.Id}`);
    },
    clickHonoraryReport() {
      this.isMenuOpenSpecialEvent = !this.isMenuOpenSpecialEvent;
      this.$router.push(
        `honorary_or_invited_report/create?event=${this.data.Id}`
      );
    },
    checkAccessCode() {
      const d1 = new Date(this.data.dateOfValidity);
      const d2 = new Date();
      return (
        this.data.passwordProgram &&
        this.data.passwordProgram !== "" &&
        this.data.dateOfValidity &&
        d1 > d2
      );
    },
    checkEndRequestDate(endDate, startDate) {
      const endDateTimeStamp = new Date(endDate).getTime();
      const startDateTimeStamp = new Date(startDate).getTime();
      const currentDate = new Date().getTime();
      this.invalidRequestDate =
        currentDate < endDateTimeStamp && startDateTimeStamp < currentDate;
    },
    checkEndReportDate(startDate, endDate) {
      const endDateTimeStamp = new Date(endDate).getTime();
      const startDateTimeStamp = new Date(startDate).getTime();
      const currentDate = new Date().getTime();
      this.invalidReportDate = currentDate < endDateTimeStamp && startDateTimeStamp < currentDate;
    },
    checkDisabledPPD() {
      this.disabledPPD = !this.canApplyRT && !this.invalidReportDate;
    },
    checkEndRTDate(startDate, endDate) {
      const endDateTimeStamp = new Date(endDate).getTime();
      const startDateTimeStamp = new Date(startDate).getTime();
      const currentDate = new Date().getTime();
      this.invalidRTDate =
        currentDate < endDateTimeStamp && currentDate > startDateTimeStamp;
    },
    dateFormat(date) {
      return dateFormatter.format(date);
    },
    registerReq() {
      this.isMenuOpenParticipation = !this.isMenuOpenParticipation;
      this.$router.push(`/requests/add/?event=${this.data.Id}`);
    },
    collectiveReq() {
      this.isMenuOpenParticipation = !this.isMenuOpenParticipation;
      this.$router.push(`/collective_requests/add/?event=${this.data.Id}`);
    },
    checkCanApplyRT() {
      api
        .request("user_can_apply_rt", null)
        .then((res) => (this.canApplyRT = res && this.invalidRTDate));
    },
  },
  i18n: {
    messages: {
      en: {
        info: "Information about the event",
        endRegistration: "Registration to the Conference is finished",
        eventPassword: "Conference program access code",
        eventDate: "Valid until",
        individualApplication: "Submit an individual application",
        register: "Apply for partipication",
        collective: "Submit an application to hold a session",
        collectiveTooltip:
          "A collective application refers to an application for holding a session with the presentation of several research papers.",
        specialEvents: "Special events",
        roundTable: "Apply for a round table",
        specialEvent: "Apply for a special event",
        initiatorsSpecial:
          "Round tables and events featuring presentations by honorary/invited speakers",
        honoraryReport: "Apply for an honorary/invited report",
        eventProgram: "Event Programme",
        textTechnicalProblems: `If you experience any technical issues while submitting your application, please contact the Organizing Committee at YasinConference@hse.ru and attach a screenshot of the relevant page.`,
      },
      ru: {
        info: "Информация о мероприятии",
        endRegistration: "Регистрация на мероприятие завершена",
        eventPassword: "Код доступа к программе конференции",
        eventDate: "Действителен до",
        individualApplication: "Подать индивидуальную заявку",
        register: "Подать заявку на участие",
        collective: "Подать заявку на проведение сессии",
        collectiveTooltip:
          "Коллективная заявка - это заявка на проведение сессии, в рамках которой планируется представление нескольких научных докладов.",
        specialEvents: "Специальные мероприятия",
        roundTable: "Подать заявку на круглый стол",
        specialEvent: "Подать заявку на специальное мероприятие",
        initiatorsSpecial:
          "Круглые столы и мероприятия с представлением почетных/приглашенных докладов",
        honoraryReport: "Подать заявку на почетный/приглашенный доклад",
        eventProgram: "Программа мероприятия",
        textTechnicalProblems: "При возникновении технических проблем в процессе подачи заявок необходимо обратиться в Организационный комитет конференции (YasinConference@hse.ru), приложив к письму принтскрин страницы",
      },
    },
  },
};
</script>

<style scoped>
.buttons button {
  width: 100%;
}

.disable {
  opacity: 0.5;
  background-color: rgba(37, 37, 65, 0.6);
}

.two-cols {
  display: flex;
  margin: 0 -15px;
  align-items: flex-end;
}

.two-cols>.field {
  padding: 0 15px;
  flex: 1;
}

.link {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.actions {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.actions>* {
  margin: 0 15px;
}

.actions .link {
  margin-top: 12px;
}

@media (max-width: 767px) {
  .two-cols {
    flex-direction: column;
    align-items: initial;
  }
}

.menu {
  position: relative;
}

.menuDrop {
  position: absolute;
  width: 100%;
  display: none;
}

.show {
  display: flex;
}

.textTechnicalProblems {
  font-size: 14px;
  color: #6b7a99;
}
</style>
