<template>
  <div>
    <div class="headingWithTooltip">
      <div class="subheading">{{ $t("personal") }}</div>
      <HseTooltip :content="$t('initiatorApplicationTooltip')">
        <IconStatusInfo />
      </HseTooltip>
    </div>

    <HseSpace size="small" vertical />

    <div class="cols two-cols">
      <div class="field longDMItem">
        <Validator :field="fields.LName" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field longDMItem">
        <Validator :field="fields.FName" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field longDMItem">
        <Validator :field="fields.SName" />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>

    <div class="cols two-cols">
      <div class="field longDMItem">
        <Validator :field="fields.Position" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field longDMItem">
        <Validator :field="fields.DepartmentIdRu" />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>

    <div class="cols two-cols">
      <div class="field longDMItem">
        <Validator :field="fields.Phone" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field longDMItem">
        <Validator :field="fields.Email" />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>

    <HseSpace size="small" vertical />
    <div class="subheading">{{ $t("speaker") }}</div>
    <HseSpace size="small" vertical />
    <div class="cols">
      <div class="field">
        <RadioGroupWithTooltip
          :label="$t('fields.honoraryOrInvitedReport.speakerStatus')"
          :options="fields.speakerStatusNew.options"
          @change="subli2"
          :disabled="fields.speakerStatusNew.disabled"
          :defaultValue="fields.speakerStatusNew.value"
        />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>
    <div class="cols two-cols">
      <div class="field longDMItem">
        <!-- statusSupported -->
        <Validator
          :field="fields.SpeakerLName"
          :disabled="this.fieldsDisabled3"
        />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field longDMItem">
        <Validator
          :field="fields.SpeakerLNameEn"
          :disabled="this.fieldsDisabled3"
        />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field longDMItem">
        <Validator
          :field="fields.SpeakerFName"
          :disabled="this.fieldsDisabled3"
        />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field longDMItem">
        <Validator
          :field="fields.SpeakerFNameEn"
          :disabled="this.fieldsDisabled3"
        />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field longDMItem">
        <Validator
          :field="fields.SpeakerSName"
          :disabled="this.fieldsDisabled3"
        />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>
    <div class="cols two-cols">
      <div class="field longDMItem">
        <Validator :field="fields.SpeakerPosition" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field longDMItem">
        <Validator :field="fields.SpeakerPositionEn" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field longDMItem">
        <Validator :field="fields.SpeakerAffiliation" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field longDMItem">
        <Validator :field="fields.SpeakerAffiliationEn" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field longDMItem">
        <Validator
          :field="fields.PlannedTopicOfTheReport"
          :disabled="this.fieldsDisabled3"
        />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field longDMItem">
        <Validator
          :field="fields.PlannedTopicOfTheReportEn"
          :disabled="this.fieldsDisabled3"
        />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>
    <div class="cols two-cols">
      <div class="field longDMItem">
        <Validator :field="fields.SpeakerCountry" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field longDMItem">
        <Validator :field="fields.SpeakerNationality" />
        <HseSpace size="small_2x" vertical />
      </div>
      <!-- <div class="field longDMItem">
        <Validator :field="fields.SpeakerStatus" />
        <HseSpace size="small_2x" vertical />
      </div> -->
    </div>

    <!-- <div class="cols">
      <div class="field fileUploader_item">
        <HseSpace size="small" vertical />
        <FileUploader :field="fields.SpeakerPassport" />
        <HseSpace size="small_2x" vertical />
      </div>
    </div> -->

    <div class="cols">
      <div class="field">
        <div class="flex-tooltip-area">
          <Validator
            :field="fields.AbstractTextRu"
            :disabled="this.fieldsDisabled3"
          />
          <HseSpace size="small_2x" vertical />
          <HseTooltip :content="$t('AnnotationTextHseTooltip')">
            <IconStatusInfo />
          </HseTooltip>
        </div>
      </div>
    </div>

    <div class="cols">
      <div class="field">
        <div class="flex-tooltip-area">
          <Validator
            :field="fields.AbstractTextEn"
            :disabled="this.fieldsDisabled3"
          />
          <HseSpace size="small_2x" vertical />
          <HseTooltip :content="$t('AnnotationTextHseTooltip')">
            <IconStatusInfo />
          </HseTooltip>
        </div>
      </div>
    </div>

    <div class="cols">
      <div class="field fileUploader_item">
        <HseSpace size="small" vertical />
        <FileUploader :field="fields.SpeakerCv" />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>

    <div class="cols">
      <div class="field">
        <Validator :field="fields.RationaleInvitation" />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>

    <div class="cols">
      <div class="field">
        <RadioGroup
          :label="$t('eventFormat')"
          :options="fields.specialEventOrganizationFormatId.options"
          @change="subli"
          :disabled="fields.specialEventOrganizationFormatId.disabled"
          :defaultValue="fields.specialEventOrganizationFormatId.value"
        />
      </div>
    </div>

    <HseSpace size="small" vertical />
    <div class="headingWithTooltip">
      <div class="subheading">
        {{ $t("fields.honoraryOrInvitedReport.coordinatingLeader") }}
      </div>
      <HseTooltip :content="$t('coordinatingHeadTooltip')">
        <IconStatusInfo />
      </HseTooltip>
    </div>
    <HseSpace size="small" vertical />

    <div class="field">
      <Validator :field="fields.CoordinatingLeaderFio" />
      <HseSpace size="small_2x" vertical />
    </div>

    <div class="cols two-cols">
      <div class="field">
        <Validator :field="fields.CoordinatingLeaderPosition" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field">
        <Validator :field="fields.CoordinatingLeaderDepartment" />
        <HseSpace size="small_2x" vertical />
      </div>
      <div class="field">
        <Validator :field="fields.CoordinatingLeaderEmail" />
        <HseSpace size="small_2x" vertical />
      </div>
    </div>

    <div class="field">
      <Validator :field="fields.Comment" />
      <HseSpace size="small_2x" vertical />
    </div>

    <div class="actions">
      <HseLink variant="secondary" @click="back">
        {{ $t("back") }}
      </HseLink>
      <div class="actions" v-if="fieldsDisabled === false">
        <HseButton
          variant="primary"
          :spinner="draftSpinner"
          spinnerPosition="left"
          size="medium"
          @click="draft"
        >
          {{ $t("draft") }}
        </HseButton>
        <HseButton
          variant="primary"
          :spinner="submitSpinner"
          spinnerPosition="left"
          size="medium"
          @click="submit"
        >
          {{ $t("submit") }}
        </HseButton>
      </div>
      <HseButton
        variant="primary"
        v-if="
          !statusDraft &&
          (fieldsDisabled === 'partially' || fieldsDisabled === 'membersOnly')
        "
        :spinner="submitSpinner"
        spinnerPosition="left"
        size="medium"
        @click="submit"
      >
        {{ $t("save") }}
      </HseButton>
    </div>
  </div>
</template>

<script>
import {
  HseSpace,
  HseButton,
  HseLink,
  IconActionPlus,
  HseTooltip,
  IconStatusInfo,
} from "@hse-design/vue";
import Validator from "../common/form/Validator";
import RadioGroup from "../common/form/RadioGroup";
import RadioGroupWithTooltip from "../common/form/RadioGroupWithTooltip";
import { mapState } from "vuex";
import { api } from "../../api";
import FileUploader from "../common/form/FileUploader.vue";

export default {
  name: "FormPage1",
  components: {
    Validator,
    RadioGroup,
    HseSpace,
    HseButton,
    HseLink,
    FileUploader,
    IconStatusInfo,
    HseTooltip,
    RadioGroupWithTooltip,
  },
  data: () => ({
    plusIcon: IconActionPlus,
    isModeratorBlockOpen: true,
    isModeratorModalOpen: false,
    editableModerator: null,
    isSpeakerBlockOpen: true,
    isSpeakerModalOpen: false,
    editableSpeaker: null,
    isMemberBlockOpen: true,
    isMemberModalOpen: false,
    isWarningModalOpen: false,
    membersChanged: false,
    editableMember: null,
    isWebinarModalOpen: false,
    isQRCodeModalOpen: false,
    participantId: null,
    draftSpinner: false,
    submitSpinner: false,
    isEditFormat: false,
    // fieldsToValidate2: ['specialEventOrganizationFormatId', 'maxParticipants', 'endRegistrationDate'],
    // radiosToValidate: ['publicityId'],
    fieldsToValidate: [
      "Position",
      "Phone",
      "Email",
      "SpeakerLName",
      "SpeakerLNameEn",
      "SpeakerFName",
      "SpeakerFNameEn",
      "SpeakerSName",
      "SpeakerPosition",
      "SpeakerPositionEn",
      "SpeakerAffiliation",
      "SpeakerAffiliationEn",
      "PlannedTopicOfTheReport",
      "PlannedTopicOfTheReportEn",
      "SpeakerCountry",
      "SpeakerNationality",
      "AbstractTextRu",
      "AbstractTextEn",
      "SpeakerCv",
      "RationaleInvitation",
      "CoordinatingLeaderFio",
      "CoordinatingLeaderPosition",
      "CoordinatingLeaderDepartment",
      "CoordinatingLeaderEmail",
      "Comment",
      "DepartmentIdRu",
    ],
  }),
  props: [
    "fields",
    "fieldsDisabled",
    "fieldsDisabled3",
    "eventId",
    "user",
    "isNew",
    "data",
    "rtId",
  ],
  created() {
    this.fields.publicityId.getAllOptions();
    this.fields.specialEventOrganizationFormatId.getAllOptions();
    this.fields.speakerStatusNew.getAllOptions();

    this.fields.LName.setValues(
      false,
      this.$store.state.user.data.public.LName
    );
    this.fields.FName.setValues(
      false,
      this.$store.state.user.data.public.FName
    );
    this.fields.SName.setValues(
      false,
      this.$store.state.user.data.public.SName
    );
    this.fields.Email.setValues(false, this.$store.state.user.login);
    this.fields.Position.setValues(
      false,
      this.$store.state.user.data.public.Position
    );
    this.fields.Phone.setValues(
      false,
      this.$store.state.user.data.public.Phone
    );
  },
  methods: {
    dateFormat(date) {
      return date.split(".").reverse().join("-");
    },
    submit() {
      this.saveForm(false);
    },
    draft() {
      this.saveForm(true);
    },
    back() {
      this.$router.push("/events");
    },
    closeWarningModal() {
      this.isWarningModalOpen = false;
      this.isEditFormat = false;
      this.draftSpinner = false;
    },
    subli(format) {
      this.fields.specialEventOrganizationFormatId.setValues(true, format);
      if (format === "895BB6AB-A343-48DC-AA19-7EAB348C8C0D") {
        this.isWarningModalOpen = true;
        this.isEditFormat = true;
      }
    },
    subli2(format) {
      this.fields.speakerStatusNew.setValues(true, format);
      if (format === "895BB6AB-A343-48DC-AA19-7EAB348C8C0D") {
        this.isWarningModalOpen = true;
        this.isEditFormat = true;
      }
    },
    saveForm(draft) {
      this.$emit("validateFields");

      if (
        +new Date(
          this.fields.endRegistrationDate.value.split(".").reverse().join("-")
        ) +
          new Date().getTimezoneOffset() * 60 * 1000 >
        +new Date(this.fields.date.value.split(".").reverse().join("-"))
      )
        return;
      const isPageValid = !this.fieldsToValidate.some((key) => {
        if (this.fields[key].isValid === false) {
          console.log("this.fields", key, this.fields);
        }

        return !this.fields[key]?.isValid;
      });

      if (isPageValid) {
        if (draft) {
          this.draftSpinner = true;
        } else {
          this.submitSpinner = true;
        }
        const params = {};

        Object.keys(this.fields).forEach((key) => {
          const { value } = this.fields[key];
          if (key === "date") {
            params[key] = this.dateFormat(value);
          } else if (this.fieldsToValidate.includes(key)) {
            params[key] = this.convertGuid(value);
          } else {
            if (
              this.statusPublicated ||
              this.statusPublication ||
              this.statusCheck
            ) {
              if (key === "endRegistrationDate") {
                params[key] = this.dateFormat(value);
              } else if (key === "maxParticipants") {
                params[key] = +value;
              } else if (this.fieldsToValidate2.includes(key)) {
                params[key] = this.convertGuid(value);
              } else if (this.radiosToValidate.includes(key)) {
                if (typeof value === "number") {
                  params[key] = !!value;
                } else {
                  params[key] = value;
                }
              }
            } else {
              params[key] = null;
            }
          }
        });

        params.LName = this.fields.LName.value;
        params.FName = this.fields.FName.value;
        params.SName = this.fields.SName.value;
        params.Email = this.fields.Email.value;
        params.Position = this.fields.Position.value;
        params.Phone = this.fields.Phone.value;
        params.SpeakerLName = this.fields.SpeakerLName.value;
        params.SpeakerLNameEn = this.fields.SpeakerLNameEn.value;
        params.SpeakerFName = this.fields.SpeakerFName.value;
        params.SpeakerFNameEn = this.fields.SpeakerFNameEn.value;
        params.SpeakerSName = this.fields.SpeakerSName.value;
        params.SpeakerPosition = this.fields.SpeakerPosition.value;
        params.SpeakerPositionEn = this.fields.SpeakerPositionEn.value;
        params.SpeakerAffiliation = this.fields.SpeakerAffiliation.value;
        params.SpeakerAffiliationEn = this.fields.SpeakerAffiliationEn.value;
        params.PlannedTopicOfTheReport =
          this.fields.PlannedTopicOfTheReport.value;
        params.PlannedTopicOfTheReportEn =
          this.fields.PlannedTopicOfTheReportEn.value;
        params.SpeakerCountry = this.fields.SpeakerCountry.value;
        params.SpeakerNationality = this.fields.SpeakerNationality.value;
        // params.SpeakerPassport = this.fields.SpeakerPassport.value
        params.AbstractTextRu = this.fields.AbstractTextRu.value;
        params.AbstractTextEn = this.fields.AbstractTextEn.value;
        params.SpeakerCv = this.fields.SpeakerCv.value;
        params.SpeakerStatus = this.fields.speakerStatusNew.value;
        params.RationaleInvitation = this.fields.RationaleInvitation.value;
        params.CoordinatingLeaderFio = this.fields.CoordinatingLeaderFio.value;
        params.CoordinatingLeaderPosition =
          this.fields.CoordinatingLeaderPosition.value;
        params.CoordinatingLeaderDepartment =
          this.fields.CoordinatingLeaderDepartment.value;
        params.CoordinatingLeaderEmail =
          this.fields.CoordinatingLeaderEmail.value;
        params.Comment = this.fields.Comment.value;
        params.DepartmentIdRu = this.fields.DepartmentIdRu.value;
        params.ParticipationFormatId =
          this.fields.specialEventOrganizationFormatId.value;

        params.rtId = this.isNew ? null : this.rtId;
        params.lang = this.lang;
        params.draft = draft;
        params.eventId = this.eventId;

        params.secondPhase = false;
        if (this.statusSupported) {
          this.$emit("editCurrentTab");
          this.submitSpinner = false;
          return;
        }

        this.draftSpinner = false;
        console.log(params, "params");
        api.request("add_hor_data", params).then((data) => {
          if (draft) {
            this.draftSpinner = "done";
          } else {
            this.submitSpinner = "done";
          }
          setTimeout(() => {
            if (draft) {
              this.draftSpinner = false;
            } else {
              this.submitSpinner = false;
            }
            if (data && data.length) {
              this.$emit("saved", draft);
            } else {
              this.$emit("savedErr");
            }
          }, 2000);
        });
      }
    },
    convertGuid(value) {
      return !value ? null : value;
    },
    getBackIfErr() {
      this.isSaved = false;
      this.isSaveErr = false;
    },
  },
  computed: {
    statusReview() {
      return this.data.StatusId === "81A603E7-391C-460E-B413-FC153EA20C1A";
    },
    statusSupported() {
      return this.data.StatusId === "3BB3404D-032C-48C1-9397-6FCF1E4A6080";
    },
    statusFirstModification() {
      return this.data.StatusId === "31812363-221B-4B92-9B53-69003FF920C4";
    },
    statusSecondModification() {
      return this.data.StatusId === "598FA3AE-2FBB-4703-8B75-02735E758EEA";
    },
    statusCheck() {
      return this.data.StatusId === "EF7995A1-DA68-4A79-AE54-0BBFCA044F37";
    },
    statusPublication() {
      return this.data.StatusId === "28AE374D-D74C-4F4B-857C-E4E5BF0FF90A";
    },
    statusPublicated() {
      return this.data.StatusId === "C08814A5-7780-4595-9A31-AF51383E3933";
    },
    statusCanceled() {
      return this.data.StatusId === "CC88FBA9-62A7-4A0F-BC3D-A133BB7207EF";
    },
    statusDeclined() {
      return this.data.StatusId === "472430DA-E08D-491B-862E-19B1EED635FD";
    },
    statusDraft() {
      return this.data.StatusId === "37241B9D-2AAA-4EB4-9B01-F310902E0E84";
    },
    ...mapState(["lang"]),
  },
  watch: {
    data() {
      // if (this.fieldsDisabled) {
      //   this.fieldsToValidate.forEach(key => {
      //     if (this.fieldsDisabled === 'partially' && (key === 'PlannedTopicOfTheReport' || key === 'SpeakerCountry')) {
      //       if (this.fields[key].setDisabled) {
      //         this.fields[key].setDisabled(true)
      //       }
      //     } else if (this.fieldsDisabled === true || this.fieldsDisabled === 'membersOnly') {
      //       if (this.fields[key].setDisabled) {
      //         this.fields[key].setDisabled(true)
      //       }
      //     }
      //   })
      // }
      // if (this.data.moderators) {
      //   this.moderators = this.data.moderators
      // }
      // if (this.data.speakers) {
      //   this.speakers = this.data.speakers
      // }
      // if (this.data.members) {
      //   this.members = this.data.members
      // }
    },
  },
  i18n: {
    messages: {
      en: {
        moderatorBlock: "Moderators",
        speakerBlock: "Experts",
        memberBlock: "Participants",
        pass: "Pass",
        letter: "Letter",
        no: "No",
        yes: "Yes",
        addParticipant: "Add",
        Approve: "Approve all",
        Webinar: "Send the link for a webinar",
        back: "Back",
        submit: "Send a request",
        draft: "Save a draft",
        okStatus: "Request sent",
        errStatus: "Error occured",
        chooseLang: "Choose a language",
        eventFormat: "Planned format of the speaker's participation",
        approved: "Approved",
        rejected: "Rejected",
        sent: "Sent",
        save: "Save changes",
        personal: "Request Initiator",
        speaker: "Speaker",
        AnnotationTextHseTooltip: "The volume is not less than 1000 characters",
        honoraryReportHint:
          "Почетный доклад. Подсказка: – доклад российского или иностранного гостя конференции с высоким показателем научного и/или экспертного рейтинга. Тематика почетного доклада, как правило, включает общие вопросы развития экономики и общества. Почетный доклад – всегда самостоятельное мероприятие.",
        invitedReportHint:
          "Приглашённый доклад. Подсказка: – доклад, представляемый российским или иностранным гостем конференции с высоким показателем научного и/или экспертного рейтинга. Тема приглашенного доклада может быть одной из тем сессии и/или круглого стола.",
      },
      ru: {
        moderatorBlock: "Модераторы",
        speakerBlock: "Эксперты-спикеры",
        memberBlock: "Участники",
        pass: "Пропуск",
        letter: "Письмо",
        no: "Нет",
        yes: "Да",
        addParticipant: "Добавить",
        Approve: "Одобрить всех",
        Webinar: "Отправить ссылку на вебинар",
        back: "Назад",
        submit: "Подать заявку",
        draft: "Сохранить черновик",
        okStatus: "Заявка отправлена",
        errStatus: "Произошла ошибка",
        chooseLang: "Выберите язык",
        eventFormat: "Планируемый формат участия докладчика ",
        approved: "Одобрен",
        rejected: "Отклонен",
        sent: "Отправлен",
        save: "Сохранить изменения",
        personal: "Инициатор заявки",
        speaker: "Докладчик",
        initiatorApplicationTooltip:
          "сотрудник НИУ ВШЭ, который заполняет заявку на проведение специального мероприятия ЯМНК и направляет ее на рассмотрение в Программный комитет",
        coordinatingHeadTooltip:
          "руководитель НИУ ВШЭ (уровня первого проректора/проректора/декана/члена ПК), который берет на себя ответственность за инициируемое мероприятие в рамках ЯМНК",
        AnnotationTextHseTooltip: "Объем не менее 1000 знаков",
        honoraryReportHint:
          "Почетный доклад. Подсказка: – доклад российского или иностранного гостя конференции с высоким показателем научного и/или экспертного рейтинга. Тематика почетного доклада, как правило, включает общие вопросы развития экономики и общества. Почетный доклад – всегда самостоятельное мероприятие.",
        invitedReportHint:
          "Приглашённый доклад. Подсказка: – доклад, представляемый российским или иностранным гостем конференции с высоким показателем научного и/или экспертного рейтинга. Тема приглашенного доклада может быть одной из тем сессии и/или круглого стола.",
      },
    },
  },
};
</script>

<style scoped>
.cols {
  display: flex;
  margin: 0 -15px;
  flex-wrap: wrap;
}

.cols > .field {
  padding: 0 15px;
  flex: 1;
}

.two-cols .field {
  max-width: 50%;
  width: 50%;
  flex-basis: 50%;
}

.three-cols .field {
  max-width: 33.3%;
}

.ident {
  margin-left: 10px;
}

@media (max-width: 767px) {
  .two-cols .field,
  .three-cols .field {
    max-width: 100%;
    width: 100%;
    flex-basis: 100%;
  }
}

.actions {
  flex-wrap: wrap;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.accordion {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
}

.accordion__head {
  display: flex;
  justify-content: space-between;
}

.participant__heading {
  display: flex;
  align-items: center;
  gap: 5px;
}

.participant__heading .icons {
  display: flex;
  gap: 3px;
}

.participant__heading .icons > svg {
  cursor: pointer;
}

.flex-tooltip-area {
  display: flex;
}

.flex-tooltip-area > div:first-child {
  width: calc(100% - 28px);
}

.flex-tooltip-area > div:last-child {
  padding: 48px 0 0 12px;
}

.headingWithTooltip {
  display: flex;
}

.headingWithTooltip > div:first-child {
  padding: 2px 0 0 0;
}

.headingWithTooltip > div:last-child {
  padding: 0px 0 0 12px;
}

.subheading {
  font-family: "Work Sans", sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #0f0f14;
}
</style>
