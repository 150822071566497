<template>
  <div class="add-coauthor">
    <HseHeading size="h3">{{ $t(this.coauthor ? 'headings.editableCoauthor' : 'headings.addCoauthor') }}</HseHeading>
    <HseSpace size='small' vertical />
    <div v-show="isCheckExists" class="check">
      <div class="field">
        <Validator :field="fields.login" @change="toggleError" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <Validator :field="fields.willCome" />
        <HseSpace size='small_2x' vertical />
      </div>

      <div class="form-footer">
        <div class="actions">
          <HseButton
            v-on:click="checkEmail"
            :spinner="buttonSpinner"
            spinnerPosition="left"
          >
            <span v-if="buttonSpinner === false">{{ $t('headings.addCoauthor') }}</span>
            <span v-else-if="buttonSpinner === true">{{ $t('adding') }}...</span>
            <span v-else-if="buttonSpinner === 'done'">{{ $t('auth.btnDone') }}</span>
          </HseButton>
        </div>
        <div class="form-description">{{ $t('fields.requiredFields') }}</div>
      </div>

    </div>
    <div v-show="!isCheckExists" class="the-form">
      <div class="subheading">{{ $t('headings.personal') }}</div>
      <HseSpace size='small' vertical />
      <div class="form">
        <div v-if="isLangRu" class="field">
          <Validator :field="fields.LName" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <Validator :field="fields.LNameEN" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div v-if="isLangRu" class="field">
          <Validator :field="fields.FName" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <Validator :field="fields.FNameEN" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div v-if="isLangRu" class="field">
          <Validator :field="fields.SName" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <Validator :field="fields.login" @change="toggleError" />
        </div>
      </div>
      <HseSpace size='small' vertical />
      <div class="subheading">{{ $t('headings.company') }}</div>
      <HseSpace size='small' vertical />
      <div class="form">
        <div class="field">
          <Validator @change="setAffiliated" :field="fields.Affiliated" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field"></div>
        <div class="field" v-show="fields.Affiliated.value">
          <Validator :field="fields.Campus" @change="setCampus" />
          <HseSpace size='small_2x' vertical />
        </div>
      </div>
      <div class="form">
        <div v-if="isLangRu" class="field">
          <Validator :field="fields.CompanyNameShort" />
          <HseSpace size='small_2x' vertical />
        </div>
        <div class="field">
          <Validator :field="fields.CompanyNameShortEn" />
          <HseSpace size='small_2x' vertical />
        </div>
      </div>
      <div class="field">
        <Validator :field="fields.willCome" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div v-show="fields.willCome.value">
        <div class="subheading">{{ $t('headings.format') }}</div>
        <HseSpace size='small' vertical />
        <div class="field">
          <Switcher :field="fields.offline" :label="$t('offline')" size="medium" />
          <HseSpace size='small_2x' vertical />
        </div>
      </div>
      <HseSpace size='medium' vertical />
      <div class="form-footer">
        <div class="actions">
          <HseButton
            v-on:click="registerReq"
            :spinner="buttonSpinner"
            spinnerPosition="left"
          >
            <span v-if="buttonSpinner === false">{{ $t(this.coauthor ? 'save' : 'headings.addCoauthor') }}</span>
            <span v-else-if="buttonSpinner === true">{{ $t(this.coauthor ? 'saving' : 'adding') }}...</span>
            <span v-else-if="buttonSpinner === 'done'">{{ $t('auth.btnDone') }}</span>
          </HseButton>
        </div>
        <div class="form-description">{{ $t('fields.requiredFields') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { HseHeading, HseSpace, HseButton } from '@hse-design/vue'
  import { api } from '../../api'
  import Validator from '../common/form/Validator.vue'
  import Switcher from '../common/form/Switcher.vue'
  import fields from './coauthorFields'
  import { mapState } from 'vuex'
  import { localizeFields } from '../../i18n'
  import { resourceLoader } from '../common/resourceLoader'

  export default {
    components: {
      HseHeading,
      HseSpace,
      HseButton,
      Validator,
      Switcher,
    },
    props: ['eventId', 'reportTitle', 'editableCoauthor', 'added'],
    data: () => ({
      fields,
      buttonSpinner: false,
      campus: { en: [], ru: [] },
      isCheckExists: true,
      coauthor: null,
      compareRuEnFields: {
        LName: 'LNameEN',
        FName: 'FNameEN',
        CompanyNameShort: 'CompanyNameShortEn',
      },
    }),
    created () {
      ['en', 'ru'].forEach(lang =>
        resourceLoader.load('campusList', { lang })
          .then(data => this.campus[lang] = data)
      )
      this.translateFields()

      if (this.editableCoauthor) {
        this.coauthor = this.editableCoauthor
        this.fillFields(this.coauthor)
      }
    },
    computed: {
      isLangRu () {
        return this.lang === 'ru'
      },
      isCitizenRu () {
        return this.fields.Citizen.value === 'BC763F4B-FBD9-461B-B395-166020E8C026'
      },
      isCountryRu () {
        return this.fields.CountryId.value === 'BC763F4B-FBD9-461B-B395-166020E8C026'
      },
      isMoscowCampus () {
        return this.fields.Campus.value === 'DFA00199-FB13-47BB-A69B-F01C6F525F71'
      },
      ...mapState(['lang'])
    },
    watch: {
      lang () {
        this.translateFields()
      },
    },
    methods: {
      setAffiliated () {
        if (!this.fields.Affiliated.value) {
          this.fields.CompanyNameShort.value = ''
          this.fields.CompanyNameShortEn.value = ''
        }
      },
      toggleError (value) {
        const isErrSet = this.$store.state.error && this.$store.state.error.code === 876
        const isAdded = this.added.some(coauth => coauth.login === value)
        if (isAdded && !isErrSet) {
          if (this.editableCoauthor && this.editableCoauthor.login === value) return
          this.$store.commit('setError', { code: 876 })
          this.$emit('validateFields')
        } else if (!isAdded && isErrSet) {
          this.$store.commit('setError', null)
          this.$emit('validateFields')
        }
      },
      citizenChange () {
        fields.SName.isRequired = this.isCitizenRu
        this.fields.SName.isRequired = this.isCitizenRu
        if (this.isCitizenRu && !this.fields.SName.label.includes('*')) {
          this.fields.SName.label = `${this.fields.SName.label.trim()} *`
        } else if (!this.isCitizenRu && this.fields.SName.label.includes('*')) {
          this.fields.SName.label = this.fields.SName.label.slice(0, this.fields.SName.label.length - 2)
        }
      },
      translateFields () {
        this.fields = localizeFields(fields, this.fields)
      },
      setCampus (campus) {
        const currCampus = this.campus.ru.find(cmps => cmps.value === campus)
        const currCampusEn = this.campus.en.find(cmps => cmps.value === campus)

        if (currCampus)
          this.fields.CompanyNameShort.setValues(
                  true, `НИУ ВШЭ${this.isMoscowCampus ? '' : ` - ${currCampus.label}`}`
          )

        if (currCampusEn)
          this.fields.CompanyNameShortEn.setValues(
                  true, `HSE University${this.isMoscowCampus ? '' : ` - ${currCampusEn.label}`}`
          )
      },
      registerReq () {
        this.$emit('validateFields')
        let isFormInvalid = false
        const skip = this.isLangRu ? [] : Object.keys(this.compareRuEnFields)

        Object.keys(this.fields).some(key => {
          if (!this.fields.Affiliated.value && key === 'Campus' || skip.includes(key))
            return false

          isFormInvalid = this.fields[key].isValid === false
          return isFormInvalid
        })

        if (isFormInvalid) return

        this.buttonSpinner = true
        if (this.coauthor && !this.coauthor.isNew) {
          this.buttonSpinner = 'done'
          setTimeout(() => {
            this.buttonSpinner = false
            this.$emit('saved', {
              ...this.coauthor, willCome: this.fields.willCome.value,
              CompanyNameShort: this.fields.CompanyNameShort.value,
              CompanyNameShortEn: this.fields.CompanyNameShortEn.value,
              Affiliated: this.fields.Affiliated.value,
              Campus: this.fields.Campus.value, offline: this.fields.offline.value,
            } )
          }, 2000)
          return
        }

        const params = {}
        Object.keys(this.fields).forEach(key => {
          if (key === 'willCome') return
          const field = this.fields[key]

          if (!this.isLangRu && this.compareRuEnFields[key]) {
            field.value = this.fields[this.compareRuEnFields[key]].value
          }

          params[key] = field.value
        })

        params.report_title = this.reportTitle
        params.event_id = this.eventId

        api.request('co.author.invite', params).then(result => {
          this.buttonSpinner = 'done'
          setTimeout(() => {
            this.buttonSpinner = false
            this.$emit('saved', { ...result, isNew: true, willCome: this.fields.willCome.value,  CompanyNameShort: result.data.public.CompanyNameShort, CompanyNameShortEn: result.data.public.CompanyNameShortEn, offline: this.fields.offline.value, } )
          }, 2000)
        })
      },
      checkEmail () {
        this.$emit('validateFields')

        if (this.fields.login.isValid) {
          this.buttonSpinner = true

          api.auth('user.manage.check.login', this.fields.login.value).then(result => {
            if (result) {
              // проверяем добавили ли мы уже этого автора в список
              const isAdded = this.added.some(coauth => coauth.id === result)
              if (!isAdded) {
                api.auth('user.manage.get', result).then(data => {
                  this.coauthor = data
                  this.fillFields(data)
                  this.buttonSpinner = false
                })
              } else {
                this.$store.commit('setError', { code: 876 })
                this.buttonSpinner = false
                this.$emit('validateFields')
              }
            } else {
              this.buttonSpinner = false
              this.isCheckExists = false
            }
          })
        }
      },
      fillFields (userData) {
        this.isCheckExists = false
        const { public: uData } = userData.data
        const fieldsKeys = Object.keys(this.fields)
        fieldsKeys.forEach(key => {
          if (uData[key])
            this.fields[key].setValues(true, uData[key])
        })
        if (this.coauthor && !this.coauthor.isNew && this.editableCoauthor) {
          this.fields.CompanyNameShort.setValues(true, userData.CompanyNameShort)
          this.fields.CompanyNameShortEn.setValues(true, userData.CompanyNameShortEn)
          this.fields.Affiliated.setValues(true, userData.Affiliated)
          this.fields.Campus.setValues(true, userData.Campus)
        }
        this.fields.login.setValues(true, userData.login)
        if (userData.willCome)
          this.fields.willCome.setValues(true, userData.willCome)
        if (!userData.isNew) {
          fieldsKeys.forEach(key => {
            if (key === 'willCome') return
            this.fields[key][key === 'Affiliated' ? 'inactive' : 'disabled'] = true
            this.fields[key][key === 'CompanyNameShort' ? 'disabled' : 'inactive'] = false
            this.fields[key][key === 'CompanyNameShortEn' ? 'disabled' : 'inactive'] = false
            this.fields[key][key === 'Campus' ? 'disabled' : 'inactive'] = false
          })
        } else this.$emit('validateFields')
      }
    },
    i18n: {
      messages: {
        en: {
          headings: {
            addCoauthor: 'Add coauthor',
            editableCoauthor: 'Edit coauthor',
            personal: 'Personal details',
            location: 'Location',
            company: 'Company',
            format: 'Preferable format of coauthor presentaion'
          },
          adding: 'Adding',
          offline: 'Face-to-face',
        },
        ru: {
          headings: {
            addCoauthor: 'Добавить соавтора',
            editableCoauthor: 'Редактировать соавтора',
            personal: 'Личная информация',
            location: 'Местонахождение',
            company: 'Организация',
            format: 'Предпочтительный формат выступления соавтора',
          },
          adding: 'Добавляем',
          offline: 'Очный',
        },
      }
    }
  }
</script>

<style scoped>
  .add-coauthor .form {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px;
  }
  .form.down {
    align-items: flex-end;
  }
  .form .field {
    padding: 0 16px;
    min-width: 0;
    width: 50%;
    flex-basis: 50%;
  }
  .actions .hse-Button {
    display: inline-block;
  }
  .checkboxes {
    display: flex;
    justify-content: space-between;
  }
  .form-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .form-description {
    padding-top: 12px;
    font-size: 14px;
    line-height: 20px;
    color: #6B7A99;
  }
  .flex-tooltip {
    display: flex;
  }
  .flex-tooltip>div:first-child {
    width: calc(100% - 28px)
  }
  .flex-tooltip>div:last-child {
    padding: 36px 0 0 12px;
  }
  @media (max-width: 1024px) {
    .register {
      margin: 16px auto;
      max-width: 95%;
    }
    .emptyfield {
      display: none;
    }
    .checkboxes {
      flex-direction: column;
    }
  }
  @media (max-width: 768px) {
    .form .field {
      width: 100%;
      flex-basis: 100%;
    }
    .form-footer {
      flex-direction: column;
      align-items: flex-start;
    }
  }
</style>
<style>
  .smallLabel {
    font-size: 14px !important;
  }
  @media (max-width: 768px) {
    .hse-Input_disabled input:disabled {
      opacity: 1 !important;
      color: #000 !important;
    }
  }
</style>