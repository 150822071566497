<template>
  <div class="wrapper">
    <ItemsGrid :data="items" :types="types" :isLoading="isLoading" :component="component" v-on:typeChange="changeType">
      <template #top>
        <HseButton v-if="!requestsMissing" variant="secondary" component="a" href="#/visas/add">{{ $t('add') }}
        </HseButton>
        <HseTooltip :content="$t('disabledInfo')">
          <HseButton v-if="requestsMissing" :disabled="true" variant="secondary" component="a">{{ $t('add') }}</HseButton>
        </HseTooltip>
      </template>
    </ItemsGrid>
  </div>
</template>

<script>
import { HseButton, HseTooltip } from '@hse-design/vue'
import { api } from '../api'
import ItemsGrid from '../components/common/ItemsGrid.vue'
import VisaCard from '../components/visasPage/VisaCard.vue'
import { mapState } from 'vuex'

export default {
  data: () => ({
    reqParams: { archive: false, offset: 0, limit: 100 },
    eventRequestItem: [],
    items: [],
    types: [
      { title: 'types.visa.current', id: 'current' },
      // { title: 'types.visa.archive', id: 'archive' },
    ],
    component: VisaCard,
    isLoading: false,
    requestsMissing: false,
  }),
  components: {
    ItemsGrid,
    HseButton,
    HseTooltip,
  },
  methods: {
    checkRequests() {
      api.request('requestsList', {}, []).then(data => {
        if (data.length) {
          this.requestsMissing = false
        } else {
          this.requestsMissing = true
        }
      })
    },
    requestItems() {
      this.isLoading = true
      api.request('visa_list', null, []).then(data => {
        this.items = data.reverse()
        this.isLoading = false
      })
    },
    changeType(type) {
      this.reqParams.archive = type === 'archive'
      this.requestItems()
    },
  },
  computed: mapState(['lang']),
  watch: {
    lang() {
      this.requestItems()
    }
  },
  created() {
    this.checkRequests();
  },
  i18n: {
    messages: {
      en: {
        add: 'Add new',
        disabledInfo: 'You do not have applications for participation yet',
      },
      ru: {
        add: 'Добавить',
        disabledInfo: 'У вас еще нет заявок на участие',
      },
    },
  },
}
</script>

<style scoped>
.wrapper {
  max-width: 1500px;
  width: calc(100vw - 192px);
  margin: 24px auto;
}

@media (max-width: 1024px) {
  .wrapper {
    width: calc(100vw - 48px)
  }
}
</style>
