<template>
  <div class="wrapper">
    <div class="card">
      <SpecialEventModal />
      <CancelModal :eventName="data.Name" />
      <SubmitModal />
      <SubmitModalHtml
        :text="showStreamLink ? $t('participantsExceededLink', { link: data.StreamLink }) : $t('participantsExceeded')" />
      <HseHeading size="h2">{{ $t('title') }}</HseHeading>
      <HseSpace size='small' vertical />

      <HseHeading size="h4">{{ $t('info') }}</HseHeading>
      <HseSpace size='small' vertical />

      <ReadonlyInput :label="$t('specialEventType')" :value="data.SpecialEventTypeName" />
      <ReadonlyInput :label="$t('specialEventName')" :value="data.Name" />


      <div v-if="data.Moderators">
        <HseSpace size='small' vertical />
        <div :style="{ background: '#6B7A99', padding: '6px' }">
          <HseButton variant='tertiaryInvert' size='medium' v-on:click="isModeratorBlockOpen = !isModeratorBlockOpen">
            {{ $t('moderatorBlock') }} <span class="ident" v-show="isModeratorBlockOpen">&#9650;</span>
            <span class="ident" v-show="!isModeratorBlockOpen">&#9660;</span>
          </HseButton>
        </div>
        <div v-show="isModeratorBlockOpen" :style="{ background: '#F0F2F5', padding: '6px' }">
          <div class="accordion" v-for="value in data.Moderators" v-bind:key="value.Id">
            <div v-if="lang === 'ru'">
              <span> {{ value.LName }}</span>
              <span> {{ value.FName }}</span>
              <span v-if="value.SName"> {{ value.SName }}</span>
            </div>
            <div v-if="lang === 'en'">
              <span> {{ value.LNameEn }}</span>
              <span> {{ value.FNameEn }}</span>
              <span v-if="value.SNameEn"> {{ value.SNameEn }}</span>
            </div>
            <div :style="{ marginLeft: '5px' }"> ({{ value.OrganizationName }})</div>

          </div>
        </div>
      </div>

      <div v-if="data.Speakers">
        <HseSpace size='small' vertical />
        <div :style="{ background: '#6B7A99', padding: '6px' }">
          <HseButton variant='tertiaryInvert' size='medium' v-on:click="isSpeakerBlockOpen = !isSpeakerBlockOpen">
            {{ $t('speakerBlock') }} <span class="ident" v-show="isSpeakerBlockOpen">&#9650;</span><span class="ident"
              v-show="!isSpeakerBlockOpen">&#9660;</span>
          </HseButton>
        </div>
        <div v-show="isSpeakerBlockOpen" :style="{ background: '#F0F2F5', padding: '6px' }">
          <div class="accordion" v-for="value in data.Speakers" v-bind:key="value.Id">
            <div v-if="lang === 'ru'">
              <span> {{ value.LName }}</span>
              <span> {{ value.FName }}</span>
              <span v-if="value.SName"> {{ value.SName }}</span>
            </div>
            <div v-if="lang === 'en'">
              <span> {{ value.LNameEn }}</span>
              <span> {{ value.FNameEn }}</span>
              <span v-if="value.SNameEn"> {{ value.SNameEn }}</span>
            </div>
            <div :style="{ marginLeft: '5px' }"> ({{ value.OrganizationName }})</div>

          </div>
        </div>
      </div>

      <div v-if="data.Disscusants">
        <HseSpace size='small' vertical />
        <div :style="{ background: '#6B7A99', padding: '6px' }">
          <HseButton variant='tertiaryInvert' size='medium' v-on:click="isDisscusantsBlockOpen = !isDisscusantsBlockOpen">
            {{ $t('disscusantsBlock') }} <span class="ident" v-show="isDisscusantsBlockOpen">&#9650;</span><span
              class="ident" v-show="!isDisscusantsBlockOpen">&#9660;</span>
          </HseButton>
        </div>
        <div v-show="isDisscusantsBlockOpen" :style="{ background: '#F0F2F5', padding: '6px' }">
          <div class="accordion" v-for="value in data.Disscusants" v-bind:key="value.Id">
            <div v-if="lang === 'ru'">
              <span> {{ value.LName }}</span>
              <span> {{ value.FName }}</span>
              <span v-if="value.SName"> {{ value.SName }}</span>
            </div>
            <div v-if="lang === 'en'">
              <span> {{ value.LNameEn }}</span>
              <span> {{ value.FNameEn }}</span>
            </div>
            <div :style="{ marginLeft: '5px' }"> ({{ value.OrganizationName }})</div>

          </div>
        </div>
        <HseSpace size='small' vertical />
      </div>

      <div v-if="data.DiscussionQuestions" class="field">
        <div class="hse-RichInput__label">{{ $t('issueForDiscussion') }}</div>
        <div v-html="data.DiscussionQuestions" class="big-item" />
        <HseSpace size='small_2x' vertical />
      </div>
      <!--      <ReadonlyInput v-if="data.DiscussionQuestions" :label="$t('issueForDiscussion')" v-html="data.DiscussionQuestions" :value="data.DiscussionQuestions"/>-->

      <ReadonlyInput :label="$t('dateOf')" :value="dateFormat(data.Date, 'date')" />
      <ReadonlyInput :label="$t('timeOf')" :value="data.Duration" />
      <ReadonlyInput :label="$t('language')" :value="workingLanguage" />
      <ReadonlyInput :label="$t('translation')" v-if="data.SyncTranslation"
        :value="data.SyncTranslation ? $t('yes') : $t('no')" />
      <ReadonlyInput :label="$t('eventFormat')" :value="data.OrgFormat" />
      <span v-if="showStreamLink">{{ $t('streamLink') }} </span><a class="link" v-if="showStreamLink"
        :href="data.StreamLink">{{ $t('link') }}</a>

      <HseSpace size='medium' vertical />
      <div class="actions">
        <HseButton v-if="!this.data.CanDecline" :disabled="!this.data.CanBeMember" variant='primary' size="medium"
          @click="submit">
          {{ $t('submit') }}
        </HseButton>
        <HseButton v-else :disabled="!this.data.CanDecline" variant='primary' size="medium" @click="cancel">
          {{ $t('cancel') }}
        </HseButton>
        <a class="link" :href="`/#/special_events/${this.eventId}`">{{ $t('eventList') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { HseHeading, HseSpace, HseButton } from '@hse-design/vue'
import ReadonlyInput from "../common/ReadonlyInput";
import { mapState } from "vuex";
import { api } from "../../api";
import { dateFormatter } from "../common/dateFormatter";
import SubmitModal from "../common/modal/SubmitModal";
import SubmitModalHtml from "../common/modal/SubmitModalHtml";
import CancelModal from "../common/modal/CancelModal";
import SpecialEventModal from "../common/modal/SpecialEventModal";

export default {
  name: "SpecialEventCard",
  components: {
    ReadonlyInput,
    HseHeading,
    HseSpace,
    HseButton,
    SubmitModal,
    SubmitModalHtml,
    CancelModal,
    SpecialEventModal,
  },
  data: () => ({
    data: [],
    cardId: '',
    eventId: '',
    eventDate: '',
    isModeratorBlockOpen: false,
    isSpeakerBlockOpen: false,
    isDisscusantsBlockOpen: false,
  }),
  created() {
    this.cardId = this.$route.params.cardId
    this.eventId = this.$route.params.eventId
    this.requestSpecialEventCard()
  },
  methods: {
    dateFormat(date, type) {
      return dateFormatter.format(date, type)
    },
    submit() {
      if (this.registrationEnded) {
        this.$root.$refs.submitModal.open(this.$i18n.tc('registrationEnded'));
      } else if (this.data.OrgFormat === this.$i18n.tc('mixed')) {
        this.$root.$refs.specialEventModal.open()
      } else {
        this.requestSpecialEventMembership()
      }
    },
    cancel() {
      this.$root.$refs.cancelModal.open()
    },
    requestDeleteSpecialEventMembers() {
      api.request('deleteSpecialEventMembers', { id: this.data.Id })
        .then(data => {
          if (data) {
            this.$root.$refs.submitModal.open(this.$i18n.tc('canceled'))
          } else {
            console.log('Error during canceling registration')
          }
          setTimeout(() => {
            this.$router.push(`/special_events/${this.eventId}`)
          }, 2000)
        })
    },
    requestSpecialEventMembership(format = null) {
      return api.request('request_special_event_membership', { id: this.data.Id, lang: this.lang, format })
        .then(data => {
          if (data?.registrationDecline) {
            if (this.data.OrgFormat === this.$i18n.tc('offline')) {
              this.$root.$refs.submitModal.open(this.$i18n.tc('registrationEnded'));
            } else {
              this.$root.$refs.submitModalHtml.open()
              return 1
            }
          } else {
            this.$root.$refs.submitModal.open(this.$i18n.tc('submited'))
            this.data.CanBeMember = false
            setTimeout(() => {
              this.$router.push(`/special_events/${this.eventId}`)
            }, 2000)
          }
        })
    },
    requestSpecialEventCard() {
      api.request('load_special_event_data', { id: this.cardId, lang: this.lang })
        .then(data => {
          this.data = data
        })
    },
  },
  computed: {
    workingLanguage() {
      return this.data.LangRu && this.data.LangEn ? `${this.data.LangRu}, ${this.data.LangEn}` : `${this.data.LangRu ?? this.data.LangEn}`
    },
    registrationEnded() {
      if (!this.data.EndRegistrationDate) {
        return false
      }
      return new Date() >= new Date(this.data.EndRegistrationDate)
    },
    showStreamLink() {
      return this.data.StreamLink && this.data.StreamLink !== ''
    },
    ...mapState(['lang']),
  },
  watch: {
    lang() {
      this.requestSpecialEventCard()
    }
  },
  i18n: {
    messages: {
      en: {
        submited: 'Participation request sent',
        title: 'Special events',
        info: 'Information about the event',
        specialEventType: 'Type',
        specialEventName: 'Topic',
        moderatorBlock: 'Moderators',
        speakerBlock: 'Speakers',
        disscusantsBlock: 'Disscusants',
        issueForDiscussion: 'Discussion points',
        dateOf: 'Date',
        timeOf: 'Time',
        translation: 'Simultaneous translation',
        eventFormat: 'Format (offline / online / blended)\n',
        streamLink: 'The stream of the event may be viewed via',
        link: 'the link',
        submit: 'Become a Participant',
        eventList: 'Go back',
        language: 'Language',
        yes: 'Yes',
        no: 'No',
        offline: 'Offline',
        mixed: 'Mixed',
        registrationEnded: 'Registration to the event is finished',
        cancel: 'Cancel my registration',
        canceled: 'Your registration has been cancelled',
        participantsExceededLink: 'The number of places available for Conference participants in the face-to-face format has been exceeded. You can still take part online <a class="link" target="_blank" href="{link}">link</a>',
        participantsExceeded: 'The number of places available for Conference participants in the face-to-face format has been exceeded.',
      },
      ru: {
        submited: 'Запрос на участие отправлен',
        title: 'Специальные мероприятия',
        info: 'Информация о мероприятии',
        specialEventType: 'Тип',
        specialEventName: 'Название',
        moderatorBlock: 'Модераторы',
        speakerBlock: 'Спикеры',
        disscusantsBlock: 'Дискуссанты',
        issueForDiscussion: 'Вопросы для обсуждения',
        dateOf: 'Дата проведения',
        timeOf: 'Время проведения',
        translation: 'Синхронный перевод',
        eventFormat: 'Формат проведения',
        streamLink: 'Трансляция мероприятия доступна по',
        link: 'ссылке',
        submit: 'Принять участие',
        eventList: 'Назад',
        language: 'Язык',
        yes: 'Да',
        no: 'Нет',
        offline: 'Офлайн',
        mixed: 'Смешанный',
        registrationEnded: 'Регистрация на специальное мероприятие завершена',
        cancel: 'Отменить регистрацию',
        canceled: 'Регистрация отменена',
        participantsExceededLink: 'К сожалению, количество регистраций в очном формате превышено. В связи с этим  возможно только онлайн-участие. Подключиться к мероприятию можно по <a class="link" target="_blank" href="{link}">ссылке</a>',
        participantsExceeded: 'К сожалению, количество регистраций в очном формате превышено. В связи с этим  возможно только онлайн-участие.',
      },
    }
  }
}
</script>

<style scoped>
.field {
  padding-top: 14px;

}

.link {
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.ident {
  margin-left: 10px;
}

.wrapper {
  max-width: 1500px;
  width: calc(100vw - 192px);
  margin: 24px auto;
}

@media (min-width: 667px) {
  .wrapper {
    width: calc(100vw - 48px)
  }
}

.card {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 32px 32px;
  max-width: 95%;
}

.link {
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.actions>* {
  margin: 0 15px;
}

.actions .link {
  padding: 12px 0;
}

.accordion {
  display: flex;
}
</style>
