import Vue from 'vue'
import App from './App.vue'
// Библиотека использует :focus-visible, для которого необходим полифилл
import 'focus-visible'
// Стили для ресета дефолтных
import 'sanitize.css'
import 'sanitize.css/forms.css'
import 'sanitize.css/typography.css'
// Подключение шрифтов, которые использует библиотека
import '@hse-design/core/src/fonts.scss'
// [Опционально] Подключение глобальных стилей
// import '@hse-design/core/lib/global.css'
// Подключение стилей компонентов
import '@hse-design/core/lib/index.css'
// Подключение стилей, специфичных для Vue
import '@hse-design/core/lib/vue/index.css'

import { router } from './router'
import { i18n } from './i18n'
import vueDebounce from 'vue-debounce'

Vue.use(vueDebounce)

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
