<template>
  <div class="register">
    <HseHeading size="h3">{{ $t('headings.profile') }}</HseHeading>
    <HseSpace size='small' vertical />
    <div class="subheading">{{ $t('headings.personal') }}</div>
    <HseSpace size='small' vertical />
    <div class="form">
      <div v-if="isLangRu" class="field">
        <Validator v-bind:field="fields.LName" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div v-if="isLangRu" class="field">
        <Validator v-bind:field="fields.FName" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div v-if="isLangRu" class="field">
        <Validator v-bind:field="fields.SName" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.FNameEN" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.LNameEN" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.Gender" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.BirthDate" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.Phone" />
        <HseSpace size='small_2x' vertical />
      </div>
      <div class="field">
        <div class="flex-tooltip">
          <Validator v-bind:field="fields.Citizen" @change="citizenChange" />
          <HseTooltip :content="$t('fields.placeholder.citizen')">
            <IconStatusInfo />
          </HseTooltip>
        </div>
        <HseSpace size='small_2x' vertical />
      </div>
      <ReadonlyInput :label="$t('fields.name.email')" :value="email" />
    </div>
    <!--    <HseSpace size='small' vertical />-->
    <!--    <div class="subheading">{{ $t('headings.location') }}</div>-->
    <!--    <HseSpace size='small' vertical />-->
    <!--    <div class="form">-->
    <!--      <div class="field">-->
    <!--        <Validator v-bind:field="fields.CountryId" />-->
    <!--      </div>-->
    <!--      <div v-if="isCountryRu" class="field">-->
    <!--        <Validator v-bind:field="fields.Region" />-->
    <!--      </div>-->
    <!--      <div class="field">-->
    <!--        <Validator v-bind:field="fields.City" />-->
    <!--      </div>-->
    <!--    </div>-->
    <HseSpace size='small' vertical />
    <div class="subheading">{{ $t('headings.company') }}</div>
    <HseSpace size='small' vertical />
    <div class="form">
      <div class="field">
        <Validator v-bind:field="fields.CompanyNameFull" />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.CompanyNameShort" />
      </div>
      <div class="field">
        <Validator v-bind:field="fields.Position" />
      </div>
    </div>
    <HseSpace size='small' vertical />
    <div class="checkboxes">
      <HseSpace size='small_2x' vertical />
      <Validator v-bind:field="fields.Special" />
    </div>
    <HseSpace size='medium' vertical />
    <div class="form-footer">
      <div class="actions">
        <HseButton v-on:click="save" :spinner="saveButtonSpinner" spinnerPosition="left">
          <span v-if="saveButtonSpinner === false">{{ $t('save') }}</span>
          <span v-else-if="saveButtonSpinner === true">{{ $t('saving') }}...</span>
          <span v-else-if="saveButtonSpinner === 'done'">{{ $t('auth.btnDone') }}</span>
        </HseButton>
        <HseButton v-show="userDataFull" v-on:click="cancel" variant="secondary">{{ $t('cancel') }}</HseButton>
      </div>
      <div>
        <span class="link" v-on:click="showPersonal"> {{ $t('pd') }} </span>
        <div class="form-description">{{ $t('fields.requiredFields') }}</div>
      </div>
    </div>
    <PersonalDataModal />
  </div>
</template>

<script>
import { HseHeading, HseSpace, HseButton, HseTooltip, IconStatusInfo } from '@hse-design/vue'
import { api } from '../api'
import Validator from '../components/common/form/Validator.vue'
import ReadonlyInput from '../components/common/ReadonlyInput.vue'
import fields from '../components/common/auth/registerFields'
import PersonalDataModal from '../components/common/modal/PersonalDataModal.vue'
import { mapState } from 'vuex'
import { localizeFields } from '../i18n'


export default {
  components: {
    HseHeading,
    HseSpace,
    HseButton,
    HseTooltip,
    IconStatusInfo,
    Validator,
    ReadonlyInput,
    PersonalDataModal,
  },
  data: () => ({
    data: {},
    fields,
    saveButtonSpinner: false,
  }),
  created() {
    this.translateFields()
    this.data = this.$store.state.user.data.public

    Object.keys(this.data).forEach(key => {
      const currentField = this.fields[key]
      if (!currentField) return
      currentField.value = this.data[key]
    })
  },
  computed: {
    email() {
      return this.$store.state.user.login
    },
    isLangRu() {
      return this.lang === 'ru'
    },
    isCitizenRu() {
      return this.fields.Citizen.value === 'BC763F4B-FBD9-461B-B395-166020E8C026'
    },
    // isCountryRu () {
    //   return this.fields.CountryId.value === 'BC763F4B-FBD9-461B-B395-166020E8C026'
    // },
    ...mapState(['lang', 'userDataFull']),
  },
  watch: {
    lang() {
      this.translateFields()
    }
  },
  methods: {
    showPersonal() {
      this.$emit('openPersonslDataModal')
    },
    citizenChange() {
      fields.SName.isRequired = this.isCitizenRu
      this.fields.SName.isRequired = this.isCitizenRu
      if (this.isCitizenRu && !this.fields.SName.label.includes('*')) {
        this.fields.SName.label = `${this.fields.SName.label.trim()} *`
      } else if (!this.isCitizenRu && this.fields.SName.label.includes('*')) {
        this.fields.SName.label = this.fields.SName.label.slice(0, this.fields.SName.label.length - 2)
      }
    },
    translateFields() {
      this.fields = localizeFields(fields, this.fields)
    },
    cancel() {
      this.$router.go(-1);
    },
    save() {
      this.$emit('validateFields')
      let isFormInvalid = false
      let skip = ['email', 'verification', 'isAgree']
      if (!this.isLangRu) {
        skip = [...skip, 'LName', 'FName', 'SName']
      }
      // if (!this.isCountryRu) {
      //   skip.push('Region')
      // }
      Object.keys(this.fields).some((key) => {
        isFormInvalid = skip.includes(key) ? false : this.fields[key].isValid === false
        return isFormInvalid
      })
      if (isFormInvalid) return

      this.saveButtonSpinner = true

      const { public: userData } = this.$store.state.user.data
      let params = {}
      Object.keys(this.fields).forEach(key => {
        if (!skip.includes(key)) {
          const currentField = this.fields[key]
          if (currentField.value !== userData[key]) {
            params[key] = currentField.value
            userData[key] = currentField.value
          }
        }
      })
      if (!Object.keys(params).length) {
        this.saveButtonSpinner = false
        return
      }
      api.auth('user.set.public.parameter', params).then(() => {
        this.saveButtonSpinner = 'done'
        this.$store.state.userDataFull = api.request('user_check_full', null)
        setTimeout(() => {
          this.saveButtonSpinner = false
        }, 2000)
      })
    },
  },
  i18n: {
    messages: {
      en: {
        headings: {
          profile: 'Profile',
          personal: 'Personal details',
          location: 'Location',
          company: 'Company',
        },
        saving: 'Saving',
        pd: 'Personal data processing agreement'
      },
      ru: {
        headings: {
          profile: 'Профиль пользователя',
          personal: 'Личная информация',
          location: 'Местонахождение',
          company: 'Организация',
        },
        saving: 'Сохраняем',
        pd: 'Согласие на обработку персональных данных',
      },
    }
  }
}
</script>

<style scoped>
.register {
  background: #FFFFFF;
  border-radius: 12px;
  margin: 53px auto;
  width: fit-content;
  padding: 32px 32px;
  width: 960px;
  max-width: calc(100vw - 320px)
}

.register .form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 -16px;
}

.register .form .field {
  padding: 0 16px;
  min-width: 0;
}

.subheading {
  font-family: 'Work Sans', sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #0F0F14;
}

/* .actions .hse-Button {
    display: inline-block;
  } */
.actions {
  display: flex;
}

.hse-Button:first-child {
  margin-right: 12px;
}

.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.form-description {
  padding-top: 12px;
  font-size: 14px;
  line-height: 20px;
  color: #6B7A99;
  text-align: right;
}

.link {
  color: blue;
  font-size: 80%;
  cursor: pointer;
}

.flex-tooltip {
  display: flex;
}

.flex-tooltip>div:first-child {
  width: calc(100% - 28px)
}

.flex-tooltip>div:last-child {
  padding: 36px 0 0 12px;
}

@media (max-width: 1023px) {
  .register {
    margin: 16px auto;
    max-width: 95%;
  }

  .register .form {
    grid-template-columns: repeat(2, 1fr);
  }

  .emptyfield {
    display: none;
  }
}

@media (max-width: 767px) {
  .register {
    margin: 0 auto;
    max-width: 100%;
    width: 100%;
  }

  .register .form {
    grid-template-columns: repeat(1, 1fr);
  }

  .form-footer {
    flex-wrap: wrap;
  }
}</style>
<style>.smallLabel {
  font-size: 14px !important;
}</style>
