<template>
    <div class="status" v-if="!isSaveErr">
        <svg width="110" height="100" viewBox="0 0 110 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M26.8055 8.42652C35.0279 2.93245 44.6949 0 54.584 0C67.8448 0 80.5625 5.26784 89.9393 14.6447C99.3161 24.0215 104.584 36.7392 104.584 50C104.584 59.8891 101.652 69.556 96.1575 77.7785C90.6634 86.0009 82.8545 92.4096 73.7182 96.194C64.5819 99.9783 54.5285 100.969 44.8295 99.0392C35.1304 97.11 26.2213 92.3479 19.2287 85.3553C12.236 78.3627 7.474 69.4535 5.54474 59.7545C3.61548 50.0555 4.60565 40.0021 8.39003 30.8658C12.1744 21.7295 18.583 13.9206 26.8055 8.42652ZM85.4219 35.1375L79.2732 28.9887L46.4721 61.7897L29.8964 45.214L23.7476 51.3627L46.4721 74.0872L85.4219 35.1375Z" fill="#0050CF"/>
        </svg>
        <HseHeading size="h3">{{ $t('headings.titleOk') }}</HseHeading>
        <HseSpace size='small' vertical />
        <div class="buttonBlock">
            <HseButton
                v-on:click="$emit('ok')"
            >
                <span>{{ $t('ok') }}</span>
            </HseButton>
        </div>
    </div>
    <div class="status" v-else-if="isSaveErr === 'canceledMembers'">
        <svg width="110" height="100" viewBox="0 0 110 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M26.8055 8.42652C35.0279 2.93245 44.6949 0 54.584 0C67.8448 0 80.5625 5.26784 89.9393 14.6447C99.3161 24.0215 104.584 36.7392 104.584 50C104.584 59.8891 101.652 69.556 96.1575 77.7785C90.6634 86.0009 82.8545 92.4096 73.7182 96.194C64.5819 99.9783 54.5285 100.969 44.8295 99.0392C35.1304 97.11 26.2213 92.3479 19.2287 85.3553C12.236 78.3627 7.474 69.4536 5.54474 59.7545C3.61548 50.0555 4.60565 40.0021 8.39003 30.8658C12.1744 21.7295 18.583 13.9206 26.8055 8.42652Z" fill="#FF564E"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M73.4702 35.2504L69.7498 31.53L55.0002 46.2796L40.2507 31.53L36.5303 35.2504L51.2799 50L36.5303 64.7496L40.2507 68.47L55.0002 53.7204L69.7498 68.47L73.4702 64.7496L58.7206 50L73.4702 35.2504Z" fill="white"/>
        </svg>
        <HseHeading size="h3">{{ $t('headings.canceledMembersErrTitle') }}</HseHeading>
        <HseSpace size='small' vertical />
        <HseHeading v-for="item of canceledMembers" size="h4" :key="item.id">{{ item.email }}</HseHeading>
        <HseSpace size='small' vertical />
        <HseHeading size="h3">{{ $t('canceledMembersErr') }}</HseHeading>
        <HseSpace size='small' vertical />
        <div class="buttonBlock">
            <HseButton
                v-on:click="$emit('ok')"
            >
                <span>{{ $t('cancel') }}</span>
            </HseButton>
            <HseButton
                v-on:click="$emit('delСanceledMembersAndSendCollectiveRequest', canceledMembers)"
            >
                <span>{{ $t('yes') }}</span>
            </HseButton>
        </div>
    </div>
    <div class="status" v-else-if="isSaveErr === 'allMembersIsCanceled'">
        <svg width="110" height="100" viewBox="0 0 110 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M26.8055 8.42652C35.0279 2.93245 44.6949 0 54.584 0C67.8448 0 80.5625 5.26784 89.9393 14.6447C99.3161 24.0215 104.584 36.7392 104.584 50C104.584 59.8891 101.652 69.556 96.1575 77.7785C90.6634 86.0009 82.8545 92.4096 73.7182 96.194C64.5819 99.9783 54.5285 100.969 44.8295 99.0392C35.1304 97.11 26.2213 92.3479 19.2287 85.3553C12.236 78.3627 7.474 69.4536 5.54474 59.7545C3.61548 50.0555 4.60565 40.0021 8.39003 30.8658C12.1744 21.7295 18.583 13.9206 26.8055 8.42652Z" fill="#FF564E"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M73.4702 35.2504L69.7498 31.53L55.0002 46.2796L40.2507 31.53L36.5303 35.2504L51.2799 50L36.5303 64.7496L40.2507 68.47L55.0002 53.7204L69.7498 68.47L73.4702 64.7496L58.7206 50L73.4702 35.2504Z" fill="white"/>
        </svg>
        <HseHeading size="h3">{{ $t('headings.titleAllMemberIsCanceledErr') }}</HseHeading>
        <HseSpace size='small' vertical />
        <HseHeading size="h3">{{ $t('allMembersIsCanceledErr') }}</HseHeading>
        <HseSpace size='small' vertical />
        <div class="buttonBlock">
            <HseButton
                v-on:click="$emit('ok')"
            >
                <span>{{ $t('ok') }}</span>
            </HseButton>
        </div>
    </div>
    <div class="status" v-else-if="isSaveErr">
        <svg width="110" height="100" viewBox="0 0 110 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M26.8055 8.42652C35.0279 2.93245 44.6949 0 54.584 0C67.8448 0 80.5625 5.26784 89.9393 14.6447C99.3161 24.0215 104.584 36.7392 104.584 50C104.584 59.8891 101.652 69.556 96.1575 77.7785C90.6634 86.0009 82.8545 92.4096 73.7182 96.194C64.5819 99.9783 54.5285 100.969 44.8295 99.0392C35.1304 97.11 26.2213 92.3479 19.2287 85.3553C12.236 78.3627 7.474 69.4536 5.54474 59.7545C3.61548 50.0555 4.60565 40.0021 8.39003 30.8658C12.1744 21.7295 18.583 13.9206 26.8055 8.42652Z" fill="#FF564E"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M73.4702 35.2504L69.7498 31.53L55.0002 46.2796L40.2507 31.53L36.5303 35.2504L51.2799 50L36.5303 64.7496L40.2507 68.47L55.0002 53.7204L69.7498 68.47L73.4702 64.7496L58.7206 50L73.4702 35.2504Z" fill="white"/>
        </svg>
        <HseHeading size="h3">{{ $t('headings.titleErr') }}</HseHeading>
        <HseSpace size='small' vertical />
        <div class="buttonBlock">
            <HseButton
                v-on:click="$emit('ok')"
            >
                <span>{{ $t('ok') }}</span>
            </HseButton>
        </div>
    </div>
</template>
<script>
    import { HseHeading, HseSpace, HseButton } from '@hse-design/vue'
    export default {
        components: {
            HseHeading,
            HseSpace,
            HseButton,
        },
        props: ['isSaveErr', 'canceledMembers'],
        i18n: {
            messages: {
                en: {
                    headings: {
                        titleOk: 'Your joint application has been registered and will be sent for review and approval by the Programme Committee.',
                        titleErr: 'An error occurred while submitting a collective request. Try again later or contact the event administrator about the error.',
                        canceledMembersErrTitle: 'The group proposal includes a proposal earlier cancelled by a participant from:',
                        titleAllMemberIsCanceledErr: 'All participants have cancelled their applications for participation in the group proposal. Please delete them and add new participants.'
                    },
                    canceledMembersErr: 'Upon submitting a group proposal for review, the cancelled proposal will be deleted. Do you want to continue?',
                    allMembersIsCanceledErr: 'If you want to cancel the group proposal, please contact the Organizing Committee at yasinconference@hse.ru',
                    ok: 'Ok',
                    back: 'Back',
                    yes: 'yes',
                    cancel: 'cancel',
                },
                ru: {
                    headings: {
                        titleOk: 'Ваша коллективная заявка зарегистрирована и будет направлена на рассмотрение членам Программного комитета.',
                        titleErr: 'При отправки коллективной заявки возникла ошибка. Попробуйте повторить отправку позже, или сообщите об ошибке администратору мероприятия.',
                        canceledMembersErrTitle: 'Коллективная заявка содержит отменную участником заявку:',
                        titleAllMemberIsCanceledErr: 'Все участники отменили заявки на участие в коллективной заявке. Удалите их и добавьте новых участников.'
                    },
                    canceledMembersErr: 'Перед отправкой коллективной заявки на рассмотрение отмененная заявка будет удалена. Хотите продолжить?',
                    allMembersIsCanceledErr: 'Если Вы хотите отменить коллективную заявку, пожалуйста, свяжитесь с Организационным комитетом (yasinconference@hse.ru)',
                    yes: 'Да',
                    cancel: 'Отмена',
                    ok: 'Ok',
                    back: 'Назад',
                },
            }
        }
    }
</script>
<style>
    .buttonBlock {
        display: flex;
        justify-content: space-between;
        width: fit-content;
    }
    .buttonBlock > button:first-child {
        margin-right: 20px;
    }
    .status {
        max-width: 450px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 0 auto;
    }
</style>