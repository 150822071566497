<template>
  <div class="event-card">
    <Loading v-if="!isDataLoaded" />
    <div v-else class="card">
      <HseHeading size="h3">
        {{ $t('reqForEvent', { number: req.requestNumber }) }} <a :href="`#/events/${req.eventId}`">{{ req.eventTitle }}</a>
      </HseHeading>
      <HseSpace size="small" vertical />
      <div class="two-cols">
        <ReadonlyInput :label="$t('requestStatus')" :value="req.status" />
        <ReadonlyInput :label="$t('createdAt')" :value="dateFormat(req.createdAt)" />
      </div>
      <div class="two-cols">
        <ReadonlyInput :label="$t('eventTitle')" :value="req.eventTitle" />
        <ReadonlyInput :label="$t('sessionName')" :value="req.sessionName" />
        <ReadonlyInput :label="$t('directionName')" :value="req.direction" />
      </div>
      
      <HseSpace size="medium" vertical />

      <div :style="{background: '#6B7A99', padding: '6px'}">
        <HseButton variant='tertiaryInvert' size='medium' v-on:click="isModersOpen = !isModersOpen">
          {{$t('moderators')}} <span class="ident" v-show="isModersOpen">&#9650;</span><span class="ident" v-show="!isModersOpen">&#9660;</span>
        </HseButton>
      </div>
      <div v-show="isModersOpen" :style="{background: '#F0F2F5', padding: '6px'}">
        <div v-for="(item, index) of req.Moderators" class="cols two-cols dropdown" :key="item.id">
          <HseSpace size="medium" vertical />
          <HseSpace size="medium" vertical />
          <HseHeading size="h4">{{ `${$t('fields.name.moderators')} №${index + 1}` }}</HseHeading>
          <HseSpace size="medium" vertical />
          <HseSpace size="medium" vertical />
          <HseSpace size="medium" vertical />
          <ReadonlyInput :label="$t('fields.name.lName')" :value="item.lastName" />
          <ReadonlyInput :label="$t('fields.name.fName')" :value="item.firstName" />
          <ReadonlyInput v-if="item.secondName" :label="$t('fields.name.mName')" :value="item.secondName" />
          <ReadonlyInput :label="$t('fields.name.email')" :value="item.email" />
          <ReadonlyInput :label="$t('fields.name.companyFull')" :value="item.company" />
          <HseSpace v-if="req.Moderators.length > 1" size="medium" vertical />
          <HseSpace v-if="req.Moderators.length > 1" size="medium" vertical />
          <HseSpace v-if="isLangRu && item.secondName" size="medium" vertical />
          <HseButton v-if="req.isModerator && (req.status === 'Создана' || req.status === 'Registered') && req.Moderators.length > 1"
            color="#F0522B"
            size="medium"
            v-on:click="() => editCurrentActionDel(role.moderators, item.id)"
            :spinner="buttonSpinner"
            spinnerPosition="left"
          >
            {{ $t('deleteModerator') }}
          </HseButton>
        </div>
        <HseSpace size="medium" vertical />
        <HseButton v-if="req.isModerator && (req.status === 'Создана' || req.status === 'Registered')"
          size="medium"
          v-on:click="() => this.isModerModalOpen = true"
          :spinner="buttonSpinner"
          spinnerPosition="left"
        >
          {{ $t('addModerator') }}
        </HseButton>
      </div>

      <HseSpace size="medium" vertical />
      <div :style="{background: '#6B7A99', padding: '6px'}">
        <HseButton variant='tertiaryInvert' size='medium' v-on:click="isMembOpen = !isMembOpen">
          {{$t('members')}} <span class="ident" v-show="isMembOpen">&#9650;</span><span class="ident" v-show="!isMembOpen">&#9660;</span>
        </HseButton>
      </div>
      <div v-show="isMembOpen" :style="{background: '#F0F2F5', padding: '6px'}">
        <div v-for="(item, index) of req.Members" class="cols two-cols dropdown" :key="item.id">
          <HseSpace size="medium" vertical />
          <HseSpace size="medium" vertical />
          <HseHeading size="h4">{{ `${$t('fields.name.members')} №${index + 1}` }}</HseHeading>
          <HseSpace size="medium" vertical />
          <HseSpace size="medium" vertical />
          <HseSpace size="medium" vertical />
          <ReadonlyInput
                  :label="$t('fields.name.email')"
                  :value="item.email"
          />
          <ReadonlyInput
                  :label="$t('fields.request.status')"
                  :value="item.value"
          />
          <HseSpace v-if="req.Members.length > 1" size='small' vertical />
          <HseSpace v-if="req.Members.length > 1" size='small' vertical />
          <HseButton v-if="req.isModerator && (req.status === 'Создана' || req.status === 'Registered') && req.Members.length > 1"
            color="#F0522B"
            size="medium"
            v-on:click="() =>editCurrentActionDel(role.members, item.id, item.email)"
            :spinner="buttonSpinner"
            spinnerPosition="left"
          >
            {{ $t('deleteMember') }}
          </HseButton>
        </div>
        <HseSpace size="medium" vertical />
        <HseButton v-if="req.isModerator && (req.status === 'Создана' || req.status === 'Registered')"
          size="medium"
          v-on:click="checkMaxParticipants"
          :spinner="buttonSpinner"
          spinnerPosition="left"
        >
          {{ $t('addMember') }}
        </HseButton>
      </div>
      <HseSpace size="medium" vertical />
      <div :style="{background: '#6B7A99', padding: '6px'}">
        <HseButton variant='tertiaryInvert' size='medium' v-on:click="isDissOpen = !isDissOpen">
          {{$t('disscussants')}} <span class="ident" v-show="isDissOpen">&#9650;</span><span class="ident" v-show="!isDissOpen">&#9660;</span>
        </HseButton>
      </div>
      <div v-show="isDissOpen" :style="{background: '#F0F2F5', padding: '6px'}">
        <div v-for="(item, index) of req.Disscussants" class="cols two-cols dropdown" :key="item.id">
          <HseSpace size="medium" vertical />
          <HseSpace size="medium" vertical />
          <HseHeading size="h4">{{ `${$t('fields.name.disscussants')} №${index + 1}` }}</HseHeading>
          <HseSpace size="medium" vertical />
          <HseSpace size="medium" vertical />
          <HseSpace size="medium" vertical />
          <ReadonlyInput :label="$t('fields.name.lName')" :value="item.lastName" />
          <ReadonlyInput :label="$t('fields.name.fName')" :value="item.firstName" />
          <ReadonlyInput v-if="item.secondName" :label="$t('fields.name.mName')" :value="item.secondName" />
          <ReadonlyInput :label="$t('fields.name.email')" :value="item.email" />
          <ReadonlyInput :label="$t('fields.name.companyFull')" :value="item.company" />
          <HseSpace size="medium" vertical />
          <HseSpace size="medium" vertical />
          <HseSpace v-if="isLangRu" size="medium" vertical />
          <HseButton v-if="req.isModerator && (req.status === 'Создана' || req.status === 'Registered')"
            color="#F0522B"
            size="medium"
            v-on:click="() =>editCurrentActionDel(role.disscussants, item.id)"
            :spinner="buttonSpinner"
            spinnerPosition="left"
          >
            {{ $t('deleteDisscussant') }}
          </HseButton>
        </div>
        <HseSpace size="medium" vertical />
        <HseButton v-if="req.isModerator && (req.status === 'Создана' || req.status === 'Registered')"
          size="medium"
          v-on:click="() => this.isDissModalOpen = true"
          :spinner="buttonSpinner"
          spinnerPosition="left"
        >
          {{ $t('addDisscussant') }}
        </HseButton>
      </div>
      <HseSpace size='small' vertical />
      <div class="cols-2-05">
        <a class="link" :href="`/#/${req.isModerator ? 'collective-requests' : 'requests'}`">{{$t(`${req.isModerator ? 'backToCollectiveRequest' : 'backToRequest'}`)}}</a>
        <HseButton v-if="req.isModerator && (req.status === 'Создана' || req.status === 'Registered') && isAllMembersApprove"
          size="medium"
          v-on:click="sendCollectiveRequest"
          :spinner="buttonSendSpinner"
          spinnerPosition="left"
          class="pull-right-action-btn"
        >
          {{ $t('send') }}
        </HseButton>
        <HseTooltip v-else-if="req.isModerator && (req.status === 'Создана' || req.status === 'Registered') && !isAllMembersApprove" class="relative" :content="$t('notApprove')">
          <HseButton class="pull-right disabled" size="medium">
            {{ $t('send') }}
          </HseButton>
        </HseTooltip>
      </div>

    </div>
    <HseModal v-model="isModerModalOpen" :shouldCloseOnOverlayClick="false">
      <ModeratorForm
        v-if="isModerModalOpen"
        :added="req.Moderators"
        @saved="onModeratorSave"
      />
    </HseModal>
    <HseModal v-model="isPartModalOpen" :shouldCloseOnOverlayClick="false">
      <ParticipantForm
        v-if="isPartModalOpen"
        :added="req.Members"
        @saved="onParticipantSave"
      />
    </HseModal>
    <HseModal v-model="isDissModalOpen" :shouldCloseOnOverlayClick="false">
      <DisscussantForm
        v-if="isDissModalOpen"
        :added="req.Disscussants"
        @saved="onDisscussantSave"
      />
    </HseModal>
    <HseModal v-model="isWarnModalOpen" :shouldCloseOnOverlayClick="false">
      <WarningModal
        v-if="isWarnModalOpen"
        @delEntry="() => deleteEntry(this.currentRole, this.entryId, this.memberEmail)"
        @cancel="() => isWarnModalOpen = false"
      />
    </HseModal>
    <HseModal v-model="isSendModalOpen" :shouldCloseOnOverlayClick="false">
      <SendCollectiveReqModal
        @ok="() => this.isSendModalOpen = false"
        :isSaveErr="isSaveErr"
        :canceledMembers="canceledMembers"
        @delСanceledMembersAndSendCollectiveRequest="(canceledMembersArr) => this.delСanceledMembersAndSendCollectiveRequest(canceledMembersArr, false)"
      />
    </HseModal>
    <SubmitModal />
  </div>
</template>

<script>

  import { api } from '../../api'
  import { store } from '../../store'
  import Loading from '../common/Loading.vue'
  import { HseSpace, HseHeading, HseButton, HseModal, HseTooltip } from '@hse-design/vue'
  import ParticipantForm from './ParticipantForm.vue'
  import ModeratorForm from './ModeratorForm.vue'
  import DisscussantForm from './DisscussantForm'
  import fields from './managerFields'
  import WarningModal from './WarningModal.vue'
  import ReadonlyInput from '../common/ReadonlyInput.vue'
  import { mapState } from 'vuex'
  import { dateFormatter } from '../common/dateFormatter'
  import SubmitModal from '../common/modal/SubmitModal.vue'
  import SendCollectiveReqModal from './SendCollectiveReqModal.vue'

  export default {
    store,
    components: {
      HseSpace,
      HseModal,
      HseHeading,
      Loading,
      ReadonlyInput,
      HseButton,
      HseTooltip,
      ModeratorForm,
      ParticipantForm,
      DisscussantForm,
      WarningModal,
      SendCollectiveReqModal,
      SubmitModal
    },
    data: () => ({
      isModerModalOpen: false,
      isDissModalOpen: false,
      isPartModalOpen: false,
      isWarnModalOpen: false,
      isSendModalOpen: false,
      isSaveErr: false,
      eventId: '',
      isDataLoaded: false,
      fields,
      req: null,
      experts: null,
      isCoauthorsOpen: false,
      isReportOpen: false,
      isMembOpen: false,
      isDissOpen: false,
      isModersOpen: false,
      isParticipationOpen: false,
      buttonSpinner: false,
      buttonSendSpinner: false,
      cancelSpinner: false,
      isSaved: false,
      eventData: {},
      currentRole: '',
      entryId: '',
      memberEmail: '',
      canceledMembers: [],
      role: {
        disscussants: 'disscussants',
        moderators: 'moderators',
        members: 'members'
      },
      roleStatus: {
        disscussants: '8EB5C9A2-8EE1-DB30-4956-B9E08C3356D0',
        moderators: '1DEDD098-DAE2-DDE9-4C9F-40606EA5659D',
      },
    }),
    created () {
      this.requestId = this.$route.params.id
      fields.ExpertSelect.requestId = this.$route.params.id
      this.requestEventData()
    },
    methods: {
      delСanceledMembersAndSendCollectiveRequest(canceledMembersArr, isShowMembers) {
        this.canceledMembers = canceledMembersArr
        if(isShowMembers) {
          this.isSaveErr = 'canceledMembers'
          this.isSendModalOpen = true
          return 
        }
        this.isSendModalOpen = false
        canceledMembersArr.forEach(item => {
          this.deleteMember(item.id, item.email, true)
        })
      },
      sendCollectiveRequest() {
        const isAllMembersApprove = this.req.Members.some(item => item.value === 'Подтверждено' || item.value === 'Confirmed')
        if(!isAllMembersApprove) {
          this.$root.$refs.submitModal.open(this.$i18n.tc('notAllMembersApprove'))
          return
        }
        const canceledMembers = this.req.Members.filter(item => item.isCanceled === true)
        console.log(canceledMembers);
        if (canceledMembers.length === this.req.Members.length) {
          this.isSaveErr = 'allMembersIsCanceled'
          this.isSendModalOpen = true
          return
        }
        if (canceledMembers.length !== 0) {
          this.delСanceledMembersAndSendCollectiveRequest(canceledMembers, true)
          return
        }
        const params = {
          collectiveRequestId: this.req.id,
          lang: this.lang,
          direction: this.req.direction
        }
        this.buttonSendSpinner = true
        api.request('collective_request_confirm', params).then((res) => {
          this.buttonSendSpinner = 'done'
          setTimeout(() => {
            if (res === 'Сollective request has been successfully confirmed') {
              this.isSaveErr = false
            } else {
              this.isSaveErr = true
            }
            this.buttonSendSpinner = false
            this.isSendModalOpen = true
            this.requestEventData()
          }, 2000)
        })
      },
      editCurrentActionDel(role, id, memberEmail) {
        this.currentRole = role
        this.entryId = id
        this.memberEmail = memberEmail ? memberEmail : this.memberEmail
        this.isWarnModalOpen = true
      },
      deleteEntry(role, id, memberEmail) {
        if (role === this.role.disscussants) {
          this.deleteDisscussantAndModerator(id, false)
        } else if (role === this.role.moderators) {
          this.deleteDisscussantAndModerator(id, true)
        } else if (role === this.role.members) {
          this.deleteMember(id, memberEmail)
        }
        this.isWarnModalOpen = false
      },
      onDisscussantSave (result) {
        const params = {
          collectiveRequestId: this.req.id,
          moderators: [
            {
              roleId: this.roleStatus.disscussants,
              email: result.login,
              lastName: result.data.public.LName,
              firstName: result.data.public.FName,
              secondName: result.data.public.SName,
              company : result.CompanyNameShort,
              lastNameEn: result.data.public.LNameEN,
              firstNameEn: result.data.public.FNameEN,
              companyEn: result.CompanyNameShortEn
            }
          ]
        }
        api.request('collective_request_add_moderator', params).then(() => {
          this.requestEventData()
          this.isDissModalOpen = false
        })
      },
      checkMaxParticipants () {
        if (!Array.isArray(this.req.Members) || this.req.Members.length < this.eventData.collectiveMembersMaxCount) {
          this.isPartModalOpen = true
        } else {
          this.$root.$refs.submitModal.open(this.$i18n.tc('maxParticipants'))
        }
      },
      onParticipantSave (result) {
        const params = {
          collectiveRequestId: this.req.id,
          eventId: this.eventId,
          members: [
            {
              email: result.login
            }
          ]
        }
        api.request('collective_request_add_members', params).then(() => {
          this.requestEventData()
          this.isPartModalOpen = false
        })
      },
      onModeratorSave (result) {
        const params = {
          collectiveRequestId: this.req.id,
          moderators: [
            {
              roleId: this.roleStatus.moderators,
              email: result.login,
              lastName: result.data.public.LName,
              firstName: result.data.public.FName,
              secondName: result.data.public.SName,
              company : result.CompanyNameShort,
              lastNameEn: result.data.public.LNameEN,
              firstNameEn: result.data.public.FNameEN,
              companyEn: result.CompanyNameShortEn
            }
          ]
        }

        api.request('collective_request_add_moderator', params).then(() => {
          this.requestEventData()
          this.isModerModalOpen = false
        })
      },
      deleteDisscussantAndModerator(dissOrModerId, isModer) {
        if (isModer && this.req.Moderators.length === 1) {
          this.$root.$refs.submitModal.open(this.$i18n.tc('noModerators'))
          this.isWarnModalOpen = false
          return
        }
        const params = {
          collectiveRequestId: this.req.id,
          id: dissOrModerId
        }
        this.buttonSpinner = true
        this.isWarnModalOpen = false
        api.request('collective_request_delete_moderator', params).then(() => {
          this.buttonSpinner = 'done'
          this.requestEventData()
          setTimeout(() => {
            this.buttonSpinner = false
          }, 2000)
        })
      },
      deleteMember(memberId, memberEmail, isSend) {
        if (this.req.Members.length === 1) {
          this.$root.$refs.submitModal.open(this.$i18n.tc('noParticipants'))
          return
        }
        const params = {
          collectiveRequestId: this.req.id,
          id: memberId,
          lang: this.lang,
          email: memberEmail
        }
        this.buttonSpinner = true
        this.isWarnModalOpen = false
        api.request('collective_request_delete_member', params).then(() => {
          this.buttonSpinner = 'done'
          this.requestEventData(isSend)
          setTimeout(() => {
            this.buttonSpinner = false
          }, 2000)
        })
      },
      openReportField() {
        if (this.req.StatusId === '96F18960-F327-419C-B1FA-D4CFA3FD5103') {
          this.isReportOpen = true
        }
      },
      requestEventData (isSend) {
        this.isDataLoaded = false
        api.request('collective_request_data', { lang: this.lang, collectiveRequestId: this.$route.params.id  }).then((res) => {
          this.req = res
          api.request('eventGet', { id: res.eventId }).then(data => {
            this.eventData = data[0]
          })
          this.eventId = res.eventId
          this.isDataLoaded = true
          this.openReportField()
          if (isSend === true) this.sendCollectiveRequest()
        })
      },
      dateFormat(date) { return dateFormatter.format(date) },
    },
    computed: {
      isAllMembersApprove () {
        return Array.isArray(this.req.Members) && this.req.Members.every(item => item.value === 'Подтверждено' || item.value === 'Confirmed')
      },
      isLangRu () {
        return this.lang === 'ru'
      },
      ...mapState(['lang']),
    },
    watch: {
      lang () {
        this.requestEventData()
      }
    },
    i18n: {
      messages: {
        en: {
          reqForEvent: 'Collective request #{number} for event',
          moderators: 'Information about moderators',
          members: 'Information about members',
          disscussants: 'Information about disscussants',
          number: 'Request number',
          coauthors: 'co-Authors',
          experts: 'Experts',
          set: 'Set',
          Novelty: 'Novelty',
          Actuality: 'Actuality',
          Depth: 'Depth',
          TotalResult: 'Total result',
          TotalResultTitle: 'Conclusion',
          remove: 'Remove',
          backToCollectiveRequest: 'Back to сollective request list',
          backToRequest: 'Back to request list',
          changeStatus: 'Change status',
          paper: 'Report',
          payment: 'Payment',
          requestStatus: 'Request status',
          createdAt: 'created at',
          eventTitle: 'event title',
          sessionName: 'session name',
          directionName: 'Topic name',
          paymentStatus: 'Payment status',
          certificateRu: 'Download certificate(ru)',
          certificateEn: 'Download certificate(en)',
          notPayed: 'Not payed',
          payed: 'Payed',
          paymentProcess: 'In payment process',
          PaymentNotNeeded: 'Payment not needed',
          PaymentDisabled: 'Payment disabled',
          commit: 'Confirm participation and upload presentation',
          change: 'Change',
          ParticipationNull: 'No data',
          ParticipationTrue: 'Confirmed',
          ParticipationFalse: 'Declined',
          ParticipationStatus: 'Participation status',
          presentation: 'Presentation',
          save: 'Save',
          adding: 'Adding',
          btnDone: 'Done',
          cancel: 'Cancel request',
          canceling: 'Canceling request',
          reviewStatus: 'Review status',
          statusComment: 'Comment',
          deleteMember: 'Delete member',
          deleteDisscussant: 'Delete Disscussant',
          deleteModerator: 'Delete moderator',
          addModerator: 'Add moderator',
          addDisscussant: 'Add disscussant',
          send: 'Send',
          addMember: 'Add member',
          maxParticipants: 'You added maximum participants. Adding new participants is impossible',
          noParticipants: 'There must be at least one member',
          noModerators: 'There must be at least one moderator',
          notAllMembersApprove: 'Not all participants confirmed participation in the collective session. If you want to submit an request, please remove the participants who have not confirmed their participation.',
          notApprove: 'The application may only be submitted after all participants have confirmed it.'
        },
        ru: {
          reqForEvent: 'Коллективная заявка №{number} на мероприятие',
          moderators: 'Информация о модераторах',
          members: 'Информация об участниках',
          disscussants: 'Информация о дискуссантах',
          number: 'Номер заявки',
          coauthors: 'Соавторы',
          experts: 'Эксперты',
          set: 'Назначить',
          Novelty: 'Новизна',
          Actuality: 'Актуальность',
          Depth: 'Глубина',
          TotalResult: 'Оценка',
          TotalResultTitle: 'Заключение',
          remove: 'Удалить',
          backToCollectiveRequest: 'К коллективным заявкам',
          backToRequest: 'К заявкам',
          changeStatus: 'Изменить статус',
          paper: 'Доклад',
          payment: 'Оплата',
          requestStatus: 'Статус заявки',
          createdAt: 'Дата создания',
          eventTitle: 'Мероприятие',
          sessionName: 'Название сессии',
          directionName: 'Название направления',
          paymentStatus: 'Статус оплаты',
          certificateRu: 'Скачать сертификат(рус.)',
          certificateEn: 'Скачать сертификат(англ.)',
          notPayed: 'Не оплачена',
          payed: 'Оплачена',
          paymentProcess: 'В процессе оплаты',
          PaymentNotNeeded: 'Оплата не требуется',
          PaymentDisabled: 'Оплата недоступна',
          commit: 'Подтверждение участия и загрузка презентации',
          change: 'Изменить',
          ParticipationNull: 'Решение не принято',
          ParticipationTrue: 'Подтверждено',
          ParticipationFalse: 'Не подтверждено',
          ParticipationStatus: 'Подтверждение участия',
          presentation: 'Презентация',
          save: 'Сохранить',
          adding: 'Добавляем',
          btnDone: 'Готово',
          cancel: 'Отменить заявку',
          canceling: 'Отмена заявки',
          reviewStatus: 'Статус рассмотрения',
          statusComment: 'Комментарий',
          deleteMember: 'Удалить участника',
          deleteDisscussant: 'Удалить дискуссанта',
          deleteModerator: 'Удалить модератора',
          addModerator: 'Добавить модератора',
          addDisscussant: 'Добавить дискуссанта',
          send: 'Отправить',
          addMember: 'Добавить участника',
          maxParticipants: 'Вы добавили максимальное количество участников. Добавление новых участников невозможно',
          noParticipants: 'Должен остаться хотя бы один участник',
          noModerators: 'Должен остаться хотя бы один модератор',
          notAllMembersApprove: 'Не все участники подтвердили участие в коллективной сессии. Если вы хотите отправить заявку, удалите участников, не подтвердивших свое участие.',
          notApprove: 'Заявку можно отправить только после подтверждения всех участников.'
        },
      }
    }
  }
</script>
<style>
  .annotation .field .fileUploader .description {
    color: #6b7a99;
  }
</style>
<style scoped>
  .dropdown > h4,
  .dropdown > button {
    margin-left: 20px;
  }
  .status-comment {
    margin: 0 -16px;
  }
  .annotation .field {
    padding: 0;
  }
  .event-card {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 32px;
    max-width: 800px;
    width: calc(100vw - 128px);
    margin: 52px auto;
  }
  .subheading {
    font-family: 'Work Sans', sans-serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #0F0F14;
  }
  .field {
    padding: 0 16px;
    min-width: 0;
  }
  .link {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    width: fit-content;
  }
  .relative {
    position: relative;
  }
  .two-cols {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px;
  }
  .two-cols>div {
    width: 50%;
  }

  .cols-3 {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    gap: 5px;
    align-items: center;
  }

  .cols-2 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 5px;
    align-items: center;
  }
  .cols-2-05 {
    display: grid;
    grid-template-columns: 2fr 0.5fr;
    gap: 5px;
    align-items: center;
  }

  .pull-right {
    position: absolute;
    right: 0;
    top: 0;
  }
  .pull-right-action-btn {
    margin-left: auto;
    margin-right: 0;
  }
  .disabled {
    opacity: 0.5;
    background-color: rgba(37, 37, 65, 0.6);;
  }

  .ident {
    margin-left: 10px;
  }
  @media (max-width: 1023px) {
    .event-card {
      margin: 16px auto;
      width: 95%;
    }
  }
  @media (max-width: 767px) {
    .event-card {
      width: 100%;
      margin: 0;
    }
    .two-cols>div {
      width: 100%;
    }
    .cols-2-05 {
    grid-template-columns: 2fr 1fr;
  }
  }
</style>
<style>
  .hse-Table__td {
    word-break: keep-all;
  }
  .hse-Button.hse-Button_variant_tertiaryInvert.hse-Button_size_medium {
    width: auto;
    min-height: 40px;
    height: auto;
  }
</style>
