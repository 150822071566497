import { HseSelect, HseTextArea } from '@hse-design/vue'
import { resourceLoader } from '../../components/common/resourceLoader'
import { store } from '../../store/index'

let rates = {}

function translateRates() {
  if (store.state.lang === 'ru') {
    rates = {
      Rate1: 'плохо',
      Rate2: 'неудовлетворительно',
      Rate3: 'удовлетворительно',
      Rate4: 'хорошо',
      Rate5: 'отлично',
    }
  } else {
    rates = {
      Rate1: 'very bad',
      Rate2: 'bad',
      Rate3: 'acceptable',
      Rate4: 'good',
      Rate5: 'excellent',
    }
  }
}
translateRates()

const fields = {
  ExpertRate1: {
    value: '',
    isValid: false,
    isRequired: true,
    label: 'fields.expert.Novelty',
    size: "large",
    component: HseSelect,
    getAllOptions () {
      return new Promise((resolve) => {
        setTimeout(() => {
          translateRates()
          const genders = [
            { value: '1', label: '1 - ' + rates.Rate1 },
            { value: '2', label: '2 - ' + rates.Rate2 },
            { value: '3', label: '3 - ' + rates.Rate3 },
            { value: '4', label: '4 - ' + rates.Rate4 },
            { value: '5', label: '5 - ' + rates.Rate5 },
          ]
          resolve(genders)
        }, 100)
      })
    },
  },
  ExpertRate2: {
    value: '',
    isValid: false,
    isRequired: true,
    label: 'fields.expert.Actuality',
    size: "large",
    component: HseSelect,
    getAllOptions () {
      return new Promise((resolve) => {
        setTimeout(() => {
          translateRates()
          const genders = [
            { value: '1', label: '1 - ' + rates.Rate1 },
            { value: '2', label: '2 - ' + rates.Rate2 },
            { value: '3', label: '3 - ' + rates.Rate3 },
            { value: '4', label: '4 - ' + rates.Rate4 },
            { value: '5', label: '5 - ' + rates.Rate5 },
          ]
          resolve(genders)
        }, 100)
      })
    },
  },
  ExpertRate3: {
    value: '',
    isValid: false,
    isRequired: true,
    label: 'fields.expert.Depth',
    size: "large",
    component: HseSelect,
    getAllOptions () {
      return new Promise((resolve) => {
        setTimeout(() => {
          translateRates()
          const genders = [
            { value: '1', label: '1 - ' + rates.Rate1 },
            { value: '2', label: '2 - ' + rates.Rate2 },
            { value: '3', label: '3 - ' + rates.Rate3 },
            { value: '4', label: '4 - ' + rates.Rate4 },
            { value: '5', label: '5 - ' + rates.Rate5 },
          ]
          resolve(genders)
        }, 100)
      })
    },
  },
  ExpertTotalResult: {
    value: '',
    isValid: false,
    isRequired: true,
    label: 'fields.expert.TotalResultTitle',
    size: "large",
    component: HseSelect,
    searchable: 'dropdown',
    getAllOptions (search) {
      return resourceLoader.load('expertTotalResultOptionsListShort', {lang: store.state.lang}, [], search)
    },
  },
  ExpertComment: {
    value: '',
    isValid: false,
    isRequired: true,
    label: 'fields.expert.Comment',
    size: "large",
    component: HseTextArea,
  },


}

Object.keys(fields).forEach((key) => {
  fields[key].setValues = function (isValid, value = '') {
    this.value = value
    this.isValid = isValid
  }
})

export default fields