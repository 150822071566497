<template>
  <div :class="hide ? 'hide' : 'wrap'" v-if="slides.length > 0">
    <div @click="closeBanner" class="close">
      <HseIcon :icon="IconActionDelete" size="medium" />
    </div>
    <ul class="indicators">
      <li 
        v-for="slide in slides.length"
        :key="slide - 1"
        :class="'indicator' + (currentSlide === slide - 1 ? ' active' : '')" />
    </ul>
    <HseCarousel
      class="carousel"
      v-model="currentSlide"
      :navigation="false"
      :autoplay="true"
      :autoplayInterval="8000"
    >
      <HseCarouselSlide
        v-for="slide in slides"
        :key="slide.id"
        class="slide"
      > 
        <img :src="slide.image" class="image"/>
        <div class="content">
          <div class="text">
            <HseHeading size="h4">{{ slide.title }}</HseHeading>
            <HseParagraph :small="true">{{ slide.description }}</HseParagraph>
          </div>
          <HseButton
            variant="primary"
            component="a"
            :href="slide.btnLink"
            target="_blank">
              {{ slide.btnText }}
            </HseButton>
        </div>
      </HseCarouselSlide>
    </HseCarousel>
  </div>
</template>

<script>
  import { HseCarousel, HseCarouselSlide, HseButton, HseHeading, HseParagraph, IconActionDelete, HseIcon } from '@hse-design/vue'
  import { api } from '../../api'
  import { mapState } from 'vuex'

  export default {
    name: 'TopNewsBanner',
    data: () => ({
      IconActionDelete,
      slides: [],
      currentSlide: 0,
      hide: false,
    }),
    created () {
      this.getSlides()
    },
    components: {
      HseCarousel,
      HseCarouselSlide,
      HseButton,
      HseHeading,
      HseParagraph,
      HseIcon,
    },
    methods: {
      getSlides () {
        api.request('newsBannerGet', {lang: this.lang}, []).then(data => {
          this.slides = data.map(slide => ({
            id: slide.imageId,
            image: (process.env.NODE_ENV === 'development' ? 'http://aprilconference-test.hse.ru' : window.location.origin) + '/files/public/' + slide.imageId,
            title: slide.title,
            description: slide.subtitle,
            btnText: slide.action,
            btnLink: slide.link,
          }))
        })
      },
      closeBanner () {
        this.hide = true
      },
    },
    computed: {
      ...mapState(['lang'])
    },
    watch: {
      lang () {
        this.requestItems()
      }
    },  
  }
</script>

<style scoped>
.wrap {
  width: 100%;
  height: 230px;
  position: relative;
  margin: 20px 0;
}
.hide {
  display: none;
}
.carousel {
  height: 100%;
}

.slide {
  height: 230px !important;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #000;
  overflow: hidden;
}

.close {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 5;
  cursor: pointer;
}

.close svg {
  color: #fff;
}

.indicators {
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 5;
  color: #fff;
  display: flex;
  gap: 4px;
  text-decoration-style: none;
}

.indicator {
  display: block;
  height: 2px;
  background-color: #fff;
  width: 30px;
  opacity: .4;
}

.indicator.active {
  opacity: 1;
}

.image {
  position: absolute;
  overflow: hidden;
  height: 100%;
  min-width: 100%;
  right: 50%;
  transform: translateX(50%);
  z-index: 1;
  opacity: .6;
}

.content {
  z-index: 2;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text {
  color: #fff;
}

.button {
  width: 200px;
}
</style>
