<template>
    <div>
        <HseModalInfo :modal-class="'payment-free-code-modal'" v-model="isOpen" :modal-style="{ maxWidth: '350px' }" ref="modal">
            <div v-if="statusCode === true">{{ $t('successCode') }}</div>
            <div v-if="statusCode === 10">{{ $t('errorUsedCode') }}</div>
            <div v-if="statusCode === 9">{{ $t('errorNotFoundCode') }}</div>
            <div v-if="statusCode === 10 || statusCode === 9">{{ $t('errorMsg') }}</div>
        </HseModalInfo>
    </div>
</template>

<script>
    import { HseModalInfo,  } from '@hse-design/vue'
    import { store } from '../../../store'

    export default {
        store,
        components: {
            HseModalInfo,
        },
        props: {
            offerData: {
                type: String
            }
        },
        data: () => ({
            isOpen: false,
            isLoading: false,
            statusCode: 9,
        }),
        created () {
            this.$root.$refs.paymentFreeCodeModal = this;
        },
        methods: {
            openOfferModal(status) {
                this.isOpen = true;
                this.statusCode = status
                setTimeout(() => {
                    this.isOpen = false
                },5000)
            }
        },
        i18n: {
            messages: {
                en: {
                    successCode: 'Code successfully applied.',
                    errorUsedCode: 'The entered code has already been used.',
                    errorNotFoundCode: 'Invalid code entered.',
                    errorMsg: 'Enter another code or empty the field.',
                },
                ru: {
                    successCode: 'Код успешно применен.',
                    errorUsedCode: 'Введенный код уже использован.',
                    errorNotFoundCode: 'Введен неверный код.',
                    errorMsg: 'Введите другой код или очистите поле.',
                }
            },
        }
    }
</script>

<style>
    .payment-free-code-modal .hse-BaseModal__wrapper {
        min-height: 120px;
        text-align: center;
        padding: 47px;
    }
</style>