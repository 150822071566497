<template>
  <div class="add-participant">
    <HseHeading size="h3">{{ $t('headings.addParticipant') }}</HseHeading>
    <HseSpace size='small' vertical />
    <div class="check">
      <div class="field">
        <Validator :field="fields.login" @change="toggleError" />
        <HseSpace size='small_2x' vertical />
      </div>

      <div class="form-footer">
        <div class="actions">
          <HseButton
            v-on:click="checkEmail"
            :spinner="buttonSpinner"
            spinnerPosition="left"
          >
            <span v-if="buttonSpinner === false">{{ $t('headings.addParticipant') }}</span>
            <span v-else-if="buttonSpinner === true">{{ $t('adding') }}...</span>
            <span v-else-if="buttonSpinner === 'done'">{{ $t('auth.btnDone') }}</span>
          </HseButton>
        </div>
        <div class="form-description">{{ $t('fields.requiredFields') }}</div>
      </div>

    </div>
  </div>
</template>

<script>
  import { HseHeading, HseSpace, HseButton } from '@hse-design/vue'
  import { api } from '../../api'
  import Validator from '../common/form/Validator.vue'
  import fields from './participantFields'
  import { mapState } from 'vuex'
  import { localizeFields } from '../../i18n'

  export default {
    components: {
      HseHeading,
      HseSpace,
      HseButton,
      Validator,
    },
    props: ['added'],
    data: () => ({
      fields,
      buttonSpinner: false,
      participant: null,
    }),
    created () {
      this.translateFields()

    },
    computed: {
      isLangRu () {
        return this.lang === 'ru'
      },
      ...mapState(['lang'])
    },
    watch: {
      lang () {
        this.translateFields()
      },
    },
    methods: {
      toggleError (value) {
        const isErrSet = this.$store.state.error && (this.$store.state.error.code === 876 || this.$store.state.error.code === 877)
        const isAdded = this.added.some(coauth => coauth.login === value)
        if (isAdded && !isErrSet) {
          this.$store.commit('setError', { code: 876 })
          this.$emit('validateFields')
        } else if (!isAdded && isErrSet) {
          this.$store.commit('setError', null)
          this.$emit('validateFields')
        }
      },
      translateFields () {
        this.fields = localizeFields(fields, this.fields)
      },
      registerReq () {
        this.$emit('validateFields')

        if (this.participant) {
          this.buttonSpinner = 'done'
          setTimeout(() => {
            this.$emit('saved', {
              ...this.participant,
            } )
          }, 2000)
          return
        }
      },
      checkEmail () {
        this.$emit('validateFields')

        if (this.fields.login.isValid) {
          this.buttonSpinner = true

          api.auth('user.manage.check.login', this.fields.login.value).then(result => {
            if (result) {
              const isAdded = this.added.some(item => item.id === result)
              if (!isAdded) {
                api.auth('user.manage.get', result).then(data => {
                  this.participant = data
                  this.registerReq()
                })
              } else {
                this.$store.commit('setError', { code: 876 })
                this.buttonSpinner = false
                this.$emit('validateFields')
              }
            } else {
              this.$store.commit('setError', { code: 877 })
              this.buttonSpinner = false
              this.$emit('validateFields')
            }
          })
        }
      },
    },
    i18n: {
      messages: {
        en: {
          headings: {
            addParticipant: 'Add a participant',
          },
          adding: 'Adding',
        },
        ru: {
          headings: {
            addParticipant: 'Добавить участника',
          },
          adding: 'Добавляем',
        },
      }
    }
  }
</script>

<style scoped>
  .add-participant .form {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px;
  }
  .form.down {
    align-items: flex-end;
  }
  .form .field {
    padding: 0 16px;
    min-width: 0;
    width: 50%;
    flex-basis: 50%;
  }
  .actions .hse-Button {
    display: inline-block;
  }
  .checkboxes {
    display: flex;
    justify-content: space-between;
  }
  .form-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .form-description {
    padding-top: 12px;
    font-size: 14px;
    line-height: 20px;
    color: #6B7A99;
  }
  .flex-tooltip {
    display: flex;
  }
  .flex-tooltip>div:first-child {
    width: calc(100% - 28px)
  }
  .flex-tooltip>div:last-child {
    padding: 36px 0 0 12px;
  }
  @media (max-width: 1024px) {
    .register {
      margin: 16px auto;
      max-width: 95%;
    }
    .emptyfield {
      display: none;
    }
    .checkboxes {
      flex-direction: column;
    }
  }
  @media (max-width: 768px) {
    .form .field {
      width: 100%;
      flex-basis: 100%;
    }
    .form-footer {
      flex-direction: column;
      align-items: flex-start;
    }
  }
</style>
<style>
  .smallLabel {
    font-size: 14px !important;
  }
  @media (max-width: 768px) {
    .hse-Input_disabled input:disabled {
      opacity: 1 !important;
      color: #000 !important;
    }
  }
</style>